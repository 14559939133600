// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
    Badge,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Toolbar,
    Tooltip,
} from '@material-ui/core';
import {
    FilterList as FilterListIcon,
    Search as SearchIcon,
} from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {};
type StateProps = {
    filterCount: number,
    search: {
        q?: string,
    },
};
type DispatchProps = {
    toggleFilterDrawer: (open: boolean) => void,
};
type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles(styles, { name: 'SearchToolbar' });

export function SearchToolbar(props: Props) {
    const {
        filterCount,
        history,
        location,
        search,
        toggleFilterDrawer,
    } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const handleQueryChange = useCallback((event: SyntheticInputEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const { q, ...query } = search;

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                value ? { ...query, q: value } : query,
                { addQueryPrefix: true, encodeValuesOnly: true }
            ),
        });
    }, [history, location.pathname, search]);

    const handleDrawerOpen = useCallback(() => {
        toggleFilterDrawer(true);
    }, [toggleFilterDrawer]);

    return (
        <Toolbar className={classes.root} disableGutters>
            <OutlinedInput
              className={classes.input}
              fullWidth
              labelWidth={0}
              margin="dense"
              onChange={handleQueryChange}
              placeholder={t('customerList.searchPlaceholder')}
              startAdornment={(
                  <InputAdornment position="start">
                      <SearchIcon />
                  </InputAdornment>
              )}
              value={search.q || ''}
            />
            <Tooltip title={t('customerList.filters.filterUsers')}>
                <IconButton onClick={handleDrawerOpen}>
                    <Badge classes={{ badge: classes.badge }} badgeContent={filterCount}>
                        <FilterListIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
        </Toolbar>
    );
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    filterCount: selectors.getActiveFilterCount(state, props),
    search: selectors.parseSearchParams(state, props),
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    toggleFilterDrawer: (open: boolean) => dispatch(actions.toggleFilterDrawer(open)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default connector(SearchToolbar);
