// @flow
import React from 'react';
import Helmet from 'react-helmet';
import { Route, Switch } from 'react-router';
import NoMatch from '../NoMatch';
import ProjectBuilder from '../ProjectBuilder';
import SubscriptionList from '../SubscriptionList';

export default function Projects() {
    return (
        <div>
            <Helmet title="Projects" />
            <Switch>
                <Route path="/projects/:orgId/create/:subscriptionId(new|\d+)" component={ProjectBuilder} />
                <Route path="/projects/:orgId/:state/list" component={SubscriptionList} />
                <Route component={NoMatch} />
            </Switch>
        </div>
    );
}
