// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import { Field, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import FormikInput from '../../../../components/FormikInput';
import SubmitButton from '../../../../components/SubmitButton';
import styles from './styles';

type Props = {
    dirty: boolean,
    handleSubmit: Function,
    isSubmitting: boolean,
    isValid: boolean,
    setStatus: (status: any) => void,
    status?: any,
};

const useStyles = makeStyles(styles, { name: 'ResetPasswordForm' });

export default function ResetPasswordForm(props: Props) {
    const { dirty, handleSubmit, isSubmitting, isValid, setStatus, status } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const handleChange = useCallback(() => {
        if (status && status.error) {
            setStatus({ error: null });
        }
    }, [status, setStatus]);

    return (
        <Paper className={classes.paper} elevation={0}>
            <Form name="resetPassword" onChange={handleChange}>
                <h1>{t('resetPassword.form.heading')}</h1>
                {status && status.error
                    ? <div className={classes.error}>{status.error}</div>
                    : null
                }
                <fieldset className={classes.fieldset}>
                    <Field
                      classes={{ input: classes.input }}
                      component={FormikInput}
                      fullWidth
                      label={t('resetPassword.form.password')}
                      labelPlacement="top"
                      margin="dense"
                      minLength={6}
                      name="password"
                      placeholder={t('shared.auth.newPasswordPlaceholder')}
                      required
                      type="password"
                      variant="outlined"
                    />
                    <Field
                      classes={{ input: classes.input }}
                      component={FormikInput}
                      fullWidth
                      label={t('resetPassword.form.confirmPassword')}
                      labelPlacement="top"
                      minLength={6}
                      margin="dense"
                      name="confirmPassword"
                      placeholder={t('shared.auth.confirmNewPasswordPlaceholder')}
                      required
                      type="password"
                      variant="outlined"
                    />
                    <Field name="email" component="input" type="hidden" />
                    <Field name="token" component="input" type="hidden" />
                </fieldset>
                <SubmitButton
                  className={classes.submitButton}
                  color="secondary"
                  disabled={!dirty || isSubmitting || !isValid}
                  onClick={handleSubmit}
                  size="large"
                  submitting={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                    {t('resetPassword.form.submit')}
                </SubmitButton>
            </Form>
        </Paper>
    );
}
