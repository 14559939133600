// @flow
export const RESET_LOCATIONS = 'g/launch/RESET_LOCATIONS';

export const GET_MORE_LOCATIONS = 'g/launch/GET_MORE_LOCATIONS';
export const GET_MORE_LOCATIONS_ERROR = `${GET_MORE_LOCATIONS}_ERROR`;
export const GET_MORE_LOCATIONS_SUCCESS = `${GET_MORE_LOCATIONS}_SUCCESS`;

export const GET_PRICE_ESTIMATE = 'g/launch/GET_PRICE_ESTIMATE';
export const GET_PRICE_ESTIMATE_ERROR = `${GET_PRICE_ESTIMATE}_ERROR`;
export const GET_PRICE_ESTIMATE_SUCCESS = `${GET_PRICE_ESTIMATE}_SUCCESS`;

export default {
    RESET_LOCATIONS,
    GET_MORE_LOCATIONS,
    GET_MORE_LOCATIONS_ERROR,
    GET_MORE_LOCATIONS_SUCCESS,
    GET_PRICE_ESTIMATE,
    GET_PRICE_ESTIMATE_ERROR,
    GET_PRICE_ESTIMATE_SUCCESS,
};
