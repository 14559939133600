// @flow
import { createAction } from 'redux-actions';
import axios from 'axios';
import type { Dispatch } from 'redux';
import type { $AxiosError, $AxiosXHR } from 'axios';
import types from './types';
import * as session from '../../../ducks/session';

/* Analytics */
// import { trackEvent } from '../../browser/shared/util/GoogleTagManager';
// import { SUBMIT_ACCEPT_INVITE } from '../constant/analytics/AuthPages';

type FormValues = {
    code: string,
    confirmPassword: string,
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    stayUpdated: boolean,
    termsOfService: boolean,
};


/* eslint-disable quote-props */
const errorsByStatusCode = {
    '500': 'internalServerError',
    default: 'unknownError',
};
/* eslint-enable quote-props */

export const submitError = createAction(types.SUBMIT_ERROR);
export const submitSuccess = createAction(types.SUBMIT_SUCCESS);
export const submit = createAction(
    types.SUBMIT,
    (values: FormValues): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            // trackEvent(SUBMIT_ACCEPT_INVITE);

            const { code, email, firstName, lastName, password } = values;
            const params = {
                firstName,
                inviteHash: code,
                lastName,
                password,
                username: email,
            };
            return axios.post('/auth/accept_invitation', params)
                .then((resp: $AxiosXHR<Object>) => {
                    const { user, features, profile } = resp.data;
                    dispatch(submitSuccess());
                    dispatch(session.actions.initialize({ user, features, profile }));
                    dispatch(session.actions.start());
                })
                .catch((err: $AxiosError<Object>) => {
                    let error = 'networkError';
                    if ('response' in err) {
                        const { data, status } = err.response;
                        if (typeof data.message === 'string') error = data.message;
                        else if (status in errorsByStatusCode) error = errorsByStatusCode[status];
                        else error = errorsByStatusCode.default;
                    }
                    dispatch(submitError(err));
                    return Promise.reject(error);
                });
        }
    )
);

export default {
    submit,
    submitError,
    submitSuccess,
};
