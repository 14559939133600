// @flow
import React, { Component } from 'react';
import debounce from 'lodash.debounce';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import {
    Button,
    FormControlLabel,
    IconButton,
    Input,
    MenuItem,
    Select,
    Toolbar,
} from '@material-ui/core';
import {
    Clear as ClearIcon,
    List as ListIcon,
    Map as MapIcon,
    Refresh as RefreshIcon,
} from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter } from 'react-router';
import * as dialog from '../../../../ducks/dialog';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {
    classes: Object,
    t: TFunction,
};
type StateProps = {
    actions: Object[],
    recordCount: number,
    search: {
        size?: string,
        page?: string,
        sort?: string,
        order?: string,
    },
    selected: number[],
};
type DispatchProps = {
    clearSelected: () => void,
    closeDialog: () => void,
    openDialog: (name: string, props: Object) => void,
    refresh: () => Promise<void>,
};
type Props = OwnProps & StateProps & DispatchProps;

export class ActionToolbar extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.handleRefresh = debounce(this.handleRefresh, 500);
    }

    getMatchParams() {
        const { match } = this.props;
        return { orgId: '0', view: 'list', ...match.params };
    }

    handleActionClick = (event: SyntheticEvent<*>) => {
        const { clearSelected, closeDialog, openDialog, refresh, selected } = this.props;
        const dialogProps = {
            onClose: () => closeDialog(),
            onSubmitSuccess: () => {
                closeDialog();
                refresh();
                clearSelected();
            },
            ticketIds: selected,
        };

        openDialog(event.currentTarget.name, dialogProps);
    };

    handleRefresh = () => {
        const { refresh } = this.props;
        refresh();
    };

    handleClearSelected = () => {
        const { clearSelected } = this.props;
        clearSelected();
    };

    handleViewChange = (event: SyntheticInputEvent<>) => {
        const { history, location, match } = this.props;
        const view = event.target.value;

        history.replace({
            ...location,
            pathname: match.url.replace(view === 'map' ? 'list' : 'map', view),
        });
    };

    renderGigCount = () => {
        const { classes, recordCount, search, t } = this.props;
        const page = Math.max(parseInt(search.page, 10) - 1, 0) || 0;
        const pageSize = Math.max(parseInt(search.size, 10), 0) || 10;

        const translateOptions = {
            count: recordCount,
            from: recordCount === 0 ? 0 : (page * pageSize) + 1,
            to: Math.min(recordCount, (page + 1) * pageSize),
        };

        return (
            <div className={classes.gigCount}>
                <Trans
                  parent="span"
                  defaults={t('ticketList.toolbar.recordCount', translateOptions)}
                  components={[
                      <strong style={{ fontWeight: 600 }}>from</strong>, '-',
                      <strong style={{ fontWeight: 600 }}>to</strong>, 'of',
                      <strong style={{ fontWeight: 600 }}>count</strong>, 'gigs',
                  ]}
                />
                <IconButton onClick={this.handleRefresh} size="small">
                    <RefreshIcon fontSize="small" />
                </IconButton>
            </div>
        );
    };

    renderSelectedCount = () => {
        const { classes, selected, t } = this.props;

        return (
            <div className={classes.selectedCount}>
                <Trans
                  parent="span"
                  defaults={t('ticketList.toolbar.selectedCount', { count: selected.length })}
                  components={[<strong style={{ fontWeight: 600 }}>from</strong>, 'selected']}
                />
                {selected.length > 0
                    ? (
                        <IconButton onClick={this.handleClearSelected} size="small">
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    )
                    : null
                }
            </div>
        );
    };

    renderActions = () => {
        const { actions: ticketActions, classes, selected, t } = this.props;
        const buttonProps = {
            classes: {
                root: classes.button,
                disabled: classes.buttonDisabled,
                contained: classes.buttonContained,
                containedPrimary: classes.buttonContainedPrimary,
            },
            variant: 'contained',
            color: 'primary',
            disabled: selected.length === 0,
            onClick: this.handleActionClick,
        };

        return (
            <div>
                {ticketActions.map((action: Object) => (
                    <Button
                      {...buttonProps}
                      key={action.name}
                      name={action.name}
                    >
                        {t(`ticketList.toolbar.${action.name}`)}
                    </Button>
                ))}
            </div>
        );
    };

    renderSelectValue = (value: string) => {
        const { classes, t } = this.props;
        return (
            <div className={classes.menuItem}>
                {value === 'list'
                    ? <ListIcon className={classes.icon} />
                    : <MapIcon className={classes.icon} />
                }
                <span className={classes.label}>{t(`ticketList.toolbar.${value}`)}</span>
            </div>
        );
    };

    render() {
        const { classes, t } = this.props;
        const params = this.getMatchParams();

        return (
            <Toolbar className={classes.root}>
                {this.renderGigCount()}
                {this.renderSelectedCount()}
                {this.renderActions()}
                <FormControlLabel
                  classes={{
                      root: classes.select,
                      label: classes.selectLabel,
                  }}
                  control={(
                      <Select input={<Input disableUnderline />} renderValue={this.renderSelectValue}>
                          <MenuItem className={classes.menuItem} value="list">
                              <ListIcon className={classes.icon} />
                              <span className={classes.label}>{t('ticketList.toolbar.list')}</span>
                          </MenuItem>
                          <MenuItem className={classes.menuItem} value="map">
                              <MapIcon className={classes.icon} />
                              <span className={classes.label}>{t('ticketList.toolbar.map')}</span>
                          </MenuItem>
                      </Select>
                  )}
                  value={params.view}
                  onChange={this.handleViewChange}
                />
            </Toolbar>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    actions: selectors.getTicketActions(state, props),
    pages: state.ticketList.metadata.page_count || 0,
    recordCount: state.ticketList.metadata.record_count || 0,
    search: selectors.parseSearchParams(state, props),
    selected: state.ticketList.selected,
    user: state.session.user,
});
const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    clearSelected: () => dispatch(actions.clearSelected()),
    closeDialog: () => dispatch(dialog.actions.close()),
    openDialog: (name: string, props: Object) => dispatch(dialog.actions.open(name, props)),
    refresh: () => dispatch(actions.refresh()),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'ActionToolbar' }),
    withTranslation(),
    connector,
);

export default enhance(ActionToolbar);
