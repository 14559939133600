// @flow
import { createSelector, createStructuredSelector } from 'reselect';
import { selectors as projectBuilder } from '../../../../../duck';

// type FormValues = {
//     workAllocation: {
//         optinType: string,
//         autoassign: boolean,
//     },
// };

export const getInitialValues = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    projectBuilder.getInitialValues,
    createStructuredSelector({
        needsPublicWorkforce: (values: Object) => values.needsPublicWorkforce,
        workAllocation: (values: Object) => values.workAllocation,
    })
);

export default {
    getInitialValues,
};
