// @flow
export default (theme: Object) => ({
    container: {
        display: 'flex',
        alignItems: 'center',

        '& > *': {
            margin: theme.spacing(0, 0.5),

            '&:first-child': { marginLeft: 0 },
            '&:last-child': { marginRight: 0 },
        },
    },
    readOnly: {
        color: theme.palette.text.secondary,
    },
    icon: {
        color: 'rgba(0, 0, 0, 0.64)', // theme.palette.action.active,
        fontSize: 22,
    },
    label: {
        fontFamily: 'ProximaNova',
        fontSize: 16,
        fontWeight: 600,
        color: 'rgba(0, 0, 0, 0.64)', // theme.palette.text.secondary,

        '&::after': {
            content: '":"',
        },
    },
    input: {
        fontFamily: 'ProximaNova',
        borderRadius: 4,
        border: [[1, 'solid', '#cfd8df']],
        padding: theme.spacing(0.5, 1),
        minWidth: 120,
        cursor: 'pointer',

        '&$disabled': {
            cursor: 'default',
            color: 'rgba(0, 0, 0, 0.48)',
            backgroundColor: '#e5eaed',
        },

        '&:focus': {
            borderColor: '#cfd8df',
        },
    },
    inputElement: {
        textAlign: 'center',

        '&$assigned': {
            fontWeight: 600,
            color: theme.palette.primary.main,
        },
    },
    clearButton: {
        padding: 6,
        fontSize: 18,
    },
    successIcon: {
        fontSize: 18,
        color: theme.palette.success.main,
    },
    errorIcon: {
        fontSize: 18,
        color: theme.palette.error.main,
    },
    assigned: {},
    disabled: {},
});
