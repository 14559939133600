// @flow
export default (theme: Object) => ({
    '@global body': {
        background: theme.palette.background.default, // $neutral-2
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            position: 'relative',
        },

        [theme.breakpoints.down('sm')]: {
            height: ['auto', '!important'],
            display: 'flex',
            flexDirection: 'column',
        },
    },
    item: {
        [theme.breakpoints.up('md')]: {
            position: 'absolute',

            '&$leftColumn': {
                width: '70%',
                left: 0,
            },

            '&$rightColumn': {
                width: '30%',
                right: 0,
            },
        },

        [theme.breakpoints.down('sm')]: {
            '&:nth-child(1)': { order: 1 },
            '&:nth-child(2)': { order: 4 },
            '&:nth-child(3)': { order: 2 },
            '&:nth-child(4)': { order: 3 },
        },
    },
    contentWrapper: {
        padding: 5,
    },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    leftColumn: {},
    rightColumn: {},
});
