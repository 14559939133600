// @flow
export default (theme: Object) => ({
    '@global body': {
        background: theme.palette.background.default, // $neutral-2
    },
    root: {
        fontFamily: 'ProximaNova',
    },
    status: {
        fontStyle: 'italic',
    },
    avatar: {
        marginRight: theme.spacing(2),
    },
    rating: {
        fontSize: 16,
        fontWeight: 600,
    },
    stars: {
        marginRight: 10,
        flexShrink: 0,
    },
    toolbars: {},
    search: {
        '&:not(:only-child)': {
            minHeight: 0,
        },
    },
    filters: {},
});
