// @flow
export const OPEN_DIALOG = 'g/dialog/OPEN';
export const CLOSE_DIALOG = 'g/dialog/CLOSE';
export const REGISTER_DIALOG = 'g/dialog/REGISTER';
export const UNREGISTER_DIALOG = 'g/dialog/UNREGISTER';

export default {
    CLOSE_DIALOG,
    OPEN_DIALOG,
    REGISTER_DIALOG,
    UNREGISTER_DIALOG,
};
