// @flow
type GeoFilter = {
    key: string,
    value: {
        primary: string,
        secondary?: string,
    }
}

const parseAddress = (address: string): Object => {
    const [primary, secondary] = address.split('+');
    const value = {};

    if (primary) value.primary = primary;
    if (secondary) value.secondary = secondary;

    return value;
};

export default function decodeGeoFilter(encoded: Object): ?GeoFilter {
    if ('zip' in encoded) return { key: 'zipcode', value: parseAddress(encoded.zip) };
    if ('city' in encoded) return { key: 'city', value: parseAddress(encoded.city) };
    if ('state' in encoded) return { key: 'state', value: parseAddress(encoded.state) };
}
