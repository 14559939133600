// @flow
import { createAction } from 'redux-actions';
import identity from 'lodash.identity';
import omitBy from 'lodash.omitby';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import logger from '../../../util/logger';
import { search as searchPayouts } from '../../../redux/entities/payouts';
import types from './types';
import { search as searchCustomers } from '../../../redux/entities/customers';
import { search as searchTickets } from '../../../redux/entities/tickets';

export const fetchDataSuccess = createAction(types.FETCH_DATA_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const fetchDataError = createAction(types.FETCH_DATA_ERROR);
export const fetchData = createAction(
    types.FETCH_DATA,
    (filters: ?Object, sort: ?Object[], limit: ?number, offset: ?number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => {
            const [sortField, sortOrder] = sort && sort[0] ? Object.entries(sort[0])[0] : [null, null];
            const params = omitBy({ ...filters, limit, offset, sort_field: sortField, sort_order: sortOrder }, (value) => value == null);
            return dispatch(searchPayouts(params))
                .then((resp: Object) => dispatch(fetchDataSuccess(resp.result, resp.metadata)))
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchDataError());
                    return Promise.reject(err);
                });
        }
    )
);

export const loadFiltersSuccess = createAction(types.LOAD_FILTERS_SUCCESS);
export const loadFiltersError = createAction(types.LOAD_FILTERS_ERROR);
export const loadFilters = createAction(
    types.LOAD_FILTERS,
    (search: Object) => (
        (dispatch: Dispatch<any>) => Promise.resolve()
            .then(() => {
                const promises = [];
                if (search.customer) {
                    const apiFilters = [{ key: 'id', value: parseInt(search.customer, 10) }];
                    promises.push(dispatch(searchCustomers({ filters: apiFilters })));
                }
                if (search.ticket) {
                    const apiFilters = [{ key: 'id', value: parseInt(search.ticket, 10) }];
                    promises.push(dispatch(searchTickets({ filters: apiFilters })));
                }
                return Promise.all(promises);
            })
            .then(() => {
                dispatch(loadFiltersSuccess());
            })
            .catch((err: $AxiosError<any>) => {
                logger.error(err);
                dispatch(loadFiltersError());
                return Promise.reject(err);
            })
    )
);

export default {
    fetchData,
    fetchDataError,
    fetchDataSuccess,
    loadFilters,
    loadFiltersError,
    loadFiltersSuccess,
};
