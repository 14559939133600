// @flow
import moment from 'moment';
import date from './date';
import required from './required';

/**
 * Returns a validation error if value is not a date in the future
 * @param value
 * @returns {string}
 */
export default (value: any): ?string => {
    const requiredError = required(value);
    if (requiredError) return;

    const dateError = date(value);
    if (dateError) return dateError;

    if (moment(value).isBefore(moment())) {
        return 'dateInThePast';
    }
};
