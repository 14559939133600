// @flow
import { fade } from '@material-ui/core/styles/colorManipulator';

export default (theme: Object) => ({
    root: {
        width: '100vw',
        height: '100%', // change
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.grey[900],
        color: theme.palette.common.white,

        '@global': {
            '.tui-image-editor-canvas-container': {
                margin: 'auto',
            },

            '.tui-image-editor-grid-visual': {
                display: 'none',
                position: 'absolute',
                width: '100%',
                height: '100%',
                border: [[1, 'solid', 'rgba(255, 255, 255, 0.7)']],
            },
        },
    },
    layout: {
        flex: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    editor: {
        width: '100%',

        '&$showGrid': {
            '@global': {
                '.tui-image-editor-grid-visual': {
                    display: 'block',
                },
            },
        },
    },
    toolbar: {
        width: '100%',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(0, 0, 0, .6)',
    },
    menuToolbar: {
        flexShrink: 0,
        width: '100%',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, .6)',
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },
    toggleButton: {
        border: [[1, 'solid', theme.palette.grey[300]]],
        margin: theme.spacing(0, 1),

        '&:hover': {
            backgroundColor: fade(theme.palette.grey[300], 0.1),
        },

        '&$selected': {
            backgroundColor: theme.palette.grey[300],
            color: theme.palette.text.primary,

            '&:hover': {
                backgroundColor: theme.palette.grey.A100,
            },
        },
    },
    save: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        zIndex: 1,

        '&$disabled': {
            color: 'rgba(255, 255, 255, 0.3)',
            backgroundColor: 'rgba(255, 255, 255, 0.12)',
        },
    },
    saveIcon: {
        marginRight: theme.spacing(1),
    },
    close: {
        position: 'absolute',
        top: theme.spacing(1),
        left: theme.spacing(1),
        zIndex: 1,
    },
    selected: {},
    disabled: {},
    showGrid: {},
});
