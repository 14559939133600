// @flow
export const EXPAND_QUESTION = 'g/questions/EXPAND_QUESTION';
export const COLLAPSE_QUESTION = 'g/questions/COLLAPSE_QUESTION';

export const SAVE_QUESTION = 'g/questions/SAVE_QUESTION';
export const SAVE_QUESTION_SUCCESS = `${SAVE_QUESTION}_SUCCESS`;
export const SAVE_QUESTION_ERROR = `${SAVE_QUESTION}_ERROR`;

export default {
    EXPAND_QUESTION,
    COLLAPSE_QUESTION,
    SAVE_QUESTION,
    SAVE_QUESTION_SUCCESS,
    SAVE_QUESTION_ERROR,
};
