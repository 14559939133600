var Handlebars = require('/tmp/build_c41768ab/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "complete";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div class=\"actions\">\n            <div class=\"ti target\" data-action=\"view-metadata\">\n                "
    + container.escapeExpression((helpers.dynamicTarget || (depth0 && depth0.dynamicTarget) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.target : depth0),{"name":"dynamicTarget","hash":{},"data":data}))
    + "\n                <div class=\"metadata popup "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasMetadata : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-target=\"target-metadata\" style=\"display:none;\"></div>\n            </div>\n\n            <div class=\"viewing icon-buttons\">\n                <a class=\"im icon-edit\" href=\"javascript:;\"></a>\n            </div>\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "has-metadata";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<strong>Q"
    + container.escapeExpression(((helper = (helper = helpers.questionNumber || (depth0 != null ? depth0.questionNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"questionNumber","hash":{},"data":data}) : helper)))
    + "</strong>";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <div class=\"files\">\n                    <!-- div \"attachment\" uses the same HTML as in Project Builder -->\n                    <div class=\"attachment\">\n                        <div class=\"plainLabel\">"
    + container.escapeExpression(((helper = (helper = helpers.attached_files || (depth0 != null ? depth0.attached_files : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"attached_files","hash":{},"data":data}) : helper)))
    + "</div>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.files : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\n                    <!-- ends div \"attachment\" -->\n                </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                            <div class=\"attached-file\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                                <a href=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" class=\"filename\" data-action=\"download\"><span class=\"im icon-paperclip\"></span>"
    + alias4(((helper = (helper = helpers.file_name || (depth0 != null ? depth0.file_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"file_name","hash":{},"data":data}) : helper)))
    + "</a>\n                                <a href=\"javascript:;\" class=\"remove\" data-action=\"remove-attachment\">"
    + alias4(((helper = (helper = helpers.remove || (depth0 != null ? depth0.remove : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"remove","hash":{},"data":data}) : helper)))
    + "</a>\n                            </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"images\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.images : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                        <div class=\"attachment image\">\n                            <img src=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\"/>\n                            <span class=\"im icon-magnify\" data-url=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" data-action=\"magnify\"></span>\n                        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<!-- -------------------- Question --------------------- -->\n<div class=\"instruction required question widget-panel edit-mode "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.completed : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n    <!-- widget header -->\n    <div class=\"header\">\n        <div class=\"controls\">\n            <div class=\"ti type\">\n                <span class=\"optional\"></span>\n                <span class=\"type\">"
    + alias4(((helper = (helper = helpers.titleBar || (depth0 != null ? depth0.titleBar : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"titleBar","hash":{},"data":data}) : helper)))
    + "</span>\n            </div>\n            <div class=\"arrow\"><span class=\"im icon-arrow-up\" data-action=\"collapse\"></span></div>\n            <div class=\"map\">\n                <span class=\"im icon-map-pin\" data-action=\"viewLatLon\" style=\"display:none\"></span>\n            </div>\n            <div class=\"dragger\"><span class=\"im icon-empty\"></span></div>\n        </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.targetsEnabled : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n    <!-- widget content -->\n    <div class=\"widget-panel-content\" data-target=\"body\">\n\n        <div class=\"inner\">\n            <div class=\"ti\" data-target=\"questionTitle\">\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.questionNumber : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                <span>"
    + ((stack1 = ((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n            </div>\n            <div class=\"description\">"
    + alias4(((helper = (helper = helpers.body || (depth0 != null ? depth0.body : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data}) : helper)))
    + "</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.files : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            <span class=\"im icon-search\"></span><input type=\"text\" class=\"search\" placeholder=\""
    + alias4(((helper = (helper = helpers.find_a_choice || (depth0 != null ? depth0.find_a_choice : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"find_a_choice","hash":{},"data":data}) : helper)))
    + "\" data-action=\"findAnswer\">\n\n            <div data-target=\"choices\" class=\"scroll-choices\"></div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.images : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        </div>\n        <!-- END inner -->\n\n        <div data-target=\"errorMsg\"></div>\n\n        <!-- main action button -->\n        <a href=\"javascript:;\" class=\"answerButton disabled\" data-action=\"save\"><span\n                class=\"glyphicon glyphicon-refresh loading-xsm\" data-buffering=\"dataItem\"></span>"
    + alias4(((helper = (helper = helpers.save || (depth0 != null ? depth0.save : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"save","hash":{},"data":data}) : helper)))
    + "</a>\n\n    </div>\n    <!-- END widget content -->\n</div><!-- END question -->\n";
},"useData":true});