// @flow
import Backbone from 'backbone';

export type Status = {
    item_updated: Array<any>,
    item_added: Array<any>,
    item_removed: Array<any>,
};

/**
 * This class is a Base Class for all collection
 *
 * @class (vo) BaseCollection
 * @constructor
 */
export default Backbone.Collection.extend({

    /**
     * @method _getReturnedObject
     * @returns {Object}
     * @private
     */
    _getReturnedObject(returnObject: Status): Status {
        if (!returnObject
            || !returnObject.item_added
            || !returnObject.item_removed
            || !returnObject.item_updated) {
            return {
                item_updated: [],
                item_added: [],
                item_removed: [],
            };
        }

        return returnObject;
    },

    /**
     * @method getModelByID
     * @param {int} inId
     * @returns {*}
     */
    getModelByID(inId: number): any {
        const id = parseInt(`${inId}`, 10);
        const list = this.getModelByProperty({ [this.model.prototype.idAttribute]: id });
        return list && list.length ? list[0] : null;
    },

    /**
     * @method getModelByProperty
     * @param {Object} property
     * @returns {Array}
     */
    getModelByProperty(property: Object): Array<Object> {
        return this.where(property);
    },

    /**
     * @method addItem
     * @param {Object} item
     * @return {Object} {item_updated: [], item_added:[], item_removed:[]}
     */
    addItem(item: Object, inReturnObject: Status): Status {
        const returnObject = this._getReturnedObject(inReturnObject);
        const { idAttribute } = this.model.prototype;

        const exist = (item[idAttribute]) ? this.getByID(item[idAttribute]) : false;
        if (exist) {
            this.updateByID(item[idAttribute], item, returnObject);
            return returnObject;
        }

        this.add(item);
        returnObject.item_added.push(item[idAttribute]);
        return returnObject;
    },

    /**
     * @method setData
     * @param {Array} data
     * @return {Object} {item_updated: [], item_added:[], item_removed:[]}
     */
    setData(data: Array<Object>): Status {
        this.reset();
        return this.addData(data);
    },

    /**
     * used to add several item at once
     *
     * @method addData
     * @param {Array||Object} data
     * @param {Object || null} returnObject
     * @return {Object} {item_updated: [], item_added:[], item_removed:[]}  // optional used internally
     */
    addData(data: any, inReturnObject: Status): Status {
        const returnObject = this._getReturnedObject(inReturnObject);

        if (_.isArray(data)) {
            _.each(data, (item: Object) => {
                this.addItem(item, returnObject); // is either  { item_updated: [id]} || { item_added: [id] }
            });
        } else if (_.isObject(data)) {
            this.addItem(data, returnObject);
        }

        return returnObject;
    },

    /**
     * @method getByID
     * @param {int} id
     * @returns {*}
     */
    getByID(inId: number): any {
        const id = parseInt(`${inId}`, 10);
        const item = this.get(id);

        if (!item) {
            return null;
        }

        return item.toJSON();
    },

    /**
     * @method getByProperty
     * @param {Object} property
     * @returns {Array}
     */
    getByProperty(property: Object): Array<Object> {
        const items = this.toJSON();
        return _.where(items, property) || [];
    },

    /**
     * @method updateByID
     * @param {int} id
     * @param newData
     * @param returnedObject // optional used internally
     * @return {Object} {item_updated: [], item_added:[], item_removed:[]}
     */
    updateByID(inId: number, inNewData: Object, inReturnObject: Status): Status {
        const returnObject = this._getReturnedObject(inReturnObject);
        const id = parseInt(`${inId}`, 10);
        this.updateByProperty({ [this.model.prototype.idAttribute]: id }, inNewData, returnObject);
        return returnObject;
    },

    /**
     * @method updateByProperty
     * @param {Object} property
     * @param newData
     */
    updateByProperty(property: Object, inNewData: Object, inReturnObject: Status): Status {
        const returnObject = this._getReturnedObject(inReturnObject);
        const item = this.findWhere(property);
        if (!item) {
            this.add(inNewData);
            returnObject.item_added.push(inNewData.id);
        } else {
            const index = this.indexOf(item);
            this.remove(item);
            this.add(inNewData, { at: index });
            returnObject.item_updated.push(inNewData.id);
        }

        return returnObject;
    },

    /**
     * remove several object at once
     * @method removeByIDs
     * @method ids
     * @method inReturnObject // optional used internally
     */
    removeByIDs(ids: any, inReturnObject: Status): Status {
        const returnObject = this._getReturnedObject(inReturnObject);

        if (_.isArray(ids)) {
            _.each(ids, (id: number) => {
                this.removeByID(id, returnObject);
            });
        } else if (_.isObject(ids)) {
            this.removeByID(ids, returnObject);
        }

        return returnObject;
    },

    /**
     * @method removeByProperty
     * @param {int} id
     * @param inReturnObject // optional used internally
     */
    removeByID(inId: number, inReturnObject: Status): Status {
        const returnObject = this._getReturnedObject(inReturnObject);
        const id = parseInt(`${inId}`, 10);
        this.removeByProperty({ [this.model.prototype.idAttribute]: id }, returnObject);
        return returnObject;
    },

    /**
     * @method removeByProperty
     * @param {Object} property
     * @param returnObject // optional used internally
     */
    removeByProperty(property: Object, inReturnObject: Status): Status {
        const returnObject = this._getReturnedObject(inReturnObject);
        const item = this.findWhere(property);
        if (item) {
            this.remove(item);
            returnObject.item_removed.push(item.id);
        }

        return returnObject;
    },

    /**
     * @method hasByID
     * @param {int} id
     * @return false or result (Object or Array)
     */
    hasByID(inID: number): any {
        const id = parseInt(`${inID}`, 10);
        return this.hasByProperty({ [this.model.prototype.idAttribute]: id });
    },

    /**
     * @method hasByProperty
     * @param {Object} property
     * @param returnObject
     */
    hasByProperty(property: Object): any {
        const listItem = this.where(property);
        if (listItem.length === 0) {
            return false;
        } else if (listItem.length === 1) {
            return listItem[0];
        }

        return listItem;
    },

    /**
     * similar as a custom toString()
     * @method toString
     * @returns {string}
     */
    toString(): string {
        return 'BaseCollection';
    },
});
