// @noflow
import Backbone from 'backbone';
import Instructions from './Instructions';
import TargetList from './TargetList';

/**
 * @class (Model) InstructionList
 * @constructor
 */
export default Backbone.Model.extend({
    defaults: {
        id: 0, /* Either subscription_id or ticket_id */
        instructions: null,
        timingTemplate: null,
    },

    initialize(data: Object) {
        this.set({
            instructions: new Instructions(null, { timingTemplate: this.get('timingTemplate') }),
        });

        if (_.isArray(data.instructions)) {
            const instructions = _.clone(data.instructions);
            let i = 0;
            let j = 0;
            let k = 0;

            // Find questions that are targeting the same target list and group them together.
            // This will convert projects built using the old project builder to the new layout.
            for (; i < instructions.length; i += 1) {
                const current = instructions[i];
                let other;

                // Not a target list!!
                if (current.template_id || !_.isArray(current.data_types)) {
                    continue;
                }

                for (j = i + 1; j < instructions.length; j += 1) {
                    other = instructions[j];

                    // Not a target list!!
                    if (other.template_id || !_.isArray(other.data_types)) {
                        continue;
                    }

                    // If the target list ids are the same, copy the questions from the second to the first
                    // and delete the second.
                    if (other.observation_target_list_id === current.observation_target_list_id) {
                        for (k = 0; k < other.data_types.length; k += 1) {
                            current.data_types.push(other.data_types[k]);
                        }

                        instructions.splice(j, 1);
                        j -= 1;
                    }
                }
            }

            this.get('instructions').add(instructions);
        }
    },

    getInstructions(): Instructions {
        const instructions = this.get('instructions');
        return (instructions instanceof Instructions) ? instructions : new Instructions();
    },

    /**
     * This is extracting all the datatypes IDS present in the instructions.
     * It was only tested with normal question (not with skip logic)
     * @returns {Array}
     */
    getInstructionsDatatypesID(): Array<number> {
        const dataTypes = _.pluck(this.getInstructions().toJSON(), 'data_types');
        // $FlowFixMe
        const mapped = dataTypes.map((data: Array<Object>) => data.map((item: Object) => item.data_type_id));
        let res = [];

        mapped.forEach((item: number) => {
            res = res.concat(item);
        });
        return res;
    },

    hasErrors(): Array<string> {
        const instructions = this.getInstructions();
        const errors = [];

        if (instructions.length === 0) {
            errors.push('missingInstructions');
        } else if (instructions.length === 1) {
            const timingTemplate = this.get('timingTemplate');
            const templateId = instructions.at(0).get('template_id');

            if (templateId && templateId === timingTemplate.id) {
                errors.push('missingInstructions');
            }
        }

        if (this.hasDuplicates()) {
            errors.push('instructionDuplicated');
        }

        instructions.each((instruction: Object) => {
            if (instruction instanceof TargetList && instruction.getQuestions().length === 0) {
                errors.push('missingQuestionsInTargetList');
            }
        });

        return errors;
    },

    /**
     * Checks for duplicated instructions with same template_id and for the same observation target list
     * @returns {boolean}
     */
    hasDuplicates(): boolean {
        const instructions = this.getInstructions();
        const uniqueInstructions = [];
        const templateInstructions = [];

        instructions.each((instruction: Object) => {
            if (instruction.get('template_id') && instruction.get('observation_target_list_id')) {
                const obj = {
                    templateId: instruction.get('template_id'),
                    observationId: instruction.get('observation_target_list_id'),
                };
                if (!_.find(uniqueInstructions, obj)) {
                    uniqueInstructions.push(obj);
                }

                templateInstructions.push(obj);
            }
        });

        return templateInstructions.length !== uniqueInstructions.length;
    },
});
