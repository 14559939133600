var Handlebars = require('/tmp/build_c41768ab/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<span class=\"sub-target\">"
    + alias4(((helper = (helper = helpers.for_target || (depth0 != null ? depth0.for_target : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"for_target","hash":{},"data":data}) : helper)))
    + ": "
    + alias4(((helper = (helper = helpers.targetName || (depth0 != null ? depth0.targetName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetName","hash":{},"data":data}) : helper)))
    + "</span><br>";
},"3":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <!-- question type is Numeric (currency) -->\n  <div class=\"type numeric currency\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-targetid=\""
    + alias4(((helper = (helper = helpers.targetID || (depth0 != null ? depth0.targetID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetID","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"answers\">\n      <div class=\"answer\">\n        <div class=\"description\">\n          "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.targetName : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n          "
    + alias4(((helper = (helper = helpers.question_text || (depth0 != null ? depth0.question_text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question_text","hash":{},"data":data}) : helper)))
    + "\n        </div>\n        <div class=\"currency-input\">\n          <input type=\"text\" data-input name=\""
    + ((stack1 = (helpers.uniqueID || (depth0 && depth0.uniqueID) || alias2).call(alias1,(depth0 != null ? depth0._answer : depth0),{"name":"uniqueID","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " answer number\" placeholder=\""
    + alias4(container.lambda(((stack1 = (depth0 != null ? depth0.placeholders : depth0)) != null ? stack1.ex_7 : stack1), depth0))
    + "\" />\n        </div>\n      </div>\n    </div>  \n  </div><!-- END Numeric (number) -->\n";
},"useData":true});