// @flow
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import type { ContextRouter } from 'react-router';
import { USER_ROLES } from '../../../../browser/shared/constant/UserRoles';
import { STATUS } from '../../../../browser/shared/constant/TicketConstant';
import { OPTIN_TYPES } from '../../../../browser/shared/constant/ProjectConstant';
import { ticket as ticketSchema } from '../../../redux/entities/schemas';
import type { State as RootState } from '../../../redux/initialState';

const getActiveUser = (state: RootState): ?Object => state.session.user;
const getEntities = (state: RootState): Object => state.entities;
const getTicketId = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): number => {
    const { match } = props;
    return parseInt(match.params.ticketId, 10);
};

export const getTicket = createSelector(
    getEntities,
    getTicketId,
    (entities: Object, ticketId: number) => denormalize(ticketId, ticketSchema, entities),
);

/**
 * Returns a list of valid actions for the current ticket. Note that this only takes into account
 * properties of the ticket's subscription and NOT its status. In order to determine which actions
 * are available to the user at a particular point in the ticket's lifecycle (ie. ASSIGNED,
 * SUBMITTED, etc), use the getAvailableAction selector.
 */
export const getValidActions = createSelector(
    getTicket,
    (ticket: ?Object): string[] => {
        if (!ticket) {
            return [];
        }

        const actions = [];
        const { subscription } = ticket;
        const {
            is_double_optin: isDoubleOptin,
            needs_approval: needsApproval,
            optin_type: optinType,
        } = subscription;
        const isHardScheduled = !subscription.can_reschedule;
        const needsPublicWorkforce = subscription.needs_public_workforce || subscription.needs_core;
        const reservationWindowEnabled = optinType === OPTIN_TYPES.SYSTEM_APPROVED_OPTIN && !isHardScheduled
            ? !!subscription.res_window_hours
            : false;

        actions.push(
            'assign',
            'cancel',
            'comment',
            'editStartDate',
            'editTimeEstimate',
            'reopen',
            'submit',
            'unassign',
        );

        if (isHardScheduled) {
            actions.push('reschedule');
        } else {
            actions.push('editDueDate', 'extend', 'schedule');
        }

        if (needsPublicWorkforce) actions.push('editPrice');
        if (needsApproval) actions.push('reject', 'approve');
        if (reservationWindowEnabled) actions.push('extendReservationWindow');
        if (isDoubleOptin) actions.push('apply', 'withdraw');
        if (optinType === OPTIN_TYPES.SIMPLE_OPTIN) actions.push('optIn');

        return actions;
    }
);

/**
 * Returns a list of allowed ticket actions for the current user. This is mostly determined by user role,
 * but group membership, assigned status, and workforce type (private vs public) also plays a role.
 * This is a subset of the actions returned by the getValidActions selector
 */
export const getPermittedActions = createSelector(
    getValidActions,
    getTicket,
    getActiveUser,
    (actions: string[], ticket: ?Object, user: ?Object) => {
        if (!ticket || !user) {
            return [];
        }

        const { customer: assignee, is_applicant: isApplicant, subscription } = ticket;
        const { group_memberships: groupMemberships, id: userId, organization, role: userRole } = user;
        const { cannot_opt_out: cannotOptOut } = organization.config;
        const { groups } = subscription;
        const needsPublicWorkforce = subscription.needs_public_workforce || subscription.needs_core;

        const isAssigned = !!assignee;
        const isSelfAssigned = assignee ? assignee.id === userId : false;
        const isTicketInGroup = groups.some((group: Object) => (
            groupMemberships.some((membership: Object) => membership.group_id === group.id)
        ));

        switch (userRole) {
            case USER_ROLES.PLATFORM_ADMIN:
            case USER_ROLES.SELF_SERVICE:
                return actions.filter((action: ?string) => {
                    switch (action) {
                        case 'submit':
                        case 'schedule':
                            return isSelfAssigned;

                        case 'unassign':
                            return isSelfAssigned ? !cannotOptOut : true;

                        case 'withdraw':
                            return isApplicant;

                        case 'apply':
                            return !isApplicant && isTicketInGroup;

                        case 'optIn':
                            return isTicketInGroup;

                        default:
                            return true;
                    }
                });

            case USER_ROLES.SUPER_ADMIN:
                return actions.filter((action: string): boolean => {
                    if (needsPublicWorkforce) return false;
                    switch (action) {
                        case 'submit':
                        case 'schedule':
                            return isSelfAssigned;

                        case 'unassign':
                            return isSelfAssigned ? !cannotOptOut : true;

                        case 'withdraw':
                            return isApplicant;

                        case 'apply':
                            return !isApplicant && isTicketInGroup;

                        case 'optIn':
                            return isTicketInGroup;

                        default:
                            return true;
                    }
                });

            case USER_ROLES.ADMIN:
                return actions.filter((action: string): boolean => {
                    if (needsPublicWorkforce) return false;
                    switch (action) {
                        case 'cancel':
                        case 'editStartDate':
                        case 'editDueDate':
                        case 'editTimeEstimate':
                        case 'editPrice':
                        case 'reject':
                        case 'approve':
                        case 'extend':
                        case 'reschedule':
                        case 'assign':
                        case 'reopen':
                            return isTicketInGroup || isAssigned;

                        case 'submit':
                        case 'schedule':
                            return isSelfAssigned;

                        case 'unassign':
                            return isSelfAssigned ? !cannotOptOut : isTicketInGroup || isAssigned;

                        case 'withdraw':
                            return isApplicant;

                        case 'apply':
                            return !isApplicant && isTicketInGroup;

                        case 'optIn':
                            return isTicketInGroup;

                        case 'extendReservationWindow':
                            return false;

                        default:
                            return true;
                    }
                });

            case USER_ROLES.OPERATOR:
                return actions.filter((action: string): boolean => {
                    if (needsPublicWorkforce) return false;
                    switch (action) {
                        case 'comment':
                        case 'assign':
                        case 'reopen':
                            return isTicketInGroup || isAssigned;

                        case 'submit':
                        case 'schedule':
                            return isSelfAssigned;

                        case 'unassign':
                            return isSelfAssigned ? !cannotOptOut : isTicketInGroup || isAssigned;

                        case 'withdraw':
                            return isApplicant;

                        case 'apply':
                            return !isApplicant && isTicketInGroup;

                        case 'reschedule':
                        case 'optIn':
                            return isTicketInGroup;

                        case 'cancel':
                        case 'editStartDate':
                        case 'editDueDate':
                        case 'editTimeEstimate':
                        case 'editPrice':
                        case 'reject':
                        case 'approve':
                        case 'extend':
                        case 'extendReservationWindow':
                            return false;

                        default:
                            return true;
                    }
                });

            case USER_ROLES.ANALYST:
                return actions.filter((action: string): boolean => {
                    if (needsPublicWorkforce) return false;
                    switch (action) {
                        case 'comment':
                        case 'reopen':
                            return isTicketInGroup || isAssigned;

                        case 'submit':
                        case 'schedule':
                            return isSelfAssigned;

                        case 'unassign':
                            return isSelfAssigned ? !cannotOptOut : false;

                        case 'withdraw':
                            return isApplicant;

                        case 'apply':
                            return !isApplicant && isTicketInGroup;

                        case 'assign':
                        case 'optIn':
                            return isTicketInGroup;

                        case 'cancel':
                        case 'editStartDate':
                        case 'editDueDate':
                        case 'editTimeEstimate':
                        case 'editPrice':
                        case 'reject':
                        case 'approve':
                        case 'extend':
                        case 'extendReservationWindow':
                        case 'reschedule':
                            return false;

                        default:
                            return true;
                    }
                });

            case USER_ROLES.WORKER:
                return actions.filter((action: string): boolean => {
                    switch (action) {
                        case 'comment':
                        case 'submit':
                        case 'schedule':
                            return isSelfAssigned;

                        case 'unassign':
                            return isSelfAssigned ? !cannotOptOut : false;

                        case 'withdraw':
                            return isApplicant;

                        case 'apply':
                            return !isApplicant && isTicketInGroup;

                        case 'optIn':
                            return isTicketInGroup;

                        case 'cancel':
                        case 'editStartDate':
                        case 'editDueDate':
                        case 'editTimeEstimate':
                        case 'editPrice':
                        case 'reject':
                        case 'approve':
                        case 'extend':
                        case 'extendReservationWindow':
                        case 'reschedule':
                        case 'assign':
                        case 'reopen':
                            return false;

                        default:
                            return true;
                    }
                });

            default:
                return [];
        }
    }
);

/**
 * Returns a list of available actions for the current user at this point in the ticket's lifecycle.
 * This is a subset of the list returned by getPermittedActions and takes into account the ticket's
 * status and approval_status.
 */
export const getAvailableActions = createSelector(
    getPermittedActions,
    getTicket,
    (actions: string[], ticket: ?Object) => {
        if (!ticket) {
            return [];
        }

        const { approval_status: approvalStatus, status, subscription } = ticket;
        const needsPublicWorkforce = subscription.needs_public_workforce || subscription.needs_core;

        switch (status) {
            case STATUS.CANCELED:
                return actions.filter((action: string) => action === 'comment');

            case STATUS.SCHEDULED:
            case STATUS.ASSIGNED:
                return actions.filter((action: string) => {
                    switch (action) {
                        case 'cancel':
                        case 'comment':
                        case 'editDueDate':
                        case 'editPrice':
                        case 'editStartDate':
                        case 'editTimeEstimate':
                        case 'extend':
                        case 'extendReservationWindow':
                        case 'reschedule':
                        case 'schedule':
                        case 'unassign':
                        case 'withdraw':
                            return true;

                        default:
                            return false;
                    }
                });

            case STATUS.UNASSIGNED:
                return actions.filter((action: string) => {
                    switch (action) {
                        case 'apply':
                        case 'assign':
                        case 'cancel':
                        case 'comment':
                        case 'editDueDate':
                        case 'editPrice':
                        case 'editStartDate':
                        case 'editTimeEstimate':
                        case 'extend':
                        case 'optIn':
                            return true;

                        default:
                            return false;
                    }
                });

            case STATUS.STARTED:
                return actions.filter((action: string) => {
                    switch (action) {
                        case 'comment':
                        case 'extend':
                        case 'extendReservationWindow':
                        case 'reschedule':
                        case 'submit':
                        case 'unassign':
                        case 'withdraw':
                            return true;

                        case 'cancel':
                            return !needsPublicWorkforce;

                        default:
                            return false;
                    }
                });

            case STATUS.SUBMITTED:
                return actions.filter((action: string) => {
                    switch (action) {
                        case 'comment':
                            return true;

                        case 'approve':
                        case 'reject':
                        case 'reopen':
                            return approvalStatus !== 'APPROVED';

                        default:
                            return false;
                    }
                });

            default:
                return [];
        }
    }
);

export default {
    getAvailableActions,
    getPermittedActions,
    getTicket,
    getValidActions,
};
