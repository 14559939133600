// @flow
export default (theme: Object) => ({
    root: {
        maxWidth: 200,
    },
    button: {
        width: '100%',
        height: '100%',
    },
    buttonContained: {
        boxShadow: 'none',
    },
    calendarInfo: {
        zIndex: 10,
        height: 42,
    },
    dayPicker: {
        minHeight: 342,
    },
    timePicker: {
        position: 'relative',
        background: theme.palette.common.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    confirmButton: {
        position: 'absolute',
        bottom: 0,
        right: 0,
    },
    icon: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        border: 0,
        lineHeight: 'normal',
        cursor: 'pointer',
        display: 'inline-block',
        verticalAlign: 'middle',
        padding: '10px',
    },
    active: {
        '&$dateInput': {
            '&::before': {
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                bottom: 'auto',
                border: '10px solid transparent',
                borderBottom: '10px solid black',
                borderTop: 0,
                left: '66px',
                zIndex: 2,
                top: '62px',
                borderBottomColor: 'rgba(0, 0, 0, 0.1)',
            },
            '&::after': {
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                bottom: 'auto',
                border: '10px solid transparent',
                borderBottom: '10px solid black',
                borderTop: 0,
                left: '66px',
                zIndex: 2,
                top: '63px',
                borderBottomColor: '#fff',
            },
        },
    },
    dateInput: {},
});
