// @flow
import { createAction } from 'redux-actions';
import { matchPath } from 'react-router';
import { getFormInitialValues, formValueSelector, change, untouch } from 'redux-form';
import blacklist from 'blacklist';
import isEqual from 'lodash.isequal';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import logger from '../../../util/logger';
import {
    fetch as fetchSubscription,
    update as updateSubscription,
    create as createSubscription,
    requestProjectReview,
} from '../../../redux/entities/subscriptions';
import { fetch as fetchGroup } from '../../../redux/entities/groups';
import { fetch as fetchCertification } from '../../../redux/entities/certifications';
import { fetch as fetchLocationList } from '../../../redux/entities/locationLists';
import {
    create as createDataType,
    update as updateDataType,
} from '../../../redux/entities/dataTypes';
import { getBalance } from '../../../redux/entities/organizations';
import { mapQuestionToEntity, mapEntityToQuestion } from '../containers/Questions/utils/transforms';
import types from './types';
import { getSubscriptionData } from './selectors';
import * as session from '../../../ducks/session';
import type { State as RootState } from '../../../redux/initialState';

type Question = {
    id: number,
    children?: Object[],
    description: string,
    valueType: string,
    questionText: string,
    propositions: Array<{ choice: string, alert: boolean }>,
    required: boolean,
    targetListId?: number,
    globalDataTypeId: number,
};

export const loadProjectSuccess = createAction(types.LOAD_PROJECT_SUCCESS);
export const loadProjectError = createAction(types.LOAD_PROJECT_ERROR);
export const loadProject = createAction(
    types.LOAD_PROJECT,
    (subscriptionId: number, orgId: number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => (
            dispatch(fetchSubscription({ organization_subscription_id: subscriptionId }))
                .then((resp: Object) => {
                    const subscription = resp.entities.subscriptions[subscriptionId];
                    const {
                        certifications,
                        groups,
                        organization_location_lists: locationLists,
                    } = subscription;

                    const promises = [];
                    if (locationLists) {
                        locationLists.forEach((id: number) => {
                            promises.push(dispatch(fetchLocationList({ organization_location_list_id: id })));
                        });
                    }
                    if (groups) {
                        groups.forEach((id: number) => {
                            promises.push(dispatch(fetchGroup({ organization_id: orgId, group_id: id })));
                        });
                    }
                    if (certifications) {
                        certifications.forEach((id: number) => {
                            promises.push(dispatch(fetchCertification({ certification_id: id })));
                        });
                    }
                    promises.push(dispatch(getBalance({ organization_id: orgId })));

                    return Promise.all(promises);
                })
                .then(() => {
                    dispatch(loadProjectSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(loadProjectError());
                    return Promise.reject(err);
                })
        )
    )
);

export const createProjectSuccess = createAction(types.CREATE_PROJECT_SUCCESS);
export const createProjectError = createAction(types.CREATE_PROJECT_ERROR);
export const createProject = createAction(
    types.CREATE_PROJECT,
    (orgId: number): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<number> => {
            const state = getState();
            const { user } = state.session;

            // @todo: Consider using another method to compute match to pass to selectors
            // I think the next best approach would be to pass props/match to action creators. The
            // issue with the current approach is that state.router.location is not guaranteed to always
            // be in sync with react-router. For instance, server-side matching and rendering happens
            // synchronously. Redux state will be updated in response, but content will already be rendered.
            const { location } = state.router;
            const match = matchPath(location.pathname, { path: '/projects/:orgId/create/:subscriptionId(new|\\d+)' });

            if (!match) {
                return Promise.reject();
            }

            const data = getSubscriptionData(state, { match });

            // Nullify properties that aren't applicable to private workforce projects or
            // can only be set by self-service admins
            const isGrandfathered = user && user.organization ? user.organization.grandfathered : false;
            const canLaunchPublicProject = session.selectors.canLaunchPublicProject(state);
            if ((data.needs_public_workforce && !canLaunchPublicProject) || data.needs_public_workforce === false) {
                // Grandfathered clients are allowed to set min_gig_price
                if (!isGrandfathered) {
                    data.min_gig_price = null;
                }
                data.tickets_overage = null;
                data.tickets_target = null;
            }

            return Promise.resolve()
                .then((): Promise<Object> => {
                    // Step 1: Create a new subscription using just the fields required for a DRAFT.
                    // That's it! This action doesn't do a whole lot, which is important!! If other actions
                    // are chained to this promise and an error occurs, a subscription will have been
                    // created without the consumer (ie. view) being notified of the new id.
                    const { organization_observation_target_lists: targetLists, ...subscription } = data;
                    // $FlowFixMe need to fix/update gigwalk-node type definitions
                    return dispatch(createSubscription({
                        organization_id: orgId,
                        subscriptions: [{ ...subscription, use_project_data_types: true }],
                    }));
                })
                .then((resp: Object) => {
                    dispatch(session.actions.refresh());
                    dispatch(createProjectSuccess());
                    return resp.result[0];
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(createProjectError());
                    return Promise.reject(err);
                });
        }
    )
);

export const saveProjectSuccess = createAction(types.SAVE_PROJECT_SUCCESS);
export const saveProjectError = createAction(types.SAVE_PROJECT_ERROR);
export const saveProject = createAction(
    types.SAVE_PROJECT,
    (subscriptionId: number): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
            let state = getState();
            const { user } = state.session;

            // @todo: Consider using another method to compute match to pass to selectors
            // I think the next best approach would be to pass props/match to action creators. The
            // issue with the current approach is that state.router.location is not guaranteed to always
            // be in sync with react-router. For instance, server-side matching and rendering happens
            // synchronously. Redux state will be updated in response, but content will already be rendered.
            const { location } = state.router;
            const match = matchPath(location.pathname, { path: '/projects/:orgId/create/:subscriptionId(new|\\d+)' });

            if (!match) {
                return Promise.reject();
            }

            const subscriptionData = getSubscriptionData(state, { match });
            const shouldSaveQuestionList = subscriptionData.hasOwnProperty('organization_observation_target_lists');
            const { organization_observation_target_lists: targetLists, ...data } = subscriptionData;

            // Nullify properties that aren't applicable to private workforce projects or
            // can only be set by self-service admins
            const isGrandfathered = user && user.organization ? user.organization.grandfathered : false;
            const canLaunchPublicProject = session.selectors.canLaunchPublicProject(state);
            if ((data.needs_public_workforce && !canLaunchPublicProject) || data.needs_public_workforce === false) {
                // Grandfathered clients are allowed to set min_gig_price
                if (!isGrandfathered) {
                    data.min_gig_price = null;
                }
                data.tickets_overage = null;
                data.tickets_target = null;
            }

            return Promise.resolve()
                .then((): ?Promise<Object[]> => {
                    // Step 1: Create global data_types for any 'new' questions added by the user
                    if (!shouldSaveQuestionList) {
                        return;
                    }

                    state = getState();
                    const questionList = formValueSelector('projectBuilder')(state, 'questionList') || [];
                    const createGlobalDataTypes = [];

                    questionList.forEach((question: Object, index: number) => {
                        const { id, ...dataType } = mapQuestionToEntity(question);

                        if (typeof id === 'string' && id.startsWith('new')) {
                            // $FlowFixMe need to fix/update gigwalk-node type definitions
                            const promise = dispatch(createDataType({ dataType, cid: id }))
                                .then((resp: Object) => {
                                    const dataTypeId = resp.result[0];
                                    const value = mapEntityToQuestion(resp.entities.dataTypes[dataTypeId]);

                                    // Update the form values for this question and untouch all fields to mark as pristine
                                    questionList[index] = value;
                                    dispatch(change('projectBuilder', `questionList[${index}]`, value, false, false));
                                    dispatch(untouch('projectBuilder', `questionList[${index}]`));
                                });

                            createGlobalDataTypes.push(promise);
                        }
                    });

                    return Promise.all(createGlobalDataTypes);
                })
                .then((): Promise<Object> => {
                    // Step 2: Update the subscription. This will create project data_types for any 'new' or
                    // 'duplicate' questions (including ones added via search)
                    let obsTargetLists = [];

                    if (shouldSaveQuestionList) {
                        state = getState();
                        const questionList = formValueSelector('projectBuilder')(state, 'questionList') || [];
                        obsTargetLists = questionList.map((question: Question) => {
                            const { children, id, globalDataTypeId, targetListId } = question;
                            const dataTypeId = typeof id === 'string' && id.startsWith('duplicate') ? globalDataTypeId : id;
                            return {
                                observation_target_list_id: targetListId,
                                data_types: [{ children, data_type_id: dataTypeId }],
                            };
                        });
                    }

                    const subscription = obsTargetLists && obsTargetLists.length > 0
                        ? { ...data, organization_observation_target_lists: obsTargetLists }
                        : data;

                    // $FlowFixMe need to fix/update gigwalk-node type definitions
                    return dispatch(updateSubscription({ organization_subscription_id: subscriptionId, subscription }));
                })
                .then((): ?Promise<Object[]> => {
                    // Step 3: Update project data_types with any edits made by the user. Since we perform this step
                    // after saving the subscription, the questionList from initialValues will have the same order
                    // as seen in the form.
                    if (!shouldSaveQuestionList) {
                        return;
                    }

                    state = getState();
                    const questionList = formValueSelector('projectBuilder')(state, 'questionList') || [];
                    const { questionList: initialQuestionList } = getFormInitialValues('projectBuilder')(state);
                    const updateProjectDataTypes = [];

                    questionList.forEach((question: Object, index: number) => {
                        // Ignore id, globalDataTypeId, and targetListId since those attribute is not editable by the user
                        const value = blacklist(question, 'id', 'globalDataTypeId', 'targetListId');
                        const initialValue = blacklist(initialQuestionList[index], 'id', 'globalDataTypeId', 'targetListId');
                        const isPristine = isEqual(value, initialValue);

                        if (!isPristine) {
                            const projectDataTypeId = initialQuestionList[index].id;
                            const { id, ...dataType } = mapQuestionToEntity(question);
                            // $FlowFixMe need to fix/update gigwalk-node type definitions
                            const promise = dispatch(updateDataType({ data_type_id: projectDataTypeId, dataType }))
                                .then((resp: Object) => {
                                    const dataTypeId = resp.result[0];
                                    const newValue = mapEntityToQuestion(resp.entities.dataTypes[dataTypeId]);

                                    // Update the form values for this question and untouch all fields to mark as pristine
                                    dispatch(change('projectBuilder', `questionList[${index}]`, newValue, false, false));
                                    dispatch(untouch('projectBuilder', `questionList[${index}]`));
                                });

                            updateProjectDataTypes.push(promise);
                        }
                    });

                    return Promise.all(updateProjectDataTypes);
                })
                .then(() => {
                    dispatch(saveProjectSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(saveProjectError());
                    return Promise.reject(err);
                });
        }
    )
);

export const requestReviewSuccess = createAction(types.REQUEST_REVIEW_SUCCESS);
export const requestReviewError = createAction(types.REQUEST_REVIEW_ERROR);
export const requestReview = createAction(
    types.REQUEST_REVIEW,
    (subscriptionId: number, orgId: number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => (
            Promise.resolve()
                .then((): Promise<void> => (
                    dispatch(requestProjectReview({
                        organization_id: orgId,
                        project_id: subscriptionId,
                    }))
                ))
                .then(() => {
                    dispatch(requestReviewSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(requestReviewError());
                    return Promise.reject(err);
                })
        )
    )
);

export const launchProjectSuccess = createAction(types.LAUNCH_PROJECT_SUCCESS);
export const launchProjectError = createAction(types.LAUNCH_PROJECT_ERROR);
export const launchProject = createAction(
    types.LAUNCH_PROJECT,
    (subscriptionId: number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => (
            Promise.resolve()
                .then(() => (
                    // Step 1: Launch the project
                    // $FlowFixMe need to fix/update gigwalk-node type definitions
                    dispatch(updateSubscription({
                        organization_subscription_id: subscriptionId,
                        subscription: { state: 'ACTIVE' },
                    }))
                ))
                .then(() => {
                    // Step 2: Refresh the session to update the organization stats
                    dispatch(session.actions.refresh());
                    dispatch(launchProjectSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(launchProjectError());
                    return Promise.reject(err);
                })
        )
    )
);

const resetState = createAction(types.RESET_STATE);

export default {
    createProject,
    createProjectError,
    createProjectSuccess,
    launchProject,
    launchProjectError,
    launchProjectSuccess,
    loadProject,
    loadProjectError,
    loadProjectSuccess,
    requestReview,
    requestReviewSuccess,
    requestReviewError,
    resetState,
    saveProject,
    saveProjectSuccess,
    saveProjectError,
};
