// @flow
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import types from './types';

export const startTransition = createAction(types.START_TRANSITION);
export const endTransition = createAction(types.END_TRANSITION);
export const toggle = createAction(
    types.TOGGLE,
    (): Function => (
        (dispatch: Dispatch<any>) => {
            dispatch(startTransition());
            setTimeout(() => dispatch(endTransition()), 500);
        }
    )
);

export const expand = createAction(types.EXPAND);
export const collapse = createAction(types.COLLAPSE);

export default {
    toggle,
    expand,
    collapse,
    startTransition,
    endTransition,
};
