// @flow
import { createSelector } from 'reselect';
import { getAPIParams, parseSearchParams } from '../../../duck/selectors';
import decodeGeoFilter from '../../../utils/decodeGeoFilter';

export { getAPIParams, parseSearchParams } from '../../../duck/selectors';

const _statusFilterOptions = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Deleted', value: 'DELETED' },
    { label: 'Inactive', value: 'INACTIVE' },
    { label: 'Pending', value: 'PENDING' },
    { label: 'Suspended', value: 'SUSPENDED' },
];

export const getStatusFilterOptions = (): Object[] => _statusFilterOptions;

const getSelectedStatuses = createSelector(
    parseSearchParams,
    (search: Object) => search.status || [],
);
export const getStatusFilterValue = createSelector(
    getSelectedStatuses,
    getStatusFilterOptions,
    (selected: string[], statusFilterOptions: Object[]): Object[] => (
        selected.reduce((acc, value) => {
            const selectedOption = statusFilterOptions.find((option) => option.value === value);
            if (selectedOption) {
                acc.push(selectedOption);
            }
            return acc;
        }, [])
    )
);


const getSelectedPlaces = createSelector(
    parseSearchParams,
    (search: Object) => search.geo || [],
);
export const getGeoFilterValue = createSelector(
    getSelectedPlaces,
    (selected: Object[]): Object[] => (
        selected.reduce((value: Object[], geo: Object): Object[] => {
            const filter = decodeGeoFilter(geo);
            if (filter) {
                value.push({
                    label: filter.value.primary,
                    value: filter,
                });
            }
            return value;
        }, [])
    )
);

export default {
    getAPIParams,
    getGeoFilterValue,
    getStatusFilterOptions,
    getStatusFilterValue,
    parseSearchParams,
};
