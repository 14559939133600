// @flow
import required from './required';

/**
 * Given an input X, returns a function that will validate whether or not
 * value is greater than X. Additionally, you can specify a field name as the
 * input for use with redux-form
 * @param fieldOrValue {any}
 * @param inclusive {boolean}
 * @returns {Function}
 */
export default (fieldOrValue: any, inclusive?: boolean): Function => (
    (value: any, allValues: Object): ?string | ?Object => {
        const requiredError = required(value);
        if (requiredError) return;

        const parsedValue = parseFloat(value);
        if (Number.isNaN(parsedValue)) {
            return 'invalidValue';
        }

        let otherValue;
        if (typeof fieldOrValue === 'string' && fieldOrValue in allValues) {
            otherValue = parseFloat(allValues[fieldOrValue]);
        } else {
            otherValue = parseFloat(fieldOrValue);
        }

        if (Number.isNaN(otherValue)) {
            return 'invalidComparison';
        }

        const invalid = inclusive ? parsedValue < otherValue : parsedValue <= otherValue;
        if (invalid) {
            return { message: 'notGreaterThan', value: otherValue };
        }
    }
);
