// @flow
import i18next from 'i18next';
import Cookie from 'js-cookie';
import logger from './logger';

const GWP_CLIENT_NAMESPACE = process.env.GWP_CLIENT_NAMESPACE || '';
let i18nInstance = null;

export function getI18nInstance() {
    if (!i18nInstance) {
        logger.error('I18n is not ready yet');
    }
    return i18nInstance;
}

/**
 * Initialize i18next for client application, function used for unit tests primarly since this would only be called once.
 */
export function initializeI18next(): Promise<typeof i18next> {
    logger.trace('start for i18next initialize');

    /**
     * CI Mode will disable loading resources or using other languages
     */
    if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'coverage') {
        i18next.changeLanguage('cimode');
    }

    const { locale, resources } = global.__i18n || {};

    // @todo add language detection support using i18next-browser-languagedetector?
    // This module is marked as dependency in package.json but we don't include it anywhere. We use
    // LanguageDetector from i18next-express-middleware on the server, so I'm not sure if detection
    // in the browser is needed
    return i18next
        .init({
            ns: GWP_CLIENT_NAMESPACE ? ['app', GWP_CLIENT_NAMESPACE] : 'app',
            defaultNS: GWP_CLIENT_NAMESPACE || 'app',
            fallbackNS: 'app',
            nonExplicitWhitelist: true, // allows en-US, es-419, etc.
            load: 'languageOnly', // en-US only loads en, es-419 only loads es, etc.
            fallbackLng: 'en',
            lng: locale || 'en',
            resources: resources || {},
        })
        .then(() => {
            logger.trace('initialized i18next instance');

            if (global.location) {
                Cookie.set('i18next', i18next.language, { secure: global.location.protocol === 'https:' });
            }

            i18nInstance = i18next;
            return i18nInstance;
        })
        .catch((err: any) => {
            logger.error(err, 'Failed to load the backend.');
        });
}

export default initializeI18next();
