// @flow
import { createSelector } from 'reselect';
import type { Organization } from 'gigwalk/lib/api/organizations/types';
import { GROUPS_ROLES, USER_ROLES } from '../../../browser/shared/constant/UserRoles';
import type { State as RootState } from '../../redux/initialState';

const ADMIN_ROLES = [
    USER_ROLES.PLATFORM_ADMIN,
    USER_ROLES.SUPER_ADMIN,
    USER_ROLES.SELF_SERVICE,
    USER_ROLES.ADMIN,
];

const SUPERVISOR_ROLES = [
    ...ADMIN_ROLES,
    USER_ROLES.OPERATOR,
];

const getUserRole = (state: RootState): string => (state.session.user ? state.session.user.role : '');
const getGroupMemberships = (state: RootState): Object[] => (state.session.user ? state.session.user.group_memberships : []);

export const isFirstTimeUser = createSelector(
    (state: RootState): ?Organization => { if (state.session.user) return state.session.user.organization; },
    (organization: ?Organization): boolean => {
        if (organization) {
            return (
                organization.stats.team.group_count <= 1
                && organization.stats.projects.active_count === 0
                && organization.stats.projects.archived_count === 0
                && organization.stats.projects.canceled_count === 0
                && organization.stats.projects.completed_count === 0
            );
        }
        return false;
    }
);

export const isGigwalker = createSelector(
    getUserRole,
    (state: RootState): number => (state.session.user ? state.session.user.organization.id : -1),
    (role: string, orgId: number) => (role === USER_ROLES.WORKER && orgId === 5)
);

export const isWorker = createSelector(getUserRole, (role: string): boolean => role === USER_ROLES.WORKER);
export const isOperator = createSelector(getUserRole, (role: string): boolean => role === USER_ROLES.OPERATOR);
export const isSupervisor = createSelector(getUserRole, (role: string): boolean => SUPERVISOR_ROLES.includes(role));
export const isAdmin = createSelector(getUserRole, (role: string): boolean => ADMIN_ROLES.includes(role));
export const isSuperAdmin = createSelector(getUserRole, (role: string): boolean => role !== USER_ROLES.ADMIN && ADMIN_ROLES.includes(role));
export const isPlatformAdmin = createSelector(getUserRole, (role: string): boolean => role === USER_ROLES.PLATFORM_ADMIN);

export const canLaunchPublicProject = createSelector(
    getUserRole,
    (role: string) => [USER_ROLES.PLATFORM_ADMIN, USER_ROLES.SELF_SERVICE].includes(role)
);

export const canManageGroups = createSelector(
    getUserRole,
    getGroupMemberships,
    (role: string, groupMemberships: Object[]) => {
        if (ADMIN_ROLES.includes(role)) {
            return true;
        }
        return groupMemberships.some((membership: Object) => membership.role === GROUPS_ROLES.OWNER);
    }
);

export const canEditOrgConfig = createSelector(
    getUserRole,
    (role: string) => (role === USER_ROLES.PLATFORM_ADMIN)
);

export const canAddFunds = createSelector(
    getUserRole,
    (state: RootState): boolean => (state.session.features ? state.session.features.canAccessPayments : false),
    (role: string, canAccessPayments: boolean) => (
        [USER_ROLES.SUPER_ADMIN, USER_ROLES.SELF_SERVICE].includes(role) && canAccessPayments
    ),
);

export default {
    canAddFunds,
    canEditOrgConfig,
    canLaunchPublicProject,
    canManageGroups,
    isAdmin,
    isFirstTimeUser,
    isGigwalker,
    isOperator,
    isPlatformAdmin,
    isSuperAdmin,
    isSupervisor,
    isWorker,
};
