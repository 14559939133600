// @flow
// $FlowFixMe
import { nameFromLevel } from 'browser-bunyan';
import type { BunyanRecord } from 'bunyan';

/* istanbul ignore next */
const trackJsStub = {
    track(args: any) {}, // eslint-disable-line no-unused-vars
    console: {
        log(...args: any[]) {}, // eslint-disable-line no-unused-vars
        debug() {},
        info(...args: any[]) {}, // eslint-disable-line no-unused-vars
        warn(...args: any[]) {}, // eslint-disable-line no-unused-vars
        error(...args: any[]) {}, // eslint-disable-line no-unused-vars
    },
};
/* istanbul ignore next */
const trackJs = global.trackJs || trackJsStub;

/* istanbul ignore next */
export default class TrackJSErrorStream {
    /* istanbul ignore next */
    write(rec: BunyanRecord) {
        try {
            const logLevel = nameFromLevel[rec.level];
            const consoleLevels = {
                trace: 'debug',
                debug: 'log',
                info: 'info',
                warn: 'warn',
                error: 'error',
                fatal: 'error',
            };
            trackJs.console[consoleLevels[logLevel] || 'log'](rec.msg);
        } catch (e) {
            console.error(e); // eslint-disable-line no-console
            trackJs.track(e);
        }
    }
}
