// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Component, Fragment, createRef } from 'react';
import cx from 'classnames';
import PluginsEditor from 'draft-js-plugins-editor';
import createInlineToolbarPlugin, { Separator } from 'draft-js-inline-toolbar-plugin';
import createLinkPlugin from 'draft-js-anchor-plugin';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { EditorState, convertFromRaw } from 'draft-js';
import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    HeadlineTwoButton,
    HeadlineThreeButton,
    UnorderedListButton,
    OrderedListButton,
    BlockquoteButton,
} from 'draft-js-buttons';
// import sanitize from '../../../browser/shared/util/gigwalkSanitize';
import './Editor.scss';
import inlineToolbarTheme from './InlineToolbarPlugin.scss';

type Props = {
    className?: string,
    disabled?: boolean,
    error?: string,
    id: string,
    label?: string,
    onBlur?: () => void,
    onChange?: (value: string) => void,
    onFocus?: () => void,
    required?: boolean,
    value?: string,
};

type State = {
    editorState: Object,
    focused: boolean,
};

const linkPlugin = createLinkPlugin({ linkTarget: '_blank' });
const inlineToolbarPlugin = createInlineToolbarPlugin({
    theme: {
        buttonStyles: inlineToolbarTheme,
        toolbarStyles: inlineToolbarTheme,
    },
});
const { InlineToolbar } = inlineToolbarPlugin;
const { LinkButton } = linkPlugin;
const plugins = [inlineToolbarPlugin, linkPlugin];

// Need to specify a custom entityStyleFn for links in order to set the target attribute
// See https://github.com/draft-js-plugins/draft-js-plugins/issues/1038
const stateToHTMLOptions = {
    entityStyleFn: (entity) => {
        const entityType = entity.get('type').toLowerCase();
        if (entityType === 'link') {
            const data = entity.getData();
            return {
                element: 'a',
                attributes: {
                    href: data.url,
                    target: '_blank',
                },
            };
        }
    },
};

// Construct an empty ContentState object that will have the same key on both
// server and client. This should prevent any SSR issues.
const emptyContentState = convertFromRaw({
    blocks: [{
        depth: 0,
        entityRanges: [],
        inlineStyleRanges: [],
        key: 'empty',
        text: '',
        type: 'unstyled',
    }],
    entityMap: {},
});

export default class Editor extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { value } = this.props;

        // Since stateFromHTML will try to use the document object to parse html, we need
        // check if we are running on the server before calling this function. Alternatively,
        // we could use DOMParser on the server by adding it the global context. However, this
        // takes some additional setup, as we don't want DOMParser included in the client bundle
        const contentState = value && 'document' in global ? stateFromHTML(value) : emptyContentState;
        this.state = {
            editorState: EditorState.createWithContent(contentState),
            focused: false,
        };

        this.editorRef = createRef();
    }

    componentWillReceiveProps(nextProps: Props) {
        const { value } = nextProps;
        this.updateEditorStateIfNecessary(value);
    }

    handleChange = (newEditorState: Object) => {
        // Make sure state update has occurred before calling onChange. Otherwise, when/if
        // componentWillReceiveProps is called, editorState may reference the old value.
        this.setState({ editorState: newEditorState }, () => {
            const { editorState } = this.state;
            const { onChange } = this.props;

            if (typeof onChange === 'function') {
                const newContent = editorState.getCurrentContent();
                onChange(stateToHTML(newContent, stateToHTMLOptions));
            }
        });
    };

    handleClick = () => {
        const { focused } = this.state;
        if (!focused && this.editorRef.current) {
            this.editorRef.current.focus();
        }
    };

    handleFocus = () => {
        const { focused } = this.state;
        const { onFocus } = this.props;

        if (!focused) {
            this.setState({ focused: true }, () => {
                if (typeof onFocus === 'function') {
                    onFocus();
                }
            });
        }
    };

    handleBlur = () => {
        const { focused } = this.state;
        const { onBlur } = this.props;

        if (focused) {
            this.setState({ focused: false }, () => {
                if (typeof onBlur === 'function') {
                    onBlur();
                }
            });
        }
    };

    updateEditorStateIfNecessary(newValue: ?string) {
        // Don't use this.props to compare current and next values!! We are trying to determine
        // if value changed by means outside the user's control (for example, a state change
        // caused by loading an API resource). The source of truth is always editorState.
        const { editorState } = this.state;

        // Compute nextEditorState using new value
        const nextContentState = newValue ? stateFromHTML(newValue) : emptyContentState;
        const nextEditorState = EditorState.createWithContent(nextContentState);

        // Compare the current and next string representations of editorState to determine
        // if anything has changed.
        const value = stateToHTML(editorState.getCurrentContent(), stateToHTMLOptions);
        const nextValue = stateToHTML(nextEditorState.getCurrentContent(), stateToHTMLOptions);
        if (value !== nextValue) {
            this.setState({ editorState: nextEditorState });
        }
    }

    editorRef: Object;

    render() {
        const { className, disabled, error, label, required, value, ...editorProps } = this.props;
        const { editorState, focused } = this.state;
        // const sanitizedText = value ? sanitize.allowTags(value) : '';

        const rootClassName = cx({
            'c-form__error': !!error,
            invalid: !!error,
            focused,
            disabled,
        }, className);

        return (
            <div className={rootClassName} onClick={this.handleClick} style={{ position: 'relative' }}>
                {label != null
                    ? <label htmlFor={editorProps.id} className={cx({ 'c-form__label-required': required })}>{label}</label>
                    : null
                }
                <PluginsEditor
                  {...editorProps}
                  ref={this.editorRef}
                  editorKey={editorProps.id}
                  editorState={editorState}
                  plugins={plugins}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  onFocus={this.handleFocus}
                />
                <InlineToolbar>
                    {
                        (props: Object) => (
                            <Fragment>
                                <BoldButton {...props} />
                                <ItalicButton {...props} />
                                <UnderlineButton {...props} />
                                <LinkButton {...props} />
                                <Separator {...props} className={inlineToolbarTheme.separator} />
                                <HeadlineTwoButton {...props} />
                                <HeadlineThreeButton {...props} />
                                <UnorderedListButton {...props} />
                                <OrderedListButton {...props} />
                                <BlockquoteButton {...props} />
                            </Fragment>
                        )
                    }
                </InlineToolbar>
                <p className="c-form__error-message">{error || ''}</p>
            </div>
        );
    }
}
