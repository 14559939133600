// @flow
export default (theme: Object) => ({
    root: {
        fontFamily: ['ProximaNova', '!important'],
        fontSize: [16, '!important'],

        '& > .pagination-bottom': {
            display: 'table',
            width: '100%',
        },
    },

    table: {
        position: 'relative',
        width: '100%',
        minHeight: 200,
        backgroundColor: theme.palette.common.white,
        overflowX: 'auto',

        backgroundImage: [
            'linear-gradient(to right, white, rgba(255, 255, 255, 1))',
            'linear-gradient(to left, white, rgba(255, 255, 255, 1))',
            'linear-gradient(to right, #f0f3f6, rgba(255, 255, 255, 1))', // $neutral-2
            'linear-gradient(to left, #f0f3f6, rgba(255, 255, 255, 1))', // $neutral-2
        ],
        backgroundPosition: ['0 0', '100% 0', '0 0', '100% 0'],
        backgroundRepeat: 'no-repeat',
        backgroundSize: [
            [theme.spacing(3), '100%'],
            [theme.spacing(3), '100%'],
            [theme.spacing(1.5), '100%'],
            [theme.spacing(1.5), '100%'],
        ],
        backgroundAttachment: ['local', 'local', 'scroll', 'scroll'],
    },

    tableInner: {
        display: 'table',
        fontFamily: 'ProximaNova',

        overflowX: 'hidden',
        borderCollapse: 'collapse',
        width: '100%',
        minWidth: 800,
    },

    body: {
        display: 'table-row-group',
        position: 'relative',
    },

    rowGroup: {
        display: 'table-row',

        '& $row': {
            borderTop: [[1, 'solid', 'transparent']],
            borderLeft: [[3, 'solid', 'transparent']],
            borderRight: [[3, 'solid', 'transparent']],
        },

        '&$selected': {
            '& $row': {
                borderTop: [[1, 'solid', theme.palette.primary.main]],
                borderLeft: [[3, 'solid', theme.palette.primary.main]],
                borderRight: [[3, 'solid', theme.palette.primary.main]],
            },

            '& + :not($selected) $row': {
                borderTop: [[1, 'solid', theme.palette.primary.main]],
            },

            '& + $selected $row': {
                borderTop: [[1, 'solid', 'transparent']],
            },
        },

        '&:last-child': {
            '& $row': {
                borderBottom: [[1, 'solid', 'transparent']],
            },

            '&$selected $row': {
                borderBottom: [[1, 'solid', theme.palette.primary.main]],
            },
        },
    },

    cell: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1.5, 2),
        fontFamily: 'ProximaNova',
        fontSize: 16,
        wordBreak: 'break-word',
    },

    row: {
        display: 'flex',
    },

    headerGroup: {
        display: 'table-header-group',

        '& $row': {
            marginLeft: 3,
            marginRight: 3,
        },
    },

    header: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1.5, 2),
        fontFamily: 'ProximaNova',
        fontSize: 16,
    },

    footer: {
        display: 'table-footer-group',
    },

    paginationCaption: {
        fontFamily: 'ProximaNova',
        fontSize: 16,
    },

    paginationInput: {
        fontFamily: 'ProximaNova',
        fontSize: 16,
    },

    paginationSelect: {
        paddingRight: theme.spacing(3),
        paddingBottom: `calc(${theme.spacing(1)} - 2px)`,
    },

    paginationSelectIcon: {
        top: 3,
    },

    loading: {
        position: 'absolute',
        height: '100%',
        width: '100%',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        paddingTop: 50,
    },

    noData: {
        fontSize: 18,
        fontStyle: 'italic',

        color: '#8e9499', // $neutral-40;

        position: 'absolute',
        height: '100%',
        width: '100%',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        paddingTop: 50,
    },

    selected: {},
});
