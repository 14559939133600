// @flow
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import pkg from '../../../../../../package.json';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = {
    classes: Object,
    t: TFunction
};

type StateProps = {
    apiVersion: string,
    webVersion: string,
};

type Props = OwnProps & StateProps;

export const Footer = (props: Props) => {
    const { apiVersion, classes, t, webVersion } = props;
    return (
        <footer className={classes.footer}>
            <div className={classes.content}>
                <img className={classes.logo} src="/public/images/gigwalk-logo.png" alt="Gigwalk logo" />
                <p className={classes.info}>{t('layout.footer.copyright')}</p>
                <p className={classes.info}>{t('layout.footer.web')}: {webVersion}</p>
                <p className={classes.info}>{t('layout.footer.api')}: {apiVersion}</p>
            </div>
        </footer>
    );
};

const mapStateToProps = (state: RootState): StateProps => ({
    apiVersion: state.config.API_VERSION || '',
    webVersion: pkg.version,
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps);
const enhance = compose(
    withStyles(styles, { name: 'Footer' }),
    withTranslation(),
    connector,
);

export default enhance(Footer);
