// @flow weak
import SimpleDropdown from '../../../../shared/view/ui/SimpleDropdown';

/**
 * @class (View) TargetDropdown
 * @constructor
 */
const TargetDropdown = SimpleDropdown.extend({

    /**
     * @method attributes
     */
    attributes: {
        'data-target': 'targetDropdown',
    },

    initialize(...args: Array<any>) {
        this._super(...args);

        this.localisation = GW.localisation.tickets.tickets.details.widgets;

        this.menuSize = Number.MAX_VALUE;
        this.searchable = true;
        this.multiselect = false;

        this.setFilter('title');
        this.setMenuLabel('title');

        this.render();
    },
});

export default TargetDropdown;
