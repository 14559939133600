// @flow
export default (theme: Object) => ({
    form: {
        width: '100%',
    },
    questionList: {},
    question: {
        marginBottom: theme.spacing(1),
    },
    title: {
        ...theme.typography.headline,
    },
    location: {
        minHeight: 60,
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
    },
    description: {
        '@global': {
            ol: {
                margin: [['1em', 0]],
                paddingLeft: '16px',
                listStyleType: 'decimal',
            },
            ul: {
                margin: [['1em', 0]],
                paddingLeft: '16px',
                listStyleType: 'disc',
            },
        },
    },
    dates: {
        display: 'flex',
        fontSize: 16,
    },
    rightArrowIcon: {
        margin: theme.spacing(0, 0.5),
    },
    adminTools: {
        padding: theme.spacing(2),
        background: '#e5eaed',
    },
    group: {
        width: '100%',
        '&:not(:last-child)': {
            marginBottom: theme.spacing(1),
        },
    },
    priceSummary: {},
    priceSummaryItem: {
        justifyContent: 'space-between',
        fontFamily: 'ProximaNova',

        '&:last-child': {
            fontWeight: 600,
            fontSize: 20,
        },
    },
    gigPrice: {
        alignItems: 'flex-end',
    },
    gigPriceInput: {
        width: 80,

        '& input': {
            textAlign: 'right',
        },
    },
    priceTotal: {
        '&$overBalance': {
            color: '#cd1e3d',
        },
    },
    overBalance: {},
    chipList: {
        padding: theme.spacing(0.5, 0.5, 0),
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    workerSummaryItem: {
        marginBottom: theme.spacing(0.5),
    },
});
