// @flow
export default (theme: Object) => ({
    root: {},
    icon: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        border: 0,
        lineHeight: 'normal',
        cursor: 'pointer',
        display: 'inline-block',
        verticalAlign: 'middle',
        padding: '10px',
    },
    active: {
        '&$dateInput': {
            '&::before': {
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                bottom: 'auto',
                border: '10px solid transparent',
                borderBottom: '10px solid black',
                borderTop: 0,
                left: '66px',
                zIndex: 2,
                top: '62px',
                borderBottomColor: 'rgba(0, 0, 0, 0.1)',
            },
            '&::after': {
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                bottom: 'auto',
                border: '10px solid transparent',
                borderBottom: '10px solid black',
                borderTop: 0,
                left: '66px',
                zIndex: 2,
                top: '63px',
                borderBottomColor: '#fff',
            },
        },
    },
    dateInput: {},
});
