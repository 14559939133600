// @flow
export const EMAIL_DATA = 'g/actionToolbar/EMAIL_DATA';
export const EMAIL_DATA_SUCCESS = `${EMAIL_DATA}_SUCCESS`;
export const EMAIL_DATA_ERROR = `${EMAIL_DATA}_ERROR`;

export const REFRESH = 'g/actionToolbar/REFRESH';
export const REFRESH_SUCCESS = `${REFRESH}_SUCCESS`;
export const REFRESH_ERROR = `${REFRESH}_ERROR`;

export default {
    EMAIL_DATA,
    EMAIL_DATA_ERROR,
    EMAIL_DATA_SUCCESS,
    REFRESH,
    REFRESH_ERROR,
    REFRESH_SUCCESS,
};
