// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import types from './types';
import typeof { fetchAPIConfigSuccess } from './actions';

export type State = {
    API_VERSION: ?string,
    CORE_EXPIRY_GRACE_SECONDS: number,
    MINIMUM_ANDROID_VERSION: ?string,
    MINIMUM_IOS_VERSION: ?string,
};

export const init: State = {
    API_VERSION: null,
    CORE_EXPIRY_GRACE_SECONDS: 1296000, // 15 days
    MINIMUM_ANDROID_VERSION: null,
    MINIMUM_IOS_VERSION: null,
};

export default handleActions({
    [types.FETCH_API_CONFIG_SUCCESS]: (state: State, action: ActionType<fetchAPIConfigSuccess>): State => {
        const {
            core_expiry_grace_seconds: CORE_EXPIRY_GRACE_SECONDS,
            minimum_android_version: MINIMUM_ANDROID_VERSION,
            minimum_ios_version: MINIMUM_IOS_VERSION,
            version: API_VERSION,
        } = action.payload;

        return {
            ...state,
            API_VERSION,
            CORE_EXPIRY_GRACE_SECONDS,
            MINIMUM_ANDROID_VERSION,
            MINIMUM_IOS_VERSION,
        };
    },
}, init);
