// @flow
import React, { Children, cloneElement } from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import type { ChildrenArray } from 'react';
import CallToActionText from '../CallToActionText';
import CallToActionButton from '../CallToActionButton';
import styles from './styles';

type Props = {
    children: ChildrenArray<any>,
    className?: string,
    classes: Object,
    variant?: 'error' | 'info' | 'success' | 'warning',
}

export const CallToAction = (props: Props): React$Element<any> => {
    const { children, classes, variant, ...other } = props;
    const className = cx(other.className, {
        [classes.error]: variant === 'error',
        [classes.info]: variant === 'info',
        [classes.success]: variant === 'success',
        [classes.warning]: variant === 'warning',
    });

    return (
        <div {...other} className={cx(className, classes.root)}>
            {Children.map(children, (child: any) => {
                const elementType = child ? child.type : null;
                switch (elementType) {
                    case CallToActionText:
                        return cloneElement(child, { className: classes.text });
                    case CallToActionButton:
                        return cloneElement(child, { className: classes.button });
                    default:
                        return child;
                }
            })}
        </div>
    );
};

export default withStyles(styles, { name: 'CallToAction' })(CallToAction);
