// @flow
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    IconButton,
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import type { ChildrenArray } from 'react';
import UserAvatar from '../../../../../../components/UserAvatar';
import getDisplayName from '../../../../../../util/getDisplayName';
import styles from './styles';

type State = {};
type Props = {
    id: string,
    author: Object,
    children: ChildrenArray<any>,
    classes: Object,
    date: string,
    onDelete?: (id: string) => void,
};

export class Comment extends Component<Props, State> {
    handleDelete = () => {
        const { id, onDelete } = this.props;
        if (typeof onDelete === 'function') {
            onDelete(id);
        }
    };

    render() {
        const { author, children, classes, date, onDelete } = this.props;
        const displayName = getDisplayName(author);
        const title = (
            <Fragment>
                <span className={classes.author}>
                    {author.profile_link
                        ? <Link to={author.profile_link}>{displayName}</Link>
                        : displayName
                    }
                </span>
                <span className={classes.date}>
                    {moment(date).fromNow()}
                </span>
            </Fragment>
        );

        return (
            <Card elevation={0}>
                <CardHeader
                  classes={{
                      action: classes.action,
                      avatar: classes.avatar,
                      subheader: classes.content,
                      title: classes.header,
                  }}
                  avatar={<UserAvatar user={author} />}
                  action={typeof onDelete === 'function'
                      ? (
                          <IconButton onClick={this.handleDelete}>
                              <DeleteIcon />
                          </IconButton>
                      )
                      : null
                  }
                  title={title}
                  subheader={children}
                />
            </Card>
        );
    }
}

export default withStyles(styles, { name: 'Comment' })(Comment);
