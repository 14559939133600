// @flow
export default () => ({
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    content: {
        backgroundColor: 'rgb(0, 43, 54)', // matches the default theme for JSONTree
        height: 500,
    },
});
