// @flow
/* eslint-disable import/no-commonjs, global-require, no-bitwise */
import { Handlebars } from 'handlebars-template-loader';
import logger from '../../../common/util/logger';

type Options = {
    hash: Object,
    fn: Function,
    inverse: Function,
};

/**
 * Handlebars helpers</br>
 *
 * @class (Plugins) Handlebars
 * @constructor
 */

/**
 * @method rolesHelper (registerHelper)
 * @param role
 * @param options
 */
Handlebars.registerHelper('roles', function rolesHelper(role: string, options: Options): mixed {
    if (!role) {
        logger.trace('role is not defined'); // eslint-disable-line no-console
        return;
    }

    const roles = role.split(',');
    const withAccess = options.hash.for.split(',');

    for (let i = 0, len = withAccess.length; i < len; i += 1) {
        let roleWithAccess = withAccess[i];

        // ignore stray commas
        if (roleWithAccess !== undefined && roleWithAccess.length) {
            // remove whitespace
            roleWithAccess = roleWithAccess.replace(' ', '');

            if (roles.indexOf(roleWithAccess) > -1) {
                return options.fn(this);
            }
        }
    }

    return '';
});

/**
 * @method isString
 */
Handlebars.registerHelper('isString', function isStringHelper(item: mixed, options: Options): mixed {
    if (_.isString(item)) {
        return options.fn(this);
    }
    return options.inverse(this);
});

/**
 * @method canIterate
 */
Handlebars.registerHelper('canIterate', function canIterateHelper(item: mixed, options: Options): mixed {
    if (_.isObject(item) || _.isArray(item)) {
        return options.fn(this);
    }
    return options.inverse(this);
});

/**
 * @method isObject
 */
Handlebars.registerHelper('isObject', function isObjectHelper(item: mixed, options: Options) {
    if (_.isObject(item) && !_.isArray(item)) {
        return options.fn(this);
    }
    return options.inverse(this);
});

/**
* @method nl2br (registerHelper)
* @param text
* converts line break in to <br>
*/
Handlebars.registerHelper('nl2br', (text: string): string => {
    const nl2br = (`${text}`).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
    return new Handlebars.SafeString(nl2br);
});

/**
 * @method dynamicTarget (registerHelper)
 * @param text
 * change the name of the target if this is dynamic
 */
Handlebars.registerHelper('dynamicTarget', (text: string) => {
    let result = text;
    if (!text || text.indexOf('Dynamic Target') > -1) {
        result = '';
    }
    return result;
});

/**
 * @method uniqueID (registerHelper)
 * @param data
 * @param name
 */
Handlebars.registerHelper('uniqueID', (p = '') => (
    `${(p) + String.fromCharCode(~~(Math.random() * 100), ~~(Math.random() * 100), ~~(Math.random() * 100))}_${(new Date()).getTime()}${~~(Math.random())}`
));

export default Handlebars;
