// @flow
export default (theme: Object) => ({
    root: {
        alignItems: 'flex-start',
        marginBottom: theme.spacing(1),
    },
    label: {
        order: 1,
        color: theme.palette.grey[500],
        textAlign: 'right',
        textTransform: 'uppercase',
        marginBottom: theme.spacing(0.5),

        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
        },
    },
    content: {
        order: 2,

        [theme.breakpoints.down('xs')]: {
            order: 3,
        },
    },
    status: {
        order: 3,

        [theme.breakpoints.down('xs')]: {
            textAlign: 'right',
            order: 2,
        },
    },
    errorIcon: {
        color: '#cd1e3d',
    },
});
