// @flow
import { createSelector } from 'reselect';
import {
    AssignmentReturn as AssignmentReturnIcon,
    Cancel as CancelIcon,
    Check as CheckIcon,
    Gavel as GavelIcon,
    HourglassEmpty as HourglassEmptyIcon,
    Info as InfoIcon,
    Print as PrintIcon,
} from '@material-ui/icons';
import { STATUS } from '../../../../../../browser/shared/constant/TicketConstant';
import { USER_ROLES } from '../../../../../../browser/shared/constant/UserRoles';
import { getAvailableActions, getPermittedActions, getTicket } from '../../../duck/selectors';
import type { State as RootState } from '../../../../../redux/initialState';

const getActiveUser = (state: RootState): ?Object => state.session.user;

// Use depth-first search to traverse skip logic tree and visit each child
const traverseSkipLogic = (node: Object, visitCallback: (node: Object) => void) => {
    visitCallback(node);

    if (node.children) {
        node.children.forEach((expr: Object) => {
            const children = expr.$cond[1];
            const altChildren = expr.$cond[2];

            if (children) {
                children.forEach((d) => traverseSkipLogic(d, visitCallback));
            }
            if (altChildren) {
                altChildren.forEach((d) => traverseSkipLogic(d, visitCallback));
            }
        });
    }
};

export { getTicket } from '../../../duck/selectors';

export const getProgress = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    getTicket,
    (ticket: ?Object): number => {
        if (!ticket) {
            return 0;
        }

        const {
            data_type_map: dataTypeMap,
            data_types: dataTypes,
            data_items: dataItems,
        } = ticket;

        let total = 0;
        let answered = 0;
        const callback = (node: Object) => {
            const {
                data_type_id: dataTypeId,
                observation_target_id: observationTargetId,
                template_id: templateId,
            } = node;

            // @todo: Add support for bulk barcode and self-directed templates
            if (dataTypeId == null) {
                return;
            }

            // Only consider required questions when calculating progress
            const dataType = dataTypeMap[dataTypeId];
            if (!dataType.is_required) {
                return;
            }

            const dataItem = dataItems.find((di: Object): boolean => (
                di.data_type_id === dataTypeId
                && di.observation_target_id === observationTargetId
                && (templateId ? di.template_id === templateId : true)
            ));

            total += 1;
            if (dataItem && dataItem.data_item_answered) {
                answered += 1;
            }
        };

        dataTypes.forEach((d: Object) => traverseSkipLogic(d, callback));
        return total > 0 ? Math.round((answered / total) * 100) : 0;
    }
);

export const getCallToAction = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    getTicket,
    getActiveUser,
    getAvailableActions,
    (ticket: ?Object, user: ?Object, availableActions: string[]): ?Object => {
        if (!ticket || !user) {
            return null;
        }

        switch (ticket.status) {
            case STATUS.UNASSIGNED: {
                if (availableActions.includes('assign')) {
                    return {
                        action: null,
                        icon: null,
                        key: 'assignGig',
                        variant: 'warning',
                    };
                }

                // Although other user roles are allowed to opt-in and apply/withdraw from gigs,
                // we only want to display a call-to-action for worker
                if (user.role === USER_ROLES.WORKER) {
                    switch (true) {
                        case availableActions.includes('apply'):
                            return {
                                action: 'apply',
                                icon: null,
                                key: 'applyToGig',
                                variant: 'warning',
                            };
                        case availableActions.includes('withdraw'):
                            return {
                                action: 'withdraw',
                                icon: null,
                                key: 'withdrawFromGig',
                                variant: 'warning',
                            };
                        case availableActions.includes('optIn'):
                            return {
                                action: 'optIn',
                                icon: null,
                                key: 'optIntoGig',
                                variant: 'warning',
                            };
                        default:
                            break;
                    }
                }

                return null;
            }

            case STATUS.SUBMITTED: {
                switch (true) {
                    case availableActions.includes('approve'):
                    case availableActions.includes('reject'):
                        return {
                            action: 'review',
                            icon: GavelIcon,
                            key: 'reviewGig',
                            variant: 'warning',
                        };
                    case availableActions.includes('reopen'):
                        return {
                            action: 'reopen',
                            icon: CheckIcon,
                            key: 'gigCompleted',
                            variant: 'success',
                        };
                    default:
                        if (ticket.approval_status === 'APPROVED') {
                            return {
                                action: null,
                                icon: CheckIcon,
                                key: 'gigApproved',
                                variant: 'success',
                            };
                        }
                        return null;
                }
            }

            default:
                return null;
        }
    }
);

export const getMenuActions = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    getPermittedActions,
    getAvailableActions,
    (permittedActions: string[], availableActions: string[]): Object[] => {
        const actions = [];

        if (permittedActions.includes('cancel')) {
            actions.push({
                name: 'cancel',
                icon: CancelIcon,
                disabled: !availableActions.includes('cancel'),
            });
        }

        if (permittedActions.includes('reopen')) {
            actions.push({
                name: 'reopen',
                icon: AssignmentReturnIcon,
                disabled: !availableActions.includes('reopen'),
            });
        }

        if (permittedActions.includes('extendReservationWindow')) {
            actions.push({
                name: 'extendReservationWindow',
                icon: HourglassEmptyIcon,
                disabled: !availableActions.includes('extendReservationWindow'),
            });
        }

        actions.push(
            { name: 'print', icon: PrintIcon },
            { name: 'ticketInfo', icon: InfoIcon },
        );

        return actions;
    }
);

export default {
    getCallToAction,
    getMenuActions,
    getProgress,
    getTicket,
};
