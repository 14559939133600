// @flow weak
import Template from './Template';
import Component from '../../../../shared/view/Component';

const BulkBarcode = Component.extend({

    events: {},

    initialize(options: Object) {
        this.props = options.props;
        this.targets = _.isArray(options.targets) ? options.targets : [];
        this.dataTypes = _.isArray(options.dataTypes) ? options.dataTypes : [];
        this.templateID = parseInt(options.templateID, 10) || 0;
        this.ticketID = parseInt(options.ticketID, 10) || 0;
        this.readMode = !!options.readMode;

        this.text = GW.localisation.tickets.tickets.details.widgets;

        this.templates = [];

        this.render();
    },

    render() {
        this.$el.hide();

        this.$el.html($('<div data-target="templates"></div>'));

        const $templates = this.$el.find('[data-target="templates"]');

        this.$els = {
            templates: $templates,
        };

        _.each(this.targets, (target: { id: number }) => {
            const widget = new Template({
                ticketID: this.ticketID,
                templateID: this.templateID,
                targetID: target.id,
                instructions: this.dataTypes,
                readMode: this.readMode,
                props: { ...this.props },
            });

            this.templates.push(widget);
            this.$els.templates.append(widget.$el);

            widget.onSave = (...args: Array<any>) => {
                this.onSave(...args);
            };
        }, this);

        this.$el.show();
    },

    toggleReadMode(bool: boolean) {
        this.readMode = bool;
        this.render();
    },
});

export default BulkBarcode;
