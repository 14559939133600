// @flow
/* global google */
import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { ListItem, ListItemSecondaryAction, ListItemText, IconButton } from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';
import { Download as DownloadIcon, Upload as UploadIcon } from 'mdi-material-ui';
import { Marker } from 'react-google-maps';
import { Field, defaultShouldValidate, formValueSelector, getFormInitialValues, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import type { $AxiosError } from 'axios';
import type { Dispatch } from 'redux';
import type { FormProps } from 'redux-form';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { TFunction } from 'react-i18next';
import type { Location as Gigwalk$Location, LocationList } from 'gigwalk/lib/api/locationLists/types';
import type { Subscription } from 'gigwalk/lib/api/subscriptions/types';
import LocationListUpload from '../../../../../browser/shared/components/LocationListUpload';
import waitForCondition from '../../../../components/waitForCondition';
import Autocomplete from '../../../../components/Autocomplete';
import GoogleMap from '../../../../components/GoogleMap';
import InfiniteList from '../../components/InfiniteList';
import Step from '../../components/Step';
import entitySelector from '../../../../redux/entities/entitySelector';
import getValidationRule from '../../utils/getValidationRule';
import { actions, selectors } from './duck';
import * as dialog from '../../../../ducks/dialog';
import * as snackbar from '../../../../ducks/snackbar';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';
import type { LocationRelation } from '../../../../redux/entities/locationLists';

type UnresolvedLocation = {
    id: number,
    state: string,
    address: string,
    title: string,
};

type NewLocation = {
    formatted_address: string,
    title: string,
    latitude?: number,
    longitude?: number,
};

type OwnProps = ContextRouter & FormProps & {
    t: TFunction,
};

type StateProps = {
    displayUploadNotification: boolean,
    editMode: boolean,
    isLocationListUploadVisible: boolean,
    isUnresolvedLocationsVisible: boolean,
    listId: number,
    locationList: ?LocationList,
    locations: Array<LocationRelation>,
    pendingAddition: Array<Object>,
    pendingRemoval: Array<number>,
    selected: number,
    subscription: ?Subscription,
    unresolvedLocations: Array<UnresolvedLocation>,
};

type DispatchProps = {
    select: (index: number) => void,
    getNextPage: (locationListId: number) => void,
    enqueueSnackbar: (message: string, options: Object) => void,
    fetchList: (locationListId: number) => void,
    createList: (listName: string, location: NewLocation) => void,
    addToList: (locationListId: number, location: NewLocation) => void,
    removeFromList: (locationListId: number, relationId: number) => void,
    hideLocationListUpload: () => void,
    showLocationListUpload: () => void,
    hideUnresolvedLocations: () => void,
    toggleUploadNotification: (enable: boolean) => void,
    openDialog: (name: string, props: Object) => void,
};

type Props = OwnProps & StateProps & DispatchProps;

const DeferredGoogleMap = waitForCondition(() => global.hasOwnProperty('google'), 100)(GoogleMap);

export class Locations extends Component<Props> {
    componentDidMount() {
        const { getNextPage, history, listId, location, locationList, locations } = this.props;
        const lastPage = history.location.state ? history.location.state.lastPage : false;
        if (!lastPage) {
            history.replace({ ...location, state: { lastPage: true } });
        }

        const locationCount = locationList ? locationList.location_count : 0;
        if (listId && locations.length === 0 && locationCount > 0) {
            getNextPage(listId);
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        const { history, location } = nextProps;
        const lastPage = history.location.state ? history.location.state.lastPage : false;
        if (!lastPage) {
            history.replace({ ...location, state: { lastPage: true } });
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { enqueueSnackbar, fetchList, listId, toggleUploadNotification } = this.props;
        const { listId: prevListId } = prevProps;

        if (listId > 0 && prevListId !== listId) {
            fetchList(listId);
        }

        const { t, locationList, unresolvedLocations, displayUploadNotification } = this.props;
        if (locationList != null && unresolvedLocations.length > 0 && displayUploadNotification) {
            const msgPart1 = t('projectBuilder.locations.locationsAddedNotification', { count: locationList.location_count || 0 });
            const msgPart2 = t('projectBuilder.locations.unresolvedLocationsNotification', { count: unresolvedLocations.length });
            const message = `${msgPart1} ${msgPart2}`;
            enqueueSnackbar(message, { variant: 'warning' });
            toggleUploadNotification(false);
        }
    }

    componentWillUnmount() {
        const { hideLocationListUpload } = this.props;
        hideLocationListUpload();
    }

    handleAddNewLocation = (predication: Object) => {
        const { addToList, createList, enqueueSnackbar, locationList, listId, subscription, t } = this.props;
        let location = null;

        const promise = new Promise((resolve, reject) => {
            const service = new google.maps.places.PlacesService(document.createElement('div'));
            const params = { placeId: predication.place_id };
            const callback = (place: Object, status: string) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) resolve(place);
                else reject(status);
            };
            service.getDetails(params, callback);
        });

        promise
            .then((place: Object) => {
                location = {
                    formatted_address: place.formatted_address,
                    title: place.formatted_address,
                    latitude: place.geometry.location.lat,
                    longitude: place.geometry.location.lng,
                };

                if (subscription && !locationList) {
                    const listName = `${subscription.title} ${t('projectBuilder.locations.defaultListName')}`;
                    return createList(listName, location);
                }

                return addToList(listId, location);
            })
            .then(() => {
                const address = location ? location.formatted_address : '';
                const message = t('projectBuilder.locations.locationAdded', { location: address });
                enqueueSnackbar(message, { variant: 'success' });
            })
            .catch((err: $AxiosError<*>) => {
                const message = err.response && err.response.data && err.response.data.message
                    ? err.response.data.message
                    : err.message;
                enqueueSnackbar(message, { variant: 'error' });
            });
    };

    handleSelectLocation = (event: SyntheticEvent<any>) => {
        const { index } = event.currentTarget.dataset;
        const { select } = this.props;
        select(index);
    };

    handleDeleteLocation = (event: SyntheticEvent<any>) => {
        const { enqueueSnackbar, locationList, removeFromList, t } = this.props;
        const { address, relationId } = event.currentTarget.dataset;
        if (relationId) {
            removeFromList(locationList.id, relationId)
                .then(() => {
                    const message = t('projectBuilder.locations.locationRemoved', { location: address });
                    enqueueSnackbar(message, { variant: 'success' });
                })
                .catch(() => {});
        }
    };

    handleClickUpload = () => {
        const { closeDialog, locations, openDialog, showLocationListUpload, t } = this.props;
        if (locations.length > 0) {
            const dialogProps = {
                actions: [
                    {
                        label: t('projectBuilder.locations.no'),
                        onClick: () => closeDialog(),
                    },
                    {
                        label: t('projectBuilder.locations.yes'),
                        onClick: () => {
                            closeDialog();
                            showLocationListUpload();
                        },
                    },
                ],
                content: t('projectBuilder.locations.uploadWarning'),
                onClose: () => closeDialog(),
                title: t('projectBuilder.locations.uploadNewList'),
            };
            openDialog('confirm', dialogProps);
        } else {
            showLocationListUpload();
        }
    };

    handleDeleteAll = () => {
        const { closeDialog, deleteList, openDialog, t } = this.props;
        const dialogProps = {
            actions: [
                {
                    label: t('projectBuilder.locations.no'),
                    onClick: () => closeDialog(),
                },
                {
                    label: t('projectBuilder.locations.yes'),
                    onClick: () => {
                        closeDialog();
                        deleteList();
                    },
                },
            ],
            content: t('projectBuilder.locations.confirmDelete'),
            onClose: () => closeDialog(),
            title: t('projectBuilder.locations.deleteAllLocations'),
        };
        openDialog('confirm', dialogProps);
    };

    handleStartUpload = () => {
        // todo: disable back/next buttons
    };

    handleCancelUpload = () => {
        const { hideLocationListUpload } = this.props;
        hideLocationListUpload();
    };

    handleUploadComplete = (listId: number) => {
        const { change, fetchList, hideLocationListUpload, toggleUploadNotification } = this.props;
        hideLocationListUpload();
        fetchList(listId);
        change('locationListId', listId, false, false);
        toggleUploadNotification(true);
    };

    search = (query: string): Promise<Object[]> => {
        const promise = new Promise((resolve, reject) => {
            const service = new google.maps.places.AutocompleteService();
            const params = {
                input: query,
                types: ['geocode'],
            };
            const callback = (predictions: ?Object[], status: string) => {
                switch (status) {
                    case google.maps.places.PlacesServiceStatus.OK:
                    case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
                        resolve(predictions || []);
                        break;

                    default:
                        reject(status);
                        break;
                }
            };
            service.getPlacePredictions(params, callback);
        });

        return promise
            .then((places: Object[]) => (
                places.map((prediction: Object) => ({
                    label: prediction.description,
                    value: prediction,
                }))
            ));
    };

    handleInfiniteLoad = (): Promise<void> => {
        const { getNextPage, listId } = this.props;
        return listId ? getNextPage(listId) : Promise.resolve();
    };

    renderUnresolvedLocations(): React$Element<any> {
        const { t, isUnresolvedLocationsVisible, unresolvedLocations, hideUnresolvedLocations } = this.props;
        const style = isUnresolvedLocationsVisible
            ? 'c-selected-block c--white c--with-transition c-alert c--error u-margin-bottom-lg'
            : 'c-selected-block c--with-transition c-alert c--error c--closed';

        return (
            <div className={style}>
                <div className="c-selected-block__transition-content">
                    <a className="c-close-button" role="button" tabIndex={-1} onClick={() => { hideUnresolvedLocations(); }}>
                        {t('projectBuilder.locations.close')}
                    </a>
                    <p>{t('projectBuilder.locations.unresolvedLocations')}</p>
                    <ul className="c-selected-block" style={{ maxHeight: '150px', overflowY: 'scroll' }}>
                        {unresolvedLocations.map((location: UnresolvedLocation, index: number): React$Element<any> => (
                            <li key={index}>{location.address}</li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }

    renderControls(): ?React$Element<any> {
        const { classes, t, editMode } = this.props;

        if (!editMode) {
            return (
                <div className={classes.controls}>
                    <a className={classes.control} onClick={this.handleClickUpload}>
                        <UploadIcon />{t('projectBuilder.locations.uploadLocationList')}
                    </a>
                    <a className={classes.control} href="/public/gigwalk_apps_location_upload_template.xlsx">
                        <DownloadIcon />{t('projectBuilder.locations.downloadTemplate')}
                    </a>
                </div>
            );
        }
    }

    render() {
        const {
            classes,
            editMode,
            isLocationListUploadVisible,
            locationList,
            locations,
            selected,
            subscription,
            t,
        } = this.props;

        if (isLocationListUploadVisible) {
            // todo: check if subscription exists. Use something like moniker to generate a temporary name?
            const listName = locationList
                ? locationList.name
                : `${subscription.title} ${t('projectBuilder.locations.defaultListName')}`;

            return (
                <Step title={t('projectBuilder.locations.header')}>
                    <LocationListUpload
                      listName={listName}
                      onCancel={this.handleCancelUpload}
                      onComplete={this.handleUploadComplete}
                      onUpload={this.handleStartUpload}
                    />
                </Step>
            );
        }

        const selectedLocation = locations[selected];
        let mapMarker = null;
        let mapCenter = null;
        if (selectedLocation) {
            const { latitude, longitude } = selectedLocation;
            mapMarker = {
                lat: parseFloat(latitude),
                lng: parseFloat(longitude),
            };
            mapCenter = mapMarker;
        } else {
            // This centers the map on San Francisco
            mapCenter = {
                lat: 37.7918928,
                lng: -122.3940939,
            };
        }

        return (
            <Step title={t('projectBuilder.locations.header')}>
                <form className={classes.form}>
                    {this.renderUnresolvedLocations()}
                    {!editMode
                        ? (
                            <Autocomplete
                              onChange={this.handleAddNewLocation}
                              placeholder={t('projectBuilder.locations.placeholder')}
                              suggestionMatch={this.search}
                            />
                        )
                        : null
                    }
                    <DeferredGoogleMap center={mapCenter} zoom={14} height={200}>
                        {mapMarker ? <Marker position={mapMarker} /> : null}
                    </DeferredGoogleMap>
                    <div>
                        {locations.length > 0
                            ? <label htmlFor="searchFilter">{t('projectBuilder.locations.projectAddress', { count: locationList.location_count })}</label>
                            : null
                        }
                        <div className={classes.list}>
                            {locations.length > 0 && !editMode
                                ? <a className={classes.deleteAll} onClick={this.handleDeleteAll}>{t('projectBuilder.locations.deleteAll')}</a>
                                : null
                            }
                            <InfiniteList maxHeight={500} rowHeight={70} onInfiniteLoad={this.handleInfiniteLoad}>
                                {locations.map((location: Gigwalk$Location, index: number) => (
                                    <ListItem
                                      button
                                      classes={{
                                          container: cx(classes.listItemContainer, { [classes.selected]: selected === index }),
                                          root: classes.listItem,
                                          selected: classes.selected,
                                      }}
                                      ContainerComponent="div"
                                      data-index={index}
                                      disableRipple
                                      key={location.relation_id}
                                      onClick={this.handleSelectLocation}
                                      selected={selected === index}
                                    >
                                        <ListItemText
                                          primary={location.title}
                                          secondary={location.title !== location.formatted_address ? location.formatted_address : null}
                                          primaryTypographyProps={{ color: 'inherit' }}
                                          secondaryTypographyProps={{ color: 'inherit' }}
                                        />
                                        {!editMode
                                            ? (
                                                <ListItemSecondaryAction>
                                                    <IconButton
                                                      color="inherit"
                                                      data-address={location.formatted_address}
                                                      data-relation-id={location.relation_id}
                                                      onClick={this.handleDeleteLocation}
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            )
                                            : null
                                        }
                                    </ListItem>
                                ))}
                            </InfiniteList>
                            {this.renderControls()}
                        </div>
                    </div>
                    <Field
                      name="locationListId"
                      component="input"
                      type="hidden"
                      validate={getValidationRule('locationListId')}
                    />
                </form>
            </Step>
        );
    }
}

const valueSelector = formValueSelector('projectBuilder');
const subscriptionSelector = entitySelector('subscriptions');
const locationListSelector = entitySelector('locationLists');

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const { match } = props;
    const initialValues = {
        ...getFormInitialValues('projectBuilder')(state),
        ...selectors.getInitialValues(state, props),
    };
    const locationListId = valueSelector(state, 'locationListId') || -1;
    const subscription = subscriptionSelector(state, match.params.subscriptionId);
    return {
        ...state.projectBuilder.locations,
        editMode: subscription ? subscription.state === 'ACTIVE' : false,
        initialValues,
        listId: locationListId,
        locationList: locationListSelector(state, locationListId),
        locations: selectors.getLocations(state, props),
        subscription,
        unresolvedLocations: selectors.getUnresolvedLocations(state, props),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    select: (index: number) => dispatch(actions.select(index)),
    getNextPage: (locationListId: number) => dispatch(actions.getNextPage(locationListId)),
    enqueueSnackbar: (message: string, options: Object) => dispatch(snackbar.actions.enqueue(message, options)),
    fetchList: (locationListId: number) => dispatch(actions.fetchList(locationListId)),
    createList: (listName: string, location: NewLocation) => dispatch(actions.createList(listName, location)),
    addToList: (locationListId: number, location: NewLocation) => dispatch(actions.addToList(locationListId, location)),
    removeFromList: (locationListId: number, relationId: number) => dispatch(actions.removeFromList(locationListId, relationId)),
    hideLocationListUpload: () => dispatch(actions.hideLocationListUpload()),
    showLocationListUpload: () => dispatch(actions.showLocationListUpload()),
    hideUnresolvedLocations: () => dispatch(actions.hideUnresolvedLocations()),
    toggleUploadNotification: (enable: boolean) => dispatch(actions.toggleUploadNotification(enable)),
    openDialog: (name: string, props: Object) => dispatch(dialog.actions.open(name, props)),
    closeDialog: () => dispatch(dialog.actions.close()),
});

const shouldValidate = (params: Object): boolean => {
    if (params.initialRender) {
        return true;
    }

    const { locationList } = params.props;
    const { locationList: nextLocationList } = params.nextProps;

    // Run validation if the location count has changed
    const locationCount = locationList ? locationList.location_count : 0;
    const nextLocationCount = nextLocationList ? nextLocationList.location_count : 0;
    return defaultShouldValidate(params) || locationCount !== nextLocationCount || locationList === 0;
};

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'Locations' }),
    withTranslation(),
    connector,
    reduxForm({
        form: 'projectBuilder',
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        shouldValidate,
    })
);

export default enhance(Locations);
