// @flow

/**
 * @property USER_ROLES
 * @type {{SELF_SERVICE: string,PLATFORM_ADMIN: string, SUPER_ADMIN: string, ADMIN: string, OPERATOR: string, WORKER: string, ANALYST: string}}
 */
export const USER_ROLES = Object.freeze({
    SELF_SERVICE: 'SELF_SERVICE',
    PLATFORM_ADMIN: 'PLATFORM_ADMIN',
    SUPER_ADMIN: 'SUPER_ADMIN',
    ADMIN: 'ADMIN',
    OPERATOR: 'OPERATOR',
    WORKER: 'WORKER',
    ANALYST: 'ANALYST',
});

export const STAFFING_USER_ROLES: Array<string> = [
    USER_ROLES.SUPER_ADMIN,
    USER_ROLES.WORKER,
];

/**
 * @property USER_RANKS
 */
export const USER_RANKS: Array<string> = [
    USER_ROLES.PLATFORM_ADMIN,
    USER_ROLES.SELF_SERVICE,
    USER_ROLES.SUPER_ADMIN,
    USER_ROLES.ADMIN,
    USER_ROLES.OPERATOR,
    USER_ROLES.WORKER,
    USER_ROLES.ANALYST,
];

/**
 * @property ONLY_PLATFORM_ADMIN_SEES
 */
export const ONLY_PLATFORM_ADMIN_SEES: Array<string> = [
    USER_ROLES.PLATFORM_ADMIN,
    USER_ROLES.SELF_SERVICE,
];

/**
 * @property ADVANCED_USER_ROLES
 */
export const ADVANCED_USER_ROLES: Array<string> = [
    USER_ROLES.ADMIN,
    USER_ROLES.OPERATOR,
];

/**
 * @property GROUPS_ROLES
 * @type {{MEMBER: string, OWNER: string}}
 */
export const GROUPS_ROLES = Object.freeze({
    MEMBER: 'MEMBER',
    OWNER: 'OWNER',
});

/**
 * @property TPL
 * @type {{SELF_SERVICE: string,PLATFORM_ADMIN: string, SUPER_ADMIN: string, ADMIN: string, OPERATOR: string, WORKER: string, ANALYST: string}}
 */

export const TPL = {
    SELF_SERVICE: 'SS',
    PLATFORM_ADMIN: 'PA',
    SUPER_ADMIN: 'SA',
    ADMIN: 'Ad',
    OPERATOR: 'Op',
    WORKER: 'Wo',
    ANALYST: 'An',
};

export default {
    USER_ROLES,
    STAFFING_USER_ROLES,
    USER_RANKS,
    ONLY_PLATFORM_ADMIN_SEES,
    GROUPS_ROLES,
    TPL,
};
