// @flow
export default (theme: Object) => ({
    form: {
        padding: theme.spacing(3, 0, 5, 0),
    },
    label: {
        fontWeight: 600,
    },
    gridList: {
        marginTop: theme.spacing(1),
    },
    tileRoot: {
        '&[type="radio"]': {
            marginRight: 0,
            border: 'none',
        },

        '&.active $tile': {
            background: theme.palette.primary.light,

            '& $tileIcon': {
                color: theme.palette.common.white,
            },
            '& $tileCaption': {
                color: theme.palette.common.white,
            },
        },
    },
    tile: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: theme.shadows[2],
        padding: theme.spacing(1),
        borderRadius: 2,

        '&:before, &:after': {
            content: '""',
        },
    },
    tileIcon: {
        color: theme.palette.grey[500],
        width: '40%',
        height: '40%',
    },
    tileCaption: {
        color: theme.palette.grey[500],
        width: '100%',
        textAlign: 'center',
    },
});
