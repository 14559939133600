// @flow
export default (theme: Object) => ({
    action: {
        marginBottom: 'auto',
        marginTop: 6,
    },
    avatar: {
        marginBottom: 'auto',
        marginTop: 6,
    },
    header: {
        fontFamily: ['ProximaNova', 'sans-serif'],
        fontSize: 15,
    },
    content: {
        fontFamily: ['ProximaNova', 'sans-serif'],
        fontSize: 16,
        wordBreak: 'break-word',
    },
    author: {
        marginRight: theme.spacing(1),
        fontWeight: 600,
    },
    date: {
        color: 'rgb(170, 170, 170)',
    },
});
