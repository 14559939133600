// @flow
// $FlowIssue need to update to a more recent flow version
import React, { forwardRef } from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import { Button, CircularProgress, Fab } from '@material-ui/core';
import type { ChildrenArray } from 'react';

type Props = {
    children: ChildrenArray<any>,
    classes: Object,
    disabled?: boolean,
    submitting?: boolean,
    variant?: string,
};

const styles = () => ({
    fabProgress: {
        position: 'absolute',
        top: -2,
        left: -2,
        zIndex: 1,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

export const SubmitButton = forwardRef((props: Props, ref: Object) => {
    const { children, classes, disabled, submitting, variant, ...other } = props;
    const { fabProgress, buttonProgress, ...buttonClasses } = classes;

    const ButtonComponent = variant === 'fab' ? Fab : Button;
    const progressProps = {
        className: cx({
            [fabProgress]: variant === 'fab',
            [buttonProgress]: variant !== 'fab',
        }),
        size: variant === 'fab' ? 60 : 24,
    };

    return (
        <ButtonComponent
          classes={buttonClasses}
          disabled={submitting || disabled}
          ref={ref}
          {...(variant !== 'fab' ? { variant } : undefined)}
          {...other}
        >
            {children}
            {submitting ? <CircularProgress {...progressProps} /> : null}
        </ButtonComponent>
    );
});

export default withStyles(styles, { name: 'SubmitButton' })(SubmitButton);
