// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import types from './types';
import typeof { fetchAPIVersionSuccess } from './actions';

export type State = {
    apiVersion: string,
};

export const init: State = {
    apiVersion: '',
};

export default handleActions({
    [types.FETCH_API_VERSION_SUCCESS]: (state: State, action: ActionType<fetchAPIVersionSuccess>): State => ({
        ...state,
        apiVersion: action.payload,
    }),
}, init);
