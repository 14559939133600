// @flow
export default (theme: Object) => ({
    '@global': {
        body: {
            background: theme.palette.primary.main,
            height: 'auto',
            padding: theme.spacing(6, 0),
            textAlign: 'center',

            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(3.5, 1.5),
            },
        },
    },
    logo: {
        maxWidth: 270,
        marginBottom: theme.spacing(4),

        [theme.breakpoints.down('xs')]: {
            maxWidth: 200,
            marginBottom: theme.spacing(2),
        },
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: 400,
        margin: [[0, 'auto']],
        color: theme.palette.primary.contrastText,
        fontWeight: 300,
        lineHeight: 1.2,

        '& > *': {
            flex: 1,
            padding: theme.spacing(1.5, 0),
        },

        '& $link': {
            color: 'inherit',
            fontSize: 18,
            textDecoration: 'underline',
        },
    },
    link: {},
});
