// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Component, createRef } from 'react';
import moment from 'moment-timezone';
import cx from 'classnames';
import { DayPickerRangeController } from 'react-dates';
import { withStyles } from '@material-ui/styles';
import styles from './styles';
import theme from './theme.scss';

type State = {
    startDate: string,
    endDate: string,
};

type Props = {
    className?: string,
    classes: Object,
    disabled?: boolean,
    error?: string,
    focused?: boolean,
    focusedInput?: 'startDate' | 'endDate',
    format?: string,
    maxDate?: string | moment$Moment | Date,
    minDate?: string | moment$Moment | Date,
    onChange?: (value: { startDate: ?string, endDate: ?string }) => void,
    onFocus?: (focusedInput: 'startDate' | 'endDate') => void,
    onBlur?: () => void,
    placeholder?: string,
    updatePosition: () => void,
    value?: {
        endDate: string | moment$Moment | Date,
        startDate: string | moment$Moment | Date,
    },
};

const defaultControllerProps = {
    hideKeyboardShortcutsPanel: true,
    numberOfMonths: 1,
    showDefaultInputIcon: false,
};

class DayPicker extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { format, value } = this.props;
        const startDate = moment(value ? value.startDate || null : null, format);
        const endDate = moment(value ? value.endDate || null : null, format);

        this.state = {
            endDate: endDate.isValid() ? endDate.format() : '',
            startDate: startDate.isValid() ? startDate.format() : '',
        };

        // $FlowIssue need to update to more recent flow version
        this.containerRef = createRef();
    }

    componentDidMount() {
        // For whatever reason, the height of DayPickerRangeController is not known until
        // after the component has been rendered (and perhaps some state updated). As a result,
        // the Popover component doesn't have all the information needed to correctly position
        // itself at render time. We can call updatePosition here to fix any positioning issues.
        const { updatePosition } = this.props;
        updatePosition();
    }

    componentWillReceiveProps(nextProps: Props) {
        const { format, value } = this.props;
        let nextState = null;

        const startDate = value ? value.startDate || null : null;
        const endDate = value ? value.endDate || null : null;
        const nextStartDate = nextProps.value ? nextProps.value.startDate || null : null;
        const nextEndDate = nextProps.value ? nextProps.value.endDate || null : null;

        if (endDate !== nextEndDate || format !== nextProps.format) {
            const datetime = moment(nextEndDate, nextProps.format);
            nextState = {
                ...nextState,
                endDate: datetime.isValid() ? datetime.format() : '',
            };
        }

        if (startDate !== nextStartDate || format !== nextProps.format) {
            const datetime = moment(nextStartDate, nextProps.format);
            nextState = {
                ...nextState,
                startDate: datetime.isValid() ? datetime.format() : '',
            };
        }

        if (nextState) {
            this.setState(nextState);
        }
    }

    handleDatesChange = (dateRange: { startDate: ?moment$Moment, endDate: ?moment$Moment }) => {
        const { onChange, format } = this.props;
        const { startDate, endDate } = dateRange;
        const nextState = {
            startDate: startDate ? startDate.format() : '',
            endDate: endDate ? endDate.format() : '',
        };

        this.setState(nextState, () => {
            if (typeof onChange === 'function') {
                onChange({
                    startDate: startDate ? startDate.format(format) : null,
                    endDate: endDate ? endDate.format(format) : null,
                });
            }
        });
    };

    handleFocusChange = (focusedInput: ?('startDate' | 'endDate')) => {
        if (focusedInput) {
            this.handleFocus(focusedInput);
        } else {
            this.handleBlur();
        }
    };

    handleFocus = (focusedInput: 'startDate' | 'endDate') => {
        const { onFocus } = this.props;
        if (typeof onFocus === 'function') {
            onFocus(focusedInput);
        }
    };

    handleBlur = () => {
        const { onBlur } = this.props;
        if (typeof onBlur === 'function') {
            onBlur();
        }
    };

    render() {
        const { endDate, startDate } = this.state;
        const { error, focusedInput, className, classes, ...controllerProps } = this.props;
        const { disabled } = controllerProps;

        const containerClassName = cx(theme.dayPicker, classes.dayPicker, className, {
            invalid: !!error && !disabled,
            disabled,
        });

        return (
            // $FlowIssue need to update to more recent flow version
            <div className={containerClassName} ref={this.containerRef}>
                <DayPickerRangeController
                  {...defaultControllerProps}
                  {...controllerProps}
                  endDate={endDate ? moment(endDate) : null}
                  focusedInput={focusedInput}
                  onBlur={() => {}}
                  onDatesChange={this.handleDatesChange}
                  onFocusChange={this.handleFocusChange}
                  startDate={startDate ? moment(startDate) : null}
                />
            </div>
        );
    }
}

export default withStyles(styles, { name: 'DayPicker' })(DayPicker);
