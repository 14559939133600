// @flow
import { createSelector } from 'reselect';
import { getAPIParams, getTableData } from '../../../duck/selectors';

type LatLng = {
    lat: number,
    lng: number,
}

export { getAPIParams, getTableData } from '../../../duck/selectors';

export const getDefaultCenter = createSelector(
    getTableData,
    (data: Object[]): LatLng => (
        data.reduce((sum: LatLng, row: Object, index: number): LatLng => {
            const { location } = row;
            const latSum = sum.lat + location.latitude;
            const lngSum = sum.lng + location.longitude;

            return index === data.length - 1
                ? { lat: latSum / data.length, lng: lngSum / data.length }
                : { lat: latSum, lng: lngSum };
        }, { lat: 0, lng: 0 })
    )
);

export default {
    getAPIParams,
    getDefaultCenter,
    getTableData,
};
