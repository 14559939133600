// @flow
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import { format } from '../../../../browser/shared/util/gigwalkApiErrorUtil';
import { update as updateTickets } from '../../../redux/entities/tickets';
import * as snackbar from '../../../ducks/snackbar';
import types from './types';

export const submitError = createAction(types.SUBMIT_ERROR);
export const submitSuccess = createAction(types.SUBMIT_SUCCESS);
export const submit = createAction(
    types.SUBMIT,
    (values: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            // @todo Think about using normalize/format to store just the option value in state
            const { assignee, force, ticketIds } = values;
            const params = {
                customer_id: assignee.value.id,
                ticket_ids: ticketIds,
                action: 'assign',
                force: !!force,
            };

            return dispatch(updateTickets(params))
                .then((resp: Object) => {
                    dispatch(submitSuccess());
                    if (resp.original && resp.original.gw_api_response && resp.original.gw_api_response.length) {
                        const message = format(resp.original.gw_api_response);
                        dispatch(snackbar.actions.enqueue(message, { variant: 'warning' }));
                    }
                })
                .catch((err: $AxiosError<Object>) => {
                    dispatch(submitError(err));
                    const resp = err ? err.response : null;
                    if (resp && resp.data && resp.data.gw_api_response) {
                        const message = format(resp.data.gw_api_response);
                        dispatch(snackbar.actions.enqueue(message, { variant: 'error' }));
                    }
                    return Promise.reject(err);
                });
        }
    )
);


export default {
    submit,
    submitError,
    submitSuccess,
};
