// @flow
export default (theme: Object) => ({
    paper: {
        maxWidth: 400,
        maxHeight: 1000,
        backgroundColor: theme.palette.grey[100],
        padding: theme.spacing(4, 3),
        margin: [[0, 'auto', theme.spacing(3)]],

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(4, 2),
        },
    },
    error: {
        padding: theme.spacing.unit,
        border: [[2, 'solid', theme.palette.error.main]],
        color: theme.palette.error.main,
        marginBottom: theme.spacing.unit,
    },
    fieldset: {
        textAlign: 'left',
        margin: theme.spacing(0, 0, 3),

        '& > *:not(:last-child)': {
            marginBottom: theme.spacing(2),
        },
    },
    input: {
        backgroundColor: theme.palette.common.white,
    },
    submitButton: {
        boxShadow: ['none', '!important'],
        width: 180,
    },
});
