// @flow
export default (theme: Object) => ({
    input: {},
    outlined: {
        fontFamily: 'ProximaNova',
        borderRadius: 4,
        border: [[1, 'solid', '#cfd8df']],
        padding: theme.spacing(0.5, 1),

        '&:focus': {
            borderColor: '#cfd8df',
        },

        '&$disabled': {
            color: 'rgba(0, 0, 0, 0.48)',
            backgroundColor: '#e5eaed',
        },
    },
    disabled: {},
});
