// @flow
import { createSelector } from 'reselect';
import qs from 'qs';
import type { ContextRouter } from 'react-router';
import type { State as RootState } from '../../../redux/initialState';

export const getInitialValues = createSelector(
    <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): string => {
        const { location } = props;
        return location.search || '';
    },
    (search: string): Object => {
        const queryParams = search ? qs.parse(search, { ignoreQueryPrefix: true }) : {};
        const {
            email,
            token,
        } = queryParams;

        return {
            confirmPassword: '',
            email: email || '',
            password: '',
            token: token || '',
        };
    }
);

export default {
    getInitialValues,
};
