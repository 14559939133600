var Handlebars = require('/tmp/build_c41768ab/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<strong>Q"
    + container.escapeExpression(((helper = (helper = helpers.questionNumber || (depth0 != null ? depth0.questionNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"questionNumber","hash":{},"data":data}) : helper)))
    + "</strong>";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "      <div class=\"files\">\n        <!-- div \"attachment\" uses the same HTML as in Project Builder -->\n        <div class=\"attachment\">\n          <div class=\"plainLabel\">"
    + container.escapeExpression(((helper = (helper = helpers.attached_files || (depth0 != null ? depth0.attached_files : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"attached_files","hash":{},"data":data}) : helper)))
    + "</div>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.files : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div><!-- ends div \"attachment\" -->\n      </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <div class=\"attached-file\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n              <a href=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" class=\"filename\" data-action=\"download\"><span class=\"im icon-paperclip\"></span>"
    + alias4(((helper = (helper = helpers.file_name || (depth0 != null ? depth0.file_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"file_name","hash":{},"data":data}) : helper)))
    + "</a>\n              <a href=\"javascript:;\" class=\"remove\" data-action=\"remove-attachment\">"
    + alias4(((helper = (helper = helpers.remove || (depth0 != null ? depth0.remove : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"remove","hash":{},"data":data}) : helper)))
    + "</a>\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <div class=\"images\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.images : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <div class=\"attachment image\">\n            <img src=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" />\n            <span class=\"im icon-magnify\" data-url=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" data-action=\"magnify\"></span>\n          </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<!--\n\n  {\n    title: \"\",\n    body: \"\",\n    attachments: [\n      url: \"\"\n    ]\n\n  }\n\n-->\n\n<!-- -------------------- Hint --------------------- -->\n<div class=\"hint widget-panel\">\n  <!-- widget header -->\n  <div class=\"header\">\n    <div class=\"controls\">\n      <div class=\"ti\">\n        <span class=\"im icon-shield\"></span>\n          <span class=\"tip-title\">\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.questionNumber : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            <span>"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span>\n        </span>\n      </div>\n      <div data-action=\"collapse\" class=\"arrow\"><span class=\"im icon-arrow-up\"></span></div>\n      <div class=\"dragger\"><span class=\"im icon-empty\"></span></div>\n    </div>\n  </div>\n\n  <!-- widget content -->\n  <div class=\"widget-panel-content\" data-target=\"body\">\n    <div class=\"hint-copy u-break-word\">"
    + alias3((helpers.nl2br || (depth0 && depth0.nl2br) || alias2).call(alias1,(depth0 != null ? depth0.body : depth0),{"name":"nl2br","hash":{},"data":data}))
    + "</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.files : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.images : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  </div><!-- END widget content -->\n</div><!-- END hint -->\n";
},"useData":true});