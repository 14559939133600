// @flow
import { createAction } from 'redux-actions';
import { getFormValues, change, untouch } from 'redux-form';
import type { $AxiosError } from 'axios';
import { create as createDataType, update as updateDataType } from '../../../../../redux/entities/dataTypes';
import { mapQuestionToEntity, mapEntityToQuestion } from '../utils/transforms';
import types from './types';
import type { State as RootState } from '../../../../../redux/initialState';

export const expandQuestion = createAction(types.EXPAND_QUESTION);
export const collapseQuestion = createAction(types.COLLAPSE_QUESTION);

export const saveQuestionSuccess = createAction(types.SAVE_QUESTION_SUCCESS);
export const saveQuestionError = createAction(types.SAVE_QUESTION_ERROR);
export const saveQuestion = createAction(
    types.SAVE_QUESTION,
    (index: number): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
            const state = getState();
            const values = getFormValues('projectBuilder')(state);
            const { id, ...dataType } = mapQuestionToEntity(values.questionList[index]);

            const action = typeof id === 'string' && id.startsWith('new')
                // $FlowFixMe need to fix/update gigwalk-node type definitions
                ? createDataType({ dataType })
                : updateDataType({ data_type_id: id, dataType });

            return dispatch(action)
                .then((data: Object) => {
                    const dataTypeId = data.result[0];
                    const question = mapEntityToQuestion(data.entities.dataTypes[dataTypeId]);

                    // Update the form values for this question and untouch all the fields to mark as pristine
                    dispatch(change('projectBuilder', `questionList[${index}]`, question, false, false));
                    dispatch(untouch('projectBuilder', `questionList[${index}]`));

                    dispatch(saveQuestionSuccess(index));
                })
                .catch((err: $AxiosError<any>) => {
                    dispatch(saveQuestionError(index));
                    return Promise.reject(err);
                });
        }
    ),
    (index: number) => ({ index })
);

// export const cloneQuestionSuccess = createAction(types.CLONE_QUESTION_SUCCESS);
// export const cloneQuestionError = createAction(types.CLONE_QUESTION_ERROR);
// export const cloneQuestion = createAction(
//     types.CLONE_QUESTION,
//     (index, subscriptionId: number | string): Function =>
//         (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
//             const state = getState();
//             const values = getFormValues('projectBuilder')(state);
//             const subscription = getSubscriptionValues(state);
//             const dataType = mapQuestionToEntity(values.questionList[index]);
//
//             dataType.id = dataType.global_data_type_id;
//
//             updateSubscription({ organization_subscription_id: subscriptionId, subscription });
//
//             return dispatch(action)
//                 .then(() => {
//                     dispatch(cloneQuestionSuccess(index));
//                 })
//                 .catch((err: $AxiosError<any>) => {
//                     dispatch(cloneQuestionError(index));
//                     return Promise.reject(err);
//                 });
//         }
// );

export default {
    expandQuestion,
    collapseQuestion,
    saveQuestion,
    saveQuestionSuccess,
    saveQuestionError,
};
