// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import canUseDom from 'can-use-dom';
import { createPortal } from 'react-dom';
import { MAP } from 'react-google-maps/lib/constants';
import type { ChildrenArray } from 'react';

type Props = {
    children: ChildrenArray<any>,
    controlPosition?: number,
};

export default class MapControl extends Component<Props> {
    static contextTypes = {
        [MAP]: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    };

    componentWillMount() {
        if (!this.container && canUseDom) {
            this.container = document.createElement('div');
        }
    }

    componentDidMount() {
        this.handleMountAtControlPosition();
    }

    componentWillUpdate(nextProp: Props) {
        const { controlPosition } = this.props;
        if (controlPosition !== nextProp.controlPosition) {
            this.handleUnmountAtControlPosition();
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { controlPosition } = this.props;
        if (controlPosition !== prevProps.controlPosition) {
            this.handleMountAtControlPosition();
        }
    }

    componentWillUnmount() {
        this.handleUnmountAtControlPosition();
    }

    container: ?HTMLDivElement;
    mountControlIndex: ?number;

    handleMountAtControlPosition() {
        const { controlPosition } = this.props;
        const map = this.context[MAP]; // eslint-disable-line react/destructuring-assignment

        if (this.container && typeof controlPosition === 'number') {
            this.mountControlIndex = map.controls[controlPosition].length;
            map.controls[controlPosition].push(this.container.firstChild);
        }
    }

    handleUnmountAtControlPosition() {
        const { controlPosition } = this.props;
        const map = this.context[MAP]; // eslint-disable-line react/destructuring-assignment

        if (typeof controlPosition === 'number') {
            const child = map.controls[controlPosition].removeAt(this.mountControlIndex);
            if (this.container && child !== undefined) {
                this.container.appendChild(child);
            }
        }
    }

    render() {
        const { children, controlPosition, ...other } = this.props;
        return this.container ? createPortal(<div {...other}>{children}</div>, this.container) : null;
    }
}
