// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Button, Paper } from '@material-ui/core';
import styles from './styles';

type Props = {
    appLink: string,
}

const useStyles = makeStyles(styles, { name: 'MobileRedirect' });

export default function MobileRedirect(props: Props) {
    const { appLink } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    return (
        <Paper className={classes.paper} elevation={0}>
            <h2>{t('mobileRedirect.continue')}</h2>
            <Button
              className={classes.appLink}
              color="secondary"
              href={appLink}
              size="large"
              variant="contained"
            >
                {t('mobileRedirect.openApp')}
            </Button>
        </Paper>
    );
}
