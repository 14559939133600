// @flow
import { emphasize } from '@material-ui/core/styles/colorManipulator';

export default (theme: Object) => ({
    root: {
        height: '100%',
    },
    outlined: {
        '& $placeholder': {
            left: 8,
        },
        '& $inputElement': {
            paddingLeft: 8,
        },
    },
    menuItem: {
        whiteSpace: 'unset',
    },
    menu: {
        position: 'absolute',
        width: '100%',
        zIndex: 1,
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 40px)', // 40px = height of input/indicator + marginTop of paper
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    inputElement: {
        minHeight: 'auto',
        background: 0,
        border: 0,
        fontSize: 'inherit',
        outline: 0,
        padding: 0,
        color: 'inherit',

        '&:focus': {
            boxShadow: 'none',
        },
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
    },
    loadingIndicator: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 36,
        height: 36,
    },
    dropdownIndicator: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 36,
        height: 36,
    },
    clearIndicator: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 36,
        padding: [[0, 6]],
        fontSize: 18,
    },
    indicatorSeparator: {
        alignSelf: 'stretch',
        backgroundColor: 'hsl(0, 0%, 80%)',
        margin: [[8, 0]],
        width: 1,
        boxSizing: 'border-box',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        color: 'rgba(0, 0, 0, 0.4)',
        position: 'absolute',
        left: 2,
    },
    paper: {
        marginTop: 4,
    },
});
