// @flow
export default (theme: Object) => ({
    root: {
        width: '100%',
        padding: 20,
        textAlign: 'center',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,

        '@global p': {
            marginBottom: 0,
        },
    },
});
