// @flow
import sanitizeHtml from 'sanitize-html';
import moment from 'moment';
import type { Validator } from 'redux-form/es/types'; // eslint-disable-line import/no-unresolved
import {
    futureDate,
    greaterThan,
    lessThan,
    minHoursAfterDate,
    minHoursBeforeDate,
    minLength,
    required,
    unique,
} from '../../../util/validations';
import { SCHEDULE_TYPES } from '../../../../browser/shared/constant/ProjectConstant';

type FieldMeta = {
    step: string,
    validationRule: Validator,
};

const generateValidator = (rules?: Validator[] = []): Validator => (
    (value: any, allValues: Object, props: Object, name: string): ?string => {
        let error;
        rules.every((validator: Validator) => {
            error = validator(value, allValues, props, name);
            return !error;
        });
        return error;
    }
);

const min1HourAfterStartDate = minHoursAfterDate(1, 'rangeStartDate');
const min1HourBeforeEndDate = minHoursBeforeDate(1, 'rangeEndDate');
const min1HourFromNow = minHoursAfterDate(1);
const minLength1 = minLength(1);
const minLength3 = minLength(3);
const greaterThan0 = greaterThan(0);
const greaterThanOrEqualToTarget = greaterThan('oversamplingTarget', true);

const validateDescription = (value: any, allValues: Object, props: Object, name: string): ?string => {
    const v = (typeof value === 'string') ? sanitizeHtml(value, { allowedTags: [], allowedAttributes: [] }) : value;
    const validate = generateValidator([required, minLength(10)]);
    return validate(v, allValues, props, name);
};

const validateAsapEndDate = (value: any, allValues: Object, props: Object, name: string): ?string => {
    const { subscription } = props;
    const { scheduleType } = allValues;

    const inProgress = subscription
        ? subscription.state === 'ACTIVE' && moment(subscription.start_date).isBefore(moment())
        : false;

    let validations;
    if (subscription) {
        const { start_date: startDate } = subscription;
        const diffAsHours = moment().diff(moment(startDate), 'hours');
        validations = inProgress
            ? [required, futureDate, minHoursAfterDate(1 - diffAsHours, startDate)]
            : [required, futureDate, min1HourFromNow];
    } else {
        validations = [required, min1HourFromNow];
    }

    if (scheduleType === SCHEDULE_TYPES.ASAP) {
        const validate = generateValidator(validations);
        return validate(value, allValues, props, name);
    }
};

const validateRangeStartDate = (value: any, allValues: Object, props: Object, name: string): ?string => {
    const { subscription } = props;
    const { scheduleType } = allValues;

    const inProgress = subscription
        ? subscription.state === 'ACTIVE' && moment(subscription.start_date).isBefore(moment())
        : false;

    // Start date cannot be edited if project is in progress, so there's no point in performing validation
    const validations = inProgress ? [] : [required, futureDate, min1HourBeforeEndDate];

    if (scheduleType === SCHEDULE_TYPES.RANGE) {
        const validate = generateValidator(validations);
        return validate(value, allValues, props, name);
    }
};

const validateRangeEndDate = (value: any, allValues: Object, props: Object, name: string): ?string => {
    const { scheduleType } = allValues;
    const validations = [required, futureDate, min1HourAfterStartDate];

    if (scheduleType === SCHEDULE_TYPES.RANGE) {
        const validate = generateValidator(validations);
        return validate(value, allValues, props, name);
    }
};

const validateLocationList = (value: any, allValues: Object, props: Object): ?string => {
    const { locationList } = props;
    const locationCount = locationList ? locationList.location_count || 0 : 0;
    return locationCount === 0 ? 'invalidValue' : undefined;
};

const validateGroups = (value: any, allValues: Object) => {
    const { needsPublicWorkforce } = allValues;
    if (!needsPublicWorkforce) {
        return minLength1(value);
    }
};

const validateQuestionPropositions = (value: any, allValues: Object, props: Object, name: string): ?string => {
    const formKey = name.slice(0, name.indexOf('.propositions'));
    const validations = [
        required,
        unique(`${formKey}.propositions`, (proposition: Object) => proposition.choice),
    ];

    const validate = generateValidator(validations);
    return validate(value, allValues, props, name);
};

const validateAmountDue = (value: any, allValues: Object, props: Object, name: string): ?string => {
    const { needsPublicWorkforce } = allValues;
    const { currentBalance } = props;

    if (!needsPublicWorkforce) {
        return undefined;
    }

    const lessThanOrEqualToBalance = lessThan(currentBalance, true);
    const validations = [required, lessThanOrEqualToBalance];
    const validate = generateValidator(validations);
    return validate(value, allValues, props, name);
};

const validateOversamplingTarget = (value: any, allValues: Object, props: Object, name: string) => {
    const validations = allValues.oversamplingOverage ? [required, greaterThan0] : [greaterThan0];
    const validate = generateValidator(validations);
    return validate(value, allValues, props, name);
};

const validateOversamplingOverage = (value: any, allValues: Object, props: Object, name: string) => {
    const validations = allValues.oversamplingTarget ? [required, greaterThan0, greaterThanOrEqualToTarget] : [greaterThan0];
    const validate = generateValidator(validations);
    return validate(value, allValues, props, name);
};

const validationRules = {
    info: {
        asapEndDate: validateAsapEndDate,
        description: validateDescription,
        rangeStartDate: validateRangeStartDate,
        rangeEndDate: validateRangeEndDate,
        scheduleType: required,
        title: generateValidator([required, minLength3]),
    },
    locations: {
        locationListId: validateLocationList,
    },
    people: {
        groups: validateGroups,
        needsPublicWorkforce: required,
        workAllocation: required,
    },
    data: {
        questionList: minLength1,
        'questionList[].questionText': required,
        'questionList[].description': required,
        'questionList[].propositions[].choice': validateQuestionPropositions,
    },
    launch: {
        amountDue: validateAmountDue,
        gigPrice: generateValidator([required, greaterThan0]),
        nearTicketDistance: generateValidator([required, greaterThan0]),
        oversamplingOverage: validateOversamplingOverage,
        oversamplingTarget: validateOversamplingTarget,
    },
};

export default (field: string): FieldMeta => {
    const key = field.replace(/\[\d+]/g, '[]');
    const step = Object.keys(validationRules).find((s: string) => !!validationRules[s][key]);

    // Create a noop that matches the Validator signature. This will be returned in the
    // event that no validation exists for the given field
    const noop = generateValidator();

    return step
        ? { step, validationRule: validationRules[step][key] || noop }
        : { step: '', validationRule: noop };
};
