// @flow
export default (theme: Object) => ({
    root: {
        height: '100%',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',

        [theme.breakpoints.down('sm')]: {
            marginLeft: [0, '!important'],
        },
    },
    content: {
        flex: [[1, 0, 'auto']],
    },
});
