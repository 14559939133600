// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Component, Fragment, createRef } from 'react';
import { withStyles } from '@material-ui/styles';
import { Menu } from '@material-ui/core';
import type { Node } from 'react';

type State = {
    open: boolean,
};

type Props = {
    anchor: Node,
    anchorEl?: null | HTMLElement | ((element: HTMLElement) => HTMLElement),
    children: (props: Object) => Node,
    classes: Object,
    onClose?: (event: SyntheticEvent<any>, reason: string) => void,
};

const styles = () => ({
    anchor: {
        cursor: 'pointer',
        display: 'flex',
    },
    menu: {},
});

export class Dropdown extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { open: false };
        this.anchorRef = createRef();
    }

    closeMenu = () => {
        this.setState({ open: false });
    };

    toggleMenu = () => {
        const { open } = this.state;
        this.setState({ open: !open });
    };

    handleClose = (event: SyntheticEvent<any>, reason: string) => {
        const { onClose } = this.props;
        this.setState({ open: false }, () => {
            if (typeof onClose === 'function') {
                onClose(event, reason);
            }
        });
    };

    anchorRef: Object;

    render() {
        const { open } = this.state;
        const { anchor, anchorEl, children, classes, onClose, ...other } = this.props;

        const childProps = {
            closeMenu: this.closeMenu,
        };

        return (
            <Fragment>
                <div className={classes.anchor} ref={this.anchorRef} onClick={this.toggleMenu}>
                    {anchor}
                </div>
                <Menu
                  anchorEl={this.anchorRef.current}
                  classes={{ paper: classes.menu }}
                  onClose={this.handleClose}
                  open={open}
                  {...other}
                >
                    {children(childProps)}
                </Menu>
            </Fragment>
        );
    }
}

export default withStyles(styles, { name: 'Dropdown' })(Dropdown);
