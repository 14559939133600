// @flow
export const TOGGLE = 'g/sideNav/TOGGLE';
export const EXPAND = 'g/sideNav/EXPAND';
export const COLLAPSE = 'g/sideNav/COLLAPSE';
export const START_TRANSITION = 'g/sideNav/START_TRANSITION';
export const END_TRANSITION = 'g/sideNav/END_TRANSITION';

export default {
    TOGGLE,
    EXPAND,
    COLLAPSE,
    START_TRANSITION,
    END_TRANSITION,
};
