// @flow
import React, { Component } from 'react';
import cx from 'classnames';
import Cleave from 'cleave.js/dist/cleave-react';
import { Icon, Tooltip } from '@material-ui/core';
import type { Element } from 'react';
import type { CleaveSyntheticInputEvent } from 'cleave.js/dist/cleave-react';
import theme from './MaskedInput.scss';

type Props = {
    className?: string,
    error?: string,
    id?: string,
    label?: string | Element<any>,
    onChange?: (value: string) => void,
    onBlur?: (value: string) => void,
    required?: boolean,
    tooltip?: string,
};

export default class MaskedInput extends Component<Props> {
    handleBlur = (event: CleaveSyntheticInputEvent) => {
        const { onBlur } = this.props;
        if (typeof onBlur === 'function') {
            onBlur(event.currentTarget.rawValue);
        }
    };

    handleChange = (event: CleaveSyntheticInputEvent) => {
        const { onChange } = this.props;
        if (typeof onChange === 'function') {
            onChange(event.currentTarget.rawValue);
        }
    };

    render() {
        const { className, error, label, tooltip, ...cleaveProps } = this.props;

        return (
            <div className={cx(className, { 'c-form__error': !!error })}>
                {label != null
                    ? (
                        <div className={theme.label}>
                            <label
                              htmlFor={cleaveProps.id}
                              className={cx({ 'c-form__label-required': !!cleaveProps.required })}
                            >
                                {label}
                            </label>
                            {tooltip != null
                                ? (
                                    <Tooltip className={theme.tooltip} title={tooltip}>
                                        <Icon>help</Icon>
                                    </Tooltip>
                                )
                                : null
                            }
                        </div>
                    )
                    : null
                }
                <Cleave
                  {...cleaveProps}
                  type="text"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                />
                <p className="c-form__error-message">{error || ''}</p>
            </div>
        );
    }
}
