var Handlebars = require('/tmp/build_c41768ab/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"ti\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</div>\n<div class=\"description\">"
    + alias4(((helper = (helper = helpers.more_work_to_complete || (depth0 != null ? depth0.more_work_to_complete : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"more_work_to_complete","hash":{},"data":data}) : helper)))
    + "</div>\n\n<div class=\"status-prompt type item-grid\" data-action=\"toggleNext\">\n    <div class=\"answers\">\n        <div class=\"answer\">\n            <label><input type=\"radio\" name=\"continue\" value=\"yes\"> &nbsp;"
    + alias4(((helper = (helper = helpers.yes || (depth0 != null ? depth0.yes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"yes","hash":{},"data":data}) : helper)))
    + "</label>\n        </div>\n        <div class=\"answer\">\n            <label><input type=\"radio\" name=\"continue\" value=\"no\" checked> &nbsp;"
    + alias4(((helper = (helper = helpers.no || (depth0 != null ? depth0.no : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"no","hash":{},"data":data}) : helper)))
    + "</label>\n        </div>\n    </div>\n</div>";
},"useData":true});