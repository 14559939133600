// @flow
type Options = {
    obfuscate?: boolean,
};

type User = {
    first_name: string,
    last_name: string,
};

export default function getDisplayName(user: User, options?: Options = {}) {
    const parsed = {
        firstName: user.first_name || '',
        lastName: user.last_name || '',
    };

    if (options.obfuscate) {
        parsed.lastName = parsed.lastName.charAt(0);
    }

    return `${parsed.firstName} ${parsed.lastName}`.trim();
}
