// @flow weak
import ReactDOM from 'react-dom';
import React from 'react';
import Backbone from 'backbone';
import '@gigwalk/backbone-injector';
import 'backbone-super';
import type { ComponentType } from 'react';
import logger from '../../../common/util/logger';

/**
 * the base class for all views/ui in the module
 *
 * @class (View) Component
 * @constructor
 */
const Component = Backbone.View.extend({

    className: '__ui__',

    /**
     * @method intialize
     */
    initialize() {
        this.active = false;
    },

    /**
     * From delegateEvents in backbone.js
     * Preserves original event node in $(event).data.$_target
     * Compatibility | HTML: all browsers, XML: IE8+
     * @method delegateEvents
     * @param eventsParam
     * @returns {Component}
     */
    delegateEvents(eventsParam?: { [key: string]: string | Function }) {
        let events = eventsParam;
        if (!eventsParam) {
            events = (typeof this.events === 'function') ? this.events() : this.events;
        }

        if (!events) {
            return this;
        }

        this.undelegateEvents();

        Object.entries(events).forEach(([event, handler]: [string, mixed]) => {
            const method = (typeof handler !== 'function') ? this[handler] : handler;

            if (method) {
                const match = event.match(/^(\S+)\s*(.*)$/);
                if (match) {
                    this.delegate(match[1], match[2], (...args: Array<any>) => { this._boundMethod(method, ...args); });
                }
            }
        });

        return this;
    },

    /**
     * @method _boundMethod
     * @param method
     * @param event
     * @private
     */
    _boundMethod(method: Function, event: Event) {
        /* Ignore callback on command or control click */
        if (event.metaKey || event.ctrlKey) {
            return;
        }

        /* Prevent default on all delegated events of type click
         * but exclude checkboxes and radio */
        if (event.type === 'click' && !$(event.target).is(':checkbox') && !$(event.target).is(':radio')) {
            event.preventDefault();
        }

        method.call(this, event);
    },

    /**
     * @method request
     */
    request(path: string) {
        if (this.njs) {
            this.njs.request(path);
        } else {
            // add / to URL to use assign correctly on FF
            window.location.assign(path.startsWith('/') ? path : `/${path}`);
        }
    },

    /**
     * CHILDREN take  the injector by default, but you can override this
     * method in your view and return more complex objects
     * The method is always called with childName has argument
     *
     * getChildOptions(childName)
     *
     * @returns {{injector: *}}
     */
    getChildOptions() {
        return { injector: this.injector };
    },

    /**
     * This method will replace all the nodes with data attribute
     * child-data="childName" with the $el of the mapped subView
     * The mapping needs to be implemented in DATA_CHILD
     *
     * @method renderChildren
     */
    renderChildren() {
        // get the children
        const $children = this.$el.find('[data-child]');
        this.children = this.children || {};
        this.reactElements = this.reactElements || {};

        // instantiate children if it doesn't exist yet
        $children.each((index: number, child: Element) => {
            // $FlowFixMe
            const childName: string = $(child).data('child');

            if (!this.DATA_CHILD[childName]) {
                logger.warn(
                    { child, childName },
                    '!! You need to map your children Classes in order to render them\n\n',
                    `Class ${this.toString()}\n`,
                    'DATA_CHILD: {\n',
                    `   ${childName}: ChildClass // missing\n`,
                    '}'
                );
            }

            if (this.DATA_CHILD[childName]) {
                if (this.DATA_CHILD[childName].isREACT) {
                    const $dest = this.$el.find(`[data-child="${childName}"]`)[0];

                    // create the ReactElement only ONCE:
                    // ReactElement is a light, stateless, immutable, virtual representation of a DOM Element
                    // It is defined by the DataChild Class + immutable props
                    if (!this.reactElements[childName]) {
                        this.reactElements[childName] = React.createElement(this.DATA_CHILD[childName], this.getChildOptions(childName));
                    }

                    // Recycling React Dom instance seems challenging.
                    // The events are lost and I cant find something like delegateEvents on React Components
                    // Therefore, lets make sure that previous instances are always cleaned up.
                    if (this.children[childName]) {
                        ReactDOM.unmountComponentAtNode($dest);
                    }

                    this.children[childName] = ReactDOM.render(this.reactElements[childName], $dest);
                    const node = ReactDOM.findDOMNode(this.children[childName]);

                    this.children[childName].$el = this.children[childName].$el || (node ? $(node) : $());
                } else {
                    this.children[childName] = this.children[childName] || new this.DATA_CHILD[childName](this.getChildOptions(childName));
                    const $dataChild = this.$el.find(`[data-child="${childName}"]`);
                    const { attributes } = $dataChild[0];

                    // Copy attributes from placeholder to child, being careful not to overwrite or remove
                    // existing attributes


                    for (let i = 0; i < attributes.length; i += 1) {
                        const { name, value } = attributes[i];

                        if (value) {
                            if (name === 'class') {
                                this.children[childName].$el.addClass(value);
                            } else if (!this.children[childName].$el.attr(name)) {
                                this.children[childName].$el.attr(name, value);
                            }
                        }
                    }

                    $dataChild.replaceWith(this.children[childName].$el);
                }
            }
        });

        this.activateChildren();
    },

    /**
     * @method activateChildren
     */
    activateChildren() {
        if (this.children) {
            Object.values(this.children).forEach((child: Component | ComponentType<any>) => {
                if (!child.active) {
                    if (typeof child.transitionIn === 'function') {
                        child.transitionIn(() => {});
                    } else if (typeof child.activate === 'function') {
                        child.activate();
                    }
                }
            });
        }
    },

    /**
     * @METHOD removeChildren
     * @param dispose
     */
    removeChildren(dispose: boolean) {
        if (this.children) {
            Object.entries(this.children).forEach(([key, child]: [string, mixed]) => {
                if (child == null) return;
                if (typeof child.transitionOut === 'function') {
                    child.transitionOut(() => {});
                } else if (typeof child.deactivate === 'function') {
                    if (child.active) {
                        if (child instanceof React.Component) {
                            const $dest = this.$el.find(`[data-child="${key}"]`)[0];
                            const success = ReactDOM.unmountComponentAtNode($dest);
                            if (!success) {
                                logger.warn(`Your React component: data-child=[${key}] could not be unmounted properly`);
                            }

                            delete this.reactElements[key];
                            delete this.children[key];
                        } else {
                            child.deactivate();
                        }
                    }
                }

                // if dispose is true, completely destroy the sub view
                // only applicable for non react component
                if (dispose && typeof child.destroy === 'function' && !(child instanceof React.Component)) {
                    child.destroy();
                    delete this.children[key];
                }
            });
        }
    },

    /**
     * @method activate
     */
    activate() {
        this.delegateEvents();
        this.active = true;
    },

    /**
     * @method deactivate
     */
    deactivate() {
        this.undelegateEvents();
        this.active = false;
        this.stopListening();
        this.removeChildren();
    },

    /**
     * @method destroy
     */
    destroy() {
        // COMPLETELY UNBIND THE VIEW
        this.deactivate();
        this.$el.removeData().unbind();

        // Remove view from DOM
        this.remove();

        // Trigger event so the parent views can be notified
        this.trigger('destroy', this);
    },

    /**
     * similar as a custom toString()
     * @method toString
     * @returns {string}
     */
    toString(): string {
        return 'Component';
    },

});

/**
 * @method extend
 * @param child {Backbone.View} The view to be extended
 * @return {Backbone.View} The extended view
 */
Component.extend = function extend<T>(properties: T, ...rest: Array<any>) {
    const view = Backbone.View.extend.call(this, properties, ...rest);
    if (typeof properties === 'object' && properties !== null && properties.events) {
        view.prototype.events = Object.assign({}, this.prototype.events, properties.events);
    }
    return view;
};

export default Component;
