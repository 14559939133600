// @flow
export const SUBMIT = 'g/unassignDialog/SUBMIT';
export const SUBMIT_SUCCESS = `${SUBMIT}_SUCCESS`;
export const SUBMIT_ERROR = `${SUBMIT}_ERROR`;

export default {
    SUBMIT,
    SUBMIT_SUCCESS,
    SUBMIT_ERROR,
};
