// @flow
import { normalize } from 'normalizr';
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import type { $AxiosXHR, $AxiosError } from 'axios';
import type { APIResponse, APIPromise } from 'gigwalk/lib/api/resource';
import type {
    Group,
    GetGroupParams,
    GetGroupForOrganizationParams,
    CreateGroupParams,
    AddGroupMemberParams,
} from 'gigwalk/lib/api/groups/types';
import logger from '../../util/logger';
import { client as gigwalk } from '../../api/createGigwalkClient';
import { init } from './initialState';
import { group as groupSchema } from './schemas';
import type { State } from './initialState';

// Actions
// -------

export const FETCH = 'g/groups/FETCH';
export const FETCH_SUCCESS = `${FETCH}_SUCCESS`;
export const FETCH_ERROR = `${FETCH}_ERROR`;

// Get group
export const GET_FOR_ORGANIZATION = 'g/groups/GET_FOR_ORGANIZATION';
export const GET_FOR_ORGANIZATION_SUCCESS = `${GET_FOR_ORGANIZATION}_SUCCESS`;
export const GET_FOR_ORGANIZATION_ERROR = `${GET_FOR_ORGANIZATION}_ERROR`;

// Create group
export const CREATE = 'g/groups/CREATE';
export const CREATE_SUCCESS = `${CREATE}_SUCCESS`;
export const CREATE_ERROR = `${CREATE}_ERROR`;

// Add member
export const ADD_MEMBER = 'g/groups/ADD_MEMBER';
export const ADD_MEMBER_SUCCESS = `${ADD_MEMBER}_SUCCESS`;
export const ADD_MEMBER_ERROR = `${ADD_MEMBER}_ERROR`;

export const ADD_MEMBERS = 'g/groups/ADD_MEMBERS';
export const ADD_MEMBERS_SUCCESS = `${ADD_MEMBERS}_SUCCESS`;
export const ADD_MEMBERS_ERROR = `${ADD_MEMBERS}_ERROR`;

// Action Creators
// ---------------

export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchError = createAction(FETCH_ERROR);
export const fetch = createAction(
    FETCH,
    (params: GetGroupParams): Function => (
        (dispatch: Dispatch<any>): APIPromise<[Group]> => (
            gigwalk.groups.get(params)
                .then((resp: $AxiosXHR<APIResponse<[Group]>>) => {
                    const normalized = normalize(resp.data.data, [groupSchema]);
                    dispatch(fetchSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchError(err));
                    return Promise.reject(err);
                })
        )
    )
);

// latform admins get all groups, Admins get all groups of the organization, Workers get information about the groups they belong to.
export const getForOrganizationSuccess = createAction(GET_FOR_ORGANIZATION_SUCCESS);
export const getForOrganizationError = createAction(GET_FOR_ORGANIZATION_ERROR);
export const getForOrganization = createAction(
    GET_FOR_ORGANIZATION,
    (params: GetGroupForOrganizationParams): Function => (
        (dispatch: Dispatch<any>): APIPromise<Group[]> => (
            gigwalk.groups.getForOrganization(params)
                .then((resp: $AxiosXHR<APIResponse<Group[]>>) => {
                    const normalized = normalize(resp.data.data, [groupSchema]);
                    dispatch(getForOrganizationSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(getForOrganizationError(err));
                    return Promise.reject(err);
                })
        )
    )
);

// Create a group.
export const createSuccess = createAction(CREATE_SUCCESS);
export const createError = createAction(CREATE_ERROR);
export const create = createAction(
    CREATE,
    (params: CreateGroupParams): Function => (
        (dispatch: Dispatch<any>): APIPromise<[Group]> => (
            gigwalk.groups.create(params)
                .then((resp: $AxiosXHR<APIResponse<[Group]>>) => {
                    const normalized = normalize(resp.data.data, [groupSchema]);
                    dispatch(createSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(createError(err));
                    return Promise.reject(err);
                })
        )
    )
);

// Add a member to a group.
export const addMemberSuccess = createAction(ADD_MEMBER_SUCCESS);
export const addMemberError = createAction(ADD_MEMBER_ERROR);
export const addMember = createAction(
    ADD_MEMBER,
    (params: AddGroupMemberParams): Function => (
        (dispatch: Dispatch<any>): APIPromise<[Group]> => (
            gigwalk.groups.addMember(params)
                // $FlowFixMe need to fix gigwalk-node type definitions - returns the Customer
                .then((resp: $AxiosXHR<APIResponse<[Group]>>) => {
                    // @todo This endpoint returns the customer that was added
                    const normalized = normalize(resp.data.data, [groupSchema]);
                    dispatch(addMemberSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(addMemberError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const addMembersSuccess = createAction(ADD_MEMBERS_SUCCESS);
export const addMembersError = createAction(ADD_MEMBERS_ERROR);
export const addMembers = createAction(
    ADD_MEMBERS,
    (params: { group_id: number, members: Object[] }): Function => (
        (dispatch: Dispatch<any>): APIPromise<[Group]> => (
            // @todo Fix gigwalk-node (gigwalk.groups.addMember) to allow multiple members
            gigwalk.client.post(`/v1/groups/${params.group_id}/customers`, params.members)
                .then((resp: $AxiosXHR<APIResponse<[Group]>>) => {
                    const normalized = normalize(resp.data.data, [groupSchema]);
                    dispatch(addMembersSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(addMembersError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const searchSuccess = createAction(CREATE_SUCCESS);
export const searchError = createAction(CREATE_ERROR);
export const search = createAction(
    CREATE,
    (params: CreateGroupParams): Function => (
        (dispatch: Dispatch<any>): APIPromise<[Group]> => (
            gigwalk.groups.create(params)
                .then((resp: $AxiosXHR<APIResponse<[Group]>>) => {
                    const normalized = normalize(resp.data.data, [groupSchema]);
                    dispatch(createSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(createError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export default function(state: State = init): State {
    return state;
}
