// @flow
export default (theme: Object) => ({
    '@global body': {
        background: theme.palette.background.default, // theme.palette.background.appBar, $neutral-2
    },
    loading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 260px)',
        textAlign: 'center',
    },
    noData: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 260px)',
        textAlign: 'center',
    },
    addIcon: {
        marginRight: theme.spacing(1),
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        marginBottom: theme.spacing(2),

        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    action: {
        minWidth: 0,
        padding: [[0, 8]],
        margin: [[0, 4]],
    },
    toolbars: {},
    search: {
        '&:not(:only-child)': {
            minHeight: 0,
        },
    },
    filters: {},
});
