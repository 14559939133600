// @flow
import union from 'lodash.union';
import { createAction } from 'redux-actions';
import { change } from 'redux-form';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import types from './types';
import { create as createLocation } from '../../../../../redux/entities/locations';
import {
    fetch as fetchLocationList,
    create as createLocationList,
    getLocations,
    removeLocations,
    addLocations,
    getUnresolvedLocations,
    getUploadStats,
} from '../../../../../redux/entities/locationLists';
import entitySelector from '../../../../../redux/entities/entitySelector';
import type { State as RootState } from '../../../../../redux/initialState';

type NewLocation = {
    formatted_address: string,
    title: string,
    latitude?: number,
    longitude?: number,
};

const locationListSelector = entitySelector('locationLists');

export const pendingAdd = createAction(types.PENDING_ADD);
export const pendingRemove = createAction(types.PENDING_REMOVE);
export const updatePending = createAction(types.UPDATE_PENDING);

export const getNextPageError = createAction(types.GET_NEXT_PAGE_ERROR);
export const getNextPageSuccess = createAction(types.GET_NEXT_PAGE_SUCCESS);
export const getNextPage = createAction(
    types.GET_NEXT_PAGE,
    (locationListId: number): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
            const state = getState();
            const { locationIds } = state.projectBuilder.locations;

            if (locationListId <= 0) {
                dispatch(getNextPageSuccess([]));
                return Promise.resolve();
            }

            const params = {
                organization_location_list_id: locationListId,
                query: {
                    limit: 20,
                    offset: Math.floor(locationIds.length / 20),
                    order_by: 'title',
                },
            };

            return dispatch(getLocations(params))
                .then((page: Object) => { dispatch(getNextPageSuccess(page.result)); })
                .catch(() => { dispatch(getNextPageError()); });
        }
    )
);

export const refreshPageError = createAction(types.REFRESH_PAGE_ERROR);
export const refreshPageSuccess = createAction(types.REFRESH_PAGE_SUCCESS);
export const refreshPage = createAction(
    types.REFRESH_PAGE,
    (locationListId: number): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
            const state = getState();
            const pageSize = state.projectBuilder.locations.locationIds.length;
            const cnt = Math.ceil(pageSize / 20) || 1;

            if (locationListId <= 0) {
                dispatch(refreshPageSuccess([]));
                return Promise.resolve();
            }

            const promises = Array.from(new Array(cnt), ((el: void, i: number): Promise<Object> => {
                const params = {
                    organization_location_list_id: locationListId,
                    query: {
                        limit: 20,
                        offset: i,
                        order_by: 'title',
                    },
                };

                return dispatch(getLocations(params));
            }));

            return Promise.all(promises)
                .then((pages: Array<Object>) => {
                    const locations = pages.reduce((ids: Array<number>, page: Object) => union(ids, page.result), []);
                    dispatch(refreshPageSuccess(locations));
                })
                .catch(() => { dispatch(refreshPageError()); });
        }
    )
);

export const getUnresolvedSuccess = createAction(types.GET_UNRESOLVED_SUCCESS);
export const getUnresolvedError = createAction(types.GET_UNRESOLVED_ERROR);
export const getUnresolved = createAction(
    types.GET_UNRESOLVED,
    (locationListId: number): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState) => {
            const state = getState();
            const fileUploads = locationListSelector(state, locationListId, 'file_uploads') || [];
            const promises = fileUploads.map((upload: Object): Promise<Object> => {
                const params = {
                    location_list_id: locationListId,
                    file_upload_id: upload.id,
                };
                return dispatch(getUnresolvedLocations(params));
            });

            return Promise.all(promises)
                .then((arr: Array<Object>) => {
                    const locations = arr.reduce((unresolved: Array<number>, resp: Object): Array<number> => union(unresolved, resp.result), []);
                    dispatch(getUnresolvedSuccess(locations));
                })
                .catch(() => { dispatch(getUnresolvedError()); });
        }
    )
);

export const fetchListError = createAction(types.FETCH_LIST_ERROR);
export const fetchListSuccess = createAction(types.FETCH_LIST_SUCCESS);
export const fetchList = createAction(
    types.FETCH_LIST,
    (locationListId: number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => {
            // fetchLocationList and getUploadStats have different param names for the same value. This should
            // be fixed in gigwalk-node
            const params = {
                organization_location_list_id: locationListId,
                location_list_id: locationListId,
            };

            // Retrieve all information about the location list, including (potentially) the
            // file uploads used to create it and any unresolved locations
            return Promise.all([dispatch(fetchLocationList(params)), dispatch(getUploadStats(params))])
                .then((): Promise<[void, void]> => Promise.all([dispatch(getNextPage(locationListId)), dispatch(getUnresolved(locationListId))]))
                .then(() => { dispatch(fetchListSuccess()); })
                .catch(() => { dispatch(fetchListError()); });
        }
    )
);

export const addToListError = createAction(types.ADD_TO_LIST_ERROR);
export const addToListSuccess = createAction(types.ADD_TO_LIST_SUCCESS);
export const addToList = createAction(
    types.ADD_TO_LIST,
    (locationListId: number, location: NewLocation): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState) => {
            const state = getState();
            const activeUser = state.session.user;

            if (!activeUser) {
                throw new Error('Active user not set');
            }

            const createLocationParams = {
                organization_id: activeUser.organization.id,
                title: location.title,
                address: location.formatted_address,
                override_existing: true,
            };

            // Add to pendingAdd so that we can display a saving/loading indicator
            dispatch(pendingAdd(location));

            // Create location and add it to the list
            return dispatch(createLocation(createLocationParams))
                .then((resp: Object) => {
                    const addLocationsParams = {
                        organization_location_list_id: locationListId,
                        locations: resp.result,
                    };

                    return dispatch(addLocations(addLocationsParams));
                })
                .then(() => dispatch(refreshPage(locationListId)))
                .then(() => {
                    dispatch(updatePending(location));
                    dispatch(addToListSuccess());
                })
                .catch((err: $AxiosError<*>) => {
                    dispatch(updatePending(location));
                    dispatch(addToListError());
                    return Promise.reject(err);
                });
        }
    )
);

export const removeFromListError = createAction(types.REMOVE_FROM_LIST_ERROR);
export const removeFromListSuccess = createAction(types.REMOVE_FROM_LIST_SUCCESS);
export const removeFromList = createAction(
    types.REMOVE_FROM_LIST,
    (locationListId: number, relationId: number): Function => (
        (dispatch: Dispatch<any>) => {
            const params = {
                organization_location_list_id: locationListId,
                relations: [relationId],
            };

            // Add to pendingRemove so that we can display a saving/loading indicator
            dispatch(pendingRemove(relationId));

            return dispatch(removeLocations(params))
                .then(() => (
                    // Since the API call to remove locations does not return a location list, we need
                    // to fetch list again to get the updated location_count
                    Promise.all([
                        dispatch(fetchList(locationListId)),
                        dispatch(refreshPage(locationListId)),
                    ])
                ))
                .then(() => {
                    dispatch(updatePending(relationId));
                    dispatch(removeFromListSuccess());
                })
                .catch((err) => {
                    dispatch(updatePending(relationId));
                    dispatch(removeFromListError());
                    return Promise.reject(err);
                });
        }
    )
);

export const createListError = createAction(types.CREATE_LIST_ERROR);
export const createListSuccess = createAction(types.CREATE_LIST_SUCCESS);
export const createList = createAction(
    types.CREATE_LIST,
    (listName: string, location?: NewLocation): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState) => {
            const state = getState();
            const activeUser = state.session.user;
            if (!activeUser) {
                throw new Error('Active user not set');
            }

            const params = {
                organization_id: activeUser.organization.id,
                location_list: {
                    name: listName,
                    locations: [],
                },
            };

            // Create an empty location list, then add the new location. This results in an additional
            // API call, but since we are always using the same API to add to the list, we ensure
            // consistent behavior
            let locationListId: ?number = null;
            return dispatch(createLocationList(params))
                .then((data: Object) => {
                    locationListId = data.result[0]; // eslint-disable-line prefer-destructuring
                    dispatch(change('projectBuilder', 'locationListId', locationListId, false, false));
                })
                .then((): ?Promise<void> => {
                    if (location && locationListId) {
                        return dispatch(addToList(locationListId, location));
                    }
                })
                .then(() => {
                    dispatch(createListSuccess());
                })
                .catch((err) => {
                    dispatch(createListError());
                    return Promise.reject(err);
                });
        }
    )
);

export const deleteList = createAction(
    types.DELETE_LIST,
    (): Function => (
        (dispatch: Dispatch<*>) => {
            dispatch(change('projectBuilder', 'locationListId', -1, false, false));
            dispatch(refreshPage(-1));
        }
    )
);

export const select = createAction(types.SELECT);
export const showLocationListUpload = createAction(types.SHOW_LOCATION_LIST_UPLOAD);
export const hideLocationListUpload = createAction(types.HIDE_LOCATION_LIST_UPLOAD);
export const showUnresolvedLocations = createAction(types.SHOW_UNRESOLVED_LOCATIONS);
export const hideUnresolvedLocations = createAction(types.HIDE_UNRESOLVED_LOCATIONS);
export const toggleUploadNotification = createAction(types.TOGGLE_UPLOAD_NOTIFICATION);

export default {
    pendingAdd,
    pendingRemove,
    updatePending,
    getNextPageError,
    getNextPageSuccess,
    getNextPage,
    refreshPageError,
    refreshPageSuccess,
    refreshPage,
    addToListError,
    addToListSuccess,
    addToList,
    removeFromListError,
    removeFromListSuccess,
    removeFromList,
    getUnresolvedError,
    getUnresolvedSuccess,
    getUnresolved,
    fetchListError,
    fetchListSuccess,
    fetchList,
    createListError,
    createListSuccess,
    createList,
    deleteList,
    select,
    showLocationListUpload,
    hideLocationListUpload,
    showUnresolvedLocations,
    hideUnresolvedLocations,
    toggleUploadNotification,
};
