// @flow
import React, { Component } from 'react';
import cx from 'classnames';
import moment from 'moment';
import noop from 'lodash.noop';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { withStyles } from '@material-ui/styles';
import {
    AppBar,
    Avatar,
    Badge,
    Hidden,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Toolbar,
} from '@material-ui/core';
import {
    Assignment as AssignmentIcon,
    CloudDownload as CloudDownloadIcon,
    Drafts as DraftsIcon,
    LocalAtm as LocalAtmIcon,
    Menu as MenuIcon,
    ModeComment as ModeCommentIcon,
    Notifications as NotificationsIcon,
    OpenInNew as OpenInNewIcon,
    Person as PersonIcon,
    PowerSettingsNew as PowerSettingsNewIcon,
    PriorityHigh as PriorityHighIcon,
} from '@material-ui/icons';
import { Download as DownloadIcon, Rocket as RocketIcon } from 'mdi-material-ui';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter } from 'react-router';
import { USER_ROLES } from '../../../../../browser/shared/constant/UserRoles';
import getDisplayName from '../../../../util/getDisplayName';
import UserAvatar from '../../../../components/UserAvatar';
import Dropdown from '../../../../components/Dropdown';
import * as session from '../../../../ducks/session';
import * as sideNav from '../SideNav/duck';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';
import type { User } from '../../../../store_utils/types/User';

type State = {
    pendingMarkAsRead: number[],
};

type OwnProps = ContextRouter & {
    classes: Object,
    t: TFunction,
};

type StateProps = {
    user: ?User,
    canAddFunds: boolean,
    navItems: Object[],
    notifications: Object[],
    unreadCount: number,
};

type DispatchProps = {
    checkNotifications: () => Promise<void>,
    markAsRead: (notificationId: number) => Promise<void>,
    toggleSideNav: () => void,
};

type Props = OwnProps & StateProps & DispatchProps;

const iconsByNotificationType = {
    FILE_DOWNLOAD: CloudDownloadIcon,
    PROJECT_LAUNCH: RocketIcon,
    COMMENT: ModeCommentIcon,
    TICKET_EVENT: PriorityHighIcon,
};

export class Header extends Component<Props, State> {
    state = {
        pendingMarkAsRead: [],
    };

    notificationTimer: ?number = null;

    componentDidMount() {
        const { checkNotifications } = this.props;
        const interval = 1000 * 60; // Check for new notifications once every minute

        const pollForUpdates = () => {
            checkNotifications()
                .catch(noop)
                .then(() => {
                    this.notificationTimer = setTimeout(pollForUpdates, interval);
                });
        };

        clearTimeout(this.notificationTimer);
        pollForUpdates();
    }

    componentWillUnmount() {
        clearTimeout(this.notificationTimer);
        this.notificationTimer = null;
    }

    handleMarkAsRead = (event: SyntheticEvent<any>) => {
        const { markAsRead } = this.props;
        const { pendingMarkAsRead } = this.state;
        const notificationId = parseInt(event.currentTarget.dataset.id, 10);

        this.setState({
            pendingMarkAsRead: [
                ...pendingMarkAsRead,
                notificationId,
            ],
        });

        markAsRead(notificationId)
            .catch(noop)
            .then(() => {
                this.setState({
                    pendingMarkAsRead: pendingMarkAsRead.filter((id) => id !== notificationId),
                });
            });
    };

    renderProfileMenu = (props: { closeMenu: () => void }) => {
        const { canAddFunds, t, user } = this.props;
        const { closeMenu } = props;

        if (user == null) {
            return null;
        }

        return [
            <ListItem key="user">
                <ListItemAvatar>
                    <UserAvatar user={user} />
                </ListItemAvatar>
                <ListItemText primary={getDisplayName(user)} secondary={user.organization.organization_name} />
            </ListItem>,
            <MenuItem component={Link} key="profile" onClick={closeMenu} to={`/member/${user.organization.id}/${user.id}/account`}>
                <ListItemIcon>
                    <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={t('layout.header.menu.profile')} />
            </MenuItem>,
            user.role === USER_ROLES.WORKER
                ? (
                    <MenuItem component={Link} key="tickets" onClick={closeMenu} to={`/tickets/${user.organization.id}/list`}>
                        <ListItemIcon>
                            <AssignmentIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('layout.header.menu.myTickets')} />
                    </MenuItem>
                )
                : null,
            canAddFunds
                ? (
                    <MenuItem component={Link} key="funds" onClick={closeMenu} to={`/payments/${user.organization.id}/add-funds`}>
                        <ListItemIcon>
                            <LocalAtmIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('layout.header.menu.funds')} />
                    </MenuItem>
                )
                : null,
            <MenuItem component="a" key="logout" href="/logout">
                <ListItemIcon>
                    <PowerSettingsNewIcon />
                </ListItemIcon>
                <ListItemText primary={t('layout.header.menu.logout')} />
            </MenuItem>,
        ];
    };

    renderNotifications = () => {
        const { classes, notifications, t } = this.props;
        const { pendingMarkAsRead } = this.state;

        const noContent = (
            <div className={classes.noNotifications}>
                {t('layout.header.notifications.noUnread')}
            </div>
        );
        const menuItems = notifications.map((notification: Object, index: number) => {
            const { data, date, i18nKey, id, type } = notification;
            const NotificationIcon = iconsByNotificationType[type] || PriorityHighIcon;
            let action = null;

            // Since all notifications currently have a similar format (ie. "text *bold_text* text"),
            // we can use the Trans component like so. The bold text is always at index 1. If this
            // changes, we'll need to handle each unique case separately using a switch statement
            const message = (
                <Trans
                  defaults={t(`layout.header.notifications.${i18nKey}`, data)}
                  components={[null, <strong style={{ fontWeight: 600 }}>projectTitle</strong>]}
                />
            );

            if (data.link) {
                action = type === 'FILE_DOWNLOAD'
                    ? (
                        <IconButton href={data.link}>
                            <DownloadIcon fontSize="inherit" />
                        </IconButton>
                    )
                    : (
                        <IconButton component={Link} target="_blank" to={data.link}>
                            <OpenInNewIcon fontSize="inherit" />
                        </IconButton>
                    );
            }

            const showSpinner = pendingMarkAsRead.includes(id);
            const className = cx(classes.notification, {
                [classes.disabled]: showSpinner,
            });

            return (
                <ListItem className={className} dense divider={index !== notifications.length - 1}>
                    <ListItemAvatar>
                        <Avatar><NotificationIcon /></Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={message} secondary={moment(date).fromNow()} />
                    <div className={classes.notificationActions}>
                        {action}
                        <IconButton onClick={this.handleMarkAsRead} data-id={id}>
                            <DraftsIcon fontSize="inherit" />
                        </IconButton>
                    </div>
                    {showSpinner
                        ? (
                            <div className={classes.spinner}>
                                <PulseLoader color="#3168aa" loading size={8} margin="1.5px" />
                            </div>
                        )
                        : null
                    }
                </ListItem>
            );
        });

        return [
            <Toolbar className={classes.notificationsToolbar} key="toolbar" variant="dense">
                {t('layout.header.notifications.header')}
            </Toolbar>,
            <div className={classes.notificationsList} key="list">
                {notifications.length === 0 ? noContent : menuItems}
            </div>,
        ];
    };

    render() {
        const { classes, match, navItems, t, toggleSideNav, unreadCount, user } = this.props;
        const { app } = match.params;
        const title = app ? t(`layout.header.title.${app}`) : '';

        if (user == null) {
            return null;
        }

        const dropdownProps = {
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
            getContentAnchorEl: null,
            transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
        };

        return (
            <AppBar className={classes.appBar} color="primary" position="static" elevation={0}>
                <Toolbar className={classes.toolbar}>
                    <Hidden mdUp implementation="css">
                        <IconButton className={classes.menuButton} color="inherit" onClick={toggleSideNav}>
                            <MenuIcon />
                        </IconButton>
                    </Hidden>

                    <div className={classes.navContainer}>
                        <div className={classes.title}>{title}</div>
                        <nav className={classes.navigation}>
                            {navItems.map((props) => (
                                <Link {...props} className={cx(classes.link, { [classes.active]: props.isActive })}>
                                    {t(`layout.header.nav.${props.key}`)}
                                </Link>
                            ))}
                        </nav>
                    </div>

                    <div className={classes.dropdownContainer}>
                        <Dropdown
                          {...dropdownProps}
                          MenuListProps={{ disableListWrap: true, disablePadding: true }}
                          anchor={(
                              <IconButton color="inherit">
                                  <Badge badgeContent={unreadCount} color="secondary">
                                      <NotificationsIcon />
                                  </Badge>
                              </IconButton>
                          )}
                          classes={{ menu: classes.notifications }}
                          disableAutoFocusItem
                        >
                            {this.renderNotifications}
                        </Dropdown>
                        <Dropdown
                          {...dropdownProps}
                          MenuListProps={{ disableListWrap: true }}
                          anchor={(<UserAvatar className={classes.avatar} user={user} />)}
                          classes={{ menu: classes.profileMenu }}
                          disableAutoFocusItem
                        >
                            {this.renderProfileMenu}
                        </Dropdown>
                    </div>
                </Toolbar>
            </AppBar>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    canAddFunds: session.selectors.canAddFunds(state),
    navItems: selectors.getNavItems(state, props),
    notifications: selectors.getNotifications(state),
    unreadCount: state.layout.header.unreadCount,
    user: state.session.user,
});
const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    checkNotifications: () => dispatch(actions.checkNotifications()),
    markAsRead: (notificationId: number) => dispatch(actions.markAsRead(notificationId)),
    toggleSideNav: () => dispatch(sideNav.actions.toggle()),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'Header' }),
    withTranslation(),
    connector
);

export default enhance(Header);
