// @flow
import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import type { ContextRouter } from 'react-router';
import CommonHelmet from '../../CommonHelmet';
import userIsNotAuthenticated from '../../auth/userIsNotAuthenticated';
import userIsAuthenticated from '../../auth/userIsAuthenticated';
import Login from '../Login';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';
import Signup from '../Signup';
import AcceptInvite from '../AcceptInvite';
import Layout from '../Layout';
import SnackbarManager from '../SnackbarManager';
import ConfirmDialog from '../ConfirmDialog';

type Props = ContextRouter;

const LoginPage = userIsNotAuthenticated(Login);
const ForgotPasswordPage = userIsNotAuthenticated(ForgotPassword);
const ResetPasswordPage = userIsNotAuthenticated(ResetPassword);
const SignupPage = userIsNotAuthenticated(Signup);
const AcceptInvitePage = userIsNotAuthenticated(AcceptInvite);
const MainLayout = userIsAuthenticated(Layout);

export class App extends Component<Props> {
    componentDidMount() {
        // Remove the server-side injected CSS.
        const jssStyles = document.getElementById('jss-server-side');
        if (jssStyles && jssStyles.parentNode) {
            jssStyles.parentNode.removeChild(jssStyles);
        }
    }

    render() {
        const { location } = this.props;

        return (
            <div style={{ height: '100%' }}>
                <CommonHelmet />
                <SnackbarManager />
                <ConfirmDialog />
                <Switch>
                    <Route path="/login" component={LoginPage} />
                    <Route path="/forgot-password" component={ForgotPasswordPage} />
                    <Route path="/reset-password" component={ResetPasswordPage} />
                    <Redirect from="/reset_password" to={{ pathname: '/reset-password', search: location.search }} />
                    <Route path="/signup" component={SignupPage} />
                    <Route path="/accept-invitation" component={AcceptInvitePage} />
                    <Redirect from="/accept_invitation" to={{ pathname: '/accept-invitation', search: location.search }} />
                    <Route path="/:app" component={MainLayout} />
                    <Redirect from="/" to="/login" />
                </Switch>
            </div>
        );
    }
}

export default withRouter(App);
