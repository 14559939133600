// @flow

/**
 * RegExUtil is a static utility that helps with validating strings
 */

/**
 * accept only positive and negative numbers
 * @method positiveAndNegNumber
 * @param input
 */
export function positiveAndNegativeNumber(input: string): boolean {
    const regex = /^(-(?!\.))?\d*\.?\d*$/;
    return regex.test(input);
}

/**
 * accept only positive numbers
 * @method positiveAndNegNumber
 * @param input
 */
export function positiveNumberOnly(input: string): boolean {
    const regex = /^\d*\.?\d*$/;
    return regex.test(input);
}

/**
 * check if format == YYYY-MM-DD HH:MM PM (or AM)
 * @method date_YYYY_MM_DD_HH_MM_AM
 * @param input
 * @returns {Boolean}
 */
export function date_YYYY_MM_DD_HH_MM_AM(input: string): boolean { // eslint-disable-line camelcase
    const regex = /^\d{4}[/-]\d{2}[/-]\d{2}\s\d{2}[-:]\d{2}\s[APap][mM]$/;
    return regex.test(input);
}

/**
 * @method date_YYYY_MM_DD
 * @param input
 * @returns {Boolean}
 */
export function date_YYYY_MM_DD(input: string): boolean { // eslint-disable-line camelcase
    const regex = /^\d{4}[/-]\d{2}[/-]\d{2}$/;
    return regex.test(input);
}

/**
 * @method stripBBCode
 * @param input
 * @returns {*}
 */
export function stripBBCode(input: string): string {
    // this is not being found with the RegEX, I don't know how to include *
    let out = input.split('[*]').join('');

    // need to replace linebreak of the text of the RegEx stops at line 1
    out = out.replace(/(\r\n|\n|\r)/gm, '<br>');

    // remove [img] tags and the urls within them
    out = out.replace(/\[img].*[/img]/gm, '');

    // remove all other tags, leaving their inner content
    out = out.replace(/\[([a-z]+)(=[\w\d.,\\/"'#-]*)*( *[a-z0-9]+=.+)*](.*?)\[\/1]/gi, '$4');
    return out;
}

export default {
    positiveAndNegativeNumber,
    positiveNumberOnly,
    date_YYYY_MM_DD_HH_MM_AM,
    date_YYYY_MM_DD,
    stripBBCode,
};
