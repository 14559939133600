var Handlebars = require('/tmp/build_c41768ab/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"arrow\"><span class=\"im icon-arrow-up\" data-action=\"collapse\"></span></div>";
},"3":function(container,depth0,helpers,partials,data) {
    return "has-metadata";
},"5":function(container,depth0,helpers,partials,data) {
    return "";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.canDelete : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.canEdit : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "<a class=\"im icon-trash\" href=\"javascript:;\" data-action=\"delete\"></a>";
},"10":function(container,depth0,helpers,partials,data) {
    return "<a class=\"im icon-edit\" href=\"javascript:;\" data-action=\"edit\"></a>";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n        <!-- main action button -->\n        "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.canEdit : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<a class=\"answerButton\" data-action=\"done\"><span class=\"glyphicon glyphicon-refresh loading-xsm\"\n                                                                        data-buffering></span>"
    + container.escapeExpression(((helper = (helper = helpers.done || (depth0 != null ? depth0.done : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"done","hash":{},"data":data}) : helper)))
    + "</a>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<!-- -------------------- Template --------------------- -->\n<div class=\"instruction template widget-panel\">\n    <!-- widget header -->\n    <div class=\"header\">\n        <div class=\"controls\">\n            <div class=\"ti type\">\n                <span class=\"optional\"></span>\n                <span class=\"type\">"
    + alias3(((helper = (helper = helpers.titleBar || (depth0 != null ? depth0.titleBar : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"titleBar","hash":{},"data":data}) : helper)))
    + "</span>\n            </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.canToggle : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            <div class=\"map\">\n                <span class=\"im icon-map-pin\" data-action=\"viewLatLon\" style=\"display:none\"></span>\n            </div>\n            <div class=\"dragger\"><span class=\"im icon-empty\"></span></div>\n        </div>\n        <div class=\"actions\">\n            <div class=\"ti target\" data-action=\"view-metadata\">\n                "
    + alias3((helpers.dynamicTarget || (depth0 && depth0.dynamicTarget) || alias2).call(alias1,(depth0 != null ? depth0.target : depth0),{"name":"dynamicTarget","hash":{},"data":data}))
    + "\n                <div class=\"metadata popup "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasMetadata : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-target=\"target-metadata\"\n                     style=\"display:none;\"></div>\n            </div>\n            <div class=\"viewing icon-buttons\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.readMode : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n\n    <!-- widget content -->\n    <div data-target=\"instructions\"></div>\n\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.readMode : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});