// @flow weak
import Hint from './Hint';
import Photo from './Photo';
import Question from './Question';
import Task from './Task';
import StatedView from '../../../../shared/view/StatedView';
import ProjectConstant from '../../../../shared/constant/ProjectConstant';
import tpl from '../../../templates/ticket/widgets/Template.hbs';

// Ideally, Template would inherit from Widget or at least have a similar API.
// The way Widget is currently implemented prevents this

const Template = StatedView.extend({

    events: {
        'click [data-action="collapse"]': 'toggleCollapse',
        'click [data-action="edit"]': 'edit',
        'click [data-action="delete"]': 'delete',
        'click [data-action="done"]': 'done',
    },

    initialize(options: Object) {
        this._super(options);

        this.props = options.props;
        this.ticketID = parseInt(options.ticketID, 10) || 0;
        this.targetID = parseInt(options.targetID, 10) || 0;
        this.templateID = parseInt(options.templateID, 10) || 0;
        this.readMode = !!options.readMode;
        this.controls = _.isArray(options.controls) ? options.controls : [];

        const { instructions } = this.props;
        const template = instructions.findWhere({ template_id: this.templateID });
        this.instructions = template.explode({ observation_target_id: this.targetID });

        this.text = GW.localisation.tickets.tickets.details.widgets;
        this.widgets = [];

        this.render();
    },

    render() {
        this.$el.hide();

        const { template, ticket } = this.props;

        const targetName = ticket.observation_target_map[this.targetID] || '';
        const type = (template.self_directed) ? 'self_directed' : (template.type || 'default').toLowerCase();
        const data = {
            titleBar: this.text.templates[type],
            target: (template ? `${template.title}/ ` : '') + targetName,
            readMode: this.readMode,
            canToggle: this.controls.indexOf('toggle') >= 0,
            canEdit: this.controls.indexOf('edit') >= 0,
            canDelete: this.controls.indexOf('delete') >= 0,
        };

        this.$el.html(tpl(_.extend(data, this.text)));

        // Don't display template header if no controls are active
        if (!this.controls.length) {
            this.$el.find('.header').remove();
        }

        this.$els = {
            instructions: this.$el.find('[data-target=\'instructions\']'),
            doneBtn: this.$el.find('[data-action=\'done\']'),
        };

        this.instructions.each((instruction: Object) => {
            const widget = this._createInstructionWidget(instruction);
            widget.onSave = (...args: Array<any>) => {
                if (typeof (this.onSave) === 'function') this.onSave(...args);
            };

            this.$els.instructions.append(widget.$el);
            this.widgets.push(widget);
        }, this);

        this.$el.show();
    },

    _createInstructionWidget(instruction: Object) {
        const { ticket } = this.props;
        const dataTypeID = instruction.get('data_type_id');
        const dataType = ticket.data_type_map[dataTypeID];

        const options = {
            instruction,
            ticketID: this.ticketID,
            readMode: this.readMode,
            showLocations: false,
            props: { ...this.props },
        };

        let valueType = dataType.value_type;
        let widget = null;

        if (instruction.links.length) {
            valueType = ProjectConstant.VALUE_TYPE.TASK;
        }

        switch (valueType) {
            case ProjectConstant.VALUE_TYPE.HINT:
            case ProjectConstant.VALUE_TYPE.CHECK:
                widget = new Hint(options);
                break;

            case ProjectConstant.VALUE_TYPE.PHOTO:
                widget = new Photo(options);
                break;

            case ProjectConstant.VALUE_TYPE.BARCODE:
            case ProjectConstant.VALUE_TYPE.MULTIPLE_CHOICE:
            case ProjectConstant.VALUE_TYPE.MULTI_SELECT:
            case ProjectConstant.VALUE_TYPE.CHECKBOXES:
            case ProjectConstant.VALUE_TYPE.NUMBER:
            case ProjectConstant.VALUE_TYPE.CURRENCY:
            case ProjectConstant.VALUE_TYPE.DATE:
            case ProjectConstant.VALUE_TYPE.TIME:
            case ProjectConstant.VALUE_TYPE.FREE_TEXT:
                widget = new Question(options);
                break;

            case ProjectConstant.VALUE_TYPE.TASK:
                widget = new Task(options);
                break;
            default:
                break;
        }

        return widget;
    },

    toggleCollapse() {
        this.$els.doneBtn.toggle();
        this.$els.instructions.toggle();
        this.$el.toggleClass('collapsed');
    },

    delete() {
        const { ticket } = this.props;
        const dataItems = _.where(ticket.data_items, {
            template_id: this.templateID,
            observation_target_id: this.targetID,
        });

        const deleteDataItems = dataItems.map((dataItem: { id: number }) => (
            this.props.deleteDataItem({
                ticket_id: ticket.id,
                data_item_id: dataItem.id,
            })
        ));

        Promise.all(deleteDataItems)
            .then(() => {
                if (typeof this.onDelete === 'function') this.onDelete();
                this.props.fetchTicket({ ticket_id: ticket.id });
            });
    },

    edit() {
        this.$els.doneBtn.show();
        this.$els.instructions.show();
        this.$el.removeClass('collapsed');
    },

    done() {
        // TODO: Save answers before toggling close?
        this.$els.doneBtn.hide();
        this.$els.instructions.hide();
        this.$el.addClass('collapsed');
        if (typeof (this.onDone) === 'function') this.onDone();
    },
});

export default Template;
