// @flow
export default (theme: Object) => ({
    root: {
        fontFamily: ['ProximaNova', '!important'],
        fontSize: 16,
        justifyContent: 'flex-start',
    },
    input: {
        backgroundColor: theme.palette.common.white,
        marginRight: theme.spacing(1),
    },
    badge: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
    },
});
