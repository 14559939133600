// @flow
import React, { Component } from 'react';
import classnames from 'classnames';
import type { DatepickerOptions, JQuery as DatepickerJQuery } from 'bootstrap-datepicker';
import type { TimePickerOptions, TimepickerJQuery } from 'timepicker';
import logger from '../../../../common/util/logger';

export type Props = {
    minDate?: moment$Moment | string,
    maxDate?: moment$Moment | string,
    dateFormat?: string,
    orientation?: string,
    value: ?string,
    dirty?: boolean,
    valid?: boolean,
    validationMessage?: string,
    mode: ?'date' | ?'datetime' | ?'time',
    beforeShowDay?: (date: Date) => ?boolean,
    inputProps: {
        readOnly: boolean,
        timeFormat24Hour?: boolean,
        placeholder?: string,
    },
    onChange?: (date: string) => void,
};

export default class DateTimePicker extends Component<Props> {
    componentWillMount() {
        const { maxDate, minDate, mode } = this.props;
        // is this a datepicker, timepicker, or a combination
        this.showDate = !mode || mode === 'date' || mode === 'datetime';
        this.showTime = !mode || mode === 'time' || mode === 'datetime';

        this.minDate = minDate;
        this.maxDate = maxDate;
    }

    componentDidMount() {
        const { value, minDate, maxDate, onChange, inputProps, dateFormat, orientation, beforeShowDay } = this.props;

        if (this.showDate) {
            let datepickerOptions: DatepickerOptions;
            datepickerOptions = {
                maxViewMode: 0,
                orientation: orientation || 'top left',
                format: dateFormat || 'm/d/yyyy',
                autoclose: true,
                language: GW.localisation.lang,
                startDate: moment(minDate, 'x').toDate(),
                container: this.$container[0],
                beforeShowDay: beforeShowDay || $.noop,
                zIndexOffset: 1100,
            };
            if (maxDate) {
                datepickerOptions = {
                    ...datepickerOptions,
                    endDate: moment(maxDate, 'x').toDate(),
                };
            }

            // initialize bootstrap-datepicker
            this.$date.datepicker(datepickerOptions);
            // set the default date values
            this.$date.datepicker('setDate', moment(value, 'x').toDate());
            this.$date.datepicker('update');
        }

        if (this.showTime) {
            const timepickerOptions: TimePickerOptions = {
                showDuration: false,
                timeFormat: inputProps.timeFormat24Hour ? 'G:i' : 'g:ia',
                orientation: 't',
                appendTo: this.$container,
                wrapHours: false,
            };

            if (minDate) {
                timepickerOptions.minTime = (typeof minDate === 'string' ? moment(minDate, 'x') : moment(minDate)).toDate();
            }
            if (maxDate) {
                timepickerOptions.maxTime = (typeof maxDate === 'string' ? moment(maxDate, 'x') : moment(maxDate)).toDate();
            }

            // initialize timepicker
            this.$time.timepicker(timepickerOptions);

            // on click of "clock" icon, show timepicker
            if (!inputProps.readOnly) {
                this.$timeContainer.on('click', () => this.$time.timepicker('show'));
            }
        }

        if (this.showDate) {
            this.$date.on('changeDate', () => {
                let selectedDateTime: string = this.$date.datepicker('getDate');
                if (this.showTime) {
                    if (this.$time.timepicker('getTime')) {
                        selectedDateTime = this.$time.timepicker('getTime', this.$date.datepicker('getDate'));
                    } else {
                        // When date is changed and time is not set, let's set time picker with value.
                        // This is required when props.value is not updated on onChange.
                        this.$time.timepicker('setTime', selectedDateTime);
                    }
                }
                if (onChange) {
                    onChange(`${moment(selectedDateTime, 'x').valueOf()}`);
                }
            });
            if (this.showTime) {
                this.$time.on('changeTime', () => {
                    const datepickerDate = this.$date.datepicker('getDate');
                    const selectedDateTime = this.$time.timepicker('getTime', datepickerDate);
                    if (!datepickerDate) {
                        this.$date.datepicker('setDate', selectedDateTime);
                    }
                    if (onChange) {
                        onChange(`${moment(selectedDateTime, 'x').valueOf()}`);
                    }
                });
            }
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        const { inputProps, value } = this.props;

        if (nextProps.value !== value) {
            if (this.showDate) {
                this.$date.datepicker('setDate', moment(nextProps.value, 'x').toDate());
                this.$date.datepicker('update');
            }

            if (this.showTime) {
                this.$time.timepicker('setTime', moment(nextProps.value, 'x').toDate());
            }
        }

        if (this.showTime && nextProps.inputProps.readOnly !== inputProps.readOnly) {
            if (nextProps.inputProps.readOnly) {
                this.$timeContainer.off('click');
            } else {
                this.$timeContainer.on('click', () => this.$time.timepicker('show'));
            }
        }
    }

    componentWillUnmount() {
        if (this.showDate) {
            this.$date.off('changeDate');
            this.$date.datepicker('destroy');
        }

        if (this.showTime) {
            this.$timeContainer.off('click');
            this.$time.timepicker('remove');
            this.$time.off('changeTime');
        }
    }

    setMinDate(newMinDate: ?moment$Moment) {
        let _newMinDate: moment$Moment;

        if (!newMinDate) {
            _newMinDate = moment(1, 'x');
        } else {
            _newMinDate = moment(newMinDate);
        }

        if (!_newMinDate.isValid()) { // assume it's unix timestamp in string
            logger.error({ date: _newMinDate.toDate() }, 'invalid date provided for setMinDate');
            _newMinDate = moment(newMinDate, 'x');
        }

        if (this.minDate == null || moment(this.minDate, 'x').diff(_newMinDate)) {
            this.$date.datepicker('setStartDate', _newMinDate.toDate());
            this.minDate = _newMinDate.format('x');
        }
    }

    setMaxDate(newMaxDate: moment$Moment) {
        let _newMaxDate: moment$Moment = moment(newMaxDate);

        if (!_newMaxDate.isValid()) { // assume it's unix timestamp in string
            logger.error({ date: _newMaxDate.toDate() }, 'invalid date provided for setMaxDate');
            _newMaxDate = moment(newMaxDate, 'x');
        }

        if (this.maxDate == null || moment(this.maxDate, 'x').diff(_newMaxDate)) {
            this.$date.datepicker('setEndDate', _newMaxDate.toDate());
            this.maxDate = _newMaxDate.format('x');
        }
    }

    getValue(): moment$Moment {
        let selectedDateTime = null;
        if (this.showDate) {
            if (this.showTime && this.$time.timepicker('getTime')) {
                selectedDateTime = this.$time.timepicker('getTime', this.$date.datepicker('getDate'));
            } else if (this.showDate) {
                selectedDateTime = this.$date.datepicker('getDate');
            }
        } else if (this.showTime && this.$time.timepicker('getTime')) {
            selectedDateTime = this.$time.timepicker('getTime');
        }
        return moment(selectedDateTime);
    }

    setValue(newValue: moment$Moment) {
        const currentValue = this.getValue();
        let _newValue = moment(newValue);
        if (!_newValue.isValid()) { // assume it's unix timestamp in string
            _newValue = moment(newValue, 'x');
        }

        if (currentValue.isValid() && _newValue.isValid() && !currentValue.diff(_newValue)) {
            return;
        }

        if (this.showTime) {
            this.$time.timepicker('setTime', _newValue.toDate());
        }

        if (this.showDate) {
            this.$date.datepicker('setDate', _newValue.toDate());
        }
    }

    minDate: moment;
    maxDate: ?moment;
    showDate: boolean;
    showTime: boolean;
    $date: DatepickerJQuery;
    $time: TimepickerJQuery;
    $timeContainer: JQuery;
    $container: JQuery;

    renderDate() {
        const { inputProps } = this.props;
        const { placeholder, readOnly } = inputProps;
        return (
            <div className="date" ref={(c) => { this.$date = c ? $(c) : $(); }}> {/* .date used by bootstrap-datepicker */}
                <div
                  className={classnames({ 'c--disabled': !!readOnly }, 'c-form__addon c--date add-on')}
                > {/* .add-on used by bootstrap-datepicker */}
                    <input
                      type="text"
                      disabled={!!readOnly}
                      placeholder={placeholder}
                    />
                </div>
            </div>
        );
    }

    renderTime() {
        const { value, inputProps } = this.props;
        // if we receive a value set it to that. Otherwise, blank.
        const defaultTime = value ? moment(value, 'x').format('h:mma') : undefined;
        return (
            <div
              className={classnames({ 'c--disabled': !!inputProps.readOnly }, 'c-form__addon c--time')}
              ref={(c) => { this.$timeContainer = c ? $(c) : $(); }}
            >
                <input
                  type="text"
                  ref={(c) => { this.$time = c ? $(c) : $(); }}
                  className="time"
                  defaultValue={defaultTime}
                  disabled={!!inputProps.readOnly}
                /> {/* .time used by timepicker */}
            </div>
        );
    }

    render() {
        const { valid, validationMessage, dirty, inputProps: { readOnly } } = this.props;
        return (
            <div className={classnames({ 'c-form__error': !readOnly && dirty && !valid })}>
                <div
                  className={classnames('c--datetimepicker-container', { 'c-form-inline-group c--break-on-medium-mobile': this.showDate && this.showTime })}
                  ref={(c) => { this.$container = c ? $(c) : $(); }}
                >
                    { this.showDate && this.renderDate() }
                    { this.showTime && this.renderTime() }
                </div>
                <p className="c-form__error-message">
                    {!readOnly && dirty && !valid ? validationMessage : ''}
                </p>
            </div>
        );
    }
}
