// @flow
import { createSelector } from 'reselect';
import type { ContextRouter } from 'react-router';
import { parseSearchParams } from '../../../duck/selectors';
import entitySelector from '../../../../../redux/entities/entitySelector';
import type { State as RootState } from '../../../../../redux/initialState';

export { parseSearchParams } from '../../../duck/selectors';

const _statusFilterOptions = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Deleted', value: 'DELETED' },
    { label: 'Inactive', value: 'INACTIVE' },
    { label: 'Pending', value: 'PENDING' },
    { label: 'Suspended', value: 'SUSPENDED' },
];

export const getStatusFilterOptions = (): Object[] => _statusFilterOptions;

const getSelectedStatuses = createSelector(
    parseSearchParams,
    (search: Object) => search.status || [],
);
export const getStatusFilterValue = createSelector(
    getSelectedStatuses,
    getStatusFilterOptions,
    (selected: string[], statusFilterOptions: Object[]): Object[] => (
        selected.reduce((acc, value) => {
            const selectedOption = statusFilterOptions.find((option) => option.value === value);
            if (selectedOption) {
                acc.push(selectedOption);
            }
            return acc;
        }, [])
    )
);

const getPlaces = (state: RootState): mixed[] => Object.values(state.googleMaps.places || {});
const getSelectedGeoPlaces = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): Object[] => {
    const search = parseSearchParams(state, props);
    return search.geo || [];
};
export const getGeoFilterValue = createSelector(
    getSelectedGeoPlaces,
    getPlaces,
    (selected: Object[], places: mixed[]): Object[] => (
        selected.reduce((acc: Object[], geo: Object) => {
            const [type, address] = Object.entries(geo)[0];
            const place = places.find((p: mixed) => (
                p && typeof p === 'object' ? p.formatted_address === address : false
            ));
            if (place && typeof place === 'object') {
                const value = {
                    label: place.formatted_address,
                    value: {
                        ...place,
                        filter_key: type,
                    },
                };
                return [...acc, value];
            }
            return acc;
        }, [])
    )
);

const organizationSelector = entitySelector('organizations');
const getSelectedOrganizations = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): Object[] => {
    const search = parseSearchParams(state, props);
    const selected = search.organization || [];
    return organizationSelector(state, selected);
};
export const getOrganizationFilterValue = createSelector(
    getSelectedOrganizations,
    (selected: Object[]) => (
        selected.map((organization: Object): Object => ({
            label: organization.organization_name,
            value: organization,
        }))
    )
);

export default {
    getGeoFilterValue,
    getOrganizationFilterValue,
    getStatusFilterOptions,
    getStatusFilterValue,
    parseSearchParams,
};
