// @flow
export default () => ({
    root: {},
    wrapper: {},
    label: {},
    disabled: {},
    checkbox: {},
    errorMessage: {
        marginTop: 0,
    },
});
