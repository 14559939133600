// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { hoistStatics } from 'recompose';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Button, Dialog, DialogTitle } from '@material-ui/core';
import {
    Clear as ClearIcon,
    Person as PersonIcon,
} from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import getDisplayName from '../../util/getDisplayName';
import ApprovalForm from './components/ApprovalForm';
import RatingForm from './components/RatingForm';
import * as dialog from '../../ducks/dialog';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../redux/initialState';

type OwnProps = {};
type StateProps = {
    onClose: () => void,
    onSubmitFail: () => void,
    onSubmitSuccess: () => void,
    open: boolean,
    ticketId: ?number,
    ticket: ?Object,
};
type DispatchProps = {
    registerDialog: (name: string) => void,
    submit: (values: Object) => Promise<void>,
    unregisterDialog: (name: string) => void,
};
type Props = OwnProps & StateProps & DispatchProps;

const ReviewSchema = yup.object().shape({
    approvalStatus: yup.string()
        .oneOf([null, 'APPROVED', 'REJECTED']),
    rating: yup.number()
        .nullable(),
    ticketId: yup.number(),
});

const useStyles = makeStyles(styles, { name: 'ReviewDialog' });

export function ReviewDialog(props: Props) {
    const {
        onClose,
        onSubmitFail,
        onSubmitSuccess,
        open,
        registerDialog,
        submit,
        ticket,
        ticketId,
        unregisterDialog,
    } = props;

    const needsApproval = ticket && ticket.subscription ? ticket.subscription.needs_approval : false;
    const twoWayRatingEnabled = ticket && ticket.subscription ? ticket.subscription.two_way_rating_enabled : false;
    const needsPublicWorkforce = ticket && ticket.subscription ? ticket.subscription.needs_public_workforce : false;
    const displayName = ticket && ticket.customer
        ? getDisplayName(ticket.customer, { obfuscate: needsPublicWorkforce })
        : null;

    const classes = useStyles(props);
    const [currentStep, setCurrentStep] = useState('reviewGig');

    useEffect(() => {
        setCurrentStep(!needsApproval && twoWayRatingEnabled ? 'rateWorker' : 'reviewGig');
    }, [needsApproval, twoWayRatingEnabled]);

    useEffect(() => {
        registerDialog(ReviewDialog.NAME);
        return () => {
            unregisterDialog(ReviewDialog.NAME);
        };
    }, [registerDialog, unregisterDialog]);

    return (
        <Formik
          enableReinitialize
          initialValues={{ ticketId }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
              if (currentStep === 'reviewGig' && twoWayRatingEnabled) {
                  setCurrentStep('rateWorker');
                  setSubmitting(false);
              } else {
                  submit(values)
                      .then(() => {
                          setStatus({ success: true });
                          onSubmitSuccess();
                      })
                      .catch((error) => {
                          setStatus({ error });
                          onSubmitFail();
                      })
                      .then(() => setSubmitting(false));
              }
          }}
          validationSchema={ReviewSchema}
        >
            {(formikProps: Object) => {
                const { handleReset } = formikProps;
                const handleExited = () => {
                    setCurrentStep('reviewGig');
                    handleReset();
                };

                return (
                    <Dialog
                      classes={{ paper: classes.dialogPaper }}
                      fullWidth
                      maxWidth="xs"
                      onClose={onClose}
                      onExited={handleExited}
                      open={open}
                    >
                        <Button className={classes.cancel} color="inherit" onClick={onClose}>
                            <ClearIcon />
                        </Button>
                        <DialogTitle className={classes.dialogTitle} disableTypography>
                            <Avatar className={classes.avatar}>
                                <PersonIcon className={classes.avatarIcon} />
                            </Avatar>
                            <h1>{displayName}</h1>
                        </DialogTitle>
                        {currentStep === 'reviewGig'
                            ? <ApprovalForm {...formikProps} />
                            : <RatingForm {...formikProps} />
                        }
                    </Dialog>
                );
            }}
        </Formik>
    );
}

ReviewDialog.NAME = 'review';
ReviewDialog.defaultProps = {
    onClose: () => {},
    onSubmitFail: () => {},
    onSubmitSuccess: () => {},
    open: false,
    ticketId: null,
};

const mapStateToProps = (state: RootState): StateProps => {
    const props = {
        ...ReviewDialog.defaultProps,
        ...state.dialog.registered[ReviewDialog.NAME],
    };
    return {
        ...props,
        ticket: selectors.getTicket(state, props),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    registerDialog: (name: string) => dispatch(dialog.actions.register(name)),
    submit: (values: Object) => dispatch(actions.submit(values)),
    unregisterDialog: (name: string) => dispatch(dialog.actions.unregister(name)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default hoistStatics(connector)(ReviewDialog);
