// @flow
import { createSelector, createStructuredSelector } from 'reselect';
import { selectors as projectBuilder } from '../../../duck';

// type FormValues = {
//     questionList: Question[],
// };

export const getInitialValues = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    projectBuilder.getInitialValues,
    createStructuredSelector({
        locationListId: (values: Object) => values.locationListId,
        questionList: (values: Object) => values.questionList,
        needsPublicWorkforce: (values: Object) => values.needsPublicWorkforce,
    })
);

export default {
    getInitialValues,
};
