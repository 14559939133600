// @flow
import logger from '../../../common/util/logger';

/**
 * DateUtil is a static utility that helps formatting Date String out of the timestamps
 */
type GigwalkAPITimestamp = {
    __type__: "datetime",
    second: number,
    microsecond: number,
    hour: number,
    year: number,
    day: number,
    minute: number,
    month: number,
};

/**
 * Using locale time format determine if uses 12-hour clock or 24-hour clock format
 * @method getDateFormat
 * @returns {*}
 */
export function is24HoursClock(): boolean {
    const date = new Date();

    try {
        const dateString: string = date.toLocaleTimeString();
        return !(dateString.match(/am|pm/i) || date.toString().match(/am|pm/i));
    } catch (err) {
        logger.error(err, 'toLocaleTimeString not supported');
        return false;
    }
}

/**
 * Returns the format for time used by the user
 */
export function getLocalTimeFormat(): string {
    if (is24HoursClock()) {
        return 'HH:mm';
    }
    return 'hh:mm A';
}

/**
 * Returns the format for date times used by the user
 * @returns {*}
 */
export function getLocalDateFormat(): string {
    if (is24HoursClock()) {
        return 'YYYY-MM-DD HH:mm';
    }
    return 'YYYY-MM-DD hh:mm A';
}

/**
 * Returns a version truncated of the date's range<br>
 * Sep 12 - Oct 01
 *
 * @method getDueDate
 * @param {Object} startDate
 * @param {Object} endDate
 * @returns {string}
 */
export function getDueDate(startDate: GigwalkAPITimestamp, endDate: GigwalkAPITimestamp): string {
    const monthNamesInText = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const startMonth = monthNamesInText[startDate.month - 1];
    const endMonth = monthNamesInText[endDate.month - 1];

    return `${startMonth} ${startDate.day} - ${endMonth} ${endDate.day}`;
}

/**
 * Returns the date's range in full<br>
 * September 12 - October 01
 *
 * @method getDueDateFullMonth
 * @param {Object} startDate
 * @param {Object} endDate
 * @returns {string}
 */
export function getDueDateFullMonth(startDate: GigwalkAPITimestamp, endDate: GigwalkAPITimestamp): string {
    const monthNamesInText = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const startMonth = monthNamesInText[startDate.month - 1];
    const endMonth = monthNamesInText[endDate.month - 1];

    return `${startMonth} ${startDate.day} - ${endMonth} ${endDate.day}`;
}

/**
 * Convert a date to a timestamp object formatted like
 * @method toAppTimestamp
 * @param date
 * @example
 *      date:{
            second:0,
            microsecond:0,
            hour:0,
            year:2014,
            __type__:"datetime",
            day:3,
            minute:0,
            month:1
        }
 */
export function toAppTimestamp(date: moment$Moment | Date | string | number): GigwalkAPITimestamp {
    const datetime = moment(date);

    if (!datetime.isValid()) {
        logger.warn('toAppTimestamp expects a valid date:', date);
    }

    return {
        __type__: 'datetime',
        second: datetime.seconds(),
        microsecond: datetime.milliseconds() * 1000,
        hour: datetime.hours(),
        year: datetime.year(),
        day: datetime.date(),
        minute: datetime.minutes(),
        month: datetime.month() + 1,
    };
}

const SERVER_TIMESTAMP_FORMAT: string = 'YYYY-MM-DD HH:mm:ss';

/**
 * Format an App Timestamp to "YYYY-MM-DD HH:MM:SS"
 * @method appToServerStamp
 * @param appTimestamp
 */
export function appToServerStamp(appTimestamp: GigwalkAPITimestamp): string {
    const { year, month, day, hour, minute, second, microsecond } = appTimestamp;
    const datetime = moment({
        year,
        month: month - 1,
        date: day,
        hour,
        minute,
        second,
        millisecond: Math.round(microsecond / 1000),
    });

    return datetime.format(SERVER_TIMESTAMP_FORMAT);
}

/**
 * @method JSDateToFormattedDate
 * @param date
 * @returns {*}
 * @constructor
 */
export function JSDateToFormattedDate(date: moment$Moment | Date | string): string {
    return appToServerStamp(toAppTimestamp(date));
}

/**
 * @method formattedDateToTimestamp
 * @param date
 * @returns {number}
 */
export function formattedDateToTimestamp(date: string): number { // UTC
    if (moment(date).isValid()) {
        return parseInt(moment(date).format('X'), 10) * 1000;
    }

    logger.warn('formattedDateToTimestamp is expecting a valid date');
    return NaN;
}

/**
 * Returns a date with the following format YYYY/DD/MM
 *
 * @method toYYYYDDMM
 * @param timestamp
 * @returns {string}
 */
export function toYYYYDDMM(timestamp: GigwalkAPITimestamp): string {
    const datetime = moment(appToServerStamp(timestamp), SERVER_TIMESTAMP_FORMAT);
    return datetime.isValid() ? datetime.format('YYYY/DD/MM') : '????/??/??';
}

/**
 * Returns a date with the following format MM/DD/YYYY
 *
 * @method toMMDDYYYY
 * @param timestamp
 * @returns {string}
 */
export function toMMDDYYYY(timestamp: GigwalkAPITimestamp): string {
    const datetime = moment(appToServerStamp(timestamp));
    return datetime.isValid() ? datetime.format('MM/DD/YYYY') : '??/??/????';
}

/**
 * Duration can only be in Minutes or Hours
 * Returns { time: time, unit: ( "minutes" || "hours"), toText: function }<br>
 *
 * @method secondsToMaxUnit
 * @param seconds
 * @returns {Object}
 */
export function secondsToMaxUnit(seconds: number): { time: number, unit: string, toText: () => string } {
    const units = ['hours', 'minutes'];
    const durationInMinute = ~~(parseInt(seconds, 10) / 60); // eslint-disable-line no-bitwise
    const durationInHours = durationInMinute / 60;
    const inHours = durationInHours === ~~(durationInHours); // eslint-disable-line no-bitwise
    const duration = inHours ? durationInHours : durationInMinute;

    const unit = inHours ? units[0] : units[1];
    const unitStr = duration !== 1 ? unit : unit.substring(0, unit.length - 1);

    return {
        time: duration,
        unit,
        toText() {
            return `${duration} ${unitStr}`;
        },
    };
}

/**
 * @method secondsToMaxUnits <br>
 * @param seconds <br>
 *
 * returns {
 *   hours: Number,
 *   minutes: Number,
 *   seconds: Number,
 *
 *   toText: Function
 * }
 * @returns {Object}
 */
export function secondsToMaxUnits(seconds: number) {
    const units = ['hours', 'minutes', 'seconds'];

    // should not be inferior to 1 minute
    if (!seconds || seconds < 60) {
        return {
            hours: 0,
            minutes: 1,
            seconds: 0,
            toText() {
                return '1 minute';
            },
        };
    }

    // in hours
    let time = seconds / 3600;

    const ret = {};

    for (let i = 0, len = units.length; i < len; i += 1) {
        time = Math.round(time * 3600) / 3600;

        const unit = units[i];

        ret[unit] = ~~(time); // eslint-disable-line no-bitwise

        time -= ret[unit];

        time *= 60;
    }

    // TODO add localisation to this function
    ret.toText = () => {
        let text = '';

        for (let i = 0, len = units.length; i < len; i += 1) {
            const unit = units[i];
            const dur = ret[unit];

            if (dur) {
                if (i && text) {
                    text += ', ';
                }

                text = (`${text + dur} ${dur !== 1 ? unit : unit.substring(0, unit.length - 1)}`);
            }
        }

        return text;
    };

    return ret;
}

/**
 * Returns int<br>
 *
 * @method timeUnitToSeconds
 * @param time
 * @param unit
 * @returns {int}
 */
export function timeUnitToSeconds(time: number, unit: string): number {
    if (!time || !time.toString().length || !unit) {
        return -1;
    }

    switch (unit.toLowerCase()) {
        case 'seconds':
            return parseInt(time, 10);

        case 'minutes':
            return parseInt(time, 10) * 60;

        case 'hours':
            return parseInt(time, 10) * 3600;

        case 'days':
            return parseInt(time, 10) * 86400;

        case 'weeks':
            return parseInt(time, 10) * 604800;

        default:
            return 0;
    }
}

/**
 * @method toRange
 * @param startDate
 * @param endDate (optional)
 * @returns {String}
 *
 */
export function toRange(startDate: moment$Moment | Date | string, endDate?: moment$Moment | Date | string): string {
    const start = moment(startDate);
    let range = `${Math.round(start.unix())}`;

    if (endDate) {
        const end = moment(endDate);
        range = `${range}-${Math.round(end.unix())}`;
    }

    return range;
}

/**
 * @method rangeToDates
 * @returns {Array}
 */
export function rangeToDates(range: string): [Date, Date] {
    const parts = range.split('-');
    return [new Date(parseInt(parts[0], 10) * 1000), new Date(parseInt(parts[1], 10) * 1000)];
}

/**
 * @method rangeToISOStart
 * @param range {String}
 * @returns {String}
 */
export function rangeToISOStart(range: string): string {
    const parts = range.split('-');
    const start = moment.unix(parseInt(parts[0], 10));

    return start.format('YYYY-MM-DDTHH:mm:ss');
}

/**
 * @method rangeToISOEnd
 * @param range {String}
 * @returns {String}
 */
export function rangeToISOEnd(range: string): string {
    const parts = range.split('-');
    const end = moment.unix(parseInt(parts[1], 10));

    return end.format('YYYY-MM-DDTHH:mm:ss');
}

/**
 * This method determines which month to render and makes sure the date range
 * is bound by the calender month
 * @method boundRange
 * @param {Moment or Date} startDate
 * @returns [ moment(startDate), moment(endDate), moment(calendarMonth) ]
 */
export function boundRange(startDate: moment$Moment | Date): [moment$Moment, moment$Moment, moment$Moment] {
    const start = moment(startDate);

    let calendarMonth = moment(start).startOf('month');

    const d1 = start.diff(calendarMonth);
    const d2 = moment(start).endOf('month').diff(start);

    // Round up if startDate is closer to the end of the month than the beginning
    if (d2 < d1) {
        calendarMonth = calendarMonth.add(1, 'months');
    }

    const firstDay = moment(calendarMonth).subtract(calendarMonth.day() || 7, 'days');
    const lastDay = moment(firstDay).add(42, 'days').subtract(1, 'seconds');

    return [firstDay, lastDay, calendarMonth];
}

/**
 * @method isValidRange
 * @returns {Boolean}
 */
export function isValidRange(range: string): boolean {
    if (!range) {
        return false;
    }

    const startEnd = range.split('-');
    if (startEnd.length !== 2) {
        return false;
    }

    for (let i = 0, len = 2; i < len; i += 1) {
        const time = startEnd[i];

        if (`${parseInt(time, 10)}` !== `${time}`) {
            return false;
        }

        if (`${time}`.length < 2) {
            return false;
        }
    }

    return true;
}

/**
 * Converts a date to an timezone aware datetime string in local time.
 * @param date
 * @returns {String}
 */
export function toTimezoneAwareString(date: moment$Moment | Date | string): string {
    const datetime = moment(date);
    if (!datetime.isValid()) {
        // TODO: Log error messge?
    }

    return datetime.format('YYYY-MM-DDTHH:mm:ssZZ');
}

/**
 * Converts a date to UTC datetime string. This is almost identical to an ISO formatted
 * string, but does not include the trailing 'Z' to indicate a UTC date.
 * @param date
 * @returns {String}
 */
export function toUTCString(date: moment$Moment | Date | string): string {
    const datetime = moment.utc(date);
    if (!datetime.isValid()) {
        // TODO: Log error messge?
    }

    return datetime.format('YYYY-MM-DDTHH:mm:ss');
}

/**
 * Converts a date into readable formatted string
 * @param date {Js Date Object || Date String || app date object as below}
 * date:{
        second:0,
        microsecond:0,
        hour:0,
        year:2014,
        __type__:"datetime",
        day:3,
        minute:0,
        month:1
    }
 * @returns {String}
 */
export function toReadableFormattedString(date: Date | GigwalkAPITimestamp | string): string {
    const datetime: moment$Moment = !(date instanceof Date) && typeof date !== 'string'
        ? moment(appToServerStamp(date), SERVER_TIMESTAMP_FORMAT)
        : moment(date);
    const dateFormat: string = getLocalDateFormat();
    return moment.utc(datetime).local().format(dateFormat);
}

/**
 * @method
 * @param duration
 * @returns {string}
 */
export function formatDuration(duration: moment$MomentDuration | number | string): string {
    let formattedStr = '';
    let _duration;
    let hours;
    let minutes;

    if (typeof duration === 'number' || typeof duration === 'string') {
        const tmp = parseInt(Number(duration), 10);
        if (!Number.isNaN(tmp)) {
            _duration = moment.duration(tmp, 'seconds');
        }
    } else {
        _duration = duration;
    }

    if (_duration) {
        hours = _duration.asHours();
        minutes = _duration.minutes();
        formattedStr = `${(hours > 0) ? hours : '00'}:${(minutes < 10) ? `0${minutes}` : minutes}`;
    }

    return formattedStr;
}
/**
 * Get durpation in unit
 * @type {number}
 */
export function getDuration(startDate: moment$Moment | Date | string, endDate: moment$Moment | Date | string, unit: string): number {
    const start = moment(startDate);
    const end = moment(endDate);

    if (!start.isValid() || !end.isValid()) {
        // TODO: Log warning/error?
        return 0;
    }

    return moment.duration(end.diff(start)).as(unit);
}

export default {
    getDueDate,
    getDueDateFullMonth,
    toAppTimestamp,
    appToServerStamp,
    JSDateToFormattedDate,
    formattedDateToTimestamp,
    toYYYYDDMM,
    toMMDDYYYY,
    secondsToMaxUnit,
    secondsToMaxUnits,
    timeUnitToSeconds,
    toRange,
    rangeToDates,
    rangeToISOStart,
    rangeToISOEnd,
    boundRange,
    isValidRange,
    toTimezoneAwareString,
    toUTCString,
    toReadableFormattedString,
    formatDuration,
    getDuration,
    getLocalDateFormat,
    is24HoursClock,
    getLocalTimeFormat,
};
