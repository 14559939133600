// @flow
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import debounce from 'lodash.debounce';
import cx from 'classnames';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import { Chip, CircularProgress, Divider, Icon, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import { compose } from 'recompose';
import type { FieldArrayProps, FormProps } from 'redux-form';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { TFunction } from 'react-i18next';
import type { Location as Gigwalk$Location } from 'gigwalk/lib/api/locationLists/types';
import { OPTIN_TYPES, SCHEDULE_TYPES } from '../../../../../browser/shared/constant/ProjectConstant';
import { USER_ROLES } from '../../../../../browser/shared/constant/UserRoles';
// @todo Create QuestionCard and QuestionFormCard components, latter for use with redux-form
import QuestionCard from '../Questions/components/QuestionCard/QuestionCard';
import fieldify from '../../../../components/fieldify';
import getValidationRule from '../../utils/getValidationRule';
import MaskedInput from '../../../../components/MaskedInput';
import MaskedInputV2 from '../../../../components/MaskedInputV2';
import InfiniteList from '../../components/InfiniteList';
import Step from '../../components/Step';
import SectionOverview from './components/SectionOverview';
import { actions, selectors } from './duck';
import styles from './styles';
import entitySelector from '../../../../redux/entities/entitySelector';
import type { State as RootState } from '../../../../redux/initialState';

type State = {
    initialRender: boolean,
}

type OwnProps = ContextRouter & FormProps & {
    t: TFunction,
};
type StateProps = {
    canAccessPayments: boolean,
    currentBalance: number,
    isGrandfathered: boolean,
    locationCount: number,
    locationListId: ?number,
    locations: Object[],
    needsPublicWorkforce: boolean,
    questionCount: number,
    scheduleType: ?string,
};
type DispatchProps = {};
type Props = OwnProps & StateProps & DispatchProps;

const formatCurrency = (value: any): string => numeral(value || 0).format('$0,0.00');
const formatDate = (value: any): string => moment(value).format('lll');

// $FlowFixMe flow complains about optional properties in InjectedProps not being in component Props
const MaskedInputField = fieldify()(MaskedInput);
const MaskedInputV2Field = fieldify()(MaskedInputV2);
const ReadOnlyField = fieldify()(
    (props: Object) => {
        const { className, value } = props;
        return <div className={className}>{value}</div>;
    }
);
const ReadOnlyHtmlField = fieldify()(
    (props: Object) => {
        const { className, value } = props;
        return <div className={className} dangerouslySetInnerHTML={{ __html: value }} />;
    }
);
const AmountDueField = fieldify()(
    (props: Object) => {
        const { className, error, loading, value } = props;
        return (
            <span className={cx(className, { error: !!error })}>
                {loading
                    ? <CircularProgress size={22} />
                    : formatCurrency(Math.abs(value))
                }
            </span>
        );
    }
);

export class Launch extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { initialRender: true };
        this.handleGigPriceChange = debounce(this.handleGigPriceChange, 500);
    }

    componentDidMount() {
        const { getMoreLocations, history, location, locationCount, locationListId, locations } = this.props;
        const lastPage = history.location.state ? history.location.state.lastPage : false;

        if (!lastPage) {
            history.replace({ ...location, state: { lastPage: true } });
        }

        if (locationListId && locations.length === 0 && locationCount > 0) {
            getMoreLocations(locationListId);
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        const { history, location } = nextProps;
        const lastPage = history.location.state ? history.location.state.lastPage : false;
        if (!lastPage) {
            history.replace({ ...location, state: { lastPage: true } });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { initialRender } = this.state;
        const { getMoreLocations, getPriceEstimate, locationCount, locationListId, locations, match } = this.props;
        const subscriptionId = parseInt(match.params.subscriptionId, 10);

        if (locationListId && locations.length === 0 && locationCount > 0) {
            getMoreLocations(locationListId);
        }

        // Need to wait until fields are registered to get price_estimate, which only
        // happens after the initial render
        if (initialRender) {
            this.setState({ initialRender: false }); // eslint-disable-line react/no-did-update-set-state
        } else if (prevState.initialRender) {
            getPriceEstimate(subscriptionId);
        }
    }

    componentWillUnmount() {
        // Reset locationIds on unmount to make sure modifications to the location list are
        // always reflected on the Launch page
        const { resetLocations } = this.props;
        resetLocations();
    }

    handleInfiniteLoad = (): Promise<void> => {
        const { getMoreLocations, locationListId } = this.props;
        return locationListId ? getMoreLocations(locationListId) : Promise.resolve();
    };

    handleGigPriceChange = () => {
        const { getPriceEstimate, match } = this.props;
        const subscriptionId = parseInt(match.params.subscriptionId, 10);
        getPriceEstimate(subscriptionId);
    };

    renderQuestions = (props: FieldArrayProps): React$Element<*> => {
        const { classes } = this.props;
        const { fields } = props;

        return (
            <ul className={classes.questionList}>
                {fields.map((name: string, index: number): React$Element<any> => {
                    const question = fields.get(index);
                    return (
                        <li key={question.id} className={classes.question}>
                            <QuestionCard
                              {...question}
                              formKey={name}
                              index={index}
                              expanded
                              readOnly
                            />
                        </li>
                    );
                })}
            </ul>
        );
    };

    renderWorkers(): React$Element<any> {
        const { classes, memberCount, needsPublicWorkforce, twoWayRatingEnabled, needsApproval, t, workAllocation } = this.props;
        const optinType = workAllocation ? workAllocation.optinType : undefined;
        const summary = [];

        const transProps = {
            parent: 'div',
            className: classes.workerSummaryItem,
        };

        summary.push(
            needsPublicWorkforce
                ? (
                    <Trans
                      {...transProps}
                      defaults={t('projectBuilder.launch.publicWorkforce')}
                      components={['I am launching a', <strong>crowdsourcing</strong>, 'project']}
                    />
                )
                : (
                    <Trans
                      {...transProps}
                      defaults={t('projectBuilder.launch.privateWorkforce')}
                      components={['I am using my own', <strong>private workforce</strong>]}
                    />
                )
        );

        switch (optinType) {
            case OPTIN_TYPES.DOUBLE_OPTIN:
                summary.push(
                    <Trans
                      {...transProps}
                      defaults={t('projectBuilder.launch.doubleOptIn')}
                      components={['I will', <strong>approve worker applications</strong>, 'to start gigs']}
                    />
                );
                break;
            case OPTIN_TYPES.SIMPLE_OPTIN:
                summary.push(
                    <Trans
                      {...transProps}
                      defaults={t('projectBuilder.launch.simpleOptIn')}
                      components={[<strong>No approval</strong>, 'is necessary for workers to start gigs']}
                    />
                );
                break;
            case OPTIN_TYPES.SYSTEM_APPROVED_OPTIN:
                summary.push(
                    <Trans
                      {...transProps}
                      defaults={t('projectBuilder.launch.systemApprovedOptIn')}
                      components={['I will let Gigwalk', <strong>automatically select the best applicant</strong>, 'for the job']}
                    />
                );
                break;
            case OPTIN_TYPES.NO_OPTIN:
                break;
            default:
                break;
        }

        if (needsApproval) {
            summary.push(
                <Trans
                  {...transProps}
                  defaults={t('projectBuilder.launch.needsApproval')}
                  components={['I will', <strong>approve gigs</strong>, 'after they are submitted']}
                />
            );
        }

        if (twoWayRatingEnabled) {
            summary.push(
                <Trans
                  {...transProps}
                  defaults={t('projectBuilder.launch.twoWayRatingEnabled')}
                  components={['I will', <strong>rate workers</strong>, 'on how well they completed the gig']}
                />
            );
        }

        summary.push(
            <FieldArray
              name="groups"
              component={
                  (props: FieldArrayProps): ?React$Element<any> => {
                      const { fields } = props;
                      return fields.length > 0
                          ? (
                              <div className={classes.workerSummaryItem}>
                                  <Trans
                                    parent="div"
                                    defaults={t('projectBuilder.launch.invitedGroups', { workerCount: memberCount, groupCount: fields.length })}
                                    components={['I am inviting', <strong>workers</strong>, 'from the following groups']}
                                  />
                                  <div className={classes.chipList}>
                                      {fields.map((name: string, index: number): React$Element<any> => {
                                          const group = fields.get(index);
                                          return (
                                              <Chip
                                                className={classes.chip}
                                                key={group.id}
                                                label={group.name}
                                              />
                                          );
                                      })}
                                  </div>
                              </div>
                          )
                          : null;
                  }
              }
            />
        );

        summary.push(
            <FieldArray
              name="certifications"
              component={
                  (props: FieldArrayProps): ?React$Element<any> => {
                      const { fields } = props;
                      return fields.length > 0
                          ? (
                              <div className={classes.workerSummaryItem}>
                                  <Trans
                                    parent="div"
                                    defaults={t('projectBuilder.launch.requiredSkills', { count: fields.length })}
                                    components={['Workers', <strong>must be certified</strong>, 'to accept gigs from this project']}
                                  />
                                  <div className={classes.chipList}>
                                      {fields.map((name: string, index: number): React$Element<any> => {
                                          const certification = fields.get(index);
                                          return (
                                              <Chip
                                                className={classes.chip}
                                                key={certification.id}
                                                label={certification.title}
                                              />
                                          );
                                      })}
                                  </div>
                              </div>
                          )
                          : null;
                  }
              }
            />
        );

        return <div>{summary}</div>;
    }

    renderLocations(): React$Element<any> {
        const { classes, locations, locationCount, t, workerCount } = this.props;
        return (
            <div>
                <InfiniteList maxHeight={500} rowHeight={70} onInfiniteLoad={this.handleInfiniteLoad}>
                    {locations.map((location: Gigwalk$Location): React$Element<any> => (
                        <ListItem
                          className={classes.location}
                          component="div"
                          key={location.relation_id}
                        >
                            <ListItemText
                              primary={location.title}
                              secondary={location.title !== location.formatted_address ? location.formatted_address : null}
                              primaryTypographyProps={{ color: 'inherit' }}
                              secondaryTypographyProps={{ color: 'inherit' }}
                            />
                        </ListItem>
                    ))}
                </InfiniteList>
                <Trans
                  parent="div"
                  defaults={t('projectBuilder.launch.locationInfo', { locationCount, workerCount: workerCount || 1 })}
                  components={[<strong>locationCount</strong>, 'locations,', <strong>workerCount</strong>, 'workers per location']}
                />
            </div>
        );
    }

    renderPriceSummary(): React$Element<any> {
        const { amountDue, classes, currentBalance, editMode, isGrandfathered, t, updatingPrice } = this.props;
        const listItems = [];

        if (isGrandfathered) {
            listItems.push(
                <ListItem key="gigPrice" className={classes.priceSummaryItem}>
                    <span>{t('projectBuilder.launch.ticketPrice')}</span>
                    <Field
                      name="gigPrice"
                      classes={{
                          root: classes.gigPrice,
                          input: classes.gigPriceInput,
                      }}
                      component={MaskedInputV2Field}
                      validate={getValidationRule('gigPrice')}
                      onChange={this.handleGigPriceChange}
                      options={{
                          numeral: true,
                          numeralThousandsGroupStyle: 'thousand',
                          numeralPositiveOnly: true,
                          prefix: '$',
                          noImmediatePrefix: true,
                          rawValueTrimPrefix: true,
                      }}
                      required
                    />
                </ListItem>
            );
        }

        listItems.push(
            <ListItem key="currentBalance" className={classes.priceSummaryItem}>
                <span>{t('projectBuilder.launch.currentBalance')}</span>
                <span>{numeral(currentBalance).format('$0,0.00')}</span>
            </ListItem>
        );

        if (editMode) {
            listItems.push(
                <ListItem key="originalPrice" className={classes.priceSummaryItem}>
                    <span>{t('projectBuilder.launch.originalPrice')}</span>
                    <Field name="projectFund" component={ReadOnlyField} format={formatCurrency} />
                </ListItem>,
                <ListItem key="newPrice" className={classes.priceSummaryItem}>
                    <span>{t('projectBuilder.launch.newPrice')}</span>
                    <Field name="priceEstimate" component={ReadOnlyField} format={formatCurrency} />
                </ListItem>
            );
        }

        listItems.push(
            <ListItem key="amountDue" className={classes.priceSummaryItem}>
                <span>
                    {amountDue < 0
                        ? t('projectBuilder.launch.amountRefunded')
                        : t('projectBuilder.launch.totalDue')
                    }
                </span>
                <Field
                  name="amountDue"
                  className={classes.priceTotal}
                  component={AmountDueField}
                  validate={getValidationRule('amountDue')}
                  loading={updatingPrice}
                />
            </ListItem>
        );

        return (
            <List className={classes.priceSummary}>
                {listItems.map((listItem: React$Element<any>, index: number) => (
                    <Fragment>
                        {listItem}
                        {index < listItems.length - 1 ? <Divider /> : null}
                    </Fragment>
                ))}
            </List>
        );
    }

    renderAdminTools(): React$Element<any> {
        const { classes, isGrandfathered, t } = this.props;

        return (
            <Paper className={classes.adminTools} elevation={0} square>
                <h2>{t('projectBuilder.launch.adminUseOnly')}</h2>
                {!isGrandfathered
                    ? (
                        <Field
                          name="gigPrice"
                          label={t('projectBuilder.launch.ticketPrice')}
                          component={MaskedInputField}
                          options={{
                              numeral: true,
                              numeralThousandsGroupStyle: 'thousand',
                              numeralPositiveOnly: true,
                              prefix: '$',
                              noImmediatePrefix: true,
                              rawValueTrimPrefix: true,
                          }}
                          validate={getValidationRule('gigPrice')}
                          required
                        />
                    )
                    : null
                }
                <Field
                  name="nearTicketDistance"
                  label={t('projectBuilder.launch.nearTicketDistanceLabel')}
                  component={MaskedInputField}
                  options={{ numeral: true, numeralThousandsGroupStyle: 'thousand', numeralPositiveOnly: true }}
                  validate={getValidationRule('nearTicketDistance')}
                  required
                />
                <Field
                  name="oversamplingTarget"
                  label={t('projectBuilder.launch.overSamplingTargetLabel')}
                  component={MaskedInputField}
                  options={{ numeral: true, numeralThousandsGroupStyle: 'thousand', numeralPositiveOnly: true }}
                  validate={getValidationRule('oversamplingTarget')}
                />
                <Field
                  name="oversamplingOverage"
                  label={t('projectBuilder.launch.overSamplingOverageLabel')}
                  component={MaskedInputField}
                  options={{ numeral: true, numeralThousandsGroupStyle: 'thousand', numeralPositiveOnly: true }}
                  validate={getValidationRule('oversamplingOverage')}
                />
            </Paper>
        );
    }

    render() {
        const { classes, needsPublicWorkforce, scheduleType, t, user } = this.props;

        // IMPORTANT! Make sure all subscription fields are rendered using Field or FieldArray.
        // This registers the field with redux-form and makes it save-able when the form
        // is submitted (ie. launched)
        return (
            <Step title={t('projectBuilder.launch.header')}>
                <form className={classes.form}>
                    <SectionOverview label={t('projectBuilder.launch.title')}>
                        <Field className={classes.title} name="title" component={ReadOnlyField} />
                    </SectionOverview>
                    <SectionOverview label={t('projectBuilder.launch.description')}>
                        <Field className={classes.description} id="description" name="description" component={ReadOnlyHtmlField} />
                    </SectionOverview>
                    <SectionOverview label={t('projectBuilder.launch.dates')}>
                        {scheduleType === SCHEDULE_TYPES.ASAP
                            ? (
                                <div className={classes.dates}>
                                    <span>{t('projectBuilder.launch.now')}</span>
                                    <Icon className={classes.rightArrowIcon}>arrow_right_alt</Icon>
                                    <Field
                                      id="asapEndDate"
                                      name="asapEndDate"
                                      component={ReadOnlyField}
                                      format={formatDate}
                                    />
                                </div>
                            )
                            : (
                                <div className={classes.dates}>
                                    <Field
                                      id="rangeStartDate"
                                      name="rangeStartDate"
                                      component={ReadOnlyField}
                                      format={formatDate}
                                    />
                                    <Icon className={classes.rightArrowIcon}>arrow_right_alt</Icon>
                                    <Field
                                      id="rangeEndDate"
                                      name="rangeEndDate"
                                      component={ReadOnlyField}
                                      format={formatDate}
                                    />
                                </div>
                            )
                        }
                    </SectionOverview>
                    <SectionOverview label={t('projectBuilder.launch.locations')}>
                        {this.renderLocations()}
                    </SectionOverview>
                    <SectionOverview label={t('projectBuilder.launch.workers')}>
                        {this.renderWorkers()}
                    </SectionOverview>
                    <SectionOverview label={t('projectBuilder.launch.questions')}>
                        <FieldArray
                          name="questionList"
                          component={this.renderQuestions}
                        />
                    </SectionOverview>
                    {needsPublicWorkforce
                        ? <SectionOverview>{this.renderPriceSummary()}</SectionOverview>
                        : null
                    }
                    {needsPublicWorkforce && user.role === USER_ROLES.SELF_SERVICE
                        ? <SectionOverview>{this.renderAdminTools()}</SectionOverview>
                        : null
                    }
                    <Field name="scheduleType" component="input" type="hidden" />
                    <Field name="locationListId" component="input" type="hidden" />
                    <Field name="needsPublicWorkforce" component="input" type="hidden" />
                    <Field name="workAllocation" component="input" type="hidden" />
                    <Field name="needsApproval" component="input" type="hidden" />
                    <Field name="twoWayRatingEnabled" component="input" type="hidden" />
                    <Field name="workerCount" component="input" type="hidden" />
                </form>
            </Step>
        );
    }
}

const valueSelector = formValueSelector('projectBuilder');
const locationListSelector = entitySelector('locationLists');
const organizationSelector = entitySelector('organizations');
const subscriptionSelector = entitySelector('subscriptions');

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const { user } = state.session;
    const { match } = props;
    const organization = organizationSelector(state, match.params.orgId);
    const subscription = subscriptionSelector(state, match.params.subscriptionId);
    const values = valueSelector(
        state,
        'amountDue',
        'locationListId',
        'needsApproval',
        'needsPublicWorkforce',
        'scheduleType',
        'twoWayRatingEnabled',
        'workAllocation',
        'workerCount',
    );

    return {
        ...state.projectBuilder.launch,
        ...values,
        editMode: subscription ? subscription.state === 'ACTIVE' : false,
        canAccessPayments: state.session.features.canAccessPayments,
        currentBalance: organization ? organization.current_balance : 0,
        // $FlowIssue flow error does not make sense - might be an issue with flow or reselect typedef
        initialValues: selectors.getInitialValues(state, props),
        isGrandfathered: organization ? organization.grandfathered : false,
        locationCount: locationListSelector(state, values.locationListId, 'location_count') || 0,
        locations: selectors.getLocations(state, props) || [],
        memberCount: selectors.getMemberCount(state),
        user,
    };
};

const mapDispatchToProps: DispatchProps = { ...actions };

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'Launch' }),
    withTranslation(),
    connector,
    reduxForm({
        form: 'projectBuilder',
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: false,
    })
);
export default enhance(Launch);
