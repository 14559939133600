// @flow
import { createSelector, createStructuredSelector } from 'reselect';
import moment from 'moment';
import { getFormValues } from 'redux-form';
import { matchPath } from 'react-router';
import type { ContextRouter, LocationShape } from 'react-router';
import type { DataType } from 'gigwalk/lib/api/dataTypes/types';
import type { Certification } from 'gigwalk/lib/api/certifications/types';
import type { Group } from 'gigwalk/lib/api/groups/types';
import { SCHEDULE_TYPES } from '../../../../browser/shared/constant/ProjectConstant';
import entitySelector from '../../../redux/entities/entitySelector';
import { mapEntityToQuestion } from '../containers/Questions/utils/transforms';
import type { State as RootState } from '../../../redux/initialState';
import type { StepErrors } from '../containers/ProgressTracker/duck/reducer';

type Question = {
    id: number,
    children?: Object[],
    description: string,
    valueType: string,
    questionText: string,
    propositions: Array<{ choice: string, alert: boolean }>,
    required: boolean,
    targetListId?: number,
    globalDataTypeId: number,
};

const subscriptionSelector = entitySelector('subscriptions');
const dataTypeSelector = entitySelector('dataTypes');

const steps = Object.freeze([
    'info',
    'locations',
    'people',
    'data',
    'launch',
]);

const getDataTypes = (state: RootState): DataType[] => dataTypeSelector(state);
const getSubscription = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): Object => {
    const { match } = props;
    return subscriptionSelector(state, match.params.subscriptionId);
};
const getPathname = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): string => {
    const { location } = props;
    return location.pathname;
};
const getMatchUrl = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): string => {
    const { match } = props;
    return match.url;
};

export const getNextPage = createSelector(
    getPathname,
    getMatchUrl,
    <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): boolean => {
        const { history } = props;
        return history.location.state ? !!history.location.state.lastPage : false;
    },
    (pathname: string, matchUrl: string, isLastPage: boolean): ?LocationShape => {
        const match = matchPath(pathname, { path: `${matchUrl}/:step?/:page?` });
        const currentStep = match ? match.params.step : 'info';
        const currentPage = match ? parseInt(match.params.page, 10) : 0;

        let nextStep;
        let nextPage;

        if (isLastPage) {
            nextStep = steps[steps.indexOf(currentStep) + 1];
            nextPage = 0;
        } else {
            nextStep = currentStep;
            nextPage = currentPage + 1;
        }

        return nextStep ? { pathname: `${matchUrl}/${nextStep}/${nextPage || '0'}` } : null;
    }
);

export const getPrevPage = createSelector(
    getPathname,
    getMatchUrl,
    (pathname: string, matchUrl: string): ?LocationShape => {
        const match = matchPath(pathname, { path: `${matchUrl}/:step?/:page?` });
        const currentStep = match ? match.params.step : 'info';
        const currentPage = match ? parseInt(match.params.page || 0, 10) : 0;

        let prevStep;
        let prevPage;

        if (currentPage === 0) {
            prevStep = steps[steps.indexOf(currentStep) - 1];
        } else {
            prevStep = currentStep;
            prevPage = currentPage - 1;
        }

        if (prevStep) {
            return {
                pathname: `${matchUrl}/${prevStep}/${prevPage || ''}`,
                state: prevPage == null ? { lastPage: true } : null,
            };
        }

        return null;
    }
);

// $FlowTypedIssue reselect typedef doesn't support createStructuredSelector being used as a resultFunc
export const mapSubscriptionToFormValues = createSelector(
    getSubscription,
    getDataTypes,
    createStructuredSelector({
        asapEndDate: (subscription: Object) => (subscription && subscription.schedule_type === SCHEDULE_TYPES.ASAP ? subscription.end_date : undefined),
        certifications: (subscription: Object) => (
            subscription && subscription.certifications
                ? subscription.certifications.filter((certification: ?Certification) => !!certification)
                : undefined
        ),
        description: (subscription: Object) => (subscription ? subscription.description : undefined),
        gigPrice: (subscription: Object) => (subscription && typeof subscription.min_gig_price === 'number' ? subscription.min_gig_price : 3),
        groups: (subscription: Object) => (
            subscription && subscription.groups
                ? subscription.groups.filter((group: ?Group) => !!group)
                : undefined
        ),
        nearTicketDistance: (subscription: Object) => (
            subscription && typeof subscription.near_ticket_distance === 'number' ? subscription.near_ticket_distance : 5
        ),
        needsApproval: (subscription: Object) => (subscription ? subscription.needs_approval : undefined),
        needsPublicWorkforce: (subscription: Object) => (subscription ? subscription.needs_public_workforce || subscription.needs_core : undefined),
        locationListId: (subscription: Object) => (
            subscription && subscription.organization_location_lists && subscription.organization_location_lists[0]
                ? subscription.organization_location_lists[0].id
                : -1
        ),
        questionList: (subscription: Object, dataTypes: DataType[]) => {
            if (!subscription || !subscription.organization_observation_target_lists) {
                return;
            }

            const { organization_observation_target_lists: targetLists } = subscription;
            const records = targetLists.reduce((arr: Object[], list: Object): Object[] => {
                if (list.data_types) {
                    const record = list.data_types.map((d: Object): Object => ({
                        children: d.children,
                        id: d.data_type_id,
                        targetListId: list.observation_target_list_id,
                    }));
                    return arr.concat(record);
                }
                return arr;
            }, []);

            const questionList = [];
            records.forEach((record: Object) => {
                const dataType = dataTypes.find(({ id }: DataType) => id === record.id);
                if (dataType) {
                    questionList.push({
                        ...mapEntityToQuestion(dataType),
                        children: record.children,
                        targetListId: record.targetListId,
                    });
                }
            });

            return questionList;
        },
        rangeEndDate: (subscription: Object) => (subscription && subscription.schedule_type === SCHEDULE_TYPES.RANGE ? subscription.end_date : undefined),
        rangeStartDate: (subscription: Object) => (
            subscription && subscription.schedule_type === SCHEDULE_TYPES.RANGE ? subscription.start_date : undefined
        ),
        scheduleType: (subscription: Object) => (subscription ? subscription.schedule_type : undefined),
        oversamplingOverage: (subscription: Object) => (subscription ? subscription.tickets_overage : undefined),
        oversamplingTarget: (subscription: Object) => (subscription ? subscription.tickets_target : undefined),
        projectFund: (subscription: Object) => (subscription ? subscription.project_fund : undefined),
        title: (subscription: Object) => (subscription ? subscription.title : undefined),
        twoWayRatingEnabled: (subscription: Object) => (subscription ? subscription.two_way_rating_enabled : undefined),
        workerCount: (subscription: Object) => (subscription ? subscription.worker_count : undefined),
        workAllocation: (subscription: Object) => {
            if (!subscription) return;

            const {
                autoassign,
                optin_type: optinType,
            } = subscription;

            if (optinType !== undefined && autoassign !== undefined) {
                return { autoassign, optinType };
            }
        },
    })
);

export const getInitialValues = createSelector(
    mapSubscriptionToFormValues,
    (values: Object) => (
        // Remove fields that have undefined values. When redux-form receives new initial
        // values, any fields that had a previous initial value of undefined will be overwritten,
        // even if the new value is still undefined.
        Object.entries(values)
            .reduce((acc: Object, [key, value]: [string, mixed]): Object => {
                if (value !== undefined) {
                    acc[key] = value;
                }
                return acc;
            }, {})
    )
);

export const getRegisteredFormValues = createSelector(
    (state: RootState): Object => getFormValues('projectBuilder')(state),
    (state: RootState): ?Object => (state.form.projectBuilder ? state.form.projectBuilder.registeredFields : undefined),
    (values: Object, registeredFields: ?Object) => (
        Object.entries(registeredFields)
            .reduce((acc: Object, [key, value]: [string, mixed]): Object => {
                const refCount = value && typeof value === 'object' && typeof value.count === 'number' ? value.count : 0;
                if (refCount > 0) {
                    acc[key] = values[key];
                }
                return acc;
            }, {})
    )
);

export const mapFormValuesToSubscription = createSelector(
    getRegisteredFormValues,
    getSubscription,
    createStructuredSelector({
        autoassign: (values: Object) => (values.workAllocation ? values.workAllocation.autoassign : undefined),
        certifications: (values: Object) => (values.certifications ? values.certifications.map((certification: Certification) => certification.id) : undefined),
        description: (values: Object) => values.description,
        end_date: (values: Object) => {
            const { scheduleType, rangeEndDate, asapEndDate } = values;
            if (scheduleType === SCHEDULE_TYPES.RANGE) {
                return rangeEndDate;
            } else if (scheduleType === SCHEDULE_TYPES.ASAP) {
                return asapEndDate;
            }
        },
        groups: (values: Object) => {
            const { needsPublicWorkforce, groups } = values;
            if (needsPublicWorkforce) {
                return [];
            } else if (Array.isArray(groups)) {
                return groups.map((group: Group) => group.id);
            }
        },
        min_gig_price: (values: Object) => {
            if (values.hasOwnProperty('needsPublicWorkforce')) {
                const gigPrice = parseFloat(values.gigPrice);
                return values.needsPublicWorkforce && !Number.isNaN(gigPrice) ? gigPrice : null;
            }
        },
        near_ticket_distance: (values: Object) => {
            const nearTicketDistance = parseFloat(values.nearTicketDistance);
            return !Number.isNaN(nearTicketDistance) ? nearTicketDistance : undefined;
        },
        needs_approval: (values: Object) => (values.needsPublicWorkforce ? true : values.needsApproval),
        needs_core: (values: Object, subscription: Object) => {
            if (subscription) {
                const { needs_core: needsCore, state } = subscription;
                return state === 'ACTIVE' && needsCore === true ? values.needsPublicWorkforce : false;
            }
            return undefined;
        },
        needs_public_workforce: (values: Object, subscription: Object) => {
            if (subscription) {
                const { needs_core: needsCore, state } = subscription;
                return state === 'ACTIVE' && needsCore === true ? false : values.needsPublicWorkforce;
            }
            return values.needsPublicWorkforce;
        },
        optin_type: (values: Object) => (values.workAllocation ? values.workAllocation.optinType : undefined),
        organization_location_lists: (values: Object) => (values.locationListId > 0 ? [values.locationListId] : undefined),
        organization_observation_target_lists: (values: Object) => {
            const { questionList } = values;
            if (Array.isArray(questionList)) {
                return questionList.map((question: Question) => {
                    const { children, id, globalDataTypeId, targetListId } = question;
                    const dataTypeId = typeof id === 'string' && id.startsWith('duplicate') ? globalDataTypeId : id;
                    return {
                        observation_target_list_id: targetListId,
                        data_types: [{ children, data_type_id: dataTypeId }],
                    };
                });
            }
        },
        schedule_type: (values: Object) => values.scheduleType,
        start_date: (values: Object, subscription: Object) => {
            const { scheduleType, rangeStartDate } = values;
            if (scheduleType === SCHEDULE_TYPES.RANGE) {
                return rangeStartDate;
            } else if (scheduleType === SCHEDULE_TYPES.ASAP) {
                // Hack to make sure ticket start date is now or in the past for any domestic locations
                // so that workers can begin work immediately. This should be handled by BE during
                // ticket creation
                if (subscription) {
                    const { state } = subscription;
                    return state === 'ACTIVE' ? undefined : moment().subtract(6, 'hours').format('YYYY-MM-DDTHH:mm');
                }
                return moment().subtract(6, 'hours').format('YYYY-MM-DDTHH:mm');
            }
        },
        ticket_time_estimate: () => 1800, // Default to 30 mins until support is added for timeEstimate and canReschedule fields
        tickets_overage: (values: Object) => {
            if (values.hasOwnProperty('needsPublicWorkforce')) {
                const oversamplingOverage = parseInt(values.oversamplingOverage, 10);
                return values.needsPublicWorkforce && !Number.isNaN(oversamplingOverage) ? oversamplingOverage : null;
            }
        },
        tickets_target: (values: Object) => {
            if (values.hasOwnProperty('needsPublicWorkforce')) {
                const oversamplingTarget = parseInt(values.oversamplingTarget, 10);
                return values.needsPublicWorkforce && !Number.isNaN(oversamplingTarget) ? oversamplingTarget : null;
            }
        },
        title: (values: Object) => values.title,
        two_way_rating_enabled: (values: Object) => (values.needsPublicWorkforce ? true : values.twoWayRatingEnabled),
        worker_count: (values: Object) => (values.hasOwnProperty('workerCount') ? values.workerCount : undefined),
    })
);

export const getSubscriptionData = createSelector(
    mapFormValuesToSubscription,
    (subscription: Object): Object => (
        Object.entries(subscription)
            .reduce((acc: Object, [key, value]: [string, mixed]): Object => {
                if (value !== undefined) {
                    acc[key] = value;
                }
                return acc;
            }, {})
    )
);

export const isReadyToLaunch = createSelector(
    (state: RootState): StepErrors => state.projectBuilder.progressTracker.errors,
    (stepErrors: StepErrors): boolean => {
        const { info, locations, people, data } = stepErrors;
        const valid = info.length === 0 && locations.length === 0 && people.length === 0 && data.length === 0;
        return valid;
    }
);

export default {
    getInitialValues,
    getSubscriptionData,
    getRegisteredFormValues,
    getNextPage,
    getPrevPage,
    isReadyToLaunch,
    mapFormValuesToSubscription,
    mapSubscriptionToFormValues,
};
