// @flow
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import typeof $i18next from 'i18next';
import { version } from '../../package.json';
import { trackVirtualPage } from '../browser/shared/util/GoogleTagManager';

export const meta = Object.freeze([
    { charset: 'UTF-8' },
    { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
    { 'http-equiv': 'X-UA-Compatible', content: 'IE=9' },
    { name: 'viewport', content: 'initial-scale=1.0,user-scalable=no,maximum-scale=1,width=device-width' },
    { name: 'viewport', content: 'initial-scale=1.0,user-scalable=no,maximum-scale=1', media: '(device-height: 568px)' },
    {
        name: 'description',
        content:
            'Gigwalk, the powerful mobile technology that lets you find the right workers, manage distributed teams, get real-time visibility and more.',
    },
]);

const crossOrigin = 'anonymous';
const async: true = true;

export const script = Object.freeze([
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/es5-shim/4.5.12/es5-shim.min.js', crossOrigin, async },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/dom4/2.1.4/dom4.js', crossOrigin, async },
    { src: '/public/modernizr-3.0.0.min.js', async },
]);

const stylesheets = Object.freeze([
    // stylesheet
    { rel: 'stylesheet', href: `/public/css/styles.css?version=${version}`, type: 'text/css' },
    { rel: 'stylesheet', href: '//fonts.googleapis.com/css?family=Open+Sans:400,300,600', type: 'text/css' },
    { rel: 'stylesheet', href: '//fonts.googleapis.com/icon?family=Material+Icons', type: 'text/css' },
]);

const icons = Object.freeze([
    // icons
    { rel: 'apple-touch-icon', href: '/public/images/gigwalk-apple-touch-icon.png' },
    { rel: 'shortcut icon', href: '/public/images/gigwalk-icon-rounded.png' },
    { rel: 'shortcut icon', href: '/public/images/gigwalk-favicon.ico' },
]);

export const link = Object.freeze([
    ...stylesheets,
    ...icons,
]);

const DEFAULT_TITLE = process.env.GWP_DEFAULT_TITLE || 'Gigwalk';

type State = {
    title: string,
};

type Props = {
    i18n: $i18next
};

export class CommonHelmet extends Component<Props, State> {
    state: State = {
        title: DEFAULT_TITLE,
    };

    handleChangeClientState = (newState: { title: string }) => {
        // prevent the trackVirtualPage to be called several time with the same page info
        const { title } = this.state;
        if (title !== newState.title) {
            this.setState({ title: newState.title });
            trackVirtualPage();
        }
    };

    render() {
        const { i18n } = this.props;
        const commonProps = {
            htmlAttributes: { lang: i18n.language },
            meta,
            script,
            link,
        };

        return (
            <Helmet
              {...commonProps}
              defaultTitle={DEFAULT_TITLE}
              titleTemplate={`${DEFAULT_TITLE} - %s`}
              onChangeClientState={this.handleChangeClientState}
            />
        );
    }
}

export default withTranslation()(CommonHelmet);
