// @flow
import { normalize } from 'normalizr';
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import type { $AxiosXHR, $AxiosError } from 'axios';
import type { APIResponse, APIPromise } from 'gigwalk/lib/api/resource';
import type {
    Certification,
    GetCertificationParams,
    GetAllCertificationsForOrganizationParams,
} from 'gigwalk/lib/api/certifications/types';
import logger from '../../util/logger';
import { client as gigwalk } from '../../api/createGigwalkClient';
import { init } from './initialState';
import { certification as certificationSchema } from './schemas';
import type { State } from './initialState';

// Actions
// -------
export const FETCH = 'g/certifications/FETCH';
export const FETCH_SUCCESS = `${FETCH}_SUCCESS`;
export const FETCH_ERROR = `${FETCH}_ERROR`;

// Get certifications
export const GET_ALL_FOR_ORGANIZATION = 'g/certifications/GET_ALL_FOR_ORGANIZATION';
export const GET_ALL_FOR_ORGANIZATION_SUCCESS = `${GET_ALL_FOR_ORGANIZATION}_SUCCESS`;
export const GET_ALL_FOR_ORGANIZATION_ERROR = `${GET_ALL_FOR_ORGANIZATION}_ERROR`;

// Assign certifications
export const ASSIGN = 'g/certifications/ASSIGN';
export const ASSIGN_SUCCESS = `${ASSIGN}_SUCCESS`;
export const ASSIGN_ERROR = `${ASSIGN}_ERROR`;

// Action Creators
// ---------------
export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchError = createAction(FETCH_ERROR);
export const fetch = createAction(
    FETCH,
    (params: GetCertificationParams): Function => (
        (dispatch: Dispatch<any>): APIPromise<[Certification]> => (
            gigwalk.certifications.get(params)
                .then((resp: $AxiosXHR<APIResponse<[Certification]>>) => {
                    const normalized = normalize(resp.data.data, [certificationSchema]);
                    dispatch(fetchSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchError(err));
                    return Promise.reject(err);
                })
        )
    )
);


// Get certifications
export const getAllForOrganizationSuccess = createAction(GET_ALL_FOR_ORGANIZATION_SUCCESS);
export const getAllForOrganizationError = createAction(GET_ALL_FOR_ORGANIZATION_ERROR);
export const getAllForOrganization = createAction(
    GET_ALL_FOR_ORGANIZATION,
    (params: GetAllCertificationsForOrganizationParams): Function => (
        (dispatch: Dispatch<any>): APIPromise<Array<Certification>> => (
            gigwalk.certifications.getAllForOrganization(params)
                .then((resp: $AxiosXHR<APIResponse<Array<Certification>>>) => {
                    const normalized = normalize(resp.data.data, [certificationSchema]);
                    dispatch(getAllForOrganizationSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(getAllForOrganizationError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const assignSuccess = createAction(ASSIGN_SUCCESS);
export const assignError = createAction(ASSIGN_ERROR);
export const assign = createAction(
    ASSIGN,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Array<Certification>> => {
            const { organization_id: orgId, ...payload } = params;
            return gigwalk.client.post(`v1/organizations/${orgId}/certifications/bulk_assign`, payload)
                .then((resp: $AxiosXHR<APIResponse<Array<Certification>>>) => {
                    const normalized = normalize(resp.data.data, [certificationSchema]);
                    dispatch(assignSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(assignError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export default function(state: State = init): State {
    return state;
}
