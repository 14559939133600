// @flow
export default (theme: Object) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    dialogContent: {
        overflowY: 'hidden',
        height: '100%',
        paddingBottom: 0,
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: [[16, 12]],

        '& > * + *': {
            marginLeft: 0,
            marginBottom: theme.spacing(0.5),
        },
    },
    dialogAction: {
        margin: 0,
    },
    submit: {
        '&:not($disabled)': {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.common.white,
        },
    },
    submitLabel: {
        display: 'inline-block',
        fontFamily: 'ProximaNova',
        fontSize: 14,
    },
    forceAssign: {
        maxWidth: '83%',
        marginBottom: theme.spacing(0.75),
        marginTop: theme.spacing(-1),
    },
    forceAssignWrapper: {
        marginRight: 0,
    },
    forceAssignLabel: {
        fontFamily: 'ProximaNova',
        color: theme.palette.text.primary,
    },
    disabled: {},
});
