// @flow
import _ from 'underscore';

let nodeCnt = 0;

class TreeNode {
    constructor(value: Object) {
        nodeCnt += 1;

        this.id = nodeCnt;
        this.value = value;
        this.parent = null;
        this.children = [];
    }

    id: number;
    value: Object;
    parent: ? Object;
    children: Array<Object>;

    /**
     * Adds a child node to the current node
     *
     * @method addChild
     * @constraints
     *      - All children must be unique
     *      - Node can only have one parent
     * @param node
     */
    addChild(_node: Object) {
        if (!(_node instanceof TreeNode)) {
            throw new TypeError('argument is not a TreeNode');
        }

        const node = _node;
        const foundNode = _.findWhere(this.children, { id: node.id });

        if (!foundNode) {
            this.children.push(node);
            if (node.parent) {
                node.parent.removeChild(node);
            }

            node.parent = this;
        }
    }

    /**
     * @method removeChild
     * @param node
     */
    removeChild(_node: Object) {
        if (!(_node instanceof TreeNode)) {
            throw new TypeError('argument is not a TreeNode');
        }

        const node = _node;
        const i = _.findIndex(this.children, (child: { id: number }) => child.id === node.id);

        if (i >= 0) {
            this.children.splice(i, 1);
            node.parent = null;
        }
    }
}

export default TreeNode;
