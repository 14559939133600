// @flow
import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import { matchPath } from 'react-router';
import type { ContextRouter } from 'react-router';
import { selectors as projectBuilder } from '../../../duck';
import type { State as RootState } from '../../../../../redux/initialState';

const getPathname = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): string => {
    const { location } = props;
    return location.pathname;
};
const getMatchUrl = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): string => {
    const { match } = props;
    return match.url;
};

export const getCurrentStep = createSelector(
    getPathname,
    getMatchUrl,
    (pathname: string, matchUrl: string): string => {
        const match = matchPath(pathname, { path: `${matchUrl}/:step?` });
        return match ? match.params.step || '' : 'info';
    }
);

export const getAllValues = createSelector(
    projectBuilder.mapSubscriptionToFormValues,
    (state: RootState): Object => getFormValues('projectBuilder')(state),
    (initialValues: Object, values: Object): Object => ({ ...initialValues, ...values })
);

export default {
    getCurrentStep,
    getAllValues,
};
