// @flow
export const PENDING_ADD = 'g/locations/PENDING_ADD';
export const PENDING_REMOVE = 'g/locations/PENDING_REMOVE';
export const UPDATE_PENDING = 'g/locations/UPDATE_PENDING';

export const GET_NEXT_PAGE = 'g/locations/GET_NEXT_PAGE';
export const GET_NEXT_PAGE_ERROR = `${GET_NEXT_PAGE}_ERROR`;
export const GET_NEXT_PAGE_SUCCESS = `${GET_NEXT_PAGE}_SUCCESS`;

export const REFRESH_PAGE = 'g/locations/REFRESH_PAGE';
export const REFRESH_PAGE_ERROR = `${REFRESH_PAGE}_ERROR`;
export const REFRESH_PAGE_SUCCESS = `${REFRESH_PAGE}_SUCCESS`;

export const ADD_TO_LIST = 'g/locations/ADD_TO_LIST';
export const ADD_TO_LIST_ERROR = `${ADD_TO_LIST}_ERROR`;
export const ADD_TO_LIST_SUCCESS = `${ADD_TO_LIST}_SUCCESS`;

export const REMOVE_FROM_LIST = 'g/locations/REMOVE_FROM_LIST';
export const REMOVE_FROM_LIST_ERROR = `${REMOVE_FROM_LIST}_ERROR`;
export const REMOVE_FROM_LIST_SUCCESS = `${REMOVE_FROM_LIST}_SUCCESS`;

export const FETCH_LIST = 'g/locations/FETCH_LIST';
export const FETCH_LIST_ERROR = `${FETCH_LIST}_ERROR`;
export const FETCH_LIST_SUCCESS = `${FETCH_LIST}_SUCCESS`;

export const CREATE_LIST = 'g/locations/CREATE_LIST';
export const CREATE_LIST_ERROR = `${CREATE_LIST}_ERROR`;
export const CREATE_LIST_SUCCESS = `${CREATE_LIST}_SUCCESS`;

export const GET_UNRESOLVED = 'g/locations/GET_UNRESOLVED';
export const GET_UNRESOLVED_ERROR = `${GET_UNRESOLVED}_ERROR`;
export const GET_UNRESOLVED_SUCCESS = `${GET_UNRESOLVED}_SUCCESS`;

export const DELETE_LIST = 'g/locations/DELETE_LIST';
export const SELECT = 'g/locations/SELECT';
export const SHOW_LOCATION_LIST_UPLOAD = 'g/locations/SHOW_LOCATION_LIST_UPLOAD';
export const HIDE_LOCATION_LIST_UPLOAD = 'g/locations/HIDE_LOCATION_LIST_UPLOAD';
export const SHOW_UNRESOLVED_LOCATIONS = 'g/locations/SHOW_UNRESOLVED_LOCATIONS';
export const HIDE_UNRESOLVED_LOCATIONS = 'g/locations/HIDE_UNRESOLVED_LOCATIONS';
export const TOGGLE_UPLOAD_NOTIFICATION = 'g/locations/TOGGLE_UPLOAD_NOTIFICATION';

export default {
    PENDING_ADD,
    PENDING_REMOVE,
    UPDATE_PENDING,
    GET_NEXT_PAGE,
    GET_NEXT_PAGE_ERROR,
    GET_NEXT_PAGE_SUCCESS,
    REFRESH_PAGE,
    REFRESH_PAGE_ERROR,
    REFRESH_PAGE_SUCCESS,
    ADD_TO_LIST,
    ADD_TO_LIST_ERROR,
    ADD_TO_LIST_SUCCESS,
    REMOVE_FROM_LIST,
    REMOVE_FROM_LIST_ERROR,
    REMOVE_FROM_LIST_SUCCESS,
    FETCH_LIST,
    FETCH_LIST_ERROR,
    FETCH_LIST_SUCCESS,
    CREATE_LIST,
    CREATE_LIST_ERROR,
    CREATE_LIST_SUCCESS,
    GET_UNRESOLVED,
    GET_UNRESOLVED_ERROR,
    GET_UNRESOLVED_SUCCESS,
    DELETE_LIST,
    SELECT,
    SHOW_LOCATION_LIST_UPLOAD,
    HIDE_LOCATION_LIST_UPLOAD,
    SHOW_UNRESOLVED_LOCATIONS,
    HIDE_UNRESOLVED_LOCATIONS,
    TOGGLE_UPLOAD_NOTIFICATION,
};
