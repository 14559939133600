// @flow
import { normalize } from 'normalizr';
import { createAction } from 'redux-actions';
import omitBy from 'lodash.omitby';
import type { Dispatch } from 'redux';
import type { $AxiosXHR, $AxiosError } from 'axios';
import type { APIResponse, APIPromise } from 'gigwalk/lib/api/resource';
import type { Customer, UpdateCustomerParams } from 'gigwalk/lib/api/customers/types';
import logger from '../../util/logger';
import { client as gigwalk } from '../../api/createGigwalkClient';
import { init } from './initialState';
import { customer as customerSchema } from './schemas';
import type { State } from './initialState';

// Actions
// -------
export const SEARCH = 'g/customers/SEARCH';
export const SEARCH_SUCCESS = `${SEARCH}_SUCCESS`;
export const SEARCH_ERROR = `${SEARCH}_ERROR`;

export const UPDATE_USER = 'g/customers/UPDATE_USER';
export const UPDATE_USER_SUCCESS = `${UPDATE_USER}_SUCCESS`;
export const UPDATE_USER_ERROR = `${UPDATE_USER}_ERROR`;

export const UPDATE = 'g/customers/UPDATE';
export const UPDATE_SUCCESS = `${UPDATE}_SUCCESS`;
export const UPDATE_ERROR = `${UPDATE}_ERROR`;

export const BULK_UPDATE = 'g/customers/BULK_UPDATE';
export const BULK_UPDATE_SUCCESS = `${BULK_UPDATE}_SUCCESS`;
export const BULK_UPDATE_ERROR = `${BULK_UPDATE}_ERROR`;

// Action Creators
// ---------------
export const searchSuccess = createAction(SEARCH_SUCCESS);
export const searchError = createAction(SEARCH_ERROR);
export const search = createAction(
    SEARCH,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => {
            const { csv, offset, limit, ...data } = params;
            const config = {
                params: omitBy({ csv, offset, limit }, (value) => value == null),
            };
            return gigwalk.client.post('/v1/search/customers', data, config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const results = resp.data.data || [];
                    const normalized = normalize(results, [customerSchema]);
                    normalized.metadata = resp.data._metadata;
                    dispatch(searchSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(searchError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const updateUserSuccess = createAction(UPDATE_USER_SUCCESS);
export const updateUserError = createAction(UPDATE_USER_ERROR);
export const updateUser = createAction(
    UPDATE_USER,
    (params: UpdateCustomerParams): Function => (
        (dispatch: Dispatch<any>): APIPromise<[Customer]> => (
            gigwalk.customers.update(params)
                .then((resp: $AxiosXHR<APIResponse<[Customer]>>) => {
                    const normalized = normalize(resp.data.data, [customerSchema]);
                    normalized.response = resp;
                    dispatch(updateUserSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(updateUserError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export const updateSuccess = createAction(UPDATE_SUCCESS);
export const updateError = createAction(UPDATE_ERROR);
export const update = createAction(
    UPDATE,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => {
            const {
                customer_id: customerId,
                organization_id: orgId,
                ...data
            } = params;
            return gigwalk.client.put(`v1/organizations/${orgId}/customers/${customerId}`, data)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalize(resp.data.data, [customerSchema]);
                    dispatch(updateSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(updateError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export const bulkUpdateSuccess = createAction(BULK_UPDATE_SUCCESS);
export const bulkUpdateError = createAction(BULK_UPDATE_ERROR);
export const bulkUpdate = createAction(
    BULK_UPDATE,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => {
            const { organization_id: orgId, ...data } = params;
            return gigwalk.client.put(`v1/organizations/${orgId}/customers`, data)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalize(resp.data.data, [{}]);
                    normalized.original = resp.data;
                    dispatch(bulkUpdateSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(bulkUpdateError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export default function(state: State = init): State {
    return state;
}
