// @flow
export default (theme: Object) => ({
    paper: {
        maxWidth: 540,
        maxHeight: 1000,
        backgroundColor: theme.palette.grey[100],
        padding: theme.spacing(4, 3),
        margin: [[0, 'auto', theme.spacing(3)]],

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(4, 2),
        },
    },
    error: {
        padding: theme.spacing.unit,
        border: [[2, 'solid', theme.palette.error.main]],
        color: theme.palette.error.main,
        marginBottom: theme.spacing.unit,
    },
    fieldset: {
        textAlign: 'left',
        margin: theme.spacing(0, 0, 3),

        '& > div': {
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'space-between',
            alignItems: 'flex-start',

            '& > *:not(:last-child)': {
                marginBottom: theme.spacing(2),
            },
        },
    },
    firstName: {
        width: '48%',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    lastName: {
        width: '48%',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    password: {
        width: '48%',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    confirmPassword: {
        width: '48%',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    input: {
        backgroundColor: theme.palette.common.white,
    },
    checkbox: {
        padding: 15,

        '& > :first-child': {
            background: theme.palette.common.white,
            borderRadius: 2,
            width: 18,
            height: 18,
        },
    },
    checkboxGroup: {
        '& > *:not(:last-child)': {
            marginBottom: theme.spacing(-1),
        },
    },
    submitButton: {
        boxShadow: ['none', '!important'],
        width: 180,
    },
});
