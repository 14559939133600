// @flow
import required from './required';

/**
 * Returns a validation error if value fails the Luhn check
 * @param value
 * @returns {string}
 */
export default (value: any): ?string => {
    if (typeof Stripe === 'undefined') {
        throw new Error('Stripe.js client library not loaded!!');
    }

    const requiredError = required(value);
    if (requiredError) return;

    const month = `${value || ''}`.substr(0, 2);
    const year = `${value || ''}`.substr(2);
    const invalid = !Stripe.card.validateExpiry(month, year);
    if (invalid) {
        return 'invalidExpiry';
    }
};
