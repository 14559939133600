// @flow
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import { client as gigwalk } from '../../../api/createGigwalkClient';
import types from './types';

/* Analytics */
// import { trackEvent } from '../../browser/shared/util/GoogleTagManager';
// import { REQUEST_RESET_PASSWORD } from '../constant/analytics/AuthPages';

/* eslint-disable quote-props */
const errorsByStatusCode = {
    '404': 'forgotPassword.form.errors.emailNotFound',
    '500': 'forgotPassword.form.errors.internalServerError',
    default: 'forgotPassword.form.errors.unknownError',
};
/* eslint-enable quote-props */

export const submitError = createAction(types.SUBMIT_ERROR);
export const submitSuccess = createAction(types.SUBMIT_SUCCESS);
export const submit = createAction(
    types.SUBMIT,
    (values: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            // trackEvent(REQUEST_RESET_PASSWORD);

            gigwalk.authorization.forgotPassword(values)
                .then(() => {
                    dispatch(submitSuccess());
                })
                .catch((err: $AxiosError<Object>) => {
                    let error = 'networkError';
                    if ('response' in err) {
                        const { data, status } = err.response;
                        if (typeof data.message === 'string') error = data.message;
                        else if (status in errorsByStatusCode) error = errorsByStatusCode[status];
                        else error = errorsByStatusCode.default;
                    }
                    dispatch(submitError(err));
                    return Promise.reject(error);
                })
        )
    )
);

export default {
    submit,
    submitSuccess,
    submitError,
};
