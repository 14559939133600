// @flow
import React, { Component, Fragment } from 'react';
import cx from 'classnames';
import { compose } from 'recompose';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import {
    Avatar,
    Card,
    CardHeader,
    CardContent,
    IconButton,
    LinearProgress,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@material-ui/core';
import {
    DirectionsRun as DirectionsRunIcon,
    MoreHoriz as MoreHorizIcon,
} from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { TFunction } from 'react-i18next';
import Timeline from './containers/Timeline';
import TimeEstimate from './containers/TimeEstimate';
import ScheduledDate from './containers/ScheduledDate';
import Assignee from './containers/Assignee';
import Payout from './containers/Payout';
import StarRating from '../../../../components/StarRating';
import CallToAction from './components/CallToAction';
import CallToActionText from './components/CallToActionText';
import CallToActionButton from './components/CallToActionButton';
import * as dialog from '../../../../ducks/dialog';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type State = {
    anchorEl: ?HTMLElement,
};

type OwnProps = ContextRouter & {
    classes: Object,
    className?: string,
    t: TFunction,
};
type StateProps = {
    cta: ?Object,
    menuActions: Object[],
    progress: number,
    ticket: Object,
};
type DispatchProps = {
    closeDialog: () => void,
    loadTicket: (ticketId: number) => Promise<void>,
    openDialog: (name: string, props: Object) => void,
};
type Props = OwnProps & StateProps & DispatchProps;

export class Summary extends Component<Props, State> {
    state = {
        anchorEl: null,
    };

    handleMenuToggle = (event: SyntheticEvent<any>) => {
        const { anchorEl } = this.state;
        this.setState({ anchorEl: anchorEl ? null : event.currentTarget });
    };

    handleActionClick = (event: SyntheticEvent<*>) => {
        const { closeDialog, loadTicket, match, openDialog } = this.props;
        const { action } = event.currentTarget.dataset;
        const orgId = parseInt(match.params.orgId, 10);
        const ticketId = parseInt(match.params.ticketId, 10);
        const dialogProps = {
            onClose: () => closeDialog(),
            onSubmitSuccess: () => {
                closeDialog();
                loadTicket(ticketId);
            },
        };

        switch (action) {
            case 'print': {
                window.open(`/tickets/${orgId}/print/${ticketId}/detail`, '_blank');
                break;
            }

            case 'ticketInfo':
            case 'apply':
            case 'withdraw':
            case 'review':
            case 'extendReservationWindow':
                openDialog(action, { ...dialogProps, ticketId });
                break;

            default:
                openDialog(action, { ...dialogProps, ticketIds: [ticketId] });
                break;
        }
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    renderCallToAction() {
        const { classes, cta, progress, t } = this.props;

        if (cta) {
            const { action, icon: Icon, key, variant } = cta;
            return (
                <CallToAction variant={variant}>
                    <CallToActionText>
                        {Icon ? <Icon className={classes.ctaIcon} /> : null}
                        {t(`ticketDetail.summary.callToAction.${key}.text`)}
                    </CallToActionText>
                    {action
                        ? (
                            <CallToActionButton data-action={action} onClick={this.handleActionClick}>
                                {t(`ticketDetail.summary.callToAction.${key}.action`)}
                            </CallToActionButton>
                        )
                        : null
                    }
                </CallToAction>
            );
        }

        return (
            <LinearProgress
              classes={{
                  root: classes.progress,
                  bar: classes.progressBar,
              }}
              variant="determinate"
              value={progress}
            />
        );
    }

    render() {
        const { classes, className, menuActions, t, ticket } = this.props;
        const { anchorEl } = this.state;
        const { approval_status: approvalStatus, title, rating, status, subscription } = ticket;

        return (
            <Fragment>
                <Card className={cx(className, classes.root)}>
                    <CardHeader
                      classes={{
                          action: classes.actionMenu,
                          title: classes.title,
                          subheader: classes.status,
                      }}
                      avatar={<Avatar className={classes.avatar}><DirectionsRunIcon /></Avatar>}
                      title={(
                          <Fragment>
                              <span>{title}</span>
                              {rating != null && approvalStatus !== 'UNREVIEWED'
                                  ? <StarRating disableHover value={rating} />
                                  : null
                              }
                          </Fragment>
                      )}
                      titleTypographyProps={{ variant: 'headline' }}
                      subheader={(
                          <Fragment>
                              <span className={classes.label}>{t('ticketDetail.summary.status')}</span>
                              {subscription.needs_approval
                                  ? `${t(`ticketDetail.summary.statusEnum.${status}`)} / ${t(`ticketDetail.summary.approvalStatusEnum.${approvalStatus}`)}`
                                  : t(`ticketDetail.summary.statusEnum.${status}`)
                              }
                          </Fragment>
                      )}
                      action={
                          menuActions.length > 0
                              ? (
                                  <IconButton onClick={this.handleMenuToggle}>
                                      <MoreHorizIcon />
                                  </IconButton>
                              )
                              : null
                      }
                    />
                    {this.renderCallToAction()}
                    <Menu
                      anchorEl={anchorEl}
                      open={!!anchorEl}
                      onClose={this.handleMenuClose}
                    >
                        {menuActions.map((action) => {
                            const Icon = action.icon;
                            return (
                                <MenuItem
                                  className={classes.menuItem}
                                  data-action={action.name}
                                  disabled={action.disabled}
                                  onClick={this.handleActionClick}
                                >
                                    <ListItemIcon className={classes.icon}><Icon /></ListItemIcon>
                                    <ListItemText disableTypography primary={t(`ticketDetail.summary.${action.name}`)} />
                                </MenuItem>
                            );
                        })}
                    </Menu>
                    <CardContent className={classes.cardContent}>
                        <Route component={Timeline} />
                        <Route component={ScheduledDate} />
                        <Route component={TimeEstimate} />
                        <Route component={Assignee} />
                        {subscription.needs_public_workforce ? <Route component={Payout} /> : null}
                    </CardContent>
                </Card>
            </Fragment>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    cta: selectors.getCallToAction(state, props),
    menuActions: selectors.getMenuActions(state, props),
    progress: selectors.getProgress(state, props),
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    ticket: selectors.getTicket(state, props),
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    closeDialog: () => dispatch(dialog.actions.close()),
    loadTicket: (ticketId: number) => dispatch(actions.loadTicket(ticketId)),
    openDialog: (name: string, props: Object) => dispatch(dialog.actions.open(name, props)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'Summary' }),
    withTranslation(),
    connector,
);

export default enhance(Summary);
