// @flow
export const LOAD_MARKERS = 'g/ticketDetail/LOAD_MARKERS';
export const LOAD_MARKERS_SUCCESS = `${LOAD_MARKERS}_SUCCESS`;
export const LOAD_MARKERS_ERROR = `${LOAD_MARKERS}_ERROR`;

export default {
    LOAD_MARKERS,
    LOAD_MARKERS_ERROR,
    LOAD_MARKERS_SUCCESS,
};
