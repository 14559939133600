// @flow
import React, { Component } from 'react';
import moment from 'moment';
import cx from 'classnames';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, getFormInitialValues, Field } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { DateRange as DateRangeIcon } from '@material-ui/icons';
import type { FormProps } from 'redux-form';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { TFunction } from 'react-i18next';
import type { Subscription } from 'gigwalk/lib/api/subscriptions/types';
import TextInput from '../../../../components/TextInput';
import RadioButton from '../../../../components/RadioButton';
import Editor from '../../../../components/Editor';
import fieldify from '../../../../components/fieldify';
import DateTimePicker from '../../../../components/DateTimePicker';
import { SCHEDULE_TYPES } from '../../../../../browser/shared/constant/ProjectConstant';
import Step from '../../components/Step';
import { selectors } from './duck';
import entitySelector from '../../../../redux/entities/entitySelector';
import getValidationRule from '../../utils/getValidationRule';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = {
    ...ContextRouter,
    ...FormProps,
    t: TFunction,
};

type StateProps = {
    inProgress: boolean,
    scheduleType: ?string,
    subscription: ?Subscription,
};

type DispatchProps = {};

type Props = OwnProps & StateProps & DispatchProps;

const TitleField = fieldify({ i18nKey: 'projectBuilder.info.validation.title' })(TextInput);
const RadioButtonField = fieldify()(RadioButton);
// $FlowFixMe flow complains about optional properties in InjectedProps not being in component Props
const DescriptionField = fieldify({ i18nKey: 'projectBuilder.info.validation.description' })(Editor);
// $FlowFixMe flow complains about optional properties in InjectedProps not being in component Props
const AsapEndDateField = fieldify({ i18nKey: 'projectBuilder.info.validation.asapEndDate' })(DateTimePicker);
// $FlowFixMe flow complains about optional properties in InjectedProps not being in component Props
const RangeStartDateField = fieldify({ i18nKey: 'projectBuilder.info.validation.rangeStartDate' })(DateTimePicker);
// $FlowFixMe flow complains about optional properties in InjectedProps not being in component Props
const RangeEndDateField = fieldify({ i18nKey: 'projectBuilder.info.validation.rangeEndDate' })(DateTimePicker);

export class BasicInfo extends Component<Props> {
    componentDidMount() {
        const { history, location } = this.props;
        const lastPage = history.location.state ? history.location.state.lastPage : false;
        if (!lastPage) {
            history.replace({ ...location, state: { lastPage: true } });
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        const { history, location } = nextProps;
        const lastPage = history.location.state ? history.location.state.lastPage : false;
        if (!lastPage) {
            history.replace({ ...location, state: { lastPage: true } });
        }
    }

    handleScheduleTypeChange = (event: Object) => {
        const { target } = event;

        if (target instanceof HTMLInputElement) {
            const { value } = target;
            const { change, untouch } = this.props;

            switch (value) {
                case SCHEDULE_TYPES.ASAP:
                    change('rangeStartDate', '', false, false);
                    change('rangeEndDate', '', false, false);
                    untouch('rangeStartDate', 'rangeEndDate');
                    break;

                case SCHEDULE_TYPES.RANGE:
                    change('asapEndDate', '', false, false);
                    untouch('asapEndDate');
                    break;

                default:
                    break;
            }
        }
    };

    render() {
        const { classes, inProgress, scheduleType, t } = this.props;

        return (
            <Step title={t('projectBuilder.info.header')}>
                <form className={classes.form}>
                    <div className={cx('c-form__row', classes.title)}>
                        <Field
                          required
                          name="title"
                          id="title"
                          label={t('projectBuilder.info.title')}
                          placeholder={t('projectBuilder.info.titlePlaceholder')}
                          component={TitleField}
                          validate={getValidationRule('title')}
                        />
                    </div>
                    <div className={cx('c-form__row', classes.description)}>
                        <Field
                          required
                          name="description"
                          id="description"
                          label={t('projectBuilder.info.description')}
                          placeholder={t('projectBuilder.info.descriptionPlaceholder')}
                          component={DescriptionField}
                          validate={getValidationRule('description')}
                        />
                    </div>
                    <label htmlFor="gigDates">{t('projectBuilder.info.gigDates')}</label>
                    <div id="gigDates">
                        <div className={cx('c-form__row', classes.asap)}>
                            <Field
                              name="scheduleType"
                              id="asap"
                              label={t('projectBuilder.info.beginImmediately')}
                              value={SCHEDULE_TYPES.ASAP}
                              component={RadioButtonField}
                              type="radio"
                              onChange={this.handleScheduleTypeChange}
                              disabled={inProgress && scheduleType !== SCHEDULE_TYPES.ASAP}
                              validate={getValidationRule('scheduleType')}
                            />
                            <div className={classes.control}>
                                <div className={classes.datePicker}>
                                    <span className="c-instruction">{t('projectBuilder.info.endsOn')}</span>
                                    <Field
                                      name="asapEndDate"
                                      id="asapEndDate"
                                      component={AsapEndDateField}
                                      customInputIcon={<DateRangeIcon />}
                                      disabled={scheduleType !== SCHEDULE_TYPES.ASAP}
                                      validate={getValidationRule('asapEndDate')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={cx('c-form__row', classes.range)}>
                            <Field
                              name="scheduleType"
                              id="range"
                              label={t('projectBuilder.info.specificDateRange')}
                              value={SCHEDULE_TYPES.RANGE}
                              component={RadioButtonField}
                              type="radio"
                              onChange={this.handleScheduleTypeChange}
                              disabled={inProgress && scheduleType !== SCHEDULE_TYPES.RANGE}
                              validate={getValidationRule('scheduleType')}
                            />
                            <div className={classes.control}>
                                <div className={classes.datePicker}>
                                    <span className="c-instruction">Starts on</span>
                                    <Field
                                      required
                                      name="rangeStartDate"
                                      id="rangeStartDate"
                                      component={RangeStartDateField}
                                      customInputIcon={<DateRangeIcon />}
                                      disabled={inProgress || scheduleType !== SCHEDULE_TYPES.RANGE}
                                      validate={getValidationRule('rangeStartDate')}
                                    />
                                </div>
                                <div className={classes.datePicker}>
                                    <span className="c-instruction">{t('projectBuilder.info.endsOn')}</span>
                                    <Field
                                      required
                                      name="rangeEndDate"
                                      id="rangeEndDate"
                                      component={RangeEndDateField}
                                      customInputIcon={<DateRangeIcon />}
                                      disabled={scheduleType !== SCHEDULE_TYPES.RANGE}
                                      validate={getValidationRule('rangeEndDate')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Step>
        );
    }
}

const valueSelector = formValueSelector('projectBuilder');
const subscriptionSelector = entitySelector('subscriptions');

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const { match } = props;
    const subscription = subscriptionSelector(state, match.params.subscriptionId);
    return {
        initialValues: {
            ...getFormInitialValues('projectBuilder')(state),
            ...selectors.getInitialValues(state, props),
        },
        inProgress: subscription ? subscription.state === 'ACTIVE' && moment(subscription.start_date).isBefore(moment()) : false,
        scheduleType: valueSelector(state, 'scheduleType'),
        subscription,
    };
};

const mapDispatchToProps: DispatchProps = {};

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'BasicInfo' }),
    withTranslation(),
    connector,
    reduxForm({
        form: 'projectBuilder',
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    })
);

export default enhance(BasicInfo);
