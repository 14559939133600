// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import styles from './styles';

const useStyles = makeStyles(styles, { name: 'InvalidLink' });

export default function InvalidLink() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Paper className={classes.paper}>
            <h1>{t('resetPassword.form.heading')}</h1>
            <p>
                <Trans
                  defaults={t('resetPassword.form.errors.invalidLink')}
                  components={[
                      'For security reasons, this link has expired. Click',
                      <Link to="/forgot-password">here</Link>,
                      'to request a new one',
                  ]}
                />
            </p>
        </Paper>
    );
}
