// @flow
import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import type { ActionType } from 'redux-actions';
import types from './types';
import typeof { submit } from './actions';

export type State = {
    email: ?string,
};

export const init: State = {
    email: null,
};

export default handleActions({
    [types.SUBMIT_SUCCESS]: (state: State, action: ActionType<submit>): State => ({
        ...state,
        email: action.payload.email,
    }),
    [LOCATION_CHANGE]: (state: State): State => ({
        ...state,
        email: null,
    }),
}, init);
