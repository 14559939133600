// @flow
import Component from './Component';
import template from '../templates/PopupConfirm.hbs';

export type PopupOptions = {
    text?: string,
    onSubmit?: ?() => void,
    onHide?: ?() => void,
    showConfirmationButton?: boolean,
    showCancelButton?: boolean,
};

const PopupConfirm = Component.extend({

    events: {
        'click [data-control="close"]': 'hide',
        'click [data-control="confirm"]': 'submit',
    },

    defaults: {
        text: '',
        onSubmit: false,
        onHide: false,
        showConfirmationButton: true,
        showCancelButton: true,
    },

    /**
     * @method initialize
     * @param options
     */
    initialize(options: PopupOptions) {
        this.options = _.extend({}, this.defaults, options);
        this.render().show();
        this.delegateEvents();
    },

    /**
     * @method render
     * @returns {PopupConfirm}
     */
    render(): PopupConfirm { // eslint-disable-line no-use-before-define
        this.$el.html(template(this.options));

        this.$els = {
            buffering: this.$el.find('[data-buffering=""]'),
            what: this.$el.find('[data-target="what"]'),
            close: this.$el.find('[data-control="close"]'),
            problemMsg: this.$el.find('.action.messages .problem'),
            confirmationMsg: this.$el.find('.action.messages .confirmation'),
            submitBtn: this.$el.find('[data-control="confirm"]'),
        };
        $('body').append(this.$el);

        this.$els.confirmationMsg.hide();
        this.$els.problemMsg.hide();
        return this;
    },

    submit() {
        this.$els.buffering.show();
        this.$els.submitBtn.addClass('disabled');
        if (this.options.onSubmit && typeof (this.options.onSubmit) === 'function') {
            this.options.onSubmit();
        }
    },

    hide() {
        this.$el.hide();
        this.$el.remove();
        if (this.options.onHide && typeof (this.options.onHide) === 'function') {
            this.options.onHide();
        }
    },

    /**
     * @method show
     */
    show() {
        this.$els.buffering.hide();
        this.$el.show();
    },

    /**
     * @complete
     * @param feedback
     */
    complete(feedback: { errorMsg?: string, confirmMsg?: string }) {
        if (feedback.errorMsg) {
            this.showError(feedback.errorMsg);
        } else if (feedback.confirmMsg) {
            this.showConfirmMessage(feedback.confirmMsg);
        } else {
            this.$el.hide();
        }
    },

    /**
     * @method updateMessageNotification
     */
    updateMessageNotification() {
        this.$els.buffering.hide();

        _.delay((): PopupConfirm => this.$el.hide(), 5000);
    },

    /**
     * @method showConfirmMessage
     * @param confirmMsg
     */
    showConfirmMessage(confirmMsg: string) {
        this.$els.confirmationMsg.find('[data-target="confirmation-msg"]').html(confirmMsg);
        this.$els.confirmationMsg.show();
        this.updateMessageNotification();
    },

    /**
     * @method showError
     * @param errorMsg
     */
    showError(errorMsg: string) {
        this.$els.problemMsg.find('[data-target="problem-msg"]').html(errorMsg);
        this.$els.problemMsg.show();
        this.updateMessageNotification();
    },

});

export default PopupConfirm;
