// @flow
export default () => ({
    submitButton: {
        position: 'relative',
        '&:only-child': {
            marginLeft: 'auto',
        },
    },

    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});
