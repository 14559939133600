// @flow
import assert from 'assert';
import logger from '../../../common/util/logger';

const NODE_ENV = process.env.NODE_ENV || 'development';

/* istanbul ignore next */
const dataLayer = global.dataLayer || [];

export type eventType = {
    eventCategory: string,
    eventAction: string,
    eventLabel: string,
    eventValue?: number,
};

export type virtualPageEvent = {
    url: string,
    title: ?string,
};

export type dataLayerValueType = {
    event: string,
    gaEventCategory?: string,
    gaEventAction?: string,
    gaEventLabel?: string,
    gaEventValue?: number,
};

/**
 * @method trackEvent
 */
export function trackEvent(inputEvent: eventType): dataLayerValueType {
    const { eventCategory, eventAction, eventLabel, eventValue } = inputEvent;
    assert(typeof eventCategory === 'string');
    assert(typeof eventAction === 'string');
    assert(typeof eventLabel === 'string');

    const dataLayerValues = {
        event: NODE_ENV === 'development' ? 'gaSandboxEvent' : 'gaTrackEvent',
        gaEventCategory: eventCategory,
        gaEventAction: eventAction,
        gaEventLabel: eventLabel,
        gaEventValue: eventValue || 1,
    };
    if (typeof window !== 'undefined') {
        logger.debug({ dataLayerValues }, 'Dispatching analytics event');

        dataLayer.push(dataLayerValues);
    }
    return dataLayerValues;
}

export type VirtualPageDataLayerObj = dataLayerValueType & {
    title: string,
    path: string,
    event: string,
};
/**
 * @method trackEvent
 */
export function trackVirtualPage(): VirtualPageDataLayerObj {
    const dataLayerValues: VirtualPageDataLayerObj = {
        event: NODE_ENV === 'development' ? 'gaSandboxEvent' : 'virtualPageView',
        path: window.location.pathname,
        title: document.title,
    };

    logger.debug({ dataLayerValues }, 'Dispatching analytics pageView');

    dataLayer.push(dataLayerValues);
    return dataLayerValues;
}

/**
 * Google tag manager utility service for pushing values to the data layer.
 */
export default {
    trackEvent,
    trackVirtualPage,
};
