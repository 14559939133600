// @flow
const envByHostname = {
    'gigwalk-multitenant-api-server': 'multitenant',
    'gigwalk-beta-api-server': 'beta',
    'gigwalk-stage-app-api-server': 'stage',
    'gigwalk-japan-api-server': 'jp_prod',
    'gigwalk-jp-staging-api-server': 'jp_stage',
    'gigwalk-partner-jp-api-server': 'jp_partner',
    'gigwalk-uu-partner': 'csmk_partner',
    'gigwalk-partner-dev-api-server': 'csmk_partner_dev',
    'gigwalk-uu': 'csmk_prod_old',
    'gigwalk-python-app-api-server': 'csmk_prod',
};

// Regular expression to test if photoUrl matches one of the known s3 buckets we use
// for hosting images
const urlRegex = new RegExp(`^https?:\\/\\/(${Object.keys(envByHostname).join('|')})\\.s3\\.amazonaws\\.com`);

export default function getThumbnailUrl(photoUrl: string): ?string {
    if (!urlRegex.test(photoUrl)) {
        return null;
    }

    // @todo URL is not available in IE11 but should be poly-filled soon - https://github.com/zloirock/core-js/pull/454
    const { hostname, pathname, protocol } = new URL(photoUrl);
    const env = envByHostname[hostname.split('.')[0]];
    return `${protocol}//d3jzakxrk8gw2o.cloudfront.net/${env}${pathname}`;
}
