// @flow
import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import { getAvailableActions, getPermittedActions, getTicket } from '../../../../../duck/selectors';

export const getInitialValues = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    getTicket,
    (ticket: ?Object): ?Object => {
        if (!ticket) {
            return { scheduledDate: undefined };
        }

        const {
            calendar_event: calendarEvent,
            location,
        } = ticket;

        // @todo Double check the timezone of the calendar event
        // I'm not sure if it's relative to the ticket's location or the user's location when the scheduled the gig
        return calendarEvent && calendarEvent.start
            ? { scheduledDate: moment.tz(calendarEvent.start, location.tzid).format('YYYY-MM-DDTHH:mm:ss') }
            : { scheduledDate: undefined };
    }
);


export const getReadOnly = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    getPermittedActions,
    (actions: string[]): string[] => {
        const readOnly = [];
        if (!actions.includes('schedule')) readOnly.push('scheduledDate');
        return readOnly;
    }
);

export const getDisabled = createSelector(
    getAvailableActions,
    (actions: string[]): string[] => {
        const disabled = [];
        if (!actions.includes('schedule')) disabled.push('scheduledDate');
        return disabled;
    }
);

export default {
    getDisabled,
    getInitialValues,
    getReadOnly,
};
