// @flow
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { compose } from 'recompose';
import { Trans, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Avatar,
    Card,
    CardHeader,
} from '@material-ui/core';
import {
    AssignmentInd as AssignmentIndIcon,
    AssignmentLate as AssignmentLateIcon,
    AssignmentReturn as AssignmentReturnIcon,
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    AttachMoney as AttachMoneyIcon,
    Cancel as CancelIcon,
    Edit as EditIcon,
    Forward as ForwardIcon,
    Group as GroupIcon,
    HourglassEmpty as HourglassEmptyIcon,
    PlayArrow as PlayArrowIcon,
    Schedule as ScheduleIcon,
    Star as StarIcon,
    ThumbDown as ThumbDownIcon,
    ThumbUp as ThumbUpIcon,
} from '@material-ui/icons';
import { Rocket as RocketIcon } from 'mdi-material-ui';
import type { TFunction } from 'react-i18next';
import { EVENTS } from '../../../../../../../browser/shared/constant/TicketConstant';
import getDisplayName from '../../../../../../util/getDisplayName';
import styles from './styles';

type Props = {
    classes: Object,
    data: Object,
    date: string,
    t: TFunction,
    type: string,
};

const iconsByEventType = {
    [EVENTS.CREATED]: RocketIcon,
    [EVENTS.CANCELED]: CancelIcon,
    [EVENTS.STARTED]: PlayArrowIcon,
    [EVENTS.SUBMITTED]: AssignmentTurnedInIcon,
    [EVENTS.SCHEDULED]: ScheduleIcon,
    [EVENTS.UNSCHEDULED]: ScheduleIcon,
    [EVENTS.EXTENDED]: ForwardIcon,
    [EVENTS.ASSIGNED]: AssignmentIndIcon,
    [EVENTS.UNASSIGNED]: AssignmentLateIcon,
    [EVENTS.AUTO_ASSIGNED]: AssignmentIndIcon,
    [EVENTS.AUTO_UNASSIGNED]: AssignmentLateIcon,
    [EVENTS.GROUPS_CHANGED]: GroupIcon,
    [EVENTS.PROJ_START_DATE_CHANGED]: EditIcon,
    [EVENTS.PROJ_DUE_DATE_CHANGED]: EditIcon,
    [EVENTS.PROJ_TIME_ESTIMATE_CHANGED]: EditIcon,
    [EVENTS.START_DATE_EDITED]: EditIcon,
    [EVENTS.END_DATE_EDITED]: EditIcon,
    [EVENTS.TIME_ESTIMATE_EDITED]: EditIcon,
    [EVENTS.APPROVED]: ThumbUpIcon,
    [EVENTS.REJECTED]: ThumbDownIcon,
    [EVENTS.REOPENED]: AssignmentReturnIcon,
    [EVENTS.SUBMIT_DEADLINE_EDITED]: EditIcon,
    [EVENTS.RATED]: StarIcon,
    [EVENTS.PAID]: AttachMoneyIcon,
    [EVENTS.DEADLINE_SET]: HourglassEmptyIcon,
};

export class TicketEvent extends Component<Props> {
    renderContent() {
        const { data, t, type } = this.props;

        switch (type) {
            case EVENTS.APPROVED: {
                const { createdCustomer } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.workApproved', { customer })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'approved the work done for this gig',
                      ]}
                    />
                );
            }

            case EVENTS.ASSIGNED: {
                const { assignedCustomer, createdCustomer } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                const isSelfAssigned = createdCustomer.id === assignedCustomer.id;
                const assignee = isSelfAssigned ? t('glossary.themselves') : getDisplayName(assignedCustomer);
                const assigneeProfile = isSelfAssigned ? null : assignedCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigAssigned', { customer, assignee })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'assigned this gig to',
                          assigneeProfile ? <Link to={assigneeProfile}>assignee</Link> : <span>assignee</span>,
                      ]}
                    />
                );
            }

            case EVENTS.AUTO_ASSIGNED: {
                const { assignedCustomer } = data;
                const assignee = getDisplayName(assignedCustomer);
                const assigneeProfile = assignedCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigAutoAssigned', { assignee })}
                      components={[
                          'This gig was auto-assigned to',
                          assigneeProfile ? <Link to={assigneeProfile}>assignee</Link> : <span>assignee</span>,
                      ]}
                    />
                );
            }

            case EVENTS.CANCELED: {
                const { createdCustomer } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigCanceled', { customer })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'canceled this gig',
                      ]}
                    />
                );
            }

            case EVENTS.DEADLINE_SET: {
                const { assignedCustomer, deadline, triggerEvent } = data;
                const assignee = getDisplayName(assignedCustomer);
                const assigneeProfile = assignedCustomer.profile_link;
                const i18nKey = triggerEvent === 'extend'
                    ? 'ticketDetail.comments.deadlineExtended'
                    : 'ticketDetail.comments.deadlineSet';
                return (
                    <Trans
                      defaults={t(i18nKey, { assignee, deadline })}
                      components={[
                          assigneeProfile ? <Link to={assigneeProfile}>assignee</Link> : <span>assignee</span>,
                          'reservation window ends at',
                          <span>deadline</span>,
                      ]}
                    />
                );
            }

            case EVENTS.END_DATE_EDITED:
            case EVENTS.PROJ_DUE_DATE_CHANGED: {
                const { createdCustomer, newEndDate, oldEndDate } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.endDateChanged', { customer, newEndDate, oldEndDate })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'changed the end date from',
                          <span>originalEndDate</span>,
                          'to',
                          <span>newEndDate</span>,
                      ]}
                    />
                );
            }

            case EVENTS.EXTENDED: {
                const { createdCustomer, extendedDate, extendedFromDate } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigExtended', { customer, extendedDate, extendedFromDate })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'extended this gig from',
                          <span>extendedFromDate</span>,
                          'to',
                          <span>extendedDate</span>,
                      ]}
                    />
                );
            }

            case EVENTS.GROUPS_CHANGED: {
                const { createdCustomer, groupsAdded, groupsRemoved } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;

                let i18nKey = 'ticketDetail.comments.groupsChanged';
                if (groupsAdded.length === 0) {
                    i18nKey = 'ticketDetail.comments.groupsRemoved';
                } else if (groupsRemoved.length === 0) {
                    i18nKey = 'ticketDetail.comments.groupsAdded';
                }

                const join = (list: string, item: string, index: number, arr: string[]) => {
                    if (index === 0) {
                        return item;
                    }
                    return index === arr.length - 1
                        ? t('format.joinLast', { list, item })
                        : t('format.join', { list, item });
                };

                return (
                    <Trans
                      defaults={t(i18nKey, { customer, groupsAdded: groupsAdded.reduce(join, ''), groupsRemoved: groupsRemoved.reduce(join, '') })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'updated groups',
                      ]}
                    />
                );
            }

            case EVENTS.PAID: {
                const { amount, createdCustomer } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.workerPaid', { amount, customer })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'paid out',
                          <span>amount</span>,
                          'for completing this gig',
                      ]}
                    />
                );
            }

            case EVENTS.RATED: {
                const { createdCustomer, rating } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.workerRated', { customer, rating })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'submitted a worker rating of',
                          <span>rating</span>,
                          'stars',
                      ]}
                    />
                );
            }

            case EVENTS.SCHEDULED: {
                const { createdCustomer, scheduledDate } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigScheduled', { customer, scheduledDate })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'scheduled this gig for',
                          <span>scheduledDate</span>,
                      ]}
                    />
                );
            }

            case EVENTS.START_DATE_EDITED:
            case EVENTS.PROJ_START_DATE_CHANGED: {
                const { createdCustomer, newStartDate, oldStartDate } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.startDateChanged', { customer, newStartDate, oldStartDate })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'changed the start date from',
                          <span>oldStartDate</span>,
                          'to',
                          <span>newStartDate</span>,
                      ]}
                    />
                );
            }

            case EVENTS.SUBMIT_DEADLINE_EDITED: {
                const { createdCustomer, newEndDate, oldEndDate } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.submitDeadlineChanged', { customer, newEndDate, oldEndDate })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'updated the submit deadline from',
                          <span>originalEndDate</span>,
                          'to',
                          <span>newEndDate</span>,
                      ]}
                    />
                );
            }

            case EVENTS.TIME_ESTIMATE_EDITED:
            case EVENTS.PROJ_TIME_ESTIMATE_CHANGED: {
                const { createdCustomer } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                // Convert from seconds to minutes
                const newTimeEstimate = data.newTimeEstimate / 60;
                const oldTimeEstimate = data.oldTimeEstimate / 60;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.timeEstimateChanged', { customer, newTimeEstimate, oldTimeEstimate })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'updated the time estimate from',
                          <span>oldTimeEstimate</span>,
                          'to',
                          <span>newTimeEstimate</span>,
                      ]}
                    />
                );
            }

            case EVENTS.REOPENED: {
                const { createdCustomer } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigReopened', { customer })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'reopened this gig',
                      ]}
                    />
                );
            }

            case EVENTS.STARTED: {
                const { createdCustomer } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigStarted', { customer })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'started this gig',
                      ]}
                    />
                );
            }

            case EVENTS.SUBMITTED: {
                const { createdCustomer } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigSubmitted', { customer })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'submitted this gig',
                      ]}
                    />
                );
            }

            case EVENTS.UNASSIGNED: {
                const { createdCustomer } = data;

                if (!createdCustomer || createdCustomer.id === 0) {
                    return t('ticketDetail.comments.gigAutoUnassigned');
                }

                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigUnassigned', { customer })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'unassigned this gig',
                      ]}
                    />
                );
            }

            case EVENTS.UNSCHEDULED: {
                const { createdCustomer } = data;
                const customer = getDisplayName(createdCustomer);
                const customerProfile = createdCustomer.profile_link;
                return (
                    <Trans
                      defaults={t('ticketDetail.comments.gigUnscheduled', { customer })}
                      components={[
                          customerProfile ? <Link to={customerProfile}>customer</Link> : <span>customer</span>,
                          'unscheduled this gig',
                      ]}
                    />
                );
            }

            case EVENTS.REJECTED:
                return t('ticketDetail.comments.workRejected');

            case EVENTS.AUTO_UNASSIGNED:
                return t('ticketDetail.comments.gigAutoUnassigned');

            case EVENTS.CREATED:
                return t('ticketDetail.comments.gigLaunched');

            default:
                break;
        }
    }

    render() {
        const { classes, date, type } = this.props;
        const EventIcon = iconsByEventType[type];
        const title = (
            <Fragment>
                <span className={classes.author}>Gigwalk</span>
                <span className={classes.date}>{moment(date).fromNow()}</span>
            </Fragment>
        );

        return (
            <Card elevation={0}>
                <CardHeader
                  classes={{
                      avatar: classes.avatar,
                      title: classes.header,
                      subheader: classes.content,
                  }}
                  avatar={<Avatar><EventIcon /></Avatar>}
                  title={title}
                  subheader={this.renderContent()}
                />
            </Card>
        );
    }
}

const enhance = compose(
    withStyles(styles, { name: 'TicketEvent' }),
    withTranslation(),
);

export default enhance(TicketEvent);
