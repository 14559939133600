// @flow
import { createAction } from 'redux-actions';
import { formValueSelector, reset as resetForm } from 'redux-form';
import type { Dispatch } from 'redux';
import type { $AxiosXHR, $AxiosError } from 'axios';
import type { APIResponse } from 'gigwalk/lib/api/resource';
import { format } from '../../../../browser/shared/util/gigwalkApiErrorUtil';
import { client as gigwalk } from '../../../api/createGigwalkClient';
import * as snackbar from '../../../ducks/snackbar';
import types from './types';
import type { State as RootState } from '../../../redux/initialState';

// Create selector for retrieving form values from state
const selector = formValueSelector('addFunds');

// Action Creators
export const hideConfirmationDialog = createAction(types.HIDE_CONFIRMATION_DIALOG);

export const createStripeTokenSuccess = createAction(types.CREATE_STRIPE_TOKEN_SUCCESS);
export const createStripeTokenError = createAction(types.CREATE_STRIPE_TOKEN_ERROR);
export const createStripeToken = createAction(
    types.CREATE_STRIPE_TOKEN,
    (): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState) => (
            new Promise((resolve: (v: StripeToken) => void, reject: (v: StripeError) => void) => {
                const state = getState();
                const { cardNumber, cvc, expiry } = selector(state, 'cardNumber', 'cvc', 'expiry');
                const expiryMonth = `${expiry}`.substr(0, 2);
                const expiryYear = `${expiry}`.substr(2);

                Stripe.card.createToken({
                    number: cardNumber,
                    exp_month: expiryMonth,
                    exp_year: expiryYear,
                    cvc,
                }, (status: number, response: StripeToken | StripeError) => {
                    if (response.error) {
                        dispatch(createStripeTokenError());
                        // $FlowFixMe type refinement isn't working for some reason
                        reject(response);
                    } else {
                        dispatch(createStripeTokenSuccess());
                        resolve(response);
                    }
                });
            })
        )
    )
);

export const getBalanceSuccess = createAction(types.GET_BALANCE_SUCCESS);
export const getBalanceError = createAction(types.GET_BALANCE_ERROR);
export const getBalance = createAction(
    types.GET_BALANCE,
    (): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<any> => {
            const state = getState();

            if (!state.session.user) {
                return Promise.reject();
            }

            const params = { organization_id: state.session.user.organization.id };
            return gigwalk.payments.getCurrentBalance(params)
                .then((resp: $AxiosXHR<APIResponse<[{ current_balance: number }]>>) => {
                    const balance = parseFloat(resp.data.data[0].current_balance);
                    dispatch(getBalanceSuccess(balance));
                })
                .catch(() => { dispatch(getBalanceError()); });
        }
    )
);

export const addFundsSuccess = createAction(types.ADD_FUNDS_SUCCESS);
export const addFundsError = createAction(types.ADD_FUNDS_ERROR);
export const addFunds = createAction(
    types.ADD_FUNDS,
    (): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<any> => {
            const state = getState();
            const amount = parseFloat(selector(state, 'amount'));

            if (!state.session.user) {
                return Promise.reject();
            }

            const orgId = state.session.user.organization.id;

            return dispatch(createStripeToken())
                .then((resp: StripeToken) => {
                    const params = {
                        organization_id: orgId,
                        stripe_token: resp.id,
                        amount,
                    };
                    return gigwalk.payments.addFunds(params);
                })
                .then(() => {
                    // NOTE: Dispatching `reset` doesn't seem to work as expected. When onBlur is triggered,
                    // the field's value is restored to what it was prior to reset.
                    dispatch(resetForm('addFunds'));
                    dispatch(getBalance());
                    dispatch(addFundsSuccess(amount));
                })
                .catch((err: $AxiosError<any>) => {
                    const resp = err.response;
                    if (resp && resp.data && resp.data.gw_api_response) {
                        const message = format(resp.data.gw_api_response);
                        dispatch(snackbar.actions.enqueue(message, { variant: 'error' }));
                    }
                    dispatch(addFundsError());
                });
        }
    )
);

export default {
    addFunds,
    addFundsSuccess,
    addFundsError,
    createStripeToken,
    createStripeTokenSuccess,
    createStripeTokenError,
    getBalance,
    getBalanceSuccess,
    getBalanceError,
    hideConfirmationDialog,
};
