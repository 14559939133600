// @flow
export const CREATE_PROJECT = 'g/projectBuilder/CREATE';
export const CREATE_PROJECT_SUCCESS = `${CREATE_PROJECT}_SUCCESS`;
export const CREATE_PROJECT_ERROR = `${CREATE_PROJECT}_ERROR`;

export const LAUNCH_PROJECT = 'g/projectBuilder/LAUNCH';
export const LAUNCH_PROJECT_SUCCESS = `${LAUNCH_PROJECT}_SUCCESS`;
export const LAUNCH_PROJECT_ERROR = `${LAUNCH_PROJECT}_ERROR`;

export const LOAD_PROJECT = 'g/projectBuilder/LOAD';
export const LOAD_PROJECT_SUCCESS = `${LOAD_PROJECT}_SUCCESS`;
export const LOAD_PROJECT_ERROR = `${LOAD_PROJECT}_ERROR`;

export const SAVE_PROJECT = 'g/projectBuilder/SAVE';
export const SAVE_PROJECT_SUCCESS = `${SAVE_PROJECT}_SUCCESS`;
export const SAVE_PROJECT_ERROR = `${SAVE_PROJECT}_ERROR`;

export const REQUEST_REVIEW = 'g/projectBuilder/REQUEST_REVIEW';
export const REQUEST_REVIEW_SUCCESS = `${REQUEST_REVIEW}_SUCCESS`;
export const REQUEST_REVIEW_ERROR = `${REQUEST_REVIEW}_ERROR`;

export const RESET_STATE = 'g/projectBuilder/RESET_STATE';

export default {
    CREATE_PROJECT,
    CREATE_PROJECT_ERROR,
    CREATE_PROJECT_SUCCESS,
    LAUNCH_PROJECT,
    LAUNCH_PROJECT_ERROR,
    LAUNCH_PROJECT_SUCCESS,
    LOAD_PROJECT,
    LOAD_PROJECT_ERROR,
    LOAD_PROJECT_SUCCESS,
    REQUEST_REVIEW,
    REQUEST_REVIEW_ERROR,
    REQUEST_REVIEW_SUCCESS,
    RESET_STATE,
    SAVE_PROJECT,
    SAVE_PROJECT_ERROR,
    SAVE_PROJECT_SUCCESS,
};
