// @flow
import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Field, formValueSelector, getFormInitialValues, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { compose, withHandlers, withProps } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { GridList, GridListTile, Typography } from '@material-ui/core';
// $FlowTypedIssue libdef is missing isWidthDown export for this module
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Star, ThumbUp } from '@material-ui/icons';
import type { FormProps } from 'redux-form';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { TFunction } from 'react-i18next';
import fieldify from '../../../../../../components/fieldify';
import entitySelector from '../../../../../../redux/entities/entitySelector';
import Step from '../../../../components/Step';
import { selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../../../redux/initialState';

type OwnProps = {
    ...ContextRouter,
    ...FormProps,
    classes: Object,
    t: TFunction,
};

type StateProps = {
    editMode: boolean,
    needsPublicWorkforce: boolean,
};

export type Props = OwnProps & StateProps;

const GridListTileField = compose(
    fieldify(),
    withProps((props: Object) => {
        const { checked, className } = props;
        return { className: cx(className, { active: checked }) };
    }),
    withHandlers({
        onClick: (props: Object) => {
            const { checked, onChange } = props;
            return () => onChange(!checked);
        },
    }),
)(GridListTile);

export const Approval = (props: Props) => {
    const { classes, editMode, t, width } = props;

    let cellWidth = 180;
    if (isWidthDown('sm', width)) {
        cellWidth = 120;
    } else if (isWidthDown('md', width)) {
        cellWidth = 140;
    }

    const cellHeight = cellWidth * 0.9;
    const cols = 2;
    const spacing = 16;

    const gridListProps = {
        cellHeight,
        cols,
        spacing,
        style: {
            width: (cellWidth + spacing) * cols,
            marginTop: 0,
        },
    };
    const gridListTileProps = {
        classes: {
            root: classes.tileRoot,
            tile: classes.tile,
        },
    };

    return (
        <Step title={t('projectBuilder.people.approval.header')} subheader={t('projectBuilder.people.approval.subheader')}>
            <form className={classes.form}>
                <Typography className={classes.label} align="center">
                    {t('projectBuilder.people.approval.selectAny')}
                </Typography>
                <GridList {...gridListProps}>
                    <Field
                      id="needsApproval"
                      name="needsApproval"
                      type="checkbox"
                      component={GridListTileField}
                      disabled={editMode}
                      {...gridListTileProps}
                    >
                        <ThumbUp className={classes.tileIcon} />
                        <Typography className={classes.tileCaption} component="p">
                            {t('projectBuilder.people.approval.approveWork')}
                        </Typography>
                    </Field>
                    <Field
                      id="twoWayRatingEnabled"
                      name="twoWayRatingEnabled"
                      type="checkbox"
                      component={GridListTileField}
                      disabled={editMode}
                      {...gridListTileProps}
                    >
                        <Star className={classes.tileIcon} />
                        <Typography className={classes.tileCaption} component="p">
                            {t('projectBuilder.people.approval.rateWorker')}
                        </Typography>
                    </Field>
                    <Field name="needsPublicWorkforce" component="input" type="hidden" />
                </GridList>
            </form>
        </Step>
    );
};

const valueSelector = formValueSelector('projectBuilder');
const subscriptionSelector = entitySelector('subscriptions');

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const { match } = props;
    return {
        editMode: subscriptionSelector(state, match.params.subscriptionId, 'state') === 'ACTIVE',
        initialValues: {
            ...getFormInitialValues('projectBuilder')(state),
            ...selectors.getInitialValues(state, props),
        },
        needsPublicWorkforce: valueSelector(state, 'needsPublicWorkforce'),
    };
};

const connector: Connector<OwnProps, Props> = connect(mapStateToProps);
const enhance = compose(
    withWidth({}),
    withStyles(styles, { name: 'Approval' }),
    withTranslation(),
    connector,
    reduxForm({
        form: 'projectBuilder',
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    })
);

export default enhance(Approval);
