// @flow
/* global google */
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import logger from '../../util/logger';
import types from './types';

let sessionToken = null;

export const getPlaceDetailsSuccess = createAction(types.GET_PLACE_DETAILS_SUCCESS);
export const getPlaceDetailsError = createAction(types.GET_PLACE_DETAILS_ERROR);
export const getPlaceDetails = createAction(
    types.GET_PLACE_DETAILS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<Object> => {
            const promise = new Promise((resolve, reject) => {
                const service = new google.maps.places.PlacesService(document.createElement('div'));
                const callback = (place, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK) resolve(place);
                    else reject(status);
                };
                service.getDetails({ ...params, ...(sessionToken ? { sessionToken } : undefined) }, callback);
                sessionToken = null;
            });

            return promise
                .then((place: Object) => {
                    dispatch(getPlaceDetailsSuccess(place));
                    return place;
                })
                .catch((err: Error) => {
                    logger.error(err);
                    dispatch(getPlaceDetailsError());
                    return Promise.reject(err);
                });
        }
    )
);

export const getPlacePredictionsSuccess = createAction(types.GET_PLACE_PREDICTIONS_SUCCESS);
export const getPlacePredictionsError = createAction(types.GET_PLACE_PREDICTIONS_ERROR);
export const getPlacePredictions = createAction(
    types.GET_PLACE_PREDICTIONS,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<Object[]> => {
            const promise = new Promise((resolve, reject) => {
                const service = new google.maps.places.AutocompleteService();
                const callback = (predictions: ?Object[], status: string) => {
                    switch (status) {
                        case google.maps.places.PlacesServiceStatus.OK:
                        case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
                            resolve(predictions || []);
                            break;

                        default:
                            reject(status);
                            break;
                    }
                };
                if (!sessionToken) {
                    sessionToken = new google.maps.places.AutocompleteSessionToken();
                }
                service.getPlacePredictions({ ...params, sessionToken }, callback);
            });

            return promise
                .then((predictions: Object[]) => {
                    dispatch(getPlacePredictionsSuccess(predictions));
                    return predictions;
                })
                .catch((err: Error) => {
                    logger.error(err);
                    dispatch(getPlacePredictionsError());
                    return Promise.reject(err);
                });
        }
    )
);

export const findPlaceFromQuerySuccess = createAction(types.FIND_PLACE_FROM_QUERY_SUCCESS);
export const findPlaceFromQueryError = createAction(types.FIND_PLACE_FROM_QUERY_ERROR);
export const findPlaceFromQuery = createAction(
    types.FIND_PLACE_FROM_QUERY,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): Promise<Object[]> => {
            const promise = new Promise((resolve, reject) => {
                const service = new google.maps.places.PlacesService(document.createElement('div'));
                const callback = (places, status) => {
                    switch (status) {
                        case google.maps.places.PlacesServiceStatus.OK:
                        case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
                            resolve(places || []);
                            break;

                        default:
                            reject(status);
                            break;
                    }
                };
                service.findPlaceFromQuery(params, callback);
            });

            return promise
                .then((places: Object[]) => {
                    dispatch(findPlaceFromQuerySuccess(places));
                    return places;
                })
                .catch((err: Error) => {
                    logger.error(err);
                    dispatch(findPlaceFromQueryError());
                    return Promise.reject(err);
                });
        }
    )
);

export default {
    findPlaceFromQuery,
    findPlaceFromQueryError,
    findPlaceFromQuerySuccess,
    getPlaceDetails,
    getPlaceDetailsError,
    getPlaceDetailsSuccess,
    getPlacePredictions,
    getPlacePredictionsError,
    getPlacePredictionsSuccess,
};
