// @flow
export default (theme: Object) => ({
    footer: {
        background: theme.palette.common.black,
        color: theme.palette.common.white,
        textAlign: 'center',
        fontSize: 12,
    },
    content: {
        padding: theme.spacing(1.5, 0),
    },
    logo: {
        width: 100,
        marginBottom: theme.spacing(0.5),
        opacity: 0.4,
    },
    info: {
        marginBottom: 0,
        opacity: 0.4,
        fontWeight: 300,
    },
});
