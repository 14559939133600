// @flow
import { createSelector, createStructuredSelector } from 'reselect';
import type { Location as Gigwalk$Location } from 'gigwalk/lib/api/locationLists/types';
import { selectors as projectBuilder } from '../../../duck';
import type { State as RootState } from '../../../../../redux/initialState';
import type {
    Relation,
    UnresolvedLocation,
    LocationRelation,
} from '../../../../../redux/entities/locationLists';

// type FormValues = {
//     locationListId: number,
// };

export const getLocations = createSelector(
    (state: RootState) => state.projectBuilder.locations.locationIds,
    (state: RootState) => state.entities.locations,
    (state: RootState) => state.entities.relations,
    // $FlowFixMe need to fix gigwalk-node type definitions
    (ids: Array<number>, locations: { [key: string]: Gigwalk$Location }, relations: { [key: string]: Relation }): Array<LocationRelation> => (
        ids.map((id: number): LocationRelation => ({
            ...locations[`${relations[`${id}`].id}`],
            relation_id: id,
        }))
    )
);

export const getUnresolvedLocations = createSelector(
    (state: RootState) => state.projectBuilder.locations.unresolvedLocationIds,
    (state: RootState) => state.entities.unresolvedLocations,
    (ids: Array<number>, unresolvedLocations: { [key: string]: UnresolvedLocation }): Array<UnresolvedLocation> => (
        ids.map((id: number): UnresolvedLocation => unresolvedLocations[`${id}`])
    )
);

export const getInitialValues = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    projectBuilder.getInitialValues,
    createStructuredSelector({
        locationListId: (values: Object) => values.locationListId,
    })
);

export default {
    getInitialValues,
    getLocations,
    getUnresolvedLocations,
};
