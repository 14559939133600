// @flow
import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Field, formValueSelector, getFormInitialValues, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { compose, withHandlers, withProps } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { GridList, GridListTile, Typography } from '@material-ui/core';
// $FlowTypedIssue libdef is missing isWidthDown export for this module
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import {
    Assignment as AssignmentIcon,
    People as PeopleIcon,
    VerifiedUser as VerifiedUserIcon,
} from '@material-ui/icons';
import { BriefcaseCheck as BriefcaseCheckIcon } from 'mdi-material-ui';
import type { FormProps } from 'redux-form';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { TFunction } from 'react-i18next';
import { OPTIN_TYPES } from '../../../../../../../browser/shared/constant/ProjectConstant';
import fieldify from '../../../../../../components/fieldify';
import entitySelector from '../../../../../../redux/entities/entitySelector';
import getValidationRule from '../../../../utils/getValidationRule';
import Step from '../../../../components/Step';
import { selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../../../redux/initialState';

type OwnProps = {
    ...ContextRouter,
    ...FormProps,
    classes: Object,
    t: TFunction,
};

type StateProps = {
    editMode: boolean,
    needsPublicWorkforce: boolean,
};

export type Props = OwnProps & StateProps;

const GridListTileField = compose(
    fieldify(),
    withProps((props: Object) => {
        const { checked, className } = props;
        return { className: cx(className, { active: checked }) };
    }),
    withHandlers({
        onClick: (props: Object) => {
            const { onChange, value } = props;
            return () => onChange(value);
        },
    }),
)(GridListTile);

export const WorkAllocation = (props: Props) => {
    const { classes, editMode, needsPublicWorkforce, t, user, width } = props;
    const orgAllowsAutoassign = user ? user.organization.config.autoassign.enabled : false;

    let cellWidth = 180;
    if (isWidthDown('sm', width)) {
        cellWidth = 120;
    } else if (isWidthDown('md', width)) {
        cellWidth = 140;
    }

    const cellHeight = cellWidth * 0.9;
    const cols = (width === 'xs' || needsPublicWorkforce) ? 2 : 3;
    const spacing = 16;

    const gridListProps = {
        cellHeight,
        cols,
        spacing,
        style: {
            width: (cellWidth + spacing) * cols,
            marginTop: 0,
        },
    };
    const gridListTileProps = {
        classes: {
            root: classes.tileRoot,
            tile: classes.tile,
        },
        disabled: editMode,
    };

    return (
        <Step
          title={t('projectBuilder.people.workAllocation.header')}
          subheader={t('projectBuilder.people.workAllocation.subheader')}
        >
            <form className={classes.form}>
                <Typography className={classes.label} align="center">
                    {t('projectBuilder.people.workAllocation.selectOne')}
                </Typography>
                <GridList {...gridListProps}>
                    <Field
                      id="doubleOptin"
                      name="workAllocation"
                      type="radio"
                      value={{ optinType: OPTIN_TYPES.DOUBLE_OPTIN, autoassign: false }}
                      validate={getValidationRule('workAllocation')}
                      component={GridListTileField}
                      {...gridListTileProps}
                    >
                        <AssignmentIcon className={classes.tileIcon} />
                        <Typography className={classes.tileCaption} component="p">
                            {t('projectBuilder.people.workAllocation.doubleOptIn')}
                        </Typography>
                    </Field>
                    <Field
                      id="systemApprovedOptin"
                      name="workAllocation"
                      type="radio"
                      value={{ optinType: OPTIN_TYPES.SYSTEM_APPROVED_OPTIN, autoassign: false }}
                      validate={getValidationRule('workAllocation')}
                      component={GridListTileField}
                      {...gridListTileProps}
                    >
                        <VerifiedUserIcon className={classes.tileIcon} />
                        <Typography className={classes.tileCaption} component="p">
                            {t('projectBuilder.people.workAllocation.systemApprovedOptIn')}
                        </Typography>
                    </Field>
                    {!needsPublicWorkforce
                        ? (
                            <Field
                              id="simpleOptin"
                              name="workAllocation"
                              type="radio"
                              value={{ optinType: OPTIN_TYPES.SIMPLE_OPTIN, autoassign: false }}
                              validate={getValidationRule('workAllocation')}
                              component={GridListTileField}
                              {...gridListTileProps}
                            >
                                <PeopleIcon className={classes.tileIcon} />
                                <Typography className={classes.tileCaption} component="p">
                                    {t('projectBuilder.people.workAllocation.simpleOptIn')}
                                </Typography>
                            </Field>
                        )
                        : <span style={{ display: 'none' }} />
                    }
                    {!needsPublicWorkforce && orgAllowsAutoassign
                        ? (
                            <Field
                              id="autoassign"
                              name="workAllocation"
                              type="radio"
                              value={{ optinType: OPTIN_TYPES.NO_OPTIN, autoassign: true }}
                              validate={getValidationRule('workAllocation')}
                              component={GridListTileField}
                              {...gridListTileProps}
                            >
                                <BriefcaseCheckIcon className={classes.tileIcon} />
                                <Typography className={classes.tileCaption} component="p">
                                    {t('projectBuilder.people.workAllocation.autoassign')}
                                </Typography>
                            </Field>
                        )
                        : <span style={{ display: 'none' }} />
                    }
                    <Field name="needsPublicWorkforce" component="input" type="hidden" />
                </GridList>
            </form>
        </Step>
    );
};

const valueSelector = formValueSelector('projectBuilder');
const subscriptionSelector = entitySelector('subscriptions');

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const { match } = props;
    return {
        editMode: subscriptionSelector(state, match.params.subscriptionId, 'state') === 'ACTIVE',
        initialValues: {
            ...getFormInitialValues('projectBuilder')(state),
            ...selectors.getInitialValues(state, props),
        },
        needsPublicWorkforce: valueSelector(state, 'needsPublicWorkforce') || false,
        user: state.session.user,
    };
};

const connector: Connector<OwnProps, Props> = connect(mapStateToProps);
const enhance = compose(
    withWidth({}),
    withStyles(styles, { name: 'WorkAllocation' }),
    withTranslation(),
    connector,
    reduxForm({
        form: 'projectBuilder',
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    })
);

export default enhance(WorkAllocation);
