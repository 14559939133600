// @flow
import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import type { ContextRouter } from 'react-router';
import type { Location as Gigwalk$Location } from 'gigwalk/lib/api/locationLists/types';
import type { Group } from 'gigwalk/lib/api/groups/types';
import { STATE } from '../../../../../../browser/shared/constant/ProjectConstant';
import entitySelector from '../../../../../redux/entities/entitySelector';
import { selectors as projectBuilder } from '../../../duck';
import type { State as RootState } from '../../../../../redux/initialState';
import type { Relation, LocationRelation } from '../../../../../redux/entities/locationLists';

// type FormValues = {
//     gigPrice: ?number,
//     needsPublicWorkforce: boolean,
//     oversamplingOverage: ?number,
//     oversamplingTarget: ?number,
// };

const valueSelector = formValueSelector('projectBuilder');
const subscriptionSelector = entitySelector('subscriptions');
const organizationSelector = entitySelector('organizations');

const getCurrentBalance = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): number => {
    const { match } = props;
    return organizationSelector(state, match.params.orgId, 'current_balance') || 0;
};

const getSubscriptionState = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): string => {
    const { match } = props;
    return subscriptionSelector(state, match.params.subscriptionId, 'state') || STATE.DRAFT;
};

export const getInitialValues = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    projectBuilder.getInitialValues,
    getSubscriptionState,
    getCurrentBalance,
    (state: RootState): ?number => state.projectBuilder.launch.priceEstimate,
    (values: Object, state: string, currentBalance: number, priceEstimate: ?number) => ({
        ...values,
        amountDue: priceEstimate != null && state === STATE.ACTIVE
            ? priceEstimate - values.projectFund
            : priceEstimate,
        currentBalance,
        priceEstimate,
    })
);

export const getMemberCount = createSelector(
    (state: RootState) => valueSelector(state, 'groups'),
    (groups: Group[]) => (groups || []).reduce((count: number, group: Group) => count + group.member_count, 0)
);

export const getLocations = createSelector(
    (state: RootState) => state.projectBuilder.launch.locationIds,
    (state: RootState) => state.entities.locations,
    (state: RootState) => state.entities.relations,
    // $FlowFixMe need to fix gigwalk-node type definitions
    (ids: number[], locationsMap: { [key: string]: Gigwalk$Location }, relationsMap: { [key: string]: Relation }): LocationRelation[] => (
        ids.reduce((locations: LocationRelation[], id: number): LocationRelation[] => {
            const relation = relationsMap[`${id}`];
            if (relation) {
                const location = locationsMap[relation.id];
                return location ? [...locations, { ...location, relation_id: id }] : locations;
            }
            return locations;
        }, [])
    )
);

export default {
    getInitialValues,
    getLocations,
    getMemberCount,
};
