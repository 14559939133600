// @flow
export default (theme: Object) => ({
    root: {
        fontFamily: 'ProximaNova',
        fontSize: 16,
        overflow: 'visible',
    },
    actionMenu: {
        // alignSelf: 'flex-start',
    },
    avatar: {
        color: theme.palette.common.white,
        backgroundColor: '#00c7ae',
    },
    title: {
        fontFamily: 'ProximaNova',
        fontWeight: 600,
        fontSize: 20,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginBottom: 5,

        '& > :first-child': {
            marginRight: 16,
        },
    },
    status: {
        fontFamily: 'ProximaNova',
        fontSize: 16,
    },
    label: {
        fontFamily: 'ProximaNova',
        fontSize: 16,
        fontWeight: 600,
        marginRight: theme.spacing(1),

        '&::after': {
            content: '":"',
        },
    },
    progress: {
        backgroundColor: theme.palette.grey[100],
    },
    progressBar: {
        backgroundColor: '#00c7ae',
    },
    cardContent: {
        '& > *': {
            margin: [[6, 0]],
            minHeight: 30,
        },
    },
    ctaIcon: {
        marginRight: theme.spacing(1),
        fontSize: 20,
    },
    toggleButtonGroupSelected: {
        boxShadow: 'none',
    },
    toggleButtonSelected: {
        background: theme.palette.primary.light,

        '&::after': {
            display: 'none',
        },
    },
    menuItem: {
        fontFamily: 'ProximaNova',
        fontSize: 16,
    },
    icon: {},
});
