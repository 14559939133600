// @flow weak
import Photo from './Photo';

// TODO: This will likely need it's own template at some point. Currently an exact duplicate of Photo

/**
 * @class (View) Signature
 * @constructor
 */
export default Photo.extend({
    getLocalisation() {
        return _.extend({}, this.text, {
            uploadLabel: this.text.upload_signature,
            questionType: this.text.signature,
        });
    },

    toString(): string {
        return 'Signature';
    },
});
