// @flow
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import type { $AxiosError, $AxiosXHR } from 'axios';
import type { APIResponse } from 'gigwalk/lib/api/resource';
import { client as gigwalk } from '../../../../../../common/api/createGigwalkClient';
import logger from '../../../../../../common/util/logger';
import types from './types';

export const fetchDataTypeSummarySuccess = createAction(types.FETCH_DATA_TYPE_SUMMARY_SUCCESS);
export const fetchDataTypeSummaryError = createAction(types.FETCH_DATA_TYPE_SUMMARY_ERROR);
export const fetchDataTypeSummary = createAction(
    types.FETCH_DATA_TYPE_SUMMARY,
    (waveId: string, filters?: Object): Function => (
        (dispatch: Dispatch<*>): Promise<any> => (
            gigwalk.client.post(`/v1/waves/${waveId}/data_type_summary`, filters)
                .then((resp: $AxiosXHR<APIResponse<any>>) => {
                    const data = resp.data.data[0];
                    dispatch(fetchDataTypeSummarySuccess(data));
                    return Promise.resolve(data);
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchDataTypeSummaryError());
                    return Promise.reject(err);
                })
        )
    )
);

export default {
    fetchDataTypeSummary,
    fetchDataTypeSummaryError,
    fetchDataTypeSummarySuccess,
};
