// @flow
import TargetDropdown from '../uis/TargetDropdown';
import Template from './Template';
import Component from '../../../../shared/view/Component';
import mainTpl from '../../../templates/ticket/widgets/self_directed/SelfDirectedWork.hbs';
import tplSurvey from '../../../templates/ticket/widgets/self_directed/Survey.hbs';
import tplStatusPropmpt from '../../../templates/ticket/widgets/self_directed/StatusPrompt.hbs';
import tplTargetDropdown from '../../../templates/ticket/widgets/self_directed/TargetDropdown.hbs';

const SelfDirectedWork = Component.extend({

    events: {
        'change .survey [data-action="toggleNext"]': 'toggleNext',
        'click .survey [data-action="next"]': 'next',
        'click .survey [data-action="back"]': 'back',
        'click .survey [data-action="save"]': 'save',
    },

    initialize(options: Object) {
        this.props = options.props;
        this.targets = _.isArray(options.targets) ? options.targets : [];
        this.templateID = parseInt(options.templateID, 10) || 0;
        this.ticketID = parseInt(options.ticketID, 10) || 0;
        this.readMode = !!options.readMode;

        this.text = GW.localisation.tickets.tickets.details.widgets;

        this.step = 0;
        this.templates = [];

        this.targetDropdown = new TargetDropdown({
            items: this.targets,
        });

        this.listenTo(this.targetDropdown, 'select', () => {
            if (this.step === 1) {
                this.$els.nextBtn.removeClass('disabled');
            }
        });

        this.listenTo(this.targetDropdown, 'deselect', () => {
            if (this.step === 1) {
                this.$els.nextBtn.addClass('disabled');
            }
        });

        this.targetDropdown.activate();
        this.render();
    },

    render() {
        this.$el.hide();

        this.$el.html(mainTpl());

        const { template, ticket } = this.props;
        const $survey = this.$el.find('[data-target=\'survey\']');
        const $templates = this.$el.find('[data-target=\'templates\']');
        const data = {
            titleBar: this.text.self_directed_work,
            template: (template) ? template.title : '',
            readMode: this.readMode,
        };

        if (!this.readMode) {
            $survey.html(tplSurvey(_.extend(data, this.text)));
        }

        this.$els = {
            templates: $templates,
            currentStep: $survey.find('[data-target="currentStep"]'),
            nextBtn: $survey.find('.survey [data-action="next"]'),
            backBtn: $survey.find('.survey [data-action="back"]'),
            saveBtn: $survey.find('.survey [data-action="save"]'),
        };

        // TODO: render templates that have answered items
        _.each(ticket.data_items, (instruction: Object) => {
            let widget;
            if (
                instruction.template_id === this.templateID
                && this.$els.templates.find(`[data-observation_target_id='${instruction.observation_target_id}']`).length === 0
            ) {
                widget = new Template({
                    ticketID: this.ticketID,
                    templateID: this.templateID,
                    targetID: instruction.observation_target_id,
                    readMode: this.readMode,
                    controls: ['edit', 'delete', 'toggle'],
                    props: { ...this.props },
                });

                this.templates.push(widget);
                this.$els.templates.append(widget.$el);

                this.targetDropdown.disableOption({
                    id: instruction.observation_target_id,
                });

                widget.onDelete = _.bind(this.removeTemplate, this, widget);
                widget.onSave = (...args: Array<any>) => this.onSave(...args);
            }
        });

        this.renderStep(this.step);

        this.$el.show();
    },

    renderStep(step: number) {
        // Detach target dropdown from DOM before using .html() to empty/replace content.
        // This method will remove any jQuery data associated with previous elements
        this.targetDropdown.$el.detach();
        this.targetDropdown.clearSelected();

        this.$els.currentStep.html('');

        switch (step) {
            case 0:
                this.$els.currentStep.html(tplStatusPropmpt(this.text));

                // Show save button (since default option is "No"), hide next and back buttons
                this.$els.nextBtn.removeClass('disabled').css({ display: 'none' });
                this.$els.saveBtn.css({ display: '' });
                this.$els.backBtn.hide();
                break;

            case 1:
                this.$els.currentStep
                    .append(tplTargetDropdown(this.text))
                    .find('[data-target=\'targetDropdown\']')
                    .replaceWith(this.targetDropdown.$el);

                // Hide save button, show next and back buttons. Next button is disabled until
                // observation target is selected.
                this.$els.saveBtn.css({ display: 'none' });
                this.$els.nextBtn.addClass('disabled').css({ display: '' });
                this.$els.backBtn.css({ display: '' });
                break;
            default:
                break;
        }
    },

    removeTemplate(widget: Object) {
        const i = this.templates.indexOf(widget);
        this.templates.splice(i, 1);

        widget.$el.remove();
        this.targetDropdown.enableOption({ id: widget.targetID });
    },

    toggleNext(event: JQueryEventObject) {
        const $target = $(event.target);
        const enableNextStep = ($target.val() === 'yes');

        this.$els.nextBtn.toggle(enableNextStep);
        this.$els.saveBtn.toggle(!enableNextStep);
    },

    toggleReadMode(bool: boolean) {
        this.readMode = bool;
        this.render();
    },

    next() {
        switch (this.step) {
            case 0:
                this.step = 1;
                this.renderStep(this.step);
                break;

            case 1: {
                const selectedTarget = this.targetDropdown.getSelected()[0];
                const widget = new Template({
                    ticketID: this.ticketID,
                    templateID: this.templateID,
                    targetID: selectedTarget.id,
                    readMode: this.readMode,
                    controls: ['edit', 'delete', 'toggle'],
                    props: { ...this.props },
                });

                this.templates.push(widget);
                this.$els.templates.append(widget.$el);

                this.targetDropdown.disableOption({ id: selectedTarget.id });

                widget.onDelete = _.bind(this.removeTemplate, this, widget);
                widget.onSave = (...args: Array<any>) => {
                    this.onSave(...args);
                };

                this.step = 0;
                this.renderStep(this.step);
                break;
            }
            default:
                break;
        }
    },

    back() {
        this.step = 0;
        this.renderStep(this.step);
    },

    save() {
        // This intentionally does not do anything.
    },
});

export default SelfDirectedWork;
