// @flow
import React from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import type { Node } from 'react';
import styles from './styles';

type Props = {
    children: Node,
    className?: string,
    classes: Object,
    subheader?: string,
    title: string,
}

export function Step(props: Props) {
    const { children, className, classes, subheader, title } = props;

    return (
        <Paper className={cx(classes.paper, className)} elevation={1}>
            <h2 className={classes.title}>{title}</h2>
            <h4 className={classes.subheader}>{subheader}</h4>
            <div className={classes.content}>
                {children}
            </div>
        </Paper>
    );
}

export default withStyles(styles, { name: 'Step' })(Step);
