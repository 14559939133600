// @flow
import { createSelector } from 'reselect';
import type { ContextRouter } from 'react-router';
import entitySelector from '../../../../../redux/entities/entitySelector';
import getDisplayName from '../../../../../util/getDisplayName';
import { getAPIParams, parseSearchParams } from '../../../duck/selectors';
import type { State as RootState } from '../../../../../redux/initialState';

export { getAPIParams, parseSearchParams } from '../../../duck/selectors';

const customerSelector = entitySelector('customers');
const getSelectedCustomer = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): ?Object => {
    const search = parseSearchParams(state, props);
    const selected = search.customer;
    return selected ? customerSelector(state, selected) : null;
};
export const getCustomerFilterValue = createSelector(
    getSelectedCustomer,
    (selected: ?Object): ?Object => (
        selected ? { label: getDisplayName(selected), value: selected } : undefined
    )
);

const ticketSelector = entitySelector('tickets');
const getSelectedTicket = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): ?Object => {
    const search = parseSearchParams(state, props);
    const selected = search.ticket;
    return selected ? ticketSelector(state, selected) : null;
};
export const getTicketFilterValue = createSelector(
    getSelectedTicket,
    (selected: ?Object): ?Object => (
        selected ? { label: selected.title, value: selected } : undefined
    )
);

export default {
    getAPIParams,
    getCustomerFilterValue,
    getTicketFilterValue,
    parseSearchParams,
};
