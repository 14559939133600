// @flow
import required from './required';

/**
 * Returns a validation error if value fails the Luhn check
 * @param value
 * @returns {string}
 */
export default (value: any): ?string => {
    if (typeof Stripe === 'undefined') {
        throw new Error('Stripe.js client library not loaded!!');
    }

    const requiredError = required(value);
    if (requiredError) return;

    const invalid = !Stripe.card.validateCardNumber(value);
    if (invalid) {
        return 'invalidCardNumber';
    }
};
