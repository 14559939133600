// @noflow
import Backbone from 'backbone';
import BaseCollection from './vo/BaseCollection';
import CondExpression from '../data_structures/CondExpression';

/**
 * @class (Model) SkipLogicTree
 * @constructor
 */
const SkipLogicTree = Backbone.Model.extend({

    parents: null,
    links: null,

    Collection: BaseCollection.extend({
        // $FlowFixMe property `constructor`. Property cannot be accessed on global object
        model: (attrs: Object, options: Object) => new this.constructor(attrs, options),
    }),

    initialize(...args: Array<any>) {
        this.links = [];
        this.parents = new this.Collection();
        this.parse(...args);
    },

    parse(data: Object) {
        _.each(data.children, ($cond: Object) => {
            const keys = _.keys($cond);

            if (keys.length !== 1) {
                throw new Error('Exception: There can only be one element in a dictionary expression');
            }

            const statement = $cond[keys[0]];
            if (statement.length !== 3) {
                throw new Error('Exception: A $cond expression must contain 3 elements');
            }

            // if (condition) then (consequence) else (alternative)
            const condition = new CondExpression(statement[0]);
            const consequence = statement[1];
            const alternative = statement[2];

            _.each(consequence, (item: Object) => {
                const node = new this.constructor(item);
                this.linkChild(condition, node, null);
            }, this);

            _.each(alternative, (item: Object) => {
                const node = new this.constructor(item);
                this.linkChild(condition, null, node);
            }, this);
        });
    },

    /**
     * Loosely links a child node to this node. A direct association is only formed when
     * condition is evaluated. If condition evaluates to true, node becomes a child. Otherwise,
     * altNode becomes a child.
     * @param condition {CondExpression}
     * @param node {SkipLogicTree}
     * @param altNode {SkipLogicTree}
     */
    linkChild(condition: Object, node: Object, altNode: Object) {
        if (!(condition instanceof CondExpression)) {
            throw new Error('Exception: condition is not a CondExpression');
        }

        const { links } = this;
        const index = _.findIndex(links, (link: Object) => link.condition.id === condition.id);
        const link = (index >= 0)
            ? links[index]
            : { condition, children: new this.Collection(), altChildren: new this.Collection() };

        if (node) {
            if (!(node instanceof SkipLogicTree)) {
                throw new Error('Exception: node is not a SkipLogicTree');
            }

            link.children.push(node);
            node.parents.add(this);
        }

        if (altNode) {
            if (!(altNode instanceof SkipLogicTree)) {
                throw new Error('Exception: altNode is not a SkipLogicTree');
            }

            link.altChildren.push(altNode);
            altNode.parents.add(this);
        }

        if (index === -1) {
            links.push(link);
        }
    },

    unlinkChild(condition: Object, node: Object, altNode: Object) {
        if (!(condition instanceof CondExpression)) {
            throw new Error('Exception: condition is not a CondExpression');
        }

        const { links } = this;
        const index = _.findIndex(links, (link: Object) => link.condition.id === condition.id);
        const link = (index >= 0) ? links[index] : null;

        if (link) {
            if (node) {
                if (!(node instanceof SkipLogicTree)) {
                    throw new Error('Exception: node is not a SkipLogicTree');
                }

                link.children.remove(node);
                node.parents.remove(this);
            }

            if (altNode) {
                if (!(altNode instanceof SkipLogicTree)) {
                    throw new Error('Exception: altNode is not a SkipLogicTree');
                }

                link.altChildren.remove(altNode);
                altNode.parents.remove(this);
            }

            if (link.children.length === 0 && link.altChildren.length === 0) {
                links.splice(index, 1);
            }
        }
    },

    /**
     *
     * @param dataItemValue
     * @returns {Array}
     */
    evaluate(dataItemValue: any[]): Array<Object> {
        const nextSteps = new Set();
        const { links } = this;

        for (let i = 0; i < links.length; i += 1) {
            const result = links[i].condition.evaluate(dataItemValue);
            if (result) {
                if (links[i].children.length) {
                    links[i].children.forEach((node) => {
                        nextSteps.add(node);
                    });
                }
            } else if (links[i].altChildren.length) {
                links[i].altChildren.forEach((node) => {
                    nextSteps.add(node);
                });
            }
        }

        return Array.from(nextSteps);
    },

    traverse(visitCallback: Function) {
        const { links } = this;

        if (this.visited) {
            return;
        }

        visitCallback(this);
        this.visited = true;

        // Traverse children
        _.each(links, (link: Object) => {
            link.children.each((child: Object) => {
                child.traverse(visitCallback);
            });

            link.altChildren.each((child: Object) => {
                child.traverse(visitCallback);
            });
        });

        delete this.visited;
    },

    toJSON(...args: Array<any>): Object {
        const json = this._super(...args);

        json.children = [];
        _.each(this.links, (link: Object) => {
            json.children.push({
                $cond: [link.condition.toJSON(), link.children.toJSON(), link.altChildren.toJSON()],
            });
        });

        Object.keys(json).forEach((key: string) => {
            if (!json[key]) {
                delete json[key];
                return;
            }

            if (_.isArray(json[key])) {
                if (json[key].length === 0) {
                    delete json[key];
                }

                return;
            }

            // Check if this is a plain object with no keys. If so, remove it.
            if (Object.getPrototypeOf(json[key]) === Object.getPrototypeOf({})) {
                if (_.size(json[key]) === 0) {
                    delete json[key];
                }
            }
        });

        return json;
    },
});

export default SkipLogicTree;
