// @flow
import React, { Component, Fragment } from 'react';
import cx from 'classnames';
import noop from 'lodash.noop';
import { compose } from 'recompose';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import {
    CircularProgress,
    Fade,
    IconButton,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import {
    AccountCircle as AccountCircleIcon,
    Check as CheckIcon,
    Clear as ClearIcon,
} from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { FormProps } from 'redux-form';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { TFunction } from 'react-i18next';
import { USER_ROLES } from '../../../../../../../browser/shared/constant/UserRoles';
import getDisplayName from '../../../../../../util/getDisplayName';
import fieldify from '../../../../../../components/fieldify';
import * as dialog from '../../../../../../ducks/dialog';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../../../redux/initialState';

type State = {
    statusTimer: ?number,
};

type OwnProps = ContextRouter & FormProps & {
    classes: Object,
    className?: string,
    t: TFunction,
};
type StateProps = {};
type DispatchProps = {
    closeDialog: () => void,
    loadTicket: (ticketId: number) => Promise<void>,
    openDialog: (name: string, props: Object) => void,
}
type Props = OwnProps & StateProps & DispatchProps;

const AssigneeField = fieldify()(TextField);
const ReadOnlyField = fieldify()(
    (props: Object) => {
        const { className, placeholder, value } = props;
        const style = !value && placeholder ? { fontStyle: 'italic' } : {};
        return (
            <div className={className} style={style}>
                {value || placeholder}
            </div>
        );
    }
);

export class Assignee extends Component<Props, State> {
    state = {
        statusTimer: null,
    };

    componentWillReceiveProps(nextProps: Props) {
        const { submitFailed, submitSucceeded } = this.props;
        const displayStatus = (!submitFailed && nextProps.submitFailed)
            || (!submitSucceeded && nextProps.submitSucceeded);

        if (displayStatus) {
            const { statusTimer } = this.state;
            clearTimeout(statusTimer);

            this.setState({
                statusTimer: setTimeout(() => {
                    this.setState({ statusTimer: null });
                }, 1500),
            });
        }
    }

    _formatAssignee = (value: Object) => {
        const { needsPublicWorkforce, user } = this.props;
        const assignee = value;

        if (assignee) {
            const hideDetails = user && user.role !== USER_ROLES.PLATFORM_ADMIN && user.id !== value.id && needsPublicWorkforce;
            return getDisplayName(assignee, { obfuscate: hideDetails });
        }

        return '';
    };

    handleUnassignClick = () => {
        const { change, handleSubmit } = this.props;
        change('assignee', null);
        // Use setTimeout to allow change action to be dispatched and update the store
        setTimeout(() => handleSubmit(), 0);
    };

    handleAssignClick = () => {
        const { closeDialog, isDoubleOptin, loadTicket, match, openDialog } = this.props;
        const ticketId = parseInt(match.params.ticketId, 10);
        const dialogProps = {
            onClose: () => closeDialog(),
            onSubmitSuccess: () => {
                closeDialog();
                loadTicket(ticketId);
            },
        };

        if (isDoubleOptin) {
            openDialog('assignApplicant', { ...dialogProps, ticketId });
        } else {
            openDialog('assign', { ...dialogProps, ticketIds: [ticketId] });
        }
    };

    renderAssigneeName = (props: Object) => {
        const { classes, disabled } = this.props;
        const { value: assignee, className, placeholder } = props;
        const canAssign = !assignee && !disabled.includes('assignee');
        const style = !assignee && placeholder ? { color: '#8e9499' } : {};
        const inputClassName = cx(className, { [classes.assigned]: assignee });

        return (
            <div className={inputClassName} style={style} onClick={canAssign ? this.handleAssignClick : noop}>
                {this._formatAssignee(assignee) || placeholder}
            </div>
        );
    };

    renderAssignee() {
        const { assignee, classes, disabled, readOnly } = this.props;
        const canUnassign = assignee && !disabled.includes('assignee');
        let endAdornment = null;

        if (canUnassign) {
            endAdornment = (
                <InputAdornment position="end">
                    <IconButton className={classes.clearButton} onClick={this.handleUnassignClick}>
                        <ClearIcon fontSize="inherit" />
                    </IconButton>
                </InputAdornment>
            );
        }

        return readOnly.includes('assignee')
            ? (
                <Field
                  name="assignee"
                  format={this._formatAssignee}
                  component={ReadOnlyField}
                  className={classes.readOnly}
                  placeholder="unassigned"
                />
            )
            : (
                <Field
                  name="assignee"
                  component={AssigneeField}
                  disabled={disabled.includes('assignee')}
                  InputProps={{
                      classes: {
                          root: classes.input,
                          input: classes.inputElement,
                          disabled: classes.disabled,
                      },
                      disableUnderline: true,
                      endAdornment,
                      inputComponent: this.renderAssigneeName,
                      placeholder: 'unassigned',
                      readOnly: true,
                  }}
                />
            );
    }

    render() {
        const { classes, handleSubmit, submitSucceeded, submitting, t } = this.props;
        const { statusTimer } = this.state;

        return (
            <Fragment>
                <form className={classes.container} onSubmit={handleSubmit}>
                    <AccountCircleIcon className={classes.icon} />
                    <span className={classes.label}>{t('ticketDetail.summary.assignee')}</span>
                    {this.renderAssignee()}
                    {submitting
                        ? <CircularProgress size={18} thickness={4} />
                        : (
                            <Fade in={!!statusTimer} timeout={{ exit: 250 }}>
                                {submitSucceeded
                                    ? <CheckIcon className={classes.successIcon} />
                                    : <ClearIcon className={classes.errorIcon} />
                                }
                            </Fade>
                        )
                    }
                </form>
            </Fragment>
        );
    }
}

const valueSelector = formValueSelector('assignee');
const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    const ticket = selectors.getTicket(state, props);
    return {
        applicants: ticket.applicants || [],
        assignee: valueSelector(state, 'assignee'),
        disabled: selectors.getDisabled(state, props),
        initialValues: selectors.getInitialValues(state, props),
        needsPublicWorkforce: ticket.subscription.needs_public_workforce,
        isDoubleOptin: ticket.subscription.is_double_optin,
        readOnly: selectors.getReadOnly(state, props),
        user: state.session.user,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    closeDialog: () => dispatch(dialog.actions.close()),
    loadTicket: (ticketId: number) => dispatch(actions.loadTicket(ticketId)),
    openDialog: (name: string, props: Object) => dispatch(dialog.actions.open(name, props)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'Assignee' }),
    withTranslation(),
    connector,
    reduxForm({
        form: 'assignee',
        enableReinitialize: true,
        destroyOnUnmount: false,
        onSubmit: (values: Object, dispatch: Dispatch<any>, props: Props): Promise<any> => dispatch(actions.submit(values, props)),
    })
);

export default enhance(Assignee);
