// @flow
import React from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

export default function highlightMatch(value: any, input: string, highlightProps?: Object): React$Element<*> {
    const valueStr = value != null ? value.toString() : '';
    const matches = match(valueStr, input);
    const parts = parse(valueStr, matches);

    return parts.map((part: Object, index: number): React$Element<*> => (
        part.highlight
            ? <strong {...highlightProps} key={index}>{part.text}</strong>
            : <span key={index}>{part.text}</span>
    ));
}
