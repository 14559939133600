// @flow
import type { User } from '../store_utils/types/User';
import logger from './logger';

/**
 * Helper Function to get the total number of projects launched.
 */
export const getLaunchCount = (projectStats: {[state: string]: number}): number => (
    Object.entries(projectStats)
        .filter(([key]): boolean => /^(active|archived|canceled|completed)/.test(key))
        .reduce((sum: number, value: [string, mixed]): number => sum + parseInt(value[1], 10), 0)
);

/**
 * Returns a default path for the user if nothing is provided.
 */
export default function getDefaultPath(user: ?User): string {
    let redirect: string = '/'; // Setting the value to something

    if (user != null && user.hasOwnProperty('organization') && user.hasOwnProperty('role')) {
        redirect = user.role === 'WORKER'
            ? `/tickets/${user.organization.id}/`
            : `/projects/${user.organization.id}/active/list`;

        // if (user.role === 'WORKER') {
        //     redirect = `/tickets/${organization.id}/`;
        // } else {
        //     /**
        //      * Safety checks in case the required info is not available
        //      */
        //     if (
        //         organization.vertical_type === 'STAFFING' &&
        //         organization.hasOwnProperty('stats') &&
        //         organization.stats.hasOwnProperty('projects') &&
        //         organization.stats.projects != null
        //     ) {
        //         redirect = getLaunchCount(organization.stats.projects) > 0 ?
        //             `/home/${organization.id}/overview/` :
        //             `/projects/${organization.id}/create/new/`;
        //     } else {
        //         redirect = `/projects/${organization.id}/active/list`;
        //     }
        // }
    }

    if (redirect === '/') {
        logger.error({ user, redirect }, 'Expected auth user shape to contain vertical_types and stats');
    }

    return redirect;
}
