// @flow
import type { Location as Gigwalk$Location } from 'gigwalk/lib/api/locations/types';
import type { LocationList, Location as Gigwalk$LocationListLocation } from 'gigwalk/lib/api/locationLists/types';
import type { Organization } from 'gigwalk/lib/api/organizations/types';
import type { Subscription } from 'gigwalk/lib/api/subscriptions/types';
import type { Certification } from 'gigwalk/lib/api/certifications/types';
import type { DataType } from 'gigwalk/lib/api/dataTypes/types';
import type { Group } from 'gigwalk/lib/api/groups/types';
import type { FileUpload, Relation, UnresolvedLocation } from './locationLists';

type Gigwalk$Organization = Organization & { current_balance?: number };

// @todo: need to define normalized types
export type State = {
    auditEvents: { [key: string]: Object },
    certifications: { [key: string]: Certification },
    customers: { [key: string]: Object },
    dataTypes: { [key: string]: DataType },
    fileUploads: { [key: string]: FileUpload },
    groups: { [key: string]: Group },
    locationLists: { [key: string]: LocationList },
    locations: { [key: string]: Gigwalk$Location | Gigwalk$LocationListLocation},
    organizations: { [key: string]: Gigwalk$Organization },
    payouts: { [key: string]: Object },
    relations: { [key: string]: Relation },
    subscriptions: { [key: string]: Subscription },
    templates: { [key: string]: Object },
    tickets: { [key: string]: Object },
    unresolvedLocations: { [key: string]: UnresolvedLocation },
};

export const init: State = Object.freeze({
    auditEvents: {},
    certifications: {},
    customers: {},
    dataTypes: {},
    fileUploads: {},
    groups: {},
    locationLists: {},
    locations: {},
    organizations: {},
    payouts: {},
    relations: {},
    subscriptions: {},
    templates: {},
    tickets: {},
    unresolvedLocations: {},
});
