// @flow
import React, { Component, createElement } from 'react';
import sanitizeHtml from 'sanitize-html';
import { withTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import {
    Avatar,
    Card,
    CardActions,
    CardHeader,
    CardContent,
    IconButton,
    Input,
    LinearProgress,
    Tooltip,
} from '@material-ui/core';
import {
    AttachMoney as AttachMoneyIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    Delete as DeleteIcon,
    Done as DoneIcon,
    Event as EventIcon,
    Gesture as GestureIcon,
    HelpOutline as HelpOutlineIcon,
    LibraryBooks as LibraryBooksIcon,
    Phone as PhoneIcon,
    Photo as PhotoIcon,
    RadioButtonUnchecked as RadioButtonUncheckedIcon,
    Schedule as ScheduleIcon,
    ShoppingCart as ShoppingCartIcon,
    Subject as SubjectIcon,
    Timer as TimerIcon,
} from '@material-ui/icons';
import { Numeric as NumericIcon } from 'mdi-material-ui';
import { compose, mapProps } from 'recompose';
import onClickOutside from 'react-onclickoutside';
import cx from 'classnames';
import type { TFunction } from 'react-i18next';
import fieldify from '../../../../../../components/fieldify';
import getValidationRule from '../../../../utils/getValidationRule';
import Ellipsis from '../../../../../../components/Ellipsis';
import Switch from '../../../../../../components/Switch';
import TypeDropdown from './TypeDropdown';
import MultipleChoice from './MultipleChoice';
import Number from './Number';
import styles from './styles';
import { VALUE_TYPE } from '../../../../../../../browser/shared/constant/ProjectConstant';

type Props = {
    classes: Object,
    description: string,
    dragButton?: React$Element<*>,
    expanded?: boolean,
    formKey: string, // the unique key that identifies this question in the form
    icon: string,
    id: number | string, // the dataType id
    isDragging?: boolean,
    index: number,
    onClick: () => void,
    onClickOutside: () => void, // consider changing this to onBlur and add support for onFocus
    onDelete: () => void,
    onDuplicate: () => void,
    onValueTypeChange: (valueType: string) => void,
    questionText: string,
    readOnly?: boolean,
    saving: boolean,
    t: TFunction,
    valid?: boolean,
    valueType: string,
};

type State = {
    valueType: string,
};

const InputField = fieldify()(Input);
// $FlowFixMe flow complains about optional properties in InjectedProps not being in component Props
const TypeDropdownField = fieldify()(TypeDropdown);
// $FlowFixMe flow complains about optional properties in InjectedProps not being in component Props
const SwitchField = compose(
    fieldify(),
    mapProps((props: Object) => {
        // The type prop is required by redux-form in order to get the field to behave
        // like a checkbox, but it should not be passed to Switch component
        const { type, ...rest } = props;
        return rest;
    })
)(Switch);

// Create a component to display question text in readOnly mode. This component needs to
// be able to render hyperlinks that are clickable and open to a new window/tab
const handleAnchorClick = (event: SyntheticEvent<any>) => {
    const { target } = event;
    if (target instanceof HTMLAnchorElement) {
        event.preventDefault();
        window.open(target.href, '_blank');
    }
};
const ReadOnlyHtml = (props: Object) => {
    const { className, value } = props;
    return <div className={className} onClick={handleAnchorClick} dangerouslySetInnerHTML={{ __html: value }} />;
};

const icons = {
    [VALUE_TYPE.MULTIPLE_CHOICE]: RadioButtonUncheckedIcon,
    [VALUE_TYPE.MULTI_SELECT]: CheckBoxOutlineBlankIcon,
    [VALUE_TYPE.NUMBER]: NumericIcon,
    [VALUE_TYPE.CURRENCY]: AttachMoneyIcon,
    [VALUE_TYPE.PHONE_NUMBER]: PhoneIcon,
    [VALUE_TYPE.PHOTO]: PhotoIcon,
    [VALUE_TYPE.FREE_TEXT]: SubjectIcon,
    [VALUE_TYPE.DATE]: EventIcon,
    [VALUE_TYPE.DATE_TIME]: ScheduleIcon,
    [VALUE_TYPE.TIME]: TimerIcon,
    [VALUE_TYPE.TASK]: DoneIcon,
    [VALUE_TYPE.BARCODE]: ShoppingCartIcon,
    [VALUE_TYPE.HINT]: HelpOutlineIcon,
    [VALUE_TYPE.CHECKBOXES]: CheckBoxOutlineBlankIcon,
    [VALUE_TYPE.SIGNATURE]: GestureIcon,
};

const questionComponents = {
    [VALUE_TYPE.MULTIPLE_CHOICE]: MultipleChoice,
    [VALUE_TYPE.MULTI_SELECT]: MultipleChoice,
    [VALUE_TYPE.CHECKBOXES]: MultipleChoice,
    [VALUE_TYPE.NUMBER]: Number,
    [VALUE_TYPE.CURRENCY]: Number,
    [VALUE_TYPE.PHONE_NUMBER]: Number,
    [VALUE_TYPE.TIME]: Number,
};

export class QuestionCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { valueType } = this.props;
        this.state = valueType ? { valueType } : { valueType: VALUE_TYPE.FREE_TEXT };
    }

    componentWillReceiveProps(nextProps: Props) {
        const { valueType } = this.props;
        const { valueType: nextValueType } = nextProps;

        // Keep state in sync with props
        if (valueType !== nextValueType) {
            this.setState({ valueType: nextValueType });
        }
    }

    handleClick = () => {
        const { onClick } = this.props;
        if (typeof onClick === 'function') {
            onClick();
        }
    };

    handleDelete = () => {
        const { onDelete } = this.props;
        if (typeof onDelete === 'function') {
            onDelete();
        }
    };

    handleDuplicate = () => {
        const { onDuplicate } = this.props;
        if (typeof onDuplicate === 'function') {
            onDuplicate();
        }
    };

    handleClickOutside = () => {
        if (typeof this.props.onClickOutside === 'function') { // eslint-disable-line react/destructuring-assignment
            this.props.onClickOutside(); // eslint-disable-line react/destructuring-assignment
        }
    };

    handleValueTypeChange = (event: Object, value: string, prevValue: string) => {
        const { onValueTypeChange } = this.props;

        // In order to prevent the entire form from re-rendering, redux-form will only update
        // the Field component whose value has changed. Since we want to render a different
        // question form depending on the valueType, we need to add this onChange handler to update
        // component's state when a new valueType is selected. Note that props may not have updated
        // because the parent component did not re-render
        if (value !== prevValue) {
            this.setState({ valueType: value }, () => {
                if (typeof onValueTypeChange === 'function') {
                    onValueTypeChange(value);
                }
            });
        }
    };

    renderQuestionForm(): React$Element<any> {
        const { classes, formKey, id, questionText, readOnly, t } = this.props;
        const { valueType } = this.state;
        const component = questionComponents[valueType];
        const componentProps = {
            className: classes.preview,
            formKey,
            id,
            questionText,
            readOnly,
            valueType,
        };
        const questionTextProps = readOnly
            ? { inputComponent: ReadOnlyHtml }
            : { multiline: true };

        return (
            <div className={classes.questionForm}>
                <Field
                  className={cx(classes.description, classes.field)}
                  name={`${formKey}.description`}
                  placeholder={t('projectBuilder.data.questionTitle')}
                  component={InputField}
                  validate={getValidationRule(`${formKey}.description`)}
                  readOnly={readOnly}
                />
                <Field
                  className={cx(classes.dropdown, classes.field)}
                  name={`${formKey}.valueType`}
                  component={TypeDropdownField}
                  onChange={this.handleValueTypeChange}
                  disabled={readOnly || typeof id !== 'string' || !id.startsWith('new')}
                />
                <Field
                  className={cx(classes.questionText, classes.field)}
                  name={`${formKey}.questionText`}
                  placeholder={t('projectBuilder.data.questionDescription')}
                  component={InputField}
                  validate={getValidationRule(`${formKey}.questionText`)}
                  readOnly={readOnly}
                  {...questionTextProps}
                />
                {component ? createElement(component, componentProps) : null}
            </div>
        );
    }

    renderExpanded() {
        const { classes, formKey, readOnly, t, valid } = this.props;
        const { valueType } = this.state;

        return (
            <Card className={cx(classes.card, classes.expanded)} elevation={0} raised>
                <CardContent>
                    {this.renderQuestionForm()}
                </CardContent>
                {!readOnly
                    ? (
                        <CardActions className={classes.cardActions} disableSpacing>
                            <IconButton disabled={false} onClick={this.handleDelete}>
                                <Tooltip title={t('remove')} placement="top">
                                    <DeleteIcon />
                                </Tooltip>
                            </IconButton>
                            <IconButton disabled={!valid} onClick={this.handleDuplicate}>
                                <Tooltip placement="top" title={t('duplicate')}>
                                    <LibraryBooksIcon />
                                </Tooltip>
                            </IconButton>
                            <div className={classes.separator} />
                            <Field
                              className={classes.requiredSwitch}
                              name={`${formKey}.required`}
                              label={t('projectBuilder.data.required')}
                              component={SwitchField}
                              disabled={valueType === VALUE_TYPE.HINT}
                              type="checkbox"
                            />
                        </CardActions>
                    )
                    : null
                }
            </Card>
        );
    }

    renderCollapsed() {
        const { classes, description, dragButton, index, isDragging, questionText, saving } = this.props;
        const { valueType } = this.state;
        const Icon = icons[valueType];

        const avatar = (
            <Avatar className={classes.avatar}>
                <Icon />
            </Avatar>
        );
        const className = cx(classes.card, classes.collapsed, {
            [classes.dragging]: isDragging,
        });

        return (
            <Card className={className} elevation={0} onClick={this.handleClick}>
                { saving ? <LinearProgress className={classes.progressBar} variant="indeterminate" /> : null }
                <span className={classes.sequence}>{`#${index + 1}`}</span>
                <CardHeader
                  action={dragButton || null}
                  avatar={avatar}
                  classes={{ action: classes.cardHeaderAction }}
                  subheader={(
                      <Ellipsis clamp={3} text={sanitizeHtml(questionText, { allowedTags: [], allowedAttributes: [] })} />
                  )}
                  title={description}
                />
            </Card>
        );
    }

    render() {
        const { expanded } = this.props;
        return expanded ? this.renderExpanded() : this.renderCollapsed();
    }
}

const enhance = compose(
    withStyles(styles, { name: 'QuestionCard' }),
    withTranslation(),
);
export default enhance(onClickOutside(QuestionCard, { excludeScrollbar: true }));
