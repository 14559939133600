// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import types from './types';
import typeof { open, register, unregister } from './actions';

export type DialogAction = {
    action?: { type: string },
    label: string,
}

export type DialogProps = {
    open: boolean,
    actions: Object[],
}

export type State = {
    active: ?string,
    registered: {
        [name: string]: DialogProps,
    },
}

export const init: State = {
    active: null,
    registered: {},
};

// @todo Refactor code to remove this 'universal' dialog...
// ...or at least rethink how to manage its state in combination with activeDialog.
// I think it's better for components to render their own dialog and use openDialog/closeDialog
// to control the open state. This way, we can ensure only one dialog is open at a time.
export default handleActions({
    [types.OPEN_DIALOG]: (state: State, action: ActionType<open>) => {
        const { registered } = state;
        const { name, props } = action.payload;

        if (!registered.hasOwnProperty(name)) {
            return state;
        }

        return {
            ...state,
            active: name,
            registered: {
                ...registered,
                [name]: {
                    ...props,
                    open: true,
                },
            },
        };
    },
    [types.CLOSE_DIALOG]: (state: State) => {
        const { active, registered } = state;

        if (active == null) {
            return state;
        }

        if (!registered.hasOwnProperty(active)) {
            return { ...state, active: null };
        }

        return {
            ...state,
            active: null,
            registered: {
                ...registered,
                [active]: {
                    ...registered[active],
                    open: false,
                },
            },
        };
    },
    [types.REGISTER_DIALOG]: (state: State, action: ActionType<register>) => {
        const { registered } = state;
        const name = action.payload;

        if (registered.hasOwnProperty(name)) {
            return state;
        }

        return {
            ...state,
            registered: {
                ...registered,
                [name]: {
                    open: false,
                    actions: [],
                },
            },
        };
    },
    [types.UNREGISTER_DIALOG]: (state: State, action: ActionType<unregister>) => {
        const name = action.payload;
        const { active, registered } = state;

        if (!registered.hasOwnProperty(name)) {
            return state;
        }

        const nextRegistered = { ...registered };
        delete nextRegistered[name];
        return {
            ...state,
            active: active === name ? null : active,
            registered: nextRegistered,
        };
    },
}, init);
