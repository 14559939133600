// @flow
type GeoFilter = {
    key: string,
    value: {
        primary: string,
        secondary?: string,
    }
}

export default function encodeGeoFilter(filter: GeoFilter): ?Object {
    const { key, value } = filter;
    const { primary, secondary } = value;
    const address = secondary ? `${primary}+${secondary}` : primary;

    if (key === 'zipcode') return { zip: address };
    if (key === 'city') return { city: address };
    if (key === 'state') return { state: address };
}
