// @flow
import React from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';

type Props = {
    className?: string,
    classes: Object,
    indicatorColor?: 'inherit' | 'primary' | 'secondary',
}

const styles = () => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '60vh',
    },
});

export const LoadingPage = (props: Props) => {
    const { className, classes, indicatorColor, ...other } = props;
    return (
        <div {...other} className={cx(classes.root, className)}>
            <CircularProgress color={indicatorColor} />
        </div>
    );
};

export default withStyles(styles, { name: 'LoadingPage' })(LoadingPage);
