// @flow
export default (theme: Object) => ({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 14,
    },
    dialogAction: {
        margin: 0,
    },
    message: {
        marginBottom: 10,
        fontSize: 16,
        color: 'black',
    },
    star: {
        width: 30,
        height: 'auto',
    },
    hint: {
        color: theme.palette.grey[500],
        height: 28,
    },
});
