// @flow
import Component from '../../../../shared/view/Component';
import ProjectConstant from '../../../../shared/constant/ProjectConstant';
import RegExUtil from '../../../../shared/util/RegExUtil';
import DateUtil from '../../../../shared/util/DateUtil';
import { USER_ROLES } from '../../../../shared/constant/UserRoles';
import metadataTpl from '../../../templates/ticket/Metadata.hbs';

type MemoType = {
    images: Array<Object>,
    files: Array<Object>,
};

// Roles that can edit answer on behalf of worker
const delegateRoles = [USER_ROLES.SUPER_ADMIN, USER_ROLES.SELF_SERVICE, USER_ROLES.PLATFORM_ADMIN];

export default Component.extend({

    className: '',

    events: {
        'click [data-action="magnify"]': 'magnify',
    },

    /**
     * Clears validation markup when input receives focus
     * @param event
     */
    clearInvalid(event: JQueryEventObject) {
        $(event.target).removeClass('invalid');
        this.$el.find('[data-target="validationHint"]').html('');
    },

    /**
     * @method initialize
     */
    initialize(options: Object) {
        this.props = options.props;
        this.instruction = options.instruction;
        this.ticketID = parseInt(options.ticketID, 10);
        this.readMode = !!options.readMode;
        this.showLocations = !!options.showLocations;

        const { ticket } = this.props;
        this.ticket = ticket;

        this.dataTypeID = parseInt(this.instruction.get('data_type_id'), 10);
        this.targetID = parseInt(this.instruction.get('observation_target_id'), 10);
        this.templateID = parseInt(this.instruction.get('template_id'), 10);

        const text = GW.localisation.tickets.tickets.details;
        this.text = _.extend({ metadata: text.metadata }, text.widgets);

        this.dts = ticket.data_type_map;
        this.dis = ticket.data_items;
        const diIndex = _.findLastIndex(ticket.data_items, {
            data_type_id: this.dataTypeID,
            observation_target_id: this.targetID,
        });
        this.di = diIndex === -1 ? {} : (ticket.data_items[diIndex] || {});
        this.targetMap = ticket.observation_target_map;
        this.isRequired = ticket.data_type_map[this.instruction.get('data_type_id')].is_required;

        const targetName = ticket.observation_target_map[this.targetID] || '';
        const targetMetadataMap = this.ticket.observation_target_metadata_map;
        const targetMetadata = {};

        _.chain(targetMetadataMap[this.targetID])
            .keys()
            .each((key: string) => {
                if (targetMetadataMap[this.targetID][key] !== null) {
                    targetMetadata[key] = targetMetadataMap[this.targetID][key];
                }
            });

        this.target = {
            id: this.targetID,
            name: targetName,
            metadata: targetMetadata,
        };

        this.events = $.extend({
            'click [data-action="collapse"]': 'toggle',
            'click [data-action="save"]': 'save',
            click: 'onClick',
            keydown: 'onKeydown',
            'keydown [data-type="positiveInts"]': 'positiveInts',
            'click [data-action="viewLatLon"]': 'viewLatLon',
        }, this.events);

        this.findDeviceLocation();

        this.render();

        this.$el.toggleClass('read-only', this.readMode);

        if (!this.isRequired) {
            this.$el.find('.required.widget-panel:first').removeClass('required');
        }

        this.setReadMode();
        this.validateInput();
    },

    onClick(e: JQueryEventObject, ...rest: Array<any>) {
        /**
         * @todo fix this in a better way related to http://gigwalk.myjetbrains.com/youtrack/issue/PGCMK-888
         */
        const target = $(e.target);
        if (target.data('action') === 'download') {
            const link = target.attr('href');
            if (link) {
                window.open(link);
            }
        } else {
            this.toggleReadMode.apply(this, [e, ...rest]);
            this.validateInput.apply(this, [e, ...rest]);
        }
    },

    onKeydown(...args: Array<any>) {
        this.toggleReadMode.apply(this, args);
        this.validateInput.apply(this, args);
    },

    render() {
        const { di, showLocations, target } = this;
        this.$el.attr('data-data_type_id', this.instruction.get('data_type_id'));
        this.$el.attr('data-observation_target_id', target.id);

        if (this.instruction.get('template_id')) {
            this.$el.attr('data-template_id', this.instruction.get('template_id'));
        }

        // If the data item contains location data show a pin
        this.$el.find('[data-action="viewLatLon"]:first').toggle(Boolean(showLocations && di && di.data_item_latitude && di.data_item_longitude));

        this.delegateEvents();
    },

    toggleReadMode(e: JQueryEventObject): ?boolean {
        const input$selector = 'a[href="javascript:;"], a[href=""], input, textarea, button';
        const $target = $(e.target).filter(input$selector).add($(e.target).find(input$selector));

        if (!$target.length) {
            return;
        }

        if (!this.readMode) {
            return;
        }

        e.preventDefault();
        e.stopImmediatePropagation();
        return false;
    },

    setReadMode() {
        const { ticket, user } = this.props;
        const dataType = ticket.data_type_map[this.instruction.get('data_type_id')];
        const isAssignee = user.id === ticket.assigned_customer_id;
        const canSaveBlankAnswer = delegateRoles.includes(user.role) && dataType.is_required === false;
        let canEditTicket = false;

        if (isAssignee) {
            canEditTicket = ticket.status !== 'SUBMITTED' && moment(ticket.start_date).isBefore(moment());
        } else if (delegateRoles.includes(user.role)) {
            canEditTicket = ticket.status === 'SUBMITTED' || ticket.status === 'STARTED';
        }

        let isReadOnly = this.readMode && !canEditTicket;

        // CSMK CSMK CSMK CSMK CSMK CSMK
        if (this.templateID && this.templateID === user.organization.config.timing_template_id) {
            isReadOnly = (!isAssignee || (ticket.status === 'SUBMITTED' && moment().subtract(3, 'weeks').isAfter(moment(ticket.due_date))));
        }
        // CSMK CSMK CSMK CSMK CSMK CSMK

        this.readMode = isReadOnly;

        const hideSaveButton = isReadOnly;
        let disableSaveButton = hideSaveButton;

        disableSaveButton = disableSaveButton || (!this.isAnswered() && !canSaveBlankAnswer);
        disableSaveButton = (dataType.value_type === 'BARCODE') ? (disableSaveButton || Boolean(!this.validateBarcode())) : disableSaveButton;
        if (dataType.value_type === ProjectConstant.VALUE_TYPE.FREE_TEXT && !disableSaveButton) {
            const input = this.$el.find('[data-input]');
            const value = input.val();
            disableSaveButton = !value && canSaveBlankAnswer ? false : !this.sanitizeFreeText();
        }

        this.$el.toggleClass('read-only', hideSaveButton);

        this.$save = this.$save || this.$el.find('[data-action="save"]:last');
        this.$save.toggleClass('disabled', disableSaveButton);
    },

    isAnswered(): boolean {
        return this.getValue().data_item_answered;
    },

    positiveInts(e: JQueryEventObject): boolean {
        const chr = String.fromCharCode(e.which);
        const positiveInt = /^[0-9]\d*$|[\b]|\t|\r/;
        const arrowKeys = [37, 38, 39, 40];
        const commandKeyPressed = e.ctrlKey || e.altKey || e.shiftKey || e.metaKey;

        if (arrowKeys.indexOf(e.which) > -1 || commandKeyPressed || positiveInt.test(chr)) {
            return true;
        }

        e.preventDefault();
        e.stopImmediatePropagation();

        return false;
    },

    findDeviceLocation() {
        const { ticket } = this.props;

        // defaults to provided location data and will not overwrite a lat lon already in a data item
        this.deviceLocation = {
            latitude: ticket.location.latitude,
            longitude: ticket.location.longitude,
        };

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position: Object) => {
                this.deviceLocation = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                };
            });
        }
    },

    toggle(event: any): boolean {
        this.$body = this.$body || this.$el.find('[data-target="body"]:first');
        this.$body.toggleClass('hidden');
        this.$el.toggleClass('collapsed');

        event.stopImmediatePropagation();
        return false;
    },

    save(next: any): ?boolean {
        const { ticket } = this.props;
        const pass = this.validateSave();
        const value = this.getValue();
        if (!pass) {
            this.$el.find('[data-input]').addClass('invalid');
            return false;
        }

        const dataTypeID = this.instruction.get('data_type_id');
        const dataType = ticket.data_type_map[dataTypeID];

        switch (dataType.value_type) {
            case ProjectConstant.VALUE_TYPE.NUMBER:
            case ProjectConstant.VALUE_TYPE.TIME:
            case ProjectConstant.VALUE_TYPE.CURRENCY:

                // data_item_value should always be an array, but I don't trust getValue to adhere to that rule.
                value.data_item_value = Array.isArray(value.data_item_value)
                    ? value.data_item_value.map((val: any) => parseFloat(val))
                    : parseFloat(value.data_item_value);

                break;

            default:
                break;
        }

        this.onSave(value, this.$save, typeof next === 'function' ? next : undefined);
    },

    magnify(e: JQueryEventObject) {
        const url = $(e.target).data('url');

        // lightbox todo
        window.open(url, '_blank');
    },

    /**
     * Displays a map of the data item's latitude and longitude
     * @method viewLatLon
     */
    viewLatLon() {
        const { di } = this;

        if (!di || !di.data_item_latitude || !di.data_item_longitude) {
            return;
        }

        window.open(`http://www.google.com/maps?q=${di.data_item_latitude},${di.data_item_longitude}`, '_blank');
    },

    /**
     * Displays available metadata for the observation target.
     * @method viewTargetMetadata
     */
    viewTargetMetadata() {
        if (this.target.metadata && Object.keys(this.target.metadata).length > 0) {
            this.$els = this.$els || {};

            // If cached element already exists in the DOM, don't do anything.
            if (this.$els.metadata) {
                return;
            }

            const text = this.text.metadata;
            const data = {
                title: text,
                metadata: this.target.metadata,
            };
            const $metadata = $(metadataTpl(data));

            this.$els.metadata = $metadata;

            $metadata.find('[data-action="close"]').on('click', () => {
                $metadata.parent().hide();
                $metadata.remove();

                delete this.$els.metadata;
            });

            this.$el.find('[data-target="target-metadata"]').html($metadata).show();
        }
    },

    groupAttachments(attachments: Array<Object>): MemoType {
        return _.reduce(attachments, (memo: MemoType, attachment: Object) => {
            attachment.file_type.indexOf('image') === 0
                ? memo.images.push(attachment)
                : memo.files.push(attachment);
            return memo;
        }, { images: [], files: [] }, this);
    },

    /**
     * Get data item. Override this method in sub-classes
     */
    getValue($elParam: JQuery): Object { // eslint-disable-line no-use-before-define
        const $el = $elParam || this.$el;

        const dataItemValue = ((): Array<any> => {
            const data = [];
            const $date = $el.find('[data-action="date"]:first').val();
            const date = $date && RegExUtil.date_YYYY_MM_DD($date.trim())
                ? `${moment.utc($date.trim()).format('YYYY-MM-DD')}T00:00:00`
                : null;

            const $dateTime = $el.find('[data-action="date_time"]:first').val();
            const dateTime = $dateTime && RegExUtil.date_YYYY_MM_DD_HH_MM_AM($dateTime.trim())
                ? moment.utc($dateTime.trim(), 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss')
                : null;

            if (date) {
                data.push(date);
            }

            if (dateTime) {
                data.push(dateTime);
            }

            const $inputs = $el.find('[data-input]');
            const unit = $el.find('[data-selected="true"][data-value]:first').data('value') || '';

            if (!$date && !dateTime) {
                _.each($inputs, (input: HTMLElement) => {
                    const $input = $(input);
                    const $val = $input.val();
                    const $type = $input.attr('type');
                    const duration = ($val.toString().length && unit && typeof unit === 'string')
                        ? DateUtil.timeUnitToSeconds($val, unit)
                        : null;
                    let val = null;
                    if ($type in { checkbox: 'x', radio: 'o' }) {
                        if ($input.is(':checked')) { // the user chose 1 or more answer(s)
                            val = $input.val();
                        }
                    } else if (duration !== null) {
                        val = duration; // the user entered a duration
                    } else if ($val && $val.toString().length) { // the user entered text
                        val = $val;
                    } else {
                        val = null;
                    }

                    const empty = val === null || (typeof val === 'string' && !val.length);

                    if (!empty) {
                        data.push(val);
                    }
                }, this);
            }

            return data;
        })();

        return {
            data_item_value: dataItemValue,
            template_id: this.instruction ? this.instruction.get('template_id') : null,
            data_item_answered: !!dataItemValue.length,
            data_item_latitude: `${((this.deviceLocation || {}).latitude || '')}`,
            data_item_longitude: `${((this.deviceLocation || {}).longitude || '')}`,
            data_item_timestamp: `${moment().toDate().getTime()}`,
            data_type_id: $el.data('id') || this.instruction.get('data_type_id'),
            observation_target_id: $el.data('targetid') || this.instruction.get('observation_target_id'),
        };
    },

    /**
     * Validates input field(s) before answer submission. Override this method in sub-classes
     * @returns {boolean}
     */
    validateSave: (): true => true,

    /**
     * Validate user input. Override this method in sub-classes
     * @returns {boolean}
     */
    validateInput() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.setReadMode();
            if (this.readMode) {
                return;
            }

            this.updateIsAnswered();
        }, 67);
    },

    /**
     * Override this method in sub-classes
     * @returns {boolean}
     */
    updateIsAnswered() {
        const dataTypeID = this.instruction.get('data_type_id');
        const targetID = this.instruction.get('observation_target_id');
        const templateID = this.instruction.get('template_id');
        const isAnswered = this.getValue().data_item_answered;

        this.props.setIsAnswered(isAnswered, dataTypeID, targetID, templateID);

        return isAnswered;
    },
});
