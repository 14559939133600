// @flow
import React, { Component } from 'react';
import DotDotDot from 'react-dotdotdot';

type State = {
    charCode: 0 | 2,
};

type Props = {
    text: string,
};

export default class Ellipsis extends Component<Props, State> {
    state = {
        charCode: 0,
    };

    componentDidMount() {
        window.addEventListener('resize', this.update, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.update, false);
    }

    update = () => {
        const { charCode } = this.state;
        this.setState({ charCode: charCode === 0 ? 2 : 0 });
    };

    render() {
        const { text, ...other } = this.props;
        const { charCode } = this.state;

        return (
            <DotDotDot {...other}>
                {`${String.fromCharCode(charCode)}${text}`}
            </DotDotDot>
        );
    }
}
