// @flow

/**
 * LangUtil is a static utility that helps with formatting words
 */

/**
 * check all the words in a sentence and breaks words that are longer than nChar
 * This fixes some rendering weirdness when people enter dummy data forgetting to input space characters :)
 * @method validateWordsLength
 * @param sentence
 */
export function validateWordsLength(sentence: string, nChar: number): string {
    if (!nChar) {
        return sentence;
    }

    const words = sentence.split(' ');
    const w = [];
    words.forEach((word: string) => {
        for (let i = 0; i < Math.ceil(word.length / nChar); i += 1) {
            const tmpWord = word.substring(i * nChar, nChar);
            w.push(tmpWord);
        }
    });

    return w.join(' ');
}

/**
 *
 * @method ellipsify
 * @param input
 * @param limit
 * @returns {string}
 */
export function ellipsify(input: string, limit: number): string {
    const str = validateWordsLength(input, 45);
    if (str.length > limit) {
        return `${str.substring(0, (limit - 3))}...`;
    }

    return str;
}

/**
 * String must contains {n} and {plural}
 * n will be replaced by the count and {plural} will be a 's' if the count is !== 1
 * @method plural
 * @param input
 * @param count
 * @returns {string}
 */
export function plural(input: string, count: number): string {
    let str = input.split('{n}').join(count.toString());
    str = (count !== 1) ? str.split('{plural}').join('s') : str.split('{plural}').join(''); // -1 nouns, 0 nouns, 1 noun, 2 nouns
    str = (count !== 1) ? str.split('{were|was}').join('were') : str.split('{were|was}').join('was'); // -1 were, 0 were, 1 was, 2 were
    str = (count !== 1) ? str.split('{have|has}').join('have') : str.split('{have|has}').join('has');
    str = (count !== 1) ? str.split('{are|is}').join('are') : str.split('{are|is}').join('is');
    str = (count !== 1) ? str.split('{workers|worker}').join('workers') : str.split('{workers|worker}').join('worker');

    return str;
}

/**
 * @method replaceTitle
 * @param input
 * @param title
 * @returns {string}
 */
export function replaceTitle(input: string, title: string = ''): string {
    return input.replace('{title}', title);
}

/**
 * @method list
 * @param arr
 * @param and (Optional)
 * @returns {string}
 */
export function list(arr: Array<string>, and?: string = 'and'): string {
    let str = '';

    for (let i = 0, len = arr.length; i < len; i += 1) {
        if (i) {
            if (i === arr.length - 1) {
                str += ` ${and}`;
            } else {
                str += ',';
            }

            str += ' ';
        }

        str += arr[i];
    }

    return str;
}

/**
 * @method phoneNumber
 * @param input
 * @returns {string}
 *
 * Supported formats:
 *   3214151234567 -> +321 (415) 123-4567
 *   14151234567 -> +1 (415) 123-4567
 *   4151234567 -> (415) 123-4567
 *
 *   Numbers less than 10 digits long are passed thru
 *
 */
export function phoneNumber(input: number | string): string {
    let number = `${parseInt(input, 10)}`;
    const len = number.length;
    let country = '';

    if (len < 10) {
        return number;
    } else if (len > 10) {
        country = `+${number.substring(0, len - 10)} `;
    }

    const local = number.substring(number.length - 10);
    number = `(${local.substring(0, 2)}) ${local.substring(3, 5)}-${local.substring(6)}`;

    return country + number;
}

export default {
    validateWordsLength,
    ellipsify,
    plural,
    replaceTitle,
    list,
    phoneNumber,
};
