// @flow
export default (theme: Object) => ({
    submitButton: {
        position: 'relative',
        '&:only-child': {
            marginLeft: 'auto',
        },
    },
    dateTimePicker: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(1),
    },
    disablePaddingBottom: {
        paddingBottom: 0,
    },
    warning: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(3),
        fontSize: 12,

        '& $icon': {
            color: '#fdc449',
        },
    },
    error: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(3),
        fontSize: 12,

        '& $icon': {
            color: '#cd1e3d',
        },
    },
    icon: {
        marginRight: theme.spacing(1),
    },
});
