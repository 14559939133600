// @flow
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import { client as gigwalk } from '../../../api/createGigwalkClient';
import types from './types';

/* Analytics */
// import { trackEvent } from '../../../browser/shared/util/GoogleTagManager';
// import { SUBMIT_NEW_PASSWORD } from '../../constant/analytics/AuthPages';

type FormValues = {
    email: string,
    token: string,
    password: string,
};

/* eslint-disable quote-props */
const errorsByStatusCode = {
    '400': 'invalidLink',
    '410': 'invalidLink',
    '500': 'internalServerError',
    default: 'unknownError',
};
/* eslint-enable quote-props */

export const submitError = createAction(types.SUBMIT_ERROR);
export const submitSuccess = createAction(types.SUBMIT_SUCCESS);
export const submit = createAction(
    types.SUBMIT,
    (values: FormValues): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            // trackEvent(SUBMIT_NEW_PASSWORD);
            const { email, token, password } = values;
            const params = {
                check_expired: false,
                email,
                password,
                token,
            };

            return gigwalk.authorization.resetPassword(params)
                .then(() => {
                    dispatch(submitSuccess());
                })
                .catch((err: $AxiosError<Object>) => {
                    let error = 'networkError';
                    if ('response' in err) {
                        const { data, status } = err.response;
                        if (typeof data.message === 'string') error = data.message;
                        else if (status in errorsByStatusCode) error = errorsByStatusCode[status];
                        else error = errorsByStatusCode.default;
                    }
                    dispatch(submitError(err));
                    return Promise.reject(error);
                });
        }
    )
);

export const checkExpired = createAction(
    types.CHECK_EXPIRED,
    (values: FormValues): Function => (
        (): Promise<any> => {
            const { email, token } = values;
            const params = {
                check_expired: true,
                email,
                password: '',
                token,
            };

            return gigwalk.authorization.resetPassword(params)
                .then(() => {})
                .catch((err: $AxiosError<Object>) => {
                    let error = 'networkError';
                    if ('response' in err) {
                        const { status } = err.response;
                        if (status in errorsByStatusCode) error = errorsByStatusCode[status];
                        else error = errorsByStatusCode.default;
                    }

                    return Promise.reject(error);
                });
        }
    )
);

export default {
    submit,
    submitError,
    submitSuccess,
    checkExpired,
};
