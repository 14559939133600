// @flow
export const CHECK_EXPIRED = 'g/resetPassword/CHECK_EXPIRED';
export const SUBMIT = 'g/resetPassword/SUBMIT';
export const SUBMIT_SUCCESS = `${SUBMIT}_SUCCESS`;
export const SUBMIT_ERROR = `${SUBMIT}_ERROR`;

export default {
    CHECK_EXPIRED,
    SUBMIT,
    SUBMIT_SUCCESS,
    SUBMIT_ERROR,
};
