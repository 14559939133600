// @flow
import React, { Component } from 'react';
import numeral from 'numeral';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { CircularProgress, Fade } from '@material-ui/core';
import {
    Check as CheckIcon,
    Clear as ClearIcon,
    MonetizationOn as MonetizationOnIcon,
} from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { FormProps } from 'redux-form';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { TFunction } from 'react-i18next';
import fieldify from '../../../../../../components/fieldify';
import MaskedInput from '../../../../../../components/MaskedInputV2';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../../../redux/initialState';

type State = {
    statusTimer: ?number,
}

type OwnProps = ContextRouter & FormProps & {
    classes: Object,
    className?: string,
    t: TFunction,
};
type StateProps = {};
type Props = OwnProps & StateProps;

const MaskedInputField = fieldify()(MaskedInput);
const ReadOnlyField = fieldify()(
    (props: Object) => {
        const { className, value } = props;
        return <div className={className}>{value}</div>;
    }
);

const formatCurrency = (value: any): string => numeral(value || 0).format('$0,0.00');

export class Payout extends Component<Props, State> {
    state = {
        statusTimer: null,
    };

    componentWillReceiveProps(nextProps: Props) {
        const { submitFailed, submitSucceeded } = this.props;
        const displayStatus = (!submitFailed && nextProps.submitFailed)
            || (!submitSucceeded && nextProps.submitSucceeded);

        if (displayStatus) {
            const { statusTimer } = this.state;
            clearTimeout(statusTimer);

            this.setState({
                statusTimer: setTimeout(() => {
                    this.setState({ statusTimer: null });
                }, 1500),
            });
        }
    }

    handleBlur = () => {
        const { dirty, handleSubmit, valid } = this.props;
        if (dirty && valid) {
            handleSubmit();
        }
    };

    renderPrice() {
        const { classes, disabled, readOnly } = this.props;
        return readOnly.includes('price')
            ? (
                <Field
                  name="price"
                  format={formatCurrency}
                  component={ReadOnlyField}
                  className={classes.readOnly}
                />
            )
            : (
                <Field
                  name="price"
                  component={MaskedInputField}
                  classes={{ input: classes.input }}
                  disabled={disabled.includes('price')}
                  variant="outlined"
                  options={{
                      numeral: true,
                      numeralThousandsGroupStyle: 'thousand',
                      numeralPositiveOnly: true,
                      prefix: '$',
                      noImmediatePrefix: true,
                      rawValueTrimPrefix: true,
                  }}
                  onBlur={this.handleBlur}
                />
            );
    }

    render() {
        const { classes, handleSubmit, submitSucceeded, submitting, t } = this.props;
        const { statusTimer } = this.state;
        return (
            <form className={classes.container} onSubmit={handleSubmit}>
                <MonetizationOnIcon className={classes.icon} />
                <span className={classes.label}>{t('ticketDetail.summary.payout')}</span>
                {this.renderPrice()}
                {submitting
                    ? <CircularProgress size={18} thickness={4} />
                    : (
                        <Fade in={!!statusTimer} timeout={{ exit: 250 }}>
                            {submitSucceeded
                                ? <CheckIcon className={classes.successIcon} />
                                : <ClearIcon className={classes.errorIcon} />
                            }
                        </Fade>
                    )
                }
            </form>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    disabled: selectors.getDisabled(state, props),
    initialValues: selectors.getInitialValues(state, props),
    readOnly: selectors.getReadOnly(state, props),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps);
const enhance = compose(
    withStyles(styles, { name: 'Payout' }),
    withTranslation(),
    connector,
    reduxForm({
        form: 'payout',
        enableReinitialize: true,
        destroyOnUnmount: false,
        onSubmit: (values: Object, dispatch: Dispatch<any>, props: Props): Promise<any> => dispatch(actions.submit(values, props)),
    })
);

export default enhance(Payout);
