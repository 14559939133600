// @flow
import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import type { ChildrenArray } from 'react';

type Props = {
    children: ChildrenArray<any>,
    className?: string,
    classes: Object,
}

const styles = () => ({
    root: {
        padding: [[4, 16]],
    },
    outlined: {
        borderColor: ['inherit', '!important'], // use !important to override :active styles from _form.scss
    },
});

export const CallToActionButton = (props: Props): React$Element<any> => {
    const { children, ...other } = props;
    return (
        <Button {...other} color="inherit" variant="outlined">
            {children}
        </Button>
    );
};

export default withStyles(styles, { name: 'CallToActionButton' })(CallToActionButton);
