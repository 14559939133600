// @flow
import { createSelector } from 'reselect';
import moment from 'moment';
import type { ContextRouter } from 'react-router';
import { STATUS } from '../../../../../../browser/shared/constant/TicketConstant';
import entitySelector from '../../../../../redux/entities/entitySelector';
import { parseSearchParams } from '../../../duck/selectors';
import type { State as RootState } from '../../../../../redux/initialState';

export { parseSearchParams } from '../../../duck/selectors';

const _statusFilterOptions = [
    { label: 'Assigned', value: STATUS.ASSIGNED },
    { label: 'Unassigned', value: STATUS.UNASSIGNED },
    { label: 'Scheduled', value: STATUS.SCHEDULED },
    { label: 'Started', value: STATUS.STARTED },
    { label: 'Submitted', value: STATUS.SUBMITTED },
    { label: 'Expired', value: STATUS.EXPIRED },
    { label: 'Canceled', value: STATUS.CANCELED },
];

const _approvalStatusFilterOptions = [
    { label: 'Approved', value: 'APPROVED' },
    { label: 'Rejected', value: 'REJECTED' },
    { label: 'Unreviewed', value: 'UNREVIEWED' },
    { label: 'Reopened', value: 'REOPENED' },
];

export const getStatusFilterOptions = (): Object[] => _statusFilterOptions;
export const getApprovalStatusFilterOptions = (): Object[] => _approvalStatusFilterOptions;

const getSelectedStatuses = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): string[] => {
    const search = parseSearchParams(state, props);
    return search.status || [];
};
export const getStatusFilterValue = createSelector(
    getSelectedStatuses,
    getStatusFilterOptions,
    (selected: string[], statusFilterOptions: Object[]): Object[] => (
        selected.reduce((acc, value) => {
            const selectedOption = statusFilterOptions.find((option) => option.value === value);
            if (selectedOption) {
                acc.push(selectedOption);
            }
            return acc;
        }, [])
    )
);

const getSelectedApprovalStatuses = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): string[] => {
    const search = parseSearchParams(state, props);
    return search.approval || [];
};
export const getApprovalStatusFilterValue = createSelector(
    getSelectedApprovalStatuses,
    getApprovalStatusFilterOptions,
    (selected: string[], approvalStatusFilterOptions: Object[]): Object[] => (
        selected.reduce((acc, value) => {
            const selectedOption = approvalStatusFilterOptions.find((option) => option.value === value);
            if (selectedOption) {
                acc.push(selectedOption);
            }
            return acc;
        }, [])
    )
);

export const getGroupFilterOptions = createSelector(
    (state: RootState) => (state.session.user ? state.session.user.group_memberships || [] : []),
    (groupMemberships: Object[]): Object[] => (
        groupMemberships.map((membership: Object): Object => ({
            label: membership.group,
            value: `${membership.group_id}`,
        }))
    )
);

const getSelectedGroupIds = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): string[] => {
    const search = parseSearchParams(state, props);
    return search.group || [];
};
export const getGroupFilterValue = createSelector(
    getSelectedGroupIds,
    getGroupFilterOptions,
    (selected: string[], groupFilterOptions: Object[]): Object[] => (
        selected.reduce((acc: Object[], value: string): Object[] => {
            const selectedOption = groupFilterOptions.find((option: Object): boolean => option.value === value);
            if (selectedOption) {
                acc.push(selectedOption);
            }
            return acc;
        }, [])
    )
);

const subscriptionSelector = entitySelector('subscriptions');
const getSelectedSubscriptions = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): Object[] => {
    const search = parseSearchParams(state, props);
    const selected = search.subscription || [];
    return subscriptionSelector(state, selected);
};
export const getSubscriptionFilterValue = createSelector(
    getSelectedSubscriptions,
    (selected: Object[]) => (
        selected.map((subscription: Object): Object => ({
            label: subscription.title,
            value: subscription,
        }))
    )
);

const getSelectedDueDate = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): ?Object => {
    const search = parseSearchParams(state, props);
    return search.dueDate;
};
export const getDueDateFilterValue = createSelector(
    getSelectedDueDate,
    (dueDate: ?Object): Object => ({
        startDate: dueDate && dueDate.from ? moment(dueDate.from) : null,
        endDate: dueDate && dueDate.to ? moment(dueDate.to) : null,
    })
);

const organizationSelector = entitySelector('organizations');
const getSelectedOrganizations = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): Object[] => {
    const search = parseSearchParams(state, props);
    const selected = search.organization || [];
    return organizationSelector(state, selected);
};
export const getOrganizationFilterValue = createSelector(
    getSelectedOrganizations,
    (selected: Object[]) => (
        selected.map((organization: Object): Object => ({
            label: organization.organization_name,
            value: organization,
        }))
    )
);

const getPlaces = (state: RootState): mixed[] => Object.values(state.googleMaps.places || {});
const getSelectedLocations = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): Object[] => {
    const search = parseSearchParams(state, props);
    return search.location || [];
};
export const getLocationFilterValue = createSelector(
    getSelectedLocations,
    getPlaces,
    (selected: Object[], places: mixed[]): Object[] => (
        selected.reduce((acc: Object[], location: Object) => {
            const [specificity, value] = Object.entries(location)[0];

            if (specificity === 'name') {
                const option = { label: value, value };
                return [...acc, option];
            }

            const place = places.find((p: mixed) => (
                p && typeof p === 'object' ? p.formatted_address === value : false
            ));

            if (place && typeof place === 'object') {
                const option = {
                    label: place.formatted_address,
                    value: place,
                };
                return [...acc, option];
            }

            return acc;
        }, [])
    )
);

export default {
    getApprovalStatusFilterValue,
    getApprovalStatusFilterOptions,
    getDueDateFilterValue,
    getGroupFilterOptions,
    getGroupFilterValue,
    getLocationFilterValue,
    getOrganizationFilterValue,
    getStatusFilterOptions,
    getStatusFilterValue,
    getSubscriptionFilterValue,
    parseSearchParams,
};
