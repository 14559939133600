// @flow
import { DEBUG, ERROR, INFO, FATAL, WARN, nameFromLevel } from 'browser-bunyan';
import blacklist from 'blacklist';
import type { BunyanRecord, BunyanLogLevels } from 'bunyan';

const { console } = global;
/**
 * Pad zeros for times.
 */
export function padZeros(num: number, len: number): string {
    return Array((len + 1) - (`${num}`).length).join('0') + num;
}
/**
 * ConsoleFormattedStream class for clientside logging.
 */
export default class ConsoleFormattedStream {
    constructor(opts?: { useTrace?: boolean } = {}) {
        const { useTrace } = {
            useTrace: true,
            ...opts,
        };

        this.logMethod = useTrace ? 'trace' : 'log';
    }

    logMethod: 'trace' | 'log';

    getLevelCSS(level: BunyanLogLevels): string {
        let levelCss: string;
        if (level < DEBUG) {
            levelCss = 'color: DeepPink';
        } else if (level < INFO) {
            levelCss = 'color: GoldenRod';
        } else if (level < WARN) {
            levelCss = 'color: DarkTurquoise';
        } else if (level < ERROR) {
            levelCss = 'color: Purple';
        } else if (level < FATAL) {
            levelCss = 'color: Crimson';
        } else {
            levelCss = 'color: Black';
        }
        return levelCss;
    }

    getLoggerName(rec: BunyanRecord): string {
        return rec.childName ? `${rec.name}/${rec.childName}` : rec.name;
    }

    getLevelName(rec: BunyanRecord): string {
        // get level name and pad start with spaces
        const levelName: string = nameFromLevel[rec.level].toUpperCase();
        return `${Array(6 - levelName.length).join(' ')}${levelName}`;
    }

    getMeta(rec: BunyanRecord): Array<[string, any]> {
        const corefields: Array<string> = [
            'v',
            'level',
            'name',
            'hostname',
            'pid',
            'time',
            'msg',
            'src',
            'levelName',
            'chileName',
            'err',
        ];
        return Object.entries(blacklist(rec, ...corefields));
    }
    write(rec: BunyanRecord) {
        try {
            const log = console[this.logMethod].bind(console); // eslint-disable-line no-console
            const levelCss: string = this.getLevelCSS(rec.level);
            const defaultCss: string = 'color: DimGray';
            const msgCss: string = 'color: SteelBlue';
            const loggerName: string = this.getLoggerName(rec);
            const levelName: string = this.getLevelName(rec);
            const meta: Array<[string, any]> = this.getMeta(rec);
            if (meta.length) {
                let consoleString: string = '[%s:%s:%s:%s] %c%s%c: %s: %c%s';
                const consoleArgs: Array<any> = [
                    padZeros(rec.time.getHours(), 2), padZeros(rec.time.getMinutes(), 2),
                    padZeros(rec.time.getSeconds(), 2), padZeros(rec.time.getMilliseconds(), 4),
                    levelCss, levelName,
                    defaultCss, loggerName,
                    msgCss, rec.msg,
                ];
                meta.forEach(([key, value]: [string, any]) => {
                    const isString = typeof (value) === 'string';
                    consoleArgs.push(defaultCss, key, value);
                    consoleString += isString ? ' %c%s: %s' : ' %c%s: %O';
                });

                log(consoleString, ...consoleArgs);
            } else {
                log(
                    '[%s:%s:%s:%s] %c%s%c: %s: %c%s',
                    padZeros(rec.time.getHours(), 2), padZeros(rec.time.getMinutes(), 2),
                    padZeros(rec.time.getSeconds(), 2), padZeros(rec.time.getMilliseconds(), 4),
                    levelCss,
                    levelName,
                    defaultCss,
                    loggerName,
                    msgCss,
                    rec.msg
                );
            }

            if (rec.err && rec.err.stack) {
                log('%c%s,', levelCss, rec.err.stack);
            }
        } catch (e) {
            console.error(e); // eslint-disable-line no-console
        }
    }
}
