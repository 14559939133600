// @flow
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import { format } from '../../../../../../../../browser/shared/util/gigwalkApiErrorUtil';
import { loadTicket } from '../../../../../duck/actions';
import {
    fetch as fetchTicket,
    update as updateTickets,
} from '../../../../../../../redux/entities/tickets';
import * as snackbar from '../../../../../../../ducks/snackbar';
import types from './types';

export { loadTicket } from '../../../duck/actions';

export const submitError = createAction(types.SUBMIT_ERROR);
export const submitSuccess = createAction(types.SUBMIT_SUCCESS);
export const submit = createAction(
    types.SUBMIT,
    (values: Object, props: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            // @todo Think about using normalize/format to store just the option value in state
            const { assignee } = values;
            const { match } = props;
            const ticketId = parseInt(match.params.ticketId, 10);

            const params = assignee === null
                ? { action: 'unassign', ticket_ids: [ticketId] }
                : { action: 'assign', customer_id: assignee.value.id, ticket_ids: [ticketId] };

            return dispatch(updateTickets(params))
                .then(() => dispatch(fetchTicket({ ticket_id: ticketId })))
                .then(() => {
                    dispatch(submitSuccess());
                })
                .catch((err: $AxiosError<Object>) => {
                    dispatch(submitError(err));
                    const resp = err ? err.response : null;
                    if (resp && resp.data && resp.data.gw_api_response) {
                        const message = format(resp.data.gw_api_response);
                        dispatch(snackbar.actions.enqueue(message, { variant: 'error' }));
                    }
                    return Promise.reject();
                });
        }
    )
);

export default {
    loadTicket,
    submit,
    submitError,
    submitSuccess,
};
