// @flow
import { createSelector } from 'reselect';
import * as session from '../../../../../ducks/session';
import { getAPIParams, parseSearchParams } from '../../../duck/selectors';

export { getAPIParams, parseSearchParams } from '../../../duck/selectors';

const customerActions = Object.freeze([
    'activateUsers',
    'deactivateUsers',
    'suspendUsers',
    'deleteUsers',
    // 'addToGroup',
    // 'assignCertifications',
]);

export const getCustomerActions = createSelector(
    session.selectors.isSupervisor,
    session.selectors.isSuperAdmin,
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    session.selectors.isPlatformAdmin,
    (isSupervisor: boolean, isSuperAdmin: boolean, isPlatformAdmin: boolean): string[] => {
        if (isPlatformAdmin) {
            return customerActions;
        } else if (isSuperAdmin) {
            return customerActions.filter((action: string) => !['delete', 'suspend'].includes(action));
        } else if (isSupervisor) {
            return customerActions.filter((action: string) => ['delete', 'suspend', 'deactivate', 'activate'].includes(action));
        }

        return [];
    }
);

export default {
    getAPIParams,
    getCustomerActions,
    parseSearchParams,
};
