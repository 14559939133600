// @flow
import React from 'react';
import cx from 'classnames';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
    FilledInput,
    FormControl,
    FormHelperText,
    FormLabel,
    OutlinedInput,
    Input,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import styles from './styles';

type Option = {
    label: string,
    value: any,
};

type Props = {
    classes?: Object,
    className?: string,
    field: Object,
    form: Object,
    label?: string,
    labelPlacement: 'default' | 'top',
    options: Option[],
    placeholder?: string,
    variant: 'standard' | 'outlined' | 'filled',
};

const useStyles = makeStyles(styles, { name: 'FormikSelect' });

const variantComponent = {
    standard: Input,
    filled: FilledInput,
    outlined: OutlinedInput,
};

export default function FormikSelect(props: Props) {
    const {
        classes: classesProp,
        className,
        field,
        form,
        label,
        labelPlacement,
        options,
        placeholder,
        variant,
        ...other
    } = props;

    const classes = useStyles(props);
    const { i18n, t } = useTranslation();

    const touched = getIn(form.touched, field.name);
    const error = getIn(form.errors, field.name);

    let errorMessage = null;
    if (touched && error) {
        if (typeof error === 'string') {
            errorMessage = i18n.exists(error) ? t(error) : error;
        } else if ('message' in error) {
            const { message, ...translateOptions } = error;
            errorMessage = i18n.exists(message) ? t(message, { ...translateOptions }) : message;
        }
    }

    const {
        root: rootClassName,
        label: labelClassName,
        select: selectClassName,
        errorMessage: errorClassName,
        ...otherClasses
    } = classes;

    const LabelComponent = labelPlacement === 'top' ? FormLabel : InputLabel;
    const InputComponent = variantComponent[variant] || Input;

    return (
        <FormControl
          className={cx(rootClassName, className)}
          error={!!errorMessage}
          variant={variant}
        >
            {label ? <LabelComponent className={labelClassName}>{label}</LabelComponent> : null}
            <Select
              classes={{ root: selectClassName, ...otherClasses }}
              displayEmpty={!!placeholder}
              input={<InputComponent />}
              {...other}
              {...field}
            >
                {placeholder ? <MenuItem value="" disabled>{placeholder}</MenuItem> : null}
                {options.map((option: Option) => (
                    <MenuItem value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            {errorMessage
                ? <FormHelperText className={errorClassName}>{errorMessage}</FormHelperText>
                : null
            }
        </FormControl>
    );
}

FormikSelect.defaultProps = {
    labelPlacement: 'default',
    options: [],
    variant: 'standard',
};
