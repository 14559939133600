// @flow
import BaseCollection from '../vo/BaseCollection';
import TargetList from './TargetList';
import Template from './Template';
import DataType from './DataType';

/**
 * @class (Model) Instructions
 * @constructor
 */
export default BaseCollection.extend({

    timingTemplate: null,

    initialize(models: Array<Object>, options: Object = {}) {
        if (options.timingTemplate && options.timingTemplate.id) {
            this.timingTemplate = options.timingTemplate;
        }

        this._super(models, options);
    },

    model(attrs: Object, options: Object): Object {
        if (attrs.template_id) {
            return new Template(attrs, options);
        } else if (attrs.data_types) {
            return new TargetList(attrs, options);
        }

        return new DataType(attrs, options);
    },

    add(models: Object | Array<Object>, options: Object = {}): any {
        const last = this.last();
        let override = {};

        if (this.timingTemplate && (!options.at || options.at === this.length)) {
            // Make sure timing template stays at the bottom of the instruction list
            if (last && last.get('template_id') === this.timingTemplate.id) {
                override = { at: this.length - 1 };
            }
        }

        return this._super.call(this, models, _.extend(options, override));
    },
});
