// @flow
import { createAction } from 'redux-actions';
import { matchPath } from 'react-router';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import logger from '../../../../../util/logger';
import { actions as payoutList } from '../../../duck';
import types from './types';
import selectors from './selectors';
import type { State as RootState } from '../../../../../redux/initialState';

export const refreshError = createAction(types.REFRESH_ERROR);
export const refreshSuccess = createAction(types.REFRESH_SUCCESS);
export const refresh = createAction(
    types.REFRESH,
    (): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
            const state = getState();

            // @todo: Consider using another method to compute match to pass to selectors
            // I think the next best approach would be to pass props/match to action creators. The
            // issue with the current approach is that state.router.location is not guaranteed to always
            // be in sync with react-router. For instance, server-side matching and rendering happens
            // synchronously. Redux state will be updated in response, but content will already be rendered.
            const { location } = state.router;
            const match = matchPath(location.pathname, { path: '/admin/:orgId/payouts/list' });

            if (!match) {
                return Promise.reject();
            }

            const {
                limit,
                offset,
                sort_field: sortField,
                sort_order: sortOrder,
                ...filters
            } = selectors.getAPIParams(state, { location, match });

            return dispatch(payoutList.fetchData(filters, [{ [sortField]: sortOrder }], limit, offset))
                .then(() => dispatch(refreshSuccess()))
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(refreshError());
                    return Promise.reject(err);
                });
        }
    )
);

export default {
    refresh,
    refreshError,
    refreshSuccess,
};
