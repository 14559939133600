// @flow
export default (theme: Object) => ({
    '@global body': {
        background: theme.palette.background.default, // $neutral-2
    },

    ticketList: {
        fontFamily: 'ProximaNova',
    },

    title: {
        fontSize: 36,
    },

    loading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 260px)',
        textAlign: 'center',
    },
});
