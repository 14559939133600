// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useState } from 'react';
import { Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { DialogActions, DialogContent } from '@material-ui/core';
import StarRating from '../../../../components/StarRating';
import SubmitButton from '../../../../components/SubmitButton';
import styles from './styles';

type Props = {
    dirty: boolean,
    handleSubmit: Function,
    isSubmitting: boolean,
    isValid: boolean,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    setStatus: (status: any) => void,
    status?: any,
    values: Object,
};

const useStyles = makeStyles(styles, { name: 'RatingForm' });

export default function RatingForm(props: Props) {
    const { handleSubmit, isSubmitting, setFieldValue, values } = props;

    const { t } = useTranslation();
    const classes = useStyles();
    const [hoverValue, setHoverValue] = useState(null);

    const handleHover = useCallback((value: ?number) => {
        setHoverValue(value);
    }, []);

    const handleChange = useCallback((value: number) => {
        setFieldValue('rating', value);
    }, [setFieldValue]);

    const handleSkip = useCallback(() => {
        setFieldValue('rating', null, false);
        handleSubmit();
    }, [handleSubmit, setFieldValue]);

    const displayValue = hoverValue || values.rating;

    return (
        <Form onSubmit={handleSubmit}>
            <DialogContent className={classes.dialogContent}>
                <h2 className={classes.message}>{t('reviewDialog.rateWorker')}</h2>
                <StarRating
                  classes={{ star: classes.star }}
                  onChange={handleChange}
                  onHover={handleHover}
                  value={displayValue}
                />
                <small className={classes.hint}>
                    {displayValue ? t(`reviewDialog.ratings.${displayValue}`) : null}
                </small>
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogActions, action: classes.dialogAction }}>
                <SubmitButton
                  onClick={handleSkip}
                  submitting={isSubmitting && values.rating == null}
                  variant="contained"
                >
                    {t('reviewDialog.skip')}
                </SubmitButton>
                <SubmitButton
                  color="secondary"
                  disabled={isSubmitting || values.rating == null}
                  submitting={isSubmitting && values.rating != null}
                  type="submit"
                  variant="contained"
                >
                    {t('reviewDialog.submit')}
                </SubmitButton>
            </DialogActions>
        </Form>
    );
}
