// @flow
import { normalize } from 'normalizr';
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import type { $AxiosXHR, $AxiosError } from 'axios';
import type { APIResponse, APIPromise } from 'gigwalk/lib/api/resource';
import logger from '../../util/logger';
import { client as gigwalk } from '../../api/createGigwalkClient';
import { template as templateSchema } from './schemas';
import { init } from './initialState';
import type { State } from './initialState';

// Actions
// -------
export const FETCH = 'g/templates/FETCH';
export const FETCH_SUCCESS = `${FETCH}_SUCCESS`;
export const FETCH_ERROR = `${FETCH}_ERROR`;

// Action Creators
// ---------------
export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchError = createAction(FETCH_ERROR);
export const fetch = createAction(
    FETCH,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => (
            gigwalk.client.get(`/v1/templates/${params.template_id}`)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalize(resp.data.data, [templateSchema]);
                    dispatch(fetchSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export default function(state: State = init): State {
    return state;
}
