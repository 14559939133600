// @flow
import Component from './Component';

/**
 * Base StatedView, the base class for all the stated views in the module
 *
 * @class (View) StatedView
 * @constructor
 */
const StatedView = Component.extend({
    /**
     * By default, all views are implementing the transition behavior from Navigator
     * @property navigatorBehaviors
     * @type Array
     */
    navigatorBehaviors: ['IHasStateTransition'],

    /**
     * By default, all views have a className like '__view__' <br>
     * This can be overridden in the sub class
     * @property className
     * @type String
     */
    className: '__view__',

    /**
     * Injected state machine: Navigatorjs</br>
     * https://github.com/BiggerBoat/navigator.js
     * @type navigatorjs.Navigator
     * @property njs
     */
    njs: 'inject',

    /**
     * Automatically called by the state machine (Navigatorjs) when the view isn't visible and it should be it
     * @method transitionIn
     * @param callOnComplete
     */
    transitionIn(callOnComplete: Function) {
        this.$el.show();

        // some UI, like the Search have both transitionIn and activate
        // TODO: look if there are UIs that already trigger activate in their
        // transitionIn and remove it to avoid that activate get called several time
        if (this.activate) {
            this.activate();
        }

        this.activateChildren();
        callOnComplete();
    },

    /**
     * Automatically called by the state machine (Navigatorjs) when the view is visible and it not should be it
     * @method transitionOut
     * @param callOnComplete
     */
    transitionOut(callOnComplete: Function) {
        this.$el.hide();

        // some UI, like the Search have both transitionOut and deactivate
        // TODO: look if there are UIs that already trigger deactivate in their
        // transitionOut and remove it to avoid that deactivate get called several time
        if (this.deactivate) {
            this.deactivate();
        }

        this.stopListening();
        this.removeChildren();
        callOnComplete();
    },

    /**
     * similar as a custom toString()
     * @method toString
     * @returns {string}
     */
    toString(): string {
        return 'StatedView';
    },

});

export default StatedView;
