// @flow
import { createAction } from 'redux-actions';
import identity from 'lodash.identity';
import omitBy from 'lodash.omitby';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import logger from '../../../util/logger';
import { format } from '../../../../browser/shared/util/gigwalkApiErrorUtil';
import * as googleMaps from '../../../ducks/googleMaps';
import * as snackbar from '../../../ducks/snackbar';
import { fetch as fetchOrganization } from '../../../redux/entities/organizations';
import {
    search as searchCustomers,
    update as updateCustomer,
} from '../../../redux/entities/customers';
import types from './types';

export const toggleFilterDrawer = createAction(types.TOGGLE_FILTER_DRAWER);

export const select = createAction(types.SELECT);
export const deselect = createAction(types.DESELECT);
export const deselectAll = createAction(types.DESELECT_ALL);

export const fetchDataSuccess = createAction(types.FETCH_DATA_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const fetchDataError = createAction(types.FETCH_DATA_ERROR);
export const fetchData = createAction(
    types.FETCH_DATA,
    (filters: ?Object[], keywords: ?string, sort: ?Object[], limit: ?number, offset: ?number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => {
            const params = omitBy({ filters, keywords, offset, limit, sort }, (value) => value == null);
            return dispatch(searchCustomers(params))
                .then((resp: Object) => dispatch(fetchDataSuccess(resp.result, resp.metadata)))
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchDataError());
                    return Promise.reject(err);
                });
        }
    )
);

export const loadFiltersSuccess = createAction(types.LOAD_FILTERS_SUCCESS);
export const loadFiltersError = createAction(types.LOAD_FILTERS_ERROR);
export const loadFilters = createAction(
    types.LOAD_FILTERS,
    (search: Object) => (
        (dispatch: Dispatch<any>) => Promise.resolve()
            .then(() => {
                const promises = [];
                if (search.organization) {
                    search.organization.forEach((id) => {
                        promises.push(dispatch(fetchOrganization({ organization_id: id })));
                    });
                }
                if (search.geo) {
                    search.geo.forEach((geo: Object) => {
                        const [type, address] = Object.entries(geo)[0]; // eslint-disable-line no-unused-vars
                        const params = { fields: ['formatted_address', 'place_id'], query: address };
                        promises.push(dispatch(googleMaps.actions.findPlaceFromQuery(params)));
                    });
                }
                return Promise.all(promises);
            })
            .then(() => {
                dispatch(loadFiltersSuccess());
            })
            .catch((err: $AxiosError<any>) => {
                logger.error(err);
                dispatch(loadFiltersError());
                return Promise.reject(err);
            })
    )
);

export const changeRoleSuccess = createAction(types.CHANGE_ROLE_SUCCESS);
export const changeRoleError = createAction(types.CHANGE_ROLE_ERROR);
export const changeRole = createAction(
    types.CHANGE_ROLE,
    (orgId: number, customerId: number, role: string): Function => (
        (dispatch: Dispatch<any>): Promise<void> => {
            const params = {
                customer_id: customerId,
                organization_id: orgId,
                role,
            };
            return dispatch(updateCustomer(params))
                .then((resp: Object) => dispatch(changeRoleSuccess(resp.result)))
                .catch((err: $AxiosError<any>) => {
                    dispatch(changeRoleError());
                    const resp = err ? err.response : null;
                    if (resp && resp.data && resp.data.gw_api_response) {
                        const message = format(resp.data.gw_api_response);
                        dispatch(snackbar.actions.enqueue(message, { variant: 'error' }));
                    }
                    return Promise.reject(err);
                });
        }
    )
);

export default {
    changeRole,
    changeRoleError,
    changeRoleSuccess,
    deselect,
    deselectAll,
    fetchData,
    fetchDataError,
    fetchDataSuccess,
    loadFilters,
    loadFiltersError,
    loadFiltersSuccess,
    select,
    toggleFilterDrawer,
};
