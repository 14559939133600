var Handlebars = require('/tmp/build_c41768ab/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<span class=\"sub-target\">"
    + alias4(((helper = (helper = helpers.for_target || (depth0 != null ? depth0.for_target : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"for_target","hash":{},"data":data}) : helper)))
    + ": "
    + alias4(((helper = (helper = helpers.targetName || (depth0 != null ? depth0.targetName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetName","hash":{},"data":data}) : helper)))
    + "</span><br>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<!-- question type is photo -->\n<div class=\"type photo\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-targetid=\""
    + alias4(((helper = (helper = helpers.targetID || (depth0 != null ? depth0.targetID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetID","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"answers\">\n    <div class=\"answer image\">\n      "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.targetName : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n      <div class=\"description\">"
    + alias4(((helper = (helper = helpers.question_text || (depth0 != null ? depth0.question_text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question_text","hash":{},"data":data}) : helper)))
    + "</div>\n      \n      <!-- the image the worker takes -->\n      <div class=\"center\">\n        <div class=\"placement\">\n          <label class=\"plainLabel\">\n            "
    + alias4(((helper = (helper = helpers.photo || (depth0 != null ? depth0.photo : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"photo","hash":{},"data":data}) : helper)))
    + "\n          </label>\n          <div class=\"wrapper\">\n            <a href=\"javascript:;\" class=\"bigButton photo\" data-action=\"uploadImage\">\n              "
    + alias4(((helper = (helper = helpers.add_photo || (depth0 != null ? depth0.add_photo : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"add_photo","hash":{},"data":data}) : helper)))
    + "\n            </a>\n          </div>\n          <div data-target=\"added\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"></div>\n        </div>\n      </div>\n      \n    </div>\n  </div>\n</div><!-- END photo -->";
},"useData":true});