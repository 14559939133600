// @flow
import { createAction, handleActions } from 'redux-actions';
import type { Dispatch } from 'redux';
import type { ActionType } from 'redux-actions';
import {
    UPLOAD_SUCCESS,
    GET_UPLOAD_STATS,
    GET_UPLOAD_STATS_SUCCESS,
    GET_UPLOAD_STATS_ERROR,
    upload,
    getUploadStats, uploadSuccess,
} from '../../../../../common/redux/entities/locationLists';
import type { DropzoneFile } from '../../Dropzone';
import type { State as RootState } from '../../../../../common/redux/initialState';

export type State = {
    listId: number,
    files: Array<DropzoneFile>,
    processing: Array<DropzoneFile>,
    isFetching: boolean,
};

export const init: State = {
    listId: 0,
    files: [],
    processing: [],
    isFetching: false,
};

// Actions
export const RESET = 'g/locationListUpload/RESET';
export const ADD_FILE = 'g/locationListUpload/ADD_FILE';
export const REMOVE_FILE = 'g/locationListUpload/REMOVE_FILE';
export const START_UPLOAD = 'g/locationListUpload/START_UPLOAD';
export const CHECK_PROGRESS = 'g/locationListUpload/CHECK_PROGRESS';
export const UPDATE_PROCESSING = 'g/locationListUpload/UPDATE_PROCESSING';

// Action Creators
export const reset = createAction(RESET);
export const addFile = createAction(ADD_FILE);
export const removeFile = createAction(REMOVE_FILE);
export const updateProcessing = createAction(UPDATE_PROCESSING);

export const checkProgress = createAction(
    CHECK_PROGRESS,
    (): Function => (
        (dispatch: Dispatch<*>, getState: () => RootState) => {
            const state = getState();
            const params = {
                location_list_id: state.locationListUpload.listId,
            };

            dispatch(getUploadStats(params));
        }
    )
);

export const startUpload = createAction(
    START_UPLOAD,
    (listName: string): Function => (
        (dispatch: Dispatch<*>, getState: () => RootState) => {
            const state = getState();
            const keys = state.locationListUpload.files.reduce((arr: Array<string>, file: DropzoneFile): Array<string> => {
                if (file.key) arr.push(file.key);
                return arr;
            }, []);
            const params = {
                organization_id: state.session.user ? state.session.user.organization.id : 0,
                location_list_name: listName,
                s3_keys: keys,
            };

            dispatch(upload(params));
        }
    )
);

// Reducer
export default handleActions({
    [RESET]: () => init,
    [ADD_FILE]: (state: State, action: ActionType<typeof addFile>) => {
        if (action.payload == null) {
            return state;
        }

        return {
            ...state,
            files: [
                ...state.files,
                action.payload,
            ],
            processing: state.processing.filter((file) => file !== action.payload),
        };
    },
    [REMOVE_FILE]: (state: State, action: ActionType<typeof removeFile>) => {
        if (action.payload == null) {
            return state;
        }

        return {
            ...state,
            files: state.files.filter((file) => file !== action.payload),
            processing: state.processing.filter((file) => file !== action.payload),
        };
    },
    [UPDATE_PROCESSING]: (state: State, action: ActionType<typeof updateProcessing>) => {
        if (action.payload == null) {
            return state;
        }

        const processing = state.processing.includes(action.payload)
            ? state.processing.filter((file) => file !== action.payload)
            : [...state.processing, action.payload];

        return {
            ...state,
            processing,
        };
    },
    [UPLOAD_SUCCESS]: (state: State, action: ActionType<typeof uploadSuccess>) => {
        if (action.payload == null) {
            return state;
        }

        return {
            ...state,
            listId: action.payload.result[0],
        };
    },
    [GET_UPLOAD_STATS]: (state: State) => ({
        ...state,
        isFetching: true,
    }),
    [GET_UPLOAD_STATS_SUCCESS]: (state: State) => ({
        ...state,
        isFetching: false,
    }),
    [GET_UPLOAD_STATS_ERROR]: (state: State) => ({
        ...state,
        isFetching: false,
    }),
}, init);
