// @flow
export const LOAD_TICKET = 'g/ticketDetail/LOAD_TICKET';
export const LOAD_TICKET_SUCCESS = `${LOAD_TICKET}_SUCCESS`;
export const LOAD_TICKET_ERROR = `${LOAD_TICKET}_ERROR`;

export const FETCH_ALL_APPLICANTS = 'g/ticketDetail/FETCH_ALL_APPLICANTS';
export const FETCH_ALL_APPLICANTS_SUCCESS = `${FETCH_ALL_APPLICANTS}_SUCCESS`;
export const FETCH_ALL_APPLICANTS_ERROR = `${FETCH_ALL_APPLICANTS}_ERROR`;

export const FETCH_ALL_EVENTS = 'g/ticketDetail/FETCH_ALL_EVENTS';
export const FETCH_ALL_EVENTS_SUCCESS = `${FETCH_ALL_EVENTS}_SUCCESS`;
export const FETCH_ALL_EVENTS_ERROR = `${FETCH_ALL_EVENTS}_ERROR`;

export default {
    FETCH_ALL_APPLICANTS,
    FETCH_ALL_APPLICANTS_ERROR,
    FETCH_ALL_APPLICANTS_SUCCESS,
    FETCH_ALL_EVENTS,
    FETCH_ALL_EVENTS_ERROR,
    FETCH_ALL_EVENTS_SUCCESS,
    LOAD_TICKET,
    LOAD_TICKET_ERROR,
    LOAD_TICKET_SUCCESS,
};
