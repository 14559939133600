// @flow
import { createAction } from 'redux-actions';
import { normalize } from 'normalizr';
import omitBy from 'lodash.omitby';
import type { Dispatch } from 'redux';
import type { $AxiosXHR, $AxiosError } from 'axios';
import type { APIResponse, APIPromise } from 'gigwalk/lib/api/resource';
import type { Organization } from 'gigwalk/lib/api/organizations/types';
import logger from '../../util/logger';
import { client as gigwalk } from '../../api/createGigwalkClient';
import { init } from './initialState';
import { organization as organizationSchema } from './schemas';
import type { State } from './initialState';

// Actions
export const FETCH = 'g/organizations/FETCH';
export const FETCH_SUCCESS = `${FETCH}_SUCCESS`;
export const FETCH_ERROR = `${FETCH}_ERROR`;

export const GET_BALANCE = 'g/organizations/GET_BALANCE';
export const GET_BALANCE_SUCCESS = `${GET_BALANCE}_SUCCESS`;
export const GET_BALANCE_ERROR = `${GET_BALANCE}_ERROR`;

export const ADD_FUNDS = 'g/organizations/ADD_FUNDS';
export const ADD_FUNDS_SUCCESS = `${ADD_FUNDS}_SUCCESS`;
export const ADD_FUNDS_ERROR = `${ADD_FUNDS}_ERROR`;

export const SEARCH = 'g/organizations/SEARCH';
export const SEARCH_SUCCESS = `${SEARCH}_SUCCESS`;
export const SEARCH_ERROR = `${SEARCH}_ERROR`;

// Action Creators
export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchError = createAction(FETCH_ERROR);
export const fetch = createAction(
    FETCH,
    (params): Function => (
        (dispatch: Dispatch<any>): APIPromise<[Organization]> => (
            gigwalk.organizations.get(params)
                .then((resp: $AxiosXHR<APIResponse<[Organization]>>) => {
                    const normalized = normalize(resp.data.data, [organizationSchema]);
                    dispatch(fetchSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchError());
                    return Promise.reject(err);
                })
        )
    )
);

export const getBalanceSuccess = createAction(GET_BALANCE_SUCCESS);
export const getBalanceError = createAction(GET_BALANCE_ERROR);
export const getBalance = createAction(
    GET_BALANCE,
    (params): Function => (
        (dispatch: Dispatch<any>): APIPromise<[{ current_balance: number }]> => (
            gigwalk.payments.getCurrentBalance(params)
                .then((resp: $AxiosXHR<APIResponse<[{ current_balance: number }]>>) => {
                    const data = resp.data.data.map((entity) => ({
                        id: params.organization_id,
                        ...entity,
                    }));
                    const normalized = normalize(data, [organizationSchema]);
                    dispatch(getBalanceSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(getBalanceError());
                    return Promise.reject(err);
                })
        )
    )
);

export const addFundsSuccess = createAction(ADD_FUNDS_SUCCESS);
export const addFundsError = createAction(ADD_FUNDS_ERROR);
export const addFunds = createAction(
    ADD_FUNDS,
    (params): Function => (
        (dispatch: Dispatch<any>): APIPromise<void> => (
            // $FlowFixMe stripe_token is not needed for platform admins
            gigwalk.payments.addFunds(params)
                .then(() => {
                    dispatch(addFundsSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(addFundsError());
                    return Promise.reject(err);
                })
        )
    )
);

export const searchSuccess = createAction(SEARCH_SUCCESS);
export const searchError = createAction(SEARCH_ERROR);
export const search = createAction(
    SEARCH,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<[Organization]> => {
            const config = {
                params: omitBy(params, (value) => value == null),
            };
            return gigwalk.client.get('/v1/organizations/search', config)
                .then((resp: $AxiosXHR<APIResponse<[Organization]>>) => {
                    const normalized = normalize(resp.data.data, [organizationSchema]);
                    dispatch(searchSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(searchError());
                    return Promise.reject(err);
                });
        }
    )
);

export default function(state: State = init): State {
    return state;
}
