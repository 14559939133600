// @flow
import React, { Component, Fragment } from 'react';
import qs from 'qs';
import isEqual from 'lodash.isequal';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter } from 'react-router';
import type { $AxiosError } from 'axios';
import { format } from '../../../browser/shared/util/gigwalkApiErrorUtil';
import getDisplayName from '../../util/getDisplayName';
import LoadingPage from '../../components/LoadingPage';
import StarRating from '../../components/StarRating';
import UserAvatar from '../../components/UserAvatar';
import SimplePane from '../../components/SimplePane';
import DataTable from '../../components/DataTable';
import ActionToolbar from './containers/ActionToolbar';
import FilterToolbar from './containers/FilterToolbar';
import SearchToolbar from './containers/SearchToolbar';
import * as snackbar from '../../ducks/snackbar';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../redux/initialState';

type State = {
    loading: boolean,
};

type OwnProps = ContextRouter & {
    classes: Object,
    t: TFunction,
};
type StateProps = {
    data: Object[],
    fetching: boolean,
    filters?: Object[],
    keywords?: string,
    limit?: number,
    offset?: number,
    pages: number,
    recordCount: number,
    search: {
        size?: string,
        page?: string,
        sort?: string,
        order?: string,
    },
    sort?: Object[],
};
type DispatchProps = {
    enqueueSnackbar: (message: string, options: Object) => void,
    fetchData: (filters: ?Object[], keywords: ?string, sort: ?Object[], limit: ?number, offset: ?number) => Promise<void>,
};
type Props = OwnProps & StateProps & DispatchProps;

export class GigwalkerList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        // this._fetchData = debounce(this._fetchData, 500);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        const { filters, keywords, sort, limit, offset } = this.props;
        this._fetchData(filters, keywords, sort, limit, offset)
            .then(() => { this.setState({ loading: false }); });
    }

    componentWillReceiveProps(nextProps: Props) {
        const { filters, keywords, sort, limit, offset } = this.props;
        const {
            filters: nextFilters,
            keywords: nextKeywords,
            sort: nextSort,
            limit: nextLimit,
            offset: nextOffset,
        } = nextProps;

        // @todo Check if page changed and previous page was out of range
        // If the previous page is out of range and the new page is the last page, that
        // means we updated the url to match the data fetched, and no fetch is needed

        // If any filter, sort, or pagination parameters changed, we should fetch new data.
        // Otherwise, if the table data has changed at all (for instance, an entity was deleted
        // from state), fetch the current page again.
        const shouldFetchData = !isEqual(nextFilters, filters)
            || !isEqual(nextSort, sort)
            || nextKeywords !== keywords
            || nextLimit !== limit
            || nextOffset !== offset;

        if (shouldFetchData) {
            this._fetchData(nextFilters, nextKeywords, nextSort, nextLimit, nextOffset);
        }
    }

    handlePageChange = (page: number) => {
        const { location, history, search } = this.props;
        const query = page >= 0 ? { page: page + 1 } : {}; // Don't forget to convert from zero-based index!

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                { ...search, ...query },
                { addQueryPrefix: true, encodeValuesOnly: true }
            ),
        });
    };

    handlePageSizeChange = (pageSize: number) => {
        const { location, history, search } = this.props;
        const { size, ...query } = search;

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                pageSize ? { ...query, size: pageSize } : query,
                { addQueryPrefix: true, encodeValuesOnly: true }
            ),
        });
    };

    handleSortedChange = (sorted: Object[]) => {
        const { location, history, search } = this.props;
        const { sort, order, ...query } = search;
        const { id, desc } = sorted.length > 0 ? sorted[0] : {};

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                id ? { ...query, sort: id, order: desc ? 'desc' : 'asc' } : query,
                { addQueryPrefix: true, encodeValuesOnly: true }
            ),
        });
    };

    _fetchData = (filters: ?Object[], keywords: ?string, sort: ?Object[], limit: ?number, offset: ?number) => {
        const { enqueueSnackbar, fetchData } = this.props;
        return fetchData(filters, keywords, sort, limit, offset)
            .catch((err: $AxiosError<any>) => {
                const resp = err ? err.response : null;
                if (resp && resp.data && resp.data.gw_api_response) {
                    const message = format(resp.data.gw_api_response);
                    enqueueSnackbar(message, { variant: 'error' });
                }
            });
    };

    _getColumns() {
        const { classes, t } = this.props;

        const status = {
            Header: t('gigwalkerList.status'),
            accessor: 'status',
            width: 100,
            className: classes.status,
            Cell: (props: Object) => t(`gigwalkerList.statusEnum.${props.value}`),
        };
        const name = {
            Header: t('gigwalkerList.name'),
            accessor: 'customer',
            id: 'name',
            Cell: (props: Object) => {
                const customer = props.value;
                return (
                    <Fragment>
                        <UserAvatar className={classes.avatar} user={customer} />
                        <Link to={`/member/5/${customer.id}/account`}>
                            {getDisplayName(customer)}
                        </Link>
                    </Fragment>
                );
            },
        };
        const email = {
            Header: t('gigwalkerList.email'),
            accessor: 'email',
        };
        const paypalEmail = {
            Header: t('gigwalkerList.paypalEmail'),
            accessor: 'paypalEmail',
        };
        const rating = {
            Header: t('gigwalkerList.rating'),
            accessor: 'rating',
            className: classes.rating,
            Cell: (props: Object) => {
                const userRating = props.value;
                return (
                    <Fragment>
                        <StarRating className={classes.stars} value={userRating || 0} disableHover />
                        {userRating ? userRating.toFixed(1) : 'N/A'}
                    </Fragment>
                );
            },
        };

        return [name, status, email, paypalEmail, rating];
    }

    render() {
        const { loading } = this.state;
        const { classes, data, fetching, pages, recordCount, search } = this.props;
        const { order, sort } = search;
        const page = Math.max(parseInt(search.page, 10) - 1, 0) || 0;
        const pageSize = Math.max(parseInt(search.size, 10), 0) || 10;

        const columns = this._getColumns();
        const sorted = [{
            id: sort || 'name',
            desc: order === 'desc',
        }];

        if (loading) {
            return (
                <SimplePane>
                    <LoadingPage />
                </SimplePane>
            );
        }

        return (
            <SimplePane classes={classes.root}>
                <div className={classes.toolbars}>
                    <Route render={(props: ContextRouter) => <SearchToolbar {...props} className={classes.search} />} />
                    <Route render={(props: ContextRouter) => <FilterToolbar {...props} className={classes.filters} />} />
                </div>
                <Paper elevation={2}>
                    <Route component={ActionToolbar} />
                    <DataTable
                      sortable
                      columns={columns}
                      count={recordCount}
                      data={data}
                      loading={fetching}
                      onPageChange={this.handlePageChange}
                      onPageSizeChange={this.handlePageSizeChange}
                      onSortedChange={this.handleSortedChange}
                      pageSizeOptions={[10, 20, 50]}
                      defaultPageSize={10}
                      page={page}
                      pages={pages}
                      pageSize={pageSize}
                      sorted={sorted}
                    />
                </Paper>
            </SimplePane>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    ...selectors.getAPIParams(state, props),
    data: selectors.getTableData(state, props),
    fetching: state.gigwalkerList.fetching,
    pages: state.gigwalkerList.metadata.page_count || 0,
    recordCount: state.gigwalkerList.metadata.record_count || 0,
    search: selectors.parseSearchParams(state, props),
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    enqueueSnackbar: (message: string, options: Object) => dispatch(snackbar.actions.enqueue(message, options)),
    fetchData: (filters: ?Object[], keywords: ?string, sort: ?Object[], limit: ?number, offset: ?number) => (
        dispatch(actions.fetchData(filters, keywords, sort, limit, offset))
    ),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'GigwalkerList' }),
    withTranslation(),
    connector,
);

export default enhance(GigwalkerList);
