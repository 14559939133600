// @flow
import isNode from 'detect-node';
import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import fsaThunkMiddleware from 'redux-fsa-thunk';
import multiMiddleware from 'redux-multi';
import type { Store } from 'redux';
import createRootReducer from '../redux';
import { init } from '../redux/initialState';
import type { State as RootState } from '../redux/initialState';
// import reduxFormAnalytics from './middleware/reduxFormAnalytics';
// import analyticsMiddleware from './middleware/analytics';

type StoreHistory = {
    store: Store<RootState, *>,
    history: Object,
}

type Options = {
    initialState?: RootState,
    url?: string,
};

const defaultOptions: Options = {
    initialState: init,
    url: '/',
};

/**
 * Configure root store for redux
 */
export default function configureStore(options?: Options): StoreHistory {
    const { initialState, url } = { ...defaultOptions, ...options };
    const history = isNode
        ? createMemoryHistory({ initialEntries: [url] })
        : createBrowserHistory();

    // Update initialState to include current routing info
    const updatedInitialState = {
        ...initialState,
        router: {
            action: history.action,
            location: history.location,
        },
    };

    const middleware = [
        multiMiddleware,
        fsaThunkMiddleware,
        // reduxFormAnalytics,
        // analyticsMiddleware,
        routerMiddleware(history),
    ];
    const rootReducer = createRootReducer(history);
    const enhancer = composeWithDevTools(applyMiddleware(...middleware));
    const store = createStore(rootReducer, updatedInitialState, enhancer);

    // if (module.hot && typeof module.hot.accept === 'function') {
    //     // Enable Webpack hot module replacement for reducers
    //     module.hot.accept('../redux', () => {
    //         const nextRootReducer = require('../redux').default; // eslint-disable-line global-require
    //         store.replaceReducer(nextRootReducer);
    //     });
    // }

    return {
        history,
        store,
    };
}
