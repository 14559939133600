// @flow
import React, { Component } from 'react';
import merge from 'lodash.merge';
import uniqueId from 'lodash.uniqueid';
import { connect } from 'react-redux';
import {
    reduxForm,
    formValueSelector,
    getFormSyncErrors,
    getFormInitialValues,
    isValid,
    defaultShouldValidate,
    FieldArray,
} from 'redux-form';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { Fab } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { FormProps, FieldArrayProps } from 'redux-form';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { $AxiosXHR } from 'axios';
import type { TFunction } from 'react-i18next';
import type { DataType } from 'gigwalk/lib/api/dataTypes/types';
import { client as gigwalk } from '../../../../api/createGigwalkClient';
import getValidationRule from '../../utils/getValidationRule';
import Autocomplete from '../../../../components/Autocomplete';
import Step from '../../components/Step';
import QuestionCard from './components/QuestionCard';
import { actions, selectors } from './duck';
import { mapEntityToQuestion } from './utils/transforms';
import styles from './styles';
import { VALUE_TYPE } from '../../../../../browser/shared/constant/ProjectConstant';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = {
    ...ContextRouter,
    ...FormProps,
    t: TFunction,
};

type StateProps = {
    errors: ?Object,
    expanded: ?number,
    questionCount: number,
};

type DispatchProps = {
    collapseQuestion: (index: number) => void,
    expandQuestion: (index: number) => void,
};

type Props = OwnProps & StateProps & DispatchProps;

export class Questions extends Component<Props> {
    componentDidMount() {
        const { history, location } = this.props;
        const lastPage = history.location.state ? history.location.state.lastPage : false;
        if (!lastPage) {
            history.replace({ ...location, state: { lastPage: true } });
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        const { history, location } = nextProps;
        const lastPage = history.location.state ? history.location.state.lastPage : false;
        if (!lastPage) {
            history.replace({ ...location, state: { lastPage: true } });
        }
    }

    search = (query: string): Promise<Object[]> => {
        const { match } = this.props;
        return gigwalk.dataTypes.search({ organization_id: match.params.orgId, query_string: query })
            .then((resp: $AxiosXHR<Object>) => {
                const searchResults = resp.data.data ? resp.data.data.search_results || [] : [];
                const dataTypes = searchResults.reduce((results: Object[], record: { score: number, data: Object }) => {
                    results.push(record.data);
                    return results;
                }, []);

                return dataTypes.map((dataType: Object) => ({
                    label: dataType.questions.question_text,
                    value: dataType,
                }));
            });
    };

    handleDuplicate = (index: number, question: Object) => {
        const { array, expandQuestion } = this.props;
        const duplicate = merge({}, question);
        const { id } = duplicate;

        // Don't use 'duplicate' as the prefix for new questions. We rely on the 'new' prefix to
        // identify questions that need to be created and then added to the subscription.
        // For simplicity's sake, a duplicate of a new question is just a new question
        duplicate.id = typeof id === 'string' && id.startsWith('new')
            ? uniqueId(`new_${id}_`)
            : uniqueId(`duplicate_${id}_`);

        const duplicateIndex = index + 1;
        array.insert('questionList', duplicateIndex, duplicate);
        expandQuestion(duplicateIndex);
    };

    handleDelete = (index: number) => {
        const { array, collapseQuestion } = this.props;
        collapseQuestion(index);
        array.remove('questionList', index);
    };

    handleSave = (index: number) => {
        const { collapseQuestion, errors, expanded } = this.props;
        const invalid = errors && errors.questionList && errors.questionList[index]
            ? Object.keys(errors.questionList[index]).length > 0
            : false;

        if (expanded === index && !invalid) {
            collapseQuestion(index);
            // saveQuestion(index);
        }
    };

    handleSelect = (index: number) => {
        const { expandQuestion, expanded } = this.props;

        // Only allow one question to be expanded at a time
        if (expanded == null) {
            expandQuestion(index);
        }
    };

    handleAddNew = () => {
        const { array, expandQuestion, questionCount } = this.props;
        const question = {
            id: uniqueId('new_'),
            description: '',
            valueType: VALUE_TYPE.FREE_TEXT,
            questionText: '',
            propositions: [],
            required: false,
        };

        array.push('questionList', question);
        expandQuestion(questionCount);
    };

    handleAddExisting = (dataType: DataType) => {
        const { array } = this.props;
        const question = mapEntityToQuestion(dataType);
        question.id = uniqueId(`duplicate_${question.id}_`);
        array.push('questionList', question);
    };

    renderQuestions: Function = (props: FieldArrayProps) => {
        const { fields } = props;
        const { array, classes, expanded, saving, valid } = this.props;

        return (
            <ul className={classes.questionList}>
                {fields.map((name: string, index: number) => {
                    const question = fields.get(index);
                    const onValueTypeChange = (valueType: string) => {
                        const shouldAddPropositions = (
                            question.propositions.length === 0
                            && (valueType === VALUE_TYPE.MULTI_SELECT || valueType === VALUE_TYPE.MULTIPLE_CHOICE)
                        );

                        if (shouldAddPropositions) {
                            array.push(`${name}.propositions`, { choice: '', alert: false });
                            array.push(`${name}.propositions`, { choice: '', alert: false });
                        } else {
                            array.removeAll(`${name}.propositions`);
                        }
                    };

                    return (
                        <li key={question.id} className={classes.question}>
                            <QuestionCard
                              {...question}
                              moveCard={fields.move}
                              draggable={expanded == null}
                              formKey={name}
                              index={index}
                              onDelete={() => { this.handleDelete(index); }}
                              onDuplicate={() => { this.handleDuplicate(index, question); }}
                              onClick={() => { this.handleSelect(index); }}
                              onClickOutside={() => { this.handleSave(index); }}
                              onValueTypeChange={onValueTypeChange}
                              expanded={index === expanded}
                              saving={index === saving}
                              valid={index === expanded ? valid : true}
                            />
                        </li>
                    );
                })}
            </ul>
        );
    };

    render() {
        const { classes, expanded, t } = this.props;

        return (
            <Step className={classes.container} title={t('projectBuilder.data.header')}>
                <form className={classes.form}>
                    <Autocomplete
                      className={classes.autocomplete}
                      onChange={this.handleAddExisting}
                      placeholder={t('projectBuilder.data.searchPlaceholder')}
                      suggestionMatch={this.search}
                      disabled={expanded != null}
                    />
                    {/* $FlowTypedIssue FieldArray validate type signature is missing name parameter */}
                    <FieldArray
                      name="questionList"
                      component={this.renderQuestions}
                      validate={getValidationRule('questionList')}
                    />
                </form>
                <Fab className={classes.button} color="secondary" disabled={expanded != null} onClick={this.handleAddNew}>
                    <AddIcon />
                </Fab>
            </Step>
        );
    }
}

const valueSelector = formValueSelector('projectBuilder');

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const errors = getFormSyncErrors('projectBuilder')(state);
    const questionList = valueSelector(state, 'questionList');

    return {
        ...state.projectBuilder.questions,
        errors,
        initialValues: {
            ...getFormInitialValues('projectBuilder')(state),
            ...selectors.getInitialValues(state, props),
        },
        questionCount: questionList ? questionList.length : 0,
        valid: isValid('projectBuilder')(state),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    collapseQuestion: (index: number) => dispatch(actions.collapseQuestion(index)),
    expandQuestion: (index: number) => dispatch(actions.expandQuestion(index)),
});

const shouldValidate = (params: Object): boolean => {
    if (params.initialRender) {
        return true;
    }

    const { values } = params.props;
    const { values: nextValues } = params.nextProps;

    // When a question is removed, redux-form will run validation by default BEFORE the
    // question fields are unregistered. This means we'll end up with sync errors for fields
    // that don't exist! Note: this check assumes only question can be removed at a time.
    const hasQuestionBeenRemoved = values.questionList && nextValues.questionList && nextValues.questionList.length === values.questionList.length - 1;
    return defaultShouldValidate(params) && !hasQuestionBeenRemoved;
};

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'Questions' }),
    withTranslation(),
    connector,
    reduxForm({
        form: 'projectBuilder',
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        shouldValidate,
    })
);

export default enhance(Questions);
