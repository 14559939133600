// @flow
import React, { Component } from 'react';
import cx from 'classnames';
import i18next from 'i18next';
import { withStyles } from '@material-ui/styles';
import { Avatar } from '@material-ui/core';
import type { Node } from 'react';
import styles from './styles';

type Props = {
    children?: Node,
    className?: string,
    classes: Object,
    user: Object,

};

type State = {
    orientation: string,
    displayDefaultAvatar: boolean,
};

export class UserAvatar extends Component<Props, State> {
    state = {
        orientation: 'portrait',
        displayDefaultAvatar: true,
    };

    handleError = () => {
        this.setState({ displayDefaultAvatar: true });
    };

    handleLoad = (event: SyntheticEvent<HTMLImageElement>) => {
        const img = event.currentTarget;
        const { height, width } = img.getBoundingClientRect();
        this.setState({
            orientation: height < width ? 'landscape' : 'portrait',
            displayDefaultAvatar: false,
        });
    };

    render() {
        const { classes, children, user, ...other } = this.props;
        const { orientation, displayDefaultAvatar } = this.state;
        const {
            first_name: firstName,
            last_name: lastName,
            photo_url: photoUrl,
        } = user;

        const name = i18next.language === 'jp'
            ? lastName || firstName || ''
            : firstName || lastName || '';

        const imgSrc = photoUrl && photoUrl.endsWith('/img/default-user-image.gif') ? null : photoUrl;

        const className = cx(classes.root, other.className, {
            [classes.landscape]: orientation === 'landscape',
            [classes.portrait]: orientation === 'portrait',
        });

        return (
            <Avatar {...other} className={className}>
                {displayDefaultAvatar ? name[0] : null}
                {imgSrc
                    ? (
                        <img
                          className={classes.image}
                          src={imgSrc}
                          alt="avatar"
                          onError={this.handleError}
                          onLoad={this.handleLoad}
                        />
                    )
                    : null
                }
                {children}
            </Avatar>
        );
    }
}

export default withStyles(styles, { name: 'UserAvatar' })(UserAvatar);
