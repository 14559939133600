// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Button, Paper } from '@material-ui/core';
import styles from './styles';

const useStyles = makeStyles(styles, { name: 'PasswordChanged' });

export default function PasswordChanged() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Paper className={classes.paper} elevation={0}>
            <h1>{t('resetPassword.changed.heading')}</h1>
            <p>{t('resetPassword.changed.passwordChanged')}</p>
            <Button
              className={classes.loginButton}
              component={Link}
              size="large"
              to="/login"
              variant="contained"
            >
                {t('resetPassword.changed.login')}
            </Button>
        </Paper>
    );
}
