// @flow
import { handleActions } from 'redux-actions';
import types from './types';

export type State = {
    expanded: boolean,
    inTransition: boolean,
    open: boolean,
};

export const init: State = {
    expanded: false,
    inTransition: false,
    open: false,
};

export default handleActions({
    [types.TOGGLE]: (state: State): State => {
        const open = !state.open;
        return {
            ...state,
            open,
        };
    },
    [types.EXPAND]: (state: State): State => ({
        ...state,
        expanded: true,
    }),
    [types.COLLAPSE]: (state: State): State => ({
        ...state,
        expanded: false,
    }),
    [types.START_TRANSITION]: (state: State): State => ({
        ...state,
        inTransition: true,
    }),
    [types.END_TRANSITION]: (state: State): State => ({
        ...state,
        inTransition: false,
    }),
}, init);
