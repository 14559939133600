// @flow
import { createSelector } from 'reselect';
import { getAvailableActions, getPermittedActions, getTicket } from '../../../../../duck/selectors';

export { getTicket } from '../../../../../duck/selectors';

export const getInitialValues = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    getTicket,
    (ticket: ?Object): Object => ({
        assignee: ticket ? ticket.customer : null,
    })
);

export const getReadOnly = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    getPermittedActions,
    (actions: string[]): string[] => {
        const readOnly = [];
        if (!actions.includes('assign') && !actions.includes('unassign')) {
            readOnly.push('assignee');
        }
        return readOnly;
    }
);

export const getDisabled = createSelector(
    getAvailableActions,
    (actions: string[]): string[] => {
        const disabled = [];
        if (!actions.includes('assign') && !actions.includes('unassign')) {
            disabled.push('assignee');
        }
        return disabled;
    }
);

export default {
    getDisabled,
    getInitialValues,
    getReadOnly,
    getTicket,
};
