// @flow
export default (theme: Object) => ({
    dialogPaper: {
        background: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
    },
    screenshot: {
        marginBottom: theme.spacing(1.5),
        height: '50vh',
        width: 'auto',
    },
    title: {
        marginBottom: theme.spacing(2),
        fontSize: 18,
    },
    description: {
        marginBottom: theme.spacing(1.5),
        fontWeight: 300,
    },
    downloadApp: {
        marginBottom: theme.spacing(2.5),

        '& > :not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
    continueToWeb: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        fontWeight: 300,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '& > :first-child': {
            margin: theme.spacing(0, 0, 0, 0.5),
        },
    },
    continueIcon: {
        fontSize: 'inherit',
    },
});
