// @flow
export default (theme: Object): Object => ({
    form: {
        minWidth: 260,
        maxWidth: 350,
        width: '70vw',
        padding: theme.spacing(3, 0, 5, 0),
    },
    label: {
        fontWeight: 600,
        marginBottom: theme.spacing(1),
    },
    requiredCertifications: {
        marginBottom: theme.spacing(2),
    },
    certification: {
        width: '100%',
        '&:not(:last-child)': {
            marginBottom: theme.spacing(1),
        },
    },
    selectInput: {
        '@global input': {
            marginLeft: 36,
            textAlign: 'center',
        },
    },
    selectPlaceholder: {
        left: 0,
        textAlign: 'center',
        width: '100%',
    },
    chipRoot: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        background: 'white',
        height: '60px',
        border: [['1px', 'solid', theme.palette.grey[100]]],
        borderRadius: '30px',
        boxShadow: theme.shadows[1],
    },
    chipAvatar: {
        position: 'absolute',
        left: 0,
        background: 'transparent',
        color: theme.palette.grey[500], // theme.palette.common.silver
        height: 60,
        width: 60,
        // fontSize: 24,
        // paddingLeft: 24,
        // justifyContent: 'flex-start',
    },
    chipAvatarChildren: {
        height: 47.5,
        width: 47.5,
    },
    chipLabel: {
        fontSize: 18,
    },
    chipDeleteIcon: {
        position: 'absolute',
        right: 0,
    },
});
