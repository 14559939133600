// @flow
import moment from 'moment';

const validFormats = [
    'HH:mm:ss',
    'HH:mm',
    'H:mm:ss',
    'H:mm',
    'hh:mm:ssA',
    'hh:mmA',
    'h:mm:ssA',
    'h:mmA',
];

export default function convertTo24Hour(time: string): ?string {
    const i = time.search(/(am|a\.m\.|pm|p\.m\.)\s*$/i);
    const _time = (i >= 0) ? `${time.slice(0, i).trim()}${time.slice(i).trim()}` : time.trim();
    const date = moment(_time, validFormats, true);

    return date.isValid() ? date.format('HH:mm') : null;
}
