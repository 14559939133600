// @flow
import React, { Component } from 'react';
import defer from 'lodash.defer';
import isEqual from 'lodash.isequal';
import noop from 'lodash.noop';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import type { MapStateToProps, MapDispatchToProps } from 'react-redux';
import type { State as RootState } from '../redux/initialState';
import StatedView from '../../browser/shared/view/StatedView';

export default (mapStateToProps: MapStateToProps<RootState, *, *>, mapDispatchToProps?: MapDispatchToProps<*, *, *>) => (
    (BackboneView: typeof StatedView) => {
        class ReactContainer extends Component<*> {
            componentDidMount() {
                // Use defer to delay rendering/updating the Backbone view until the next event loop.
                // This is important if the view includes any React children. It gets a little messy
                // if you try to render a new React root during the current render phase.
                defer(this._transitionIn);
            }

            shouldComponentUpdate(nextProps) {
                return !this.view || !isEqual(this.props, nextProps);
            }

            componentDidUpdate() {
                // Use defer to delay rendering/updating the Backbone view until the next event loop.
                // This is important if the view includes any React children. It gets a little messy
                // if you try to render a new React root during the current render phase.
                defer(this._updateView);
            }

            componentWillUnmount() {
                this._transitionOut();
            }

            _transitionIn = () => {
                if (!this.view) {
                    const node = findDOMNode(this); // eslint-disable-line react/no-find-dom-node
                    this.view = new BackboneView({ el: node, props: this.props });
                    if (this.view.transitionIn) {
                        this.view.transitionIn(noop);
                    }
                    this.view.render();
                } else if (this.view.transitionIn) {
                    this.view.transitionIn(noop);
                }
            };

            _transitionOut = () => {
                if (this.view) {
                    if (this.view.transitionOut) {
                        this.view.transitionOut(noop);
                    }
                    delete this.view;
                }
            };

            _updateView = () => {
                if (!this.view) {
                    this._transitionIn();
                } else {
                    this.view.props = this.props;
                    this.view.render();
                }
            };

            view: ?StatedView<*>;

            render() {
                return <div />;
            }
        }

        return connect(mapStateToProps, mapDispatchToProps)(ReactContainer);
    }
);
