// @flow
import React, { Component, Fragment } from 'react';
import qs from 'qs';
import cx from 'classnames';
import { isEmail } from 'validator';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { IconButton, ListItemText, Toolbar, Tooltip } from '@material-ui/core';
import { Check as CheckIcon, Clear as ClearIcon } from '@material-ui/icons';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter } from 'react-router';
import { search as searchCustomers } from '../../../../redux/entities/customers';
import { search as searchTickets } from '../../../../redux/entities/tickets';
import highlightMatch from '../../../../util/highlightMatch';
import getDisplayName from '../../../../util/getDisplayName';
import FilterSelect from '../../../../components/FilterSelect';
import { selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {
    className?: string,
    classes: Object,
    t: TFunction,
};
type StateProps = {
    customerFilterValue: ?Object,
    search: {
        customer?: string,
        ticket?: string,
    },
    ticketFilterValue: ?Object,
};
type DispatchProps = {
    searchCustomers: (params: Object) => Promise<Object>,
    searchTickets: (params: Object) => Promise<Object>,
};
type Props = OwnProps & StateProps & DispatchProps;

const noFilters = {
    customer: undefined,
    ticket: undefined,
};

export class FilterToolbar extends Component<Props> {
    formatCustomerOptionLabel = (option: Object, meta: Object) => {
        const { classes } = this.props;
        const { label, value } = option;
        const highlightProps = { className: classes.highlight };
        const primary = highlightMatch(label, meta.inputValue, highlightProps);
        const secondary = (
            <Fragment>
                <div>
                    <span>Email: </span>
                    {highlightMatch(value.email, meta.inputValue, highlightProps)}
                </div>
                <div>
                    <span>ID: </span>
                    {highlightMatch(value.id, meta.inputValue, highlightProps)}
                </div>
            </Fragment>
        );

        return (
            <Fragment>
                <ListItemText
                  classes={{
                      primary: classes.listItemPrimary,
                      secondary: classes.listItemSecondary,
                  }}
                  primary={primary}
                  secondary={secondary}
                />
                {meta.isSelected ? <CheckIcon /> : null}
            </Fragment>
        );
    };

    formatTicketOptionLabel = (option: Object, meta: Object) => {
        const { classes } = this.props;
        const { label, value } = option;
        const highlightProps = { className: classes.highlight };
        const primary = highlightMatch(label, meta.inputValue, highlightProps);
        const secondary = (
            <Fragment>
                <span>ID: </span>
                {highlightMatch(value.id, meta.inputValue, highlightProps)}
            </Fragment>
        );

        return (
            <Fragment>
                <ListItemText
                  classes={{
                      primary: classes.listItemPrimary,
                      secondary: classes.listItemSecondary,
                  }}
                  primary={primary}
                  secondary={secondary}
                />
                {meta.isSelected ? <CheckIcon /> : null}
            </Fragment>
        );
    };

    loadCustomerOptions = (inputValue: string) => {
        const params = {};
        params.filters = [
            { key: 'organization_id', value: 5 },
        ];
        params.limit = 20;
        params.offset = 0;
        params.sort = [{ first_name: 'asc' }];

        if (inputValue) {
            if (isEmail(inputValue)) {
                params.filters.push(
                    {
                        filter_type: 'or',
                        filters: [
                            { key: 'email', value: inputValue },
                            { key: 'paypal_email', value: inputValue },
                        ],
                    }
                );
            } else {
                params.keywords = inputValue;
            }
        }

        // Use searchCustomers to ensure entities are stored in state
        return this.props.searchCustomers(params) // eslint-disable-line react/destructuring-assignment
            .then((resp) => {
                // @todo: Consider using entitySelector to get denormalized entity...
                const { entities, result } = resp;
                return result.map((id: number) => ({
                    label: getDisplayName(entities.customers[id]),
                    value: entities.customers[id],
                }));
            });
    };

    loadTicketOptions = (inputValue: string) => {
        const params = {};
        params.filters = [{
            key: 'needs_public_workforce',
            value: true,
        }];
        params.limit = 20;
        params.offset = 0;
        params.sort = [{ title: 'asc' }];

        if (inputValue) {
            params.keywords = inputValue;
        }

        // Use searchOrganizations to ensure entities are stored in state
        return this.props.searchTickets(params) // eslint-disable-line react/destructuring-assignment
            .then((resp) => {
                // @todo: Consider using entitySelector to get denormalized entity...
                const { entities, result } = resp;
                return result.map((id: number) => ({
                    label: entities.tickets[id].title,
                    value: entities.tickets[id],
                }));
            });
    };

    handleCustomerFilterChange = (value: ?Object) => {
        const customerFilter = value ? value.value.id : undefined;
        this.updateFilters({ customer: customerFilter });
    };

    handleTicketFilterChange = (value: ?Object) => {
        const ticketFilter = value ? value.value.id : undefined;
        this.updateFilters({ ticket: ticketFilter });
    };

    handleClear = () => {
        this.updateFilters(noFilters);
    };

    updateFilters(filters: Object) {
        const { location, history, search } = this.props;

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                { ...search, ...filters },
                { addQueryPrefix: true, encodeValuesOnly: true },
            ),
        });
    }

    renderSelectedCount = (value: ?Object) => {
        const { t } = this.props;
        let count = 0;

        if (value != null) {
            count = Array.isArray(value) ? value.length : 1;
        }

        return count
            ? (
                <Trans
                  defaults={t('payoutList.filters.selectedCount', { count })}
                  components={[<strong style={{ fontWeight: 600 }}>count</strong>, 'selected']}
                />
            )
            : t('payoutList.filters.any');
    };

    render() {
        const {
            className,
            classes,
            customerFilterValue,
            ticketFilterValue,
            t,
        } = this.props;

        return (
            <Toolbar className={cx(classes.root, className)}>
                <FilterSelect
                  label={`${t('payoutList.filters.customer')}:`}
                  loadOptions={this.loadCustomerOptions}
                  value={customerFilterValue}
                  renderValue={this.renderSelectedCount}
                  onChange={this.handleCustomerFilterChange}
                  formatOptionLabel={this.formatCustomerOptionLabel}
                  defaultOptions
                />
                <FilterSelect
                  label={`${t('payoutList.filters.ticket')}:`}
                  loadOptions={this.loadTicketOptions}
                  value={ticketFilterValue}
                  renderValue={this.renderSelectedCount}
                  onChange={this.handleTicketFilterChange}
                  formatOptionLabel={this.formatTicketOptionLabel}
                  defaultOptions
                />
                <Tooltip title={t('payoutList.filters.clearFilters')}>
                    <IconButton className={classes.clearButton} onClick={this.handleClear}>
                        <ClearIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    ...selectors.getAPIParams(state, props),
    customerFilterValue: selectors.getCustomerFilterValue(state, props),
    ticketFilterValue: selectors.getTicketFilterValue(state, props),
    search: selectors.parseSearchParams(state, props),
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    searchCustomers: (params: Object) => dispatch(searchCustomers(params)),
    searchTickets: (params: Object) => dispatch(searchTickets(params)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'FilterToolbar' }),
    withTranslation(),
    connector,
);

export default enhance(FilterToolbar);
