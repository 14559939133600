// @flow
import React, { Component } from 'react';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import { CancelToken } from 'axios';
import { Icon } from '@material-ui/core';
import Spinner from '../Spinner';
import theme from './Autocomplete.scss';

type Item = {
    label: string,
    value: any,
};

type Props = {
    className?: string,
    disabled?: boolean,
    label?: string,
    onChange?: (data: Object) => void,
    onClear?: () => void,
    onQueryChange?: (value: string) => void,
    onSearch?: (query: string) => void,
    onFocus?: () => void,
    onBlur?: () => void,
    placeholder?: string,
    source?: Array<Item>,
    suggestionMatch?: string | Function,
};

type State = {
    query: string,
    suggestions: Array<Item>,
    loading: boolean,
};

class Autocomplete extends Component<Props, State> {
    static defaultProps = {
        suggestionMatch: 'start',
    };

    constructor(props: Props) {
        super(props);

        this.match = debounce(this.match, 200);

        this.state = {
            loading: false,
            query: '',
            suggestions: [],
        };
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { query } = this.state;

        if (query !== prevState.query) {
            this.match();
        }
    }

    cancelSearch = () => {}; // default to no-op

    match = () => {
        const { source, suggestionMatch } = this.props;
        const { query: rawQuery } = this.state;
        const query = this.normalize(rawQuery);

        this.cancelSearch();

        // Don't execute match if input is empty
        if (query.length === 0) {
            return;
        }

        if (typeof suggestionMatch === 'function') {
            const promise = suggestionMatch(query);

            if (promise instanceof Promise) {
                // Set up a cancelToken so that we can cancel the operation
                let rejectPromise: (msg?: string) => void = () => {}; // default to no-op
                const cancelToken = new CancelToken((c: Function) => { this.cancelSearch = c; });
                cancelToken.promise.then((reason: Object) => { rejectPromise(reason.message); });

                const autocompletePromise = new Promise((resolve: Function, reject: Function) => {
                    promise
                        .then((suggestions?: ?Array<Item>) => {
                            // We assume that on a successful match, suggestions will be an array of objects
                            // with a label and value key. However, if the promise was rejected, all bets are off.
                            // In this case, let the user handle the error and just resolve our promise with
                            // an empty array
                            resolve(Array.isArray(suggestions) ? suggestions : []);
                        })
                        .catch(() => { /* in case the user did not handle the rejected promise */ })
                        .then(() => {
                            // Make rejectPromise a no-op so that we don't try to reject an already settled promise
                            rejectPromise = () => {};
                        });

                    rejectPromise = reject;
                });

                this.setState({ loading: true });
                autocompletePromise
                    .then((suggestions: Array<Item>) => {
                        this.setState({
                            suggestions,
                            loading: false,
                        });
                    })
                    .catch(() => { /* promise cancelled by us */ });
            }
        } else if (Array.isArray(source)) {
            let suggestions = [];

            switch (suggestionMatch) {
                case 'anywhere':
                    suggestions = source.filter(({ label }: Item): boolean => this.normalize(label).includes(query));
                    break;
                case 'word': {
                    const regex = new RegExp(`\\b${query}`, 'g');
                    suggestions = source.filter(({ label }: Item): boolean => regex.test(this.normalize(label)));
                    break;
                }
                case 'disabled':
                    suggestions = source.filter((): boolean => true);
                    break;
                case 'start':
                default:
                    suggestions = source.filter(({ label }: Item): boolean => this.normalize(label).startsWith(query));
                    break;
            }

            this.setState({ suggestions });
        }
    };

    handleClear = () => {
        const { onClear } = this.props;
        if (typeof onClear === 'function') onClear();

        this.cancelSearch();
        this.setState({
            query: '',
            loading: false,
            suggestions: [],
        });
    };

    handleChange = (value: any) => {
        const { onChange } = this.props;
        if (typeof onChange === 'function') onChange(value);

        this.cancelSearch();
        this.setState({
            query: '',
            loading: false,
            suggestions: [],
        });
    };

    handleBlur = () => {
        const { onBlur } = this.props;
        if (typeof onBlur === 'function') onBlur();
    };

    handleFocus = () => {
        const { onFocus } = this.props;
        if (typeof onFocus === 'function') onFocus();
    };

    handleQueryChange = (event: SyntheticEvent<>) => {
        if (event.target instanceof HTMLInputElement) {
            const { value } = event.target;
            const { onQueryChange } = this.props;

            if (typeof onQueryChange === 'function') {
                onQueryChange(value);
            }

            if (value.length > 0) {
                this.setState({ query: value });
            } else {
                this.setState({
                    query: '',
                    loading: false,
                    suggestions: [],
                });
            }
        }
    };

    normalize(value: string): string {
        return value.toLowerCase().trim();
    }

    renderSuggestions() {
        const { suggestions } = this.state;

        return (
            <ul className={theme.suggestions}>
                {suggestions.map((suggestion: Item, index: number) => (
                    <li
                      role="menuitem"
                      key={index}
                      className={theme.suggestion}
                      onClick={() => { this.handleChange(suggestion.value); }}
                    >
                        {suggestion.label}
                    </li>
                ))}
            </ul>
        );
    }

    render() {
        const { className, disabled, placeholder } = this.props;
        const { loading, query, suggestions } = this.state;

        const icon = query.length > 0
            ? <Icon className={theme.icon} onClick={this.handleClear}>clear</Icon>
            : <Icon className={theme.icon}>search</Icon>;

        const rootClassName = cx(theme.autocomplete, { [theme.disabled]: disabled }, className);

        return (
            <div className={rootClassName}>
                <div className={theme.input}>
                    <input
                      type="text"
                      disabled={disabled}
                      className={theme.inputElement}
                      placeholder={placeholder}
                      onChange={this.handleQueryChange}
                      value={query}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                    />
                    {loading ? <Spinner className={theme.icon} /> : icon}
                </div>
                {suggestions.length > 0
                    ? this.renderSuggestions()
                    : null
                }
            </div>
        );
    }
}

export default Autocomplete;
