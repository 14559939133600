// @flow
export const DELETE_COMMENT = 'g/ticketDetail/DELETE_COMMENT';
export const DELETE_COMMENT_SUCCESS = `${DELETE_COMMENT}_SUCCESS`;
export const DELETE_COMMENT_ERROR = `${DELETE_COMMENT}_ERROR`;

export default {
    DELETE_COMMENT,
    DELETE_COMMENT_ERROR,
    DELETE_COMMENT_SUCCESS,
};
