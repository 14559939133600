// @flow
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import type { $AxiosError, $AxiosXHR } from 'axios';
import { client as gigwalk } from '../../api/createGigwalkClient';
import types from './types';

export const fetchAPIConfigError = createAction(types.FETCH_API_CONFIG_ERROR);
export const fetchAPIConfigSuccess = createAction(types.FETCH_API_CONFIG_SUCCESS);
export const fetchAPIConfig = createAction(
    types.FETCH_API_CONFIG,
    (): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.versions.get()
                .then((res: $AxiosXHR<*>) => {
                    if (res && res.data && res.data.data.length && res.data.data[0]) {
                        dispatch(fetchAPIConfigSuccess(res.data.data[0]));
                    }
                })
                .catch((err: $AxiosError<*>) => {
                    dispatch(fetchAPIConfigError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export default {
    fetchAPIConfigError,
    fetchAPIConfigSuccess,
    fetchAPIConfig,
};
