// @flow
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { ticket as ticketSchema } from '../../../redux/entities/schemas';
import type { State as RootState } from '../../../redux/initialState';

const getEntities = (state: RootState): Object => state.entities;
const getTicketId = (state: RootState, props: Object): ?number => props.ticketId;

export const getTicket = createSelector(
    getEntities,
    getTicketId,
    (entities: Object, ticketId: ?number) => denormalize(ticketId, ticketSchema, entities),
);

export default {
    getTicket,
};
