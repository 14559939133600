// @flow
import React from 'react';
import Helmet from 'react-helmet';
import { Route, Switch } from 'react-router';
import waitForCondition from '../../components/waitForCondition';
import AddFunds from '../AddFunds';

export function Payments() {
    return (
        <div>
            <Helmet title="Payments" />
            <Switch>
                <Route path="/payments/:orgId/add-funds" component={AddFunds} />
            </Switch>
        </div>
    );
}

export default waitForCondition(() => global.hasOwnProperty('Stripe'), 100)(Payments);


// const onEnterHook = (nextState: NextState, replace: Function) => {
//     // Confirm user is a super admin and that the canAccessPayments feature flag is enabled
//     const { user, features } = store.getState().session;
//     if (!user || user.role !== USER_ROLES.SUPER_ADMIN || !features.canAccessPayments) {
//         replace('/');
//     }
// };
