// @flow
export default (theme: Object) => ({
    root: {
        fontFamily: 'ProximaNova',
        fontSize: 16,
        justifyContent: 'flex-start',

        '& > *': {
            margin: theme.spacing(0, 1),

            '&:first-child': { marginLeft: 0 },
            '&:last-child': { marginRight: 0 },
        },
    },
    clearButton: {
        marginLeft: 'auto',
        marginRight: [-12, '!important'],
        padding: 8,
    },
});
