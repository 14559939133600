// @flow
import React, { Component } from 'react';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import qs from 'qs';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { Button, CircularProgress, Paper, Tooltip } from '@material-ui/core';
import {
    Add as AddIcon,
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    Delete as DeleteIcon,
} from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter } from 'react-router';
import FilterToolbar from './containers/FilterToolbar';
import SearchToolbar from './containers/SearchToolbar';
import ProgressBar from './components/ProgressBar';
import SimplePane from '../../components/SimplePane';
import DataTable from '../../components/DataTable';
import { USER_ROLES } from '../../../browser/shared/constant/UserRoles';
import { format } from '../../../browser/shared/util/gigwalkApiErrorUtil';
import { ellipsify } from '../../../browser/shared/util/LangUtil';
import { stripBBCode } from '../../../browser/shared/util/RegExUtil';
import { stripTags } from '../../../browser/shared/util/gigwalkSanitize';
import * as dialog from '../../ducks/dialog';
import * as snackbar from '../../ducks/snackbar';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../redux/initialState';

type State = {
    loadingPage: boolean,
    fetchingData: boolean,
};

type OwnProps = {
    ...ContextRouter,
    classes: Object,
    t: TFunction,
};

type StateProps = {
    data: Object[],
    filters?: Object[],
    keywords?: string,
    limit?: number,
    offset?: number,
    oldProjectBuilder: boolean,
    pages: number,
    recordCount: number,
    search: {
        size?: number,
        page?: number,
        q?: string,
        sort?: string,
        order?: string,
    },
    sort?: Object[],
    user: ?Object,
};

type DispatchProps = {
    closeDialog: () => void,
    deleteProject: (subscriptionId: number) => Promise<void>,
    enqueueSnackbar: (message: string, options: Object) => void,
    fetchData: (filters: ?Object[], keywords: ?string, sort: ?Object[], limit: ?number, offset: ?number) => Promise<void>,
    loadFilters: (search: Object) => Promise<void>,
    openDialog: (name: string, props: Object) => void,
    runAutoassign: (subscriptionId: number) => Promise<void>,
};

type Props = OwnProps & StateProps & DispatchProps;

export class SubscriptionList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        // this._fetchData = debounce(this._fetchData, 500);
        this.state = {
            loadingPage: true,
            fetchingData: true,
        };
    }

    componentDidMount() {
        const { filters, keywords, search, sort, limit, loadFilters, offset } = this.props;
        const promises = [
            this._fetchData(filters, keywords, sort, limit, offset),
            loadFilters(search),
        ];

        Promise.all(promises)
            .then(() => {
                this.setState({
                    fetchingData: false,
                    loadingPage: false,
                });
            });
    }

    componentWillReceiveProps(nextProps: Props) {
        const { data, filters, keywords, sort, limit, offset, match } = this.props;
        const {
            data: nextData,
            filters: nextFilters,
            keywords: nextKeywords,
            sort: nextSort,
            limit: nextLimit,
            match: nextMatch,
            offset: nextOffset,
        } = nextProps;

        if (match.params.state !== nextMatch.params.state) {
            this.setState({ loadingPage: true });
        }

        // If any filter, sort, or pagination parameters changed, we should fetch new data.
        // Otherwise, if the table data has changed at all (for instance, an entity was deleted
        // from state), fetch the current page again.
        const shouldFetchData = !isEqual(nextFilters, filters)
            || !isEqual(nextSort, sort)
            || nextKeywords !== keywords
            || nextLimit !== limit
            || nextOffset !== offset
            || nextData.length !== data.length;

        if (shouldFetchData) {
            this.setState({ fetchingData: true });
            this._fetchData(nextFilters, nextKeywords, nextSort, nextLimit, nextOffset)
                .then(() => {
                    this.setState({
                        fetchingData: false,
                        loadingPage: false,
                    });
                });
        }
    }

    getMatchParams() {
        const { match } = this.props;
        return { orgId: '0', state: 'active', ...match.params };
    }

    handlePageChange = (page: number) => {
        const { location, history, search } = this.props;
        const query = page >= 0 ? { page: page + 1 } : {}; // Don't forget to convert from zero-based index!

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                { ...search, ...query },
                { addQueryPrefix: true }
            ),
        });
    };

    handlePageSizeChange = (pageSize: number) => {
        const { location, history, search } = this.props;
        const { size, ...query } = search;

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                pageSize ? { ...query, size: pageSize } : query,
                { addQueryPrefix: true }
            ),
        });
    };

    handleSortedChange = (sorted: Object[]) => {
        const { location, history, search } = this.props;
        const { sort, order, ...query } = search;
        const { id, desc } = sorted.length > 0 ? sorted[0] : {};

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                id ? { ...query, sort: id, order: desc ? 'desc' : 'asc' } : query,
                { addQueryPrefix: true }
            ),
        });
    };

    handleDeleteProject = (id: number) => {
        const { closeDialog, data, deleteProject, openDialog, t } = this.props;
        const draft = data.find((subscription) => subscription.id === id);

        if (draft) {
            const dialogProps = {
                actions: [
                    {
                        label: t('subscriptionList.no'),
                        onClick: () => closeDialog(),
                    },
                    {
                        label: t('subscriptionList.yes'),
                        onClick: () => {
                            closeDialog();
                            deleteProject(id);
                        },
                    },
                ],
                content: t('subscriptionList.confirmDelete', { title: draft.title }),
                onClose: () => closeDialog(),
                title: t('subscriptionList.deleteDraft'),
            };
            openDialog('confirm', dialogProps);
        }
    };

    handleRunAutoassign = (id: number) => {
        const { data, enqueueSnackbar, runAutoassign, t } = this.props;
        const project = data.find((subscription) => subscription.id === id);

        if (project) {
            runAutoassign(id)
                .then(() => {
                    enqueueSnackbar(t('subscriptionList.autoassignSuccess'), { variant: 'success' });
                })
                .catch(() => {
                    enqueueSnackbar(t('subscriptionList.autoassignError'), { variant: 'error' });
                });
        }
    };

    _fetchData = (filters: ?Object[], keywords: ?string, sort: ?Object[], limit: ?number, offset: ?number) => {
        const { enqueueSnackbar, fetchData } = this.props;
        return fetchData(filters, keywords, sort, limit, offset)
            .catch((err: $AxiosError<any>) => {
                const resp = err ? err.response : null;
                if (resp && resp.data && resp.data.gw_api_response) {
                    const message = format(resp.data.gw_api_response);
                    enqueueSnackbar(message, { variant: 'error' });
                }
            });
    };

    _getColumns() {
        const { classes, location, oldProjectBuilder, t } = this.props;
        const { state } = this.getMatchParams();

        const projectTitle = {
            Header: t('subscriptionList.projectTitle'),
            accessor: 'title',
            minWidth: 200,
            Cell: (props: Object) => {
                const { id, organizationId } = props.original;
                const pathname = (state === 'draft' && !oldProjectBuilder)
                    ? `/projects/${organizationId}/create/${id}`
                    : `/projects/${organizationId}/${state}/${id}`;
                const to = {
                    pathname,
                    state: {
                        breadcrumb: `${location.pathname}${location.search || ''}`,
                    },
                };
                return <Link to={to}>{props.value}</Link>;
            },
        };
        const description = {
            Header: t('subscriptionList.description'),
            accessor: 'description',
            minWidth: 200,
            Cell: (props: Object) => {
                const value = stripTags(stripBBCode(props.value || ''));
                return ellipsify(value, 150);
            },
        };
        const startDate = {
            Header: t('subscriptionList.startDate'),
            accessor: 'startDate',
            defaultSortDesc: true,
            Cell: (props: Object) => {
                const date = moment(props.value);
                if (!date.isValid()) {
                    return '';
                }
                return (
                    <div>
                        <div>{date.format('MMM DD YYYY')}</div>
                        <div style={{ color: '#aaaaaa' }}>{date.format('hh:mm A')}</div>
                    </div>
                );
            },
        };
        const endDate = {
            Header: t('subscriptionList.endDate'),
            accessor: 'endDate',
            defaultSortDesc: true,
            Cell: (props: Object) => {
                const date = moment(props.value);
                if (!date.isValid()) {
                    return '';
                }
                return (
                    <div>
                        <div>{date.format('MMM DD YYYY')}</div>
                        <div style={{ color: '#aaaaaa' }}>{date.format('hh:mm A')}</div>
                    </div>
                );
            },
        };
        const activeActions = {
            sortable: false,
            Header: t('subscriptionList.action'),
            accessor: 'id',
            Cell: (props: Object) => {
                const { autoassign } = props.original;

                if (!autoassign) {
                    return null;
                }

                return (
                    <Tooltip title={t('subscriptionList.runAutoassign')} placement="top">
                        <Button className={classes.action} onClick={() => { this.handleRunAutoassign(props.value); }}>
                            <AssignmentTurnedInIcon />
                        </Button>
                    </Tooltip>
                );
            },
        };
        const draftActions = {
            sortable: false,
            Header: t('subscriptionList.action'),
            accessor: 'id',
            Cell: (props: Object) => (
                <Tooltip title={t('subscriptionList.delete')} placement="top">
                    <Button className={classes.action} onClick={() => { this.handleDeleteProject(props.value); }}>
                        <DeleteIcon />
                    </Button>
                </Tooltip>
            ),
        };
        const execution = {
            Header: t('subscriptionList.execution'),
            accessor: 'execution',
            Cell: (props: Object) => <ProgressBar value={props.value} />,
        };
        const status = {
            Header: t('subscriptionList.status'),
            accessor: 'status',
            sortable: false,
            Cell: (props: Object) => t(`subscriptionList.${props.value}`),
        };
        const locations = { Header: t('subscriptionList.locations'), accessor: 'locations' };
        const creator = { Header: t('subscriptionList.creator'), accessor: 'creator' };
        const projectState = { Header: t('subscriptionList.state'), accessor: 'state' };

        switch (state) {
            case 'active':
                return [projectTitle, execution, status, startDate, endDate, locations, creator, activeActions];
            case 'draft':
                return [projectTitle, description, startDate, endDate, creator, draftActions];
            case 'archive':
                return [projectTitle, description, startDate, endDate, creator, projectState];
            default:
                return [];
        }
    }

    renderNoData() {
        const { classes, oldProjectBuilder, t } = this.props;
        const { orgId, state } = this.getMatchParams();

        return (
            <div className={classes.noData}>
                <h1>{t(`subscriptionList.noData.${state}.title`)}</h1>
                <p>{t(`subscriptionList.noData.${state}.message`)}</p>
                <a href="https://bit.ly/gigwalkpostagig" target="_blank">Watch this video to learn how to build a project</a>
                <br/>
                <Button
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to={oldProjectBuilder ? `/projects/${orgId}/draft/new` : `/projects/${orgId}/create/new`}
                >
                    <AddIcon className={classes.addIcon} />
                    {t('subscriptionList.newProject')}
                </Button>
            </div>
        );
    }

    render() {
        const { fetchingData, loadingPage } = this.state;
        const { classes, data, pages, recordCount, search, user } = this.props;
        const { order, q, sort } = search;
        const page = Math.max(parseInt(search.page, 10) - 1, 0) || 0;
        const pageSize = Math.max(parseInt(search.size, 10), 0) || 10;

        const columns = this._getColumns();
        const sorted = [{
            id: sort || 'startDate',
            desc: order !== 'asc',
        }];

        if (loadingPage) {
            return (
                <SimplePane>
                    <div className={classes.loading}>
                        <CircularProgress />
                    </div>
                </SimplePane>
            );
        }

        // todo: use org stats to determine if there is no data available
        // If a keyword is provided, the page_count will correspond to the result set filtered
        // by that keyword. This does NOT reflect the total page_count of subscriptions
        // for the user's org, so we can't rely on this field to render the NoData component
        const isPA = user && user.role === USER_ROLES.PLATFORM_ADMIN;
        if (!fetchingData && recordCount === 0 && pages === 0 && q == null && !isPA) {
            return (
                <SimplePane>
                    {this.renderNoData()}
                </SimplePane>
            );
        }

        return (
            <SimplePane>
                <div className={classes.toolbars}>
                    <Route render={(props: ContextRouter) => <SearchToolbar {...props} className={classes.search} />} />
                    <Route render={(props: ContextRouter) => <FilterToolbar {...props} className={classes.filters} />} />
                </div>
                <Paper elevation={2}>
                    <DataTable
                      sortable
                      columns={columns}
                      count={recordCount}
                      data={data}
                      loading={fetchingData}
                      onPageChange={this.handlePageChange}
                      onPageSizeChange={this.handlePageSizeChange}
                      onSortedChange={this.handleSortedChange}
                      pageSizeOptions={[10, 20, 50]}
                      defaultPageSize={10}
                      page={page}
                      pages={pages}
                      pageSize={pageSize}
                      sorted={sorted}
                    />
                </Paper>
            </SimplePane>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    ...selectors.getAPIParams(state, props),
    data: selectors.getTableData(state, props),
    pages: state.subscriptionList.metadata.page_count || 0,
    oldProjectBuilder: state.session.features.oldProjectBuilder,
    recordCount: state.subscriptionList.metadata.record_count,
    search: selectors.parseSearchParams(state, props),
    user: state.session.user,
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    closeDialog: () => dispatch(dialog.actions.close()),
    deleteProject: (subscriptionId) => dispatch(actions.deleteProject(subscriptionId)),
    enqueueSnackbar: (message: string, options: Object) => dispatch(snackbar.actions.enqueue(message, options)),
    fetchData: (filters: ?Object[], keywords: ?string, sort: ?Object[], limit: ?number, offset: ?number) => (
        dispatch(actions.fetchData(filters, keywords, sort, limit, offset))
    ),
    loadFilters: (search: Object) => dispatch(actions.loadFilters(search)),
    openDialog: (name: string, props: Object) => dispatch(dialog.actions.open(name, props)),
    runAutoassign: (subscriptionId) => dispatch(actions.runAutoassign(subscriptionId)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'SubscriptionList' }),
    withTranslation(),
    connector
);

export default enhance(SubscriptionList);
