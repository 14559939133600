// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { Redirect, Route, Switch, matchPath } from 'react-router';
import type { ComponentType } from 'react';
import type { FormProps } from 'redux-form';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { TFunction } from 'react-i18next';
import Workforce from './containers/Workforce';
import WorkAllocation from './containers/WorkAllocation';
import Approval from './containers/Approval';
import Groups from './containers/Groups';
import Certifications from './containers/Certifications';
import entitySelector from '../../../../redux/entities/entitySelector';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & FormProps & {
    t: TFunction,
};

type StateProps = {
    disableCertifications: boolean,
    needsPublicWorkforce: boolean,
};

export type Props = OwnProps & StateProps;

export class People extends Component<Props> {
    componentDidMount() {
        this.computeLastPage(this.props);
    }

    componentWillReceiveProps(nextProps: Props) {
        this.computeLastPage(nextProps);
    }

    getPages() {
        const { disableCertifications, needsPublicWorkforce } = this.props;

        if (needsPublicWorkforce) {
            return disableCertifications ? [Workforce, WorkAllocation] : [Workforce, WorkAllocation, Certifications];
        }

        return [Workforce, WorkAllocation, Approval, Groups, Certifications];
    }

    computeLastPage(router: ContextRouter) {
        const { history, location, match } = router;

        // If location and history are out of sync, we're likely in the middle
        // of a page transition, so don't do anything.
        if (location.pathname !== history.location.pathname) {
            return;
        }

        const pageMatch = matchPath(location.pathname, { path: `${match.path}/:page?` });
        const currentPage = pageMatch ? pageMatch.params.page : '0';
        const lastPage = history.location.state ? history.location.state.lastPage : false;
        const pages = this.getPages();

        if (currentPage === `${pages.length - 1}` && !lastPage) {
            history.replace({ ...location, state: { lastPage: true } });
        }
    }

    render() {
        const { history, match, onSubmit } = this.props;
        const pages = this.getPages();

        const indexRedirect = history.location.state && history.location.state.lastPage
            ? { pathname: `${match.url}/${pages.length - 1}`, state: { lastPage: true } }
            : { pathname: `${match.url}/0` };

        return (
            <Switch>
                {pages.map((Page: ComponentType<*>, index: number) => (
                    <Route
                      key={index}
                      path={`${match.path}/${index}`}
                      render={(props: ContextRouter) => <Page {...props} onSubmit={onSubmit} />}
                    />
                ))}
                <Redirect to={indexRedirect} />
            </Switch>
        );
    }
}

const valueSelector = formValueSelector('projectBuilder');
const subscriptionSelector = entitySelector('subscriptions');
const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const subscription = subscriptionSelector(state, props.match.params.subscriptionId);
    return {
        disableCertifications: subscription ? subscription.state === 'ACTIVE' && subscription.needs_core === true : false,
        needsPublicWorkforce: valueSelector(state, 'needsPublicWorkforce') || false,
    };
};

const connector: Connector<OwnProps, Props> = connect(mapStateToProps);
export default withTranslation()(connector(People));
