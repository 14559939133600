// @flow
export default (theme: Object) => ({
    root: {
        fontFamily: 'ProximaNova',
        fontSize: 16,
        justifyContent: 'flex-end',

        '& > *': {
            margin: theme.spacing(0, 1),

            '&:first-child': {
                marginLeft: 0,
            },

            '&:last-child': {
                marginRight: 0,
            },
        },
    },
    recordCount: {
        marginRight: 'auto',

        '& > *:not(:last-child)': {
            marginRight: theme.spacing(0.5),
        },
    },
    button: {
        margin: theme.spacing(0, 1),
        borderRadius: 4,
    },
    buttonContained: {
        boxShadow: 'none',

        '&$buttonDisabled': {
            backgroundColor: 'transparent',
            border: [[1, 'solid', '#e4e4e4']],
        },
    },
    buttonContainedPrimary: {
        border: [[1, 'solid', theme.palette.primary.main]],
    },
    buttonDisabled: {},
    icon: {
        // marginRight: theme.spacing(1),
        fontSize: 22,
    },
});
