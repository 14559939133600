// @flow
import { combineReducers } from 'redux';
import header, { init as headerInit } from '../containers/Header/duck';
import footer, { init as footerInit } from '../containers/Footer/duck';
import sideNav, { init as sideNavInit } from '../containers/SideNav/duck';
import type { State as HeaderState } from '../containers/Header/duck';
import type { State as FooterState } from '../containers/Footer/duck';
import type { State as SideNavState } from '../containers/SideNav/duck';

export type State = {
    footer: FooterState,
    header: HeaderState,
    sideNav: SideNavState,
};

export const init: State = {
    footer: footerInit,
    header: headerInit,
    sideNav: sideNavInit,
};

export default combineReducers({
    footer,
    header,
    sideNav,
});
