// @flow weak
import libphonenumber from 'libphonenumber-node';
import { getAlpha2Code } from 'i18n-iso-countries';
import { USER_ROLES } from '../../../../shared/constant/UserRoles';
import questionTpl from '../../../templates/ticket/widgets/question/Question.hbs';
import checkboxTpl from '../../../templates/ticket/widgets/question/Checkboxes.hbs';
import dateTpl from '../../../templates/ticket/widgets/question/Date.hbs';
import dateTimeTpl from '../../../templates/ticket/widgets/question/DateTime.hbs';
import itemGridTpl from '../../../templates/ticket/widgets/question/ItemGrid.hbs';
import numberTpl from '../../../templates/ticket/widgets/question/Number.hbs';
import phoneNumberTpl from '../../../templates/ticket/widgets/question/PhoneNumber.hbs';
import textTpl from '../../../templates/ticket/widgets/question/Text.hbs';
import barcodeTpl from '../../../templates/ticket/widgets/question/Barcode.hbs';
import timeTpl from '../../../templates/ticket/widgets/question/Time.hbs';
import Widget from './Widget';
import CondExpression from '../../../../shared/data_structures/CondExpression';
import PopupConfirm from '../../../../shared/view/PopupConfirm';
import LabeledDateTimePicker from '../../../../shared/components/DateTime/LabeledDateTimePicker';
import ProjectConstant from '../../../../shared/constant/ProjectConstant';
import DateUtil from '../../../../shared/util/DateUtil';
import LangUtil from '../../../../shared/util/LangUtil';
import RegExUtil from '../../../../shared/util/RegExUtil';
import BrowserUtil from '../../../../shared/util/BrowserUtil';
import { stripTags } from '../../../../shared/util/gigwalkSanitize';

// Roles that can edit answer on behalf of worker
const delegateRoles = [USER_ROLES.SUPER_ADMIN, USER_ROLES.SELF_SERVICE, USER_ROLES.PLATFORM_ADMIN];

/**
 * @class (View) Question
 * @constructor
 */
export default Widget.extend({
    /**
     * @type string
     * @property className
     */
    className: '',

    /**
     * @property events
     */
    events: {
        'click [data-selected][data-value]': 'selectTime',
        'click [data-action="magnify"]': 'magnify',
        'click [data-action="view-metadata"]': 'viewTargetMetadata',
        'keypress [data-input]': 'restrictInput',
        'keyup [data-barcode]': 'onBarcodeEnter',
        'click [data-target="validationMark"]': 'disableClick',
        'click input[type="radio"]': 'handleRadioClick',

        // TODO: Make all widgets consistent with regards to enabling/disabling save button when changes are detected
        'focusin [data-input]': 'clearInvalid',
        'keydown [data-action="findAnswer"]': 'onFindAnswer',
    },

    DATA_CHILD: {
        datepicker: LabeledDateTimePicker,
    },

    datepickerType: '',

    /**
     * @method render
     */
    render() {
        /* O one ring to rule them all O */

        const widgets: {
            BARCODE: (data?: Object) => string,
            CHECKBOXES: (data?: Object) => string,
            CURRENCY: (data?: Object) => string,
            DATE: (data?: Object) => string,
            DATE_TIME: (data?: Object) => string,
            FREE_TEXT: (data?: Object) => string,
            ITEM_GRID: (data?: Object) => string,
            MULTIPLE_CHOICE: (data?: Object) => string,
            MULTI_SELECT: (data?: Object) => string,
            NUMBER: (data?: Object) => string,
            PHONE_NUMBER: (data?: Object) => string,
            TIME: (data?: Object) => string,
        } = {
            CHECKBOXES: checkboxTpl,
            MULTI_SELECT: checkboxTpl,
            DATE: dateTpl,
            DATE_TIME: dateTimeTpl,
            MULTIPLE_CHOICE: itemGridTpl,
            ITEM_GRID: itemGridTpl,
            NUMBER: numberTpl,
            PHONE_NUMBER: phoneNumberTpl,
            CURRENCY: numberTpl,
            FREE_TEXT: textTpl,
            BARCODE: barcodeTpl,
            TIME: timeTpl,
        };
        const multipleChoices: {CHECKBOXES: string, ITEM_GRID: string, MULTIPLE_CHOICE: string, MULTI_SELECT: string} = {
            MULTIPLE_CHOICE: '[o]',
            MULTI_SELECT: '[o]',
            ITEM_GRID: '[x]',
            CHECKBOXES: '[x]',
        };

        const { index, ticket, user } = this.props;
        const dataTypeID = this.instruction.get('data_type_id');
        const dataType = ticket.data_type_map[dataTypeID];
        const attachments = this.groupAttachments(dataType.attachments);
        const { targets_enabled: targetsEnabledConfig } = user.organization.config;

        if (!(dataType.value_type in multipleChoices)) {
            dataType.questions.propositions = [];
        } else {
            this.$el.toggleClass('searchable', Boolean(dataType.questions.propositions.length > 10));
        }

        // let's generate a phone number example to use as a placeholder
        let countryCode = ticket.location.country;
        if (countryCode.length !== 2) {
            const name = countryCode.replace(/^(United States)|(United States of America)$/i, 'United States of America');
            countryCode = getAlpha2Code(name, 'en');
        }

        let formattedPhoneNumber = '';
        const examplePhoneNumber = libphonenumber['i18n.phonenumbers']
            .PhoneNumberUtil
            .getInstance()
            .getExampleNumber(countryCode);

        if (examplePhoneNumber && examplePhoneNumber.values_) {
            Object.keys(examplePhoneNumber.values_).forEach((val: string) => {
                formattedPhoneNumber += examplePhoneNumber.values_[val];
            });
            this.text.placeholders = {
                ...this.text.placeholders,
                phone_number: libphonenumber.format(formattedPhoneNumber, countryCode),
            };
        }

        const uniqid: string = _.uniqueId();
        let timeUnit: {unit: string} = {
            unit: 'seconds',
        };
        const hbsCfg = {
            ...this.text,
            questionNumber: index != null ? index + 1 : null,
            titleBar: dataType.value_type !== 'BARCODE' ? this.text.question : this.text.enter_barcode,
            target: this.target.name,
            title: dataType.description,
            body: LangUtil.replaceTitle((dataType.questions.question_text || ''), this.target.name),
            answers: (() => {
                const answers = [];
                for (let len = dataType.questions.propositions.length, i: number = !len ? -1 : 0; i < len; i++) { // eslint-disable-line no-plusplus
                    const value = this.getAnswer(i);
                    const a = dataType.questions.propositions[i] || value;

                    if (dataType.value_type === 'TIME' && this.di.data_item_value && this.di.data_item_value[0]) {
                        timeUnit = DateUtil.secondsToMaxUnit(this.di.data_item_value[0]);
                    } else {
                        timeUnit = { unit: 'seconds', time: 0 };
                    }

                    /* CSMK CSMK CSMK CSMK CSMK CSMK CSMK CSMK */
                    if (timeUnit.unit === 'seconds') {
                        timeUnit.unit = 'minutes';
                        timeUnit.time = Math.round(timeUnit.time / 60) || 0;
                    }
                    /* CSMK CSMK CSMK CSMK CSMK CSMK CSMK CSMK */

                    answers.push({
                        checked: Boolean(value),
                        exception: this.isException(i),
                        text: timeUnit.time || a,
                        timeUnit: timeUnit.unit,
                        _answerID: uniqid,
                        currency: dataType.value_type === 'CURRENCY',
                    });
                }

                return answers;
            })(),
            complete: Boolean(this.di.data_item_answered),
            images: attachments.images,
            files: attachments.files,
            hasMetadata: !!this.target.metadata && Object.keys(this.target.metadata).length > 0,
            targetsEnabled: targetsEnabledConfig,
        };

        this.$el.html(questionTpl(hbsCfg));
        this.$el.find('[data-target="questionTitle"]').off('click').on('click', 'a', BrowserUtil.openNewWindow);
        this.$choices = this.$el.find('[data-target="choices"]:first').html(widgets[dataType.value_type](hbsCfg));

        this.$els = {
            validationHint: this.$el.find('[data-target="validationHint"]'),
            input: this.$el.find('[data-input]'),
        };

        this.timeUnit = timeUnit;

        this.datepickerType = dataType.value_type; // used for datepicker type in getChildOptions

        this.renderChildren();
        this.datePickersInit(dataType.value_type);
        this.timeSelectsInit();
        this.barcodeInit();

        this._super();
    },

    /**
     * @method getChildOptions
     * @param {string} childName
     * @returns {Object} options
     */
    getChildOptions(childName: string) {
        const options = this._super();
        if (childName === 'datepicker') {
            switch (this.datepickerType) {
                case ProjectConstant.VALUE_TYPE.DATE:
                    Object.assign(options, {
                        minDate: 1,
                        mode: 'date',
                        dateFormat: 'yyyy-mm-dd',
                        inputProps: {
                            timeFormat24Hour: false,
                            readOnly: false,
                        },
                    });
                    break;
                case ProjectConstant.VALUE_TYPE.DATE_TIME:
                    Object.assign(options, {
                        minDate: 1,
                        mode: 'datetime',
                        dateFormat: 'yyyy-mm-dd',
                        inputProps: {
                            timeFormat24Hour: DateUtil.is24HoursClock(),
                            readOnly: false,
                        },
                    });
                    break;
                default:
                    break;
            }
        }

        return options;
    },

    /**
     * @method datePickersInit
     */
    datePickersInit(valueType: string) {
        const { ticket, user } = this.props;
        const { timing_template_id: timingTemplateId } = user.organization.config;

        switch (valueType) {
            case ProjectConstant.VALUE_TYPE.DATE:
                this.children.datepicker.empty();
                if (this.di.data_item_value && this.di.data_item_value[0]) {
                    const date = moment(this.di.data_item_value[0]).format('x');
                    this.children.datepicker.setValue(date);
                }

                // CSMK CSMK CSMK CSMK CSMK Timing Template Restriction
                if (this.templateID && this.templateID === timingTemplateId) {
                    const isInTheFuture = moment(ticket.start_date).startOf('day').isAfter(moment());
                    if (!isInTheFuture) {
                        this.children.datepicker.setMaxDate(moment().endOf('day'));
                        this.children.datepicker.setMinDate(moment(ticket.start_date).startOf('day'));
                    }

                    this.children.datepicker.disable(isInTheFuture);
                }

                // CSMK CSMK CSMK CSMK CSMK Timing Template Restriction

                break;
            case ProjectConstant.VALUE_TYPE.DATE_TIME: {
                this.children.datepicker.empty();
                if (this.di.data_item_value && this.di.data_item_value[0]) {
                    // Convert from utc to ticket's timezone, then format as a string without the timezone
                    // information attached. The underlying datepicker that LabeledDateTimePicker uses assumes the
                    // provided date is a local datetime will convert to UTC for internal use.
                    const dateTime = moment.tz(this.di.data_item_value[0], ticket.location.tzid);
                    this.children.datepicker.setValue(moment(dateTime.format('YYYY-MM-DDTHH:mm:ss')));
                }

                break;
            }
            default:
                break;
        }
    },

    /**
     * @method timeSelectsInit
     */
    timeSelectsInit() {
        const $seli = this.$el.find(`[data-selected][data-value="${(this.timeUnit.unit || false)}"]:first`);

        if (!$seli || !$seli.length || $seli.hasClass('disabled')) {
            return;
        }

        const $siblings = $seli.siblings();
        const $dropdown = $seli.closest('[data-action="dropdown"]');

        $seli.attr('data-selected', 'true');
        $siblings.attr('data-selected', 'false');
        $dropdown.find('[data-target="selected"]:first').html($seli.text());
    },

    /**
     * @method getValidUPCs
     * @returns {Array}
     */
    getValidUPCs() {
        const validUPCs = _.isString(this.target.metadata.upc_scan_key) ? this.target.metadata.upc_scan_key.split('|') : [];
        return _.compact(validUPCs);
    },

    /**
     * @method barcodeInit
     */
    barcodeInit() {
        const { ticket } = this.props;
        const dataTypeID = this.instruction.get('data_type_id');
        const dataType = ticket.data_type_map[dataTypeID];

        if (dataType.value_type !== 'BARCODE') {
            return;
        }

        if (this.di.data_item_value && this.di.data_item_value[0]) {
            this.validateBarcode(this.di.data_item_value[0]);
        }

        if (!this.getValidUPCs().length) {
            this.$el.find('.validation').hide();
        }
    },

    /**
     * @method selectTime
     * @param e
     */
    selectTime(e: JQueryEventObject) {
        const $seli = $(e.currentTarget);

        if (!$seli || !$seli.length || $seli.hasClass('disabled')) {
            return;
        }

        const $siblings = $seli.siblings();
        const $dropdown = $seli.closest('[data-action="dropdown"]');

        $seli.attr('data-selected', 'true');
        $siblings.attr('data-selected', 'false');
        $dropdown.find('[data-target="selected"]:first').html($seli.text());
    },

    /**
     * @method onFindAnswer
     * @param e
     */
    onFindAnswer(e: JQueryEventObject) {
        const { ticket } = this.props;
        const $el = $(e.currentTarget);
        const dataTypeID = this.instruction.get('data_type_id');
        const dataType = ticket.data_type_map[dataTypeID];

        clearTimeout(this.answerTimeout);
        this.answerTimeout = setTimeout(() => {
            const str = $el.val().toLowerCase();
            const i = _.findIndex(dataType.questions.propositions, (answer: string) => answer.toLowerCase().indexOf(str) > -1);

            const answerHeight: number = (this.$choices.find('.answers').height() / dataType.questions.propositions.length);
            this.$choices.scrollTop(str.length ? (i * answerHeight) : 0);
        }, 17);
    },

    /**
     * @method getAnswer
     * @param index
     * @returns {string}
     */
    getAnswer(index: number): string | boolean {
        const { ticket } = this.props;
        const dataTypeID = this.instruction.get('data_type_id');
        const dataType = ticket.data_type_map[dataTypeID];

        /* O one ring, to bring them all O */

        const ind: number = index > -1 ? index : 0;

        let result: string | boolean = '';
        if (this.di.data_item_value) {
            // has a set of possible answe  rs, the user chose one
            if (dataType.questions.propositions[ind]) {
                // the user chose the answer
                result = this.di.data_item_value.indexOf(dataType.questions.propositions[ind]) > -1;
            } else {
                // the user entered an answer
                result = this.di.data_item_value[ind];
            }
        }

        return result;
    },

    /**
     * @method onBarcodeEnter
     */
    onBarcodeEnter() {
        this.validateBarcode();
    },

    /**
     * @method validateBarcode
     */
    validateBarcode(barcodePrm: Object): boolean {
        let barcode = barcodePrm || this.$el.find('[data-barcode]').val();
        if (!_.isString(barcode)) {
            barcode = barcode.toString();
        }

        const { ticket, user } = this.props;
        const dataType = ticket.data_type_map[this.instruction.get('data_type_id')];
        const canSaveBlankAnswer = delegateRoles.includes(user.role) && dataType.is_required === false;
        if (!barcode && canSaveBlankAnswer) {
            return true;
        }

        barcode = barcode.trim();

        this.$els.validationHint.html('');

        let result: boolean = false;
        if (barcode) {
            const validUPCs = this.getValidUPCs();

            if (!validUPCs.length) {
                result = true;
            } else {
                const isValid = _.any(validUPCs, (upc: string) => upc.trim().toLowerCase() === barcode.toLowerCase());

                this.$el.find('[data-target="validationMark"]').prop('checked', isValid);
                this.$els.validationHint.html(isValid ? this.text.valid : this.text.invalid);
                result = isValid;
            }
        }

        return result;
    },

    sanitizeFreeText(): boolean {
        const input = this.$el.find('[data-input]');
        const value = input.val();
        const sanitizedValue = stripTags(value);
        input.val(sanitizedValue);
        return !!sanitizedValue;
    },

    handleRadioClick(event: JQueryEventObject) {
        const { target } = event;
        const { ticket, user } = this.props;

        if (target instanceof HTMLInputElement) {
            const dataType = ticket.data_type_map[this.instruction.get('data_type_id')];
            const canSaveBlankAnswer = delegateRoles.includes(user.role) && dataType.is_required === false;
            const isChecked = target.dataset.checked === 'true';

            // Set the data-checked attribute to false for all radio inputs before updating
            // the checked state of the event target
            const step = target.closest('.item-grid');
            const inputs = step ? step.querySelectorAll('input') : [];
            for (let i = 0; i < inputs.length; i += 1) {
                inputs[i].dataset.checked = 'false';
            }

            if (isChecked && canSaveBlankAnswer) {
                target.checked = false;
                target.dataset.checked = 'false';
            } else {
                target.checked = true;
                target.dataset.checked = 'true';
            }
        }
    },

    /**
     * Restricts invalid characters from being entered into input fields. Behavior is dependent on question type
     * @method checkInput
     */
    restrictInput(event: JQueryEventObject): boolean {
        const { ticket } = this.props;
        const $target = $(event.target);
        const value = $target.val().trim();
        const dataTypeID = this.instruction.get('data_type_id');
        const dataType = ticket.data_type_map[dataTypeID];
        const code: number = (typeof event.keyCode === 'number') && event.keyCode ? event.keyCode : event.which;
        const char = String.fromCharCode(code);
        const buffer = value + char;

        switch (dataType.value_type) {
            // Accepts positive/negative numbers
            case ProjectConstant.VALUE_TYPE.NUMBER:
                if (!RegExUtil.positiveAndNegativeNumber(buffer) && code !== 8) {
                    event.preventDefault();
                }

                break;

            // Only accepts postive numbers
            case ProjectConstant.VALUE_TYPE.TIME:
            case ProjectConstant.VALUE_TYPE.CURRENCY:

                if (!RegExUtil.positiveNumberOnly(buffer) && code !== 8) {
                    event.preventDefault();
                }

                break;
            default:
                break;
        }

        return true;
    },

    /**
     * @method disableClick
     * @returns {boolean}
     */
    disableClick(e: JQueryEventObject): boolean {
        e.preventDefault();
        e.stopImmediatePropagation();
        return false;
    },

    /**
     * Validates answer before submission
     * @method validateSave
     * @returns {boolean}
     */
    validateSave(): boolean {
        const { ticket, user } = this.props;
        const dataType = ticket.data_type_map[this.instruction.get('data_type_id')];
        const canSaveBlankAnswer = delegateRoles.includes(user.role) && dataType.is_required === false;

        let countryCode = ticket.location.country;
        if (countryCode.length !== 2) {
            const name = countryCode.replace(/^(United States)|(United States of America)$/i, 'United States of America');
            countryCode = getAlpha2Code(name, 'en');
        }

        this.$els.validationHint.html('');

        let pass = true;
        let validationHint = '';
        let answer = this.getValue().data_item_value;

        if (!_.isArray(answer)) {
            answer = [answer];
        }

        switch (dataType.value_type) {
            case ProjectConstant.VALUE_TYPE.PHONE_NUMBER:
                pass = _.all(answer, (value: Object) => {
                    if (!value && canSaveBlankAnswer) {
                        return true;
                    }

                    const formattedPhoneNumber = libphonenumber.format(value, countryCode);
                    if (formattedPhoneNumber && libphonenumber.isValid(formattedPhoneNumber)) {
                        this.$els.input.val(libphonenumber.format(formattedPhoneNumber, 'e164'));
                        return true;
                    }

                    validationHint = `${this.text.validationError.phone_number}: ${libphonenumber.format(this.text.placeholders.phone_number, 'e164')}`;
                    return false;
                });
                break;
            case ProjectConstant.VALUE_TYPE.NUMBER:
            case ProjectConstant.VALUE_TYPE.CURRENCY:
                pass = _.all(answer, (value: number | string) => (
                    !value && canSaveBlankAnswer ? true : this._validateNumber(value, dataType)
                ), this);
                break;

            case ProjectConstant.VALUE_TYPE.TIME:
                pass = _.all(answer, (value: Object) => {
                    if (!value && canSaveBlankAnswer) {
                        return true;
                    }

                    const val: number = parseFloat(value);
                    if (Number.isNaN(Number(value)) || Number.isNaN(val)) {
                        validationHint = this.text.validationError.not_a_number;
                        return false;
                    }

                    if (val < 0) {
                        validationHint = this.text.validationError.negative_number;
                        return false;
                    }

                    return true;
                }, this);

                break;

            default:
                break;
        }

        // if there is not already an error displayed
        if (!this.$els.validationHint.html() && validationHint) {
            this.$els.validationHint.html(validationHint);
        }

        return pass;
    },

    /**
     * @method isException
     * @param i
     * @returns {boolean}
     */
    isException(i: number): boolean {
        const { ticket } = this.props;
        const dataTypeID = this.instruction.get('data_type_id');
        const dataType = ticket.data_type_map[dataTypeID];

        if (i < 0) {
            return false;
        }

        if (!dataType.expected_value_expression) {
            return true;
        }

        function isExpected(obj: number, ind: void): boolean {
            function isList(arg: Object): boolean {
                const type: string = Object.prototype.toString.call(arg);
                return (type === '[object Array]' || type === '[object Object]');
            }

            _.chain(obj)
                .values()
                .each((item: Object) => {
                    if (item[0] === '$data_item_value' && item[1] === dataType.questions.propositions[ind]) {
                        return true;
                    }

                    if (isList(item)) {
                        return isExpected(obj);
                    }
                });

            return false;
        }

        return !isExpected(i);
    },

    /**
     * @method _validateNumber
     * @param {*} answerParam answer
     * @param {*} dataTypeParam data type
     * @returns {boolean} is valid
     * @private
     */
    _validateNumber(answerParam: Object, dataTypeParam: Object): boolean {
        let dataType = dataTypeParam;
        const answer: number = parseFloat(answerParam);

        if (Number.isNaN(answer)) {
            this.$els.validationHint.html(this.text.validationError.not_a_number);
            return false;
        }

        if (!dataType.expected_value_expression) {
            return true;
        }

        dataType = this._transformBetweenExclusive(dataType);
        const condExpression = new CondExpression(dataType.expected_value_expression);
        const isValid: boolean = condExpression.evaluate([answer]);
        let message: string;

        if (condExpression.value.operator === CondExpression.OPERATORS.AND) {
            const isBoundaryFlexible = dataType.questions.data_boundary_flexible || false;
            const limits = {
                min_value: dataType.expected_value_expression.$and[0].$gte[1],
                max_value: dataType.expected_value_expression.$and[1].$lte[1],
            };

            switch (true) {
                case !isBoundaryFlexible && !isValid:
                    message = this._formatMessage(this.text.validationError.not_in_range, limits);
                    this.$els.validationHint.html(message);
                    return false;
                case isBoundaryFlexible && !isValid:
                    message = this._formatMessage(this.text.validationError.flexible_range, limits);
                    this._displayBoundaryConfirmation(message, answer);
                    return false;
                default:
                    return true;
            }
        } else if (condExpression.value.operator === CondExpression.OPERATORS.EQ && !isValid) {
            message = this._formatMessage(this.text.validationError.not_exact_number, {
                number: condExpression.value.expected_value,
            });
            this.$els.validationHint.html(message);
        }

        return isValid;
    },

    /**
     * @method formatMessage
     * @private
     */
    _formatMessage(str: Object, obj: Object) {
        let result = str;
        _.chain(obj)
            .keys()
            .each((key: any) => {
                result = result.replace(`{${key}}`, obj[key]);
            });
        return result;
    },

    /**
     * @method _destroyPopup
     * @private
     */
    _removePopup() {
        if (this.popup) {
            this.popup.remove();
        }

        this.$els.input.removeClass('invalid');
    },

    /**
     * @method _displayBoundaryConfirmation
     * @private
     */
    _displayBoundaryConfirmation(message: string, answer: string) {
        const localisation = this.text.confirmCancel;
        this.popup = new PopupConfirm({
            text: {
                action: message,
                yes: localisation.yes,
                no: localisation.no,
                subject: this._formatMessage(localisation.your_value, { value: answer }),
            },
            onSubmit: () => {
                this.onSave(this.getValue(), this.$save, () => {
                    this._removePopup();
                });
            },

            onHide: _.bind(() => {
                this.$els.input.val('');
                this.$els.input.focus();
                this._removePopup();
            }, this),
        });
    },

    /**
     * Transform the expression to be inclusive to be able to use CondExpression to evaluate it
     * @method _transformBetweenExclusive
     * @param {*} dataType Data Type
     * @returns {*} Data Type transformed
     * @private
     */
    _transformBetweenExclusive(dataType: Object) {
        const betweenExpr = dataType.expected_value_expression.$and;

        // if it isn't a between rule then return the expression
        if (!betweenExpr) {
            return dataType;
        }

        if (betweenExpr[0].$gt) {
            betweenExpr[0] = {
                [CondExpression.OPERATORS.GTE]: betweenExpr[0].$gt,
            };
        }

        if (betweenExpr[1].$lt) {
            betweenExpr[1] = {
                [CondExpression.OPERATORS.LTE]: betweenExpr[1].$lt,
            };
        }

        return dataType;
    },

    /**
     * @method getValue
     */
    getValue($el: JQuery) {
        const { ticket } = this.props;
        const dataTypeID = this.instruction.get('data_type_id');
        const dataType = ticket.data_type_map[dataTypeID];
        let result = this._super.apply(this, [$el]);
        const data = [];
        let date: moment$Moment | string | boolean;
        switch (dataType.value_type) {
            case ProjectConstant.VALUE_TYPE.DATE:
                date = this.children.datepicker.getValue();

                if (date && date.isValid()) {
                    date = date.format('YYYY-MM-DDT00:00:00');
                    data.push(date);
                } else {
                    date = false;
                }

                result = _.extend(result, {
                    data_item_answered: !!date,
                    data_item_value: data,
                });

                break;
            case ProjectConstant.VALUE_TYPE.DATE_TIME: {
                const _date = this.children.datepicker.getValue();
                date = moment.tz(_date.format('YYYY-MM-DDTHH:mm:ss'), ticket.location.tzid);

                if (date && date.isValid()) {
                    date = date.utc().format('YYYY-MM-DDTHH:mm:ssZ');
                    data.push(date);
                } else {
                    date = false;
                }

                result = _.extend(result, {
                    data_item_answered: !!date,
                    data_item_value: data,
                });
                break;
            }
            default:
                break;
        }
        return result;
    },

    setReadMode() {
        this._super();

        const { ticket, user } = this.props;
        const { timing_template_id: timingTemplateId } = user.organization.config;

        const isAssignee = user.id === ticket.assigned_customer_id;
        let isReadOnly = this.readMode;

        // CSMK CSMK CSMK CSMK CSMK CSMK
        if (this.templateID && this.templateID === timingTemplateId) {
            isReadOnly = (!isAssignee || (ticket.status === 'SUBMITTED' && moment().subtract(3, 'weeks').isAfter(moment(ticket.due_date))));
        }
        // CSMK CSMK CSMK CSMK CSMK CSMK

        const dataType = ticket.data_type_map[this.instruction.get('data_type_id')];

        // disables datepicker
        // read-only parent class does not take effect on child datepicker, thus disable it with child component
        if (dataType.value_type === ProjectConstant.VALUE_TYPE.DATE_TIME || dataType.value_type === ProjectConstant.VALUE_TYPE.DATE) {
            this.children.datepicker.disable(isReadOnly);
        }
    },
});
