var Handlebars = require('/tmp/build_c41768ab/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <button type=\"submit\" class=\"c-button\" data-search-submit>"
    + container.escapeExpression(((helper = (helper = helpers.submit || (depth0 != null ? depth0.submit : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"submit","hash":{},"data":data}) : helper)))
    + "</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <ul data-search-results class=\"c-dropdown__content\"></ul>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"c-search__action\">\n    <input type=\"text\" placeholder=\""
    + container.escapeExpression(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"placeholder","hash":{},"data":data}) : helper)))
    + "\" data-search-input class=\"c-search__input\">\n    <a data-search-clear role=\"button\" class=\"c-search__action-icon c--clear\">Clear</a>\n    <span data-search-icon class=\"c-search__action-icon c--search\">Search</span>\n    <span data-buffering class=\"c-search__action-icon c-spinner\" style=\"display: none\">Loading</span>\n</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.submit : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showList : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});