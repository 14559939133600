// @flow
export default (theme: Object) => ({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 14,
    },
    dialogAction: {
        margin: 0,
    },
    message: {
        marginBottom: 10,
        fontSize: 16,
        color: 'black',
    },
    hint: {
        color: theme.palette.grey[500],
        height: 28,
    },
    reject: {
        minWidth: 0,
        backgroundColor: theme.palette.error.main,
        color: 'white',

        '&:hover': {
            backgroundColor: theme.palette.error.main, // emphasize(theme.palette.error.main),
        },
    },
    approve: {
        minWidth: 0,
        backgroundColor: theme.palette.success.main,
        color: 'white',

        '&:hover': {
            backgroundColor: theme.palette.success.main, // emphasize(theme.palette.success.main),
        },
    },
});
