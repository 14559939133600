// @flow
export default (theme: Object) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        height: '100%',
        color: theme.palette.common.white,
    },
    closeButton: {
        position: 'absolute',
        left: 0,
    },
    toolbar: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
    },
    carousel: {
        width: '100vw',
        flex: 1,
        marginTop: 30,
    },
    info: {
        backgroundColor: 'rgba(0, 0, 0, .6)',
        width: '100%',
        padding: 10,
        textAlign: 'center',
    },
    title: {
        fontFamily: 'ProximaNova',
        color: theme.palette.common.white,
    },
    description: {
        fontFamily: 'ProximaNova',
        color: 'rgba(255, 255, 255, 0.7)',
    },
});
