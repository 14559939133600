// @flow
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal } from '@material-ui/core';
import Container from './Container';
import styles from './styles';

type Props = {
    classes?: Object,
    imageUrl: string,
    onClose: () => void,
    onSave: (dataURL: string) => ?Promise<void>,
    open: boolean,
};

const useStyles = makeStyles(styles, { name: 'ImageEditor' });

export default function ImageEditor(props: Props) {
    const { classes: otherClasses, onClose, open, ...other } = props;
    const classes = useStyles(props);

    return (
        <Modal onClose={onClose} open={open}>
            <Container {...other} classes={classes} onClose={onClose} />
        </Modal>
    );
}

ImageEditor.defaultProps = {
    open: false,
    onClose: () => {},
    onSave: () => {},
};
