// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Component, createRef } from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import { Popover } from '@material-ui/core';
import type { Node } from 'react';
import DateRangeInput from './DateRangeInput';
import DayPicker from './DayPicker';
import styles from './styles';

type Props = {
    className?: string,
    classes: Object,
    customInputIcon: Node,
    disabled?: boolean,
    error?: string,
    endDatePlaceholder?: string,
    format: string,
    isOutsideRange: (day: moment$Moment) => boolean,
    label?: string,
    onBlur?: () => void,
    onChange?: (value: { startDate: ?string, endDate: ?string }) => void,
    onFocus?: () => void,
    showClearDates?: boolean,
    startDatePlaceholder?: string,
    value?: {
        endDate: string | moment$Moment | Date,
        startDate: string | moment$Moment | Date,
    },
};

type State = {
    focusedInput: ?('startDate' | 'endDate'),
    open: boolean,
};

const returnsFalse = () => false;

export class DateRangePicker extends Component<Props, State> {
    static defaultProps = {
        format: 'YYYY-MM-DDTHH:mm',
        isOutsideRange: returnsFalse,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            focusedInput: null,
            open: false,
        };

        this.anchorRef = createRef();
    }

    handleChange = (value: { startDate: ?string, endDate: ?string }) => {
        const { onChange } = this.props;
        if (typeof onChange === 'function') {
            onChange(value);
        }
    };

    handleFocus = (focusedInput: ?('startDate' | 'endDate')) => {
        const nextState = {
            focusedInput,
            open: focusedInput != null,
        };

        this.setState(nextState, () => {
            const { onFocus } = this.props;
            if (typeof onFocus === 'function') {
                onFocus();
            }
        });
    };

    handleBlur = () => {
        this.setState({ open: false }, () => {
            const { onBlur } = this.props;
            if (typeof onBlur === 'function') {
                onBlur();
            }
        });
    };

    registerActions = (actions: Object) => {
        this.actions = actions;
    };

    updatePosition = () => {
        const { actions } = this;
        if (actions && actions.updatePosition) {
            actions.updatePosition();
        }
    };

    actions: ?Object;

    anchorRef: Object;

    render() {
        const { focusedInput, open } = this.state;
        const {
            className,
            classes,
            customInputIcon,
            disabled,
            endDatePlaceholder,
            error,
            label,
            showClearDates,
            startDatePlaceholder,
            value,
            ...dayPickerProps
        } = this.props;

        const inputProps = { customInputIcon, disabled, error, label, showClearDates, value };

        return (
            <div className={cx(className, classes.root)}>
                {/* $FlowIssue need to update to more recent flow version */}
                <div ref={this.anchorRef}>
                    <DateRangeInput
                      {...inputProps}
                      className={classes.dateInput}
                      endDatePlaceholder={endDatePlaceholder || ''}
                      focusedInput={focusedInput}
                      onChange={this.handleChange}
                      onFocus={this.handleFocus}
                      startDatePlaceholder={startDatePlaceholder || ''}
                    />
                </div>
                <Popover
                  classes={{ paper: classes.popoverPaper }}
                  open={open}
                  onClose={this.handleBlur}
                  // $FlowIssue need to update to more recent flow version
                  anchorEl={this.anchorRef.current}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  disableRestoreFocus
                  action={this.registerActions}
                >
                    <DayPicker
                      {...dayPickerProps}
                      disabled={disabled}
                      focusedInput={focusedInput}
                      onBlur={this.handleBlur}
                      onChange={this.handleChange}
                      onFocus={this.handleFocus}
                      updatePosition={this.updatePosition}
                      value={value}
                    />
                </Popover>
            </div>
        );
    }
}

export default withStyles(styles, { name: 'DateRangePicker' })(DateRangePicker);
