// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import types from './types';
import typeof { checkNotificationsSuccess, markAsReadSuccess } from './actions';

export type State = {
    notificationIds: number[],
    unreadCount: number,
};

export const init: State = {
    notificationIds: [],
    unreadCount: 0,
};

export default handleActions({
    [types.CHECK_NOTIFICATIONS_SUCCESS]: (state: State, action: ActionType<checkNotificationsSuccess>) => ({
        ...state,
        notificationIds: action.payload,
        unreadCount: action.meta.unreadCount,
    }),
    [types.MARK_AS_READ_SUCCESS]: (state: State, action: ActionType<markAsReadSuccess>) => ({
        ...state,
        notificationIds: state.notificationIds.filter((id: number) => id !== action.payload),
        unreadCount: state.unreadCount - 1,
    }),
}, init);
