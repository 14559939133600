// @flow
import React, { Component } from 'react';
import cx from 'classnames';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import { MenuItem, TextField } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import type { TFunction } from 'react-i18next';
import fieldify from '../../../../../../components/fieldify';
import { VALUE_TYPE } from '../../../../../../../browser/shared/constant/ProjectConstant';

type Props = {
    classes: Object,
    className?: string,
    formKey: string,
    id: number,
    questionText: string,
    readOnly?: boolean,
    t: TFunction,
    valueType: string,
};

type Item = {
    label: string,
    value: string,
};

const styles = () => ({
    root: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    input: {
        flexBasis: '70%',
    },
    numberFormat: {
        flexBasis: '30%',
    },
    menuItem: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        label: {
            flexGrow: 2,
            display: 'flex',
            fontSize: 14, // $font-size
        },
    },
});

const valueTypes: Array<Item> = [
    { label: 'Number', value: VALUE_TYPE.NUMBER },
    { label: 'Currency', value: VALUE_TYPE.CURRENCY },
    { label: 'Phone Number', value: VALUE_TYPE.PHONE_NUMBER },
    { label: 'Duration', value: VALUE_TYPE.TIME },
];

const SelectField = fieldify()(
    (props: Object) => {
        const { onBlur, onChange, ...other } = props;
        return (
            <TextField
              {...other}
              select
              onChange={(event: SyntheticInputEvent<*>) => onChange(event.target.value)}
              onBlur={() => onBlur()}
            />
        );
    }
);

export class Number extends Component<Props> {
    renderValue = (value: string) => {
        const { classes } = this.props;
        const valueType = valueTypes.find((item: Item) => item.value === value);

        if (!valueType) {
            return null;
        }

        return (
            <div className={classes.menuItem}>
                <span className={classes.label}>{valueType.label}</span>
            </div>
        );
    };

    render() {
        const { className, classes, formKey, id, readOnly, t } = this.props;

        return (
            <div className={cx(className, classes.root)}>
                <Field
                  disabled={readOnly || id > 0}
                  name={`${formKey}.valueType`}
                  label={t('shared.question_form.number_format')}
                  component={SelectField}
                  className={classes.numberFormat}
                  renderValue={this.renderValue}
                  SelectProps={{
                      MenuProps: { className: 'ignore-react-onclickoutside' },
                  }}
                >
                    {valueTypes.map((item: Item) => (
                        <MenuItem key={item.value} className={classes.menuItem} value={item.value}>
                            <span className={classes.label}>{item.label}</span>
                        </MenuItem>
                    ))}
                </Field>
            </div>
        );
    }
}

const enhance = compose(withStyles(styles, { name: 'Number' }), withTranslation());
export default enhance(Number);
