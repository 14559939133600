// @flow
import { createSelector } from 'reselect';
import { parseSearchParams } from '../../../duck/selectors';

export { parseSearchParams } from '../../../duck/selectors';

export const getActiveFilterCount = createSelector(
    parseSearchParams,
    (search: Object): number => (
        Object.entries(search).reduce((cnt: number, [key, filter]: [string, mixed]): number => {
            if (['q', 'page', 'size', 'sort', 'order'].includes(key)) {
                return cnt;
            }
            return Array.isArray(filter) ? cnt + filter.length : cnt + 1;
        }, 0)
    )
);

export default {
    getActiveFilterCount,
    parseSearchParams,
};
