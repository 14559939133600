var Handlebars = require('/tmp/build_c41768ab/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"selected-items\" data-target=\"selected-items\"></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"dropdown\" data-target=\"dropdown\">\n    <button class=\"btn btn-default dropdown-toggle\" type=\"button\" data-toggle=\"dropdown\">\n        <span class=\"caret\"></span>\n        <span data-target=\"button-label\">"
    + container.escapeExpression(((helper = (helper = helpers.select_from_options || (depth0 != null ? depth0.select_from_options : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"select_from_options","hash":{},"data":data}) : helper)))
    + "</span>\n    </button>\n    <ul class=\"dropdown-menu\" role=\"menu\">\n        <li class=\"c-search\" data-target=\"search\"></li>\n        <li class=\"menu-list\" data-target=\"dropdown-list\">\n            <div>\n                <!-- Retrieved list goes in this div -->\n            </div>\n        </li>\n    </ul>\n</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.multiselect : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});