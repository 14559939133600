// @flow
export default () => ({
    root: {
        position: 'relative',
        // zIndex: 1,
        overflow: 'hidden',
        width: 40,
        height: 40,
        borderRadius: '50%',

        // '&$small': {
        //     width: 40,
        //     height: 40,
        // },

        // '&$medium': {
        //     width: 70,
        //     height: 70,
        //
        //     '& > span': {
        //         fontSize: 26, // 4
        //         lineHeight: 70,
        //     }
        // },

        // '&$large': {
        //     width: 115,
        //     height: 115,
        //
        //     '& > span': {
        //         fontSize: 50, // 4
        //         lineHeight: 115,
        //     },
        // },
    },

    image: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },

    portrait: {
        '& $image': {
            width: '100%',
            height: 'auto',
        },
    },

    landscape: {
        '& $image': {
            width: 'auto',
            height: '100%',
            maxWidth: 'none',
        },
    },
});

// .c-avatar__container {
//     position: absolute;
//     bottom: 0;
//     width: 115px;
//     min-height: 45px;
//     background: black;
//     opacity: 0.8;
//     cursor: pointer;
//     z-index: 2;
//
// > span {
//         font-size: $small-font-size;
//         line-height: normal;
//     }
// }
