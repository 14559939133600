// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Component, Fragment, createRef } from 'react';
import cx from 'classnames';
import capitalize from 'lodash.capitalize';
// $FlowTypedIssue update react-redux libdef to v6
import { ReactReduxContext, connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Route, Switch } from 'react-router';
import { withStyles } from '@material-ui/styles';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import loadScript from 'load-script';
import type { ContextRouter } from 'react-router';
import type { TFunction } from 'react-i18next';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import typeof $i18next from 'i18next';
import isCrossmark from '../../util/isCrossmark';
import logger from '../../util/logger';
import { USER_ROLES } from '../../../browser/shared/constant/UserRoles';
import LoadingPage from '../../components/LoadingPage';
import Footer from './containers/Footer';
import Header from './containers/Header';
import SideNav from './containers/SideNav';
import NoMatch from '../NoMatch';
import PaymentsApp from '../PaymentsApp';
import ProjectsApp from '../ProjectsApp';
import TicketsApp from '../TicketsApp';
import AdminApp from '../AdminApp';
import SplashScreen from '../SplashScreen';
import * as config from '../../ducks/config';
import * as ui from '../../ducks/ui';
import styles from './styles';
import type { State as RootState } from '../../redux/initialState';
import type { User } from '../../store_utils/types/User';
import typeof $ApplicationContext from '../../../browser/ApplicationContext';

const fallbackContent = 'For this site to function it is necessary to enable JavaScript.'
    + 'Here are the <a href="http://www.enable-javascript.com/" target="_blank">'
    + 'instructions how to enable JavaScript in your web browser</a>.'
    + '<style>[data-layout-buffering] { display: none; }</style>';

type State = {
    loading: boolean,
};

type OwnProps = ContextRouter & {
    classes: Object,
    i18n: $i18next,
    store: Store<RootState, *>,
    t: TFunction,
};

type StateProps = {
    backboneActive: boolean,
    sideNavExpanded: boolean,
    user: ?User,
};

type DispatchProps = {
    fetchAPIConfig: () => Promise<void>,
    toggleBackbone: (active: boolean) => void,
};

type Props = OwnProps & StateProps & DispatchProps;

const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY || '';
const STRIPE_PUBLISHABLE_KEY = process.env.STRIPE_PUBLISHABLE_KEY || '';
const NODE_ENV = process.env.NODE_ENV || 'development';

export function hideZendeskWidget({ zE }: { zE: ?Function } = global) {
    if (zE != null && typeof zE === 'function') {
        zE(() => {
            // This is only for test, this will be removed in production code with uglify anyways.
            // istanbul ignore next
            if (NODE_ENV !== 'test') {
                ({ zE } = global); // eslint-disable-line no-param-reassign
            }

            if (zE != null && typeof zE.hide === 'function') {
                zE.hide();
            } else {
                logger.error({ zE, err: new TypeError('Expected zE.hide to be defined and ready') });
            }
        });
    } else {
        logger.error({ zE, err: new TypeError('Expected zE to be a type function') });
    }
}

export class MainLayout extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { loading: true };
        this.backboneRoot = createRef();
    }

    componentDidMount() {
        const { fetchAPIConfig, history, i18n, store } = this.props;

        fetchAPIConfig()
            .then(() => {
                this.setState({ loading: false });
            });

        loadScript('/public/scripts/zendeskWidget.js', (err: ?Error) => {
            if (err) logger.error(err, 'error loading zendesk widget!');
            hideZendeskWidget();
        });

        loadScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`, (err: ?Error) => {
            if (err) logger.error(err);
        });

        loadScript('https://js.stripe.com/v2/', (err: ?Error) => {
            if (err) logger.error(err);
            else if (global.hasOwnProperty('Stripe')) {
                Stripe.setPublishableKey(STRIPE_PUBLISHABLE_KEY);
            }
        });

        // webpack does some magic with require making it difficult to test
        /* istanbul ignore next */
        // $FlowIssue https://github.com/facebook/flow/issues/3217
        import('../../../browser/ApplicationContext').then(({ default: ApplicationContext }) => {
            this.applicationContext = new ApplicationContext({
                $el: $(this.backboneRoot.current),
                store,
                i18n,
                browserHistory: history,
            });
        });
    }

    componentWillReceiveProps(nextProps: Props) {
        const { location, toggleBackbone } = this.props;
        const {
            location: nextLocation,
            backboneActive: nextBackboneActive,
        } = nextProps;

        // If the location has changed, hide the backbone container in case the new location
        // matches a react-router route. Nested routes should use the NoMatch component
        // to toggle the backbone container active.
        if (location.pathname !== nextLocation.pathname && nextBackboneActive) {
            toggleBackbone(false);
        }
    }

    backboneRoot: Object;

    applicationContext: $ApplicationContext;

    renderMain() {
        const { loading } = this.state;
        const { backboneActive, classes, match } = this.props;

        // @todo Remove these 2 lines after R3 migration is complete
        const appName = capitalize(match.params.app);
        const appClassName = cx(classes.content, appName, { Ticket: appName === 'Tickets' });
        const mainStyle = {
            flex: '1 0 auto',
            display: loading ? 'none' : 'block',
        };

        return (
            <Fragment>
                {loading ? <LoadingPage style={{ height: '100%' }} /> : null}
                <main data-module="gigwalk-app" className={appClassName} style={mainStyle}>
                    <div style={{ display: loading ? 'none' : 'block' }}>
                        <div
                          data-backbone-root
                          ref={this.backboneRoot}
                          style={{ display: backboneActive ? 'block' : 'none' }}
                        >
                            <div data-app-buffering className="glyphicon glyphicon-refresh loading-tab" />
                            <div data-app-buffered />
                        </div>

                        <Switch>
                            <Route path="/projects" component={ProjectsApp} />
                            <Route path="/payments" component={PaymentsApp} />
                            <Route path="/tickets" component={TicketsApp} />
                            <Route path="/admin" component={AdminApp} />
                            <Route component={NoMatch} />
                        </Switch>
                    </div>
                </main>
            </Fragment>
        );
    }

    render() {
        const { classes, i18n, sideNavExpanded, user } = this.props;

        if (!user) {
            return null;
        }

        let helmetProps = { lang: i18n.language };
        if (isCrossmark(user)) {
            helmetProps = {
                ...helmetProps,
                link: [{
                    href: '/public/images/clients/crossmark/favicon.ico',
                    rel: 'shortcut icon',
                }],
            };
        } else if (user.role === 'WORKER') {
            helmetProps = {
                ...helmetProps,
                meta: [{
                    name: 'apple-itunes-app',
                    content: 'app-id=973152153',
                }],
                link: [{
                    rel: 'manifest',
                    href: '/public/manifest.json',
                }],
            };
        }

        const isWorker = user.role === USER_ROLES.WORKER;

        let containerStyle = {};
        if (!isWorker) {
            containerStyle = { marginLeft: sideNavExpanded ? 240 : 70 };
        }

        return (
            <div className={classes.root}>
                <Helmet {...helmetProps} />
                <Route component={SplashScreen} />
                {isWorker ? null : <Route component={SideNav} />}
                <div className={classes.container} style={containerStyle}>
                    <Route component={Header} />
                    {this.renderMain()}
                    <Route component={Footer} />
                </div>
                <div className="lightbox">
                    <div className="lb-shadow photos lightbox" style={{ display: 'none' }} />
                </div>
                <noscript dangerouslySetInnerHTML={{ __html: fallbackContent }} />
            </div>
        );
    }
}

export const MainLayoutWithContext = (props: Object) => (
    <ReactReduxContext.Consumer>
        {(context: Object) => <MainLayout {...props} store={context.store} />}
    </ReactReduxContext.Consumer>
);

const mapStateToProps = (state: RootState): StateProps => ({
    backboneActive: state.ui.backboneActive,
    sideNavExpanded: state.layout.sideNav.expanded,
    user: state.session.user,
});
const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    fetchAPIConfig: () => dispatch(config.actions.fetchAPIConfig()),
    toggleBackbone: (active: boolean) => dispatch(ui.actions.toggleBackbone(active)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'MainLayout' }),
    withTranslation(),
    connector,
);
export default enhance(MainLayoutWithContext);
