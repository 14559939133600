// @flow
import { createAction } from 'redux-actions';
import { denormalize } from 'normalizr';
import stringify from 'json-stable-stringify';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import { format } from '../../../../browser/shared/util/gigwalkApiErrorUtil';
import { customer as customerSchema } from '../../../redux/entities/schemas';
import { bulkUpdate as updateCustomers } from '../../../redux/entities/customers';
import * as snackbar from '../../../ducks/snackbar';
import types from './types';
import type { State as RootState } from '../../../redux/initialState';

export const submitError = createAction(types.SUBMIT_ERROR);
export const submitSuccess = createAction(types.SUBMIT_SUCCESS);
export const submit = createAction(
    types.SUBMIT,
    (values: Object): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<any> => {
            const { customerIds } = values;
            const state = getState();
            const promises = [];

            const customers = denormalize(customerIds, [customerSchema], state.entities);
            const customersByOrg = customers.reduce((map: Map<number, number[]>, customer: Object) => {
                // The v1/search/customers endpoint returns an organization_id field for each customer
                // @todo Create a function similar to mapESRecordToTicket for v1/search/customers
                const orgId = customer.organization ? customer.organization.id : customer.organization_id;
                const customerId = customer.id;

                if (map.has(orgId)) {
                    const ids = map.get(orgId);
                    // $FlowIssue flow should be able to understand that ids won't be undefined
                    map.set(orgId, [...ids, customerId]);
                } else {
                    map.set(orgId, [customerId]);
                }

                return map;
            }, new Map());

            customersByOrg.forEach((value: number[], key: number) => {
                const params = {
                    action: 'UPDATE',
                    customers: value.map((id: number) => ({
                        customer_id: id,
                        customer_status: 'INACTIVE',
                    })),
                    organization_id: key,
                };
                promises.push(dispatch(updateCustomers(params)));
            });

            return Promise.all(promises)
                .then((results: Object[]) => {
                    dispatch(submitSuccess());

                    const warnings = results.reduce((set: Set<string>, resp: Object) => {
                        if (resp.original && resp.original.gw_api_response && resp.original.gw_api_response.length > 0) {
                            resp.original.gw_api_response.forEach((warning: Object) => {
                                set.add(stringify(warning));
                            });
                        }
                        return set;
                    }, new Set());

                    if (warnings.size > 0) {
                        const message = format(Array.from(warnings, (warning: string) => JSON.parse(warning)));
                        dispatch(snackbar.actions.enqueue(message, { variant: 'warning' }));
                    }
                })
                .catch((err: $AxiosError<Object>) => {
                    dispatch(submitError(err));
                    const resp = err ? err.response : null;
                    if (resp && resp.data && resp.data.gw_api_response) {
                        const message = format(resp.data.gw_api_response);
                        dispatch(snackbar.actions.enqueue(message, { variant: 'error' }));
                    }
                    return Promise.reject();
                });
        }
    )
);

export default {
    submit,
    submitError,
    submitSuccess,
};
