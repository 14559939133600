// @flow
export const SELECT = 'g/ticketList/SELECT';
export const SELECT_ALL = 'g/ticketList/SELECT_ALL';
export const DESELECT = 'g/ticketList/DESELECT';
export const DESELECT_ALL = 'g/ticketList/DESELECT_ALL';
export const CLEAR_SELECTED = 'g/ticketList/CLEAR_SELECTED';

export const RESET_COLUMNS = 'g/ticketList/RESET_COLUMNS';
export const TOGGLE_COLUMN = 'g/ticketList/TOGGLE_COLUMN';
export const TOGGLE_FILTER_DRAWER = 'g/ticketList/TOGGLE_FILTER_DRAWER';

export const FETCH_DATA = 'g/ticketList/FETCH_DATA';
export const FETCH_DATA_SUCCESS = `${FETCH_DATA}_SUCCESS`;
export const FETCH_DATA_ERROR = `${FETCH_DATA}_ERROR`;

export const LOAD_FILTERS = 'g/ticketList/LOAD_FILTERS';
export const LOAD_FILTERS_SUCCESS = `${LOAD_FILTERS}_SUCCESS`;
export const LOAD_FILTERS_ERROR = `${LOAD_FILTERS}_ERROR`;

export const REFRESH = 'g/actionToolbar/REFRESH';
export const REFRESH_SUCCESS = `${REFRESH}_SUCCESS`;
export const REFRESH_ERROR = `${REFRESH}_ERROR`;

export default {
    CLEAR_SELECTED,
    DESELECT,
    DESELECT_ALL,
    FETCH_DATA,
    FETCH_DATA_ERROR,
    FETCH_DATA_SUCCESS,
    LOAD_FILTERS,
    LOAD_FILTERS_ERROR,
    LOAD_FILTERS_SUCCESS,
    REFRESH,
    REFRESH_ERROR,
    REFRESH_SUCCESS,
    RESET_COLUMNS,
    SELECT,
    SELECT_ALL,
    TOGGLE_COLUMN,
    TOGGLE_FILTER_DRAWER,
};
