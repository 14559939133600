// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import types from './types';
import typeof { getBalanceSuccess, addFundsSuccess } from './actions';

export type State = {
    confirmationDialogVisible: boolean,
    currentFunds: number,
    addedFunds: number,
};

export const init: State = Object.freeze({
    confirmationDialogVisible: false,
    currentFunds: 0,
    addedFunds: 0,
});

export default handleActions({
    [types.GET_BALANCE_SUCCESS]: (state: State, action: ActionType<getBalanceSuccess>) => {
        if (!action.payload) {
            return state;
        }

        return {
            ...state,
            currentFunds: action.payload,
        };
    },
    [types.ADD_FUNDS_SUCCESS]: (state: State, action: ActionType<addFundsSuccess>) => {
        if (!action.payload) {
            return state;
        }

        return {
            ...state,
            confirmationDialogVisible: true,
            addedFunds: action.payload,
        };
    },
    [types.HIDE_CONFIRMATION_DIALOG]: (state: State) => ({
        ...state,
        confirmationDialogVisible: false,
    }),
}, init);
