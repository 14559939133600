// @flow
export const FETCH_DATA = 'g/transactionList/FETCH_DATA';
export const FETCH_DATA_SUCCESS = `${FETCH_DATA}_SUCCESS`;
export const FETCH_DATA_ERROR = `${FETCH_DATA}_ERROR`;

export const LOAD_FILTERS = 'g/transactionList/LOAD_FILTERS';
export const LOAD_FILTERS_SUCCESS = `${LOAD_FILTERS}_SUCCESS`;
export const LOAD_FILTERS_ERROR = `${LOAD_FILTERS}_ERROR`;

export default {
    FETCH_DATA,
    FETCH_DATA_ERROR,
    FETCH_DATA_SUCCESS,
    LOAD_FILTERS,
    LOAD_FILTERS_ERROR,
    LOAD_FILTERS_SUCCESS,
};
