// @flow
import { createAction } from 'redux-actions';
import identity from 'lodash.identity';
import omitBy from 'lodash.omitby';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import logger from '../../../util/logger';
import { search as searchCustomers } from '../../../redux/entities/customers';
import types from './types';

export const fetchDataSuccess = createAction(types.FETCH_DATA_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const fetchDataError = createAction(types.FETCH_DATA_ERROR);
export const fetchData = createAction(
    types.FETCH_DATA,
    (filters: ?Object[], keywords: ?string, sort: ?Object[], limit: ?number, offset: ?number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => {
            const data = omitBy({ filters, keywords, offset, limit, sort }, (value) => value == null);
            return dispatch(searchCustomers(data))
                .then((resp: Object) => dispatch(fetchDataSuccess(resp.result, resp.metadata)))
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchDataError());
                    return Promise.reject(err);
                });
        }
    )
);

export default {
    fetchData,
    fetchDataError,
    fetchDataSuccess,
};
