// @flow
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';
import addFunds from '../containers/AddFunds/duck';
import config from '../ducks/config';
import customerList from '../containers/CustomerList/duck';
import dialog from '../ducks/dialog';
import entities from './entities';
import gigwalkerList from '../containers/GigwalkerList/duck';
import googleMaps from '../ducks/googleMaps';
import layout from '../containers/Layout/duck';
import locationListUpload from '../../browser/shared/components/LocationListUpload/redux';
import payoutList from '../containers/PayoutList/duck';
import projectBuilder from '../containers/ProjectBuilder/duck';
import projectSummary from '../../browser/dashboard/view/active/Summary/duck';
import projectManagement from '../../browser/dashboard/view/active/Management/duck';
import session from '../ducks/session';
import signup from '../containers/Signup/duck';
import snackbar from '../ducks/snackbar';
import subscriptionList from '../containers/SubscriptionList/duck';
import ticketList from '../containers/TicketList/duck';
import ticketDetail from '../containers/TicketDetail/duck';
import transactionList from '../containers/TransactionList/duck';
import ui from '../ducks/ui';

const allReducers = {
    addFunds,
    config,
    customerList,
    dialog,
    entities,
    form,
    gigwalkerList,
    googleMaps,
    layout,
    locationListUpload,
    payoutList,
    projectBuilder,
    projectSummary,
    projectManagement,
    session,
    signup,
    snackbar,
    subscriptionList,
    ticketDetail,
    ticketList,
    transactionList,
    ui,
};

export default (history: Object) => combineReducers({
    ...allReducers,
    router: connectRouter(history),
});
