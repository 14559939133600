// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import types from './types';
import typeof { enqueue, remove } from './actions';

export type State = {
    notifications: Object[],
}

export const init: State = {
    notifications: [],
};

export default handleActions({
    [types.ENQUEUE_SNACKBAR]: (state: State, action: ActionType<enqueue>) => ({
        ...state,
        notifications: [
            ...state.notifications,
            action.payload,
        ],
    }),
    [types.REMOVE_SNACKBAR]: (state: State, action: ActionType<remove>) => ({
        ...state,
        notifications: state.notifications.filter(
            (notification) => notification.key !== action.payload,
        ),
    }),
}, init);
