// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import types from './types';
import typeof { loadMarkersSuccess } from './actions';

export type MarkerProps = {
    icon: {
        url: string,
    },
    info: string,
    position: { lat: number, lng: number },
}

export type State = {
    markers: MarkerProps[],
};

export const init: State = {
    markers: [],
};

export default handleActions({
    [types.LOAD_MARKERS_SUCCESS]: (state: State, action: ActionType<loadMarkersSuccess>) => ({
        ...state,
        markers: action.payload,
    }),
}, init);
