// @flow
export const FETCH_DATA_TYPE_SUMMARY = 'g/projectSummary/FETCH_DATA_TYPE_SUMMARY';
export const FETCH_DATA_TYPE_SUMMARY_SUCCESS = `${FETCH_DATA_TYPE_SUMMARY}_SUCCESS`;
export const FETCH_DATA_TYPE_SUMMARY_ERROR = `${FETCH_DATA_TYPE_SUMMARY}_ERROR`;

export default {
    FETCH_DATA_TYPE_SUMMARY,
    FETCH_DATA_TYPE_SUMMARY_ERROR,
    FETCH_DATA_TYPE_SUMMARY_SUCCESS,
};
