// @flow
import { createAction } from 'redux-actions';
import { matchPath } from 'react-router';
import merge from 'lodash.merge';
import type { Dispatch } from 'redux';
import types from './types';
import getFieldMeta from '../../../utils/getFieldMeta';
import * as session from '../../../../../ducks/session';
import entitySelector from '../../../../../redux/entities/entitySelector';
import selectors from './selectors';
import type { StepErrors } from './reducer';
import type { State as RootState } from '../../../../../redux/initialState';

const subscriptionSelector = entitySelector('subscriptions');
const locationListSelector = entitySelector('locationLists');

export const updateStepErrors = createAction(types.UPDATE_STEP_ERRORS);

export const validateSteps = createAction(
    types.VALIDATE_STEPS,
    (): Function => (
        (dispatch: Dispatch<*>, getState: () => RootState) => {
            const state = getState();

            // @todo: Consider using another method to compute match to pass to selectors
            // I think the next best approach would be to pass props/match to action creators. The
            // issue with the current approach is that state.router.location is not guaranteed to always
            // be in sync with react-router. For instance, server-side matching and rendering happens
            // synchronously. Redux state will be updated in response, but content will already be rendered.
            const { location } = state.router;
            const match = matchPath(location.pathname, { path: '/projects/:orgId/create/:subscriptionId(new|\\d+)' });

            if (!match) {
                return;
            }

            const formValues = selectors.getAllValues(state, { match });
            const subscription = subscriptionSelector(state, match.params.subscriptionId || -1);
            const locationList = locationListSelector(state, formValues.locationListId || -1);
            const canLaunchPublicProject = session.selectors.canLaunchPublicProject(state);

            // Props needed by field-level validation rules to perform validation. When used within the
            // context of redux-form, these are just the props of the wrapped component.
            const validateProps = { canLaunchPublicProject, locationList, subscription };

            if (!formValues) {
                return;
            }

            // This function recursively iterates over all form values and runs validation for each
            // property that matches a known field
            const stepValidator = (values: Object, path?: string = ''): StepErrors => {
                let errors = {
                    info: [],
                    locations: [],
                    people: [],
                    data: [],
                    launch: [],
                };

                Object.entries(values).forEach(([field, value]: [string, any]) => {
                    const fieldPath = `${path ? `${path}.` : ''}${field}`;

                    if (Array.isArray(value)) {
                        value.forEach((nestedValues: mixed, index: number) => {
                            if (nestedValues != null && typeof nestedValues === 'object' && !Array.isArray(nestedValues)) {
                                errors = merge(errors, stepValidator(nestedValues, `${fieldPath}[${index}]`));
                            }
                        });
                    }

                    const { step, validationRule } = getFieldMeta(fieldPath);
                    const error = validationRule(value, formValues, validateProps, fieldPath);

                    if (error && errors.hasOwnProperty(step)) {
                        errors[step].push(error);
                    }
                });

                return errors;
            };

            dispatch(updateStepErrors(stepValidator(formValues)));
        }
    )
);

export default {
    updateStepErrors,
    validateSteps,
};
