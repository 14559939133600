// @flow
export default (theme: Object) => ({
    root: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 44,
        filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.4))',
        padding: theme.spacing(0, 2),
        color: theme.palette.common.white,

        '&::before': {
            position: 'absolute',
            content: '""',
            top: '50%',
            left: -20,
            marginTop: -22,
            borderTop: [[22, 'solid']],
            borderBottom: [[22, 'solid']],
            borderLeft: [[18, 'solid', 'transparent']],
            borderRight: [[3, 'solid']],
        },
        '&::after': {
            position: 'absolute',
            content: '""',
            top: '50%',
            right: -5,
            marginTop: -22,
            borderTop: [[22, 'solid']],
            borderBottom: [[22, 'solid']],
            borderLeft: [[4, 'solid']],
            borderRight: [[4, 'solid']],
        },
    },
    success: {
        backgroundColor: theme.palette.success.dark,

        '&::before': {
            borderTopColor: theme.palette.success.dark,
            borderBottomColor: theme.palette.success.dark,
            borderLeftColor: 'transparent',
            borderRightColor: theme.palette.success.dark,
        },
        '&::after': {
            borderColor: theme.palette.success.dark,
        },
    },
    error: {
        backgroundColor: theme.palette.error.dark,

        '&::before': {
            borderTopColor: theme.palette.error.dark,
            borderBottomColor: theme.palette.error.dark,
            borderLeftColor: 'transparent',
            borderRightColor: theme.palette.error.dark,
        },
        '&::after': {
            borderColor: theme.palette.error.dark,
        },
    },
    info: {
        backgroundColor: theme.palette.primary.light,

        '&::before': {
            borderTopColor: theme.palette.primary.light,
            borderBottomColor: theme.palette.primary.light,
            borderLeftColor: 'transparent',
            borderRightColor: theme.palette.primary.light,
        },
        '&::after': {
            borderColor: theme.palette.primary.light,
        },
    },
    warning: {
        backgroundColor: theme.palette.secondary.light,

        '&::before': {
            borderTopColor: theme.palette.secondary.light,
            borderBottomColor: theme.palette.secondary.light,
            borderLeftColor: 'transparent',
            borderRightColor: theme.palette.secondary.light,
        },
        '&::after': {
            borderColor: theme.palette.secondary.light,
        },
    },
    text: {
        fontFamily: 'ProximaNova',
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        fontFamily: 'ProximaNova',
        fontSize: 16,
        position: 'absolute',
        right: theme.spacing(2),
    },
});
