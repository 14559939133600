// @flow
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import type { ComponentType } from 'react';
import type { FieldProps } from 'redux-form';
import type { Event as ReduxFormEvent } from 'redux-form/lib/types'; // eslint-disable-line import/no-unresolved
import type { TFunction } from 'react-i18next';

type State = {
    dirtyOnMount: boolean,
}

type InjectedProps = {
    checked?: boolean,
    error?: string,
    name?: string,
    onBlur?: (eventOrValue: ReduxFormEvent | any) => void,
    onChange?: (eventOrValue: ReduxFormEvent | any) => void,
    onDrop?: (event: ReduxFormEvent) => void,
    onDragStart?: (event: ReduxFormEvent) => void,
    onFocus?: (event: ReduxFormEvent) => void,
    value?: any
}

type FieldComponentProps = FieldProps & {
    i18n: Object,
    t: TFunction,
}

type Config = {
    i18nKey?: string,
};

export default (config?: Config = {}) => (
    <Props: {}>(WrappedComponent: ComponentType<InjectedProps & Props>) => {
        class FieldComponent extends Component<FieldComponentProps & Props, State> {
            state: State = {
                dirtyOnMount: true,
            };

            componentWillMount() {
                const { meta } = this.props;
                this.setState({
                    dirtyOnMount: meta.dirty,
                });
            }

            render() {
                const { i18nKey } = config;
                const { i18n, meta, input, t, ...componentProps } = this.props;
                const { dirtyOnMount } = this.state;

                // If the input has a truthy pristine value (i.e. non-empty) that is invalid
                // OR an invalid value entered by the user, consider it an error
                const truthyPristineValue = !!meta.initial && meta.pristine;
                const hasError = !!meta.error && (meta.touched || truthyPristineValue || (meta.dirty && dirtyOnMount));

                const metaProps = {};
                if (hasError) {
                    const { error } = meta;
                    if (typeof error === 'string') {
                        let key = `${i18nKey ? `${i18nKey}.` : ''}${error}`;
                        if (!i18n.exists(key)) {
                            key = `validation.${error}`;
                        }
                        metaProps.error = t(key);
                    } else if (error && 'message' in error) {
                        const { message, ...options } = error;
                        let key = `${i18nKey ? `${i18nKey}.` : ''}${message}`;
                        if (!i18n.exists(key)) {
                            key = `validation.${message}`;
                        }
                        metaProps.error = t(key, { ...options });
                    }
                }

                // if (typeof input.value === 'boolean') {
                //     input.checked = input.value;
                // }

                return <WrappedComponent {...componentProps} {...input} {...metaProps} />;
            }
        }

        return withTranslation()(FieldComponent);
    }
);
