// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useEffect } from 'react';
import { hoistStatics } from 'recompose';
import { connect } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import * as dialog from '../../ducks/dialog';
import type { State as RootState } from '../../redux/initialState';

type OwnProps = {};
type StateProps = {
    actions: Object[],
    content: string,
    onClose: () => void,
    open: boolean,
    title: string,
};
type DispatchProps = {
    dispatch: Dispatch<any>,
    registerDialog: (name: string) => void,
    unregisterDialog: (name: string) => void,
};
type Props = OwnProps & StateProps & DispatchProps;

export function ConfirmDialog(props: Props) {
    const {
        actions,
        content,
        onClose,
        open,
        registerDialog,
        title,
        unregisterDialog,
    } = props;

    useEffect(() => {
        registerDialog(ConfirmDialog.NAME);
        return () => {
            unregisterDialog(ConfirmDialog.NAME);
        };
    }, [registerDialog, unregisterDialog]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {actions.map((action: Object) => (
                    <Button onClick={action.onClick}>
                        {action.label}
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    );
}

ConfirmDialog.NAME = 'confirm';
ConfirmDialog.defaultProps = {
    actions: [],
    content: '',
    onClose: () => {},
    open: false,
    title: '',
};

const mapStateToProps = (state: RootState): StateProps => ({
    ...ConfirmDialog.defaultProps,
    ...state.dialog.registered[ConfirmDialog.NAME],
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    dispatch,
    registerDialog: (name: string) => dispatch(dialog.actions.register(name)),
    unregisterDialog: (name: string) => dispatch(dialog.actions.unregister(name)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default hoistStatics(connector)(ConfirmDialog);
