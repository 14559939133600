// @flow
import { createSelector } from 'reselect';
import type { ContextRouter } from 'react-router';
import entitySelector from '../../../../../redux/entities/entitySelector';
import { getAPIParams, parseSearchParams } from '../../../duck/selectors';
import type { State as RootState } from '../../../../../redux/initialState';

export { getAPIParams, parseSearchParams } from '../../../duck/selectors';

const organizationSelector = entitySelector('organizations');
const getSelectedOrganizations = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): Object[] => {
    const search = parseSearchParams(state, props);
    const selected = search.organization || [];
    return organizationSelector(state, selected);
};
export const getOrganizationFilterValue = createSelector(
    getSelectedOrganizations,
    (selected: Object[]) => (
        selected.map((organization: Object): Object => ({
            label: organization.organization_name,
            value: organization,
        }))
    )
);

export default {
    getAPIParams,
    getOrganizationFilterValue,
    parseSearchParams,
};
