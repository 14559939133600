// @flow
/* global google */
// $FlowIssue need to update to a more recent flow version
import React, { Component, Fragment, createRef } from 'react';
import moment from 'moment-timezone';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    DateRange as DateRangeIcon,
    Timer as TimerIcon,
} from '@material-ui/icons';
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { compose, withProps } from 'recompose';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter } from 'react-router';
import { USER_ROLES } from '../../../../../browser/shared/constant/UserRoles';
import waitForCondition from '../../../../components/waitForCondition';
import getDisplayName from '../../../../util/getDisplayName';
import renderToDataUrl from '../../../../util/renderToDataUrl';
import ClusterMarker from '../../../../components/icons/ClusterMarker';
import GigMarker from '../../../../components/icons/GigMarker';
import { selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type MarkerProps = {
    icon: {
        url: string,
        scaledSize?: Object,
    },
    info: string | React$Element<any>,
    position: { lat: number, lng: number },
}

type State = {
    activeMarker: ?number,
    // loading: boolean,
    markers: MarkerProps[],
    ready: boolean,
}

type OwnProps = ContextRouter & {
    classes: Object,
    t: TFunction,
};
type StateProps = {
    data: Object[],
    defaultCenter: { lat: number, lng: number },
    fetching: boolean,
    filters?: Object[],
    limit?: number,
    offset?: number,
    pages: number,
    recordCount: number,
    sort?: Object[],
    user: ?Object,
};
type DispatchProps = {};
type Props = OwnProps & StateProps & DispatchProps;

let sign = 1;

export class MapView extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { data } = this.props;
        const markers = data.map((row: Object) => {
            const { location, price } = row;
            const label = price != null ? numeral(price).format('$0') : 1;
            return {
                icon: {
                    url: renderToDataUrl(
                        <GigMarker width={60} height={60} fill="#39beac" xmlns="http://www.w3.org/2000/svg" text={label} />,
                        'image/svg+xml'
                    ),
                },
                info: this.renderInfoBox(row),
                position: {
                    lat: location.latitude,
                    lng: location.longitude,
                },
            };
        });

        this.state = {
            activeMarker: null,
            markers,
            ready: false,
        };

        // $FlowIssue need to update to more recent flow version
        this.mapRef = createRef();
    }

    componentWillReceiveProps(nextProps: Props) {
        const { data: nextData } = nextProps;
        const { data } = this.props;
        const { markers } = this.state;

        if (data !== nextData || markers.length === 0) {
            const nextMarkers = nextData.map((row: Object) => {
                const { location, price } = row;
                const label = price != null ? numeral(price).format('$0') : 1;
                return {
                    icon: {
                        url: renderToDataUrl(
                            <GigMarker width={60} height={60} fill="#39beac" xmlns="http://www.w3.org/2000/svg" text={label} />,
                            'image/svg+xml'
                        ),
                    },
                    info: this.renderInfoBox(row),
                    position: {
                        lat: location.latitude,
                        lng: location.longitude,
                    },
                };
            });

            this.setState({ markers: nextMarkers });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { markers, ready } = this.state;
        // $FlowIssue need to update to more recent flow version
        const map = this.mapRef.current;

        const shouldFitBounds = (!prevState.ready && ready)
            || (ready && prevState.markers !== markers);

        // Adjust the bounds of the map to include all markers. Make sure that map
        // is initialized before calling fitBounds
        if (shouldFitBounds && map) {
            const currentBounds = map.getBounds();
            const bounds = new google.maps.LatLngBounds();

            markers.forEach((marker: MarkerProps) => {
                bounds.extend(marker.position);
            });

            if (!currentBounds.equals(bounds)) {
                map.fitBounds(bounds);

                // Slighty adjust the center of the map as a workaround for marker clusters
                // disappearing when fitBounds is called. Alternate sign to ensure center
                // does not gradually shift in one direction.
                // See https://github.com/googlemaps/v3-utility-library/issues/252
                // and https://github.com/tomchentw/react-google-maps/issues/172
                const center = map.getCenter();
                map.panTo({
                    lat: center.lat() + (sign * 0.0000001),
                    lng: center.lng(),
                });
                sign = -sign;
            }
        }
    }

    getMatchParams() {
        const { match } = this.props;
        return { orgId: '0', ...match.params };
    }

    handleMapIdle = () => {
        const { ready } = this.state;
        if (!ready) {
            this.setState({ ready: true });
        }
    };

    handleMarkerClick = (index: number) => {
        this.setState({ activeMarker: index });
    };

    handleInfoWindowClose = () => {
        this.setState({ activeMarker: null });
    };

    renderInfoBox = (row: Object) => {
        const { classes, user, t } = this.props;
        const { assignee, dueDate, location, needsPublicWorkforce, timeEstimate } = row;
        const { orgId } = this.getMatchParams();

        let assignedTo = null;
        if (!assignee) {
            assignedTo = t('ticketList.unassigned');
        } else {
            const hideDetails = !user || (user.role !== USER_ROLES.PLATFORM_ADMIN && user.id !== assignee.id && needsPublicWorkforce);
            const assigneeName = getDisplayName(assignee, { obfuscate: hideDetails });

            assignedTo = hideDetails
                ? <span>{assigneeName}</span>
                : <Link to={`/member/${orgId}/${assignee.id}/account`}>{assigneeName}</Link>;
        }

        return (
            <Fragment>
                <div className={classes.infoPrimary}>
                    {location.title !== location.formatted_address
                        ? <div className={classes.locationTitle}>{location.title}</div>
                        : null}
                    <div>{location.formatted_address}</div>
                    <div className={classes.assignee}>{assignedTo}</div>
                </div>
                <div className={classes.infoSecondary}>
                    <div className={classes.dueDate}>
                        <DateRangeIcon className={classes.infoIcon} />
                        <span>{`${t('ticketList.due')} ${moment.tz(dueDate, location.tzid).fromNow()}`}</span>
                    </div>
                    <div className={classes.timeEstimate}>
                        <TimerIcon className={classes.infoIcon} />
                        <span>{timeEstimate ? moment.duration(timeEstimate, 'seconds').humanize() : 'N/A'}</span>
                    </div>
                </div>
            </Fragment>
        );
    };

    render() {
        const { activeMarker, markers } = this.state;
        const { classes, defaultCenter } = this.props;

        const clusterIcon = {
            url: renderToDataUrl(
                <ClusterMarker width={60} height={60} fill="#39beac" xmlns="http://www.w3.org/2000/svg" />,
                'image/svg+xml'
            ),
        };
        const clusterIconStyles = [{
            url: clusterIcon.url,
            height: 60,
            width: 60,
            fontFamily: 'ProximaNova, san-serif',
            fontWeight: 'normal',
            textColor: '#ffffff',
            textSize: 14,
        }];

        return (
            <GoogleMap
              onIdle={this.handleMapIdle}
              defaultCenter={defaultCenter}
              defaultZoom={4}
              // $FlowIssue need to update to more recent flow version
              ref={this.mapRef}
            >
                <MarkerClusterer
                  defaultAverageCenter
                  defaultEnableRetinaIcons
                  defaultGridSize={60}
                  defaultStyles={clusterIconStyles}
                >
                    {markers.map((marker: MarkerProps, index: number) => {
                        const { info, ...markerProps } = marker;
                        return (
                            <Marker
                              {...markerProps}
                              key={index}
                              onClick={() => { this.handleMarkerClick(index); }}
                            >
                                {activeMarker === index
                                    ? (
                                        <InfoBox
                                          options={{
                                              alignBottom: true,
                                              boxClass: classes.infoBox,
                                              closeBoxURL: '',
                                              pixelOffset: new google.maps.Size(-120, -70),
                                              infoBoxClearance: new google.maps.Size(60, 60),
                                          }}
                                          onCloseClick={this.handleInfoWindowClose}
                                        >
                                            {info}
                                        </InfoBox>
                                    )
                                    : null
                                }
                            </Marker>
                        );
                    })}
                </MarkerClusterer>
            </GoogleMap>
        );
    }
}

/* eslint-disable no-unused-vars */
const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    ...selectors.getAPIParams(state, props),
    data: selectors.getTableData(state, props),
    defaultCenter: selectors.getDefaultCenter(state, props),
    fetching: state.ticketList.fetching,
    pages: state.ticketList.metadata.page_count || 0,
    recordCount: state.ticketList.metadata.record_count || 0,
    // search: selectors.parseSearchParams(state, props),
    user: state.session.user,
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({});
/* eslint-enable no-unused-vars */

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'MapView' }),
    withTranslation(),
    waitForCondition(() => global.hasOwnProperty('google'), 100),
    withProps(() => ({
        containerElement: <div style={{ width: '100%', height: 500 }} />,
        loadingElement: <div style={{ height: '100%' }} />,
        mapElement: <div style={{ height: '100%' }} />,
    })),
    withGoogleMap,
    connector,
);

export default enhance(MapView);
