// @flow
import React from 'react';
import theme from './ProgressBar.scss';

type Props = {
    value: number,
};

export default function ProgressBar(props: Props) {
    const { value } = props;
    const percentage = Math.max(Math.min(value, 100), 0);

    return (
        <div
          className={theme.progressBar}
          role="progressbar"
          aria-valuenow="100"
          aria-valuemin="0"
          aria-valuemax="100"
        >
            <span className={theme.indicator} style={{ width: `${percentage}%` }} />
            <span className={theme.value}>{percentage}%</span>
        </div>
    );
}
