// @flow
import React, { Component } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import {
    Notifications as NotificationsIcon,
    NotificationsOff as NotificationsOffIcon,
} from '@material-ui/icons';

type Props = {
    className?: string,
    onChange?: (value: boolean) => void,
    readOnly?: boolean,
    tooltip?: string,
    value?: boolean,
};

export default class Alert extends Component<Props> {
    handleClick = () => {
        const { value, onChange } = this.props;
        if (typeof onChange === 'function') onChange(!value);
    };

    render() {
        const { className, readOnly, tooltip, value } = this.props;

        if (readOnly) {
            return (
                <div className={className} style={{ padding: 12 }}>
                    {value ? <NotificationsIcon color="secondary" /> : <NotificationsOffIcon />}
                </div>
            );
        }

        return (
            <Tooltip title={tooltip} placement="top">
                <IconButton
                  className={className}
                  color={value ? 'secondary' : 'inherit'}
                  onClick={this.handleClick}
                >
                    {value ? <NotificationsIcon /> : <NotificationsOffIcon />}
                </IconButton>
            </Tooltip>
        );
    }
}
