// @flow
import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: '100%',
        borderCollapse: 'collapse',

        '@global': {
            'tr:hover': {
                backgroundColor: 'inherit',
            },
            td: {
                border: [[1, 'solid', 'rgba(255, 255, 255, 0.3)']],
            },
        },
    },
    dot: {
        '&::before': {
            content: '""',
            position: 'absolute',
            boxSizing: 'border-box',
            width: 10,
            height: 10,
            border: 0,
            boxShadow: '0 0 1px 0 rgba(0,0,0,0.3)',
            borderRadius: '100%',
            backgroundColor: '#fff',
        },
    },
    leftTop: {
        '&::before': {
            top: -5,
            left: -5,
        },
    },
    rightTop: {
        '&::before': {
            top: -5,
            right: -5,
        },
    },
    leftBottom: {
        '&::before': {
            bottom: -5,
            left: -5,
        },
    },
    rightBottom: {
        '&::before': {
            bottom: -5,
            right: -5,
        },
    },
}), { name: 'Grid' });

export default function Grid() {
    const classes = useStyles();
    return (
        <table className={classes.root}>
            <tr>
                <td className={cx(classes.dot, classes.leftTop)} />
                <td />
                <td className={cx(classes.dot, classes.rightTop)} />
            </tr>
            <tr>
                <td />
                <td />
                <td />
            </tr>
            <tr>
                <td className={cx(classes.dot, classes.leftBottom)} />
                <td />
                <td className={cx(classes.dot, classes.rightBottom)} />
            </tr>
        </table>
    );
}
