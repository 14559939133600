// @flow
import React from 'react';
import { createAction } from 'redux-actions';
import loadImage from 'blueimp-load-image';
import type { Dispatch } from 'redux';
import renderToDataUrl from '../../../../../../../util/renderToDataUrl';
import RunMarker from '../../../../../../../components/icons/RunMarker';
import PhotoMarker from '../../../../../../../components/icons/PhotoMarker';
import EditMarker from '../../../../../../../components/icons/EditMarker';
import types from './types';
import type { MarkerProps } from './reducer';

export const loadMarkersSuccess = createAction(types.LOAD_MARKERS_SUCCESS);
export const loadMarkersError = createAction(types.LOAD_MARKERS_ERROR);
export const loadMarkers = createAction(
    types.LOAD_MARKERS,
    (ticket: Object): Function => (
        (dispatch: Dispatch<any>): Promise<void> => {
            const { location, data_items: dataItems } = ticket;
            const promises = [];
            const svgProps = {
                height: 40,
                width: 40,
                xmlns: 'http://www.w3.org/2000/svg',
            };
            const mapCenter = {
                lat: location.latitude,
                lng: location.longitude,
            };

            promises.push(
                Promise.resolve({
                    icon: {
                        url: renderToDataUrl(
                            <RunMarker {...svgProps} width={60} height={60} fill="#3168aa" />,
                            'image/svg+xml'
                        ),
                    },
                    info: location.formatted_address,
                    position: mapCenter,
                })
            );

            dataItems.forEach((dataItem: Object) => {
                const {
                    data_item_value: values,
                    data_type_value_type: valueType,
                    data_item_latitude: latitude,
                    data_item_longitude: longitude,
                } = dataItem;

                const lat = parseFloat(latitude);
                const lng = parseFloat(longitude);

                if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
                    promises.push(
                        Promise.resolve({
                            icon: {
                                url: renderToDataUrl(
                                    <EditMarker {...svgProps} fill="#00c7ae" />,
                                    'image/svg+xml'
                                ),
                            },
                            info: valueType === 'PHOTO' ? 'gpsVerifiedMap.photoUploaded' : 'gpsVerifiedMap.answerSubmitted',
                            position: { lat, lng },
                        })
                    );
                }

                if (valueType === 'PHOTO') {
                    values.forEach((value: { photo_url: string }) => {
                        promises.push(
                            new Promise((resolve: Function) => {
                                const callback = (img: HTMLImageElement | Event, meta: Object) => {
                                    let resolvedValue;

                                    if ((img instanceof HTMLImageElement) && meta.exif) {
                                        // According to exif standard, GPSLatitude and GPSLongitude each contain 3 rational fields
                                        // representing degrees, minutes, and seconds. Need to convert to a single decimal value
                                        const gpsLat = meta.exif.get('GPSLatitude');
                                        const gpsLng = meta.exif.get('GPSLongitude');
                                        const gpsLatRef = meta.exif.get('GPSLatitudeRef');
                                        const gpsLngRef = meta.exif.get('GPSLongitudeRef');

                                        let photoLat = NaN;
                                        if (gpsLat && gpsLatRef) {
                                            const sign = gpsLatRef === 'S' ? -1 : 1;
                                            photoLat = sign * (gpsLat[0] + ((gpsLat[1] || 0) / 60) + ((gpsLat[2] || 0) / 3600));
                                        }

                                        let photoLng = NaN;
                                        if (gpsLng && gpsLngRef) {
                                            const sign = gpsLngRef === 'W' ? -1 : 1;
                                            photoLng = sign * (gpsLng[0] + ((gpsLng[1] || 0) / 60) + ((gpsLng[2] || 0) / 3600));
                                        }

                                        if (!Number.isNaN(photoLat) && !Number.isNaN(photoLng)) {
                                            resolvedValue = {
                                                icon: {
                                                    url: renderToDataUrl(
                                                        <PhotoMarker {...svgProps} fill="#00c7ae" />,
                                                        'image/svg+xml'
                                                    ),
                                                },
                                                info: <img style={{ 'max-width': '200px' }} src={img.src} alt="" />,
                                                position: {
                                                    lat: photoLat,
                                                    lng: photoLng,
                                                },
                                            };
                                        }
                                    }

                                    resolve(resolvedValue);
                                };

                                loadImage(value.photo_url, callback, { meta: true });
                            })
                        );
                    });
                }
            });

            return Promise.all(promises)
                .then((results: MarkerProps[]) => {
                    const markers = results.filter((v: MarkerProps): boolean => v != null);
                    dispatch(loadMarkersSuccess(markers));
                });
        }
    )
);

export default {
    loadMarkers,
    loadMarkersError,
    loadMarkersSuccess,
};
