// @flow
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import qs from 'qs';
import GeoPoint from 'geopoint';
import moment from 'moment-timezone';
import type { ContextRouter } from 'react-router';
import { USER_ROLES } from '../../../../browser/shared/constant/UserRoles';
import { ticket as ticketSchema } from '../../../redux/entities/schemas';
import type { State as RootState } from '../../../redux/initialState';

type TableData = Array<{
    applications: ?Object[],
    approvalStatus: string,
    assignee: ?Object,
    deadline: ?string,
    description: string,
    dueDate: string,
    id: number,
    location: Object,
    price: ?number,
    scheduleDate: ?string,
    startDate: string,
    status: string,
    timeEstimate: ?number,
    title: string,
}>;

type SearchParams = {
    applicants?: string,
    approval?: string[],
    assignee?: string[],
    dueDate?: {
        from?: string,
        to?: string,
    },
    geo?: {
        lat: string,
        lng: string,
        r: string,
    },
    group?: string[],
    location?: Object[],
    order?: string,
    organization?: string[],
    page?: string,
    q?: string,
    size?: string,
    sort?: string,
    scheduleDate?: {
        from?: string,
        to?: string,
    },
    status?: string[],
    subscription?: string[],
};

type PathParams = {
    orgId: string,
};

type APIParams = {
    filters?: Object[],
    keywords?: string,
    sort?: Object[],
    limit?: number,
    offset?: number,
};

// Maps the key we use in the url query to the key expected by the API
const apiParams = {
    assignee: 'assigned_customer_name',
    dueDate: 'due_date',
    location: 'location_formatted_address',
    scheduleDate: 'calendar_event_start',
    startDate: 'start_date',
    status: 'ticket_status',
    submittedDate: 'date_submitted',
    title: 'title',
};

const getEntities = (state: RootState): Object => state.entities;
const getTickets = createSelector(
    getEntities,
    (state: RootState): number[] => state.ticketList.data,
    (entities: Object, ticketIds: number[]) => denormalize(ticketIds, [ticketSchema], entities),
);

export const getSelectedTickets = createSelector(
    getEntities,
    (state: RootState): number[] => state.ticketList.selected,
    (entities: Object, ticketIds: number[]) => denormalize(ticketIds, [ticketSchema], entities),
);

export const getTableData = createSelector(
    getTickets,
    (tickets: Object[]): TableData => (
        tickets
            .filter((ticket) => !!ticket)
            .map((ticket) => {
                const {
                    applications,
                    approval_status: approvalStatus,
                    calendar_event: calendarEvent,
                    customer: assignee,
                    date_submitted: submittedDate,
                    deadline,
                    description,
                    due_date: dueDate,
                    id,
                    location,
                    organization_id: organizationId,
                    price,
                    start_date: startDate,
                    status,
                    subscription,
                    time_estimate: timeEstimate,
                    title,
                } = ticket;

                const pendingApplications = subscription.is_double_optin
                    ? applications.filter((application: Object) => application.status === 'PENDING')
                    : null;

                return {
                    applications: pendingApplications,
                    approvalStatus,
                    assignee,
                    deadline: deadline ? moment.tz(deadline, location.tzid).format('YYYY-MM-DDTHH:mm:ss') : null,
                    description,
                    dueDate: moment.tz(dueDate, location.tzid).format('YYYY-MM-DDTHH:mm:ss'),
                    id,
                    location,
                    needsPublicWorkforce: subscription.needs_public_workforce,
                    organizationId,
                    price,
                    scheduleDate: calendarEvent.start ? moment.tz(calendarEvent.start, location.tzid).format('YYYY-MM-DDTHH:mm:ss') : null,
                    startDate: moment.tz(startDate, location.tzid).format('YYYY-MM-DDTHH:mm:ss'),
                    submittedDate: moment.utc(submittedDate).tz(location.tzid).format('YYYY-MM-DDTHH:mm:ss'),
                    status,
                    timeEstimate,
                    title,
                };
            })
    )
);

export const parseSearchParams = createSelector(
    <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): string => {
        const { location } = props;
        return location.search;
    },
    (search: string): SearchParams => qs.parse(search, { ignoreQueryPrefix: true })
);

const getMatchParams = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): PathParams => {
    const { match } = props;
    return { orgId: '0', ...match.params };
};
export const getAPIParams = createSelector(
    parseSearchParams,
    getMatchParams,
    (state: RootState) => state.session.user,
    (search: SearchParams, path: PathParams, user: ?Object): APIParams => {
        const { order, page, q, size, sort, ...filters } = search;
        const offset = Math.max(parseInt(page, 10) - 1, 0) || 0;
        const limit = Math.max(parseInt(size, 10), 0) || 10;
        const defaultSort = [{ title: order || 'asc' }];
        const apiFilters = [];

        if (user && user.role === USER_ROLES.PLATFORM_ADMIN) {
            if (filters.organization) {
                apiFilters.push({
                    key: 'organization_id',
                    value: filters.organization.map((id: string): number => parseInt(id, 10)),
                });
            }
        } else {
            apiFilters.push({
                key: 'organization_id',
                value: parseInt(path.orgId, 10),
            });
        }

        if (filters.status) {
            apiFilters.push({
                key: 'ticket_status',
                value: filters.status,
            });
        }

        if (filters.approval) {
            apiFilters.push({
                key: 'approval_status',
                value: filters.approval,
            });
        }

        if (filters.applicants && filters.applicants === 'true') {
            apiFilters.push({
                key: 'applications.status',
                value: 'PENDING',
            });
        }

        if (filters.group) {
            apiFilters.push({
                key: 'group_id',
                value: filters.group.map((id: string): number => parseInt(id, 10)),
            });
        }

        if (filters.dueDate) {
            const { from, to } = filters.dueDate;
            apiFilters.push({
                filter_type: 'range',
                key: 'due_date',
                value: [
                    from ? moment(from).startOf('day').format('YYYY-MM-DDTHH:mm:ss') : null,
                    to ? moment(to).endOf('day').format('YYYY-MM-DDTHH:mm:ss') : null,
                ],
            });
        }

        if (filters.subscription) {
            apiFilters.push({
                key: 'organization_subscription_id',
                value: filters.subscription.map((id: string): number => parseInt(id, 10)),
            });
        }

        if (filters.assignee) {
            apiFilters.push({
                key: 'assigned_customer_id',
                value: filters.assignee.map((id: string): number => parseInt(id, 10)),
            });
        }

        if (filters.geo) {
            // value must be a bounding box [topLeftLat, topLeftLong, bottomRightLat, bottomRightLong]
            const { lat, lng, r } = filters.geo;
            const center = new GeoPoint(parseFloat(lat), parseFloat(lng));
            const [sw, ne] = center.boundingCoordinates(parseInt(r, 10), null, true);
            const boundingBox = [
                ...[ne.latitude(), sw.longitude()],
                ...[sw.latitude(), ne.longitude()],
            ];

            apiFilters.push({
                filter_type: 'viewbox',
                key: 'location_geopoint',
                value: boundingBox,
            });
        }

        if (filters.location) {
            if (filters.location.length > 1) {
                apiFilters.push({
                    filter_type: 'or',
                    filters: filters.location.reduce((locationFilters: Object[], location: Object): Object[] => {
                        const [specificity, value] = Object.entries(location)[0];
                        const filter = specificity === 'name'
                            ? { key: 'location_title', value }
                            : { filter_type: 'address', key: specificity, value };

                        return [...locationFilters, filter];
                    }, []),
                });
            } else {
                const location = filters.location[0];
                const [specificity, value] = Object.entries(location)[0];
                const filter = specificity === 'name'
                    ? { key: 'location_title', value }
                    : { filter_type: 'address', key: specificity, value };

                apiFilters.push(filter);
            }
        }

        if (filters.scheduleDate) {
            const { from, to } = filters.scheduleDate;
            apiFilters.push({
                filter_type: 'range',
                key: 'calendar_event_start',
                value: [
                    from ? moment(from).startOf('day').format('YYYY-MM-DDTHH:mm:ss') : null,
                    to ? moment(to).endOf('day').format('YYYY-MM-DDTHH:mm:ss') : null,
                ],
            });
        }

        return {
            filters: apiFilters,
            keywords: q,
            sort: (sort && sort in apiParams) ? [{ [apiParams[sort]]: order || 'asc' }] : defaultSort,
            limit,
            offset,
        };
    }
);

export default {
    getAPIParams,
    getSelectedTickets,
    getTableData,
    parseSearchParams,
};
