// @flow
import { createMuiTheme } from '@material-ui/core';
import createTypography from './createTypography';
import createOverrides from './createOverrides';

/* eslint-disable no-useless-computed-key */
const grey = {
    [50]: '#f9fdff',
    [100]: '#f0f3f6', // $neutral-2
    [200]: '#eceff2', // ~ $neutral-7
    [300]: '#dee2e6', // ~ $neutral-7
    [400]: '#cfd8df', // $neutral-13
    [500]: '#8e9499', // $neutral-40
    [600]: '#767b7f',
    [700]: '#606568',
    [800]: '#3b3f42',
    [900]: '#212223',
    A100: '#d5d9de',
    A200: '#a7adb1',
    A400: '#2e3236',
    A700: '#61666b',
};
/* eslint-enable no-useless-computed-key */

const palette = {
    type: 'light',
    primary: {
        xlight: '#edf8fd',
        light: '#4bbfeb',
        main: '#3168aa',
        dark: '#12406f',
        xdark: '#0e3257',
        contrastText: '#fff',
    },
    secondary: {
        light: '#fdc449',
        main: '#ee6749',
        dark: '#ee6749',
        contrastText: '#fff',
    },
    grey,
    error: {
        light: '#f5d2d8',
        main: '#cd1e3d',
        dark: '#cd1e3d',
        contrastText: '#fff',
    },
    success: {
        light: '#00c7ae',
        main: '#00c7ae',
        dark: '#00c7ae',
        contrastText: '#fff',
    },
    background: {
        default: grey[100],
    },
};

const theme = createMuiTheme({
    palette,
    spacing: 8,
    typography: createTypography(palette),
});

export default { ...theme, overrides: createOverrides(theme) };
