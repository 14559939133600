// @flow
import React from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import { Icon } from '@material-ui/core';
import styles from './styles';

type Props = {
    classes: Object,
    className?: string,
    theme: Object,
};

export const Spinner = (props: Props) => {
    const { classes, className, ...rest } = props;
    return (
        <Icon {...rest} className={cx(classes.spinner, className)}>sync</Icon>
    );
};

export default withStyles(styles, { name: 'Spinner' })(Spinner);
