// @flow
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import { format } from '../../../../../../browser/shared/util/gigwalkApiErrorUtil';
import { deleteEvent as deleteTicketEvent } from '../../../../../redux/entities/tickets';
import * as snackbar from '../../../../../ducks/snackbar';
import types from './types';

export const deleteCommentSuccess = createAction(types.DELETE_COMMENT_SUCCESS);
export const deleteCommentError = createAction(types.DELETE_COMMENT_ERROR);
export const deleteComment = createAction(
    types.DELETE_COMMENT,
    (id: string): Function => (
        (dispatch: Dispatch<any>): Promise<void> => {
            const params = { ticket_event_id: id };
            return dispatch(deleteTicketEvent(params))
                .then(() => {
                    dispatch(deleteCommentSuccess());
                })
                .catch((err: $AxiosError<Object>) => {
                    dispatch(deleteCommentError(err));
                    const resp = err ? err.response : null;
                    if (resp && resp.data && resp.data.gw_api_response) {
                        const message = format(resp.data.gw_api_response);
                        dispatch(snackbar.actions.enqueue(message, { variant: 'error' }));
                    }
                    return Promise.reject();
                });
        }
    )
);

export default {
    deleteComment,
    deleteCommentError,
    deleteCommentSuccess,
};
