// @flow
export const HIDE_CONFIRMATION_DIALOG = 'g/payments/HIDE_CONFIRMATION_DIALOG';

export const CREATE_STRIPE_TOKEN = 'g/payments/CREATE_STRIPE_TOKEN';
export const CREATE_STRIPE_TOKEN_SUCCESS = `${CREATE_STRIPE_TOKEN}_SUCCESS`;
export const CREATE_STRIPE_TOKEN_ERROR = `${CREATE_STRIPE_TOKEN}_ERROR`;

export const GET_BALANCE = 'g/payments/GET_BALANCE';
export const GET_BALANCE_SUCCESS = `${GET_BALANCE}_SUCCESS`;
export const GET_BALANCE_ERROR = `${GET_BALANCE}_ERROR`;

export const ADD_FUNDS = 'g/payments/ADD_FUNDS';
export const ADD_FUNDS_SUCCESS = `${ADD_FUNDS}_SUCCESS`;
export const ADD_FUNDS_ERROR = `${ADD_FUNDS}_ERROR`;

export default {
    ADD_FUNDS,
    ADD_FUNDS_SUCCESS,
    ADD_FUNDS_ERROR,
    CREATE_STRIPE_TOKEN,
    CREATE_STRIPE_TOKEN_SUCCESS,
    CREATE_STRIPE_TOKEN_ERROR,
    GET_BALANCE,
    GET_BALANCE_SUCCESS,
    GET_BALANCE_ERROR,
    HIDE_CONFIRMATION_DIALOG,
};
