// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Slider } from '@material-ui/lab';
import { Button, OutlinedInput, Typography } from '@material-ui/core';
import {
    RotateLeft as RotateLeftIcon,
    RotateRight as RotateRightIcon,
} from '@material-ui/icons';
import typeof TuiImageEditor from 'tui-image-editor';

type Props = {
    editor: TuiImageEditor,
}

const useStyles = makeStyles((theme: Object) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(2, 0),
    },
    button: {
        margin: theme.spacing(0, 1),
    },
    buttonLabel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    sliderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth: 300,
        marginTop: 12,
    },
    slider: {
        margin: theme.spacing(0, 2),
    },
    input: {
        width: 60,
        flexShrink: 0,
        borderColor: 'white',
        color: 'white',
        pointerEvents: 'none',

        '& $notchedOutline': {
            borderColor: 'rgba(255, 255, 255, 0.23)',
        },
    },
    inputElement: {
        textAlign: 'center',
        paddingTop: 8,
        paddingBottom: 8,
    },
    notchedOutline: {},
}));

export default function RotateMenu(props: Props) {
    const { editor } = props;
    const classes = useStyles(props);

    const [sliderValue, setSliderValue] = useState(editor._graphics.getCanvasImage().angle);

    useLayoutEffect(() => {
        editor.setAngle(sliderValue);
    }, [editor, sliderValue]);

    const handleRotateClick = useCallback((event: SyntheticEvent<any>) => {
        switch (event.currentTarget.name) {
            case 'rotateLeft':
                setSliderValue(sliderValue - 30);
                break;
            case 'rotateRight':
                setSliderValue(sliderValue + 30);
                break;
            default:
                break;
        }
    }, [sliderValue]);

    const handleSliderChange = useCallback((event: SyntheticEvent<any>, newValue: number) => {
        setSliderValue(newValue);
    }, []);

    const buttonProps = {
        classes: {
            root: classes.button,
            label: classes.buttonLabel,
        },
        color: 'inherit',
        onClick: handleRotateClick,
    };

    return (
        <div className={classes.root}>
            <div className={classes.controls}>
                <Button {...buttonProps} name="rotateRight">
                    <RotateRightIcon fontSize="large" />
                    30
                </Button>
                <Button {...buttonProps} name="rotateLeft">
                    <RotateLeftIcon fontSize="large" />
                    -30
                </Button>
            </div>
            <div className={classes.sliderContainer}>
                <Typography color="inherit" variant="subtitle1">
                    Angle
                </Typography>
                <Slider className={classes.slider} max={360} min={-360} onChange={handleSliderChange} step={1} value={sliderValue} />
                <OutlinedInput
                  classes={{
                      root: classes.input,
                      notchedOutline: classes.notchedOutline,
                  }}
                  inputProps={{ className: classes.inputElement }}
                  labelWidth={0}
                  readOnly
                  value={sliderValue}
                />
            </div>
        </div>
    );
}
