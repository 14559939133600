// @flow
import { createAction } from 'redux-actions';
import axios from 'axios';
import type { Dispatch } from 'redux';
import type { $AxiosXHR, $AxiosError } from 'axios';
import types from './types';
import * as session from '../../../ducks/session';

type FormValues = {
    email: string,
    password: string,
    rememberMe: boolean,
};

export const GENERIC_LOGIN_ERROR = 'Unspecified error occurred, please try again.';

export const submitError = createAction(types.SUBMIT_ERROR);
export const submitSuccess = createAction(types.SUBMIT_SUCCESS);
export const submit = createAction(
    types.SUBMIT,
    (values: FormValues): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            // trackEvent(SUBMIT_LOGIN);
            const { email, password, rememberMe } = values;
            const payload = {
                password,
                rememberMe: !!rememberMe,
                username: email,
            };

            return axios.post('/auth/login', payload)
                .then((resp: $AxiosXHR<Object>) => {
                    const { user, features, profile } = resp.data;
                    dispatch(session.actions.initialize({ user, features, profile }));
                    dispatch(session.actions.start());
                    dispatch(submitSuccess());
                })
                .catch((err: $AxiosError<Object>) => {
                    let error = GENERIC_LOGIN_ERROR;
                    if ('response' in err) {
                        const { data } = err.response;
                        if (typeof data.message === 'string') {
                            error = data.message;
                        }
                    }
                    dispatch(submitError(err));
                    return Promise.reject(error);
                });
        }
    )
);

export default {
    submit,
    submitError,
    submitSuccess,
};
