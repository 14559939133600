// @flow
import required from './required';

/**
 * Given a number N, returns a function that will validate whether or not
 * the specified value has a length of at least N.
 * @param n {number}
 * @returns {Function}
 */
export default (n: number): Function => (
    (value: any): ?string | ?Object => {
        const requiredError = required(value);
        if (requiredError) return;

        if (!value.hasOwnProperty('length')) {
            return 'invalidValue';
        }

        if (value.length < n) {
            return {
                message: 'tooShort',
                minLength: n,
                length: value.length,
            };
        }
    }
);
