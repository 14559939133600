// @flow
export const FEATURES = {
    INITIATIVE: 'INITIATIVE',
    BILLING_CODE: 'BILLING_CODE',
    PAY_TYPE_DROPDOWN: 'PAY_TYPE_DROPDOWN',
    GROUP_DROPDOWN: 'GROUP_DROPDOWN',
    REQUIREMENT_DROPDOWN: 'REQUIREMENT_DROPDOWN',
    REQUIRED_MATERIALS: 'REQUIRED_MATERIALS',
    DIVISION_DROPDOWN: 'DIVISION_DROPDOWN',
    CLIENTS_DROPDOWN: 'CLIENTS_DROPDOWN',
    DISTRIBUTION: 'DISTRIBUTION',
    AUTO_ASSIGN: 'AUTO_ASSIGN',
    SKIP_LOGIC: 'SKIP_LOGIC',
    BARCODE: 'BARCODE',
    PAGES_PER_REP: 'PAGES_PER_REP',
    PAGES_PER_STORE: 'PAGES_PER_STORE',
    CARD_PRICE: 'CARD_PRICE',
    EXECUTION_CAT: 'EXECUTION_CAT',
    NEW_DATA_LIST: 'NEW_DATA_LIST',
};

export const NO_WAVE = 'no_wave';

export const STATE = Object.freeze({
    ACTIVE: 'ACTIVE',
    CANCELED: 'CANCELED',
    DRAFT: 'DRAFT',
    ARCHIVED: 'ARCHIVED',
});

export const FREQUENCY_WEEKDAY = {
    ALL: 'MO,TU,WE,TH,FR',
    MO_WE_FR: 'MO,WE,FR',
    TU_TH: 'TU,TH',
    MO: 'MO',
    TU: 'TU',
    WE: 'WE',
    TH: 'TH',
    FR: 'FR',
    SA: 'SA',
    SU: 'SU',
};

export const FREQUENCY_TYPE = {
    DAYS: 'days',
    WEEKS: 'weeks',
    MONTHS: 'months',
    YEARS: 'years',
};

export const COMPARATIVE = Object.freeze({
    GREATER_THAN: '$gt',
    GREATER_THAN_OR_EQUAL: '$gte',
    LESS_THAN: '$lt',
    LESS_THAN_OR_EQUAL: '$lte',
    EQUALTO: '$eq',
});

export const VALUE_TYPE = Object.freeze({
    MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
    MULTI_SELECT: 'MULTI_SELECT',
    NUMBER: 'NUMBER',
    CURRENCY: 'CURRENCY',
    PHONE_NUMBER: 'PHONE_NUMBER',
    PHOTO: 'PHOTO',
    FREE_TEXT: 'FREE_TEXT',
    ITEM_GRID: 'ITEM_GRID',
    DATE: 'DATE',
    DATE_TIME: 'DATE_TIME',
    TIME: 'TIME',
    TASK: 'TASK',
    STEP_TASK: 'STEP_TASK',
    BARCODE: 'BARCODE',
    HINT: 'HINT',
    CHECK: 'CHECK',
    CHECKBOXES: 'CHECKBOXES',
    SIGNATURE: 'SIGNATURE',
});

export const BARCODE_TITLE = ' - Barcode: ';

export const LOCATION_OVERRIDE_LIST_PREFIX = 'Autogenerated List - Location Override - Filename: ';

export const VALIDATED = 'isValidated';

export const OPTIN_TYPES = {
    NO_OPTIN: 'NO_OPTIN',
    SIMPLE_OPTIN: 'SIMPLE_OPTIN',
    DOUBLE_OPTIN: 'DOUBLE_OPTIN',
    SYSTEM_APPROVED_OPTIN: 'SYSTEM_APPROVED_OPTIN',
};

export const SCHEDULE_TYPES = {
    ASAP: 'ASAP',
    RANGE: 'RANGE',
    RECURRING_ASAP: 'RECURRING_ASAP',
    RECURRING_RANGE: 'RECURRING_RANGE',
};

export const QUESTION_STATUS = {
    ERROR: 'error',
    COMPLETED: 'completed',
};

export default {
    BARCODE_TITLE,
    COMPARATIVE,
    FEATURES,
    FREQUENCY_TYPE,
    FREQUENCY_WEEKDAY,
    LOCATION_OVERRIDE_LIST_PREFIX,
    NO_WAVE,
    OPTIN_TYPES,
    QUESTION_STATUS,
    SCHEDULE_TYPES,
    STATE,
    VALIDATED,
    VALUE_TYPE,
};
