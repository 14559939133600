// @flow
export default (theme: Object) => ({
    drawerPaper: {
        background: theme.palette.primary.dark,
        overflow: 'visible',
        width: 240,
    },
    collapsed: {
        width: 70,
    },
    logo: {
        position: 'relative',
        minHeight: 80,
        marginBottom: 20,

        '&>img': {
            width: 190,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate3D(-50%, -50%, 0)',
        },
    },
    navigation: {},
    expander: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        display: 'block',
        width: '100%',
        padding: [[12, theme.spacing(1)]],

        background: theme.palette.primary.xdark,
        color: theme.palette.getContrastText(theme.palette.primary.xdark),
        textAlign: 'right',
        fontSize: 25,
        lineHeight: 1,

        '&:focus': {
            boxShadow: 'none',
            outline: 'none',
        },

        // The sass darken function seems to work differently than the material-ui one,
        // so I've copied the computed hex values from Inspector.
        '&:hover': {
            // backgroundColor: desaturate(darken($color, 7%), 7%),
            backgroundColor: '#2f5888',
        },

        '&:active': {
            // border-top-color: darken($color, 20%);
            // backgroundColor: darken($color, 3%),
            backgroundColor: '#2e619e',
        },
    },
});
