// @flow
import React, { Component } from 'react';
import moment from 'moment';
import cx from 'classnames';
import { DateRangePickerInput } from 'react-dates';
import { withStyles } from '@material-ui/styles';
import { FormControl, FormHelperText, FormLabel, IconButton } from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';
import reactDatesTheme from './theme.scss';

type Props = {
    className?: string,
    classes: Object,
    disabled?: boolean,
    endDatePlaceholder?: string,
    error?: string,
    focusedInput: ?('startDate' | 'endDate'),
    label?: string,
    onChange?: ({ endDate: ?string, startDate: ?string }) => void,
    onFocus?: ('startDate' | 'endDate') => void,
    onBlur?: () => void,
    startDatePlaceholder?: string,
    value?: {
        endDate: string | moment$Moment | Date,
        startDate: string | moment$Moment | Date,
    },
};

const defaultInputProps = {
    showDefaultInputIcon: false,
    small: true,
};

const styles = () => ({
    clearButton: {
        padding: 7,
        fontSize: 16,
    },
    error: {
        marginTop: 5,
        lineHeight: 'inherit',
    },
});

class DateRangeInput extends Component<Props> {
    handleStartDateChange = (newStartDate: string) => {
        const { value, onChange } = this.props;
        const endDate = moment(value ? value.endDate || null : null);
        const startDate = moment(newStartDate);
        if (typeof onChange === 'function') {
            onChange({
                endDate: endDate.isValid() ? endDate.format('YYYY-MM-DD') : '',
                startDate: startDate.isValid() ? startDate.format('YYYY-MM-DD') : '',
            });
        }
    };

    handleStartDateFocus = () => {
        const { onFocus } = this.props;
        if (typeof onFocus === 'function') {
            onFocus('startDate');
        }
    };

    handleEndDateChange = (newEndDate: string) => {
        const { onChange, value } = this.props;
        const endDate = moment(newEndDate);
        const startDate = moment(value ? value.startDate || null : null);
        if (typeof onChange === 'function') {
            onChange({
                endDate: endDate.isValid() ? endDate.format('YYYY-MM-DD') : '',
                startDate: startDate.isValid() ? startDate.format('YYYY-MM-DD') : '',
            });
        }
    };

    handleEndDateFocus = () => {
        const { onFocus } = this.props;
        if (typeof onFocus === 'function') {
            onFocus('endDate');
        }
    };

    handleClearDates = () => {
        const { onChange } = this.props;
        if (typeof onChange === 'function') {
            onChange({ endDate: '', startDate: '' });
        }
    };

    render() {
        const { className, classes, error, focusedInput, label, value, ...inputProps } = this.props;
        const { disabled, endDatePlaceholder, startDatePlaceholder } = inputProps;
        const invalid = !!error && !disabled;

        let startDate = moment(value ? value.startDate || null : null);
        let endDate = moment(value ? value.endDate || null : null);
        startDate = startDate.isValid() ? startDate : null;
        endDate = endDate.isValid() ? endDate : null;

        return (
            <FormControl
              className={cx(reactDatesTheme.dateInput, { invalid, disabled }, className)}
              error={invalid}
              margin={label ? 'normal' : 'none'}
            >
                {label ? <FormLabel>{label}</FormLabel> : null}
                <DateRangePickerInput
                  {...defaultInputProps}
                  {...inputProps}
                  customCloseIcon={(
                      <IconButton className={classes.clearButton} component="div">
                          <ClearIcon fontSize="inherit" />
                      </IconButton>
                  )}
                  endDate={endDate ? endDate.format('M/D/YYYY') : ''}
                  endDatePlaceholderText={endDatePlaceholder || ''}
                  isStartDateFocused={focusedInput === 'startDate'}
                  isEndDateFocused={focusedInput === 'endDate'}
                  onClearDates={this.handleClearDates}
                  onEndDateChange={this.handleEndDateChange}
                  onEndDateFocus={this.handleEndDateFocus}
                  onStartDateChange={this.handleStartDateChange}
                  onStartDateFocus={this.handleStartDateFocus}
                  startDate={startDate ? startDate.format('M/D/YYYY') : ''}
                  startDatePlaceholderText={startDatePlaceholder || ''}
                />
                {error ? <FormHelperText className={classes.error}>{error}</FormHelperText> : null}
            </FormControl>
        );
    }
}

export default withStyles(styles, { name: 'DateRangeInput' })(DateRangeInput);
