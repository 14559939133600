// @flow
/* global google */
// $FlowIssue need to update to a more recent flow version
import React, { Children, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { compose, withProps } from 'recompose';
import {
    withGoogleMap,
    // withScriptJs
    GoogleMap as ReactGoogleMap,
    Marker,
} from 'react-google-maps';
import type { Node } from 'react';

type Props = {
    children: Node,
    height?: number | string,
    width?: number | string,
};

export function GoogleMap(props: Props) {
    const { children } = props;

    const [ready, setReady] = useState(false);
    const mapRef = useRef(null);

    useLayoutEffect(() => {
        const map = mapRef.current;

        // Adjust the bounds of the map to include all markers. Make sure that map is
        // initialized before calling fitBounds
        if (map !== null && ready) {
            const currentBounds = map.getBounds();
            let bounds = new google.maps.LatLngBounds();

            if (currentBounds) {
                const sw = currentBounds.getSouthWest();
                const ne = currentBounds.getNorthEast();
                bounds = new google.maps.LatLngBounds(sw, ne);
            }

            Children.forEach(children, (child: React$Element<any>) => {
                if (child && child.type === Marker) {
                    const { position } = child.props;
                    bounds = bounds.extend(position);
                }
            });

            if (!currentBounds || !currentBounds.equals(bounds)) {
                map.fitBounds(bounds);
            }
        }
    }, [children, ready]);

    const handleMapIdle = useCallback(() => {
        setReady(true);
    }, []);

    return (
        <ReactGoogleMap
          {...props}
          onIdle={handleMapIdle}
          ref={mapRef}
        >
            {children}
        </ReactGoogleMap>
    );
}

// todo: switch to withScriptjs once other google map dependents have been deprecrated
const enhance = compose(
    withProps((ownProps: Props) => {
        const { height, width } = ownProps;
        const containerStyle = {
            height: height != null ? height : '100%',
            width: width != null ? width : '100%',
        };
        return {
            // googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDjj56khdS0a5K6ZLt4nsL74CuCPF08U58',
            containerElement: <div style={containerStyle} />,
            loadingElement: <div style={{ height: '100%' }} />,
            mapElement: <div style={{ height: '100%' }} />,
        };
    }),
    // withScriptjs,
    withGoogleMap,
);

export default enhance(GoogleMap);
