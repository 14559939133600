// @flow
export default (theme: Object) => ({
    root: {
        maxWidth: 1100,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(0, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    logo: {
        maxWidth: 150,
        padding: theme.spacing(1.5, 0, 1),
    },
    gigwalkerSignup: {
        display: 'flex',
        flexBasis: '65%',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    mobileApps: {
        marginLeft: theme.spacing(2),
        marginTop: 2,

        '& > *': {
            textDecoration: 'none',

            '&:not(:last-child) ': {
                marginRight: 5,
            },
        },
    },
});
