// @flow
import React from 'react';
import type { Node } from 'react';

export type Props = {
    children?: Node,
    title?: string,
};

export default function SimplePane(props: Props) {
    const { children, title } = props;
    return (
        <div className="c-content">
            {title ? <h2>{title}</h2> : ''}
            <div className="u-margin-bottom-lg">
                {children}
            </div>
        </div>
    );
}
