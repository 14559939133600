// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import types from './types';
import typeof { updateStepErrors } from './actions';

export type StepErrors = {
    info: string[],
    locations: string[],
    people: string[],
    data: string[],
    launch: [],
};

export type State = {
    errors: StepErrors,
};

export const init: State = {
    errors: {
        info: [],
        locations: [],
        people: [],
        data: [],
        launch: [],
    },
};

export default handleActions({
    [types.UPDATE_STEP_ERRORS]: (state: State, action: ActionType<updateStepErrors>) => ({
        ...state,
        errors: action.payload,
    }),
}, init);
