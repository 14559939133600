// @flow
export default () => ({
    spinner: {
        animation: 'sync 1s infinite linear',
        transform: 'scaleX(-1)',
    },
    '@keyframes sync': {
        '0%': { transform: 'scaleX(-1) rotate(0deg)' },
        '100%': { transform: 'scaleX(-1) rotate(-360deg)' },
    },
});
