// @flow
export default (theme: Object) => ({
    '@global body': {
        background: theme.palette.background.default, // theme.palette.background.appBar, $neutral-2
    },
    projectBuilder: {
        display: 'flex',
        flexDirection: 'column',
        padding: [['0', '4vw']],
    },
    navigation: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2, 0),
    },
    nextButton: {
        position: 'relative',
        '&:only-child': {
            marginLeft: 'auto',
        },
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    loading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '60vh',
    },
});
