// @flow
import moment from 'moment';
import required from './required';

// TODO: Define an array of acceptable date formats to pass to moment
// By default, moment is not very strict when parsing date strings.
// However, you can specify an array of formats as the 2nd argument
// and pass true as the 3rd argument to enable strict mode

/**
 * Returns a validation error if value is not a date
 * @param value
 * @returns {string}
 */
export default (value: any): ?string => {
    const requiredError = required(value);
    if (requiredError) return;

    const date = moment(value);
    if (!date.isValid()) {
        return 'invalidDate';
    }
};
