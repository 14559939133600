var Handlebars = require('/tmp/build_c41768ab/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<span class=\"sub-target\">"
    + alias4(((helper = (helper = helpers.for_target || (depth0 != null ? depth0.for_target : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"for_target","hash":{},"data":data}) : helper)))
    + ": "
    + alias4(((helper = (helper = helpers.targetName || (depth0 != null ? depth0.targetName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetName","hash":{},"data":data}) : helper)))
    + "</span><br>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<!-- question type is \"enter a description\" -->\n<div class=\"type written-description\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-targetid=\""
    + alias4(((helper = (helper = helpers.targetID || (depth0 != null ? depth0.targetID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetID","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"answers\">\n    <div class=\"answer\">\n      <div class=\"description\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.targetName : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias4((helpers.nl2br || (depth0 && depth0.nl2br) || alias2).call(alias1,(depth0 != null ? depth0.question_text : depth0),{"name":"nl2br","hash":{},"data":data}))
    + "</div>\n      <textarea name=\"target1 answer text\" data-input data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-action=\"description\" placeholder=\""
    + alias4(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placeholder","hash":{},"data":data}) : helper)))
    + "\"></textarea>\n    </div>\n  </div>\n</div><!-- END enter a description -->\n";
},"useData":true});