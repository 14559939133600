// @flow
export default (theme: Object) => ({
    infoBox: {
        fontFamily: 'ProximaNova',
        fontSize: 12,

        width: 240,
        overflow: ['visible', '!important'],
        position: 'relative',
        background: theme.palette.common.white,
        boxShadow: '0px 2px 2px 0 rgba(0, 0, 0, 0.35)',
        borderRadius: 4,

        '&:after': {
            content: '""',
            position: 'absolute',
            width: 0,
            height: 0,
            marginLeft: -6,
            bottom: -6,
            left: '50%',
            border: [[6, 'solid', theme.palette.common.white]],
            borderTopColor: 'transparent',
            borderRightColor: 'transparent',
            transform: 'rotate(-45deg)',
            boxShadow: '-2px 2px 2px 0 rgba(0, 0, 0, 0.35)',
        },
    },

    infoPrimary: {
        padding: [[10, 13]],
        display: 'flex',
        flexDirection: 'column',

        '& > *': {
            marginBottom: 4,
        },
    },

    infoSecondary: {
        padding: [[10, 13]],
        borderTop: [[1, 'solid', theme.palette.grey[400]]],
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    infoIcon: {
        width: 12,
        height: 12,
        marginRight: theme.spacing(0.5),
    },

    locationTitle: {
        fontWeight: 600,
    },

    assignee: {
        color: theme.palette.grey[500],
    },

    dueDate: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    timeEstimate: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
