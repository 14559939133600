// @flow weak
import React from 'react';
import ReactDOM, { render } from 'react-dom';
import axios from 'axios';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from '@material-ui/styles';
import Widget from './Widget';
import { USER_ROLES } from '../../../../shared/constant/UserRoles';
import ProjectConstant from '../../../../shared/constant/ProjectConstant';
import DateUtil from '../../../../shared/util/DateUtil';
import LangUtil from '../../../../shared/util/LangUtil';
import Upload from '../../../../shared/view/ui/Upload';
import LabeledDateTimePicker from '../../../../shared/components/DateTime/LabeledDateTimePicker';
import template from '../../../templates/ticket/widgets/task/Task.hbs';
import addedImageTPL from '../../../templates/ticket/widgets/task/AddedImage.hbs';
import describeTPL from '../../../templates/ticket/widgets/task/Describe.hbs';
import instructionTPL from '../../../templates/ticket/widgets/task/Instruction.hbs';
import itemGridTPL from '../../../templates/ticket/widgets/task/ItemGrid.hbs';
import photoTPL from '../../../templates/ticket/widgets/task/Photo.hbs';
import numericTPL from '../../../templates/ticket/widgets/task/Number.hbs';
import phoneNumberTPL from '../../../templates/ticket/widgets/task/PhoneNumber.hbs';
import dateTPL from '../../../templates/ticket/widgets/task/Date.hbs';
import timeTPL from '../../../templates/ticket/widgets/task/Time.hbs';
import currencyTPL from '../../../templates/ticket/widgets/task/Currency.hbs';
import { dataUriToBlob } from '../../../../shared/util/FileUtil';
import Lightbox from '../../../../../common/components/Lightbox';
import ImageEditor from '../../../../../common/components/ImageEditor';
import ExifViewer from '../../../../../common/components/ExifViewer';
import theme from '../../../../../styles/theme';

// Roles that can edit answer on behalf of worker
const delegateRoles = [USER_ROLES.SUPER_ADMIN, USER_ROLES.SELF_SERVICE, USER_ROLES.PLATFORM_ADMIN];

/**
 * @class (View) Task
 * @constructor
 */
export default Widget.extend({

    /**
     * @type string
     * @property className
     */
    className: '',

    /**
     * @property events
     */
    events: {
        'click [data-action="view-metadata"]': 'viewTargetMetadata',
        'click [data-action="next"]': 'next',
        'click [data-action="save"]': 'save',
        'click input[type="radio"]': 'handleRadioClick',
    },

    timestamp: null,

    versions: {},

    initialize(options: Object) {
        this.timestamp = Date.now();
        this._super(options);
    },

    render() {
        const { index, ticket } = this.props;
        this.currentIndex = 0;
        this.skipLogicStack = [];

        const dataType = ticket.data_type_map[this.instruction.get('data_type_id')];
        const attachments = this.groupAttachments(dataType.attachments);
        const targetID = this.instruction.get('observation_target_id');
        const targetName = ticket.observation_target_map[targetID];
        const targetMetadata = ticket.observation_target_metadata_map[targetID];

        this.$el.html(template(_.extend({}, this.text, {
            questionNumber: index != null ? index + 1 : null,
            target: targetName,
            target_id: targetID,
            title: LangUtil.replaceTitle(dataType.description, targetName),
            images: attachments.images,
            files: attachments.files,
            hasMetadata: _.size(targetMetadata) > 0,
        })));

        this.$steps = this.$el.find('[data-target="steps"]:first');
        this.$save = this.$el.find('[data-action="save"]:last');
        this.$next = this.$el.find('[data-action="next"]:last');

        const lightboxContainer = this.$el.find('.react-lightbox-container')[0] || document.createElement('div');
        lightboxContainer.classList.add('react-lightbox-container');
        this.$el.append(lightboxContainer);

        const imageEditorContainer = this.$el.find('.react-image-editor-container')[0] || document.createElement('div');
        imageEditorContainer.classList.add('react-image-editor-container');
        this.$el.append(imageEditorContainer);

        const exifViewerContainer = this.$el.find('.react-exif-viewer-container')[0] || document.createElement('div');
        exifViewerContainer.classList.add('react-exif-viewer-container');
        this.$el.append(exifViewerContainer);

        this.populate();
        this.validateInput();

        this._super();
    },

    populate() {
        // Use depth-first search to get steps in correct order
        const flatten = (step: Object) => {
            this.skipLogicStack.push(step);

            const dataTypeID = step.instruction.get('data_type_id');
            const targetID = step.instruction.get('observation_target_id');
            const dataItem = _.findWhere(this.ticket.data_items, {
                data_type_id: dataTypeID,
                observation_target_id: targetID,
            });
            let nextSteps;


            if (dataItem && dataItem.data_item_value.length) {
                nextSteps = step.instruction.evaluate(dataItem.data_item_value);
                _.each(nextSteps, (instruction: Object) => {
                    flatten({
                        parent_id: dataTypeID,
                        instruction,
                    });
                });
            }
        };

        // TODO: Add a flatten method to SkipLogicTree. Will need to pass in data_items map
        flatten({ instruction: this.instruction });

        _.each(this.skipLogicStack, (step: Object) => {
            this.insertForm(step.instruction);
        }, this);

        this.currentIndex = this.skipLogicStack.length - 1;
    },

    insertForm(instruction: Object) {
        const { ticket } = this.props;
        const dataTypeID = instruction.get('data_type_id');
        const dataType = ticket.data_type_map[dataTypeID];
        const targetID = instruction.get('observation_target_id'); // || this.targetID;
        const targetName = ticket.observation_target_map[targetID];
        const dataItem = _.findWhere(ticket.data_items, {
            data_type_id: dataTypeID,
            observation_target_id: targetID,
        });
        const tplData = _.extend({
            id: dataTypeID,
            targetID,

            // targetName: targetName,
            description: dataType.description,

            // TODO: Just use dataType.questions.question_text when/if bad data is deleted on BE
            // Workaround for bad data caused by a bug in project builder that recorded wrong data for questions.question_text
            question_text: dataType.questions.question_text.number
                ? dataType.questions.question_text.number
                : LangUtil.replaceTitle(dataType.questions.question_text, targetName),
        }, this.text);

        let $step;

        // TODO: Check dataType.questions.question_type instead?
        // According to the docs... Each data type object has a “value_type” which defines its behaviour.
        // There is also a “question_type” which defines the format used for display
        switch (dataType.value_type) {
            case ProjectConstant.VALUE_TYPE.PHOTO:
                $step = $(photoTPL(tplData));
                this.initPhoto($step, dataItem);
                break;

            case ProjectConstant.VALUE_TYPE.ITEM_GRID:
            case ProjectConstant.VALUE_TYPE.MULTIPLE_CHOICE:
                _.extend(tplData, {
                    choices: dataType.questions.propositions,
                    type: 'radio',
                    uniqueID: _.uniqueId('radio'),
                });
                $step = $(itemGridTPL(tplData));
                this.initChoices($step, dataItem);
                break;

            case ProjectConstant.VALUE_TYPE.MULTI_SELECT:
            case ProjectConstant.VALUE_TYPE.CHECKBOXES:
                _.extend(tplData, {
                    choices: dataType.questions.propositions,
                    type: 'checkbox',
                    uniqueID: _.uniqueId('checkbox'),
                });
                $step = $(itemGridTPL(tplData));
                this.initChoices($step, dataItem);
                break;

            case ProjectConstant.VALUE_TYPE.NUMBER:
                $step = $(numericTPL(tplData));
                this.initNumber($step, dataItem);
                break;

            case ProjectConstant.VALUE_TYPE.PHONE_NUMBER:
                $step = $(phoneNumberTPL(tplData));
                this.initPhoneNumber($step, dataItem);
                break;

            case ProjectConstant.VALUE_TYPE.CURRENCY:
                $step = $(currencyTPL(tplData));
                this.initNumber($step, dataItem);
                break;

            case ProjectConstant.VALUE_TYPE.DATE_TIME:
            case ProjectConstant.VALUE_TYPE.DATE:
                $step = $(dateTPL(tplData));
                this.initDatePicker($step, dataType.value_type === ProjectConstant.VALUE_TYPE.DATE_TIME, dataItem);
                break;

            case ProjectConstant.VALUE_TYPE.TIME:
                $step = $(timeTPL(tplData));
                this.initTime($step, dataItem);
                break;

            case ProjectConstant.VALUE_TYPE.TASK:
            case ProjectConstant.VALUE_TYPE.HINT:
                $step = $(instructionTPL(tplData));
                break;

            case ProjectConstant.VALUE_TYPE.FREE_TEXT:
                $step = $(describeTPL(tplData));
                this.initDescribe($step, dataItem);
                break;
            default:
                break;
        }

        if ($step) {
            $step.on('change keyup', _.bind(this.onChoiceUpdated, this));
        }

        this.$steps.append($step);

        /* Show/hide red asterix */
        this.toggleAsterix($step);
    },

    toggleAsterix() {
        const { ticket } = this.props;
        const $children = this.$steps.children('[data-id]');
        _.each($children, (el: JQuery) => {
            const $el = $(el);
            const id: number = parseInt($el.data('id'), 10);
            const isRequired = !!ticket.data_type_map[id].is_required;

            this.$('[data-target="isRequired"]:first').toggleClass('optional', !isRequired);
            $el.find('.answers:first').toggleClass('required', isRequired);
        }, this);
    },

    handleRadioClick(event: JQueryEventObject) {
        const { target } = event;
        const { ticket, user } = this.props;

        if (target instanceof HTMLInputElement) {
            const dataType = ticket.data_type_map[target.dataset.id];
            const canSaveBlankAnswer = delegateRoles.includes(user.role) && dataType.is_required === false;
            const isChecked = target.dataset.checked === 'true';

            // Set the data-checked attribute to false for all radio inputs before updating
            // the checked state of the event target
            const step = target.closest('.item-grid');
            const inputs = step ? step.querySelectorAll('input') : [];
            for (let i = 0; i < inputs.length; i += 1) {
                inputs[i].dataset.checked = 'false';
            }

            if (isChecked && canSaveBlankAnswer) {
                target.checked = false;
                target.dataset.checked = 'false';
                $(target).trigger('change');
            } else {
                target.checked = true;
                target.dataset.checked = 'true';
            }
        }
    },

    initChoices($el: JQuery, di: Object) {
        const choiceValues: null = di && di.data_item_value.length
            ? di.data_item_value.slice(0)
            : null;

        if (choiceValues !== null) {
            _.each(choiceValues, (choiceValue: string) => {
                const $target = $el.find(`[data-choice="${choiceValue.replace(/["\\]/g, '\\$&')}"]`);
                if ($target.length > 0) {
                    $target.prop('checked', true);
                    $target.find('input')[0].dataset.checked = 'true';
                }
            });
        }
    },

    initDatePicker($el: JQuery, enableTime: boolean, di: Object) {
        const { ticket } = this.props;

        /* initialize time dropdown, datepicker */
        let $datepicker: ?JQuery = $el.find('[data-action="date"]:first');
        if (!$datepicker || !$datepicker.length) {
            $datepicker = null;
            return;
        }

        let date = null;
        if (di) {
            date = enableTime
                ? moment(moment.tz(di.data_item_value[0], ticket.location.tzid).format('YYYY-MM-DDTHH:mm:ss'))
                : moment(di.data_item_value[0]).format('x');
        }

        const datepickerOptions: Object = {
            minDate: 1,
            mode: enableTime ? 'datetime' : 'date',
            dateFormat: 'yyyy-mm-dd',
            value: date,
            inputProps: {
                timeFormat24Hour: enableTime ? DateUtil.is24HoursClock() : false,
                readOnly: false,
            },
        };

        if ($datepicker[0]) {
            const container = document.createElement('div');
            Object.entries($datepicker[0].dataset).forEach(([key, value]) => {
                container.dataset[key] = value;
            });
            const reactDatepicker = React.createElement(LabeledDateTimePicker, datepickerOptions);
            ReactDOM.render(reactDatepicker, container);
            $datepicker[0].replaceWith(container);
        }
    },

    initTime($el: JQuery, di: Object) {
        $el.find('[data-selected][data-value]').on('click', _.bind(this.onSelectTime, this));
        $el.find('[data-target="selected"]:first').html(this.text.timeUnits.minutes);
        $el.find('[data-selected][data-value]:first').attr('data-selected', 'true');

        if (di && di.data_item_value[0]) {
            const timeAndUnit = DateUtil.secondsToMaxUnit(parseInt(di.data_item_value[0], 10));
            this.setTime($el, timeAndUnit.time, timeAndUnit.unit);
        }
    },

    initDescribe($el: JQuery, di: Object) {
        di && $el.find('textarea').html(di.data_item_value[0]);
    },

    initPhoto($el: JQuery, di: Object) {
        const photos$el = $el.find('[data-target="added"]:first');
        const images = di ? di.data_item_value : [];

        $el.find('[data-action="uploadImage"]:first').bind('click', () => new Upload({
            text: GW.localisation.shared.photoChooser,
            onSave: _.bind(this.addImage, this, photos$el),
        }));

        di && _.each(di.data_item_value, (photoUrl: { photo_url: string }, index: number) => {
            const url = `${photoUrl.photo_url}?t=${this.timestamp}&v=${this.versions[photoUrl.photo_url] || 0}`;
            this.addImage($el, { url, index }, images);
        }, this);
    },

    initNumber($el: JQuery, di: Object) {
        di && $el.find('input[type="text"]:first').val(di.data_item_value[0] || '');
    },

    initPhoneNumber($el: JQuery, di: Object) {
        di && $el.find('input[type="tel"]:first').val(di.data_item_value[0] || '');
    },

    next() {
        const $step = this.$steps.children().eq(this.currentIndex);
        let answers = [];
        let nextSteps = [];
        let instruction;
        let currentID;

        // Answers to photo questions are ignored. They don't affect skip logic
        switch ($step.find('input').attr('type')) {
            case 'checkbox':
            case 'radio':
                $step.find('input:checked').each((id: number, el: Object) => {
                    answers.push($(el).val());
                });

                break;

            case 'text':
                answers = [$step.find('input').val()];
                break;
            default:
                break;
        }

        if (answers.length) {
            ({ instruction } = this.skipLogicStack[this.currentIndex]);
            currentID = instruction.get('data_type_id');
            nextSteps = instruction.evaluate(answers);
        }

        _.each(nextSteps, (thisInstruction: Object, i: number) => {
            const exists = _.any(this.skipLogicStack, (step: { instruction: Object }) => thisInstruction === step.instruction);

            if (!exists) {
                this.skipLogicStack.splice(this.currentIndex + 1 + i, 0, {
                    parent_id: currentID,
                    instruction: thisInstruction,
                });
            }
        }, this);

        if (this.currentIndex < this.skipLogicStack.length - 1) {
            this.currentIndex += 1;
            if (this.$steps.children().eq(this.currentIndex).length === 0) {
                this.insertForm(this.skipLogicStack[this.currentIndex].instruction);
            }
        }
    },

    addImage($el: JQuery, url: Object, images: Object[]) {
        $el.append(addedImageTPL(url));
        const $added = $el.children(':last');
        $added.find('[data-action="trash"]:first').bind('click', () => {
            $added.remove();
        });

        $added.find('[data-action="magnify"]:first').bind('click', () => {
            this.magnify(url.url, url.index, images);
        });

        this.validateInput();
    },

    onSelectTime(e: JQueryEventObject) {
        const $el = $(e.currentTarget);

        if ($el.hasClass('disabled')) {
            return;
        }

        const $parent = $el.closest('div.time[data-id]');
        const time = $parent.find('input:first').val();
        const unit = $el.data('value');

        this.setTime($parent, time, unit);
    },

    setTime($el: JQuery, time: string | number, unit: string) {
        $el.find('[data-selected][data-value]').removeAttr('data-selected');
        $el.find(`[data-selected][data-value="${unit}"]:first`).attr('data-selected', 'true');
        $el.find('[data-target="selected"]:first').html(this.text.timeUnits[unit]);
        $el.find('input:first').val(time);
    },

    renderLightbox(props: Object) {
        const { ticket } = this.props;
        const lightboxContainer = this.$el.find('.react-lightbox-container')[0];
        const dataTypeID = this.instruction.get('data_type_id');
        const dataType = ticket.data_type_map[dataTypeID];

        const images = props.images.map(({ photo_url: url }: { photo_url: string }) => ({
            src: `${url}?t=${this.timestamp}&v=${this.versions[url] || 0}`,
            title: dataType.description,
            description: dataType.questions.question_text,
        }));

        const handleClose = () => {
            this.exifViewerOpen = false;
            this.renderLightbox({ ...props, open: false });
            this.renderExifViewer({ open: this.exifViewerOpen });
        };

        const handleChange = (index) => {
            this.renderLightbox({ ...props, currentImage: index });
            this.renderExifViewer({ open: this.exifViewerOpen, photoUrl: images[index].src });
        };

        const handleEditClick = (index: number) => {
            this.exifViewerOpen = false;
            this.renderExifViewer({ open: this.exifViewerOpen });
            this.renderImageEditor({
                currentImage: index,
                imageUrl: images[index].src,
                open: true,
                images: props.images,
            });
        };

        const handleInfoClick = (index: number) => {
            this.exifViewerOpen = !this.exifViewerOpen;
            this.renderExifViewer({
                open: this.exifViewerOpen,
                photoUrl: images[index].src,
            });
        };

        render(
            <I18nextProvider i18n={i18next}>
                <ThemeProvider theme={theme}>
                    <Lightbox
                      {...props}
                      images={images}
                      onChange={handleChange}
                      onClose={handleClose}
                      onEditClick={handleEditClick}
                      onInfoClick={handleInfoClick}
                    />
                </ThemeProvider>
            </I18nextProvider>,
            lightboxContainer
        );
    },

    renderImageEditor(props: Object) {
        const { currentImage, images, ...other } = props;
        const imageEditorContainer = this.$el.find('.react-image-editor-container')[0];

        const handleClose = () => {
            this.renderImageEditor({ ...props, open: false });
        };

        const handleSave = (dataURL: string) => {
            const originalSrc = images[currentImage].photo_url;
            const blob = dataUriToBlob(dataURL, 'image/jpeg');
            const formData = new FormData();
            formData.append('url', originalSrc);
            formData.append('file', blob);

            return axios.put('/upload', formData)
                .then(() => {
                    this.versions[originalSrc] = (this.versions[originalSrc] || 0) + 1;
                    this.renderImageEditor({ ...props, open: false });
                    this.renderLightbox({ currentImage, open: false, images });
                    this.render();
                    this.renderLightbox({ currentImage, open: true, images });
                });
        };

        render(
            <I18nextProvider i18n={i18next}>
                <ThemeProvider theme={theme}>
                    <ImageEditor {...other} onClose={handleClose} onSave={handleSave} />
                </ThemeProvider>
            </I18nextProvider>,
            imageEditorContainer
        );
    },

    renderExifViewer(props: Object) {
        const { ticket } = this.props;
        const { location } = ticket;

        const gigLocation = {
            address: location.formatted_address,
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude),
        };

        const exifViewerContainer = this.$el.find('.react-exif-viewer-container')[0];

        const handleClose = () => {
            this.exifViewerOpen = false;
            this.renderExifViewer({ ...props, open: this.exifViewerOpen });
        };

        render(
            <I18nextProvider i18n={i18next}>
                <ThemeProvider theme={theme}>
                    <ExifViewer {...props} gigLocation={gigLocation} onClose={handleClose} />
                </ThemeProvider>
            </I18nextProvider>,
            exifViewerContainer
        );
    },

    magnify(url: string, index?: number, images?: Object[]) {
        if (!Number.isNaN(index) && images) {
            this.renderLightbox({
                currentImage: index,
                open: true,
                images,
            });
        } else if (_.isString(url)) {
            window.open(url, '_blank');
        }
    },

    onChoiceUpdated(event: JQueryEventObject) {
        if (this.readMode) {
            return true;
        }

        const $step = $(event.currentTarget);
        const index = this.$steps.children().index($step);
        let dataTypeID;
        let step;

        // If this step is the current one, wait until next is clicked
        if (index === this.currentIndex) {
            return true;
        }

        step = this.skipLogicStack[index];
        dataTypeID = step.instruction.get('data_type_id');

        // Helper method
        function removeSteps(parentID: number, start: number) {
            const newStart = start || 0;

            for (let i: number = newStart; i < this.skipLogicStack.length; i += 1) {
                step = this.skipLogicStack[i];
                dataTypeID = step.instruction.get('data_type_id');

                if (step.parent_id === parentID) {
                    this.skipLogicStack.splice(i, 1);
                    removeSteps.call(this, dataTypeID, i);
                    i -= 1;
                }
            }
        }

        // Recursive remove child steps that were added by original answer to this step
        removeSteps.call(this, dataTypeID, index);

        $step.nextAll().remove();
        this.currentIndex = index;
        this.validateInput();
    },

    getValues() {
        const dis = [];

        _.each(this.$steps.children(), (el: JQuery) => {
            const $el = $(el);
            const di = this.getValue($el);

            if (di.data_item_answered) {
                dis.push(di);
            }
        }, this);

        return dis;
    },

    save() {
        const { ticket } = this.props;
        const dis = this.getValues();
        const dataItems = ticket.data_items;

        _.each(dis, (di: Object) => {
            const existing = _.findWhere(dataItems, {
                data_type_id: di.data_type_id,
                observation_target_id: di.observation_target_id,
            });
            if (existing) {
                _.extend(existing, di);
            } else {
                dataItems.push(di);
            }

            this.onSave(di, this.$save);
        }, this);
    },

    getValue($el: JQuery) {
        if (!$el || !$el.length) {
            return {};
        }

        const { ticket } = this.props;
        const dataTypeID = $el.data('id');
        const targetID = $el.data('targetid');
        const dataType = ticket.data_type_map[dataTypeID];
        let dataItemValue = [];

        switch (dataType.value_type) {
            case ProjectConstant.VALUE_TYPE.DATE: {
                const dateStr = $el.find('input').val().trim();
                const date = dateStr ? moment.utc(dateStr).startOf('day') : null;
                dataItemValue = date && date.isValid() ? [date.format('YYYY-MM-DDTHH:mm:ss')] : [];
                break;
            }

            case ProjectConstant.VALUE_TYPE.DATE_TIME: {
                const dateStr = `${$el.find('input').val().trim()} ${$el.find('input.time').val().trim()}`.trim();
                const date = dateStr ? moment.tz(dateStr, 'YYYY-MM-DD hh:mm a', ticket.location.tzid) : null;
                dataItemValue = date && date.isValid() ? [date.utc().format('YYYY-MM-DDTHH:mm:ssZ')] : [];
                break;
            }

            case ProjectConstant.VALUE_TYPE.PHOTO:
                dataItemValue = _.map($el.find('.added img[data-url]'), (img: JQuery) => ({
                    // $FlowTypedIssue jquery libdef thinks data method returns a JQuery object
                    photo_url: $(img).data('url').split('?')[0],
                }));
                break;

            default:
                dataItemValue = this._super.call(this, $el).data_item_value;
        }

        return {
            data_item_value: dataItemValue,
            template_id: this.instruction.get('template_id'),
            data_item_answered: !!(dataType.value_type === 'TASK' || dataType.value_type === 'CHECK' || dataItemValue.length),
            data_item_latitude: `${this.deviceLocation.latitude}`,
            data_item_longitude: `${this.deviceLocation.longitude}`,
            data_item_timestamp: `${moment().toDate().getTime()}`,
            data_type_id: dataTypeID,
            observation_target_id: targetID,
        };
    },

    toggleReadMode(e: JQueryEventObject, ...rest: Array<any>) {
        if ($(e.target).data('action') === 'next') {
            return;
        }

        return this._super.apply(this, [e, ...rest]);
    },

    validateInput() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.setReadMode();

            const { ticket, user } = this.props;
            const $el = this.$steps.children('[data-id]:last');
            const dataType = ticket.data_type_map[$el.data('id')];

            if (this.readMode || !dataType) {
                return;
            }

            const canSaveBlankAnswer = delegateRoles.includes(user.role) && dataType.is_required === false;
            const saveError = !this.getValue($el).data_item_answered && !canSaveBlankAnswer;
            const nextError = saveError;

            const value = this.getValue($el);
            const hasNextStep = this.currentIndex < this.skipLogicStack.length - 1
                || (value.data_item_answered && this.skipLogicStack[this.currentIndex].instruction.evaluate(value.data_item_value).length > 0);
            const showSave = !nextError && !hasNextStep;
            if (showSave) {
                this.$save.show();
                this.$next.hide();
            } else {
                this.$next.show();
                this.$save.hide();
            }

            this.$el.toggleClass('read-only', this.readMode);
            this.$next.toggleClass('disabled', nextError || this.readMode);
            this.$save.toggleClass('disabled', this.readMode || saveError);

            this.updateIsAnswered();
        }, 67);
    },

    /**
     * @returns {boolean}
     */
    updateIsAnswered(): boolean {
        const $els = this.$steps.children();
        let isAnswered: boolean = true;
        let dataTypeID;
        let targetID;
        let di;

        for (let i: number = 0, len = $els.length; i < len; i++) { // eslint-disable-line no-plusplus
            const $el = $($els[i]);
            dataTypeID = $el.data('id');
            targetID = $el.data('targetid');
            di = this.getValue($el);

            this.props.setIsAnswered(di.data_item_answered, dataTypeID, targetID, this.instruction.get('template_id'), di);

            if (di.data_item_answered) {
                continue;
            }

            isAnswered = false;
            break;
        }

        return isAnswered;
    },

});
