// @flow
export default (theme: Object) => ({
    locationTitle: {
        fontFamily: 'ProximaNova',
        fontWeight: 600,
        fontSize: 16,
        marginBottom: 5,
    },
    locationAddress: {
        fontFamily: 'ProximaNova',
        fontSize: 16,
    },
    cardContent: {
        fontFamily: 'ProximaNova',
        fontSize: 16,

        '& $label': {
            fontWeight: 600,
        },
    },
    label: {
        '&:after': {
            content: '":"',
        },
    },
    description: {
        position: 'relative',
        marginBottom: theme.spacing(1),
    },
    showMoreButton: {
        fontFamily: 'ProximaNova',
        fontSize: 16,
        height: 50,
    },
    gradient: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 40,
        backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), ${theme.palette.common.white})`,
    },
});
