// @flow
export const REFRESH = 'g/session/REFRESH';
export const REFRESH_SUCCESS = `${REFRESH}_SUCCESS`;
export const REFRESH_ERROR = `${REFRESH}_ERROR`;

export const INITIALIZE = 'g/session/INITIALIZE';
export const LOGOUT = 'g/session/LOGOUT';
export const START = 'g/session/START';

export default {
    INITIALIZE,
    LOGOUT,
    REFRESH,
    REFRESH_SUCCESS,
    REFRESH_ERROR,
    START,
};
