// @noflow
import SkipLogicTree from '../SkipLogicTree';
import Instructions from './Instructions';
import InstructionList from './InstructionList';

/**
 * @class (Model) Template
 * @constructor
 */
export default SkipLogicTree.extend({

    defaults: {
        data_types: null,
        observation_target_ids: [],
        observation_target_list_id: 0,
        template_id: 0,
        template_args: [],
    },

    Collection: Instructions,

    initialize(attrs: Object, ...args: Array<any>) {
        this.Collection = Instructions;
        this._super(attrs, ...args);
        this.set({
            data_types: new Instructions(attrs.data_types),
        });
    },

    /**
     * Explodes a template to create a set of instructions. The data argument can be used
     * to set attributes on each instruction. When merging, the original value is used.
     *
     * @param data {Object}
     * @returns instructions {Instructions}
     */
    explode(data: Object): Instructions {
        const dataTypes = this.get('data_types').toJSON();
        const templateID = this.get('template_id');
        const instructionList = new InstructionList({ instructions: dataTypes });
        const instructions = instructionList.getInstructions();

        instructions.forEach((dataType: SkipLogicTree) => {
            dataType.traverse((node: SkipLogicTree) => {
                let origValue;
                Object.keys(data).forEach((key: string) => {
                    origValue = node.get(key);
                    node.set(key, origValue || data[key]);
                });

                if (templateID) {
                    node.set('template_id', templateID);
                }
            });
        });

        return instructions;
    },
});
