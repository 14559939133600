// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import { Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { DialogActions, DialogContent } from '@material-ui/core';
import {
    Clear as ClearIcon,
    Done as DoneIcon,
} from '@material-ui/icons';
import SubmitButton from '../../../../components/SubmitButton';
import styles from './styles';

type Props = {
    dirty: boolean,
    handleSubmit: Function,
    isSubmitting: boolean,
    isValid: boolean,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    setStatus: (status: any) => void,
    status?: any,
    values: Object,
};

const useStyles = makeStyles(styles, { name: 'ApprovalForm' });

export default function ApprovalForm(props: Props) {
    const { handleSubmit, isSubmitting, setFieldValue, values } = props;

    const { t } = useTranslation();
    const classes = useStyles(props);

    const handleApprove = useCallback(() => {
        setFieldValue('approvalStatus', 'APPROVED', false);
        handleSubmit();
    }, [handleSubmit, setFieldValue]);

    const handleReject = useCallback(() => {
        setFieldValue('approvalStatus', 'REJECTED', false);
        handleSubmit();
    }, [handleSubmit, setFieldValue]);

    return (
        <Form onSubmit={handleSubmit}>
            <DialogContent className={classes.dialogContent}>
                <h2 className={classes.message}>{t('reviewDialog.message')}</h2>
                <small className={classes.hint}>{t('reviewDialog.hint')}</small>
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogActions, action: classes.dialogAction }}>
                <SubmitButton
                  className={classes.reject}
                  color="inherit"
                  disabled={isSubmitting}
                  onClick={handleReject}
                  submitting={isSubmitting && values.approvalStatus === 'REJECTED'}
                  variant="fab"
                >
                    <ClearIcon />
                </SubmitButton>
                <SubmitButton
                  className={classes.approve}
                  color="inherit"
                  disabled={isSubmitting}
                  onClick={handleApprove}
                  submitting={isSubmitting && values.approvalStatus === 'APPROVED'}
                  variant="fab"
                >
                    <DoneIcon />
                </SubmitButton>
            </DialogActions>
        </Form>
    );
}
