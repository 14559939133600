// @flow
export default (theme: Object): Object => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '60vh',
        padding: theme.spacing(3),
    },
    title: {
        color: theme.palette.primary.main,
        textAlign: 'center',
    },
    subheader: {
        textAlign: 'center',
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        flex: [[1, 1, 'auto']],
    },
});
