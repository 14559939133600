// @flow
import { createSelector } from 'reselect';
import qs from 'qs';
import { isEmail } from 'validator';
import type { ContextRouter } from 'react-router';
import entitySelector from '../../../redux/entities/entitySelector';
import decodeGeoFilter from '../utils/decodeGeoFilter';
import type { State as RootState } from '../../../redux/initialState';

type TableData = Array<{
    customer: Object,
    email: string,
    id: number,
    paypalEmail: ?string,
    rating: ?number,
    status: string,
}>;

type SearchParams = {
    geo?: Object[],
    order?: string,
    page?: string,
    q?: string,
    size?: string,
    sort?: string,
    status?: string[],
};

type APIParams = {
    filters?: Object[],
    keywords?: string,
    sort?: Object[],
    limit?: number,
    offset?: number,
};

const customerSelector = entitySelector('customers');

// Maps the key we use in the url query to the key expected by the API
const apiParams = {
    email: 'email',
    name: 'first_name',
    paypalEmail: 'paypal_email',
    rating: 'rating_score',
    status: 'customer_status',
};

// compoundFilters

export const getTableData = createSelector(
    (state: RootState) => customerSelector(state, state.gigwalkerList.data),
    (customers: Object[]): TableData => (
        customers
            .filter((customer) => !!customer)
            .map((customer) => {
                const {
                    // current_latitude: latitude,
                    // current_longitude: longitude,
                    customer_status: status,
                    email,
                    id,
                    paypal_email: paypalEmail,
                    rating_score: rating,
                } = customer;

                // const geocoder = new google.maps.Geocoder();
                //
                // const currentLocation = {
                //     lat: latitude,
                //     lng: longitude,
                // };
                // geocoder.geocode({ location: currentLocation }, callback);

                return {
                    customer,
                    email,
                    id,
                    paypalEmail,
                    rating: rating != null ? Math.round(rating * 10) / 10 : null,
                    status,
                };
            })
    )
);

export const parseSearchParams = createSelector(
    <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): string => {
        const { location } = props;
        return location.search || '';
    },
    (search: string): SearchParams => qs.parse(search, { ignoreQueryPrefix: true })
);

export const getAPIParams = createSelector(
    parseSearchParams,
    (search: SearchParams): APIParams => {
        const { order, page, q, size, sort, ...filters } = search;
        const offset = Math.max(parseInt(page, 10) - 1, 0) || 0;
        const limit = Math.max(parseInt(size, 10), 0) || 10;
        const defaultSort = [{ first_name: order || 'asc' }];

        const apiFilters = [{
            key: 'organization_id',
            value: 5,
        }];

        if (filters.geo) {
            if (filters.geo.length > 1) {
                apiFilters.push({
                    filter_type: 'or',
                    filters: filters.geo.reduce((geoFilters: Object[], geo: Object): Object[] => {
                        const filter = decodeGeoFilter(geo);
                        if (filter) {
                            geoFilters.push({
                                filter_type: 'address',
                                key: filter.key,
                                value: `${filter.value.primary} ${filter.value.secondary || ''}`.trim(),
                            });
                        }
                        return geoFilters;
                    }, []),
                });
            } else {
                const filter = decodeGeoFilter(filters.geo[0]);
                if (filter) {
                    apiFilters.push({
                        filter_type: 'address',
                        key: filter.key,
                        value: `${filter.value.primary} ${filter.value.secondary || ''}`.trim(),
                    });
                }
            }
        }

        if (filters.status) {
            apiFilters.push({
                key: 'customer_status',
                value: filters.status,
            });
        }

        const searchByEmail = q != null && isEmail(q);
        if (searchByEmail) {
            apiFilters.push(
                {
                    filter_type: 'or',
                    filters: [
                        { key: 'email', value: q },
                        { key: 'paypal_email', value: q },
                    ],
                }
            );
        }

        return {
            filters: apiFilters,
            keywords: searchByEmail ? undefined : q,
            sort: (sort && sort in apiParams) ? [{ [apiParams[sort]]: order || 'asc' }] : defaultSort,
            limit,
            offset,
        };
    }
);

export default {
    getAPIParams,
    getTableData,
    parseSearchParams,
};
