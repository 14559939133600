// @flow
export default (theme: Object) => ({
    filledInput: {
        paddingTop: 10,
    },
    selectValue: {
        display: 'flex',
        alignItems: 'center',
    },
    progress: {
        flexShrink: 0,
        marginLeft: 'auto',
    },
    successIcon: {
        flexShrink: 0,
        marginLeft: 'auto',
        fontSize: 18,
        color: theme.palette.success.main,
    },
    errorIcon: {
        flexShrink: 0,
        marginLeft: 'auto',
        fontSize: 18,
        color: theme.palette.error.main,
    },
});
