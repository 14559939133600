// @flow
import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { Route } from 'react-router';
import { withStyles } from '@material-ui/styles';
import {
    Collapse,
    IconButton,
    Paper,
    Toolbar,
} from '@material-ui/core';
import {
    ChatBubbleOutline as ChatBubbleOutlineIcon,
    ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { TFunction } from 'react-i18next';
import { EVENTS } from '../../../../../browser/shared/constant/TicketConstant';
import AddComment from './containers/AddComment';
import Comment from './components/Comment';
import TicketEvent from './components/TicketEvent';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type State = {
    expanded: boolean,
};

type OwnProps = ContextRouter & {
    className?: string,
    classes: Object,
    t: TFunction,
};
type StateProps = {
    comments: Object[],
    user: ?Object,
};
type DispatchProps = {
    deleteComment: (id: string) => Promise<void>,
};
type Props = OwnProps & StateProps & DispatchProps;

export class Comments extends Component<Props, State> {
    state = { expanded: true };

    handleExpandClick = () => {
        const { expanded } = this.state;
        this.setState({ expanded: !expanded });
    };

    handleDelete = (id: string) => {
        const { deleteComment } = this.props;
        deleteComment(id);
    };

    renderComments() {
        const { comments, user } = this.props;

        return comments.map((comment) => {
            const { author, data, date, id, type } = comment;

            if (type === EVENTS.COMMENT) {
                const isAuthor = user ? user.id === author.id : false;
                return (
                    <Comment
                      id={id}
                      author={author}
                      date={date}
                      {...(isAuthor ? { onDelete: this.handleDelete } : undefined)}
                    >
                        {data.comment}
                    </Comment>
                );
            }

            return <TicketEvent type={type} data={data} date={date} />;
        });
    }

    render() {
        const { classes } = this.props;
        const { expanded } = this.state;

        return (
            <Paper className={classes.comments}>
                <Toolbar variant="dense" className={classes.toolbar}>
                    <ChatBubbleOutlineIcon className={classes.commentIcon} color="action" />
                    <span>Comments</span>
                    <IconButton
                      className={cx(classes.expand, { [classes.expandOpen]: expanded })}
                      onClick={this.handleExpandClick}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </Toolbar>
                <Collapse in={expanded}>
                    <div className={classes.comments}>
                        {this.renderComments()}
                        <Route component={AddComment} />
                    </div>
                </Collapse>
            </Paper>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    comments: selectors.getComments(state, props),
    user: state.session.user,
});
const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    deleteComment: (id: string) => dispatch(actions.deleteComment(id)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'Comments' }),
    withTranslation(),
    connector,
);

export default enhance(Comments);
