// @flow
import { createSelector } from 'reselect';
import * as session from '../../../../../ducks/session';
import { getAPIParams, parseSearchParams } from '../../../duck/selectors';

export { getAPIParams, parseSearchParams } from '../../../duck/selectors';

const ticketActions = Object.freeze([
    { name: 'assign', icon: 'assignment_ind' },
    { name: 'cancel', icon: 'cancel' },
    { name: 'extend', icon: 'forward' },
    { name: 'optIn', icon: 'assignment_ind' },
    { name: 'reopen', icon: 'assignment_return' },
    { name: 'reschedule', icon: 'access_time' },
    { name: 'schedule', icon: 'access_time' },
    { name: 'unassign', icon: 'remove_circle' },
]);

export const getTicketActions = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    session.selectors.isWorker,
    session.selectors.isOperator,
    session.selectors.isAdmin,
    (isWorker: boolean, isOperator: boolean, isAdmin: boolean): Object[] => {
        if (isAdmin) {
            return ticketActions.filter((action: Object) => ['assign', 'cancel', 'extend', 'reopen', 'reschedule', 'unassign'].includes(action.name));
        } else if (isOperator) {
            return ticketActions.filter((action: Object) => ['assign', 'reopen'].includes(action.name));
        } else if (isWorker) {
            return ticketActions.filter((action: Object) => ['optIn', 'schedule', 'unassign'].includes(action.name));
        }

        return [];
    }
);

export default {
    getAPIParams,
    getTicketActions,
    parseSearchParams,
};
