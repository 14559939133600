// @flow
import { createSelector } from 'reselect';
import numeral from 'numeral';
import jstz from 'jstimezonedetect';
import moment from 'moment-timezone';
import type { ContextRouter } from 'react-router';
import logger from '../../../../../util/logger';
import { EVENTS } from '../../../../../../browser/shared/constant/TicketConstant';
import { getTicket } from '../../../duck/selectors';
import type { State as RootState } from '../../../../../redux/initialState';
import { USER_ROLES } from '../../../../../../browser/shared/constant/UserRoles';

type Comment = {
    author: ?Object,
    data: ?Object,
    date: string,
    id: string,
    type: string,
}

const getActiveUser = (state: RootState): ?Object => state.session.user;
const getOrganizationId = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): number => {
    const { match } = props;
    return parseInt(match.params.orgId, 10);
};

export const getComments = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    getTicket,
    getOrganizationId,
    getActiveUser,
    (ticket: Object, orgId: number, user: ?Object): Comment[] => {
        const needsPublicWorkforce = ticket ? ticket.subscription.needs_public_workforce : false;
        const events = ticket ? ticket.events || [] : [];
        const ticketTimezone = ticket ? ticket.location.tzid : '';
        const localTimezone = jstz.determine().name();

        // Simple helper function to add additional info to customer object.
        // Because the API does not return org info or the user role for a customer in the
        // ticket_events GET response, we cannot assume the orgId for needsPublicWorkforce tickets.
        const getCustomerInfo = (customer: Object) => {
            const hideDetails = user && user.role !== USER_ROLES.PLATFORM_ADMIN && user.id !== customer.id && needsPublicWorkforce;
            const lastName = customer.last_name || '';
            return {
                ...customer,
                last_name: hideDetails ? lastName.charAt(0) : lastName,
                profile_link: hideDetails ? null : `/member/${orgId}/${customer.id}/account`,
            };
        };

        const comments = events.reduce((list: Comment[], event: Object) => {
            const createdCustomer = getCustomerInfo(event.created_customer);
            const {
                id: eventId,
                ticket_event_date: eventDate,
                ticket_event_data: eventData,
                ticket_event_type: eventType,
            } = event;

            let data: ?Object = null;
            let author: ?Object = null;

            switch (eventType) {
                case EVENTS.ASSIGNED: {
                    const { assigned_customer: assignedCustomer } = eventData;
                    data = {
                        assignedCustomer: getCustomerInfo(assignedCustomer),
                        createdCustomer,
                    };
                    break;
                }

                case EVENTS.AUTO_ASSIGNED: {
                    const { assigned_customer: assignedCustomer } = eventData;
                    data = { assignedCustomer: getCustomerInfo(assignedCustomer) };
                    break;
                }

                case EVENTS.COMMENT: {
                    const { comment } = eventData;
                    author = { ...createdCustomer };
                    data = { comment };
                    break;
                }

                case EVENTS.DEADLINE_SET: {
                    const { assigned_customer: assignedCustomer, deadline, trigger_event: triggerEvent } = eventData;
                    data = {
                        assignedCustomer: getCustomerInfo(assignedCustomer),
                        deadline: moment.tz(deadline, ticketTimezone).format('lll'),
                        triggerEvent,
                    };
                    break;
                }

                case EVENTS.END_DATE_EDITED: {
                    const { new_end_date: newEndDate, original_end_date: oldEndDate } = eventData;
                    data = {
                        createdCustomer,
                        newEndDate: moment.tz(newEndDate, ticketTimezone).format('lll'),
                        oldEndDate: moment.tz(oldEndDate, ticketTimezone).format('lll'),
                    };
                    break;
                }

                case EVENTS.EXTENDED: {
                    const { extended_date: extendedDate, extended_from_date: extendedFromDate } = eventData;
                    data = {
                        createdCustomer,
                        extendedDate: moment.tz(extendedDate, ticketTimezone).format('lll'),
                        extendedFromDate: moment.tz(extendedFromDate, ticketTimezone).format('lll'),
                    };
                    break;
                }

                case EVENTS.GROUPS_CHANGED: {
                    const { new_groups: newGroups, old_groups: oldGroups } = eventData;
                    const groupsAdded = newGroups.filter((group: string) => !oldGroups.includes(group));
                    const groupsRemoved = oldGroups.filter((group: string) => !newGroups.includes(group));
                    data = {
                        createdCustomer,
                        groupsAdded,
                        groupsRemoved,
                    };
                    break;
                }

                case EVENTS.PAID: {
                    const { amount } = eventData;
                    data = {
                        amount: numeral(amount).format('$0,0.00'),
                        createdCustomer,
                    };
                    break;
                }

                case EVENTS.PROJ_DUE_DATE_CHANGED: {
                    const { new_date: newEndDate, old_date: oldEndDate } = eventData;
                    data = {
                        createdCustomer,
                        newEndDate: moment.tz(newEndDate, ticketTimezone).format('lll'),
                        oldEndDate: moment.tz(oldEndDate, ticketTimezone).format('lll'),
                    };
                    break;
                }

                case EVENTS.PROJ_START_DATE_CHANGED: {
                    const { new_date: newStartDate, old_date: oldStartDate } = eventData;
                    data = {
                        createdCustomer,
                        newStartDate: moment.tz(newStartDate, ticketTimezone).format('lll'),
                        oldStartDate: moment.tz(oldStartDate, ticketTimezone).format('lll'),
                    };
                    break;
                }

                case EVENTS.PROJ_TIME_ESTIMATE_CHANGED: {
                    const { new_time_estimate: newTimeEstimate, old_time_estimate: oldTimeEstimate } = eventData;
                    data = {
                        createdCustomer,
                        newTimeEstimate,
                        oldTimeEstimate,
                    };
                    break;
                }

                case EVENTS.RATED: {
                    const { rating } = eventData;
                    data = {
                        createdCustomer,
                        rating,
                    };
                    break;
                }

                case EVENTS.SCHEDULED: {
                    const { scheduled_date: scheduledDate } = eventData;
                    data = {
                        createdCustomer,
                        scheduledDate: moment.tz(scheduledDate, ticketTimezone).format('lll'),
                    };
                    break;
                }

                case EVENTS.START_DATE_EDITED: {
                    const { new_start_date: newStartDate, original_start_date: oldStartDate } = eventData;
                    data = {
                        createdCustomer,
                        newStartDate: moment.tz(newStartDate, ticketTimezone).format('lll'),
                        oldStartDate: moment.tz(oldStartDate, ticketTimezone).format('lll'),
                    };
                    break;
                }

                case EVENTS.SUBMIT_DEADLINE_EDITED: {
                    const { new_end_date: newEndDate, original_end_date: oldEndDate } = eventData;
                    data = {
                        createdCustomer,
                        newEndDate: moment.tz(newEndDate, ticketTimezone).format('lll'),
                        oldEndDate: moment.tz(oldEndDate, ticketTimezone).format('lll'),
                    };
                    break;
                }

                case EVENTS.TIME_ESTIMATE_EDITED: {
                    const { new_time_estimate: newTimeEstimate, original_time_estimate: oldTimeEstimate } = eventData;
                    data = {
                        createdCustomer,
                        newTimeEstimate,
                        oldTimeEstimate,
                    };
                    break;
                }

                case EVENTS.APPROVED:
                case EVENTS.CANCELED:
                case EVENTS.REJECTED:
                case EVENTS.REOPENED:
                case EVENTS.STARTED:
                case EVENTS.SUBMITTED:
                case EVENTS.UNASSIGNED:
                case EVENTS.UNSCHEDULED:
                    data = { createdCustomer };
                    break;

                // These events don't have a created_customer
                case EVENTS.AUTO_UNASSIGNED:
                case EVENTS.CREATED:
                    break;

                // Events that we don't support anymore or want to display to users
                case EVENTS.EDIT:
                case EVENTS.PAUSED:
                case EVENTS.PRINTED:
                case EVENTS.PROJECT_UPDATE:
                case EVENTS.RESTARTED:
                    return list;

                default:
                    logger.error(`Unsupported ticket event type: ${eventType}`);
                    return list;
            }

            list.push({
                author,
                data,
                date: moment.utc(eventDate).tz(localTimezone).format(),
                id: eventId,
                type: eventType,
            });

            return list;
        }, []);

        return comments.sort((a: Comment, b: Comment): number => (new Date(a.date)) - (new Date(b.date)));
    }
);

export default {
    getComments,
};
