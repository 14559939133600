// @flow
import type { User } from '../store_utils/types/User';

const { GIGWALK_API_SERVER_HOSTNAME } = process.env;

/**
* Check if user belongs to Crossmark
*/
export default function isCrossmark(user: User): boolean {
    const { organization: { id: orgID } } = user;
    let result: boolean = false;
    if (orgID === 7) {
        result = true;
    } else {
        switch (GIGWALK_API_SERVER_HOSTNAME) {
            case 'stage-api.apps.gigwalk.com':
                result = (orgID === 310);
                break;
            case 'partner-api.apps.gigwalk.com':
                result = (orgID === 110);
                break;
            case 'partner-dev-api.enterprise.gigwalk.com':
                result = (orgID === 10);
                break;
            case 'api.enterprise.gigwalk.com':
                result = (orgID === 359);
                break;
            default:
                break;
        }
    }

    return result;
}
