// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles, { name: 'MarketingFooter' });

export function MarketingFooter() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <p>
                <a href="http://www.gigwalk.com/">
                    <img alt="Gigwalk" src="/public/img/gigwalk_logo.png" />
                </a>
            </p>
            <p>{t('signup.marketing.copyright')}</p>
        </div>
    );
}

export default MarketingFooter;
