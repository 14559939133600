// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import types from './types';
import typeof { toggleBackbone } from './actions';

export type State = {
    backboneActive: boolean,
}

export const init: State = {
    backboneActive: false,
};

export default handleActions({
    [types.TOGGLE_BACKBONE]: (state: State, action: ActionType<toggleBackbone>) => ({
        ...state,
        backboneActive: action.payload,
    }),
}, init);
