// @flow
import { combineReducers } from 'redux';
import gpsVerifiedMap, { init as initGPSVerifiedMapState } from '../containers/GPSVerifiedMap/duck/reducer';
import type { State as GPSVerifiedState } from '../containers/GPSVerifiedMap/duck/reducer';

export type State = {
    gpsVerifiedMap: GPSVerifiedState,
};

export const init: State = {
    gpsVerifiedMap: initGPSVerifiedMapState,
};

export default combineReducers({
    gpsVerifiedMap,
});
