// @flow
/* global google */
// $FlowIssue need to update to a more recent flow version
import React, { Component, Fragment, createRef } from 'react';
import isEqual from 'lodash.isequal';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import { withStyles } from '@material-ui/styles';
import { Chip, CircularProgress } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter } from 'react-router';
import type { Ticket } from 'gigwalk/lib/api/tickets/types';
import typeof $i18next from 'i18next';
import MapControl from '../../../../../../components/MapControl';
import entitySelector from '../../../../../../redux/entities/entitySelector';
import { actions } from './duck';
import styles from './styles';
import type { MarkerProps } from './duck/reducer';
import type { State as RootState } from '../../../../../../redux/initialState';

type State = {
    activeMarker: ?number,
    loading: boolean,
    ready: boolean,
}

type OwnProps = ContextRouter & {
    classes: Object,
    height?: number,
    i18n: $i18next,
    t: TFunction,
    width?: number,
}
type StateProps = {
    markers: MarkerProps[],
    ticket: Ticket,
};
type DispatchProps = {
    loadMarkers: (ticket: Object) => Promise<void>,
};

type Props = OwnProps & StateProps & DispatchProps;

/**
 * @todo checklist
 * [-] Add a loading indicator while marker data is loading
 * [-] Figure out a good solution to group multiple pins in close proximity
 * [-] Assign a unique color to pins from different assignees
 */

export class GPSVerifiedMap extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            activeMarker: null,
            ready: false,
            loading: true,
        };

        this.mapRef = createRef();
    }

    componentDidMount() {
        this._loadMarkerData();
    }

    componentWillReceiveProps(nextProps: Props) {
        const { ticket } = this.props;
        const { ticket: nextTicket } = nextProps;

        if (!isEqual(ticket.data_items, nextTicket.data_items)) {
            this._loadMarkerData();
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { ready } = this.state;
        const { markers } = this.props;
        const { mapRef } = this;

        const shouldFitBounds = (!prevState.ready && ready)
            || (ready && prevProps.markers !== markers);

        // Adjust the bounds of the map to include all markers. Make sure that map is
        // initialized before calling fitBounds
        if (shouldFitBounds && mapRef.current) {
            const currentBounds = mapRef.current.getBounds();

            let bounds = new google.maps.LatLngBounds();
            markers.forEach((marker: MarkerProps) => {
                bounds = bounds.extend(marker.position);
            });
            bounds = currentBounds.union(bounds);

            if (!currentBounds.equals(bounds)) {
                mapRef.current.fitBounds(bounds);
            }
        }
    }

    handleMapIdle = () => {
        const { ready } = this.state;
        if (!ready) {
            this.setState({ ready: true });
        }
    };

    handleMarkerClick = (index: number) => {
        this.setState({ activeMarker: index });
    };

    handleInfoWindowClose = () => {
        this.setState({ activeMarker: null });
    };

    _loadMarkerData() {
        const { loadMarkers, ticket } = this.props;
        this.setState({ loading: true });
        loadMarkers(ticket)
            .then(() => {
                this.setState({ loading: false });
            });
    }

    mapRef: Object;

    render() {
        const { activeMarker, loading } = this.state;
        const { classes, i18n, markers, t, ticket } = this.props;

        const mapCenter = {
            lat: ticket.location.latitude,
            lng: ticket.location.longitude,
        };

        return (
            <Fragment>
                {loading
                    ? (
                        <div className={classes.loading}>
                            <CircularProgress color="primary" />
                        </div>
                    )
                    : null
                }
                <GoogleMap
                  onIdle={this.handleMapIdle}
                  defaultCenter={mapCenter}
                  defaultOptions={{
                      fullscreenControl: false,
                      mapTypeControl: false,
                      streetViewControl: false,
                  }}
                  defaultZoom={15}
                  ref={this.mapRef}
                >
                    <MapControl controlPosition={google.maps.ControlPosition.TOP_RIGHT}>
                        <Chip
                          classes={{
                              root: classes.chip,
                              label: classes.chipLabel,
                          }}
                          label={(
                              <Fragment>
                                  <span><strong>GPS</strong> Verified</span>
                                  <CheckIcon className={classes.checkIcon} />
                              </Fragment>
                          )}
                        />
                    </MapControl>
                    {markers.map((marker: MarkerProps, index: number) => {
                        const { info, ...markerProps } = marker;
                        return (
                            <Marker
                              {...markerProps}
                              key={index}
                              onClick={() => { this.handleMarkerClick(index); }}
                            >
                                {activeMarker === index
                                    ? (
                                        <InfoWindow onCloseClick={this.handleInfoWindowClose}>
                                            <span>
                                                {typeof info === 'string' && i18n.exists(info) ? t(info) : info}
                                            </span>
                                        </InfoWindow>
                                    )
                                    : null
                                }
                            </Marker>
                        );
                    })}
                </GoogleMap>
            </Fragment>
        );
    }
}

const ticketSelector = entitySelector('tickets');
const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const { match } = props;
    return {
        ...state.ticketDetail.description.gpsVerifiedMap,
        ticket: ticketSelector(state, match.params.ticketId),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    loadMarkers: (ticket: Object) => dispatch(actions.loadMarkers(ticket)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(
    withStyles(styles, { name: 'GPSVerifiedMap' }),
    withTranslation(),
    connector,
    withProps((ownProps: Props) => {
        const { height, width } = ownProps;
        const containerStyle = {
            position: 'relative',
            height: height ? `${height}px` : '100%',
            width: width ? `${width}px` : '100%',
        };

        return {
            containerElement: <div style={containerStyle} />,
            mapElement: <div style={{ height: '100%' }} />,
        };
    }),
    withGoogleMap,
);

export default enhance(GPSVerifiedMap);
