// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { hoistStatics } from 'recompose';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import SubmitButton from '../../components/SubmitButton';
import * as dialog from '../../ducks/dialog';
import { actions } from './duck';
import styles from './styles';
import type { State as RootState } from '../../redux/initialState';

type OwnProps = {};
type StateProps = {
    onClose: () => void,
    onSubmitFail: () => void,
    onSubmitSuccess: () => void,
    open: boolean,
    ticketId: ?number,
};
type DispatchProps = {
    registerDialog: (name: string) => void,
    submit: (values: Object) => Promise<void>,
    unregisterDialog: (name: string) => void,
};
type Props = OwnProps & StateProps & DispatchProps;

const WithdrawSchema = yup.object().shape({
    ticketId: yup.number(),
});

const useStyles = makeStyles(styles, { name: 'WithdrawDialog' });

export function WithdrawDialog(props: Props) {
    const {
        onClose,
        onSubmitFail,
        onSubmitSuccess,
        open,
        registerDialog,
        submit,
        ticketId,
        unregisterDialog,
    } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    useEffect(() => {
        registerDialog(WithdrawDialog.NAME);
        return () => {
            unregisterDialog(WithdrawDialog.NAME);
        };
    }, [registerDialog, unregisterDialog]);

    return (
        <Formik
          enableReinitialize
          initialValues={{ ticketId }}
          isInitialValid
          onSubmit={(values, { setStatus, setSubmitting }) => {
              submit(values)
                  .then(() => {
                      setStatus({ success: true });
                      onSubmitSuccess();
                  })
                  .catch((error) => {
                      setStatus({ error });
                      onSubmitFail();
                  })
                  .then(() => setSubmitting(false));
          }}
          validationSchema={WithdrawSchema}
        >
            {(formikProps: Object) => {
                const { isSubmitting, isValid, handleReset, handleSubmit } = formikProps;
                return (
                    <Dialog open={open} onClose={onClose} onExited={handleReset}>
                        <Form onSubmit={handleSubmit}>
                            <DialogTitle>{t('withdrawDialog.title')}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>{t('withdrawDialog.text')}</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={onClose}>
                                    {t('withdrawDialog.cancel')}
                                </Button>
                                <SubmitButton
                                  className={classes.submitButton}
                                  color="primary"
                                  disabled={isSubmitting || !isValid}
                                  submitting={isSubmitting}
                                  type="submit"
                                  variant="contained"
                                >
                                    {t('withdrawDialog.confirm')}
                                </SubmitButton>
                            </DialogActions>
                        </Form>
                    </Dialog>
                );
            }}
        </Formik>
    );
}

WithdrawDialog.NAME = 'withdraw';
WithdrawDialog.defaultProps = {
    onClose: () => {},
    onSubmitFail: () => {},
    onSubmitSuccess: () => {},
    open: false,
    ticketId: null,
};

const mapStateToProps = (state: RootState): StateProps => ({
    ...WithdrawDialog.defaultProps,
    ...state.dialog.registered[WithdrawDialog.NAME],
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    registerDialog: (name: string) => dispatch(dialog.actions.register(name)),
    submit: (values: Object) => dispatch(actions.submit(values)),
    unregisterDialog: (name: string) => dispatch(dialog.actions.unregister(name)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default hoistStatics(connector)(WithdrawDialog);
