// @flow
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import type { $AxiosError, $AxiosXHR } from 'axios';
import types from './types';
import { client as gigwalk } from '../../../../../api/createGigwalkClient';

export const fetchAPIVersionError = createAction(types.FETCH_API_VERSION_ERROR);
export const fetchAPIVersionSuccess = createAction(types.FETCH_API_VERSION_SUCCESS);
export const fetchAPIVersion = createAction(
    types.FETCH_API_VERSION,
    (): Function => (
        (dispatch: Dispatch<any>): Promise<any> => (
            gigwalk.versions.get()
                .then((res: $AxiosXHR<*>) => {
                    if (res && res.data && res.data.data.length && res.data.data[0].version) {
                        dispatch(fetchAPIVersionSuccess(res.data.data[0].version));
                    }
                })
                .catch((err: $AxiosError<*>) => {
                    dispatch(fetchAPIVersionError(err));
                    return Promise.reject(err);
                })
        )
    )
);

export default {
    fetchAPIVersionError,
    fetchAPIVersionSuccess,
    fetchAPIVersion,
};
