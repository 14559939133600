// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useEffect } from 'react';
import JSONTree from 'react-json-tree';
import { hoistStatics } from 'recompose';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import * as dialog from '../../ducks/dialog';
import { selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../redux/initialState';

type OwnProps = {}
type StateProps = {
    metadata: Object,
    onClose: () => void,
    open: boolean,
    ticketId: ?number,
}
type DispatchProps = {
    registerDialog: (name: string) => void,
    unregisterDialog: (name: string) => void,
};
type Props = OwnProps & StateProps & DispatchProps;

const shouldExpandNode = () => true;
const useStyles = makeStyles(styles, { name: 'TicketInfoDialog' });

export function TicketInfoDialog(props: Props) {
    const { metadata, onClose, open, registerDialog, unregisterDialog } = props;

    const { t } = useTranslation();
    const classes = useStyles(props);

    useEffect(() => {
        registerDialog(TicketInfoDialog.NAME);
        return () => {
            unregisterDialog(TicketInfoDialog.NAME);
        };
    }, [registerDialog, unregisterDialog]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <Button className={classes.closeButton} onClick={onClose}>
                <ClearIcon />
            </Button>
            <DialogTitle>{t('ticketInfoDialog.title')}</DialogTitle>
            <DialogContent className={classes.content}>
                <JSONTree data={metadata} shouldExpandNode={shouldExpandNode} hideRoot />
            </DialogContent>
        </Dialog>
    );
}

TicketInfoDialog.NAME = 'ticketInfo';
TicketInfoDialog.defaultProps = {
    onClose: () => {},
    open: false,
    ticketId: null,
};

const mapStateToProps = (state: RootState): StateProps => {
    const props = {
        ...TicketInfoDialog.defaultProps,
        ...state.dialog.registered[TicketInfoDialog.NAME],
    };
    return {
        ...props,
        metadata: selectors.getTicketMetadata(state, props),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    registerDialog: (name: string) => dispatch(dialog.actions.register(name)),
    unregisterDialog: (name: string) => dispatch(dialog.actions.unregister(name)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default hoistStatics(connector)(TicketInfoDialog);
