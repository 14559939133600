// @flow
import React, { Component, Fragment } from 'react';
import qs from 'qs';
import isEqual from 'lodash.isequal';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { MenuItem, Paper } from '@material-ui/core';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter } from 'react-router';
import type { $AxiosError } from 'axios';
import { USER_ROLES } from '../../../browser/shared/constant/UserRoles';
import { format } from '../../../browser/shared/util/gigwalkApiErrorUtil';
import getDisplayName from '../../util/getDisplayName';
import waitForCondition from '../../components/waitForCondition';
import LoadingPage from '../../components/LoadingPage';
import UserAvatar from '../../components/UserAvatar';
import SimplePane from '../../components/SimplePane';
import DataTable from '../../components/DataTable';
import ActionToolbar from './containers/ActionToolbar';
import SearchToolbar from './containers/SearchToolbar';
import FilterDrawer from './containers/FilterDrawer';
import RoleSelect from './components/RoleSelect';
import * as snackbar from '../../ducks/snackbar';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../redux/initialState';

type State = {
    loading: boolean,
};

type OwnProps = ContextRouter & {
    classes: Object,
    t: TFunction,
};
type StateProps = {
    data: Object[],
    fetching: boolean,
    filters?: Object[],
    keywords?: string,
    limit?: number,
    offset?: number,
    pages: number,
    recordCount: number,
    search: {
        size?: string,
        page?: string,
        sort?: string,
        order?: string,
    },
    selected: number[],
    sort?: Object[],
    userRoleOptions: string[],
};
type DispatchProps = {
    changeRole: (orgId: number, customerId: number, role: string) => Promise<void>,
    deselect: (id: number) => void,
    enqueueSnackbar: (message: string, options: Object) => void,
    fetchData: (filters: ?Object[], keywords: ?string, sort: ?Object[], limit: ?number, offset: ?number) => Promise<void>,
    loadFilters: (search: Object) => Promise<void>,
    select: (id: number) => void,
};
type Props = OwnProps & StateProps & DispatchProps;

const stopPropagation = (event: SyntheticEvent<any>) => {
    event.stopPropagation();
    event.preventDefault();
};

export class CustomerList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        // this._fetchData = debounce(this._fetchData, 500);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        const { filters, keywords, search, sort, limit, loadFilters, offset } = this.props;
        const promises = [
            this._fetchData(filters, keywords, sort, limit, offset),
            loadFilters(search),
        ];

        Promise.all(promises)
            .then(() => this.setState({ loading: false }));
    }

    componentWillReceiveProps(nextProps: Props) {
        const { filters, keywords, sort, limit, offset } = this.props;
        const {
            filters: nextFilters,
            keywords: nextKeywords,
            sort: nextSort,
            limit: nextLimit,
            offset: nextOffset,
        } = nextProps;

        // @todo Check if page changed and previous page was out of range
        // If the previous page is out of range and the new page is the last page, that
        // means we updated the url to match the data fetched, and no fetch is needed

        // If any filter, sort, or pagination parameters changed, we should fetch new data.
        // Otherwise, if the table data has changed at all (for instance, an entity was deleted
        // from state), fetch the current page again.
        const shouldFetchData = !isEqual(nextFilters, filters)
            || !isEqual(nextSort, sort)
            || nextKeywords !== keywords
            || nextLimit !== limit
            || nextOffset !== offset;

        if (shouldFetchData) {
            this._fetchData(nextFilters, nextKeywords, nextSort, nextLimit, nextOffset);
        }
    }

    handlePageChange = (page: number) => {
        const { location, history, search } = this.props;
        const query = page >= 0 ? { page: page + 1 } : {}; // Don't forget to convert from zero-based index!

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                { ...search, ...query },
                { addQueryPrefix: true, encodeValuesOnly: true }
            ),
        });
    };

    handlePageSizeChange = (pageSize: number) => {
        const { location, history, search } = this.props;
        const { size, ...query } = search;

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                pageSize ? { ...query, size: pageSize } : query,
                { addQueryPrefix: true, encodeValuesOnly: true }
            ),
        });
    };

    handleSortedChange = (sorted: Object[]) => {
        const { location, history, search } = this.props;
        const { sort, order, ...query } = search;
        const { id, desc } = sorted.length > 0 ? sorted[0] : {};

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                id ? { ...query, sort: id, order: desc ? 'desc' : 'asc' } : query,
                { addQueryPrefix: true, encodeValuesOnly: true }
            ),
        });
    };

    handleSelect = (item: Object) => {
        const { deselect, select, selected } = this.props;
        const { id } = item;

        if (selected.includes(id)) deselect(id);
        else select(id);
    };

    handleRoleChange = (event: SyntheticEvent<any>, child: React$Element<any>) => {
        const { changeRole } = this.props;
        // $FlowFixMe material-ui changes the target to be a plain JS object
        const { value } = event.target;
        const {
            'data-customer': customerId,
            'data-organization': orgId,
        } = child.props;

        return changeRole(orgId, customerId, value);
    };

    _fetchData = (filters: ?Object[], keywords: ?string, sort: ?Object[], limit: ?number, offset: ?number) => {
        const { enqueueSnackbar, fetchData } = this.props;
        return fetchData(filters, keywords, sort, limit, offset)
            .catch((err: $AxiosError<any>) => {
                const resp = err ? err.response : null;
                if (resp && resp.data && resp.data.gw_api_response) {
                    const message = format(resp.data.gw_api_response);
                    enqueueSnackbar(message, { variant: 'error' });
                }
            });
    };

    _getColumns() {
        const { classes, t, userRoleOptions } = this.props;

        const status = {
            Header: t('customerList.status'),
            accessor: 'status',
            width: 120,
            className: classes.status,
            Cell: (props: Object) => t(`customerList.statusEnum.${props.value}`),
        };
        const name = {
            Header: t('customerList.name'),
            accessor: 'customer',
            id: 'name',
            Cell: (props: Object) => {
                const customer = props.value;
                const orgId = customer.organization_id;
                return (
                    <Fragment>
                        <UserAvatar className={classes.avatar} user={customer} />
                        <Link to={`/member/${orgId}/${customer.id}/account`}>
                            {getDisplayName(customer)}
                        </Link>
                    </Fragment>
                );
            },
        };
        const email = {
            Header: t('customerList.email'),
            accessor: 'email',
        };
        const role = {
            Header: t('customerList.role'),
            accessor: 'role',
            maxWidth: 200,
            Cell: (props: Object) => {
                const { customer } = props.original;
                const readOnly = (customer.organization_id === 5 && customer.role === USER_ROLES.WORKER)
                    || userRoleOptions.find((option: string) => option === customer.role) == null;

                return (
                    <RoleSelect
                      disableUnderline
                      fullWidth
                      onChange={this.handleRoleChange}
                      onClick={stopPropagation}
                      readOnly={readOnly}
                      value={customer.role}
                    >
                        {userRoleOptions.map((option: string) => (
                            <MenuItem
                              data-customer={customer.id}
                              data-organization={customer.organization_id}
                              key={option}
                              value={option}
                            >
                                {t(`customerList.roleEnum.${option}`)}
                            </MenuItem>
                        ))}
                    </RoleSelect>
                );
            },
        };

        return [name, status, email, role];
    }

    render() {
        const { loading } = this.state;
        const { classes, data, fetching, pages, recordCount, search, selected } = this.props;
        const { order, sort } = search;
        const page = Math.max(parseInt(search.page, 10) - 1, 0) || 0;
        const pageSize = Math.max(parseInt(search.size, 10), 0) || 10;

        const columns = this._getColumns();
        const sorted = [{
            id: sort || 'name',
            desc: order === 'desc',
        }];

        if (loading) {
            return (
                <SimplePane>
                    <LoadingPage />
                </SimplePane>
            );
        }

        return (
            <SimplePane classes={classes.root}>
                <Route component={SearchToolbar} />
                <Route component={FilterDrawer} />
                <Paper elevation={2}>
                    <Route component={ActionToolbar} />
                    <DataTable
                      sortable
                      columns={columns}
                      count={recordCount}
                      data={data}
                      loading={fetching}
                      onPageChange={this.handlePageChange}
                      onPageSizeChange={this.handlePageSizeChange}
                      onSelect={this.handleSelect}
                      onSortedChange={this.handleSortedChange}
                      pageSizeOptions={[10, 20, 50]}
                      defaultPageSize={10}
                      page={page}
                      pages={pages}
                      pageSize={pageSize}
                      selected={selected.map((id: number) => ({ id }))}
                      sorted={sorted}
                    />
                </Paper>
            </SimplePane>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    ...selectors.getAPIParams(state, props),
    data: selectors.getTableData(state, props),
    fetching: state.customerList.fetching,
    pages: state.customerList.metadata.page_count || 0,
    recordCount: state.customerList.metadata.record_count || 0,
    search: selectors.parseSearchParams(state, props),
    selected: state.customerList.selected,
    userRoleOptions: selectors.getUserRoleOptions(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    changeRole: (orgId: number, customerId: number, role: string) => dispatch(actions.changeRole(orgId, customerId, role)),
    deselect: (id: number) => dispatch(actions.deselect(id)),
    enqueueSnackbar: (message: string, options: Object) => dispatch(snackbar.actions.enqueue(message, options)),
    fetchData: (filters: ?Object[], keywords: ?string, sort: ?Object[], limit: ?number, offset: ?number) => (
        dispatch(actions.fetchData(filters, keywords, sort, limit, offset))
    ),
    loadFilters: (search: Object) => dispatch(actions.loadFilters(search)),
    select: (id: number) => dispatch(actions.select(id)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles),
    withTranslation(),
    waitForCondition(() => global.hasOwnProperty('google'), 100),
    connector,
);

export default enhance(CustomerList);
