// @flow
export default (theme: Object) => ({
    root: {
        justifyContent: 'flex-end',
    },
    input: {
        backgroundColor: theme.palette.common.white,
        maxWidth: 400,
        // padding: theme.spacing(1, 1),
        marginRight: theme.spacing(1),
    },
    outlined: {
        borderRadius: 4,
        border: [[1, 'solid', theme.palette.grey[400]]],
        padding: theme.spacing(0.5, 1),

        '&:focus': {
            borderColor: '#cfd8df',
        },

        '&$disabled': {
            color: 'rgba(0, 0, 0, 0.48)',
            backgroundColor: '#e5eaed',
        },
    },
    addButton: {
        flexShrink: 0,
    },
    addIcon: {
        marginRight: theme.spacing(1),
    },
});
