// @flow
import React, { Component } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';

type Props = {
    disabled?: boolean,
    label?: string,
    onChange?: (value: boolean) => void,
    onBlur?: (value: boolean) => void,
    onFocus?: () => void,
};

// This is a simple wrapper around the react-toolbox Switch component.
// When the component is disabled, it still receives and triggers focus/blur
// event handlers, which is not desirable for our use case.
export default class SwitchComponent extends Component<Props> {
    handleChange = (event: SyntheticInputEvent<*>, checked: boolean) => {
        const { disabled, onChange } = this.props;
        if (typeof onChange === 'function' && !disabled) {
            onChange(checked);
        }
    };

    handleBlur = (event: SyntheticInputEvent<*>) => {
        const { target } = event;
        const { disabled, onBlur } = this.props;

        if (typeof onBlur === 'function' && !disabled) {
            onBlur(target.checked);
        }
    };

    handleFocus = () => {
        const { disabled, onFocus } = this.props;
        if (typeof onFocus === 'function' && !disabled) {
            onFocus();
        }
    };

    render() {
        const { onBlur, onChange, ...switchProps } = this.props;
        return (
            <FormControlLabel
              {...switchProps}
              control={(
                  <Switch
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                  />
              )}
            />
        );
    }
}
