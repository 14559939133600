// @flow
import type { Location } from 'react-router';
import { init as addFundsInit } from '../containers/AddFunds/duck';
import { init as configInit } from '../ducks/config';
import { init as customerListInit } from '../containers/CustomerList/duck';
import { init as dialogInit } from '../ducks/dialog';
import { init as entitiesInit } from './entities/initialState';
import { init as layoutInit } from '../containers/Layout/duck';
import { init as locationListUploadInit } from '../../browser/shared/components/LocationListUpload/redux';
import { init as gigwalkerListInit } from '../containers/GigwalkerList/duck';
import { init as googleMapsInit } from '../ducks/googleMaps';
import { init as payoutListInit } from '../containers/PayoutList/duck';
import { init as projectBuilderInit } from '../containers/ProjectBuilder/duck';
import { init as projectManagementInit } from '../../browser/dashboard/view/active/Management/duck';
import { init as projectSummaryInit } from '../../browser/dashboard/view/active/Summary/duck';
import { init as sessionInit } from '../ducks/session';
import { init as signupInit } from '../containers/Signup/duck';
import { init as snackbarInit } from '../ducks/snackbar';
import { init as subscriptionListInit } from '../containers/SubscriptionList/duck';
import { init as ticketDetailInit } from '../containers/TicketDetail/duck';
import { init as ticketListInit } from '../containers/TicketList/duck';
import { init as transactionListInit } from '../containers/TransactionList/duck';
import { init as uiInit } from '../ducks/ui';
import type { State as AddFundsState } from '../containers/AddFunds/duck';
import type { State as ConfigState } from '../ducks/config';
import type { State as CustomerListState } from '../containers/CustomerList/duck';
import type { State as DialogState } from '../ducks/dialog';
import type { State as EntitiesState } from './entities/initialState';
import type { State as LayoutState } from '../containers/Layout/duck';
import type { State as LocationListUploadState } from '../../browser/shared/components/LocationListUpload/redux';
import type { State as GigwalkerListState } from '../containers/GigwalkerList/duck';
import type { State as GoogleMapsState } from '../ducks/googleMaps';
import type { State as PayoutListState } from '../containers/PayoutList/duck';
import type { State as ProjectBuilderState } from '../containers/ProjectBuilder/duck';
import type { State as ProjectManagementState } from '../../browser/dashboard/view/active/Management/duck';
import type { State as ProjectSummaryState } from '../../browser/dashboard/view/active/Summary/duck';
import type { State as TicketDetailState } from '../containers/TicketDetail/duck';
import type { State as TicketListState } from '../containers/TicketList/duck';
import type { State as TransactionListState } from '../containers/TransactionList/duck';
import type { State as SessionState } from '../ducks/session';
import type { State as SignupState } from '../containers/Signup/duck';
import type { State as SnackbarState } from '../ducks/snackbar';
import type { State as SubscriptionListState } from '../containers/SubscriptionList/duck';
import type { State as UiState } from '../ducks/ui';

// Root state for the application
export type State = {
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    addFunds: AddFundsState,
    config: ConfigState,
    customerList: CustomerListState,
    dialog: DialogState,
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    entities: EntitiesState,
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    form: Object,
    gigwalkerList: GigwalkerListState,
    googleMaps: GoogleMapsState,
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    layout: LayoutState,
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    locationListUpload: LocationListUploadState,
    payoutList: PayoutListState,
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    projectBuilder: ProjectBuilderState,
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    projectSummary: ProjectSummaryState,
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    projectManagement: ProjectManagementState,
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    router: {
        action: string,
        location: Location,
    },
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    session: SessionState,
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    signup: SignupState,
    snackbar: SnackbarState,
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    subscriptionList: SubscriptionListState,
    ticketDetail: TicketDetailState,
    ticketList: TicketListState,
    transactionList: TransactionListState,
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    ui: UiState,
};

export const init: State = {
    addFunds: addFundsInit,
    config: configInit,
    customerList: customerListInit,
    dialog: dialogInit,
    entities: entitiesInit,
    form: {},
    gigwalkerList: gigwalkerListInit,
    googleMaps: googleMapsInit,
    layout: layoutInit,
    locationListUpload: locationListUploadInit,
    payoutList: payoutListInit,
    projectBuilder: projectBuilderInit,
    projectSummary: projectSummaryInit,
    projectManagement: projectManagementInit,
    router: {
        action: 'POP',
        location: {
            hash: '',
            pathname: '/',
            search: '',
        },
    },
    session: sessionInit,
    signup: signupInit,
    snackbar: snackbarInit,
    subscriptionList: subscriptionListInit,
    ticketDetail: ticketDetailInit,
    ticketList: ticketListInit,
    transactionList: transactionListInit,
    ui: uiInit,
};
