// @flow
import { createSelector } from 'reselect';
import type { ContextRouter } from 'react-router';
import entitySelector from '../../../../../redux/entities/entitySelector';
import getDisplayName from '../../../../../util/getDisplayName';
import { getAPIParams, parseSearchParams } from '../../../duck/selectors';
import type { State as RootState } from '../../../../../redux/initialState';

export { getAPIParams, parseSearchParams } from '../../../duck/selectors';

const _typeFilterOptions = [
    { label: 'Balance edit', value: 'PAYMENTS_ADD' },
    { label: 'Credit', value: 'PAYMENTS_ADD_STRIPE' },
    { label: 'Debit', value: 'PAYMENTS_DEDUCT' },
    { label: 'Refund', value: 'PAYMENTS_REFUND' },
];

export const getTypeFilterOptions = (): Object[] => _typeFilterOptions;

const getSelectedTypes = createSelector(
    parseSearchParams,
    (search: Object) => search.type || [],
);
export const getTypeFilterValue = createSelector(
    getSelectedTypes,
    getTypeFilterOptions,
    (selected: string[], typeFilterOptions: Object[]): Object[] => (
        selected.reduce((acc, value) => {
            const selectedOption = typeFilterOptions.find((option) => option.value === value);
            if (selectedOption) {
                acc.push(selectedOption);
            }
            return acc;
        }, [])
    )
);

const organizationSelector = entitySelector('organizations');
const getSelectedOrganization = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): ?Object => {
    const search = parseSearchParams(state, props);
    const selected = search.organization;
    return selected ? organizationSelector(state, selected) : null;
};

export const getOrganizationFilterValue = createSelector(
    getSelectedOrganization,
    (selected: ?Object): ?Object => (
        selected ? { label: selected.organization_name, value: selected } : null
    )
);

const customerSelector = entitySelector('customers');
const getSelectedCustomers = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): Object[] => {
    const search = parseSearchParams(state, props);
    const selected = search.customer || [];
    return customerSelector(state, selected);
};

export const getCustomerFilterValue = createSelector(
    getSelectedCustomers,
    (selected: Object[]): Object[] => (
        selected.map((customer: Object): Object => ({
            label: getDisplayName(customer),
            value: customer,
        }))
    )
);

export default {
    getAPIParams,
    getCustomerFilterValue,
    getOrganizationFilterValue,
    getTypeFilterOptions,
    getTypeFilterValue,
    parseSearchParams,
};
