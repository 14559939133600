// @flow
export default (theme: Object) => ({
    form: {
        '& > *:not(:last-child)': {
            marginBottom: theme.spacing(2),
        },
    },
    list: {
        position: 'relative',
    },
    listItem: {
        minHeight: 60,
        backgroundColor: theme.palette.grey[100],

        '&$selected': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,

            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            },
        },

        '&:hover': {
            backgroundColor: theme.palette.grey[100],
        },
    },
    listItemContainer: {
        '&$selected': {
            color: theme.palette.primary.contrastText,
        },
    },
    deleteAll: {
        position: 'absolute',
        right: 0,
        top: 10 * -2.5,
    },
    controls: {
        '&>*:not(:last-child)': {
            marginRight: 10 * 1.5,
        },
    },
    control: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    selected: {},
});
