// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import cx from 'classnames';
import striptags from 'striptags';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import { IconButton } from '@material-ui/core';
import {
    CheckCircle as CheckCircleIcon,
    Close as CloseIcon,
    Error as ErrorIcon,
    Info as InfoIcon,
    Warning as WarningIcon,
} from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import * as snackbar from '../../ducks/snackbar';
import styles from './styles';
import type { State as RootState } from '../../redux/initialState';

type OwnProps = {};
type StateProps = {
    notifications: Object[],
};
type DispatchProps = {
    removeSnackbar: (key: string) => void,
};
type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles(styles, { name: 'SnackbarManager' });

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

export function SnackbarManager(props: Props) {
    const { notifications, removeSnackbar } = props;

    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const classes = useStyles(props);

    const queueRef = useRef([]);

    const handleSnackbarExited = useCallback((event: SyntheticEvent<any>, key: string) => {
        removeSnackbar(key);
    }, [removeSnackbar]);

    const handleSnackbarClose = useCallback((event: SyntheticEvent<any>) => {
        const { key } = event.currentTarget.dataset;
        closeSnackbar(key);
    }, [closeSnackbar]);

    useEffect(() => {
        const queue = queueRef.current;
        queueRef.current = notifications.map((notification: Object) => {
            const { key, message, options } = notification;
            const { variant } = options;

            // @todo Decide what options should override defaults
            const finalOptions = {
                action: () => (
                    <Fragment>
                        <IconButton color="inherit" data-key={key} onClick={handleSnackbarClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Fragment>
                ),
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                ContentProps: {
                    className: cx(
                        classes.base,
                        {
                            [classes.error]: variant === 'error',
                            [classes.info]: variant === 'info',
                            [classes.success]: variant === 'success',
                            [classes.warning]: variant === 'warning',
                        }
                    ),
                },
                onExited: handleSnackbarExited,
                ...options,
            };

            const Icon = variantIcon[variant] || null;
            const content = (
                <Fragment>
                    {Icon ? <Icon className={classes.variantIcon} fontSize="small" /> : null}
                    <span dangerouslySetInnerHTML={{ __html: striptags(message, '<a><br>') }} />
                </Fragment>
            );

            if (!queue.includes(key)) {
                enqueueSnackbar(content, { ...finalOptions, key });
            }

            return key;
        });
    }, [classes, enqueueSnackbar, handleSnackbarClose, handleSnackbarExited, notifications]);

    return null;
}

const mapStateToProps = (state: RootState): StateProps => ({
    notifications: state.snackbar.notifications,
});
const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    removeSnackbar: (key: string) => dispatch(snackbar.actions.remove(key)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default connector(SnackbarManager);
