// @flow
import { createSelector } from 'reselect';
import qs from 'qs';
import type { ContextRouter } from 'react-router';
import type { State as RootState } from '../../../redux/initialState';

export const getInitialValues = createSelector(
    <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): string => {
        const { location } = props;
        return location.search || '';
    },
    (search: string): Object => {
        const queryParams = search ? qs.parse(search, { ignoreQueryPrefix: true }) : {};
        const {
            code,
            email,
            first_name: firstName,
            last_name: lastName,
        } = queryParams;

        return {
            code: code || '',
            confirmPassword: '',
            email: email || '',
            firstName: firstName || '',
            lastName: lastName || '',
            password: '',
            stayUpdated: false,
            termsOfService: false,
        };
    }
);

export default {
    getInitialValues,
};
