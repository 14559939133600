// @flow
import { createSelector } from 'reselect';
import type { ContextRouter } from 'react-router';
import { VALUE_TYPE } from '../../../../../shared/constant/ProjectConstant';
import entitySelector from '../../../../../../common/redux/entities/entitySelector';
import getThumbnailUrl from '../../../../../../common/util/getThumbnailUrl';
import type { State as RootState } from '../../../../../../common/redux/initialState';

const subscriptionSelector = entitySelector('subscriptions');

const getSubscription = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }) => {
    const { match } = props;
    return subscriptionSelector(state, match.params.subscriptionId);
};

export const getDataTypeSummary = createSelector(
    getSubscription,
    (state: RootState) => state.entities.dataTypes,
    (state: RootState) => state.projectSummary.dataTypeSummary,
    (subscription?: Object, dataTypes: Object, dataTypeSummary: ?Object): Object[] => {
        if (!subscription || !dataTypeSummary) {
            return [];
        }

        // Use depth-first approach to get questions in the order a worker would see them
        // This will flatten the list like so: Q1 -> Q1A -> Q1Aa -> Q1Ab -> Q1B -> Q2
        const flattenSkipLogic = (node: Object, depth?: number = 0): Object[] => {
            const list = [];

            if (node.data_type_id) {
                list.push({ id: node.data_type_id, depth });
            } else if (node.data_types) {
                node.data_types.forEach((d: Object) => {
                    list.push(...flattenSkipLogic(d, depth));
                });
            }

            if (node.children) {
                node.children.forEach((expr: Object) => {
                    const children = expr.$cond[1];
                    const altChildren = expr.$cond[2];
                    const skipLogicReducer = (childList: Object[], d: Object): Object[] => {
                        childList.push(...flattenSkipLogic(d, depth + 1));
                        return childList;
                    };

                    if (children) {
                        list.push(...children.reduce(skipLogicReducer, []));
                    }
                    if (altChildren) {
                        list.push(...altChildren.reduce(skipLogicReducer, []));
                    }
                });
            }

            return list;
        };

        const { organization_observation_target_lists: targetLists = [] } = subscription;
        const questionList = targetLists.reduce((questions, list) => {
            if (list.data_types) {
                list.data_types.forEach((d: Object) => {
                    questions.push(...flattenSkipLogic(d));
                });
            }
            return questions;
        }, []);

        const summary = [];
        const { charts, free_text: freeText, photos } = dataTypeSummary;
        let sequence = 0;

        questionList.forEach((question: Object) => {
            const { id, depth } = question;
            const dataType = dataTypes[id];

            if (dataType) {
                // Always render a chart/legend for multiple choice questions, even when no data
                // has been collected. This way, we'll still display the question's propositions
                const isMultipleChoice = [VALUE_TYPE.MULTIPLE_CHOICE, VALUE_TYPE.MULTI_SELECT, VALUE_TYPE.CHECKBOXES].includes(dataType.value_type);

                // Note: We are removing empty responses/submissions from the dataset. Is this desirable?
                if (charts[dataType.id] || isMultipleChoice) {
                    const data = charts[dataType.id] || {};
                    summary.push({
                        type: 'chart',
                        data: Object.entries(data).reduce((acc, [key, value]) => (
                            key.trim() ? { ...acc, [key]: value } : acc
                        ), {}),
                        dataType,
                        sequence: depth === 0 ? sequence += 1 : null,
                    });
                } else if (photos[dataType.id]) {
                    const data = photos[dataType.id] || [];
                    summary.push({
                        type: 'photo',
                        data: data.map((dataItem: Object): Object => ({
                            ...dataItem,
                            thumbnail_url: dataItem.thumbnail_url || getThumbnailUrl(dataItem.photo_url),
                        })),
                        dataType,
                        sequence: depth === 0 ? sequence += 1 : null,
                    });
                } else if (freeText[dataType.id]) {
                    const data = freeText[dataType.id] || {};
                    summary.push({
                        type: 'freeText',
                        data: Object.entries(data).map(([key, value]) => ({
                            data_item_value: value,
                            ticket_id: key,
                        })),
                        dataType,
                        sequence: depth === 0 ? sequence += 1 : null,
                    });
                } else {
                    summary.push({
                        type: 'noData',
                        data: null,
                        dataType,
                        sequence: depth === 0 ? sequence += 1 : null,
                    });
                }
            }
        });

        return summary;
    }
);

export const getTicketCount = createSelector(
    getSubscription,
    (subscription?: Object) => {
        if (!subscription) {
            return 0;
        }

        const {
            organization_location_lists: locationLists = [],
            worker_count: workerCount = 1,
        } = subscription;

        const locationCount = locationLists.reduce((count, list) => (
            list ? count + list.location_count : count
        ), 0);

        return workerCount * locationCount;
    }
);

export default {
    getDataTypeSummary,
    getTicketCount,
};
