// @flow
export default (theme: Object) => ({
    paper: {
        maxWidth: 540,
        maxHeight: 1000,
        backgroundColor: theme.palette.grey[100],
        padding: theme.spacing(4, 3),
        margin: [[0, 'auto', theme.spacing(3)]],

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(4, 2),
        },
    },
    appLink: {
        boxShadow: ['none', '!important'],
        width: 180,
    },
});
