// @flow
export default () => ({
    rating: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'ProximaNova',
        fontWeight: 600,
    },
    stars: {
        marginRight: 8,
    },
    comment: {
        marginTop: 4,

        '&::before': {
            content: 'open-quote',
        },
        '&::after': {
            content: 'close-quote',
        },
    },
    sortValue: {
        fontWeight: 600,
        fontSize: 14,
    },
    sortMenu: {
        width: 'auto',
    },
});
