// @flow
export default (theme: Object) => ({
    root: {
        fontFamily: 'ProximaNova',
        fontSize: 16,
        justifyContent: 'flex-end',
    },
    recordCount: {
        marginRight: 'auto',

        '& > *:not(:last-child)': {
            marginRight: theme.spacing(0.5),
        },
    },
});
