// @flow
import React, { Component } from 'react';
import cx from 'classnames';
import isMatch from 'lodash.ismatch';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import ReactTable from 'react-table';
import type { Node, ChildrenArray } from 'react';
import type { TFunction } from 'react-i18next';
import styles from './styles';

type Column = {
    // Renderers
    Cell?: any,
    Header?: any,
    Footer?: any,
    Aggregated?: any,
    Pivot?: any,
    PivotValue?: any,
    Expander?: any,
    Filter?: any,

    // Standard options
    sortable?: boolean,
    resizable?: boolean,
    filterable?: boolean,
    show?: boolean,
    minWidth?: number,

    // Cells only
    className?: string,
    style?: Object,
    getProps?: Function,

    // Headers only
    headerClassName?: string,
    headerStyle?: Object,
    getHeaderProps?: Function,

    // Footers only
    footerClassName?: string,
    footerStyle?: Object,
    getFooterProps?: Function,
    filterAll?: boolean,
    filterMethod?: Function,
    sortMethod?: Function,
    defaultSortDesc?: boolean,
}

type Props = {
    classes: Object,
    className?: string,
    columns: Column[],
    count: number,
    data: Object[],
    loading?: boolean,
    onSelect?: (item: Object) => void,
    page: number,
    pageSize: number,
    selected?: [],
    t: TFunction,
}

const NoDataComponent = () => null;
const getTrGroupProps = (state, rowInfo) => ({ rowInfo });
const getTheadThProps = (state, rowInfo, column, instance) => ({
    defaultSortDesc: column.defaultSortDesc != null
        ? column.defaultSortDesc
        : instance.props.defaultSortDesc,
});

export class DataTable extends Component<Props> {
    handleSelect = (item: Object) => {
        const { onSelect } = this.props;
        if (typeof onSelect === 'function') {
            onSelect(item);
        }
    };

    renderTable = (props: { children?: Node, className?: string }) => {
        const { children, className, ...rest } = props;
        const { classes, data, loading, t } = this.props;

        let overlay = null;
        if (loading) {
            overlay = <div className={classes.loading}><CircularProgress /></div>;
        } else if (data.length === 0) {
            overlay = <div className={classes.noData}>{t('table.noData')}</div>;
        }

        return (
            <div {...rest} className={cx(classes.table, className)}>
                {overlay}
                <Table component="div" className={classes.tableInner}>
                    {children}
                </Table>
            </div>
        );
    };

    renderHeaderGroup = (props: { children?: Node, className: ? string }) => {
        const { children, className, ...rest } = props;
        const { classes } = this.props;
        return (
            <TableHead
              {...rest}
              component="div"
              className={cx(classes.headerGroup, className)}
            >
                {children}
            </TableHead>
        );
    };

    renderHeader = (props: { children?: ChildrenArray<any>, className?: string, defaultSortDesc: boolean, toggleSort: Function }) => {
        const { children, className, defaultSortDesc, toggleSort, ...rest } = props;
        const { classes } = this.props;

        const sortable = className && className.includes('-cursor-pointer');
        const isSortedDesc = className && className.includes('-sort-desc');
        const isSortedAsc = className && className.includes('-sort-asc');
        const sorted = isSortedAsc || isSortedDesc;
        const sortDirection = (!sorted && defaultSortDesc) || isSortedDesc ? 'desc' : 'asc';

        const newClassName = cx({
            [classes.sortable]: sortable,
            [classes.sorted]: sorted,
        });

        return (
            <TableCell
              {...rest}
              component="div"
              className={newClassName}
              classes={{ head: classes.header }}
            >
                {sortable
                    ? (
                        <TableSortLabel
                          active={sorted}
                          direction={sortDirection}
                          onClick={toggleSort}
                        >
                            {children}
                        </TableSortLabel>
                    )
                    : children
                }
            </TableCell>
        );
    };

    renderBody = (props: { children?: Node, className?: string }) => {
        const { children, className, ...rest } = props;
        const { classes, loading } = this.props;

        return (
            <TableBody
              {...rest}
              component="div"
              className={cx(classes.body, className)}
              style={{ opacity: loading ? 0 : 1 }}
            >
                {children}
            </TableBody>
        );
    };

    renderRowGroup = (props: { children?: Node, className?: string, rowInfo: Object }) => {
        const { children, rowInfo, ...rest } = props;
        const { classes, selected } = this.props;

        if (!rowInfo) {
            return null;
        }

        const rowData = rowInfo.original;
        const isSelected = (selected || []).some((item: any) => (
            rowData === item || rowData.id === item.id || isMatch(rowData, item)
        ));
        const className = cx(classes.rowGroup, { [classes.selected]: isSelected }, props.className);

        return (
            <TableRow
              {...rest}
              className={className}
              component="div"
              hover
              onClick={() => { this.handleSelect(rowData); }}
            >
                {children}
            </TableRow>
        );
    };

    renderRow = (props: { children?: Node, className?: string }) => {
        const { children, className, ...rest } = props;
        const { classes } = this.props;
        return (<div {...rest} className={cx(classes.row, className)}>{children}</div>);
    };

    renderCell = (props: { children?: Node, className?: string }) => {
        const { children, className, ...rest } = props;
        const { classes } = this.props;
        return (
            <TableCell
              {...rest}
              component="div"
              className={className}
              classes={{ body: classes.cell }}
            >
                {children}
            </TableCell>
        );
    };

    renderPagination = (props: Object) => {
        const { count, page, pageSize, pageSizeOptions } = props;
        const { classes, loading } = this.props;

        const handleChangeRowsPerPage = (event: SyntheticInputEvent<*>) => {
            const { onPageSizeChange } = props;
            const rowsPerPage = parseInt(event.target.value, 10);
            onPageSizeChange(rowsPerPage);
        };

        // TablePagination will call onChangePage when the component receives new props
        // AND the page is out of range. If we are fetching data for the first time, we
        // don't know the record count, so onChangePage will always be called with a
        // value of 0. This breaks url navigation to a specific page.
        const handleChangePage = (event: SyntheticEvent<*>, value: number) => {
            const { onPageChange } = props;
            if (value >= 0 && !loading) {
                onPageChange(value);
            }
        };

        return (
            <TableFooter className={classes.footer} component="div">
                <TableRow component="div">
                    <TablePagination
                      component="div"
                      classes={{
                          caption: classes.paginationCaption,
                          input: classes.paginationInput,
                          select: classes.paginationSelect,
                          selectIcon: classes.paginationSelectIcon,
                      }}
                      count={count}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={pageSizeOptions}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableRow>
            </TableFooter>
        );
    };

    render() {
        const { classes, className, ...rest } = this.props;
        return (
            <ReactTable
              {...rest}
              className={cx(classes.root, className)}
              manual
              sortable
              pageSizeOptions={[10, 20, 50]}
              defaultPageSize={10}
              PaginationComponent={this.renderPagination}
              TableComponent={this.renderTable}
              TheadComponent={this.renderHeaderGroup}
              TbodyComponent={this.renderBody}
              TrGroupComponent={this.renderRowGroup}
              TrComponent={this.renderRow}
              ThComponent={this.renderHeader}
              TdComponent={this.renderCell}
              LoadingComponent={NoDataComponent}
              NoDataComponent={NoDataComponent}
              getTheadThProps={getTheadThProps}
              getTrGroupProps={getTrGroupProps}
            />
        );
    }
}

const enhance = compose(
    withStyles(styles, { name: 'DataTable' }),
    withTranslation(),
);
export default enhance(DataTable);
