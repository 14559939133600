// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import { Field, Form } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import FormikInput from '../../../../components/FormikInput';
import FormikCheckbox from '../../../../components/FormikCheckbox';
import SubmitButton from '../../../../components/SubmitButton';
import styles from './styles';

type Props = {
    dirty: boolean,
    handleSubmit: Function,
    isSubmitting: boolean,
    isValid: boolean,
    setStatus: (status: any) => void,
    status?: any,
};

const useStyles = makeStyles(styles, { name: 'AcceptInviteForm' });

export default function AcceptInviteForm(props: Props) {
    const { dirty, handleSubmit, isSubmitting, isValid, setStatus, status } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const handleChange = useCallback(() => {
        if (status && status.error) {
            setStatus({ error: null });
        }
    }, [status, setStatus]);

    const linkProps = {
        rel: 'noopener noreferrer',
        target: '_blank',
    };
    const termsOfService = (
        <Trans
          defaults={t('shared.auth.terms')}
          components={[
              'I agree with the',
              <a {...linkProps} href="http://www.gigwalk.com/terms-of-service">Terms of Service</a>,
              'and',
              <a {...linkProps} href="http://www.gigwalk.com/privacy-policy/">Privacy Policy</a>,
          ]}
        />
    );

    return (
        <Paper className={classes.paper} elevation={0}>
            <Form name="acceptInvite" onChange={handleChange}>
                <h1>{t('acceptInvite.form.acceptInvitation')}</h1>
                {status && status.error
                    ? <div className={classes.error}>{status.error}</div>
                    : null
                }
                <fieldset className={classes.fieldset}>
                    <div>
                        <Field
                          className={classes.firstName}
                          classes={{ input: classes.input }}
                          component={FormikInput}
                          fullWidth
                          label={t('acceptInvite.form.firstName')}
                          labelPlacement="top"
                          margin="dense"
                          name="firstName"
                          placeholder={t('shared.auth.firstNamePlaceholder')}
                          required
                          variant="outlined"
                        />
                        <Field
                          className={classes.firstName}
                          classes={{ input: classes.input }}
                          component={FormikInput}
                          fullWidth
                          label={t('acceptInvite.form.lastName')}
                          labelPlacement="top"
                          margin="dense"
                          name="lastName"
                          placeholder={t('shared.auth.lastNamePlaceholder')}
                          required
                          variant="outlined"
                        />
                        <Field
                          classes={{ input: classes.input }}
                          component={FormikInput}
                          disabled
                          fullWidth
                          label={t('acceptInvite.form.email')}
                          labelPlacement="top"
                          margin="dense"
                          name="email"
                          required
                          type="email"
                          variant="outlined"
                        />
                        <Field
                          className={classes.password}
                          classes={{ input: classes.input }}
                          component={FormikInput}
                          fullWidth
                          label={t('acceptInvite.form.password')}
                          labelPlacement="top"
                          margin="dense"
                          minLength={6}
                          name="password"
                          placeholder={t('shared.auth.newPasswordPlaceholder')}
                          required
                          type="password"
                          variant="outlined"
                        />
                        <Field
                          className={classes.confirmPassword}
                          classes={{ input: classes.input }}
                          component={FormikInput}
                          fullWidth
                          label={t('shared.auth.confirmPassword')}
                          labelPlacement="top"
                          minLength={6}
                          margin="dense"
                          name="confirmPassword"
                          placeholder={t('shared.auth.confirmNewPasswordPlaceholder')}
                          required
                          type="password"
                          variant="outlined"
                        />
                        <div className={classes.checkboxGroup}>
                            <Field
                              classes={{ checkbox: classes.checkbox }}
                              color="primary"
                              component={FormikCheckbox}
                              label={t('shared.auth.stayUpdated')}
                              name="stayUpdated"
                              type="checkbox"
                            />
                            <Field
                              classes={{ checkbox: classes.checkbox }}
                              color="primary"
                              component={FormikCheckbox}
                              label={termsOfService}
                              name="termsOfService"
                              type="checkbox"
                              required
                            />
                        </div>
                    </div>
                </fieldset>
                <SubmitButton
                  className={classes.submitButton}
                  color="secondary"
                  disabled={!dirty || isSubmitting || !isValid}
                  onClick={handleSubmit}
                  size="large"
                  submitting={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                    {t('acceptInvite.form.signup')}
                </SubmitButton>
            </Form>
        </Paper>
    );
}
