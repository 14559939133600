// @flow weak
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import axios from 'axios';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from '@material-ui/styles';
import { USER_ROLES } from '../../../../shared/constant/UserRoles';
import Lightbox from '../../../../../common/components/Lightbox';
import ImageEditor from '../../../../../common/components/ImageEditor';
import ExifViewer from '../../../../../common/components/ExifViewer';
import theme from '../../../../../styles/theme';
import Widget from './Widget';
import LangUtil from '../../../../shared/util/LangUtil';
import Upload from '../../../../shared/view/ui/Upload';
import template from '../../../templates/ticket/widgets/photo/Photo.hbs';
import addedImages from '../../../templates/ticket/widgets/photo/AddedImages.hbs';
import { dataUriToBlob } from '../../../../shared/util/FileUtil';

// Roles that can edit answer on behalf of worker
const delegateRoles = [USER_ROLES.SUPER_ADMIN, USER_ROLES.SELF_SERVICE, USER_ROLES.PLATFORM_ADMIN];

/**
 * @class (View) Photo
 * @constructor
 */
export default Widget.extend({

    events: {
        'click [data-action="uploadImage"]': 'onClickUpload',
        'click [data-action="magnify"]': 'magnify',
        'click [data-action="view-metadata"]': 'viewTargetMetadata',
    },

    timestamp: null,

    versions: {},

    initialize(options: Object) {
        this.timestamp = Date.now();
        this._super(options);
    },

    render() {
        this.images = this.di.data_item_value || [];

        const { index, ticket } = this.props;
        const dataTypeID = this.instruction.get('data_type_id');
        const dataType = ticket.data_type_map[dataTypeID];
        const attachments = this.groupAttachments(dataType.attachments);

        const localisation = this.getLocalisation();

        this.$el.html(template({
            ...localisation,
            questionNumber: index != null ? index + 1 : null,
            target: this.target.name,
            title: dataType.description,
            body: LangUtil.replaceTitle((dataType.questions.question_text || ''), this.target.name),
            complete: Boolean(this.di.data_item_answered),
            images: attachments.images,
            files: attachments.files,
            hasMetadata: !!this.target.metadata && Object.keys(this.target.metadata).length > 0,
        }));

        const lightboxContainer = this.$el.find('.react-lightbox-container')[0] || document.createElement('div');
        lightboxContainer.classList.add('react-lightbox-container');
        this.$el.append(lightboxContainer);

        const imageEditorContainer = this.$el.find('.react-image-editor-container')[0] || document.createElement('div');
        imageEditorContainer.classList.add('react-image-editor-container');
        this.$el.append(imageEditorContainer);

        const exifViewerContainer = this.$el.find('.react-exif-viewer-container')[0] || document.createElement('div');
        exifViewerContainer.classList.add('react-exif-viewer-container');
        this.$el.append(exifViewerContainer);

        this.preview();
        this.validateInput();
        this.tweakCSS(attachments);

        this._super();
    },

    renderLightbox(props: Object) {
        const { ticket } = this.props;
        const lightboxContainer = this.$el.find('.react-lightbox-container')[0];
        const dataTypeID = this.instruction.get('data_type_id');
        const dataType = ticket.data_type_map[dataTypeID];

        const images = this.images.map(({ photo_url: url }: { photo_url: string }) => ({
            src: `${url}?t=${this.timestamp}&v=${this.versions[url] || 0}`,
            title: dataType.description,
            description: dataType.questions.question_text,
        }));

        const handleClose = () => {
            this.exifViewerOpen = false;
            this.renderLightbox({ ...props, open: false });
            this.renderExifViewer({ open: this.exifViewerOpen });
        };

        const handleChange = (index) => {
            this.renderLightbox({ ...props, currentImage: index });
            this.renderExifViewer({ open: this.exifViewerOpen, photoUrl: images[index].src });
        };

        const handleEditClick = (index: number) => {
            this.exifViewerOpen = false;
            this.renderExifViewer({ open: this.exifViewerOpen });
            this.renderImageEditor({
                currentImage: index,
                imageUrl: images[index].src,
                open: true,
            });
        };

        const handleInfoClick = (index: number) => {
            this.exifViewerOpen = !this.exifViewerOpen;
            this.renderExifViewer({
                open: this.exifViewerOpen,
                photoUrl: images[index].src,
            });
        };

        render(
            <I18nextProvider i18n={i18next}>
                <ThemeProvider theme={theme}>
                    <Lightbox
                      {...props}
                      images={images}
                      onChange={handleChange}
                      onClose={handleClose}
                      onEditClick={handleEditClick}
                      onInfoClick={handleInfoClick}
                    />
                </ThemeProvider>
            </I18nextProvider>,
            lightboxContainer
        );
    },

    renderImageEditor(props: Object) {
        const { currentImage, ...other } = props;
        const imageEditorContainer = this.$el.find('.react-image-editor-container')[0];

        const handleClose = () => {
            this.renderImageEditor({ ...props, open: false });
        };

        const handleSave = (dataURL: string) => {
            const originalSrc = this.images[currentImage].photo_url;
            const blob = dataUriToBlob(dataURL, 'image/jpeg');
            const formData = new FormData();
            formData.append('url', originalSrc);
            formData.append('file', blob);

            return axios.put('/upload', formData)
                .then(() => {
                    this.versions[originalSrc] = (this.versions[originalSrc] || 0) + 1;
                    this.renderImageEditor({ ...props, open: false });
                    this.renderLightbox({ currentImage, open: false });
                    this.render();
                    this.renderLightbox({ currentImage, open: true });
                });
        };

        render(
            <I18nextProvider i18n={i18next}>
                <ThemeProvider theme={theme}>
                    <ImageEditor {...other} onClose={handleClose} onSave={handleSave} />
                </ThemeProvider>
            </I18nextProvider>,
            imageEditorContainer
        );
    },

    renderExifViewer(props: Object) {
        const { ticket } = this.props;
        const { location } = ticket;

        const gigLocation = {
            address: location.formatted_address,
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude),
        };

        const exifViewerContainer = this.$el.find('.react-exif-viewer-container')[0];

        const handleClose = () => {
            this.exifViewerOpen = false;
            this.renderExifViewer({ ...props, open: this.exifViewerOpen });
        };

        render(
            <I18nextProvider i18n={i18next}>
                <ThemeProvider theme={theme}>
                    <ExifViewer {...props} gigLocation={gigLocation} onClose={handleClose} />
                </ThemeProvider>
            </I18nextProvider>,
            exifViewerContainer
        );
    },

    magnify(e: JQueryEventObject) {
        const index = parseInt($(e.target).data('index'), 10);

        if (Number.isNaN(index)) {
            this._super(e);
        } else {
            this.renderLightbox({
                currentImage: index,
                open: true,
            });
        }
    },

    getLocalisation() {
        return {
            ...this.text,
            uploadLabel: this.text.add_photo,
            questionType: this.text.photo,
        };
    },

    tweakCSS(attachments: { images: Object }) {
        /* centers photo button when attachment photos are empty */
        this.$el
            .find('.answers:first')
            .toggleClass('no-examples', Boolean(!attachments.images || !attachments.images.length));
    },

    onClickUpload() {
        if (this.upload) {
            this.upload.destroy();
        }

        this.upload = new Upload({
            text: GW.localisation.shared.photoChooser,
            onSave: this.addImage.bind(this),
        });
    },

    addImage(urlType: { url: string }) {
        this.images.push({
            photo_url: urlType.url,
        });

        this.preview();

        this.validateInput();
    },

    removeImage(e: Event) {
        // $FlowTypedIssue jquery libdef thinks data method returns a JQuery object
        const url = $(e.currentTarget).data('url').split('?')[0];
        const img = _.find(this.images, (obj: { photo_url: string }) => obj.photo_url === url);

        this.images = _.without(this.images, img);

        this.preview();
    },

    clean() {
        let len: number = this.images.length;

        for (let i: number = 0; i < len; i += 1) {
            if (this.images[i]) {
                if (typeof this.images[i] === 'string') {
                    this.images[i] = {
                        photo_url: this.images[i],
                    };
                }

                continue;
            }

            this.images.splice(i, 1);
            len -= 1;
            i -= 1;
        }
    },

    preview() {
        this.clean();

        const images = this.images.map((image: Object) => ({
            ...image,
            photo_url: `${image.photo_url}?t=${this.timestamp}&v=${this.versions[image.photo_url] || 0}`,
        }));

        this.$preview = this.$el.find('[data-target="images"]:first');
        this.$preview.html(addedImages(images));
        this.$preview
            .find('[data-action="magnify"]')
            .bind('click', _.bind(this.magnify, this));
        this.$preview
            .find('[data-action="trash"]')
            .bind('click', _.bind(this.removeImage, this));
    },

    validateSave(): boolean {
        const { ticket, user } = this.props;
        const dataTypeID = this.instruction.get('data_type_id');
        const dataType = ticket.data_type_map[dataTypeID];
        const canSaveBlankAnswer = delegateRoles.includes(user.role) && dataType.is_required === false;
        return canSaveBlankAnswer ? true : this.images.length > 0;
    },

    setReadMode() {
        this._super();
        // setReadMode is called after initial rendering, so hide images here
        this.toggleImagesSection();
    },

    toggleImagesSection() {
        if (this.images.length) {
            // if there are some uploaded images, hide only button
            this.$el.find('.answer.image .wrapper').toggle(!this.readMode);
        } else {
            // if there aren't any uploaded images, hide the whole section
            this.$el.find('.answer.image').css({
                display: this.readMode ? 'none' : 'inline-block',
            });
        }
    },

    getValue() {
        const dataTypeID = this.instruction.get('data_type_id');

        return {
            data_item_value: this.images,
            template_id: this.templateID || null,
            data_item_answered: Boolean(this.images.length),
            data_item_latitude: `${this.deviceLocation.latitude}`,
            data_item_longitude: `${this.deviceLocation.longitude}`,
            data_item_timestamp: `${(moment(this.di.data_item_timestamp) || moment()).toDate().getTime()}`,
            data_type_id: dataTypeID,
            observation_target_id: this.target.id,
        };
    },

    deactivate() {
        const lightboxContainer = this.$el.find('.react-lightbox-container')[0];
        if (lightboxContainer) {
            unmountComponentAtNode(lightboxContainer);
        }

        this._super();
    },
});
