// @flow
/* eslint-disable max-len */
import React, { Fragment } from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
    <Fragment>
        <path d="M 12.001 21.547 L 6.227 15.354 C 6.068 15.197 5.915 15.032 5.77 14.863 L 5.654 14.739 L 5.666 14.739 C 4.494 13.32 3.79 11.5 3.79 9.516 C 3.79 4.981 7.466 1.305 12.001 1.305 C 16.535 1.305 20.21 4.981 20.21 9.516 C 20.21 11.5 19.507 13.32 18.336 14.739 L 18.347 14.739 L 18.231 14.863 C 18.085 15.032 17.932 15.197 17.773 15.354 Z" />
        <ellipse cx="12.001" cy="21.686" rx="4.439" ry="1.314" style={{ fill: '#000000', fillOpacity: 0.3 }} />
        <path d="M 291 242 m -7 0 a 7 7 0 1 0 14 0 a 7 7 0 1 0 -14 0 Z M 291 242 m -6.6 0 a 6.6 6.6 0 0 1 13.2 0 a 6.6 6.6 0 0 1 -13.2 0 Z" transform="matrix(-0.707107, 0.707107, -0.707107, -0.707107, 388.887665, -25.13213)" style={{ fill: '#ffffff' }} />
        <circle cx="12" cy="9.841" r="1.037" style={{ fill: '#ffffff' }} />
        <path d="M 11.028 6.599 L 10.434 7.248 L 9.406 7.248 C 9.05 7.248 8.758 7.54 8.758 7.896 L 8.758 11.786 C 8.758 12.143 9.05 12.434 9.406 12.434 L 14.593 12.434 C 14.95 12.434 15.241 12.143 15.241 11.786 L 15.241 7.896 C 15.241 7.54 14.95 7.248 14.593 7.248 L 13.565 7.248 L 12.972 6.599 L 11.028 6.599 Z M 12 11.462 C 11.105 11.462 10.379 10.736 10.379 9.841 C 10.379 8.946 11.105 8.221 12 8.221 C 12.894 8.221 13.621 8.946 13.621 9.841 C 13.621 10.736 12.894 11.462 12 11.462 Z" style={{ fill: '#ffffff' }} />
    </Fragment>,
    'PlaceMarker'
);
