// @flow
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import type { State as RootState } from '../../../redux/initialState';
import { ticket as ticketSchema } from '../../../redux/entities/schemas';

const getEntities = (state: RootState): Object => state.entities;
const getTicketId = (state: RootState, props: Object): ?number => props.ticketId;

export const getTicket = createSelector(
    getEntities,
    getTicketId,
    (entities: Object, ticketId: ?number) => denormalize(ticketId, ticketSchema, entities),
);

export const getTicketMetadata = createSelector(
    getTicket,
    (ticket: ?Object): Object => {
        if (!ticket) {
            return {
                ticket_id: null,
                subscription_id: null,
                ticket_metadata: null,
                location_metadata: null,
                subscription_metadata: null,
            };
        }

        // Remove metadata fields added by us. Ideally, this information should belong
        // to the subscription
        const {
            certifications,
            oversampling_overage: oversamplingOverage,
            oversampling_target: oversamplingTarget,
            ...subscriptionMetadata
        } = ticket.subscription_metadata;

        return {
            ticket_id: ticket.id,
            subscription_id: ticket.subscription.id,
            ticket_metadata: ticket.ticket_metadata || null,
            location_metadata: ticket.location.organization_data || null,
            subscription_metadata: subscriptionMetadata || null,
        };
    }
);

export default {
    getTicketMetadata,
};
