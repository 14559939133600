// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import union from 'lodash.union';
import types from './types';
import {
    FETCH_SUCCESS as FETCH_LOCATION_LIST_SUCCESS,
    CREATE_SUCCESS as CREATE_LOCATION_LIST_SUCCESS,
    REMOVE_LOCATIONS_SUCCESS,
    typeof fetchSuccess as fetchLocationListSuccess,
    typeof createSuccess as createLocationListSuccess,
    typeof removeLocationsSuccess,
} from '../../../../../redux/entities/locationLists';
import typeof {
    refreshPageSuccess,
    getNextPageSuccess,
    getUnresolvedSuccess,
    pendingAdd,
    pendingRemove,
    updatePending,
    select,
    toggleUploadNotification,
} from './actions';

type NewLocation = {
    formatted_address: string,
    title: string,
    latitude?: number,
    longitude?: number,
};

export type State = {
    locationIds: Array<number>,
    unresolvedLocationIds: Array<number>,
    selected: number,
    isLocationListUploadVisible: boolean,
    isUnresolvedLocationsVisible: boolean,
    pendingAddition: Array<Object>,
    pendingRemoval: Array<number>,
    displayUploadNotification: boolean,
};

export const init: State = {
    locationIds: [],
    unresolvedLocationIds: [],
    selected: 0,
    isLocationListUploadVisible: false,
    isUnresolvedLocationsVisible: false,
    pendingAddition: [],
    pendingRemoval: [],
    displayUploadNotification: false,
};

export default handleActions({
    [types.REFRESH_PAGE_SUCCESS]: (state: State, action: ActionType<refreshPageSuccess>) => {
        if (action.payload == null) {
            return state;
        }

        return {
            ...state,
            locationIds: action.payload,
        };
    },
    [types.GET_NEXT_PAGE_SUCCESS]: (state: State, action: ActionType<getNextPageSuccess>) => {
        if (action.payload == null) {
            return state;
        }

        return {
            ...state,
            locationIds: union(state.locationIds, action.payload),
        };
    },
    [FETCH_LOCATION_LIST_SUCCESS]: (state: State, action: ActionType<fetchLocationListSuccess>) => {
        if (action.payload == null) {
            return state;
        }

        return {
            ...state,
            locationIds: [],
            unresolvedLocationIds: [],
            pendingAddition: [],
            pendingRemoval: [],
        };
    },
    [CREATE_LOCATION_LIST_SUCCESS]: (state: State, action: ActionType<createLocationListSuccess>) => {
        if (action.payload == null) {
            return state;
        }

        return {
            ...state,
            locationIds: [],
            unresolvedLocationIds: [],
        };
    },
    [REMOVE_LOCATIONS_SUCCESS]: (state: State, action: ActionType<removeLocationsSuccess>) => {
        if (action.payload == null) {
            return state;
        }

        const removedIds = action.payload.result.map((item: Object): number => item.relation);
        const locationIds = state.locationIds.filter((id: number): boolean => !removedIds.includes(id));

        return {
            ...state,
            locationIds,
        };
    },
    [types.GET_UNRESOLVED_SUCCESS]: (state: State, action: ActionType<getUnresolvedSuccess>) => {
        if (action.payload == null) {
            return state;
        }

        return {
            ...state,
            unresolvedLocationIds: action.payload,
            isUnresolvedLocationsVisible: action.payload.length > 0,
        };
    },
    [types.PENDING_ADD]: (state: State, action: ActionType<pendingAdd>) => {
        if (action.payload == null) {
            return state;
        }

        return {
            ...state,
            pendingAddition: [
                ...state.pendingAddition,
                action.payload,
            ],
        };
    },
    [types.PENDING_REMOVE]: (state: State, action: ActionType<pendingRemove>) => {
        if (action.payload == null) {
            return state;
        }

        return {
            ...state,
            pendingRemoval: [
                ...state.pendingRemoval,
                action.payload,
            ],
        };
    },
    [types.UPDATE_PENDING]: (state: State, action: ActionType<updatePending>) => {
        if (action.payload == null) {
            return state;
        }

        // If the payload is an id, then we are updating a pending removal
        if (typeof action.payload === 'number') {
            return {
                ...state,
                pendingRemoval: state.pendingRemoval.filter((id: number): boolean => id !== action.payload),
            };
        }

        // Otherwise, the payload is a location that was pending addition
        const pending = state.pendingAddition.filter((location: NewLocation): boolean => location !== action.payload);
        return {
            ...state,
            pendingAddition: pending,
        };
    },
    [types.SELECT]: (state: State, action: ActionType<select>) => {
        if (action.payload == null) {
            return state;
        }

        return {
            ...state,
            selected: (action.payload >= state.locationIds.length)
                ? Math.max(state.locationIds.length - 1, 0)
                : Math.max(action.payload, 0),
        };
    },
    [types.SHOW_LOCATION_LIST_UPLOAD]: (state: State) => ({
        ...state,
        isLocationListUploadVisible: true,
    }),
    [types.HIDE_LOCATION_LIST_UPLOAD]: (state: State) => ({
        ...state,
        isLocationListUploadVisible: false,
    }),
    [types.SHOW_UNRESOLVED_LOCATIONS]: (state: State) => ({
        ...state,
        isUnresolvedLocationsVisible: true,
    }),
    [types.HIDE_UNRESOLVED_LOCATIONS]: (state: State) => ({
        ...state,
        isUnresolvedLocationsVisible: false,
    }),
    [types.TOGGLE_UPLOAD_NOTIFICATION]: (state: State, action: ActionType<toggleUploadNotification>) => ({
        ...state,
        displayUploadNotification: !!action.payload,
    }),
}, init);
