// @flow
import { schema } from 'normalizr';

export const fileUpload = new schema.Entity('fileUploads');
export const relation = new schema.Entity('relations', {}, { idAttribute: 'relation_id' });
export const certification = new schema.Entity('certifications');
export const dataType = new schema.Entity('dataTypes');
export const location = new schema.Entity('locations');
export const unresolvedLocation = new schema.Entity('unresolvedLocations');
export const group = new schema.Entity('groups');
export const organization = new schema.Entity('organizations');
export const template = new schema.Entity('templates');
export const customer = new schema.Entity('customers');
export const ticket = new schema.Entity('tickets', { applicants: [customer] });
export const application = new schema.Entity('applications', { ticket: [ticket] }, { idAttribute: 'cid' });
export const auditEvent = new schema.Entity('auditEvents', {}, { idAttribute: (value: Object): string => value._id.oid });
export const payout = new schema.Entity('payouts', {}, { idAttribute: 'ticket_id' });

// NOTE: This creates a cyclic reference between customer, application, and ticket
customer.define({ applications: [application] });

/**
 * 1. The `locations` attribute only exists when creating/updating a list. Fetching will return `location_count`
 * 2. The `file_uploads` attribute only exists when creating a list via the upload endpoint, as well as when
 *    fetching upload stats for the list
 */
export const locationList = new schema.Entity(
    'locationLists',
    {
        locations: [relation], // 1
        file_uploads: [fileUpload], // 2
    }
);

export const subscription = new schema.Entity(
    'subscriptions',
    {
        certifications: [certification],
        groups: [group],
        organization_location_lists: [locationList],
    },
);

export const notification = new schema.Entity(
    'notifications',
    {
        resource: {
            project: subscription,
            ticket,
        },
    },
    {
        processStrategy(value) {
            const { resource } = value;
            const normalized = {};

            if (resource.ticket != null) {
                const { customer: assignee, location: ticketLocation } = resource.ticket;
                normalized.ticket = {
                    ...resource.ticket,
                    customer: assignee && assignee.id === 0 ? null : assignee,
                    location: {
                        ...ticketLocation,
                        latitude: parseFloat(ticketLocation.latitude),
                        longitude: parseFloat(ticketLocation.longitude),
                    },
                };
            }

            return {
                ...value,
                resource: {
                    ...resource,
                    ...normalized,
                },
            };
        },
    }
);

export default {
    auditEvent,
    certification,
    customer,
    dataType,
    fileUpload,
    group,
    location,
    locationList,
    notification,
    organization,
    payout,
    relation,
    subscription,
    template,
    ticket,
    unresolvedLocation,
};
