// @flow
import React from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import { Grid, Icon, Tooltip, withWidth } from '@material-ui/core';
import { compose } from 'recompose';
import type { Node } from 'react';
import styles from './styles';

type Props = {
    children: Node,
    className: string,
    classes: Object,
    error?: Node,
    label: string,
    width: string,
}

export function SectionOveriew(props: Props) {
    const { children, className, classes, error, label, width } = props;
    const spacing = width === 'xs' ? 0 : 2;

    return (
        <Grid className={cx(classes.root, className)} container spacing={spacing} justify="center">
            <Grid className={classes.label} item xs={10} sm={2}>
                {label}
            </Grid>
            <Grid className={classes.content} item xs={10} sm={7}>
                {children}
            </Grid>
            <Grid className={classes.status} item xs={2} sm={2}>
                {error
                    ? (
                        <Tooltip placement={width === 'xs' ? 'bottom' : 'right'} title={error}>
                            <Icon className={classes.errorIcon}>error</Icon>
                        </Tooltip>
                    )
                    : null
                }
            </Grid>
        </Grid>
    );
}

const enhance = compose(
    withWidth(),
    withStyles(styles, { name: 'SectionOveriew' })
);
export default enhance(SectionOveriew);
