// @flow
import { createAction } from 'redux-actions';
import types from './types';

export const enqueue = createAction(
    types.ENQUEUE_SNACKBAR,
    (message: string, options: Object) => ({
        key: `${new Date().getTime() + Math.random()}`,
        message,
        options,
    })
);

export const remove = createAction(types.REMOVE_SNACKBAR);

export default {
    enqueue,
    remove,
};
