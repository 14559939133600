// @flow
import { createAction } from 'redux-actions';
import { normalize } from 'normalizr';
import type { Dispatch } from 'redux';
import type { $AxiosXHR, $AxiosError } from 'axios';
import type { APIResponse, APIPromise } from 'gigwalk/lib/api/resource';
import type { Location } from 'gigwalk/lib/api/locations/types';
import logger from '../../util/logger';
import { init } from './initialState';
import { location as locationSchema } from './schemas';
import { client as gigwalk } from '../../api/createGigwalkClient';
import type { State } from './initialState';

// Actions
export const CREATE = 'g/locations/CREATE';
export const CREATE_SUCCESS = `${CREATE}_SUCCESS`;
export const CREATE_ERROR = `${CREATE}_ERROR`;

// Action Creators
export const createSuccess = createAction(CREATE_SUCCESS);
export const createError = createAction(CREATE_ERROR);
export const create = createAction(
    CREATE,
    (params): Function => (
        (dispatch: Dispatch<*>): APIPromise<[Location]> => (
            gigwalk.locations.createForOrganization(params)
                .then((resp: $AxiosXHR<APIResponse<[Location]>>) => {
                    const normalized = normalize(resp.data.data, [locationSchema]);
                    dispatch(createSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(createError());
                    return Promise.reject(err);
                })
        )
    )
);

export default function(state: State = init): State {
    return state;
}
