// @flow
import { createSelector, createStructuredSelector } from 'reselect';
import { selectors as projectBuilder } from '../../../../../duck';

// type FormValues = {
//     certifications: Certification[],
// };

export const getInitialValues = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    projectBuilder.getInitialValues,
    createStructuredSelector({
        certifications: (values: Object) => values.certifications,
    })
);

export default {
    getInitialValues,
};
