// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import cx from 'classnames';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { FormControl, FormHelperText, FormLabel } from '@material-ui/core';
import DateTimePicker from '../DateTimePicker';
import styles from './styles';

type Props = {
    classes?: Object,
    className?: string,
    disabled?: boolean,
    field: Object,
    form: Object,
    label?: string,
    margin?: string,
    required?: boolean,
};

const useStyles = makeStyles(styles, { name: 'FormikDateTimePicker' });

export default function FormikDateTimePicker(props: Props) {
    const {
        classes: classesProp,
        className,
        disabled,
        field,
        form,
        label,
        required,
        ...other
    } = props;

    const classes = useStyles(props);
    const { i18n, t } = useTranslation();

    const handleChange = useCallback((value) => {
        form.setFieldValue(field.name, value);
    }, [field.name, form]);

    // @todo Fix focus management in DateTimePicker so that focus/blur event is passed
    const handleBlur = useCallback(() => {
        // event.target.name = field.name; // eslint-disable-line no-param-reassign
        // form.handleBlur(event);
    }, []);

    const touched = getIn(form.touched, field.name);
    const error = getIn(form.errors, field.name);

    let errorMessage = null;
    if (touched && error) {
        if (typeof error === 'string') {
            errorMessage = i18n.exists(error) ? t(error) : error;
        } else if ('message' in error) {
            const { message, ...options } = error;
            errorMessage = i18n.exists(message) ? t(message, { ...options }) : message;
        }
    }

    const {
        root: rootClassName,
        label: labelClassName,
        dateTimePicker: dateTimePickerClassName,
        errorMessage: errorClassName,
        ...otherClasses
    } = classes;

    return (
        <FormControl
          className={cx(rootClassName, className)}
          disabled={disabled}
          error={!!errorMessage}
          required={required}
        >
            {label ? <FormLabel className={labelClassName}>{label}</FormLabel> : null}
            <DateTimePicker
              classes={{ root: dateTimePickerClassName, ...otherClasses }}
              {...other}
              {...field}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errorMessage
                ? <FormHelperText className={errorClassName}>{errorMessage}</FormHelperText>
                : null
            }
        </FormControl>
    );
}
