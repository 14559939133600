// @flow
import React from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import type { ChildrenArray } from 'react';

type Props = {
    children: ChildrenArray<any>,
    className?: string,
    classes: Object,
}

const styles = () => ({
    root: {
        fontWeight: 600,
    },
});

export const CallToActionText = (props: Props): React$Element<any> => {
    const { children, className, classes, ...other } = props;
    return (
        <div className={cx(classes.root, className)} {...other}>
            {children}
        </div>
    );
};

export default withStyles(styles, { name: 'CallToActionText' })(CallToActionText);
