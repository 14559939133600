// @flow
export default (theme: Object) => ({
    rating: {
        display: 'inline-block',
    },
    star: {
        float: 'right',
        padding: [[0, 2]],
        transition: [
            theme.transitions.create('fill', { duration: theme.transitions.duration.shorter }),
            theme.transitions.create('stroke', { duration: theme.transitions.duration.shorter }),
        ],

        '& > path': {
            strokeLinejoin: 'round',

            '&:first-of-type': {
                fill: '#f0f3f6',
                stroke: '#bdbdbd',
                strokeWidth: 1.5,
            },
            '&:last-of-type': {
                fill: '#fff9B2',
                stroke: '#fcdd00',
                strokeWidth: 2,
            },
        },

        '&:last-child': {
            marginLeft: -4,
        },

        '&:first-child': {
            marginRight: -4,
        },
    },
});
