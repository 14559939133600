// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { MenuItem, Select } from '@material-ui/core';
import {
    Gesture as GestureIcon,
    HelpOutline as HelpOutlineIcon,
    Photo as PhotoIcon,
    RadioButtonUnchecked as RadioButtonUncheckedIcon,
    Schedule as ScheduleIcon,
    ShoppingCart as ShoppingCartIcon,
    Subject as SubjectIcon,
} from '@material-ui/icons';
import { Numeric as NumericIcon } from 'mdi-material-ui';
import { VALUE_TYPE } from '../../../../../../../browser/shared/constant/ProjectConstant';

type Props = {
    classes: Object,
    onBlur?: () => void,
    onChange?: (value: string) => void,
    value?: string,
};

type Item = {
    label: string,
    value: string,
    icon: string,
};

const styles = (theme: Object) => ({
    menuItem: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        flexGrow: 0,
        marginRight: theme.spacing(1),
        display: 'flex',
        fontSize: 18,
    },
    label: {
        flexGrow: 2,
        display: 'flex',
        fontSize: 14,
    },
});

const valueTypes: Array<Item> = [
    { label: 'Multiple Choice', value: VALUE_TYPE.MULTIPLE_CHOICE, icon: RadioButtonUncheckedIcon },
    { label: 'Numeric Entry', value: VALUE_TYPE.NUMBER, icon: NumericIcon },
    { label: 'Photo', value: VALUE_TYPE.PHOTO, icon: PhotoIcon },
    { label: 'Free Text', value: VALUE_TYPE.FREE_TEXT, icon: SubjectIcon },
    { label: 'Date & Time', value: VALUE_TYPE.DATE_TIME, icon: ScheduleIcon },
    { label: 'Hint', value: VALUE_TYPE.HINT, icon: HelpOutlineIcon },
    { label: 'Barcode', value: VALUE_TYPE.BARCODE, icon: ShoppingCartIcon },
    { label: 'Signature', value: VALUE_TYPE.SIGNATURE, icon: GestureIcon },
];

// We group some question types by their input type. For instance, CURRENCY and PHONE_NUMBER
// are both numeric types
const normalizeValueType = (valueType: string): string => {
    switch (valueType) {
        case VALUE_TYPE.MULTIPLE_CHOICE:
        case VALUE_TYPE.MULTI_SELECT:
        case VALUE_TYPE.CHECKBOXES: {
            return VALUE_TYPE.MULTIPLE_CHOICE;
        }

        case VALUE_TYPE.NUMBER:
        case VALUE_TYPE.PHONE_NUMBER:
        case VALUE_TYPE.CURRENCY:
        case VALUE_TYPE.TIME: {
            return VALUE_TYPE.NUMBER;
        }

        // @todo: Is DATE different than DATE_TIME?
        // case VALUE_TYPE.DATE: {
        //     return VALUE_TYPE.DATE_TIME;
        // }

        default:
            return valueType;
    }
};

export class TypeDropdown extends Component<Props> {
    handleChange = (event: SyntheticInputEvent<*>) => {
        const newValue = event.target.value;
        const { onChange, value } = this.props;

        if (typeof onChange === 'function' && (!value || normalizeValueType(value) !== newValue)) {
            onChange(newValue);
        }
    };

    handleBlur = () => {
        const { onBlur } = this.props;
        if (typeof onBlur === 'function') {
            // Don't pass event object. There seems to be a bug in the Dropdown component
            // where selecting a menu item will cause onBlur to fire with an event.targe.value of 0.
            // This causes redux-form to assume the new value is 0
            onBlur();
        }
    };

    renderValue = (value: string) => {
        const { classes } = this.props;
        const valueType = valueTypes.find((item: Item) => item.value === value);

        if (!valueType) {
            return null;
        }

        const { icon: Icon, label } = valueType;
        return (
            <div className={classes.menuItem}>
                <Icon className={classes.icon} />
                <span className={classes.label}>{label}</span>
            </div>
        );
    };

    render() {
        const { classes, value } = this.props;
        const normalizedValue = value ? normalizeValueType(value) : undefined;

        return (
            <Select
              {...this.props}
              value={normalizedValue}
              renderValue={this.renderValue}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              MenuProps={{
                  className: 'ignore-react-onclickoutside',
              }}
            >
                {valueTypes.map((item: Item) => {
                    const { icon: Icon, label } = item;
                    return (
                        <MenuItem className={classes.menuItem} key={item.value} value={item.value}>
                            <Icon className={classes.icon} />
                            <span className={classes.label}>{label}</span>
                        </MenuItem>
                    );
                })}
            </Select>
        );
    }
}

export default withStyles(styles, { name: 'TypeDropdown' })(TypeDropdown);
