// @flow weak
import Widget from './Widget';
import template from '../../../templates/ticket/widgets/Hint.hbs';

/**
 * @class (View) Hint
 * @constructor
 */
export default Widget.extend({

    /**
     * @type string
     * @property className
     */
    className: '',

    /**
     * @property events
     */
    events: {
        'click [data-action="magnify"]': 'magnify',
        'click [data-action="download"]': 'download',
    },

    render() {
        const { index, ticket } = this.props;
        const dataTypeID = this.instruction.get('data_type_id');
        const dataType = ticket.data_type_map[dataTypeID];
        const title = dataType.description;
        const body: string = dataType.questions ? dataType.questions.question_text : '';
        const attachments = this.groupAttachments(dataType.attachments);

        this.$el.html(template($.extend(_.clone(this.text), {
            questionNumber: index != null ? index + 1 : null,
            title: title !== body ? title : '',
            body,
            images: attachments.images,
            files: attachments.files,
        })));
    },

    getValue(): {} {
        return {};
    },

    findDeviceLocation() {},

    validateInput() {},

    save() {},

    readOnly() {},

    /**
     * @todo fix this in a better way related to http://gigwalk.myjetbrains.com/youtrack/issue/PGCMK-888
     */
    download(e: any) {
        const link = e.target.href;
        if (link) {
            window.open(link);
        }
    },
});
