// @flow
import { normalize } from 'normalizr';
import { createAction } from 'redux-actions';
import omitBy from 'lodash.omitby';
import type { Dispatch } from 'redux';
import type { $AxiosXHR, $AxiosError } from 'axios';
import type { APIResponse, APIPromise } from 'gigwalk/lib/api/resource';
import logger from '../../util/logger';
import { client as gigwalk } from '../../api/createGigwalkClient';
import { init } from './initialState';
import { payout as payoutSchema } from './schemas';
import type { State } from './initialState';

// Actions
// -------
export const SEARCH = 'g/payouts/SEARCH';
export const SEARCH_SUCCESS = `${SEARCH}_SUCCESS`;
export const SEARCH_ERROR = `${SEARCH}_ERROR`;

// Action Creators
// ---------------
export const searchSuccess = createAction(SEARCH_SUCCESS);
export const searchError = createAction(SEARCH_ERROR);
export const search = createAction(
    SEARCH,
    (params: Object): Function => (
        (dispatch: Dispatch<any>): APIPromise<Object[]> => {
            const { limit, offset, sort_field: sortField, sort_order: sortOrder, ...data } = params;
            const config = {
                params: omitBy({ limit, offset, sort_field: sortField, sort_order: sortOrder }, (value) => value == null),
            };
            // @todo Have BE change this from v1/payouts/ -> v1/payouts
            return gigwalk.client.post('v1/payouts/', data, config)
                .then((resp: $AxiosXHR<APIResponse<Object[]>>) => {
                    const normalized = normalize(resp.data.data, [payoutSchema]);
                    normalized.metadata = resp.data._metadata;
                    dispatch(searchSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(searchError(err));
                    return Promise.reject(err);
                });
        }
    )
);

export default function(state: State = init): State {
    return state;
}
