// @flow

/**
 * FileUtil is a static utility that helps with operations involving File objects
 */

// Map of file extensions to known/supported mime types. This list will grow as needed
const mimeTypeMap = {
    csv: [
        'text/csv',
        'text/x-csv',
        'text/x-comma-separated-values',
        'text/comma-separated-values',
        'application/csv',
        'application/x-csv',
        'application/vnd.ms-excel',
    ],
    xls: ['application/vnd.ms-excel'],
    xlsx: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
};

// A reverse lookup map for the above structure. Maps mime types to possible file extensions.
const indexedMimeTypes = {};
Object.entries(mimeTypeMap).forEach(([extension, typeList]: [string, mixed]) => {
    if (Array.isArray(typeList)) {
        typeList.forEach((mimeType: mixed) => {
            if (typeof mimeType === 'string') {
                if (indexedMimeTypes[mimeType] === undefined) {
                    indexedMimeTypes[mimeType] = [];
                }

                indexedMimeTypes[mimeType].push(extension);
            }
        });
    }
});

/**
 * Returns true if file is a File or Blob object
 * @param file
 * @returns {boolean}
 */
export function isFile(file: any) {
    return file instanceof File || file instanceof Blob;
}

/**
 * Returns the mime type of the given file. If the file type matches any of the supported
 * mime types, this function will returned officially registered type.
 * See http://fileformats.archiveteam.org/wiki/CSV for an example
 * @param file
 * @returns {string}
 */
export function getMimeType(file: Object): string {
    let extension = '';
    let mimeType = '';
    let extList;

    if (!isFile(file)) {
        throw new TypeError('file is not a File or Blob object');
    }

    const parts = file.name.split('.');
    if (parts.length > 2 || (parts.length === 2 && parts[0].length)) {
        extension = parts.pop().toLowerCase();
    }

    if (file.type.length > 0) {
        // Browser was able to determine file type! Let's see if we can normalize by looking up
        // mime type by file extension
        extList = indexedMimeTypes[file.type];
        if (extList && extList.indexOf(extension) !== -1) {
            switch (extension) {
                case 'csv':
                case 'xls':
                case 'xlsx':
                    mimeType = mimeTypeMap[extension][0]; // eslint-disable-line prefer-destructuring
                    break;

                default:
                    mimeType = file.type;
            }
        } else {
            mimeType = file.type;
        }
    } else if (extension.length) {
        // Browser was NOT able to determine file type. Lookup mime type by file extension
        switch (extension) {
            case 'csv':
            case 'xls':
            case 'xlsx':
                mimeType = mimeTypeMap[extension][0]; // eslint-disable-line prefer-destructuring
                break;

            default:
                mimeType = '';
        }
    }

    return mimeType;
}

/**
 * Creates a copy of the given file. Note that since IE doesn't support creating new File objects using
 * the File constructor, we have to create a Blob instead.
 * @param file
 * @param type
 * @returns {Blob}
 */
export function createBlobFromFile(file: Object, type?: string): Blob {
    if (!isFile(file)) {
        throw new TypeError('file is not a File or Blob object');
    }

    const blob = new Blob([file], {
        type: type || getMimeType(file) || file.type,
    });

    // Note: I'm suppressing these flow errors because both `name` and `lastModifiedDate` are
    // not known properties of Blob objects. I tried creating a `FileBlob` class which extends `Blob`,
    // but with with the way babel transpiles classes, contructing a new `FileBlob` was throwing
    // errors (at least in Chrome).

    // $FlowFixMe
    blob.name = file.name;

    // $FlowFixMe
    blob.lastModifiedDate = new Date(file.lastModifiedDate);

    return blob;
}

/**
 * @method dataUriToBlob
 * @param dataURI
 */
export function dataUriToBlob(dataURI: string, mimeType: string): Blob {
    // serialize the base64/URLEncoded data
    const binary = atob(dataURI.split(',')[1]);
    const array = [];

    for (let i = 0; i < binary.length; i += 1) {
        array.push(binary.charCodeAt(i));
    }

    return new Blob([new Uint8Array(array)], {
        type: mimeType,
    });
}

/**
 * Returns the extension of the given File object
 * @param file
 * @returns {*}
 */
export function getExtension(file: Object): string {
    let extension = '';

    if (!isFile(file)) {
        throw new TypeError('file is not a File or Blob object');
    }

    const parts = file.name.split('.');
    if (parts.length > 2 || (parts.length === 2 && parts[0].length)) {
        extension = parts.pop().toLowerCase();
    }

    return extension;
}

/**
 * This method will return a list of all the known/supported mime types
 * for the given file extensions
 * @param extensions
 * @returns {Array}
 */
export function getMimeTypes(extensions?: Array<string>) {
    let result = [];

    if (Array.isArray(extensions)) {
        extensions.forEach((extension: string) => {
            if (mimeTypeMap[extension]) {
                result = result.concat(mimeTypeMap[extension]);
            }
        });
    } else {
        Object.values(mimeTypeMap).forEach((mimeTypes: mixed) => {
            result = result.concat(mimeTypes);
        });
    }

    return result;
}

export default {
    isFile,
    getMimeType,
    createBlobFromFile,
    dataUriToBlob,
    getExtension,
    getMimeTypes,
};
