// @flow
import { createSelector } from 'reselect';
import { getAvailableActions, getPermittedActions, getTicket } from '../../../../../duck/selectors';

export const getInitialValues = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    getTicket,
    (ticket: ?Object): Object => ({
        timeEstimate: ticket ? ticket.time_estimate || 0 : undefined,
    })
);


export const getReadOnly = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    getPermittedActions,
    (actions: string[]): string[] => {
        const readOnly = [];
        if (!actions.includes('editTimeEstimate')) readOnly.push('timeEstimate');
        return readOnly;
    }
);

export const getDisabled = createSelector(
    getAvailableActions,
    (actions: string[]): string[] => {
        const disabled = [];
        if (!actions.includes('editTimeEstimate')) disabled.push('timeEstimate');
        return disabled;
    }
);

export default {
    getDisabled,
    getInitialValues,
    getReadOnly,
};
