// @flow
export default () => ({
    dialogPaper: {
        background: 'linear-gradient(#3168aa 70px, white 70px)',
        height: 600,
        maxWidth: 450,
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        paddingBottom: 0,
    },
    avatar: {
        marginTop: 4,
        border: '4px white solid',
        width: 80,
        height: 80,
        display: 'flex',
        alignItems: 'center',
    },
    avatarIcon: {
        width: '60%',
        height: 'auto',
    },
    cancel: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: 'white',
    },
});
