// @flow
import { createSelector } from 'reselect';
import qs from 'qs';
import moment from 'moment';
import type { ContextRouter } from 'react-router';
import entitySelector from '../../../redux/entities/entitySelector';
import type { State as RootState } from '../../../redux/initialState';

type TableData = Array<{
    amount: number,
    balance: number,
    customerId: number,
    date: string,
    id: string,
    organizationId: number,
    referenceId: ?string,
    subscriptionId: ?number,
    type: string,
}>;

type SearchParams = {
    customer?: string[],
    order?: string,
    organization?: string,
    page?: string,
    q?: string,
    size?: string,
    sort?: string,
    type?: string[],
}

type APIParams = {
    audit_types: string[],
    charge_token: ?string,
    customer_ids: ?number[],
    organization_id: ?number,
    limit: number,
    offset: number,
    sort_field: string,
    sort_order: string,
}

const auditEventSelector = entitySelector('auditEvents');

type AuditEvent = {
    _id: {
        oid: string,
        __type__: 'ObjectId',
    },
    source_customer_id: number,
    timestamp: {
        hour: number,
        month: number,
        __type__: 'datetime',
        day: number,
        second: number,
        minute: number,
        year: number,
        microsecond: number,
    },
    organization_id: number,
    audit_type: string,
    status: string,
    context: Object,
};

// Maps the key we use in the url query to the key expected by the API
const apiParams = {
    date: 'timestamp',
};

export const getTableData = createSelector(
    (state: RootState) => auditEventSelector(state, state.transactionList.data),
    (auditEvents: AuditEvent[]): TableData => (
        auditEvents
            .filter((auditEvent: ?AuditEvent): boolean => !!auditEvent)
            .map((auditEvent: AuditEvent) => {
                const {
                    _id,
                    audit_type: type,
                    source_customer_id: customerId,
                    timestamp,
                    organization_id: organizationId,
                    context,
                } = auditEvent;

                const {
                    charge_token: chargeToken,
                    current_balance: currentBalance,
                    dollar_value: dollarValue,
                    project_id: projectId,
                    reference_id: referenceId,
                } = context;

                let amount = 0;
                if (dollarValue) {
                    amount = type === 'PAYMENTS_DEDUCT' ? -Math.abs(dollarValue) : dollarValue;
                }

                return {
                    amount,
                    balance: currentBalance,
                    customerId,
                    // Since native Date objects have 0-indexed months, we must adjust the datetime
                    // object return by the API to get the correct results
                    date: moment({ ...timestamp, month: timestamp.month - 1 }).format(),
                    id: _id.oid,
                    organizationId,
                    referenceId: referenceId || chargeToken,
                    subscriptionId: projectId,
                    type,
                };
            })
    )
);

export const parseSearchParams = createSelector(
    <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): string => {
        const { location } = props;
        return location.search;
    },
    (search: string): SearchParams => qs.parse(search, { ignoreQueryPrefix: true })
);

export const getAPIParams = createSelector(
    parseSearchParams,
    (search: SearchParams): APIParams => {
        const { order, page, q, size, sort, ...filters } = search;
        const offset = Math.max(parseInt(page, 10) - 1, 0) || 0;
        const limit = Math.max(parseInt(size, 10), 0) || 10;
        const customerIds = [];
        const auditTypes = [];
        let organizationId;

        if (filters.organization) {
            organizationId = parseInt(filters.organization, 10);
        }

        if (filters.customer) {
            customerIds.push(...filters.customer.map((id: string): number => parseInt(id, 10)));
        }

        if (filters.type) {
            auditTypes.push(...filters.type);
        } else {
            auditTypes.push('PAYMENTS_ADD', 'PAYMENTS_ADD_STRIPE', 'PAYMENTS_DEDUCT', 'PAYMENTS_REFUND');
        }

        return {
            audit_types: auditTypes,
            charge_token: q,
            customer_ids: customerIds.length > 0 ? customerIds : null,
            organization_id: organizationId,
            limit,
            offset,
            sort_field: sort ? apiParams[sort] : 'timestamp',
            sort_order: order || 'desc',
        };
    }
);

export default {
    getAPIParams,
    getTableData,
    parseSearchParams,
};
