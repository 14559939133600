// @flow
import React, { Component } from 'react';
import qs from 'qs';
import cx from 'classnames';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import {
    Input,
    InputAdornment,
    Toolbar,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter } from 'react-router';
import { selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {
    className?: string,
    classes: Object,
    t: TFunction,
};

type StateProps = {
    search: {
        size?: string,
        page?: string,
        q?: string,
        sort?: string,
        order?: string,
    },
};
type DispatchProps = {};
type Props = OwnProps & StateProps & DispatchProps;

export class SearchToolbar extends Component<Props> {
    handleQueryChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const { location, history, search } = this.props;
        const { q, ...query } = search;

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                value ? { ...query, q: value } : query,
                { addQueryPrefix: true, encodeValuesOnly: true }
            ),
        });
    };

    render() {
        const { className, classes, search, t } = this.props;
        return (
            <Toolbar className={cx(classes.root, className)} disableGutters>
                <Input
                  className={cx(classes.input, classes.outlined)}
                  disableUnderline
                  fullWidth
                  onChange={this.handleQueryChange}
                  placeholder={t('transactionList.search.placeholder')}
                  value={search.q || ''}
                  startAdornment={(
                      <InputAdornment position="start">
                          <SearchIcon />
                      </InputAdornment>
                  )}
                />
            </Toolbar>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    ...selectors.getAPIParams(state, props),
    search: selectors.parseSearchParams(state, props),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps);
const enhance = compose(
    withStyles(styles, { name: 'SearchToolbar' }),
    withTranslation(),
    connector
);

export default enhance(SearchToolbar);
