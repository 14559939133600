// @flow
import moment from 'moment';
import date from './date';
import required from './required';

/**
 * Given a number N and date D, returns a function that will validate whether or not
 * value is at least N hours after D. If no date is given, the current time will
 * be used. Additionally, you can specify a field name instead of a date for use with
 * redux-form
 * @param n {number}
 * @param fieldOrDate {string | moment | Date}
 * @returns {Function}
 */
export default (n: number, fieldOrDate?: string | moment$Moment | Date): Function => (
    (value: any, allValues: Object): ?string | ?Object => {
        const requiredError = required(value);
        if (requiredError) return;

        const dateError = date(value);
        if (dateError) return dateError;

        let otherDate;
        if (typeof fieldOrDate === 'undefined') {
            otherDate = moment();
        } else if (typeof fieldOrDate === 'string' && fieldOrDate in allValues) {
            otherDate = moment(allValues[fieldOrDate]);
        } else {
            otherDate = moment(fieldOrDate);
        }

        if (!moment(value).add(n, 'hours').isSameOrBefore(otherDate)) {
            return { message: 'lessThanNHoursBeforeDate', n };
        }
    }
);
