// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import stringify from 'json-stable-stringify';
// $FlowIssue update to more recent flow version and un-ignore react-select module
import Select from 'react-select/lib/index';
import { withStyles } from '@material-ui/styles';
import {
    Chip,
    CircularProgress,
    FilledInput,
    Input as StandardInput,
    MenuItem,
    OutlinedInput,
    Paper,
    RootRef,
} from '@material-ui/core';
import {
    Clear as ClearIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@material-ui/icons';
import type { Node } from 'react';
import styles from './styles';

type Props = {
    cacheOptions?: any,
    classes: Object,
    className?: string,
    components?: Object,
    defaultOptions?: Object[] | boolean,
    disableSelectedOptions?: boolean,
    dropdownIcon?: Node,
    formatOptionLabel?: (option: Object, meta: Object) => Node,
    isLoading?: boolean,
    loadOptions?: (inputValue: string) => Promise<Object[]>,
    loadingIcon?: Node,
    menuType?: 'list' | 'menu',
    onBlur: () => void,
    options?: Object[],
    placeholder?: string,
    showSelectedOptions?: boolean | 'top',
    value?: ?(Object | Object[]),
};

const LoadingIndicator = (props: Object) => {
    const { innerProps, selectProps } = props;
    const { classes, loadingIcon } = selectProps;

    return (
        <div className={classes.loadingIndicator} {...innerProps}>
            {loadingIcon || <CircularProgress size={18} thickness={4.4} />}
        </div>
    );
};

const DropdownIndicator = (props: Object) => {
    const { innerProps, selectProps } = props;
    const { classes, dropdownIcon, isLoading } = selectProps;

    if (isLoading) {
        return null;
    }

    return (
        <div className={classes.dropdownIndicator} {...innerProps}>
            {dropdownIcon || <KeyboardArrowDownIcon />}
        </div>
    );
};

const ClearIndicator = (props: Object) => {
    const { innerProps, selectProps } = props;
    const { classes, isLoading } = selectProps;

    if (isLoading) {
        return null;
    }

    return (
        <div className={classes.clearIndicator} {...innerProps}>
            <ClearIcon fontSize="inherit" />
        </div>
    );
};

const IndicatorSeparator = (props: Object) => {
    const { innerProps, selectProps } = props;
    const { classes, isClearable, isLoading } = selectProps;

    if (isLoading || !isClearable) {
        return null;
    }

    return <span className={classes.indicatorSeparator} {...innerProps} />;
};

const Input = (props: Object) => {
    const { cx: classnames, innerRef, isDisabled, isHidden, getStyles, selectProps, ...other } = props;
    const { classes } = selectProps;
    return (
        <input
          className={classes.inputElement}
          disabled={isDisabled}
          ref={innerRef}
          style={{ opacity: isHidden ? 0 : 1 }}
          {...other}
        />
    );
};

// const NoOptionsMessage = (props: Object) => {
//     const { children, innerProps, selectProps } = props;
//     const { classes } = selectProps;
//     return (
//         <Typography color="textSecondary" className={classes.noOptionsMessage} {...innerProps}>
//             {children}
//         </Typography>
//     );
// };

const variantComponent = {
    standard: StandardInput,
    filled: FilledInput,
    outlined: OutlinedInput,
};

const inputComponent = (props: Object) => {
    const { inputRef, ...other } = props;
    return <div ref={inputRef} {...other} />;
};

const Control = (props: Object) => {
    const { children, innerProps, innerRef, selectProps } = props;
    const { classes, ControlProps } = selectProps;
    const { inputProps, variant, ...otherControlProps } = ControlProps || {};
    const className = cx(classes.control, {
        [classes.outlined]: variant === 'outlined',
        [classes.filled]: variant === 'filled',
    });

    const InputComponent = variantComponent[variant] || StandardInput;

    return (
        <InputComponent
          className={className}
          inputComponent={inputComponent}
          inputProps={{
              className: classes.input,
              inputRef: innerRef,
              children,
              ...inputProps,
              ...innerProps,
          }}
          {...otherControlProps}
        />
    );
};

const Option = (props: Object) => {
    const { children, clearValue, innerProps, innerRef, isFocused, isMulti, isSelected, selectProps } = props;
    const { classes, disableSelectedOptions } = selectProps;
    const { onClick } = innerProps;

    innerProps.onClick = () => {
        if (disableSelectedOptions && isSelected) {
            return;
        }
        onClick();
        if (!isMulti && isSelected) {
            clearValue();
        }
    };

    return (
        <MenuItem
          className={classes.menuItem}
          buttonRef={innerRef}
          selected={isFocused}
          component="div"
          style={{ fontWeight: isSelected ? 500 : 400 }}
          {...innerProps}
        >
            {children}
        </MenuItem>
    );
};

const Placeholder = (props: Object) => {
    const { children, innerProps, selectProps } = props;
    const { classes } = selectProps;
    return (
        <div className={classes.placeholder} {...innerProps}>
            {children}
        </div>
    );
};

const SingleValue = (props: Object) => {
    const { children, innerProps, selectProps } = props;
    const { classes } = selectProps;
    return (
        <div className={classes.singleValue} {...innerProps}>
            {children}
        </div>
    );
};

const ValueContainer = (props: Object) => {
    const { children, selectProps } = props;
    const { classes } = selectProps;
    return <div className={classes.valueContainer}>{children}</div>;
};

const MultiValue = (props: Object) => {
    const { children, isFocused, removeProps, selectProps } = props;
    const { classes } = selectProps;
    const { onClick, onMouseDown } = removeProps;

    return (
        <Chip
          tabIndex={-1}
          label={children}
          className={cx(classes.chip, { [classes.chipFocused]: isFocused })}
          onDelete={(event: SyntheticEvent<any>) => {
              onClick();
              onMouseDown(event);
          }}
        />
    );
};

const Menu = (props: Object) => {
    const { children, innerProps, innerRef, placement, selectProps } = props;
    const { classes, menuType } = selectProps;

    const className = cx(classes.paper, {
        [classes.menu]: menuType === 'menu',
        [classes.list]: menuType === 'list',
    });

    const style = placement === 'top'
        ? { bottom: 40 }
        : null;

    return (
        <RootRef rootRef={innerRef}>
            <Paper
              className={className}
              elevation={menuType === 'list' ? 0 : 8}
              square
              style={style}
              {...innerProps}
            >
                {children}
            </Paper>
        </RootRef>
    );
};

// const MenuList = (props: Object) => {
//     const { children, innerProps, innerRef } = props;
//     return (
//         <div {...innerProps} ref={innerRef}>
//             <MenuList>
//                 {children}
//             </MenuList>
//         </div>
//     );
// };

const getOptionValue = (option: Object) => {
    let { value } = option;
    if (typeof value === 'object') {
        value = value.hasOwnProperty('id') ? value.id : value;
    }
    return stringify(value);
};

const mergeSelectedOptions = (options: Object[], selectValue: ?(Object | Object[])): Object[] => {
    if (!selectValue) {
        return options;
    }

    const value = Array.isArray(selectValue) ? selectValue : [selectValue];
    return [
        ...value.filter((option) => {
            const candidate = getOptionValue(option);
            return !options.some((v) => getOptionValue(v) === candidate);
        }),
        ...options,
    ];
};

const reorderSelectedOptions = (options: Object[], selectValue: ?(Object | Object[])) => {
    if (!selectValue) {
        return options;
    }

    const top = [];
    const bottom = [];
    const value = Array.isArray(selectValue) ? selectValue : [selectValue];
    options.forEach((option) => {
        const candidate = getOptionValue(option);
        const isOptionSelected = value.some((v) => getOptionValue(v) === candidate);
        if (isOptionSelected) {
            top.push(option);
        } else {
            bottom.push(option);
        }
    });

    return [...top, ...bottom];
};

function SelectController(props: Props) {
    const {
        cacheOptions,
        classes,
        className,
        components,
        defaultOptions: defaultOptionsProp,
        disableSelectedOptions,
        formatOptionLabel: formatOptionLabelProp,
        isLoading,
        loadOptions,
        options: optionsProp,
        placeholder,
        onBlur,
        showSelectedOptions,
        value,
        ...other
    } = props;

    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadedOptions, setLoadedOptions] = useState([]);
    const [defaultOptions, setDefaultOptions] = useState(Array.isArray(defaultOptionsProp) ? defaultOptionsProp : []);

    const selectRef = useRef(null);

    const isAsync = typeof loadOptions === 'function';
    const showDefaultOptions = inputValue === '';

    useEffect(() => {
        if (defaultOptionsProp === true && loadOptions) {
            setLoading(true);
            loadOptions('')
                .catch(() => {})
                .then((results?: Object[] = []) => {
                    setDefaultOptions(results);
                    setLoading(false);
                });
        } else {
            setDefaultOptions(Array.isArray(defaultOptionsProp) ? defaultOptionsProp : []);
        }
    }, [defaultOptionsProp, loadOptions]);

    const options = useMemo(() => {
        let selectOptions = optionsProp || [];

        if (isAsync) {
            selectOptions = showDefaultOptions ? defaultOptions : loadedOptions;
        }

        switch (showSelectedOptions) {
            case 'top':
                selectOptions = mergeSelectedOptions(selectOptions, value);
                return reorderSelectedOptions(selectOptions, value);
            case true:
                return mergeSelectedOptions(selectOptions, value);
            default:
                return selectOptions;
        }
    }, [defaultOptions, isAsync, loadedOptions, optionsProp, showDefaultOptions, showSelectedOptions, value]);

    const formatOptionLabel = useCallback((option: Object, meta: Object) => {
        if (formatOptionLabelProp) {
            // This is a little hacky, but it's currently the only way to expose isSelected to formatOptionLabel
            const node = selectRef.current;
            const select = node ? node.select : null;

            // @todo Submit a feature request to react-select to expose option props to formatOptionLabel
            const isSelected = select ? select.isOptionSelected(option, meta.selectValue) : false;
            return formatOptionLabelProp(option, { ...meta, isSelected });
        }
    }, [formatOptionLabelProp]);

    const handleBlur = useCallback(() => onBlur(), [onBlur]);

    const handleInputChange = useCallback((newValue: string) => {
        setInputValue(newValue);

        if (loadOptions && newValue !== '') {
            setLoading(true);
            loadOptions(newValue)
                .catch(() => {})
                .then((results?: Object[] = []) => {
                    setLoadedOptions(results);
                    setLoading(false);
                });
        }
    }, [loadOptions]);

    const handleKeyDown = useCallback((event: SyntheticKeyboardEvent<any>) => {
        const node = selectRef.current;
        const select = node ? node.select : null;

        if (select) {
            const { focusedOption, selectValue } = select.state;
            if (disableSelectedOptions && select.isOptionSelected(focusedOption, selectValue)) {
                switch (event.key) {
                    case 'Tab':
                    case 'Enter':
                    case ' ':
                        event.preventDefault();
                        break;

                    default:
                        break;
                }
            }
        }
    }, [disableSelectedOptions]);

    return (
        <Select
          {...other}
          {...(isAsync ? { filterOption: null } : undefined)}
          classes={classes}
          className={cx(classes.root, className)}
          components={{
              ClearIndicator,
              Control,
              DropdownIndicator,
              IndicatorSeparator,
              Input,
              LoadingIndicator,
              Menu,
              MultiValue,
              // NoOptionsMessage,
              Option,
              Placeholder,
              SingleValue,
              ValueContainer,
              ...components,
          }}
          formatOptionLabel={formatOptionLabelProp ? formatOptionLabel : undefined}
          getOptionValue={getOptionValue}
          hideSelectedOptions={!showSelectedOptions}
          inputValue={inputValue}
          isLoading={isLoading != null ? isLoading : loading}
          onBlur={handleBlur}
          onInputChange={handleInputChange}
          onKeyDown={handleKeyDown}
          options={options}
          placeholder={placeholder || ''}
          ref={selectRef}
          value={value}
        />
    );
}

SelectController.defaultProps = {
    menuType: 'menu',
    onBlur: () => {},
    showSelectedOptions: true,
};

export default withStyles(styles, { name: 'SelectController' })(SelectController);
