/** @flow */
import sanitize from 'sanitize-html';
import striptags from 'striptags';
import safe from 'safe-regex';

export function stripTags(htmlToSanitize: string, allowedTags: ?Object | ?string): string {
    if (allowedTags) {
        return striptags(htmlToSanitize, allowedTags);
    }
    return striptags(htmlToSanitize);
}

export function allowTags(htmlToSanitize: string): string {
    return sanitize(htmlToSanitize, {
        allowedTags: [...sanitize.defaults.allowedTags, 'h2'],
    });
}

export function customTagsReplace(html: string = '', tags: { [tag: string]: string } = {}): string {
    const sanitized: string = `${html}`;
    return Object.entries(tags)
        .reduce((result: string, [tag, value]: [string, any]): string => {
            const reg: RegExp = new RegExp(`<${tag}(.*?)>(.*?)</${tag}>`, 'g');
            return safe(reg) ? result.replace(reg, value) : result;
        }, sanitized);
}

export default {
    stripTags,
    allowTags,
    customTagsReplace,
};
