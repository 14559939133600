// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { Drawer, Hidden } from '@material-ui/core';
import { compose } from 'recompose';
import cx from 'classnames';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter, LocationShape } from 'react-router';
import NavItem from './components/NavItem';
import actions from './duck/actions';
import selectors from './duck/selectors';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type NavItemProps = {
    href?: string,
    icon: string,
    id: string,
    isActive?: Function,
    to?: string | LocationShape,
};

type OwnProps = {
    ...ContextRouter,
    classes: Object,
    className?: string,
    t: TFunction,
};

type StateProps = {
    expanded: boolean,
    inTransition: boolean,
    logoAlt: string,
    logoUri: string,
    navItems: NavItemProps[],
    open: boolean,
};

type DispatchProps = {
    collapse: () => void,
    expand: () => void,
    toggle: () => void,
};

type Props = OwnProps & StateProps & DispatchProps;

export class SideNav extends Component<Props> {
    handleExpanderClick = () => {
        const { collapse, expand, expanded } = this.props;
        (expanded ? collapse : expand)();
    };

    renderLogo() {
        const { classes, logoAlt, logoUri } = this.props;
        return (
            <NavLink to="/" className={classes.logo}>
                <img className="" src={logoUri} alt={logoAlt} />
            </NavLink>
        );
    }

    renderNavItems() {
        const { classes, expanded, inTransition, navItems, open, t } = this.props;
        const mini = !(open || expanded || inTransition);
        return (
            <nav className={classes.navigation}>
                {navItems.map((item: NavItemProps) => (
                    <NavItem {...item} label={t(`layout.sideNav.${item.id}`)} mini={mini} key={item.id} />
                ))}
            </nav>
        );
    }

    render() {
        const { classes, expanded, open, toggle } = this.props;
        return (
            <div>
                <Hidden mdUp>
                    <Drawer
                      classes={{ paper: cx(classes.drawerPaper) }}
                      variant="temporary"
                      open={open}
                      onClose={toggle}
                      ModalProps={{ keepMounted: true }}
                    >
                        {this.renderLogo()}
                        {this.renderNavItems()}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                      classes={{ paper: cx(classes.drawerPaper, { [classes.collapsed]: !expanded }) }}
                      variant="permanent"
                    >
                        {this.renderLogo()}
                        {this.renderNavItems()}
                        <button type="button" className={classes.expander} onClick={this.handleExpanderClick}>
                            <i className="c-icon" data-icon-name={expanded ? 'angle-double-left' : 'angle-double-right'} />
                        </button>
                    </Drawer>
                </Hidden>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    ...state.layout.sideNav,
    logoAlt: state.session.user ? state.session.user.organization.organization_name : 'Gigwalk',
    logoUri: selectors.getLogoUri(state, props),
    navItems: selectors.getNavItems(state, props),
});
const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    toggle: () => dispatch(actions.toggle()),
    collapse: () => dispatch(actions.collapse()),
    expand: () => dispatch(actions.expand()),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'SideNav' }),
    withTranslation(),
    connector
);
export default enhance(SideNav);
