// @flow
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { compose } from 'recompose';
import { DragSource, DropTarget } from 'react-dnd';
import { IconButton } from '@material-ui/core';
import { Reorder as ReorderIcon } from '@material-ui/icons';
import type { TFunction } from 'react-i18next';
import type {
    ConnectDragPreview,
    ConnectDragSource,
    ConnectDropTarget,
    DragSourceMonitor,
    DragSourceConnector,
    DropTargetConnector,
    DropTargetMonitor,
} from 'react-dnd';
import QuestionCard from './QuestionCard';

type Props = {
    connectDragSource: ConnectDragSource,
    connectDropTarget: ConnectDropTarget,
    connectDragPreview: ConnectDragPreview,
    description: string,
    dragButton?: React$Element<*>,
    draggable: boolean,
    expanded?: boolean,
    formKey: string, // the unique key that identifies this question in the form
    icon: string,
    id: number | string, // the dataType id
    isDragging?: boolean,
    index: number,
    moveCard: (indexA: number, indexB: number) => void,
    onClick: () => void,
    onClickOutside: () => void, // consider changing this to onBlur and add support for onFocus
    onDelete: () => void,
    onDuplicate: () => void,
    questionText: string,
    saving: boolean,
    t: TFunction,
    valueType: string,
};

class DraggableQuestionCard extends Component<Props> {
    render() {
        const { connectDragPreview, connectDragSource, connectDropTarget, ...other } = this.props;
        const dragButton = connectDragSource(
            <div>
                <IconButton component="div" disableRipple>
                    <ReorderIcon />
                </IconButton>
            </div>
        );
        const dropTarget = connectDropTarget(
            <div>
                <QuestionCard {...other} dragButton={dragButton} />
            </div>
        );
        return dropTarget ? connectDragPreview(dropTarget) : null;
    }
}

const cardSource = {
    canDrag: (props: Props) => {
        const { draggable } = props;
        return draggable;
    },
    beginDrag: (props: Props) => {
        const { id, index } = props;
        return { id, index };
    },
};

const cardTarget = {
    hover: (props: Props, monitor: DropTargetMonitor, component: React$Component<any, any>) => {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        const node = findDOMNode(component);
        if (!(node instanceof Element)) {
            return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = node.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }

        // Time to actually perform the action
        props.moveCard(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations, but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex; // eslint-disable-line no-param-reassign
    },
};

const targetCollect = (connect: DropTargetConnector) => ({
    connectDropTarget: connect.dropTarget(),
});

const sourceCollect = (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
});

const enhance = compose(
    DropTarget('QuestionCard', cardTarget, targetCollect),
    DragSource('QuestionCard', cardSource, sourceCollect),
);

export default enhance(DraggableQuestionCard);
