// @flow
export default (theme: Object) => ({
    base: {
        flexWrap: 'nowrap',
        lineHeight: '1.46429em',
        fontFamily: 'ProximaNova',
        fontSize: '1rem',
        // fontFamily: theme.typography.fontFamily,
        // fontSize: '0.875rem',
        // fontWeight: theme.typography.fontWeightRegular,
    },
    error: {
        backgroundColor: theme.palette.error.main,
    },
    info: {
        backgroundColor: theme.palette.primary.light,
    },
    success: {
        backgroundColor: theme.palette.success.main,
    },
    warning: {
        backgroundColor: theme.palette.secondary.light,
    },
    variantIcon: {
        marginRight: theme.spacing(1),
    },
});
