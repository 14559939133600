// @flow
import blacklist from 'blacklist';
import { createAction, handleActions } from 'redux-actions';
import { normalize } from 'normalizr';
import type { Dispatch } from 'redux';
import type { ActionType } from 'redux-actions';
import type { $AxiosXHR, $AxiosError } from 'axios';
import type { APIResponse, APIPromise } from 'gigwalk/lib/api/resource';
import type {
    LocationList,
    LocationListFile,
    Location as Gigwalk$Location,
} from 'gigwalk/lib/api/locationLists/types';
import logger from '../../util/logger';
import { client as gigwalk } from '../../api/createGigwalkClient';
import { init } from './initialState';
import {
    locationList as locationListSchema,
    relation as relationSchema,
    unresolvedLocation as unresolvedLocationSchema,
} from './schemas';
import type { State } from './initialState';

export type Relation = {
    id: number,
    relation_id: number,
};

export type LocationRelation = Gigwalk$Location & Relation;

// @todo Add to gigwalk-node
export type UnresolvedLocation = {
    id: number,
    state: string,
    address: string,
    title: string,
};

// @todo Add to gigwalk-node
export type FileUpload = {
    id: number,
    status: string,
    location_count: number,
    processed_row_count: number,
    unresolved_location_count: number,
};

// @todo Add to gigwalk-node
export type RemovedLocation = {
    relation: number,
    location: number,
};

// Actions
export const FETCH = 'g/locationLists/FETCH';
export const FETCH_SUCCESS = `${FETCH}_SUCCESS`;
export const FETCH_ERROR = `${FETCH}_ERROR`;

export const CREATE = 'g/locationLists/CREATE';
export const CREATE_SUCCESS = `${CREATE}_SUCCESS`;
export const CREATE_ERROR = `${CREATE}_ERROR`;

export const UPDATE = 'g/locationLists/UPDATE';
export const UPDATE_SUCCESS = `${UPDATE}_SUCCESS`;
export const UPDATE_ERROR = `${UPDATE}_ERROR`;

export const ADD_LOCATIONS = 'g/locationLists/ADD_LOCATIONS';
export const ADD_LOCATIONS_SUCCESS = `${ADD_LOCATIONS}_SUCCESS`;
export const ADD_LOCATIONS_ERROR = `${ADD_LOCATIONS}_ERROR`;

export const REMOVE_LOCATIONS = 'g/locationLists/REMOVE_LOCATIONS';
export const REMOVE_LOCATIONS_SUCCESS = `${REMOVE_LOCATIONS}_SUCCESS`;
export const REMOVE_LOCATIONS_ERROR = `${REMOVE_LOCATIONS}_ERROR`;

export const GET_LOCATIONS = 'g/locationLists/GET_LOCATIONS';
export const GET_LOCATIONS_SUCCESS = `${GET_LOCATIONS}_SUCCESS`;
export const GET_LOCATIONS_ERROR = `${GET_LOCATIONS}_ERROR`;

export const UPLOAD = 'g/locationLists/UPLOAD';
export const UPLOAD_SUCCESS = `${UPLOAD}_SUCCESS`;
export const UPLOAD_ERROR = `${UPLOAD}_ERROR`;

export const GET_UPLOAD_STATS = 'g/locationLists/GET_UPLOAD_STATS';
export const GET_UPLOAD_STATS_SUCCESS = `${GET_UPLOAD_STATS}_SUCCESS`;
export const GET_UPLOAD_STATS_ERROR = `${GET_UPLOAD_STATS}_ERROR`;

export const GET_UNRESOLVED_LOCATIONS = 'g/locationLists/GET_UNRESOLVED_LOCATIONS';
export const GET_UNRESOLVED_LOCATIONS_SUCCESS = `${GET_UNRESOLVED_LOCATIONS}_SUCCESS`;
export const GET_UNRESOLVED_LOCATIONS_ERROR = `${GET_UNRESOLVED_LOCATIONS}_ERROR`;

// Action Creators
export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchError = createAction(FETCH_ERROR);
export const fetch = createAction(
    FETCH,
    (params): Function => (
        (dispatch: Dispatch<any>): APIPromise<LocationList> => (
            gigwalk.locationLists.get(params)
                // $FlowFixMe need to fix gigwalk-node type definitions
                .then((resp: $AxiosXHR<APIResponse<LocationList>>) => {
                    const normalized = normalize(resp.data.data, locationListSchema);
                    dispatch(fetchSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchError());
                    return Promise.reject(err);
                })
        )
    )
);


// This function will normalize the locations returned by /v1/location_lists/{id}/locations,
// which include a special relation_id attribute. We refer to these locations as relations.
// There could be duplicate locations in a list, but each entry will have a unique relation_id.
// Since we want entities.locations to remain the source of truth for location data, we need
// to extract that data from entities.relations and merge it into entities.locations
export function normalizeRelations(data: Gigwalk$Location[]): Object {
    const normalized = normalize(data, [relationSchema]);

    if (normalized.entities.relations == null) {
        return normalized;
    }

    const relations = {};
    const locations = {};

    Object.entries(normalized.entities.relations).forEach(([id, relation]: [string, mixed]) => {
        if (relation == null || typeof relation !== 'object') return;
        const location = Object.assign({}, relation);
        delete location.relation_id;

        relations[id] = {
            relation_id: parseInt(id, 10),
            id: location.id,
        };

        locations[location.id] = location;
    });

    return {
        ...normalized,
        entities: {
            ...blacklist(normalized.entities, 'relations'),
            relations,
            locations,
        },
    };
}

export const getLocationsSuccess = createAction(GET_LOCATIONS_SUCCESS);
export const getLocationsError = createAction(GET_LOCATIONS_ERROR);
export const getLocations = createAction(
    GET_LOCATIONS,
    (params): Function => (
        (dispatch: Dispatch<any>): APIPromise<Gigwalk$Location[]> => (
            gigwalk.locationLists.getLocations(params)
                .then((resp: $AxiosXHR<APIResponse<Gigwalk$Location[]>>) => {
                    const normalized = normalizeRelations(resp.data.data);
                    dispatch(getLocationsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(getLocationsError());
                    return Promise.reject(err);
                })
        )
    )
);

export const createSuccess = createAction(CREATE_SUCCESS);
export const createError = createAction(CREATE_ERROR);
export const create = createAction(
    CREATE,
    (params): Function => (
        (dispatch: Dispatch<any>): APIPromise<[LocationList]> => (
            gigwalk.locationLists.createForOrganization(params)
                .then((resp: $AxiosXHR<APIResponse<[LocationList]>>) => {
                    const normalized = normalize(resp.data.data, [locationListSchema]);
                    dispatch(createSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(createError());
                    return Promise.reject(err);
                })
        )
    )
);

export const updateSuccess = createAction(UPDATE_SUCCESS);
export const updateError = createAction(UPDATE_ERROR);
export const update = createAction(
    UPDATE,
    (params): Function => (
        (dispatch: Dispatch<any>): APIPromise<[LocationList]> => (
            gigwalk.locationLists.update(params)
                .then((resp: $AxiosXHR<APIResponse<[LocationList]>>) => {
                    const normalized = normalize(resp.data.data, [locationListSchema]);
                    dispatch(updateSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(updateError());
                    return Promise.reject(err);
                })
        )
    )
);

export const addLocationsSuccess = createAction(ADD_LOCATIONS_SUCCESS);
export const addLocationsError = createAction(ADD_LOCATIONS_ERROR);
export const addLocations = createAction(
    ADD_LOCATIONS,
    (params): Function => (
        (dispatch: Dispatch<any>): APIPromise<LocationList> => (
            gigwalk.locationLists.addLocations(params)
                // $FlowFixMe need to fix gigwalk-node type definitions
                .then((resp: $AxiosXHR<APIResponse<LocationList>>) => {
                    const normalized = normalize(resp.data.data, locationListSchema);
                    dispatch(addLocationsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(addLocationsError());
                    return Promise.reject(err);
                })
        )
    )
);

export const removeLocationsSuccess = createAction(REMOVE_LOCATIONS_SUCCESS);
export const removeLocationsError = createAction(REMOVE_LOCATIONS_ERROR);
export const removeLocations = createAction(
    REMOVE_LOCATIONS,
    (params): Function => (
        (dispatch: Dispatch<any>): APIPromise<RemovedLocation[]> => (
            gigwalk.locationLists.removeLocations(params)
                // $FlowFixMe need to fix gigwalk-node type definitions
                .then((resp: $AxiosXHR<APIResponse<RemovedLocation[]>>) => {
                    const normalized = normalize(resp.data.data, [{}]);
                    dispatch(removeLocationsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(removeLocationsError());
                    return Promise.reject(err);
                })
        )
    )
);

export const uploadSuccess = createAction(UPLOAD_SUCCESS);
export const uploadError = createAction(UPLOAD_ERROR);
export const upload = createAction(
    UPLOAD,
    (params): Function => (
        (dispatch: Dispatch<any>) => (
            gigwalk.locationLists.createForOrganizationWithFile(params)
                .then((resp: $AxiosXHR<APIResponse<[LocationListFile]>>) => {
                    const normalized = normalize(resp.data.data, [locationListSchema]);
                    dispatch(uploadSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(uploadError());
                    return Promise.reject(err);
                })
        )
    )
);


export const getUploadStatsSuccess = createAction(GET_UPLOAD_STATS_SUCCESS);
export const getUploadStatsError = createAction(GET_UPLOAD_STATS_ERROR);
export const getUploadStats = createAction(
    GET_UPLOAD_STATS,
    (params): Function => (
        (dispatch: Dispatch<any>): APIPromise<[LocationListFile]> => (
            gigwalk.locationLists.getFileInfo(params)
                .then((resp: $AxiosXHR<APIResponse<[LocationListFile]>>) => {
                    const normalized = normalize(resp.data.data, [locationListSchema]);
                    dispatch(getUploadStatsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(getUploadStatsError());
                    return Promise.reject(err);
                })
        )
    )
);

export const getUnresolvedLocationsSuccess = createAction(GET_UNRESOLVED_LOCATIONS_SUCCESS);
export const getUnresolvedLocationsError = createAction(GET_UNRESOLVED_LOCATIONS_ERROR);
export const getUnresolvedLocations = createAction(
    GET_UNRESOLVED_LOCATIONS,
    (params): Function => (
        (dispatch: Dispatch<any>): APIPromise<UnresolvedLocation[]> => (
            gigwalk.locationLists.getUnresolved(params)
                .then((resp: $AxiosXHR<APIResponse<UnresolvedLocation[]>>) => {
                    const normalized = normalize(resp.data.data, [unresolvedLocationSchema]);
                    dispatch(getUnresolvedLocationsSuccess(normalized));
                    return normalized;
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(getUnresolvedLocationsError());
                    return Promise.reject(err);
                })
        )
    )
);


export default handleActions({
    [REMOVE_LOCATIONS_SUCCESS]: (state: State, action: ActionType<typeof removeLocationsSuccess>): State => {
        if (action.payload && Array.isArray(action.payload.result) && action.payload.result.length) {
            const keys: Array<string> = action.payload.result.map((item: RemovedLocation): string => `${item.relation}`);
            return {
                ...state,
                relations: blacklist(state.relations, ...keys),
            };
        }

        return state;
    },
}, init);
