var Handlebars = require('/tmp/build_c41768ab/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "    <div class=\"answer\">\n          <input type=\"text\" data-input name=\"answer time\" value=\""
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\" placeholder=\""
    + alias4(alias5(((stack1 = (depths[1] != null ? depths[1].placeholders : depths[1])) != null ? stack1.ex_3 : stack1), depth0))
    + "\" />\n          <div class=\"dropdown\" data-action=\"dropdown\">\n            <button class=\"btn btn-default dropdown-toggle\" type=\"button\" id=\"dropdownMenu1\" data-toggle=\"dropdown\">\n              <span data-target=\"selected\">"
    + alias4(((helper = (helper = helpers.timeUnit || (depth0 != null ? depth0.timeUnit : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"timeUnit","hash":{},"data":data}) : helper)))
    + "</span>\n              <span class=\"caret\"></span>\n            </button>\n            <ul class=\"dropdown-menu\" role=\"menu\" aria-labelledby=\"dropdownMenu1\">\n              <!--<li role=\"presentation\" data-selected=\"false\" data-value=\"seconds\" class=\"disabled\"><a role=\"menuitem\" tabindex=\"-1\" href=\"javascript:;\">"
    + alias4(alias5(((stack1 = (depths[1] != null ? depths[1].timeUnits : depths[1])) != null ? stack1.seconds : stack1), depth0))
    + "</a></li>-->\n              <li role=\"presentation\" data-selected=\"true\" data-value=\"minutes\"><a role=\"menuitem\" tabindex=\"-1\" href=\"javascript:;\">"
    + alias4(alias5(((stack1 = (depths[1] != null ? depths[1].timeUnits : depths[1])) != null ? stack1.minutes : stack1), depth0))
    + "</a></li>\n              <li role=\"presentation\" data-selected=\"false\" data-value=\"hours\"><a role=\"menuitem\" tabindex=\"-1\" href=\"javascript:;\">"
    + alias4(alias5(((stack1 = (depths[1] != null ? depths[1].timeUnits : depths[1])) != null ? stack1.hours : stack1), depth0))
    + "</a></li>\n              <!--<li role=\"presentation\" data-selected=\"false\" data-value=\"days\"><a role=\"menuitem\" tabindex=\"-1\" href=\"javascript:;\">"
    + alias4(alias5(((stack1 = (depths[1] != null ? depths[1].timeUnits : depths[1])) != null ? stack1.days : stack1), depth0))
    + "</a></li>-->\n            </ul>\n          </div>\n        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<!-- question type is Time -->\n<div class=\"type time\">\n  <div class=\"answers\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.answers : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n</div><!-- END Time -->\n<div class=\"validation error\" data-target=\"validationHint\">\n  <!--\n    \"Must be a number\"\n    \"Must be at least 2 characters long\"\n  -->\n</div>\n";
},"useData":true,"useDepths":true});