// @flow
import { combineReducers } from 'redux';
import description, { init as initDescriptionState } from '../containers/Description/duck/reducer';
import type { State as DescriptionState } from '../containers/Description/duck/reducer';

export type State = {
    description: DescriptionState,
};

export const init: State = {
    description: initDescriptionState,
};

export default combineReducers({
    description,
});
