// @flow
import React, { Component } from 'react';
import Card from 'react-credit-cards';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import numeral from 'numeral';
import type { Connector } from 'react-redux';
import type { Dispatch } from 'redux';
import type { FormProps } from 'redux-form';
import type { TFunction } from 'react-i18next';
import MaskedInput from '../../components/MaskedInput';
import SimplePane from '../../components/SimplePane';
import Spinner from '../../components/Spinner';
import fieldify from '../../components/fieldify';
import { cardNumber, currency, cvc, expiry, required } from '../../util/validations';
import actions from './duck/actions';
import type { State as RootState } from '../../redux/initialState';

type OwnProps = FormProps & {
    t: TFunction,
};

type StateProps = {
    addedFunds: number,
    confirmationDialogVisible: boolean,
    currentFunds: number,
    cardNumber: number,
    cvc: number,
    expiry: number,
    active: string,
};

type DispatchProps = {
    addFunds: () => void,
    getBalance: () => void,
    hideConfirmationDialog: () => void,
};

type Props = OwnProps & StateProps & DispatchProps;

// $FlowFixMe flow complains about optional properties in InjectedProps not being in component Props
const MaskedInputField = fieldify()(MaskedInput);

export class AddFunds extends Component<Props> {
    componentDidMount() {
        this.props.getBalance(); // eslint-disable-line react/destructuring-assignment
    }

    render() {
        const {
            addFunds,
            addedFunds,
            confirmationDialogVisible,
            currentFunds,
            handleSubmit,
            hideConfirmationDialog,
            invalid,
            submitting,
            t,
        } = this.props;

        // Format currentFunds for display. This should match the formatter used
        // by Cleave for the 'amount' field in CreditCardForm as close as possible.
        // Using numeral.js here since it has better support for currency.
        const balance = numeral(currentFunds).format('$0,0.00');
        const added = numeral(addedFunds).format('$0,0.00');

        return (
            <SimplePane>
                <form onSubmit={handleSubmit(addFunds)} noValidate>
                    <Dialog
                      open={confirmationDialogVisible}
                      onClose={hideConfirmationDialog}
                      maxWidth="xs"
                    >
                        <DialogTitle>{t('payments.confirmationDialog.title')}</DialogTitle>
                        <DialogContent>
                            {t('payments.confirmationDialog.message', { amount: added })}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={hideConfirmationDialog}>Okay</Button>
                        </DialogActions>
                    </Dialog>

                    <div className="row">
                        <h2 className="col-md-12">
                            {t('payments.currentFunds')}
                            <span className="amount">{balance}</span>
                        </h2>
                    </div>

                    <div className="row middle-xs c-form__row">
                        <div className="col-md-6">
                            <div className="row c-form__row">
                                <Field
                                  required
                                  className="col-xs-4"
                                  inputMode="numeric"
                                  pattern="[0-9.,]*"
                                  name="amount"
                                  id="amount"
                                  label={t('payments.form.amount')}
                                  placeholder={t('payments.form.amountPlaceholder')}
                                  component={MaskedInputField}
                                  options={{ numeral: true, numeralThousandsGroupStyle: 'thousand', numeralPositiveOnly: true }}
                                  validate={[required, currency]}
                                />
                                <Field
                                  required
                                  className="col-xs-8"
                                  inputMode="numeric"
                                  pattern="[0-9]*"
                                  name="cardNumber"
                                  id="cardNumber"
                                  label={t('payments.form.cardNumber')}
                                  placeholder={t('payments.form.cardNumberPlaceholder')}
                                  component={MaskedInputField}
                                  options={{ creditCard: true }}
                                  validate={[required, cardNumber]}
                                />
                            </div>
                            <div className="row c-form__row">
                                <Field
                                  required
                                  className="col-xs-6"
                                  inputMode="numeric"
                                  pattern="[0-9]*"
                                  name="expiry"
                                  id="expiry"
                                  label={t('payments.form.expiry')}
                                  placeholder={t('payments.form.expiryPlaceholder')}
                                  component={MaskedInputField}
                                  options={{ date: true, datePattern: ['m', 'y'] }}
                                  validate={[required, expiry]}
                                />
                                <Field
                                  required
                                  className="col-xs-6"
                                  inputMode="numeric"
                                  pattern="[0-9]*"
                                  name="cvc"
                                  id="cvc"
                                  label={t('payments.form.cvc')}
                                  placeholder={t('payments.form.cvcPlaceholder')}
                                  component={MaskedInputField}
                                  options={{ blocks: [4], numericOnly: true }}
                                  validate={[required, cvc]}
                                />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <Card
                              name=""
                              number={this.props.cardNumber || ''} // eslint-disable-line react/destructuring-assignment
                              expiry={this.props.expiry || ''} // eslint-disable-line react/destructuring-assignment
                              cvc={this.props.cvc || ''} // eslint-disable-line react/destructuring-assignment
                              focused={this.props.active} // eslint-disable-line react/destructuring-assignment
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <Button
                              variant="contained"
                              color="secondary"
                              type="submit"
                              id="addFunds"
                              style={{ width: '110px' }}
                              disabled={submitting || invalid}
                            >
                                {submitting ? <Spinner /> : t('payments.form.addFunds')}
                            </Button>
                        </div>
                    </div>
                </form>
            </SimplePane>
        );
    }
}

const valueSelector = formValueSelector('addFunds');

const mapStateToProps = (state: RootState): StateProps => ({
    ...state.addFunds,
    cardNumber: valueSelector(state, 'cardNumber'),
    expiry: valueSelector(state, 'expiry'),
    cvc: valueSelector(state, 'cvc'),
    active: state.form.addFunds ? state.form.addFunds.active || '' : '', // No selector exists for getting the active field :(
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    addFunds: () => dispatch(actions.addFunds()),
    getBalance: () => dispatch(actions.getBalance()),
    hideConfirmationDialog: () => dispatch(actions.hideConfirmationDialog()),
});

const DecoratedForm = reduxForm({
    form: 'addFunds',
})(AddFunds);

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default withTranslation()(connector(DecoratedForm));
