// @flow
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import getDefaultPath from '../util/getDefaultPath';
import type { State as RootState } from '../redux/initialState';

const locationHelper = locationHelperBuilder({});

export default connectedRouterRedirect({
    // This sends the user either to the query param route if we have one, or to the
    // default path if none is specified and the user is already logged in
    redirectPath: (state: RootState, ownProps) => {
        if (state.session.user == null) {
            return '/';
        }
        return locationHelper.getRedirectQueryParam(ownProps) || getDefaultPath(state.session.user);
    },
    // This prevents us from adding the query parameter when we send the user away from the login page
    allowRedirectBack: false,
    // If selector is true, wrapper will not redirect
    authenticatedSelector: (state: RootState): boolean => state.session.user == null,
    // A nice display name for this check
    wrapperDisplayName: 'UserIsNotAuthenticated',
});
