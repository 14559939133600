// @flow
/* eslint-disable max-len */
import React, { Fragment } from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
    <Fragment>
        <path d="M 12.001 21.547 L 6.227 15.354 C 6.068 15.197 5.915 15.032 5.77 14.863 L 5.654 14.739 L 5.666 14.739 C 4.494 13.32 3.79 11.5 3.79 9.516 C 3.79 4.981 7.466 1.305 12.001 1.305 C 16.535 1.305 20.21 4.981 20.21 9.516 C 20.21 11.5 19.507 13.32 18.336 14.739 L 18.347 14.739 L 18.231 14.863 C 18.085 15.032 17.932 15.197 17.773 15.354 Z" />
        <ellipse cx="12.001" cy="21.686" rx="4.439" ry="1.314" style={{ fill: '#000000', fillOpacity: 0.3 }} />
        <path d="M 291 242 m -7 0 a 7 7 0 1 0 14 0 a 7 7 0 1 0 -14 0 Z M 291 242 m -6.6 0 a 6.6 6.6 0 0 1 13.2 0 a 6.6 6.6 0 0 1 -13.2 0 Z" transform="matrix(-0.707107, 0.707107, -0.707107, -0.707107, 388.887665, -25.13213)" style={{ fill: '#ffffff' }} />
        <path d="M 13.821 7.933 L 12.633 6.746 L 9.467 9.913 L 9.467 11.1 L 10.655 11.1 L 13.821 7.933 Z M 14.758 6.997 C 14.881 6.873 14.881 6.674 14.758 6.55 L 14.017 5.81 C 13.893 5.686 13.694 5.686 13.57 5.81 L 12.95 6.43 L 14.137 7.617 L 14.758 6.997 Z" style={{ fill: '#ffffff' }} />
        <path d="M 8.201 12.05 L 15.799 12.05 L 15.799 13.316 L 8.201 13.316 Z" style={{ fill: '#ffffff' }} />
    </Fragment>,
    'EditMarker'
);
