// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useMemo } from 'react';
import { Field, Form } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { DialogActions, DialogContent } from '@material-ui/core';
import type { $AxiosXHR } from 'axios';
import type { Customer } from 'gigwalk/lib/api/customers/types';
import { client as gigwalk } from '../../../../api/createGigwalkClient';
import getDisplayName from '../../../../util/getDisplayName';
import FormikCheckbox from '../../../../components/FormikCheckbox';
import SubmitButton from '../../../../components/SubmitButton';
import UserSelect from '../UserSelect';
import styles from './styles';

type Props = {
    dirty: boolean,
    handleSubmit: Function,
    isSubmitting: boolean,
    isValid: boolean,
    setFieldValue: (field: string, value: any) => void,
    setStatus: (status: any) => void,
    status?: any,
    values: Object,
};

const useStyles = makeStyles(styles, { name: 'AssignForm' });

export default function AssignForm(props: Props) {
    const { handleSubmit, isSubmitting, isValid, setFieldValue, status, values } = props;
    const { assignee, ticketIds } = values;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const loadOptions = useCallback((inputValue: string) => {
        const params = { size: 20, from: 0 };
        const data = {
            ticket_ids: ticketIds,
            group_ids: [],
            required_certifications: [],
            q: `*${inputValue}*`,
        };

        return gigwalk.client.post('/v1/tickets/search/customers', data, { params })
            .then((resp: $AxiosXHR<Object>) => {
                const searchResults = resp.data.hits ? resp.data.hits.hits || [] : [];
                const customers = searchResults.map((record: { _source: Customer }) => record._source);

                return customers.map((customer: Customer) => ({
                    label: getDisplayName(customer),
                    value: customer,
                }));
            });
    }, [ticketIds]);

    const handleChange = useCallback((value) => {
        setFieldValue('assignee', value);
    }, [setFieldValue]);

    const allowForceAssign = useMemo(() => {
        const err = status && status.error ? status.error : null;
        const resp = err && err.response ? err.response : null;

        if (resp && resp.data && resp.data.gw_api_response) {
            const errors = resp.data.gw_api_response;
            return errors.every((error: Object) => {
                // 0x00010001 = lack_certifications
                // 0x00010002 = exceed_capacity
                // 0x00010003 = another_hard_scheduled
                const { error_code: errorCode } = error;
                return errorCode === '0x00010001' || errorCode === '0x00010002' || errorCode === '0x00010003';
            });
        }
        return false;
    }, [status]);

    return (
        <Form onSubmit={handleSubmit} className={classes.root}>
            <DialogContent className={classes.dialogContent}>
                <UserSelect
                  defaultOptions
                  loadOptions={loadOptions}
                  onChange={handleChange}
                  value={values.assignee}
                />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                {allowForceAssign
                    ? (
                        <Field
                          classes={{
                              label: classes.forceAssignLabel,
                              root: classes.forceAssign,
                              wrapper: classes.forceAssignWrapper,
                          }}
                          component={FormikCheckbox}
                          label={t('assignDialog.forceAssign')}
                          name="force"
                          type="checkbox"
                        />
                    ) : null
                }
                <SubmitButton
                  classes={{
                      disabled: classes.disabled,
                      label: classes.submitLabel,
                      root: classes.submit,
                  }}
                  color="primary"
                  disabled={isSubmitting || !isValid}
                  fullWidth
                  size="large"
                  submitting={isSubmitting}
                  type="submit"
                  variant="outlined"
                >
                    {!assignee
                        ? t('assignDialog.invalidSubmit')
                        : (
                            <Trans
                              defaults={t('assignDialog.validSubmit', { customer: assignee.label })}
                              components={['Assign', <strong style={{ fontWeight: 600 }}>customer</strong>]}
                            />
                        )
                    }
                </SubmitButton>
            </DialogActions>
        </Form>
    );
}
