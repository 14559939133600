// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles, { name: 'MarketingHeader' });

export function MarketingHeader() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <header className={classes.root}>
            <div>
                <a href="http://www.gigwalk.com/" target="_top">
                    <img alt="Gigwalk" src="/public/images/gigwalk-blue-logo@2x.png" className={classes.logo} />
                </a>
            </div>
            <div className={classes.gigwalkerSignup}>
                <a href="http://www.gigwalk.com/gigwalkers/">
                    {t('signup.marketing.becomeAGigwalker')}
                </a>
                <div className={classes.mobileApps}>
                    <a href="https://itunes.apple.com/us/app/gigwalk/id391881025">
                        <img
                          width={120}
                          alt="Download on the App Store"
                          src="/public/img/marketing/app-store-badge.svg"
                        />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.gigwalk">
                        <img
                          width={120}
                          alt="Get it on Google Play"
                          src="/public/img/marketing/google-play-badge.svg"
                        />
                    </a>
                </div>
            </div>
        </header>
    );
}

export default MarketingHeader;
