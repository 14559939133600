// @flow
import Instructions from './Instructions';
import SkipLogicTree from '../SkipLogicTree';

/**
 * @class (Model) TargetList
 * @constructor
 */
export default SkipLogicTree.extend({

    defaults: {
        data_types: null,
        observation_target_list_id: 0,
    },

    Collection: Instructions,

    initialize(attrs: Object, ...args: Array<any>) {
        this.Collection = Instructions;
        this._super(attrs, ...args);
        this.set({
            data_types: new Instructions(attrs.data_types),
        });
    },

    addQuestion(question: Object): Array<Object> {
        return this.get('data_types').push(question);
    },

    removeQuestions(ids: Array<number>): boolean {
        ids.forEach((id: number) => {
            this.get('data_types').removeByProperty({ data_type_id: id });
        });
        return this.get('data_types').length === 0;
    },

    getQuestions(): Array<Object> {
        return this.get('data_types');
    },
});
