// @flow
import React from 'react';
import cx from 'classnames';
import type { Element } from 'react';

type Props = {
    className?: string,
    error?: string,
    id: string,
    label?: string | Element<any>,
    required?: boolean,
};

export default (props: Props) => {
    const { className, error, label, ...inputProps } = props;

    return (
        <div className={cx(className, { 'c-form__error': !!error })}>
            <input {...inputProps} type="radio" />
            <label htmlFor={inputProps.id} className={cx({ 'c-form__label-required': !!inputProps.required })}>{label}</label>
            <p className="c-form__error-message">{error || ''}</p>
        </div>
    );
};
