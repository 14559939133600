// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Field, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import FormikInput from '../../../../components/FormikInput';
import FormikCheckbox from '../../../../components/FormikCheckbox';
import SubmitButton from '../../../../components/SubmitButton';
import styles from './styles';

type Props = {
    dirty: boolean,
    handleSubmit: Function,
    isSubmitting: boolean,
    isValid: boolean,
    setStatus: (status: any) => void,
    status?: any,
};

const useStyles = makeStyles(styles, { name: 'LoginForm' });

export default function LoginForm(props: Props) {
    const { dirty, handleSubmit, isSubmitting, isValid, setStatus, status } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const handleChange = useCallback(() => {
        if (status && status.error) {
            setStatus({ error: null });
        }
    }, [status, setStatus]);

    return (
        <Paper className={classes.paper} elevation={0}>
            <Form name="login" onChange={handleChange}>
                <h1>{t('login.loginForm.heading')}</h1>
                {status && status.error
                    ? <div className={classes.error}>{status.error}</div>
                    : null
                }
                <fieldset className={classes.fieldset}>
                    <Field
                      classes={{ input: classes.input }}
                      component={FormikInput}
                      fullWidth
                      label={t('shared.auth.email')}
                      labelPlacement="top"
                      margin="dense"
                      name="email"
                      placeholder={t('shared.auth.emailPlaceholder')}
                      required
                      variant="outlined"
                    />
                    <div className={classes.passwordContainer}>
                        <Field
                          classes={{ input: classes.input }}
                          component={FormikInput}
                          fullWidth
                          label={t('shared.auth.password')}
                          labelPlacement="top"
                          margin="dense"
                          name="password"
                          placeholder={t('shared.auth.enterPasswordPlaceholder')}
                          type="password"
                          required
                          variant="outlined"
                        />
                        <Link className={classes.forgotPasswordLink} to="/forgot-password">
                            {t('login.loginForm.forgotPassword')}
                        </Link>
                    </div>
                    <Field
                      classes={{ checkbox: classes.checkbox }}
                      color="primary"
                      component={FormikCheckbox}
                      label={t('login.loginForm.rememberMe')}
                      name="rememberMe"
                      type="checkbox"
                    />
                </fieldset>
                <SubmitButton
                  className={classes.submitButton}
                  color="secondary"
                  disabled={!dirty || isSubmitting || !isValid}
                  onClick={handleSubmit}
                  size="large"
                  submitting={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                    {t('login.loginForm.submit')}
                </SubmitButton>
            </Form>
        </Paper>
    );
}
