// @flow
/* eslint-disable global-require */
import type { Logger } from 'bunyan';
import typeof NodeBunyan from 'bunyan';
import typeof BrowserBunyan from 'browser-bunyan';
import TrackJSStream from './TrackJSStream';
import ConsoleFormattedStream from './ConsoleFormattedStream';

let bunyan: NodeBunyan | BrowserBunyan;
let logger: Logger; // eslint-disable-line import/no-mutable-exports
const name = 'app';
const { trackJs } = global;
const { NODE_ENV } = process.env;

type BunyanStream = {
    level: string,
    type: string,
    path?: string,
    stream?: stream$Writable,
};

// istanbul ignore next
if (global.__SERVER__) {
    bunyan = require('bunyan');
    const { createStream } = require('bunyan-logentries');
    const path = require('path');
    const bunyanFormat = require('bunyan-format');
    const bFormat = bunyanFormat({ outputMode: 'short' });
    let streams: Array<BunyanStream>;
    const LOGENTRIES_TOKEN_INFO = process.env.LOGENTRIES_TOKEN_INFO || '';
    const LOG_LEVEL = process.env.LOG_LEVEL || 'info';
    if (LOGENTRIES_TOKEN_INFO) {
        streams = [{
            level: LOG_LEVEL,
            type: 'raw',
            stream: createStream({
                token: LOGENTRIES_TOKEN_INFO,
                // The levels here are set to match bunyan log levels, this will
                // no longer be needed when/if this is merged:
                // https://github.com/nemtsov/node-bunyan-logentries/pull/9
                levels: ['trace', 'debug', 'info', 'warn', 'error', 'fatal'],
            }),
        }];
    } else {
        streams = [{
            path: process.env.GWP_LOG_DEBUG_PATH || path.resolve(__dirname, '../../../log/debug.log'),
            type: 'rotating-file',
            level: 'debug',
        }, {
            path: process.env.GWP_LOG_ERROR_PATH || path.resolve(__dirname, '../../../log/error.log'),
            type: 'rotating-file',
            level: 'error',
        }];
    }

    logger = bunyan.createLogger({
        name,
        src: true,
        streams: [{
            type: 'stream',
            level: LOG_LEVEL,
            stream: bFormat,
        }, ...streams],
        serializers: bunyan.stdSerializers,
    });

    logger.on('error', (e: Error, stream: any) => {
        console.error(e); // eslint-disable-line no-console
        try {
            if (stream) stream.pipe(process.stdout);
        } catch (err) { console.error(err); } // eslint-disable-line no-console
    });
} else {
    bunyan = require('browser-bunyan');
    let streams: Array<Object> = [];
    if (process.env.NODE_ENV !== 'test' && !global.hasOwnProperty('__IS_COVERAGE__')) {
        const hasLocalStorage: boolean = ('localStorage' in global);
        const envLevel: string = NODE_ENV === 'production' ? 'warn' : 'info';
        const loggerLevel: string = (hasLocalStorage && localStorage.getItem('gwp-log-level')) || envLevel;

        streams = [{
            level: loggerLevel,
            stream: new ConsoleFormattedStream({ useTrace: false }),
            type: 'raw',
        }, {
            level: 'trace',
            stream: new TrackJSStream(),
            type: 'raw',
        }];
    }

    logger = bunyan.createLogger({
        name,
        streams,
        serializers: bunyan.stdSerializers,
    });

    if (NODE_ENV === 'production') {
        const errorMethod = logger.error.bind(logger);
        logger.error = function logAndTrackError(...args: Array<any>) {
            if (trackJs) {
                args.filter((arg: any): boolean => arg instanceof Error)
                    .forEach((err: Error) => {
                        logger.trace('Sending error to track JS', err);
                        trackJs.track(err);
                    });
            }

            errorMethod(...args);
        };
    }
}

logger.debug('logger initialized');

export default logger;
