// @flow
const { GIGWALK_API_SERVER_HOSTNAME } = process.env;

export default function isCrossmark(orgID: string | number): boolean {
    const id: number = Number(orgID);

    if (id === 7) {
        return true;
    }

    switch (GIGWALK_API_SERVER_HOSTNAME) {
        case 'stage-api.apps.gigwalk.com':
            return id === 310;

        case 'partner-api.apps.gigwalk.com':
            return id === 110;

        case 'partner-dev-api.enterprise.gigwalk.com':
            return id === 10;

        case 'api.enterprise.gigwalk.com':
            return id === 359;

        default:
            return false;
    }
}
