// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/styles';
import typeof TuiImageEditor from 'tui-image-editor';
import { Button } from '@material-ui/core';
import {
    Check as CheckIcon,
    Close as CloseIcon,
    Crop169 as Crop169Icon,
    Crop32 as Crop32Icon,
    Crop54 as Crop54Icon,
    Crop75 as Crop75Icon,
    CropFree as CropFreeIcon,
    CropSquare as CropSquareIcon,
} from '@material-ui/icons';

type Props = {
    editor: TuiImageEditor,
    onClose: () => void,
}

const useStyles = makeStyles((theme: Object) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(2, 0),
    },
    presets: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: 400,
        marginBottom: theme.spacing(1),
    },
    preset: {
        color: fade(theme.palette.common.white, 0.5),

        '&$selected': {
            color: theme.palette.common.white,
        },
    },
    controls: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: 200,
    },
    buttonLabel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    selected: {},
}), { name: 'CropMenu' });

const presets = [
    { icon: CropFreeIcon, label: 'Custom', value: 'custom' },
    { icon: CropSquareIcon, label: 'Square', value: 'square' },
    { icon: Crop32Icon, label: '3:2', value: 'preset-3-2' },
    { icon: Crop54Icon, label: '5:4', value: 'preset-5-4' },
    { icon: Crop75Icon, label: '7:5', value: 'preset-7-5' },
    { icon: Crop169Icon, label: '16:9', value: 'preset-16-9' },
];

export default function CropMenu(props: Props) {
    const { editor, onClose } = props;
    const classes = useStyles(props);

    const [preset, setPreset] = useState('custom');

    useEffect(() => {
        editor.startDrawingMode('CROPPER');
        return () => {
            // Only call stopDrawingMode if the editor is initialized. Calling editor.destroy()
            // will set the _graphics instance to null, so we check that condition here.
            if (editor._graphics) {
                editor.stopDrawingMode();
            }
        };
    }, [editor]);

    const handlePresetClick = useCallback((event: SyntheticEvent<any>) => {
        const selected = event.currentTarget.name;
        switch (selected) {
            case 'square':
                editor.setCropzoneRect(1);
                break;
            case 'preset-3-2':
                editor.setCropzoneRect(3 / 2);
                break;
            case 'preset-5-4':
                editor.setCropzoneRect(5 / 4);
                break;
            case 'preset-7-5':
                editor.setCropzoneRect(7 / 5);
                break;
            case 'preset-16-9':
                editor.setCropzoneRect(16 / 9);
                break;
            default:
                editor.setCropzoneRect();
                break;
        }
        setPreset(selected);

        const graphics = editor._graphics;
        const cropper = graphics.getComponent('CROPPER');
        if (cropper._cropzone) {
            const canvas = graphics.getCanvas();
            const canvasElement = graphics.getCanvasElement();
            const scale = canvas.getHeight() / parseFloat(canvasElement.parentElement.style.maxHeight);
            cropper._cropzone.cornerSize = Math.round(10 * scale);
            cropper._cropzone._renderControls(canvas.getContext());
        }
    }, [editor]);

    const handleApply = useCallback(() => {
        const rect = editor.getCropzoneRect();
        editor.crop(rect);
        onClose();
    }, [editor, onClose]);

    return (
        <div className={classes.root}>
            <div className={classes.presets}>
                {presets.map(({ icon: Icon, label, value }: Object) => (
                    <Button
                      classes={{ label: classes.buttonLabel }}
                      className={cx(classes.preset, { [classes.selected]: value === preset })}
                      color="inherit"
                      name={value}
                      onClick={handlePresetClick}
                    >
                        <Icon fontSize="large" />
                        {label}
                    </Button>
                ))}
            </div>
            <div className={classes.controls}>
                <Button color="inherit" onClick={handleApply}>
                    <CheckIcon className={classes.icon} />
                    Apply
                </Button>
                <Button color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon} />
                    Cancel
                </Button>
            </div>
        </div>
    );
}

CropMenu.defaultProps = {
    onClose: () => {},
};
