// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { Button, Icon, IconButton, Input } from '@material-ui/core';
import { Add as AddIcon, Clear as ClearIcon } from '@material-ui/icons';
import { Field, FieldArray } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import type { TFunction } from 'react-i18next';
import Alert from './Alert';
import MultiSelectSwitch from './MultiSelectSwitch';
import fieldify from '../../../../../../components/fieldify';
import getValidationRule from '../../../../utils/getValidationRule';
import { VALUE_TYPE } from '../../../../../../../browser/shared/constant/ProjectConstant';

type Props = {
    classes: Object,
    className?: string,
    formKey: string,
    id: number,
    readOnly?: boolean,
    t: TFunction,
    valueType: string,
};

const styles = (theme: Object) => ({
    choice: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        margin: theme.spacing(2),
    },
    choiceValue: {
        flex: 1,
    },
    choiceActions: {
        flexBasis: '17%',
        height: 46,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    icon: {
        width: 48,
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.26)',
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
});

const ChoiceField = fieldify({ i18nKey: 'projectBuilder.data.validation.proposition' })(Input);
// $FlowFixMe flow complains about optional properties in InjectedProps not being in component Props
const AlertField = fieldify()(Alert);
// $FlowFixMe flow complains about optional properties in InjectedProps not being in component Props
const MultiSelectSwitchField = fieldify()(MultiSelectSwitch);

export class MultipleChoice extends Component<Props> {
    renderChoices: Function = (props: Props & { fields: Object }) => {
        const { classes, readOnly } = this.props;
        const { fields, formKey, id, t, valueType } = props;
        const icon = valueType === VALUE_TYPE.MULTIPLE_CHOICE ? 'radio_button_unchecked' : 'check_box_outline_blank';

        return (
            <ul>
                {fields.map((name: string, index: number) => (
                    <li key={name} className={classes.choice}>
                        <Icon className={classes.icon}>{icon}</Icon>
                        {
                            // $FlowFixMe Validator type needs to be updated to include name argument
                            <Field
                              className={classes.choiceValue}
                              name={`${name}.choice`}
                              component={ChoiceField}
                              validate={getValidationRule(`${name}.choice`)}
                              readOnly={readOnly}
                            />
                        }
                        <div className={classes.choiceActions}>
                            <Field
                              name={`${name}.alert`}
                              component={AlertField}
                              tooltip={t('projectBuilder.data.markAsException')}
                              readOnly={readOnly}
                            />
                            {!readOnly && fields.length > 2
                                ? (
                                    <IconButton onClick={() => { fields.remove(index); }}>
                                        <ClearIcon />
                                    </IconButton>
                                )
                                : null
                            }
                        </div>
                    </li>
                ))}
                {!readOnly
                    ? (
                        <li className={classes.actions}>
                            <Button
                              flat
                              color="primary"
                              onClick={() => { fields.push({ choice: '', alert: false }); }}
                            >
                                <AddIcon />{t('projectBuilder.data.addOption')}
                            </Button>
                            <Field
                              disabled={id > 0}
                              name={`${formKey}.valueType`}
                              label={t('projectBuilder.data.checkboxes')}
                              component={MultiSelectSwitchField}
                            />
                        </li>
                    )
                    : null
                }
            </ul>
        );
    };

    render() {
        const { className, formKey } = this.props;

        return (
            <div className={className}>
                <FieldArray
                  {...this.props}
                  name={`${formKey}.propositions`}
                  component={this.renderChoices}
                />
            </div>
        );
    }
}

const enhance = compose(withStyles(styles, { name: 'MultipleChoice' }), withTranslation());
export default enhance(MultipleChoice);
