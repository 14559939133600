// @flow
import React, { Component } from 'react';
import Switch from '../../../../../../components/Switch';
import { VALUE_TYPE } from '../../../../../../../browser/shared/constant/ProjectConstant';

type Props = {
    className?: string,
    label?: string,
    onBlur?: (value: 'MULTI_SELECT' | 'MULTIPLE_CHOICE') => void,
    onChange?: (value: 'MULTI_SELECT' | 'MULTIPLE_CHOICE') => void,
    value?: 'MULTI_SELECT' | 'MULTIPLE_CHOICE',
};

export default class MultiSelectSwitch extends Component<Props> {
    handleChange = (checked: boolean) => {
        const { onChange } = this.props;

        if (typeof onChange === 'function') {
            const value = checked ? VALUE_TYPE.MULTI_SELECT : VALUE_TYPE.MULTIPLE_CHOICE;
            onChange(value);
        }
    };

    handleBlur = (checked: boolean) => {
        const { onBlur } = this.props;

        if (typeof onBlur === 'function') {
            const value = checked ? VALUE_TYPE.MULTI_SELECT : VALUE_TYPE.MULTIPLE_CHOICE;
            onBlur(value);
        }
    };

    render() {
        // To prevent flow errors, remove onBlur/onChange from switchProps. Flow does not seem
        // to understand that the onChange/onBlur props specified below take precedence over
        // those in switchProps
        const { onBlur, onChange, value, ...switchProps } = this.props;
        return (
            <Switch
              {...switchProps}
              checked={value === VALUE_TYPE.MULTI_SELECT}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
        );
    }
}
