// @flow
import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import { getAvailableActions, getPermittedActions, getTicket } from '../../../../../duck/selectors';

export const getInitialValues = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    getTicket,
    (ticket: ?Object): ?Object => {
        if (!ticket) {
            return {
                dueDate: undefined,
                startDate: undefined,
            };
        }

        const {
            due_date: dueDate,
            location,
            start_date: startDate,
        } = ticket;

        return {
            dueDate: moment.tz(dueDate, location.tzid).format('YYYY-MM-DDTHH:mm:ss'),
            startDate: moment.tz(startDate, location.tzid).format('YYYY-MM-DDTHH:mm:ss'),
        };
    }
);

export const getReadOnly = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    getPermittedActions,
    (actions: string[]): string[] => {
        const readOnly = [];
        const canEditStartDate = actions.includes('editStartDate');
        const canEditDueDate = actions.includes('editDueDate');
        const canExtend = actions.includes('extend');

        if (!canEditStartDate) {
            readOnly.push('startDate');
        }

        if (!canEditDueDate && !canExtend) {
            readOnly.push('dueDate');
        }

        return readOnly;
    }
);

export const getDisabled = createSelector(
    getAvailableActions,
    (actions: string[]): string[] => {
        const disabled = [];
        const canEditStartDate = actions.includes('editStartDate');
        const canEditDueDate = actions.includes('editDueDate');
        const canExtend = actions.includes('extend');

        if (!canEditStartDate) {
            disabled.push('startDate');
        }

        if (!canEditDueDate && !canExtend) {
            disabled.push('dueDate');
        }

        return disabled;
    }
);

export default {
    getDisabled,
    getInitialValues,
    getReadOnly,
};
