// @flow
import { createSelector, createStructuredSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import type { Group } from 'gigwalk/lib/api/groups/types';
import { selectors as projectBuilder } from '../../../../../duck';
import type { State as RootState } from '../../../../../../../redux/initialState';

// type FormValues = {
//     groups: Group[],
// };

const valueSelector = formValueSelector('projectBuilder');

export const getInitialValues = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    projectBuilder.getInitialValues,
    createStructuredSelector({
        groups: (values: Object) => values.groups,
    })
);

export const getMemberCount = createSelector(
    (state: RootState) => valueSelector(state, 'groups'),
    (groups: Group[]) => (groups || []).reduce((count: number, group: Group) => count + group.member_count, 0)
);

export default {
    getInitialValues,
    getMemberCount,
};
