// @flow
import { createSelector } from 'reselect';
import qs from 'qs';
import type { ContextRouter } from 'react-router';
import entitySelector from '../../../redux/entities/entitySelector';
import type { State as RootState } from '../../../redux/initialState';
import { USER_ROLES } from '../../../../browser/shared/constant/UserRoles';

type TableData = Array<{
    customer: Object,
    email: string,
    id: number,
    role: string,
    status: string,
}>;

type PathParams = {
    orgId: string,
};

type SearchParams = {
    geo?: Object[],
    order?: string,
    organization?: string[],
    page?: string,
    q?: string,
    size?: string,
    sort?: string,
    status?: string[],
};

type APIParams = {
    filters?: Object[],
    keywords?: string,
    sort?: Object[],
    limit?: number,
    offset?: number,
};

const customerSelector = entitySelector('customers');

// Maps the key we use in the url query to the key expected by the API
const apiParams = {
    email: 'email',
    name: 'first_name',
    role: 'role',
    status: 'customer_status',
};

const userRoleOptions = Object.freeze([
    USER_ROLES.PLATFORM_ADMIN,
    USER_ROLES.SELF_SERVICE,
    USER_ROLES.SUPER_ADMIN,
    USER_ROLES.ADMIN,
    USER_ROLES.OPERATOR,
    USER_ROLES.ANALYST,
    USER_ROLES.WORKER,
]);

const getPathParams = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): PathParams => {
    const { match } = props;
    // $FlowFixMe Should be safe to assume orgId will always be defined
    return match.params;
};

export const getUserRoleOptions = createSelector(
    (state: RootState): string => (state.session.user ? state.session.user.role : ''),
    (userRole: string): string[] => {
        switch (userRole) {
            case USER_ROLES.PLATFORM_ADMIN:
                // Note: Platform admins should only exist in org 4
                return userRoleOptions;

            case USER_ROLES.SELF_SERVICE:
            case USER_ROLES.SUPER_ADMIN:
                return userRoleOptions.filter((option: string) => option !== USER_ROLES.PLATFORM_ADMIN);

            default:
                return [];
        }
    }
);

export const getTableData = createSelector(
    (state: RootState) => customerSelector(state, state.customerList.data),
    (customers: Object[]): TableData => (
        customers
            .filter((customer) => !!customer)
            .map((customer) => {
                const {
                    // current_latitude: latitude,
                    // current_longitude: longitude,
                    customer_status: status,
                    email,
                    id,
                    role,
                } = customer;

                // const geocoder = new google.maps.Geocoder();
                // const currentLocation = {
                //     lat: latitude,
                //     lng: longitude,
                // };
                // geocoder.geocode({ location: currentLocation }, callback);

                return {
                    customer,
                    email,
                    id,
                    role,
                    status,
                };
            })
    )
);

export const parseSearchParams = createSelector(
    <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): string => {
        const { location } = props;
        return location.search || '';
    },
    (search: string): SearchParams => qs.parse(search, { ignoreQueryPrefix: true })
);

export const getAPIParams = createSelector(
    parseSearchParams,
    getPathParams,
    (state: RootState) => state.session.user,
    (search: SearchParams, path: PathParams, user: ?Object): APIParams => {
        const { order, page, q, size, sort, ...filters } = search;
        const offset = Math.max(parseInt(page, 10) - 1, 0) || 0;
        const limit = Math.max(parseInt(size, 10), 0) || 10;
        const defaultSort = [{ first_name: order || 'asc' }];

        const apiFilters = [];

        if (user && user.role === USER_ROLES.PLATFORM_ADMIN) {
            if (filters.organization) {
                apiFilters.push({
                    key: 'organization_id',
                    value: filters.organization.map((id: string): number => parseInt(id, 10)),
                });
            }
        } else {
            apiFilters.push({
                key: 'organization_id',
                value: parseInt(path.orgId, 10),
            });
        }

        if (filters.geo) {
            if (filters.geo.length > 1) {
                apiFilters.push({
                    filter_type: 'or',
                    filters: filters.geo.reduce((geoFilters: Object[], geo: Object): Object[] => {
                        const [type, address] = Object.entries(geo)[0];
                        geoFilters.push({
                            filter_type: 'address',
                            key: type,
                            value: address,
                        });
                        return geoFilters;
                    }, []),
                });
            } else {
                const [type, address] = Object.entries(filters.geo[0])[0];
                apiFilters.push({
                    filter_type: 'address',
                    key: type,
                    value: address,
                });
            }
        }

        if (filters.status) {
            apiFilters.push({
                key: 'customer_status',
                value: filters.status,
            });
        }

        return {
            filters: apiFilters,
            keywords: q,
            sort: (sort && sort in apiParams) ? [{ [apiParams[sort]]: order || 'asc' }] : defaultSort,
            limit,
            offset,
        };
    }
);

export default {
    getAPIParams,
    getTableData,
    getUserRoleOptions,
    parseSearchParams,
};
