// @flow
export const FETCH_DATA = 'g/subscriptionList/FETCH_DATA';
export const FETCH_DATA_SUCCESS = `${FETCH_DATA}_SUCCESS`;
export const FETCH_DATA_ERROR = `${FETCH_DATA}_ERROR`;

export const DELETE_PROJECT = 'g/subscriptionList/DELETE_PROJECT';
export const DELETE_PROJECT_SUCCESS = `${DELETE_PROJECT}_SUCCESS`;
export const DELETE_PROJECT_ERROR = `${DELETE_PROJECT}_ERROR`;

export const RUN_AUTOASSIGN = 'g/subscriptionList/RUN_AUTOASSIGN';
export const RUN_AUTOASSIGN_SUCCESS = `${RUN_AUTOASSIGN}_SUCCESS`;
export const RUN_AUTOASSIGN_ERROR = `${RUN_AUTOASSIGN}_ERROR`;

export const LOAD_FILTERS = 'g/subscriptionList/LOAD_FILTERS';
export const LOAD_FILTERS_SUCCESS = `${LOAD_FILTERS}_SUCCESS`;
export const LOAD_FILTERS_ERROR = `${LOAD_FILTERS}_ERROR`;

export default {
    DELETE_PROJECT,
    DELETE_PROJECT_SUCCESS,
    DELETE_PROJECT_ERROR,
    FETCH_DATA,
    FETCH_DATA_SUCCESS,
    FETCH_DATA_ERROR,
    LOAD_FILTERS,
    LOAD_FILTERS_ERROR,
    LOAD_FILTERS_SUCCESS,
    RUN_AUTOASSIGN,
    RUN_AUTOASSIGN_SUCCESS,
    RUN_AUTOASSIGN_ERROR,
};
