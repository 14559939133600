// @flow
export const GET_PLACE_DETAILS = 'g/googleMaps/GET_PLACE_DETAILS';
export const GET_PLACE_DETAILS_SUCCESS = `${GET_PLACE_DETAILS}_SUCCESS`;
export const GET_PLACE_DETAILS_ERROR = `${GET_PLACE_DETAILS}_ERROR`;

export const GET_PLACE_PREDICTIONS = 'g/googleMaps/GET_PLACE_PREDICTIONS';
export const GET_PLACE_PREDICTIONS_SUCCESS = `${GET_PLACE_PREDICTIONS}_SUCCESS`;
export const GET_PLACE_PREDICTIONS_ERROR = `${GET_PLACE_PREDICTIONS}_ERROR`;

export const FIND_PLACE_FROM_QUERY = 'g/googleMaps/FIND_PLACE_FROM_QUERY';
export const FIND_PLACE_FROM_QUERY_SUCCESS = `${FIND_PLACE_FROM_QUERY}_SUCCESS`;
export const FIND_PLACE_FROM_QUERY_ERROR = `${FIND_PLACE_FROM_QUERY}_ERROR`;


export default {
    FIND_PLACE_FROM_QUERY,
    FIND_PLACE_FROM_QUERY_ERROR,
    FIND_PLACE_FROM_QUERY_SUCCESS,
    GET_PLACE_DETAILS,
    GET_PLACE_DETAILS_ERROR,
    GET_PLACE_DETAILS_SUCCESS,
    GET_PLACE_PREDICTIONS,
    GET_PLACE_PREDICTIONS_ERROR,
    GET_PLACE_PREDICTIONS_SUCCESS,
};
