// @flow
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Button, Dialog, Icon } from '@material-ui/core';
import { compose } from 'recompose';
import platform from 'platform';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import * as session from '../../ducks/session';
import styles from './styles';
import { USER_ROLES } from '../../../browser/shared/constant/UserRoles';
import type { State as RootState } from '../../redux/initialState';

type State = {
    open: boolean,
    osFamily: string,
};

type OwnProps = {
    classes: Object,
    t: TFunction,
};

type StateProps = {
    isGigwalker: boolean,
    isWorker: boolean,
};

type Props = OwnProps & StateProps;

const HEROKU_APP_NAME = process.env.HEROKU_APP_NAME || 'gigwalk-stage-server';
const GIGWALK_APP_STORE_LINK = 'https://itunes.apple.com/us/app/gigwalk-enterprise/id973152153?mt=8';
const GIGWALK_GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.gigwalk.platform&hl=en';

const appBlacklist = [
    'gigwalk-python-app-server',
    'gigwalk-partner-server',
    'gigwalk-partner-dev-server',
    'gigwalk-japan-fe-server',
    'gigwalk-jp-staging-fe-server',
    'gigwalk-partner-jp-fe-server',
];

export class SplashScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        let osFamily = platform.os.family ? platform.os.family.toLowerCase() : '';
        osFamily = osFamily === 'ios' || osFamily === 'android' ? osFamily : 'other';

        let open = false;
        if (props.isGigwalker) {
            open = true;
        } else if (props.isWorker) {
            open = osFamily !== 'other' && !appBlacklist.includes(HEROKU_APP_NAME);
        }

        this.state = {
            open,
            osFamily,
        };
    }

    componentWillReceiveProps(nextProps: Props) {
        const { isGigwalker, isWorker } = nextProps;
        const { osFamily } = this.state;

        let open = false;
        if (isGigwalker) {
            open = true;
        } else if (isWorker) {
            open = (osFamily === 'ios' || osFamily === 'android') && !appBlacklist.includes(HEROKU_APP_NAME);
        }

        this.setState({ open });
    }

    handleDismiss = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes, isGigwalker, t } = this.props;
        const { open, osFamily } = this.state;
        const canDismiss = !isGigwalker;

        const appStore = (
            <Button key="ios" color="secondary" variant="contained" href={GIGWALK_APP_STORE_LINK} target="_blank" rel="noopener noreferrer">
                {osFamily === 'other' ? t('splashScreen.ios') : t('splashScreen.downloadNow')}
            </Button>
        );

        const googlePlay = (
            <Button key="android" color="secondary" variant="contained" href={GIGWALK_GOOGLE_PLAY_LINK} target="_blank" rel="noopener noreferrer">
                {osFamily === 'other' ? t('splashScreen.android') : t('splashScreen.downloadNow')}
            </Button>
        );

        const downloadButtons = [];
        switch (osFamily) {
            case 'android':
                downloadButtons.push(googlePlay);
                break;
            case 'ios':
                downloadButtons.push(appStore);
                break;
            default:
                downloadButtons.push(appStore, googlePlay);
                break;
        }

        return (
            <Dialog
              classes={{ paper: classes.dialogPaper }}
              fullScreen
              open={open}
              onClose={this.handleDismiss}
            >
                <img
                  className={classes.screenshot}
                  src={`/public/images/screenshots/${osFamily === 'other' ? 'ios' : osFamily}.png`}
                  alt={osFamily}
                />
                <h2 className={classes.title}>
                    {isGigwalker
                        ? t(`splashScreen.gigwalker.${osFamily}.title`)
                        : t(`splashScreen.worker.${osFamily}.title`)
                    }
                </h2>
                <p className={classes.description}>
                    {isGigwalker
                        ? t(`splashScreen.gigwalker.${osFamily}.description`)
                        : t(`splashScreen.worker.${osFamily}.description`)
                    }
                </p>
                <div className={classes.downloadApp}>{downloadButtons}</div>
                {canDismiss
                    ? (
                        <a className={classes.continueToWeb} onClick={this.handleDismiss}>
                            {t('splashScreen.continueToWeb')}<Icon className={classes.continueIcon}>arrow_forward</Icon>
                        </a>
                    )
                    : null
                }
            </Dialog>
        );
    }
}


const mapStateToProps = (state: RootState): StateProps => ({
    isGigwalker: session.selectors.isGigwalker(state),
    isWorker: state.session.user ? state.session.user.role === USER_ROLES.WORKER : false,
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps);
const enhance = compose(
    withStyles(styles, { name: 'SplashScreen' }),
    withTranslation(),
    connector,
);
export default enhance(SplashScreen);
