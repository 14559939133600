var Handlebars = require('/tmp/build_c41768ab/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "has-metadata";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<strong>Q"
    + container.escapeExpression(((helper = (helper = helpers.questionNumber || (depth0 != null ? depth0.questionNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"questionNumber","hash":{},"data":data}) : helper)))
    + "</strong>";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <div class=\"files\">\n                    <!-- div \"attachment\" uses the same HTML as in Project Builder -->\n                    <div class=\"attachment\">\n                        <div class=\"plainLabel\">"
    + container.escapeExpression(((helper = (helper = helpers.attached_files || (depth0 != null ? depth0.attached_files : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"attached_files","hash":{},"data":data}) : helper)))
    + "</div>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.files : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\n                    <!-- ends div \"attachment\" -->\n                </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                            <div class=\"attached-file\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                                <a href=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" class=\"filename\" data-action=\"download\"><span class=\"im icon-paperclip\"></span>"
    + alias4(((helper = (helper = helpers.file_name || (depth0 != null ? depth0.file_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"file_name","hash":{},"data":data}) : helper)))
    + "</a>\n                                <a href=\"javascript:;\" class=\"remove\" data-action=\"remove-attachment\">"
    + alias4(((helper = (helper = helpers.remove || (depth0 != null ? depth0.remove : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"remove","hash":{},"data":data}) : helper)))
    + "</a>\n                            </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"images\" data-target=\"attachments\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.images : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                        <div class=\"attachment image\">\n                            <img src=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\"/>\n                            <span class=\"im icon-magnify\" data-url=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" data-action=\"magnify\"></span>\n                        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"instruction required task widget-panel edit-mode\">\n    <!-- widget header -->\n    <div class=\"header\" style=\"position: relative\">\n        <div class=\"controls\">\n            <div class=\"ti type\">\n                <span data-target=\"isRequired\" class=\"optional\"></span>\n                <span class=\"type\">"
    + alias4(((helper = (helper = helpers.task || (depth0 != null ? depth0.task : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"task","hash":{},"data":data}) : helper)))
    + "</span>\n            </div>\n            <div class=\"arrow\" data-action=\"collapse\"><span class=\"im icon-arrow-up\"></span></div>\n            <div class=\"map\">\n                <span class=\"im icon-map-pin\" data-action=\"viewLatLon\" style=\"display:none\"></span>\n            </div>\n            <div class=\"dragger\"><span class=\"im icon-empty\"></span></div>\n        </div>\n        <div class=\"actions\">\n            <div class=\"ti target "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasMetadata : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-action=\"view-metadata\">\n                "
    + alias4((helpers.dynamicTarget || (depth0 && depth0.dynamicTarget) || alias2).call(alias1,(depth0 != null ? depth0.target : depth0),{"name":"dynamicTarget","hash":{},"data":data}))
    + "\n                <div class=\"metadata popup\" data-target=\"target-metadata\" style=\"display:none;\"></div>\n            </div>\n            <div class=\"viewing icon-buttons\">\n                <a class=\"im icon-edit\" href=\"javascript:;\"></a>\n            </div>\n        </div>\n\n    </div>\n\n    <!-- widget content -->\n    <div class=\"widget-panel-content\" data-target=\"body\">\n\n        <div class=\"inner\">\n            <div class=\"ti\">\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.questionNumber : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                <span>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span>\n            </div>\n            <div class=\"description\"></div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.files : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            <!-- images added to the step task -->\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.images : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            <!-- ------------------- STEPS ------------------------ -->\n\n            <div data-target=\"steps\">\n                <!-- ItemGrid.hbs -->\n                <!-- Instruction.hbs -->\n                <!-- Describe.hbs -->\n                <!-- Photo.hbs -->\n                <!-- ../Barcode.hbs -->\n                <!-- Number.hbs -->\n                <!-- Date.hbs -->\n                <!-- Time.hbs -->\n                <!-- Currency.hbs -->\n            </div>\n\n        </div>\n        <!-- END inner -->\n\n        <!-- main action button -->\n        <a href=\"javascript:;\" class=\"answerButton\" data-action=\"next\">"
    + alias4(((helper = (helper = helpers.next || (depth0 != null ? depth0.next : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"next","hash":{},"data":data}) : helper)))
    + "</a>\n        <a href=\"javascript:;\" class=\"answerButton disabled\" style=\"display:none\" data-action=\"save\"><span class=\"glyphicon glyphicon-refresh loading-xsm\" data-buffering=\"dataItem\"></span>"
    + alias4(((helper = (helper = helpers.save || (depth0 != null ? depth0.save : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"save","hash":{},"data":data}) : helper)))
    + "</a>\n\n    </div>\n    <!-- END widget content -->\n</div>\n";
},"useData":true});