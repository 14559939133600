// @flow
export default (theme: Object) => ({
    popoverPaper: {
        padding: 12,
        height: 250,
        width: 350,
    },

    filterContainer: {
        alignItems: 'baseline',
        flexDirection: 'row-reverse',
        marginLeft: 0,
        marginRight: 0,
    },

    filterLabel: {
        marginRight: theme.spacing(1),
        fontFamily: ['ProximaNova', 'sans-serif'],
        fontSize: 16,
        whiteSpace: 'nowrap',
    },

    selectRoot: {
        fontFamily: ['ProximaNova', 'sans-serif'],
        fontSize: 16,
        fontWeight: 500,
    },

    select: {
        '-moz-appearance': 'none', // Reset
        '-webkit-appearance': 'none', // Reset
        // When interacting quickly, the text can end up selected.
        // Native select can't be selected either.
        userSelect: 'none',
        paddingRight: theme.spacing(4),
        width: `calc(100% - ${theme.spacing(4)})`,
        minWidth: theme.spacing(2), // So it doesn't collapse.
        cursor: 'pointer',
        whiteSpace: 'nowrap',

        '&:focus': {
            // Show that it's not an text input
            background: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.05)',
            borderRadius: 0, // Reset Chrome style
        },

        // Remove Firefox focus border
        '&:-moz-focusring': {
            color: 'transparent',
            textShadow: '0 0 0 #000',
        },

        // Remove IE11 arrow
        '&::-ms-expand': {
            display: 'none',
        },
    },

    // selectMenu: {
    //     height: '100%',
    //     width: 'auto', // Fix Safari textOverflow
    //     textOverflow: 'ellipsis',
    //     whiteSpace: 'nowrap',
    //     overflow: 'hidden',
    //     minHeight: '1.1875em', // Reset (19px), match the native input line-height
    // },

    disabled: {
        cursor: 'default',
    },

    icon: {
        // We use a position absolute over a flexbox in order to forward the pointer events
        // to the input.
        position: 'absolute',
        right: 0,
        top: 'calc(50% - 12px)', // Center vertically
        color: theme.palette.action.active,
        'pointer-events': 'none', // Don't block pointer events on the select under the icon.
    },
});
