// @flow
export default () => ({
    actions: {
        justifyContent: 'flex-end',
    },
    avatar: {
        marginBottom: 'auto',
        marginTop: 6,
    },
    header: {
        fontFamily: ['ProximaNova', 'sans-serif'],
        fontSize: 15,
    },
    content: {
        fontFamily: ['ProximaNova', 'sans-serif'],
        fontSize: 16,
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});
