// @flow
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { ticket as ticketSchema } from '../../../redux/entities/schemas';
import type { State as RootState } from '../../../redux/initialState';

const getEntities = (state: RootState): Object => state.entities;
const getTicketIds = (state: RootState, props: Object): number[] => props.ticketIds || [];

export const getReschedulableTicketIds = createSelector(
    getEntities,
    getTicketIds,
    (entities: Object, ticketIds: number[]): number[] => {
        const tickets = denormalize(ticketIds, [ticketSchema], entities);
        return tickets.reduce((ids: number[], ticket: ?Object) => (
            ticket && !ticket.can_reschedule ? [...ids, ticket.id] : ids
        ), []);
    }
);

export default {
    getReschedulableTicketIds,
};
