// @flow
import isEqual from 'lodash.isequal';
import identity from 'lodash.identity';
import dotProp from 'dot-prop';
import required from './required';

/**
 * Given an array A and (optionally) an iteratee F, returns a function that will validate the
 * uniqueness of a value in A, using F to generate the criterion by which uniqueness is computed.
 * If no array is given, value will always be unique. Additionally, you can specify a field name
 * instead of an array for use with redux-form
 * @param fieldOrArray {(string | Array)}
 * @returns {Function}
 */
export default (fieldOrArray?: string | any[], iteratee?: (element: any) => boolean = identity): Function => (
    (value: any, allValues: Object): ?string | ?Object => {
        const requiredError = required(value);
        if (requiredError) return;

        let array;
        if (typeof fieldOrArray === 'undefined') {
            array = [];
        } else if (typeof fieldOrArray === 'string') {
            const key = fieldOrArray.replace(/\[/g, '.').replace(/]/g, '');
            const field = dotProp.get(allValues, key);
            array = Array.isArray(field) ? field : [];
        } else {
            array = fieldOrArray;
        }

        const uniqueness = array.filter((element: any) => isEqual(value, iteratee(element)));
        if (uniqueness.length > 1) {
            return 'notUnique';
        }
    }
);
