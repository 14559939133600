var Handlebars = require('/tmp/build_c41768ab/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<table>\n<tr>\n<td>\n	<div class=\"inner\">\n		<div data-target=\"buttons\" class=\"buttons\">\n			<div class=\"button\" data-action=\"filePicker\">\n				<span class=\"im icon-file\"></span>\n				<a href=\"javascript:;\">"
    + alias4(((helper = (helper = helpers.file_picker || (depth0 != null ? depth0.file_picker : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"file_picker","hash":{},"data":data}) : helper)))
    + "</a>\n			</div>\n			<div class=\"button\" data-action=\"takePhoto\">\n				<span class=\"im icon-camera\"></span>\n				<a href=\"javascript:;\">"
    + alias4(((helper = (helper = helpers.camera || (depth0 != null ? depth0.camera : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"camera","hash":{},"data":data}) : helper)))
    + "</a>\n			</div>\n		</div>\n		<div data-target=\"preview\" class=\"preview\">\n			<div data-target=\"video\" id=\"webcam-video-stream\"></div>\n			<a href=\"javascript:;\" data-target=\"video\" data-action=\"getStill\"><span class=\"im icon-camera linkly\"></span></a>\n            <div data-target=\"attachment\"></div>\n			<div data-buffering class=\"buffering-outer\">\n				<div><div class=\"loading-xsm black\"><span class=\"glyphicon glyphicon-refresh\"></span></div></div>\n				<a href=\"javascript:;\" data-action=\"cancel\">"
    + alias4(((helper = (helper = helpers.cancel || (depth0 != null ? depth0.cancel : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cancel","hash":{},"data":data}) : helper)))
    + "</a>\n			</div>\n		</div>\n		<div class=\"error\" data-target=\"errors\"></div>\n		<div class=\"controls\">\n			<a href=\"javascript:;\" class=\"btn cancel\" data-action=\"close\">"
    + alias4(((helper = (helper = helpers.cancel || (depth0 != null ? depth0.cancel : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cancel","hash":{},"data":data}) : helper)))
    + "</a>\n			<a href=\"javascript:;\" class=\"btn primary disabled\" data-action=\"save\">"
    + alias4(((helper = (helper = helpers.save || (depth0 != null ? depth0.save : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"save","hash":{},"data":data}) : helper)))
    + "</a>\n		</div>\n	</div>\n</td>\n</tr>\n</table>\n";
},"useData":true});