// @flow
import React, { Component } from 'react';
import qs from 'qs';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { IconButton, Toolbar, Tooltip } from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter } from 'react-router';
import { search as searchOrganizations } from '../../../../redux/entities/organizations';
import FilterSelect from '../../../../components/FilterSelect';
import { selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';
import { USER_ROLES } from '../../../../../browser/shared/constant/UserRoles';

type OwnProps = ContextRouter & {
    className?: string,
    classes: Object,
    t: TFunction,
};
type StateProps = {
    organizationFilterValue: Object[],
    search: {
        organization?: string[],
    },
    user: ?Object,
};
type DispatchProps = {
    searchOrganizations: (params: Object) => Promise<Object>,
};
type Props = OwnProps & StateProps & DispatchProps;

const noFilters = {
    organization: undefined,
};

export class FilterToolbar extends Component<Props> {
    loadOrganizationOptions = (inputValue: string) => {
        const params = {
            q: inputValue ? `*${inputValue}*` : null,
            from: 0,
            size: 20,
            sort_field: 'organization_name',
            sort_order: 'asc',
        };

        // Use searchOrganizations to ensure entities are stored in state
        return this.props.searchOrganizations(params) // eslint-disable-line react/destructuring-assignment
            .then((resp) => {
                // @todo: Consider using entitySelector to get denormalized entity...
                const { entities, result } = resp;
                return result.map((id: number) => ({
                    label: entities.organizations[id].organization_name,
                    value: entities.organizations[id],
                }));
            });
    };

    handleOrganizationFilterChange = (value: Object[]) => {
        const organizationFilters = value.map((option: Object) => option.value.id);
        this.updateFilters({ organization: organizationFilters });
    };

    handleClear = () => {
        this.updateFilters(noFilters);
    };

    updateFilters(filters: Object) {
        const { location, history, search } = this.props;

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                { ...search, ...filters },
                { addQueryPrefix: true, encodeValuesOnly: true },
            ),
        });
    }

    renderSelectedCount = (value: Object[]) => {
        const { t } = this.props;
        let count = 0;

        if (value != null) {
            count = Array.isArray(value) ? value.length : 1;
        }

        return count
            ? (
                <Trans
                  defaults={t('subscriptionList.filters.selectedCount', { count })}
                  components={[<strong style={{ fontWeight: 600 }}>count</strong>, 'selected']}
                />
            )
            : t('subscriptionList.filters.any');
    };

    render() {
        const { className, classes, organizationFilterValue, t, user } = this.props;

        // Since we currently only have an organization filter, we shouldn't rendering anything
        // if the user is not a PLATFORM_ADMIN
        if (!user || user.role !== USER_ROLES.PLATFORM_ADMIN) {
            return null;
        }

        return (
            <Toolbar className={cx(classes.root, className)}>
                <FilterSelect
                  label={`${t('subscriptionList.filters.organization')}:`}
                  loadOptions={this.loadOrganizationOptions}
                  value={organizationFilterValue}
                  renderValue={this.renderSelectedCount}
                  onChange={this.handleOrganizationFilterChange}
                  defaultOptions
                  isMulti
                />
                <Tooltip title={t('subscriptionList.filters.clearFilters')}>
                    <IconButton className={classes.clearButton} onClick={this.handleClear}>
                        <ClearIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    ...selectors.getAPIParams(state, props),
    organizationFilterValue: selectors.getOrganizationFilterValue(state, props),
    search: selectors.parseSearchParams(state, props),
    user: state.session.user,
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    searchOrganizations: (params: Object) => dispatch(searchOrganizations(params)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'FilterToolbar' }),
    withTranslation(),
    connector,
);

export default enhance(FilterToolbar);
