// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Component, Fragment, createRef } from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import {
    FormControlLabel,
    Input,
    ListItemText,
    Popover,
} from '@material-ui/core';
import {
    ArrowDropDown as ArrowDropDownIcon,
    Check as CheckIcon,
    Search as SearchIcon,
} from '@material-ui/icons';
import type { Node } from 'react';
import highlightMatch from '../../util/highlightMatch';
import Select from '../Select';
import styles from './styles';

type Props = {
    autoFocus?: boolean,
    classes: Object,
    className?: string,
    disabled?: boolean,
    formatOptionLabel?: (option: Object, meta: Object) => Node,
    label?: string,
    onChange?: (data: Object) => void,
    onFocus?: () => void,
    onBlur?: () => void,
    options?: Object[],
    placeholder?: string,
    renderValue?: (value: Object | Object[] | null | void) => React$Element<any>,
    suggestionMatch?: string | Function,
    value?: Object | Object[] | null | void,
};

type State = {
    open: boolean,
};

const defaultFormatOptionLabel = (option: Object, meta: Object) => (
    <Fragment>
        <ListItemText primary={highlightMatch(option.label, meta.inputValue)} />
        {meta.isSelected ? <CheckIcon /> : null}
    </Fragment>
);

class FilterSelect extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { open: false };
        // $FlowIssue need to update to more recent flow version
        this.anchorRef = createRef();
    }

    handleSelectInputClick = () => {
        this.setState({ open: true });
    };

    handlePopoverClose = () => {
        this.setState({ open: false });
    };

    renderSelectInput = (props: Object) => {
        const { classes, renderValue, value } = this.props;
        const { className } = props;

        return (
            <div>
                <div className={cx(classes.select, className)}>
                    {renderValue ? renderValue(value) : 'Any'}
                </div>
                <input type="hidden" value="" />
                <ArrowDropDownIcon className={classes.icon} />
            </div>
        );
    };

    render() {
        const { open } = this.state;
        const { classes, className, formatOptionLabel, label, ...other } = this.props;

        return (
            <Fragment>
                {/* $FlowIssue need to update to more recent flow version */}
                <div ref={this.anchorRef}>
                    <FormControlLabel
                      classes={{
                          root: classes.filterContainer,
                          label: classes.filterLabel,
                      }}
                      control={(
                          <Input
                            className={classes.selectRoot}
                            disableUnderline
                            inputComponent={this.renderSelectInput}
                          />
                      )}
                      label={label}
                      onClick={this.handleSelectInputClick}
                    />
                </div>
                <Popover
                  classes={{
                      paper: classes.popoverPaper,
                  }}
                  open={open}
                  onClose={this.handlePopoverClose}
                  // $FlowIssue need to update to more recent flow version
                  anchorEl={this.anchorRef.current}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    <Select
                      {...other}
                      className={cx(classes.selectMenu, className)}
                      controlShouldRenderValue={false}
                      dropdownIcon={<SearchIcon />}
                      isClearable={false}
                      menuIsOpen
                      menuType="list"
                      formatOptionLabel={formatOptionLabel || defaultFormatOptionLabel}
                    />
                </Popover>
            </Fragment>
        );
    }
}

export default withStyles(styles, { name: 'FilterSelect' })(FilterSelect);
