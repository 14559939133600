// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Component, createRef } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { withStyles } from '@material-ui/styles';
import { Popover } from '@material-ui/core';
import type { Node } from 'react';
import DateInput from './DateInput';
import DayPicker from './DayPicker';
import styles from './styles';

type Props = {
    className?: string,
    classes: Object,
    customInputIcon: Node,
    disabled?: boolean,
    error?: string,
    format: string,
    isOutsideRange: (day: moment$Moment) => boolean,
    label?: string,
    minDate?: string | moment$Moment | Date, // Not implemented
    maxDate?: string | moment$Moment | Date, // Not implemented
    // mode?: 'date' | 'time' | 'datetime',
    onBlur?: () => void,
    onChange?: (value: string) => void,
    onFocus?: () => void,
    placeholder?: string,
    readOnly?: boolean,
    value?: string | moment$Moment | Date,
};

type State = {
    open: boolean,
};

export class DateTimePicker extends Component<Props, State> {
    static defaultProps = {
        format: 'YYYY-MM-DDTHH:mm',
        isOutsideRange: (day: moment$Moment) => moment(day).isBefore(moment().startOf('day')),
        // mode: 'datetime',
    };

    constructor(props: Props) {
        super(props);
        this.state = { open: false };
        // $FlowIssue need to update to more recent flow version
        this.anchorRef = createRef();
    }

    handleChange = (value: string) => {
        const { onChange } = this.props;
        if (typeof onChange === 'function') {
            onChange(value);
        }
    };

    handleFocus = () => {
        this.setState({ open: true }, () => {
            const { onFocus } = this.props;
            if (typeof onFocus === 'function') {
                onFocus();
            }
        });
    };

    handleBlur = () => {
        this.setState({ open: false }, () => {
            const { onBlur } = this.props;
            if (typeof onBlur === 'function') {
                onBlur();
            }
        });
    };

    registerActions = (actions: Object) => {
        this.actions = actions;
    };

    updatePosition = () => {
        const { actions } = this;
        if (actions && actions.updatePosition) {
            actions.updatePosition();
        }
    };

    actions: ?Object;

    render() {
        const { open } = this.state;
        const { className, classes, customInputIcon, disabled, error, label, placeholder, value, ...dayPickerProps } = this.props;
        const inputProps = { error, customInputIcon, disabled, label, value };

        return (
            <div className={cx(className, classes.root)}>
                {/* $FlowIssue need to update to more recent flow version */}
                <div ref={this.anchorRef}>
                    <DateInput
                      {...inputProps}
                      className={classes.dateInput}
                      placeholder={placeholder || ''}
                      onFocus={this.handleFocus}
                    />
                </div>
                <Popover
                  classes={{ paper: classes.popoverPaper }}
                  open={open}
                  onClose={this.handleBlur}
                  // $FlowIssue need to update to more recent flow version
                  anchorEl={this.anchorRef.current}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  disableRestoreFocus
                  action={this.registerActions}
                >
                    <DayPicker
                      {...dayPickerProps}
                      value={value}
                      focused={open}
                      disabled={disabled}
                      onBlur={this.handleBlur}
                      onChange={this.handleChange}
                      updatePosition={this.updatePosition}
                      enableTime
                    />
                </Popover>
            </div>
        );
    }
}

export default withStyles(styles, { name: 'DateTimePicker' })(DateTimePicker);
