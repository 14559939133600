// @flow
import { createSelector, createStructuredSelector } from 'reselect';
import { selectors as projectBuilder } from '../../../duck';

// type FormValues = {
//     title: string,
//     description: string,
//     scheduleType: string,
//     asapEndDate: string,
//     rangeStartDate: string,
//     rangeEndDate: string,
// };

export const getInitialValues = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    projectBuilder.getInitialValues,
    createStructuredSelector({
        title: (values: Object) => values.title,
        description: (values: Object) => values.description,
        scheduleType: (values: Object) => values.scheduleType,
        asapEndDate: (values: Object) => values.asapEndDate,
        rangeStartDate: (values: Object) => values.rangeStartDate,
        rangeEndDate: (values: Object) => values.rangeEndDate,
    })
);

export default {
    getInitialValues,
};
