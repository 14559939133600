// @flow
import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import type { ChildrenArray } from 'react';
import theme from './MarketingHero.scss';

type Props = {
    children: ChildrenArray<any>,
};

export function MarketingHero(props: Props) {
    const { children } = props;
    const { t } = useTranslation();

    return (
        <div className={theme.siteWrapper}>
            <div
              className={cx(theme.hero, theme.fullWrapper)}
              style={{ backgroundImage: 'url(/public/img/marketing/gigwalk_hero_barista_web.jpg)' }}
            >
                <div className={cx(theme.contentWrapper, theme.inlineGrid)}>
                    <div className={theme.col_1_2}>
                        <div className={theme.formWrapper}>
                            {children}
                        </div>
                    </div>
                    <div className={theme.col_1_2}>
                        <h1 className={theme.header}>{t('signup.marketing.header')}</h1>
                    </div>
                </div>
                <div className={theme.overlay} />
            </div>

            <div className={cx(theme.fullWrapper, theme.bgGray)}>
                <div className={theme.contentWrapper}>
                    <div className={cx(theme.grid, theme.copySection)}>
                        <div className={cx(theme.paragraphText, theme.col_1_2)}>
                            <div>
                                <p style={{ fontSize: '18px' }}>
                                    {t('signup.marketing.gigwalkCloudDescription')}&nbsp;
                                </p>
                            </div>
                            <ul>
                                <li>
                                    <strong>{t('signup.marketing.bullet1.header')}:</strong>&nbsp;
                                    {t('signup.marketing.bullet1.description')}&nbsp;
                                </li>
                                <li>
                                    <strong>{t('signup.marketing.bullet2.header')}:</strong>&nbsp;
                                    {t('signup.marketing.bullet2.description')}&nbsp;
                                </li>
                                <li>
                                    <strong>{t('signup.marketing.bullet3.header')}:</strong>&nbsp;
                                    {t('signup.marketing.bullet3.description')}&nbsp;
                                </li>
                            </ul>
                            <div style={{ textAlign: 'center' }}>
                                <br />
                                <span style={{ fontSize: '18px' }}>
                                    <em><strong>{t('signup.marketing.tagline')}&nbsp;</strong></em>
                                </span>
                            </div>
                        </div>
                        <div className={theme.col_1_2}>
                            <div>
                                <img alt="Gigwalk App" src="/public/img/marketing/two_phones_overlay.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={cx(theme.contentWrapper, theme.testimonial)}>
                <div className={theme.flexViewport} style={{ overflow: 'hidden', position: 'relative' }}>
                    <ul style={{ width: '800%', transitionDuration: '0s', transform: 'translate3d(-1053px, 0px, 0px)' }} />
                    <p className={theme.quote} style={{ textAlign: 'center' }}>{t('signup.marketing.quote')}</p>
                    <p className={theme.name} style={{ textAlign: 'center' }}>{t('signup.marketing.byline')}</p>
                    <p className={theme.name} style={{ textAlign: 'center' }}>
                        <img
                          alt=""
                          height="62"
                          src="/public/img/marketing/Whirlpool_Corporation_logo.svg.png"
                          style={{ width: '150px', height: '62px', borderWidth: '0px', borderStyle: 'solid' }}
                          width="150"
                        />
                    </p>
                </div>
            </div>
        </div>
    );
}

export default MarketingHero;
