var Handlebars = require('/tmp/build_c41768ab/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <div class=\"answer "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.currency : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n        <input type=\"number\" data-input value=\""
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\" name=\""
    + alias4(((helper = (helper = helpers._answerID || (depth0 != null ? depth0._answerID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_answerID","hash":{},"data":data}) : helper)))
    + "target2 answer number\" placeholder=\""
    + alias4(container.lambda(((stack1 = (depths[1] != null ? depths[1].placeholders : depths[1])) != null ? stack1.ex_7 : stack1), depth0))
    + "\" />\n      </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " currency-input";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<!-- question type is Numeric -->\n<div class=\"type numeric\">\n  <div class=\"answers\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.answers : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n</div><!-- END Numeric -->\n<div class=\"validation error\" data-target=\"validationHint\">\n  <!--\n    \"Must be a number\"\n    \"Must be at least 2 characters long\"\n  -->\n</div>\n";
},"useData":true,"useDepths":true});