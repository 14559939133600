// @flow
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import pick from 'lodash.pick';
import type { ContextRouter } from 'react-router';
import type { Certification } from 'gigwalk/lib/api/certifications/types';
import type { Group } from 'gigwalk/lib/api/groups/types';
import { subscription as subscriptionSchema } from '../../../../../../common/redux/entities/schemas';
import type { State as RootState } from '../../../../../../common/redux/initialState';

const subscriptionFields = [
    'min_gig_price',
    'dashboard_visible',
    'project_fund',
    'groups',
    'worker_count',
    'tickets_overage',
    'can_reschedule',
    'recurrence',
    'autoassign',
    'schedule_type',
    'ticket_time_estimate',
    'organization_location_lists',
    'near_ticket_distance',
    'bundle_autoassign',
    'needs_approval',
    'tickets_target',
    'certifications',
    'location_override',
    'needs_public_workforce',
    'optin_type',
    'two_way_rating_enabled',
    'needs_core',
];

const getEntities = (state: RootState): Object => state.entities;
const getSubscriptionId = <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): number => {
    const { match } = props;
    return parseInt(match.params.subscriptionId, 10);
};

export const getSubscription = createSelector(
    getEntities,
    getSubscriptionId,
    (entities: Object, subscriptionId: number) => denormalize(subscriptionId, subscriptionSchema, entities),
);

export const getProjectInfo = createSelector(
    getSubscription,
    (subscription) => {
        if (!subscription) {
            return;
        }

        const data = pick(subscription, subscriptionFields);

        const certifications = data.certifications
            .filter((certification: ?Certification) => !!certification)
            .map((certification: Certification) => ({
                description: certification.description,
                id: certification.id,
                title: certification.title,
                user_count: certification.user_count,
            }));

        const groups = data.groups
            .filter((group: ?Group) => !!group)
            .map((group: Group) => ({
                id: group.id,
                member_count: group.member_count,
                name: group.name,
            }));

        const locationLists = data.organization_location_lists
            .filter((locationList: ?Object) => !!locationList)
            .map((locationList: Object) => ({
                id: locationList.id,
                location_count: locationList.location_count,
                name: locationList.name,
            }));

        return {
            ...data,
            certifications,
            groups,
            organization_location_lists: locationLists,
        };
    }
);

export default {
    getProjectInfo,
    getSubscription,
};
