// @flow
export const CHECK_NOTIFICATIONS = 'g/header/CHECK_NOTIFICATIONS';
export const CHECK_NOTIFICATIONS_SUCCESS = `${CHECK_NOTIFICATIONS}_SUCCESS`;
export const CHECK_NOTIFICATIONS_ERROR = `${CHECK_NOTIFICATIONS}_ERROR`;

export const MARK_AS_READ = 'g/header/MARK_AS_READ';
export const MARK_AS_READ_SUCCESS = `${MARK_AS_READ}_SUCCESS`;
export const MARK_AS_READ_ERROR = `${MARK_AS_READ}_ERROR`;

export default {
    CHECK_NOTIFICATIONS,
    CHECK_NOTIFICATIONS_ERROR,
    CHECK_NOTIFICATIONS_SUCCESS,
    MARK_AS_READ,
    MARK_AS_READ_ERROR,
    MARK_AS_READ_SUCCESS,
};
