// @flow
import React, { Component } from 'react';
import cx from 'classnames';
import { Redirect, Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { destroy, isSubmitting, isValid, submit } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import type { ContextRouter, LocationShape } from 'react-router';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import { getBalance } from '../../redux/entities/organizations';
import * as session from '../../ducks/session';
import { format } from '../../../browser/shared/util/gigwalkApiErrorUtil';
import ProgressTracker from './containers/ProgressTracker';
import BasicInfo from './containers/BasicInfo';
import Locations from './containers/Locations';
import People from './containers/People';
import Questions from './containers/Questions';
import Launch from './containers/Launch';
import * as snackbar from '../../ducks/snackbar';
import { actions, selectors } from './duck';
import entitySelector from '../../redux/entities/entitySelector';
import styles from './styles';
import type { State as RootState } from '../../redux/initialState';

type State = {
    loading: boolean,
}

type OwnProps = {
    ...ContextRouter,
    classes: Object,
    t: TFunction,
};

type StateProps = {
    canLaunchPublicProject: boolean,
    editMode: boolean,
    isLocationListUploadVisible: boolean,
    nextPage: ?LocationShape,
    prevPage: ?LocationShape,
    readyToLaunch: boolean,
    submitting: boolean,
    valid: boolean,
};

type DispatchProps = {
    createProject: (orgId: number) => Promise<number>,
    destroy: (form: string) => void,
    enqueueSnackbar: (message: string, options: Object) => void,
    getBalance: (params: { organization_id: number }) => Promise<Object>,
    launchProject: (subscriptionId: number) => Promise<void>,
    loadProject: (subscriptionId: number, orgId: number) => Promise<void>,
    requestReview: (subscriptionId: number, orgId: number) => Promise<void>,
    resetState: () => void,
    saveProject: (subscriptionId: number) => Promise<void>,
    submit: (form: string) => void,
};

type Props = OwnProps & StateProps & DispatchProps;

export class ProjectBuilder extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { match } = this.props;
        const subscriptionId = parseInt(match.params.subscriptionId, 10);

        this.state = {
            loading: subscriptionId > 0,
        };
    }

    componentDidMount() {
        const { loadProject, match } = this.props;
        const orgId = parseInt(match.params.orgId, 10);

        if (match.params.subscriptionId === 'new') {
            this.props.getBalance({ organization_id: orgId }); // eslint-disable-line react/destructuring-assignment
        } else {
            const subscriptionId = parseInt(match.params.subscriptionId, 10);
            if (subscriptionId > 0) {
                loadProject(subscriptionId, orgId)
                    .then(() => { this.setState({ loading: false }); });
            }
        }
    }

    componentWillUnmount() {
        const { destroy: destroyForm, resetState } = this.props;
        destroyForm('projectBuilder');
        resetState();
    }

    getMatchParams() {
        const { match } = this.props;
        return { orgId: '0', subscriptionId: '0', ...match.params };
    }

    saveProject = (values: Object): Promise<any> => {
        const {
            canLaunchPublicProject,
            createProject,
            editMode,
            enqueueSnackbar,
            history,
            launchProject,
            location,
            nextPage,
            requestReview,
            saveProject,
            t,
        } = this.props;
        const { needsPublicWorkforce } = values;
        const matchParams = this.getMatchParams();
        const orgId = parseInt(matchParams.orgId, 10);

        // If this a 'new' draft, we'll have to update projectId after the subscription is created
        let projectId = parseInt(matchParams.subscriptionId, 10);

        // Create a promise that we can use to chain API calls and other actions
        let promise = Promise.resolve();

        if (editMode && nextPage) {
            promise = promise.then(() => {
                history.push(nextPage);
            });
        } else {
            if (matchParams.subscriptionId === 'new') {
                promise = promise
                    .then(() => createProject(orgId))
                    .then((id: number) => {
                        projectId = id;
                        history.replace(location.pathname.replace('new', `${projectId}`));
                    });
            }

            promise = promise.then(() => saveProject(projectId));

            if (!nextPage) {
                if (needsPublicWorkforce && !canLaunchPublicProject) {
                    promise = promise
                        .then(() => requestReview(projectId, orgId))
                        .then(() => {
                            const message = t('projectBuilder.launch.reviewConfirmation');
                            enqueueSnackbar(message, { variant: 'success' });
                            history.push(`/projects/${orgId}/active/list`);
                        });
                } else {
                    // Only launch the project if it's in the DRAFT state. Changes to ACTIVE projects
                    // propagate to the ticket level immediately
                    promise = promise
                        .then(() => (!editMode ? launchProject(projectId) : null))
                        .then(() => {
                            history.push(`/projects/${orgId}/active/${projectId}`);
                        });
                }
            } else {
                promise = promise
                    .then(() => {
                        const nextPath = nextPage.pathname || '';
                        history.push({
                            ...nextPage,
                            pathname: nextPath.replace('new', `${projectId}`),
                        });
                    });
            }

            promise = promise
                .catch((err: $AxiosError<any>) => {
                    const resp = err ? err.response : null;
                    if (resp && resp.data && resp.data.gw_api_response) {
                        const message = format(resp.data.gw_api_response);
                        enqueueSnackbar(message, { variant: 'error' });
                    }
                });
        }

        return promise;
    };

    handleNext = () => {
        const { submit: submitForm } = this.props;
        submitForm('projectBuilder');
    };

    handlePrev = () => {
        const { history, prevPage } = this.props;
        if (prevPage) {
            history.push(prevPage);
        }
    };

    renderBackButton() {
        const { prevPage, t } = this.props;

        if (prevPage) {
            const buttonText = t('projectBuilder.navigation.back');
            return (
                <Button variant="contained" onClick={this.handlePrev}>
                    <KeyboardArrowLeft />
                    {buttonText}
                </Button>
            );
        }
    }

    renderNextButton() {
        const {
            classes,
            editMode,
            isLocationListUploadVisible,
            nextPage,
            readyToLaunch,
            submitting,
            t,
            valid,
        } = this.props;

        const isLaunchPage = !nextPage;
        const invalid = isLaunchPage ? !readyToLaunch || !valid : !valid;

        let buttonText: string;
        if (editMode) {
            buttonText = isLaunchPage
                ? t('projectBuilder.navigation.relaunch')
                : t('projectBuilder.navigation.continue');
        } else {
            buttonText = isLaunchPage
                ? t('projectBuilder.navigation.launch')
                : t('projectBuilder.navigation.saveAndContinue');
        }

        return (
            <Button
              className={classes.nextButton}
              variant="contained"
              color={isLaunchPage ? 'secondary' : 'primary'}
              onClick={this.handleNext}
              disabled={invalid || isLocationListUploadVisible || submitting}
            >
                {submitting ? <CircularProgress size={24} className={classes.progress} /> : null}
                {buttonText}<KeyboardArrowRight />
            </Button>
        );
    }

    render() {
        const { loading } = this.state;
        const { classes, match } = this.props;
        const createPath = match.path.split('/:subscriptionId')[0];

        if (loading) {
            return (
                <div className={cx(classes.projectBuilder)}>
                    <div className={classes.loading}>
                        <CircularProgress />
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.projectBuilder}>
                <Route component={ProgressTracker} />
                <Switch>
                    <Redirect
                      from={`${createPath}/new/(locations|people|data|launch)`}
                      to={`${match.url}/info`}
                    />
                    <Route
                      path={`${match.path}/info`}
                      render={(props: ContextRouter) => <BasicInfo {...props} onSubmit={this.saveProject} />}
                    />
                    <Route
                      path={`${match.path}/locations`}
                      render={(props: ContextRouter) => <Locations {...props} onSubmit={this.saveProject} />}
                    />
                    <Route
                      path={`${match.path}/people`}
                      render={(props: ContextRouter) => <People {...props} onSubmit={this.saveProject} />}
                    />
                    <Route
                      path={`${match.path}/data`}
                      render={(props: ContextRouter) => <Questions {...props} onSubmit={this.saveProject} />}
                    />
                    <Route
                      path={`${match.path}/launch`}
                      render={(props: ContextRouter) => <Launch {...props} onSubmit={this.saveProject} />}
                    />
                    <Redirect to={`${match.url}/info`} />
                </Switch>
                <nav className={classes.navigation}>
                    {this.renderBackButton()}
                    {this.renderNextButton()}
                </nav>
            </div>
        );
    }
}

const subscriptionSelector = entitySelector('subscriptions');
const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const { match } = props;
    const subscription = subscriptionSelector(state, match.params.subscriptionId);
    return {
        canLaunchPublicProject: session.selectors.canLaunchPublicProject(state),
        editMode: subscription ? subscription.state === 'ACTIVE' : false,
        isLocationListUploadVisible: state.projectBuilder.locations.isLocationListUploadVisible,
        nextPage: selectors.getNextPage(state, props),
        prevPage: selectors.getPrevPage(state, props),
        readyToLaunch: selectors.isReadyToLaunch(state),
        submitting: isSubmitting('projectBuilder')(state),
        valid: isValid('projectBuilder')(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    createProject: (orgId: number) => dispatch(actions.createProject(orgId)),
    destroy: (form: string) => dispatch(destroy(form)),
    enqueueSnackbar: (message: string, options: Object) => dispatch(snackbar.actions.enqueue(message, options)),
    getBalance: (params: { organization_id: number }) => dispatch(getBalance(params)),
    launchProject: (subscriptionId: number) => dispatch(actions.launchProject(subscriptionId)),
    loadProject: (subscriptionId: number, orgId: number) => dispatch(actions.loadProject(subscriptionId, orgId)),
    requestReview: (subscriptionId: number, orgId: number) => dispatch(actions.requestReview(subscriptionId, orgId)),
    resetState: () => dispatch(actions.resetState()),
    saveProject: (subscriptionId: number) => dispatch(actions.saveProject(subscriptionId)),
    submit: (form: string) => dispatch(submit(form)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'ProjectBuilder' }),
    withTranslation(),
    connector,
);
export default enhance(ProjectBuilder);
