// @flow
// $FlowIssue need to update to a more recent flow version
import React, { forwardRef } from 'react';
import cx from 'classnames';
import { Icon, Tooltip } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import type { LocationShape } from 'react-router';
import theme from './NavItem.scss';

type Props = {
    href?: string,
    to?: string | LocationShape,
    isActive?: Function,
    className?: string,
    icon: string,
    label: string,
    mini?: boolean,
};

/* eslint-disable jsx-a11y/anchor-has-content, react/no-multi-comp */
const RouterNavLink = forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);
const ExternalLink = forwardRef((props, ref) => <a ref={ref} {...props} />);
/* eslint-enable */

export default function NavItem(props: Props) {
    const { className, href, icon, isActive, label, mini, to } = props;

    const isExternal = typeof href !== 'undefined';
    const Link = isExternal ? ExternalLink : RouterNavLink;

    let linkProps = { className: cx(theme.navItem, className) };
    if (isExternal) {
        linkProps = { ...linkProps, href, target: '_blank' };
    } else {
        linkProps = { ...linkProps, activeClassName: theme.active, isActive, to };
    }

    return mini
        ? (
            <Tooltip title={label} placement="right">
                <Link {...linkProps}>
                    <Icon className={theme.icon}>{icon}</Icon>
                </Link>
            </Tooltip>
        )
        : (
            <Link {...linkProps}>
                <Icon className={theme.icon}>{icon}</Icon>
                <span>{label}</span>
                {isExternal
                    ? (
                        <span className={theme.externalLinkIcon}>
                            <Icon>open_in_new</Icon>
                        </span>
                    )
                    : null
                }
            </Link>
        );
}
