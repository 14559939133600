// @flow
/* eslint-disable max-len */
import React, { Fragment } from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
    <Fragment>
        <path d="M 12.001 21.547 L 6.227 15.354 C 6.068 15.197 5.915 15.032 5.77 14.863 L 5.654 14.739 L 5.666 14.739 C 4.494 13.32 3.79 11.5 3.79 9.516 C 3.79 4.981 7.466 1.305 12.001 1.305 C 16.535 1.305 20.21 4.981 20.21 9.516 C 20.21 11.5 19.507 13.32 18.336 14.739 L 18.347 14.739 L 18.231 14.863 C 18.085 15.032 17.932 15.197 17.773 15.354 Z" />
        <ellipse cx="12.001" cy="21.686" rx="4.439" ry="1.314" style={{ fill: '#000000', fillOpacity: 0.3 }} />
        <path d="M 291 242 m -7 0 a 7 7 0 1 0 14 0 a 7 7 0 1 0 -14 0 Z M 291 242 m -6.6 0 a 6.6 6.6 0 0 1 13.2 0 a 6.6 6.6 0 0 1 -13.2 0 Z" transform="matrix(-0.707107, 0.707107, -0.707107, -0.707107, 388.887665, -25.13213)" style={{ fill: '#ffffff' }} />
        <path d="M 13.062 6.705 C 13.52 6.705 13.896 6.331 13.896 5.874 C 13.896 5.416 13.52 5.04 13.062 5.04 C 12.604 5.04 12.229 5.416 12.229 5.874 C 12.229 6.331 12.604 6.705 13.062 6.705 Z M 11.563 12.493 L 11.98 10.661 L 12.854 11.495 L 12.854 13.993 L 13.687 13.993 L 13.687 10.871 L 12.812 10.037 L 13.062 8.789 C 13.603 9.412 14.436 9.829 15.352 9.829 L 15.352 8.995 C 14.561 8.995 13.896 8.58 13.562 7.998 L 13.146 7.33 C 12.98 7.081 12.729 6.914 12.438 6.914 C 12.313 6.914 12.229 6.956 12.104 6.956 L 9.939 7.872 L 9.939 9.829 L 10.772 9.829 L 10.772 8.413 L 11.522 8.121 L 10.855 11.495 L 8.815 11.079 L 8.648 11.91 L 11.563 12.493 Z" style={{ fill: '#ffffff' }} />
    </Fragment>,
    'RunMarker'
);
