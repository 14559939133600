// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import styles from './styles';

type Props = {
    email: string,
};

const useStyles = makeStyles(styles, { name: 'EmailSent' });

export default function EmailSent(props: Props) {
    const { email } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    return (
        <Paper className={classes.paper} elevation={0}>
            <h1>{t('signup.emailSent.heading')}</h1>
            <p>
                <span>{t('signup.emailSent.emailSent')}</span><br />
                <a href={`mailto:${email}`} target="_top">{email}</a>
            </p>
            <p>{t('signup.emailSent.followInstructions')}</p>
        </Paper>
    );
}
