// @flow
import React, { Component } from 'react';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { CircularProgress, Fade, InputAdornment } from '@material-ui/core';
import {
    Check as CheckIcon,
    Clear as ClearIcon,
    Timer as TimerIcon,
} from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { FormProps } from 'redux-form';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { TFunction } from 'react-i18next';
import fieldify from '../../../../../../components/fieldify';
import MaskedInput from '../../../../../../components/MaskedInputV2';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../../../redux/initialState';

type State = {
    timeUnit: 'minutes' | 'hours',
    statusTimer: ?number,
};

type OwnProps = ContextRouter & FormProps & {
    classes: Object,
    className?: string,
    t: TFunction,
};
type StateProps = {};
type Props = OwnProps & StateProps;

const MaskedInputField = fieldify()(MaskedInput);
const ReadOnlyField = fieldify()(
    (props: Object) => {
        const { className, value } = props;
        return <div className={className}>{value}</div>;
    }
);

export class TimeEstimate extends Component<Props, State> {
    state = {
        timeUnit: 'minutes',
        statusTimer: null,
    };

    componentWillReceiveProps(nextProps: Props) {
        const { submitFailed, submitSucceeded } = this.props;
        const displayStatus = (!submitFailed && nextProps.submitFailed)
            || (!submitSucceeded && nextProps.submitSucceeded);

        if (displayStatus) {
            const { statusTimer } = this.state;
            clearTimeout(statusTimer);

            this.setState({
                statusTimer: setTimeout(() => {
                    this.setState({ statusTimer: null });
                }, 1500),
            });
        }
    }

    _formatTimeEstimate = (value: any): ?string => {
        const { readOnly } = this.props;
        const { timeUnit } = this.state;
        const timeEstimate = parseInt(value, 10);

        if (!Number.isNaN(timeEstimate)) {
            const divisor = timeUnit === 'hours' ? 3600 : 60;
            const formattedValue = `${Math.round((timeEstimate / divisor) * 100) / 100}`;
            return readOnly.includes('timeEstimate')
                ? `${formattedValue} ${timeUnit === 'minutes' ? 'min' : 'hr'}`
                : `${formattedValue}`;
        }
    };

    _normalizeTimeEstimate = (value: any): ?number => {
        const { timeUnit } = this.state;
        const input = parseInt(value, 10);

        if (!Number.isNaN(input)) {
            const multiplier = timeUnit === 'hours' ? 3600 : 60;
            return Math.round(input * multiplier);
        }
    };

    // handleTimeUnitChange = () => {
    //     const { timeUnit } = this.state;
    //     this.setState({ timeUnit: timeUnit === 'minutes' ? 'hours' : 'minutes' });
    // };

    handleBlur = () => {
        const { dirty, handleSubmit, valid } = this.props;
        if (dirty && valid) {
            handleSubmit();
        }
    };

    renderTimeEstimate() {
        const { classes, disabled, readOnly } = this.props;
        const { timeUnit } = this.state;

        return readOnly.includes('timeEstimate')
            ? (
                <Field
                  name="timeEstimate"
                  format={this._formatTimeEstimate}
                  normalize={this._normalizeTimeEstimate}
                  component={ReadOnlyField}
                  className={classes.readOnly}
                />
            )
            : (
                <Field
                  name="timeEstimate"
                  format={this._formatTimeEstimate}
                  normalize={this._normalizeTimeEstimate}
                  component={MaskedInputField}
                  classes={{ input: classes.input }}
                  disabled={disabled.includes('timeEstimate')}
                  variant="outlined"
                  timeUnit={timeUnit} // pass timeUnit as a prop to force field to update when value changes
                  options={{
                      numeral: true,
                      numeralThousandsGroupStyle: 'thousand',
                      numeralPositiveOnly: true,
                  }}
                  endAdornment={(
                      <InputAdornment position="end">
                          {timeUnit === 'minutes' ? 'min' : 'hr'}
                      </InputAdornment>
                  )}
                  onBlur={this.handleBlur}
                />
            );
    }

    render() {
        const { classes, handleSubmit, submitSucceeded, submitting, t } = this.props;
        const { statusTimer } = this.state;

        return (
            <form className={classes.container} onSubmit={handleSubmit}>
                <TimerIcon className={classes.icon} />
                <span className={classes.label}>{t('ticketDetail.summary.timeEstimate')}</span>
                {this.renderTimeEstimate()}
                {submitting
                    ? <CircularProgress size={18} thickness={4} />
                    : (
                        <Fade in={!!statusTimer} timeout={{ exit: 250 }}>
                            {submitSucceeded
                                ? <CheckIcon className={classes.successIcon} />
                                : <ClearIcon className={classes.errorIcon} />
                            }
                        </Fade>
                    )
                }
            </form>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    disabled: selectors.getDisabled(state, props),
    initialValues: selectors.getInitialValues(state, props),
    readOnly: selectors.getReadOnly(state, props),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps);
const enhance = compose(
    withStyles(styles, { name: 'TimeEstimate' }),
    withTranslation(),
    connector,
    reduxForm({
        form: 'timeEstimate',
        enableReinitialize: true,
        destroyOnUnmount: false,
        onSubmit: (values: Object, dispatch: Dispatch<any>, props: Props): Promise<any> => dispatch(actions.submit(values, props)),
    })
);

export default enhance(TimeEstimate);
