// @flow
import React, { Component, Fragment } from 'react';
import qs from 'qs';
import cx from 'classnames';
import { isEmail } from 'validator';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { IconButton, ListItemIcon, ListItemText, Toolbar, Tooltip } from '@material-ui/core';
import {
    AccountBalanceWallet as AccountBalanceWalletIcon,
    AddCircle as AddCircleIcon,
    Check as CheckIcon,
    Clear as ClearIcon,
    RemoveCircle as RemoveCircleIcon,
    Undo as UndoIcon,
} from '@material-ui/icons';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter } from 'react-router';
import { search as searchOrganizations } from '../../../../redux/entities/organizations';
import { search as searchCustomers } from '../../../../redux/entities/customers';
import getDisplayName from '../../../../util/getDisplayName';
import highlightMatch from '../../../../util/highlightMatch';
import FilterSelect from '../../../../components/FilterSelect';
import { selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {
    className?: string,
    classes: Object,
    t: TFunction,
};
type StateProps = {
    customerFilterValue: Object[],
    organizationFilterValue: ?Object,
    search: {
        customer?: string[],
        organization?: string,
        type?: string[],
    },
    typeFilterOptions: Object[],
    typeFilterValue: Object[],
};
type DispatchProps = {
    searchCustomers: (params: Object) => Promise<Object>,
    searchOrganizations: (params: Object) => Promise<Object>,
};
type Props = OwnProps & StateProps & DispatchProps;

const noFilters = {
    customer: undefined,
    organization: undefined,
    type: undefined,
};

const formatTypeOptionLabel = (option: Object, meta: Object) => {
    const { label, value } = option;

    let icon = null;
    switch (value) {
        case 'PAYMENTS_ADD':
            icon = <AccountBalanceWalletIcon />;
            break;
        case 'PAYMENTS_ADD_STRIPE':
            icon = <AddCircleIcon />;
            break;
        case 'PAYMENTS_DEDUCT':
            icon = <RemoveCircleIcon />;
            break;
        case 'PAYMENTS_REFUND':
            icon = <UndoIcon />;
            break;
        default:
            break;
    }

    return (
        <Fragment>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={highlightMatch(label, meta.inputValue)} />
            {meta.isSelected ? <CheckIcon /> : null}
        </Fragment>
    );
};

export class FilterToolbar extends Component<Props> {
    loadCustomerOptions = (inputValue: string) => {
        const params = {};
        params.limit = 20;
        params.offset = 0;
        params.sort = [{ first_name: 'asc' }];

        if (inputValue) {
            if (isEmail(inputValue)) {
                params.filters.push(
                    {
                        filter_type: 'or',
                        filters: [
                            { key: 'email', value: inputValue },
                            { key: 'paypal_email', value: inputValue },
                        ],
                    }
                );
            } else {
                params.keywords = inputValue;
            }
        }

        // Use searchCustomers to ensure entities are stored in state
        return this.props.searchCustomers(params) // eslint-disable-line react/destructuring-assignment
            .then((resp) => {
                // @todo: Consider using entitySelector to get denormalized entity...
                const { entities, result } = resp;
                return result.map((id: number) => ({
                    label: getDisplayName(entities.customers[id]),
                    value: entities.customers[id],
                }));
            });
    };

    loadOrganizationOptions = (inputValue: string) => {
        const params = {
            q: inputValue ? `*${inputValue}*` : null,
            from: 0,
            size: 20,
            sort_field: 'organization_name',
            sort_order: 'asc',
        };

        // Use searchOrganizations to ensure entities are stored in state
        return this.props.searchOrganizations(params) // eslint-disable-line react/destructuring-assignment
            .then((resp) => {
                // @todo: Consider using entitySelector to get denormalized entity...
                const { entities, result } = resp;
                return result.map((id: number) => ({
                    label: entities.organizations[id].organization_name,
                    value: entities.organizations[id],
                }));
            });
    };

    handleCustomerFilterChange = (value: Object[]) => {
        const customerFilters = value.map((option: Object) => option.value.id);
        this.updateFilters({ customer: customerFilters });
    };

    handleOrganizationFilterChange = (value: ?Object) => {
        const organizationFilter = value ? value.value.id : undefined;
        this.updateFilters({ organization: organizationFilter });
    };

    handleTypeFilterChange = (value: Object[]) => {
        const typeFilters = value.map((option: Object) => option.value);
        this.updateFilters({ type: typeFilters });
    };

    handleClear = () => {
        this.updateFilters(noFilters);
    };

    updateFilters(filters: Object) {
        const { location, history, search } = this.props;

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                { ...search, ...filters },
                { addQueryPrefix: true, encodeValuesOnly: true },
            ),
        });
    }

    renderSelectedCount = (value: ?Object | Object[]) => {
        const { t } = this.props;
        let count = 0;

        if (value != null) {
            count = Array.isArray(value) ? value.length : 1;
        }

        return count
            ? (
                <Trans
                  defaults={t('transactionList.filters.selectedCount', { count })}
                  components={[<strong style={{ fontWeight: 600 }}>count</strong>, 'selected']}
                />
            )
            : t('transactionList.filters.any');
    };

    render() {
        const {
            className,
            classes,
            customerFilterValue,
            organizationFilterValue,
            typeFilterOptions,
            typeFilterValue,
            t,
        } = this.props;

        return (
            <Toolbar className={cx(classes.root, className)}>
                <FilterSelect
                  label={`${t('transactionList.filters.organization')}:`}
                  loadOptions={this.loadOrganizationOptions}
                  value={organizationFilterValue}
                  renderValue={this.renderSelectedCount}
                  onChange={this.handleOrganizationFilterChange}
                  defaultOptions
                />
                <FilterSelect
                  label={`${t('transactionList.filters.customer')}:`}
                  loadOptions={this.loadCustomerOptions}
                  value={customerFilterValue}
                  renderValue={this.renderSelectedCount}
                  onChange={this.handleCustomerFilterChange}
                  defaultOptions
                  isMulti
                />
                <FilterSelect
                  label={`${t('transactionList.filters.type')}:`}
                  options={typeFilterOptions}
                  value={typeFilterValue}
                  renderValue={this.renderSelectedCount}
                  onChange={this.handleTypeFilterChange}
                  formatOptionLabel={formatTypeOptionLabel}
                  isMulti
                />
                <Tooltip title={t('transactionList.filters.clearFilters')}>
                    <IconButton className={classes.clearButton} onClick={this.handleClear}>
                        <ClearIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    ...selectors.getAPIParams(state, props),
    organizationFilterValue: selectors.getOrganizationFilterValue(state, props),
    customerFilterValue: selectors.getCustomerFilterValue(state, props),
    search: selectors.parseSearchParams(state, props),
    typeFilterOptions: selectors.getTypeFilterOptions(),
    typeFilterValue: selectors.getTypeFilterValue(state, props),
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    searchOrganizations: (params: Object) => dispatch(searchOrganizations(params)),
    searchCustomers: (params: Object) => dispatch(searchCustomers(params)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'FilterToolbar' }),
    withTranslation(),
    connector,
);

export default enhance(FilterToolbar);
