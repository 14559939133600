// @flow
import React from 'react';
import DropzoneComponent from 'react-dropzone-component';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import type { TFunction } from 'react-i18next';
import { createBlobFromFile } from '../../util/FileUtil';
import logger from '../../../../common/util/logger';

export type DropzoneFile = File & {
    previewElement: Element,
    status: string,
    url?: string,
    key?: string,
};

// The structure of this object depends on the AmazonS3 API response and how
// xml2js.parseString parses the response text
type AmazonS3Error = {
    Error: {
        Code: [string],
    },
};

type Props = {
    t: TFunction,
    className?: string,
    bucket?: 'locationList' | 'locationListUpdate' | 'customer',
    multiple?: boolean,
    accept?: string,
    minSize?: number,
    maxSize?: number,
    onUploadSuccess?: (file: DropzoneFile) => void,
    onUploadError?: (file: DropzoneFile, err: string | AmazonS3Error) => void,
    onRemoveFile?: (file: DropzoneFile) => void,
    onProcessingFile?: (file: DropzoneFile) => void,
};

type DropzoneConfig = {
    addRemoveLinks?: boolean,
    acceptedFiles?: string,
    params?: { [key: string]: any },
};

export function Dropzone(props: Props): React$Element<any> {
    const {
        accept,
        bucket,
        className,
        multiple,
        onProcessingFile,
        onRemoveFile,
        onUploadError,
        onUploadSuccess,
        t,
    } = props;

    const config = {
        postUrl: '/upload',
    };

    const djsConfig: DropzoneConfig = {
        maxFiles: multiple ? Infinity : 1,
        addRemoveLinks: true,
        acceptedFiles: accept,
        dictDefaultMessage: t('shared.dropzone.dictDefaultMessage'),
        dictFallbackMessage: t('shared.dropzone.dictFallbackMessage'),
        dictFallbackText: t('shared.dropzone.dictFallbackText'),
        dictInvalidFileType: t('shared.dropzone.dictInvalidFileType'),
        dictFileTooBig: t('shared.dropzone.dictFileTooBig'),
        dictResponseError: t('shared.dropzone.dictResponseError'),
        dictCancelUpload: t('shared.dropzone.dictCancelUpload'),
        dictCancelUploadConfirmation: t('shared.dropzone.dictCancelUploadConfirmation'),
        dictRemoveFile: t('shared.dropzone.dictRemoveFile'),
        dictMaxFilesExceeded: t('shared.dropzone.dictMaxFilesExceeded'),
    };

    if (bucket) {
        djsConfig.params = { bucket };
    }

    const eventHandlers = {
        init(dropzone) {
            const _addFile = dropzone.addFile.bind(dropzone);

            // eslint-disable-next-line no-param-reassign
            dropzone.addFile = function addFile(file) {
                const blob = createBlobFromFile(file);
                return _addFile(blob);
            };
        },

        success(file: DropzoneFile, response: { url: string, key?: string }) {
            file.url = response.url; // eslint-disable-line no-param-reassign
            if (response.key) {
                file.key = response.key; // eslint-disable-line no-param-reassign
            }

            if (onUploadSuccess) onUploadSuccess(file);

            // Since we are overriding the default event handler, we need to
            // add the 'dz-success' class ourselves. Dropzone will not do this.
            // Strangely, this is not needed for the other event handlers.
            if (file.previewElement) file.previewElement.classList.add('dz-success');
        },

        error(file: DropzoneFile, err: string | AmazonS3Error, xhr?: XMLHttpRequest) {
            if (onUploadError) onUploadError(file, err);
            if (xhr == null) return;

            const { status, responseText } = xhr;
            logger.error(`Dropzone failed to upload file - status: ${status}, responseText: ${responseText}`);

            if (file.previewElement) {
                file.previewElement.classList.add('dz-error');

                const nodeList = file.previewElement.querySelectorAll('[data-dz-errormessage]');
                nodeList.forEach((node: Element) => {
                    switch (status) {
                        case 0:
                            // Request failed to send. Possibly due to network issues on the client
                            node.textContent = t('shared.dropzone.networkError'); // eslint-disable-line no-param-reassign
                            break;

                        case 500:
                            // This could happen due to network issues on the server
                            node.textContent = t('shared.dropzone.internalServerError'); // eslint-disable-line no-param-reassign
                            break;

                        case 403: {
                            // Check to see if this is an error from the AmazonS3 API.
                            // See http://docs.aws.amazon.com/AmazonS3/latest/API/ErrorResponses.html#ErrorCodeList for a list of error codes
                            if (typeof err !== 'string' && err.Error && err.Error.Code) {
                                const errorCode = err.Error.Code[0];
                                const key = `shared.dropzone.aws${errorCode}`;
                                const message = t(key);

                                // If this is false, lookup failed and we don't have an error message for this error code.
                                if (message !== key) {
                                    node.textContent = message; // eslint-disable-line no-param-reassign
                                    break;
                                }
                            }

                            node.textContent = t('shared.dropzone.unknownError'); // eslint-disable-line no-param-reassign
                            break;
                        }

                        default:
                            node.textContent = t('shared.dropzone.unknownError'); // eslint-disable-line no-param-reassign
                            break;
                    }
                });
            }
        },

        removedfile(file: DropzoneFile) {
            if (onRemoveFile) onRemoveFile(file);
        },

        processing(file: DropzoneFile) {
            if (onProcessingFile) onProcessingFile(file);
        },
    };

    return (
        <DropzoneComponent
          className={classnames('dropzone', className)}
          config={config}
          djsConfig={djsConfig}
          eventHandlers={eventHandlers}
        />
    );
}

export default withTranslation()(Dropzone);
