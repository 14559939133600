// @flow
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import { format } from '../../../../../shared/util/gigwalkApiErrorUtil';
import { client as gigwalk } from '../../../../../../common/api/createGigwalkClient';
import * as snackbar from '../../../../../../common/ducks/snackbar';
import types from './types';

type FormValues = {
    comment: string,
    filterBySubmitted: boolean,
    filterByUnsubmitted: boolean,
};

export const submitError = createAction(types.SUBMIT_ERROR);
export const submitSuccess = createAction(types.SUBMIT_SUCCESS);
export const submit = createAction(
    types.SUBMIT,
    (values: FormValues, props: Object): Function => (
        (dispatch: Dispatch<any>): Promise<any> => {
            const { match } = props;
            const { comment, filterBySubmitted, filterByUnsubmitted } = values;
            const subscriptionId = parseInt(match.params.subscriptionId, 10);

            const status = [];
            if (filterByUnsubmitted) {
                status.push('ASSIGNED', 'STARTED', 'SCHEDULED');
            }
            if (filterBySubmitted) {
                status.push('SUBMITTED');
            }

            const data = {
                ticket_event_type: 'COMMENT',
                ticket_comment: { comment },
                project_id: subscriptionId,
                ticket_status: status,
            };

            return gigwalk.client.post('v1/ticket_events/comment', data)
                .then(() => {
                    dispatch(submitSuccess());
                })
                .catch((err: $AxiosError<Object>) => {
                    dispatch(submitError(err));
                    const resp = err ? err.response : null;
                    if (resp && resp.data && resp.data.gw_api_response) {
                        const message = format(resp.data.gw_api_response);
                        dispatch(snackbar.actions.enqueue(message, { variant: 'error' }));
                    }
                    return Promise.reject();
                });
        }
    )
);

export default {
    submit,
    submitError,
    submitSuccess,
};
