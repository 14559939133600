// @flow
/**
 * Returns a validation error if value is null, undefined, or an empty string
 * @param value
 * @returns {string}
 */
export default (value: any): ?string => {
    // A boolean value should be considered valid
    const invalid = (typeof value === 'string' && !value)
        || (typeof value === 'undefined')
        || (value === null);

    if (invalid) {
        return 'required';
    }
};
