// @flow
import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Field, formValueSelector, getFormInitialValues, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { compose, withHandlers, withProps } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { GridList, GridListTile, Typography } from '@material-ui/core';
// $FlowTypedIssue libdef is missing isWidthDown export for this module
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Public, Work } from '@material-ui/icons';
import type { FormProps } from 'redux-form';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { TFunction } from 'react-i18next';
import { OPTIN_TYPES } from '../../../../../../../browser/shared/constant/ProjectConstant';
import fieldify from '../../../../../../components/fieldify';
import entitySelector from '../../../../../../redux/entities/entitySelector';
import getValidationRule from '../../../../utils/getValidationRule';
import Step from '../../../../components/Step';
import { selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../../../redux/initialState';

type OwnProps = ContextRouter & FormProps & {
    classes: Object,
    t: TFunction,
};

type StateProps = {
    editMode: boolean,
    needsPublicWorkforce: boolean,
};

export type Props = OwnProps & StateProps;

const GridListTileField = compose(
    fieldify(),
    withProps((props: Object) => {
        const { checked, className } = props;
        return { className: cx(className, { active: checked }) };
    }),
    withHandlers({
        onClick: (props: Object) => {
            const { onChange, value } = props;
            return () => onChange(value);
        },
    }),
)(GridListTile);

export class People extends Component<Props> {
    componentWillReceiveProps(nextProps: Props) {
        const { needsPublicWorkforce } = this.props;
        const { change } = nextProps;

        if (needsPublicWorkforce !== nextProps.needsPublicWorkforce) {
            change(
                'workAllocation',
                nextProps.needsPublicWorkforce
                    ? { optinType: OPTIN_TYPES.SYSTEM_APPROVED_OPTIN, autoassign: false }
                    : { optinType: OPTIN_TYPES.SIMPLE_OPTIN, autoassign: false },
                false,
                false
            );
            change('needsApproval', nextProps.needsPublicWorkforce, false, false);
            change('twoWayRatingEnabled', nextProps.needsPublicWorkforce, false, false);

            // Since you can't mix private and public certifications, we need to clear any certifications
            // previously added to the project
            change('certifications', [], false, false);
        }
    }

    render() {
        const { classes, editMode, t, width } = this.props;

        let cellWidth = 180;
        if (isWidthDown('sm', width)) {
            cellWidth = 120;
        } else if (isWidthDown('md', width)) {
            cellWidth = 140;
        }

        const cellHeight = cellWidth * 0.9;
        const cols = 2;
        const spacing = 16;

        const gridListProps = {
            cellHeight,
            cols,
            spacing,
            style: {
                width: (cellWidth + spacing) * cols,
                marginTop: 0,
            },
        };
        const gridListTileProps = {
            classes: {
                root: classes.tileRoot,
                tile: classes.tile,
            },
            disabled: editMode,
        };

        return (
            <Step title={t('projectBuilder.people.workforce.header')} subheader={t('projectBuilder.people.workforce.subheader')}>
                <form className={classes.form}>
                    <Typography className={classes.label} align="center">
                        {t('projectBuilder.people.workforce.selectOne')}
                    </Typography>
                    <GridList {...gridListProps}>
                        <Field
                          id="privateWorkforce"
                          name="needsPublicWorkforce"
                          type="radio"
                          value={false}
                          validate={getValidationRule('needsPublicWorkforce')}
                          component={GridListTileField}
                          {...gridListTileProps}
                        >
                            <Work className={classes.tileIcon} />
                            <Typography className={classes.tileCaption} component="p">
                                {t('projectBuilder.people.workforce.privateWorkforce')}
                            </Typography>
                        </Field>
                        <Field
                          id="publicWorkforce"
                          name="needsPublicWorkforce"
                          type="radio"
                          value={true} // eslint-disable-line react/jsx-boolean-value
                          validate={getValidationRule('needsPublicWorkforce')}
                          component={GridListTileField}
                          {...gridListTileProps}
                        >
                            <Public className={classes.tileIcon} />
                            <Typography className={classes.tileCaption} component="p">
                                {t('projectBuilder.people.workforce.publicWorkforce')}
                            </Typography>
                        </Field>
                    </GridList>
                    <Field name="workAllocation" component="input" type="hidden" />
                    <Field name="needsApproval" component="input" type="hidden" />
                    <Field name="twoWayRatingEnabled" component="input" type="hidden" />
                </form>
            </Step>
        );
    }
}

const valueSelector = formValueSelector('projectBuilder');
const subscriptionSelector = entitySelector('subscriptions');

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const { match } = props;
    return {
        editMode: subscriptionSelector(state, match.params.subscriptionId, 'state') === 'ACTIVE',
        initialValues: {
            ...getFormInitialValues('projectBuilder')(state),
            ...selectors.getInitialValues(state, props),
        },
        needsPublicWorkforce: valueSelector(state, 'needsPublicWorkforce') || false,
    };
};

const connector: Connector<OwnProps, Props> = connect(mapStateToProps);
const enhance = compose(
    withWidth({}),
    withStyles(styles, { name: 'People' }),
    withTranslation(),
    connector,
    reduxForm({
        form: 'projectBuilder',
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    })
);

export default enhance(People);
