// @flow
import React from 'react';
import Helmet from 'react-helmet';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import LoginForm from './components/LoginForm';
import actions from './duck/actions';
import styles from './styles';

type OwnProps = {};
type DispatchProps = {
    submit: (values: Object) => Promise<void>
};
type Props = OwnProps & DispatchProps;

const logoSrc = process.env.GWP_COMPANY_LOGO || '/public/images/gigwalk-logo.png';
const hideLink = process.env.GWP_HIDE_SIGNUP === 'true';
const useStyles = makeStyles(styles, { name: 'Login' });

const LoginSchema = yup.object().shape({
    email: yup.string()
        .email('validation.invalidEmail')
        .required('validation.required'),
    password: yup.string()
        .required('validation.required'),
    rememberMe: yup.boolean(),
});

const initialValues = {
    email: '',
    password: '',
    rememberMe: false,
};

export function Login(props: Props) {
    const { submit } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    return (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setStatus, setSubmitting }) => {
              submit(values)
                  .then(() => setStatus({ success: true }))
                  .catch((error: string) => setStatus({ error }))
                  .then(() => setSubmitting(false));
          }}
          validationSchema={LoginSchema}
        >
            {(formikProps: Object) => (
                <main>
                    <Helmet title={t('login.title')} />
                    <img alt="Gigwalk" className={classes.logo} src={logoSrc} />
                    <LoginForm {...formikProps} />
                    {hideLink
                        ? null
                        : (
                            <div className={classes.footer}>
                                <div>
                                    <p>{t('login.loginFooter.legacyCustomer')}</p>
                                    <a className={classes.link} href="https://gigwalk.com/login">
                                        {t('login.loginFooter.loginLegacy')}
                                    </a>
                                </div>
                                <div>
                                    <p>{t('login.loginFooter.dontHaveAccount')}</p>
                                    <Link className={classes.link} to="/signup">
                                        {t('login.loginFooter.signup')}
                                    </Link>
                                </div>
                            </div>
                        )
                    }
                </main>
            )}
        </Formik>
    );
}

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    submit: (values) => dispatch(actions.submit(values)),
});
const connector: Connector<OwnProps, Props> = connect(null, mapDispatchToProps);
export default connector(Login);
