// @flow
import React, { Component } from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import Star from '../icons/Star';
import styles from './styles';

type State = {
    hover: ?number,
};

type Props = {
    classes: Object,
    className?: string,
    disableHover?: boolean,
    onChange?: (value: number) => void,
    onHover?: (value: ?number) => void;
    value?: number,
}

export class StarRating extends Component<Props, State> {
    state = {
        hover: null,
    };

    handleMouseOver = (event: SyntheticEvent<any>) => {
        const rating = parseInt(event.currentTarget.dataset.rating, 10);
        this._updateHoverState(rating);
    };

    handleMouseOut = () => {
        this._updateHoverState(null);
    };

    handleClick = (event: SyntheticEvent<any>) => {
        const rating = parseInt(event.currentTarget.dataset.rating, 10);
        const { onChange } = this.props;
        if (typeof onChange === 'function') {
            onChange(rating);
        }
    };

    _updateHoverState(value: ?number) {
        const { disableHover, onHover } = this.props;
        if (!disableHover) {
            this.setState({ hover: value }, () => {
                if (typeof onHover === 'function') {
                    onHover(value);
                }
            });
        }
    }

    render() {
        const { classes, className, value } = this.props;
        const { hover } = this.state;
        // Round up/down to the nearest multiple of 0.5
        const nearestRating = Math.round((hover || value || 0) * 2) / 2;

        const stars = Array.from(new Array(5), (el: void, i: number) => {
            const rating = 5 - i;
            const difference = rating - nearestRating;

            // Determine if star should be empty or completely/partially filled
            let starValue = 1;
            if (difference >= 1) {
                starValue = 0;
            } else if (difference > 0) {
                starValue = difference;
            }

            return (
                <Star
                  key={i}
                  className={classes.star}
                  data-rating={rating}
                  onClick={this.handleClick}
                  onMouseOver={this.handleMouseOver}
                  onMouseOut={this.handleMouseOut}
                  value={starValue}
                />
            );
        });

        return (
            <div className={cx(classes.rating, className)}>
                {stars}
            </div>
        );
    }
}

export default withStyles(styles, { name: 'StarRating' })(StarRating);
