// @flow
import type { DataType } from 'gigwalk/lib/api/dataTypes/types';
import CondExpression from '../../../../../../browser/shared/data_structures/CondExpression';
import { VALUE_TYPE } from '../../../../../../browser/shared/constant/ProjectConstant';

type Proposition = {
    choice: string,
    alert: boolean,
};

export const mapQuestionToEntity = (question: Object) => {
    const {
        id,
        description,
        valueType,
        questionText,
        propositions,
        required,
        globalDataTypeId,
    } = question;

    const alerted = [];
    const notAlerted = [];
    propositions.forEach((p: Proposition) => {
        if (p.alert) alerted.push(p.choice);
        else notAlerted.push(p.choice);
    });

    let expectedValueExpression;
    if (propositions.length === 0) {
        expectedValueExpression = null;
    } else if (alerted.length === propositions.length) {
        // All proposition have alerts
        expectedValueExpression = {
            $and: alerted.map((choice: string): Object => ({
                $ne: ['$data_item_value', choice],
            })),
        };
    } else if (notAlerted.length === 1) {
        // All but one proposition has an alert
        expectedValueExpression = {
            $eq: ['$data_item_value', notAlerted[0]],
        };
    } else if (alerted.length > 0) {
        // Some propositions have alerts
        expectedValueExpression = {
            $or: notAlerted.map((choice: string): Object => ({
                $eq: ['$data_item_value', choice],
            })),
        };
    }

    // todo: figure out if there are other restrictions to question_type
    let questionType;
    switch (valueType) {
        case VALUE_TYPE.SIGNATURE:
            questionType = VALUE_TYPE.PHOTO;
            break;

        case VALUE_TYPE.HINT:
        case VALUE_TYPE.TASK:
            questionType = VALUE_TYPE.CHECK;
            break;

        default:
            questionType = valueType;
            break;
    }

    return {
        id,
        description,
        value_type: valueType,
        is_required: required || false,
        questions: {
            question_text: questionText,
            question_type: questionType,
            propositions: propositions.map((p: Proposition): string => p.choice),
        },
        expected_value_expression: expectedValueExpression || null,
        dashboard_visible: true,
        global_data_type_id: globalDataTypeId,
    };
};

export const mapEntityToQuestion = (dataType: DataType) => {
    const {
        id,
        description,
        value_type: valueType,
        questions: {
            question_text: questionText,
            propositions,
        },
        is_required: required,
        expected_value_expression: expectedValueExpression,
        global_data_type_id: globalDataTypeId,
        // $FlowFixMe need to update gigwalk-node type definitions
        project_or_global_type: type,
    } = dataType;

    const condExpression = expectedValueExpression ? new CondExpression(expectedValueExpression) : null;
    return {
        id,
        description,
        valueType,
        questionText,
        propositions: propositions ? propositions.map((p) => ({ choice: p, alert: condExpression ? !condExpression.evaluate([p]) : false })) : [],
        required: required || false,
        globalDataTypeId: type === 'global' ? id : globalDataTypeId || -1,
    };
};

export default {
    mapQuestionToEntity,
    mapEntityToQuestion,
};
