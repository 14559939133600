// @flow
import React from 'react';
import * as yup from 'yup';
import Helmet from 'react-helmet';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import EmailSent from './components/EmailSent';
import actions from './duck/actions';
import styles from './styles';

type OwnProps = {};
type DispatchProps = {
    submit: (values: Object) => Promise<void>,
};
type Props = OwnProps & DispatchProps;

const logoSrc = process.env.GWP_COMPANY_LOGO || '/public/images/gigwalk-logo.png';
const useStyles = makeStyles(styles, { name: 'ForgotPassword' });

const ForgotPasswordSchema = yup.object().shape({
    email: yup.string()
        .email('validation.invalidEmail')
        .required('validation.required'),
});

const initialValues = {
    email: '',
};

export function ForgotPassword(props: Props) {
    const { submit } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    return (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setStatus, setSubmitting }) => {
              submit(values)
                  .then(() => setStatus({ success: true }))
                  .catch((error: string) => setStatus({ error }))
                  .then(() => setSubmitting(false));
          }}
          validationSchema={ForgotPasswordSchema}
        >
            {(formikProps: Object) => {
                const { status, values } = formikProps;
                return (
                    <main>
                        <Helmet title={t('forgotPassword.emailSent.title')} />
                        <img alt="Gigwalk" className={classes.logo} src={logoSrc} />
                        {status && status.success
                            ? <EmailSent email={values.email} />
                            : <ForgotPasswordForm {...formikProps} />
                        }
                        <div className={classes.footer}>
                            <Link className={classes.link} to="/login">
                                {t('forgotPassword.backToLogin')}
                            </Link>
                        </div>
                    </main>
                );
            }}
        </Formik>
    );
}

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    submit: (values) => dispatch(actions.submit(values)),
});
const connector: Connector<OwnProps, Props> = connect(null, mapDispatchToProps);
export default connector(ForgotPassword);
