// @flow
import React, { Component, Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import {
    Button,
    Card,
    CardActions,
    CardHeader,
    CircularProgress,
    Input,
} from '@material-ui/core';
import type { Dispatch } from 'redux';
import type { FormProps } from 'redux-form';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { TFunction } from 'react-i18next';
import UserAvatar from '../../../../../../components/UserAvatar';
import fieldify from '../../../../../../components/fieldify';
import { required } from '../../../../../../util/validations';
import { actions } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../../../redux/initialState';

type OwnProps = ContextRouter & FormProps & {
    className?: string,
    classes: Object,
    t: TFunction,
};
type StateProps = {
    user: ?Object,
};
type Props = OwnProps & StateProps;

const InputField = fieldify()(Input);

export class AddComment extends Component<Props> {
    handleCancel = () => {
        // Use initialize action to reset form to initial state. See https://github.com/erikras/redux-form/issues/2971
        // NOTE: Any initialization related config options passed to reduxForm HOC should also be passed
        // to the initialize action
        const { initialize } = this.props;
        initialize();
    };

    render() {
        const { classes, handleSubmit, invalid, submitting, user } = this.props;

        // In some browsers, hitting the 'Enter' key in a text input that is the only field in the form will cause
        // the form to be submitted. This bypasses all the submission control/logic of reduxForm. As a workaround,
        // you can add an onSubmit event handler and use `event.preventDefault()` to disable this behavior.
        // See https://stackoverflow.com/questions/1370021/why-does-forms-with-single-input-field-submit-upon-pressing-enter-key-in-input
        return (
            <form onSubmit={handleSubmit}>
                <Card elevation={0}>
                    <CardHeader
                      avatar={<UserAvatar user={user} />}
                      classes={{
                          avatar: classes.avatar,
                          title: classes.header,
                          subheader: classes.content,
                      }}
                      title={(
                          <Fragment>
                              <Field
                                name="comment"
                                component={InputField}
                                validate={[required]}
                                multiline
                                fullWidth
                                placeholder="Add a comment..."
                              />
                              <CardActions className={classes.actions}>
                                  <Button onClick={this.handleCancel}>
                                      Cancel
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={invalid || submitting}
                                  >
                                      {submitting ? <CircularProgress size={24} className={classes.progress} /> : null}
                                      Comment
                                  </Button>
                              </CardActions>
                          </Fragment>
                      )}
                    />
                </Card>
            </form>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    user: state.session.user,
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps);
const enhance = compose(
    withStyles(styles, { name: 'AddComment' }),
    withTranslation(),
    connector,
    reduxForm({
        form: 'addComment',
        destroyOnUnmount: false,
        onSubmit: (values: Object, dispatch: Dispatch<any>, props: Props): Promise<any> => dispatch(actions.submit(values, props)),
    })
);

export default enhance(AddComment);
