// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Children, useCallback, useState } from 'react';
import identity from 'lodash.identity';
import { useTransition, animated } from 'react-spring';
import { makeStyles } from '@material-ui/styles';
import { IconButton } from '@material-ui/core';
import {
    KeyboardArrowLeft as KeyboardArrowLeftIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@material-ui/icons';
import type { ChildrenArray } from 'react';

type Props = {
    children: ChildrenArray<any>,
    currentSlide: number,
    infinite: boolean,
    onChange: (index: number) => void,
    onNextClick: (index: number) => void,
    onPrevClick: (index: number) => void,
};

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    prev: {
        position: 'absolute',
        left: 0,
    },
    next: {
        position: 'absolute',
        right: 0,
    },
    container: {
        flex: 1,
        height: '100%',
        position: 'relative',
    },
    slide: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
    },


}), { name: 'Carousel' });

export default function Carousel(props: Props) {
    const { children, currentSlide, infinite, onNextClick, onPrevClick } = props;
    const childArray = Children.toArray(children);
    const classes = useStyles(props);

    const [direction, setDirection] = useState(null);

    const canMoveToLeft = infinite || currentSlide > 0;
    const canMoveToRight = infinite || currentSlide < childArray.length - 1;

    const transitions = useTransition(currentSlide, identity, {
        initial: { transform: 'translate3d(0%,0,0)' },
        from: { transform: `translate3d(${direction === 'left' ? -100 : 100}%,0,0)` },
        enter: { transform: 'translate3d(0%,0,0)' },
        leave: { transform: `translate3d(${direction === 'left' ? 100 : -100}%,0,0)` },
    });

    const handlePrevClick = useCallback(() => {
        if (canMoveToLeft) {
            const prevSlide = currentSlide - 1;
            onPrevClick(infinite && currentSlide === 0 ? childArray.length - 1 : prevSlide);
            setDirection('left');
        }
    }, [canMoveToLeft, childArray.length, currentSlide, infinite, onPrevClick]);

    const handleNextClick = useCallback(() => {
        if (canMoveToRight) {
            const nextSlide = currentSlide + 1;
            onNextClick(infinite ? nextSlide % childArray.length : nextSlide);
            setDirection('right');
        }
    }, [canMoveToRight, childArray.length, currentSlide, infinite, onNextClick]);

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                {transitions.map((transition) => (
                    <animated.div className={classes.slide} key={transition.key} style={transition.props}>
                        {childArray[transition.item]}
                    </animated.div>
                ))}
            </div>
            {canMoveToLeft
                ? (
                    <IconButton className={classes.prev} color="inherit" onClick={handlePrevClick}>
                        <KeyboardArrowLeftIcon fontSize="large" />
                    </IconButton>
                )
                : null
            }
            {canMoveToRight
                ? (
                    <IconButton className={classes.next} color="inherit" onClick={handleNextClick}>
                        <KeyboardArrowRightIcon fontSize="large" />
                    </IconButton>
                )
                : null
            }
        </div>
    );
}

Carousel.defaultProps = {
    currentSlide: 0,
    infinite: false,
    onChange: () => {},
    onNextClick: () => {},
    onPrevClick: () => {},
};
