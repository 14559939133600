// @flow
import GigwalkAPI from 'gigwalk';
import logger from '../util/logger';

let _client: ?GigwalkAPI;

const defaultHostName = typeof process.env.GIGWALK_API_SERVER_HOSTNAME === 'string'
    ? process.env.GIGWALK_API_SERVER_HOSTNAME
    : 'stage-api.apps.gigwalk.com';

export default function createClient(hostname: string = defaultHostName): GigwalkAPI {
    if (_client == null) {
        logger.debug('creating new GigwalkAPI instance...');
        _client = new GigwalkAPI({ hostname });
    }
    return _client;
}

export const client = createClient();
