// @flow
import { handleActions } from 'redux-actions';
import type { ActionType } from 'redux-actions';
import types from './types';
import typeof { fetchDataTypeSummarySuccess } from './actions';

type DataTypeSummary = {
    charts: { [key: string]: Object },
    freeText: { [key: string]: string[] },
    photos: { [key: string]: Object },
}

export type State = {
    dataTypeSummary: ?DataTypeSummary,
}

export const init: State = {
    dataTypeSummary: null,
};

export default handleActions({
    [types.FETCH_DATA_TYPE_SUMMARY_SUCCESS]: (state: State, action: ActionType<fetchDataTypeSummarySuccess>) => ({
        ...state,
        dataTypeSummary: action.payload,
    }),
}, init);
