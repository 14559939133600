// @flow
import { createSelector } from 'reselect';
import { getAvailableActions, getPermittedActions, getTicket } from '../../../../../duck/selectors';

export const getInitialValues = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    getTicket,
    (ticket: ?Object): Object => ({
        price: ticket ? ticket.price : undefined,
    })
);

export const getReadOnly = createSelector(
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    getPermittedActions,
    (actions: string[]): string[] => {
        const readOnly = [];
        if (!actions.includes('editPrice')) readOnly.push('price');
        return readOnly;
    }
);

export const getDisabled = createSelector(
    getAvailableActions,
    (actions: string[]): string[] => {
        const disabled = [];
        if (!actions.includes('editPrice')) disabled.push('price');
        return disabled;
    }
);

export default {
    getDisabled,
    getInitialValues,
    getReadOnly,
};
