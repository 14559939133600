// @flow
import React from 'react';
import Helmet from 'react-helmet';
import { Route, Switch } from 'react-router';
import NoMatch from '../NoMatch';
import GigwalkerList from '../GigwalkerList';
import CustomerList from '../CustomerList';
import PayoutList from '../PayoutList';
import TransactionsList from '../TransactionList';

export default () => (
    <div>
        <Helmet title="Admin" />
        <Switch>
            <Route path="/admin/:orgId/my-team/gigwalkers/list" component={GigwalkerList} />
            <Route path="/admin/:orgId/my-team/customers/list" component={CustomerList} />
            <Route path="/admin/:orgId/payouts/list" component={PayoutList} />
            <Route path="/admin/:orgId/transactions/list" component={TransactionsList} />
            <Route component={NoMatch} />
        </Switch>
    </div>
);
