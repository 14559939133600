// @flow
import dotProp from 'dot-prop';
import { schema, denormalize } from 'normalizr';
import schemas from './schemas';
import type { State as RootState } from '../initialState';

// todo: could we use a generic type here? See https://flow.org/en/docs/types/generics/
// todo: add stricter type annotation for ids parameter. Need something like this - number | string | number[] | string[]
export type EntitySelector = (state: RootState, ids: any, ...fields: string[]) => any;

// Create a dictionary mapping an entity's key to its schema
type SchemaMap = { [key: string]: typeof schema.Entity };
const schemaMap = Object.values(schemas)
    .reduce((map: SchemaMap, value: mixed): SchemaMap => {
        if (value instanceof schema.Entity) {
            return {
                ...map,
                [value.key]: value,
            };
        }
        return map;
    }, {});

const getValues = (entity: Object, ...fields: string[]) => {
    if (fields.length === 0) return entity;
    if (fields.length === 1) return dotProp.get(entity, `${fields[0]}`);

    return fields.reduce((accumulator: Object, field: string) => {
        const value = dotProp.get(entity, `${field}`);
        return value === undefined ? accumulator : dotProp.set(accumulator, field, value);
    }, {});
};

// todo: investigate using reselect to only recompute if necessary
// https://github.com/reactjs/reselect#sharing-selectors-with-props-across-multiple-components
export default (key: string): EntitySelector => (
    (state: RootState, ids?: any, ...fields?: string[]): any => {
        if (!(key in state.entities)) {
            throw new Error(`Cannot find '${key}' in \`state.entities\``);
        }

        const _ids = ids == null ? Object.keys(state.entities[key]) : ids;
        const entitySchema = schemaMap[key];

        if (Array.isArray(_ids)) {
            const entities = denormalize(_ids, [entitySchema], state.entities);
            return entities
                .filter((entity?: Object): boolean => !!entity)
                .map((entity: Object): Object => getValues(entity, ...fields));
        }

        const entity = denormalize(_ids, entitySchema, state.entities);
        return getValues(entity, ...fields);
    }
);
