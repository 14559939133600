var Handlebars = require('/tmp/build_c41768ab/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "	    <div class=\"answer "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.exception : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n	      <label class=\"plainLabel\">\n	        <input data-input type=\"checkbox\" name=\""
    + alias4(((helper = (helper = helpers._answerID || (depth0 != null ? depth0._answerID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_answerID","hash":{},"data":data}) : helper)))
    + " answer soda\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.checked : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " value=\""
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\"/>\n	        "
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\n	      </label>\n	    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " exception";
},"4":function(container,depth0,helpers,partials,data) {
    return " checked=\"true\" ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<!-- question type is Checkboxes -->\n<div class=\"type checkboxes\">\n  <div class=\"answers\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.answers : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n</div><!-- END Checkboxes -->\n<div class=\"validation error\" data-target=\"validationHint\">\n  <!--\n    \"Must be a number\"\n    \"Must be at least 2 characters long\"\n  -->\n</div>";
},"useData":true});