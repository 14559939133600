// @flow
import { createAction } from 'redux-actions';
import { denormalize } from 'normalizr';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import { format } from '../../../../browser/shared/util/gigwalkApiErrorUtil';
import { ticket as ticketSchema } from '../../../redux/entities/schemas';
import {
    update as updateTickets,
    createRating as createWorkerRating,
    updateRating as updateWorkerRating,
} from '../../../redux/entities/tickets';
import * as snackbar from '../../../ducks/snackbar';
import types from './types';
import type { State as RootState } from '../../../redux/initialState';

export const submitError = createAction(types.SUBMIT_ERROR);
export const submitSuccess = createAction(types.SUBMIT_SUCCESS);
export const submit = createAction(
    types.SUBMIT,
    (values: Object): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<any> => {
            const { approvalStatus, rating, ticketId } = values;
            const state = getState();
            const ticket = denormalize(ticketId, ticketSchema, state.entities);
            const promises = [];

            const { assigned_customer_id: assigneeId, subscription } = ticket;
            const {
                needs_approval: needsApproval,
                two_way_rating_enabled: twoWayRatingEnabled,
            } = subscription;

            if (needsApproval) {
                const action = approvalStatus === 'APPROVED' ? 'approve' : 'reject';
                const params = {
                    action,
                    ticket_ids: [ticketId],
                };
                promises.push(dispatch(updateTickets(params)));
            }

            if (twoWayRatingEnabled) {
                const defaultRating = approvalStatus === 'APPROVED' ? 4 : 2;
                const params = {
                    rating: rating || defaultRating,
                    ticket_id: ticketId,
                };

                if (ticket.rating != null) {
                    promises.push(dispatch(updateWorkerRating({ ...params, customer_id: assigneeId })));
                } else {
                    promises.push(dispatch(createWorkerRating(params)));
                }
            }

            return Promise.all(promises)
                .then(() => {
                    dispatch(submitSuccess());
                    // if (resp.original && resp.original.gw_api_response && resp.original.gw_api_response.length) {
                    //     const message = format(resp.original.gw_api_response);
                    //     dispatch(snackbar.actions.enqueue(message, { variant: 'warning' }));
                    // }
                })
                .catch((err: $AxiosError<Object>) => {
                    dispatch(submitError(err));
                    const resp = err ? err.response : null;
                    if (resp && resp.data && resp.data.gw_api_response) {
                        const message = format(resp.data.gw_api_response);
                        dispatch(snackbar.actions.enqueue(message, { variant: 'error' }));
                    }
                    return Promise.reject();
                });
        }
    )
);


export default {
    submit,
    submitError,
    submitSuccess,
};
