// @flow
import React, { Component } from 'react';
import cx from 'classnames';
import { isSubmitting } from 'redux-form';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { Hidden, Step, StepButton, StepIcon, Stepper } from '@material-ui/core';
import { Error as ErrorIcon } from '@material-ui/icons';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter } from 'react-router';
import type { Subscription } from 'gigwalk/lib/api/subscriptions/types';
import entitySelector from '../../../../redux/entities/entitySelector';
import { actions, selectors } from './duck';
import styles from './styles';
import type { StepErrors } from './duck/reducer';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {
    classes: Object,
    t: TFunction,
}

type StateProps = {
    currentStep: string,
    errors: StepErrors,
    submitting: boolean,
    subscription: ?Subscription,
}

type DispatchProps = {
    validateSteps: () => void,
}

type Props = OwnProps & StateProps & DispatchProps;

const steps = Object.freeze([
    'info',
    'locations',
    'people',
    'data',
    'launch',
]);

export class ProgressTracker extends Component<Props> {
    constructor(props: Props) {
        super(props);
        const { validateSteps } = this.props;
        validateSteps();
    }

    componentWillReceiveProps(nextProps: Props) {
        const { currentStep, subscription, validateSteps } = this.props;

        // Only run validation if (1) the subscription was loaded or (2) the current step has changed
        const shouldValidate = (!subscription && nextProps.subscription)
            || (currentStep !== nextProps.currentStep);

        if (shouldValidate) {
            validateSteps();
        }
    }

    getMatchParams() {
        const { match } = this.props;
        return { orgId: '0', subscriptionId: '0', ...match.params };
    }

    handleClickStep = (index: number) => {
        const { history } = this.props;
        const { orgId, subscriptionId } = this.getMatchParams();
        const step = steps[index];

        history.push(`/projects/${orgId}/create/${subscriptionId}/${step}`);
    };

    render() {
        const { classes, currentStep, errors, submitting, t } = this.props;
        const { subscriptionId } = this.getMatchParams();
        const currentIndex = steps.indexOf(currentStep);

        return (
            <Stepper
              classes={{ root: classes.stepper }}
              activeStep={currentIndex}
              nonLinear={subscriptionId !== 'new'}
              alternativeLabel
            >
                {steps.map((step: string, index) => {
                    const valid = errors[step].length === 0;
                    const icon = index < currentIndex && !valid
                        ? <StepIcon icon={<ErrorIcon className={cx(classes.customStepIcon, classes.error)} />} />
                        : index + 1;

                    return (
                        <Step
                          key={step}
                          classes={{ horizontal: classes.stepHorizontal }}
                          completed={currentIndex > index}
                          {...(submitting ? { disabled: true } : {})}
                        >
                            <StepButton
                              icon={icon}
                              onClick={() => { this.handleClickStep(index); }}
                            >
                                <Hidden xsDown implementation="css">
                                    {t(`projectBuilder.${step}.sectionTitle`)}
                                </Hidden>
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
        );
    }
}

const subscriptionSelector = entitySelector('subscriptions');

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const { match } = props;
    return {
        currentStep: selectors.getCurrentStep(state, props),
        errors: state.projectBuilder.progressTracker.errors,
        submitting: isSubmitting('projectBuilder')(state),
        subscription: subscriptionSelector(state, match.params.subscriptionId),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    validateSteps: () => { dispatch(actions.validateSteps()); },
});

const enhance = compose(
    withStyles(styles, { name: 'ProgressTracker' }),
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ProgressTracker);
