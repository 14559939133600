// @flow
import { createAction } from 'redux-actions';
import identity from 'lodash.identity';
import omitBy from 'lodash.omitby';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import { client as gigwalk } from '../../../api/createGigwalkClient';
import logger from '../../../util/logger';
import { fetch as fetchOrganization } from '../../../redux/entities/organizations';
import {
    search as searchSubscriptions,
    deleteSubscription,
} from '../../../redux/entities/subscriptions';
import types from './types';

export const fetchDataSuccess = createAction(types.FETCH_DATA_SUCCESS, identity, (data: Object, meta: Object): Object => meta);
export const fetchDataError = createAction(types.FETCH_DATA_ERROR);
export const fetchData = createAction(
    types.FETCH_DATA,
    (filters: ?Object[], keywords: ?string, sort: ?Object[], limit: ?number, offset: ?number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => {
            const data = omitBy({ filters, keywords, offset, limit, sort }, (value) => value == null);
            return dispatch(searchSubscriptions(data))
                .then((resp: Object) => dispatch(fetchDataSuccess(resp.result, resp.metadata)))
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchDataError());
                    return Promise.reject(err);
                });
        }
    )
);

export const deleteProjectSuccess = createAction(types.DELETE_PROJECT_SUCCESS);
export const deleteProjectError = createAction(types.DELETE_PROJECT_ERROR);
export const deleteProject = createAction(
    types.DELETE_PROJECT,
    (subscriptionId: number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => (
            dispatch(deleteSubscription({ organization_subscription_id: subscriptionId }))
                .then((resp: Object) => dispatch(deleteProjectSuccess(resp.result)))
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(deleteProjectError());
                    return Promise.reject(err);
                })
        )
    )
);

export const runAutoassignSuccess = createAction(types.RUN_AUTOASSIGN_SUCCESS);
export const runAutoassignError = createAction(types.RUN_AUTOASSIGN_ERROR);
export const runAutoassign = createAction(
    types.RUN_AUTOASSIGN,
    (subscriptionId: number): Function => (
        (dispatch: Dispatch<any>): Promise<void> => (
            gigwalk.client.post(`/v1/organization_subscriptions/${subscriptionId}`, { action: 'autoassign' })
                .then(() => dispatch(runAutoassignSuccess()))
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(runAutoassignError());
                    return Promise.reject(err);
                })
        )
    )
);

export const loadFiltersSuccess = createAction(types.LOAD_FILTERS_SUCCESS);
export const loadFiltersError = createAction(types.LOAD_FILTERS_ERROR);
export const loadFilters = createAction(
    types.LOAD_FILTERS,
    (search: Object) => (
        (dispatch: Dispatch<any>) => Promise.resolve()
            .then(() => {
                const promises = [];
                if (search.organization) {
                    search.organization.forEach((id) => {
                        promises.push(dispatch(fetchOrganization({ organization_id: id })));
                    });
                }
                return Promise.all(promises);
            })
            .then(() => {
                dispatch(loadFiltersSuccess());
            })
            .catch((err: $AxiosError<any>) => {
                logger.error(err);
                dispatch(loadFiltersError());
                return Promise.reject(err);
            })
    )
);

export default {
    deleteProject,
    fetchData,
    loadFilters,
    loadFiltersError,
    loadFiltersSuccess,
    runAutoassign,
};
