// @flow
// $FlowIssue need to update to a more recent flow version
import React, { useCallback, useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { makeStyles } from '@material-ui/styles';
import { Dialog } from '@material-ui/core';
import Container from './Container';
import styles from './styles';

type Props = {
    classes?: Object,
    gigLocation: {
        address: string,
        lat: number,
        lng: number,
    },
    onClose: () => void,
    open: boolean,
    photoUrl: string,
};

const useStyles = makeStyles(styles, { name: 'ExifViewer' });

export default function ExifViewer(props: Props) {
    const { classes: otherClasses, onClose, open, ...other } = props;
    const classes = useStyles(props);

    const dialogRef = useRef(null);
    const resizeObserverRef = useRef(null);

    useEffect(() => {
        let resizeObserver = resizeObserverRef.current;

        if (!resizeObserver) {
            resizeObserver = new ResizeObserver((entries) => {
                const { target, contentRect } = entries[0];
                if (target instanceof HTMLElement) {
                    target.style.marginTop = `-${contentRect.height / 2}px`;
                    target.style.marginLeft = `-${contentRect.width / 2}px`;
                }
            });

            resizeObserverRef.current = resizeObserver;
        }

        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
                resizeObserverRef.current = null;
            }
        };
    }, []);

    const refCallback = useCallback((node: ?React$Element<*>) => {
        const resizeObserver = resizeObserverRef.current;
        const dialog = dialogRef.current;

        if (dialog !== null) resizeObserver.unobserve(dialog);
        if (node !== null) resizeObserver.observe(node);

        dialogRef.current = node;
    }, []);

    const style = {
        top: '50%',
        right: 'unset',
        bottom: 'unset',
        left: '50%',
    };

    return (
        <Dialog
          classes={{ paper: classes.dialogPaper }}
          hideBackdrop
          maxWidth={false}
          onClose={onClose}
          open={open}
          ref={refCallback}
          style={style}
        >
            <Container {...other} classes={classes} onClose={onClose} />
        </Dialog>
    );
}
