// @flow
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import type { State as RootState } from '../redux/initialState';

export default connectedRouterRedirect({
    // The url to redirect user to if they fail
    redirectPath: '/login',
    // If selector is true, wrapper will not redirect
    authenticatedSelector: (state: RootState): boolean => !!state.session.user,
    // A nice display name for this check
    wrapperDisplayName: 'UserIsAuthenticated',
});
