// @flow
import { createSelector } from 'reselect';
import qs from 'qs';
import moment from 'moment';
import { isEmail } from 'validator';
import type { ContextRouter } from 'react-router';
import entitySelector from '../../../redux/entities/entitySelector';
import type { State as RootState } from '../../../redux/initialState';

// @todo Not sure which fields may be null
type TableData = Array<{
    amount: number,
    customer: Object,
    paymentDate: ?string,
    paypalEmail: ?string,
    status: string,
    ticket: Object,
    transactionId: ?string
}>;

type SearchParams = {
    customer?: string,
    page?: string,
    q?: string,
    size?: string,
    ticket?: string,
}

type APIParams = {
    customer_id: ?number,
    limit: number,
    offset: number,
    receiver_email: ?string,
    sort_field: string,
    sort_order: string,
    ticket_id: ?number,
    paypal_transaction_id: ?string,
}

const payoutSelector = entitySelector('payouts');

type Payout = {
    amount: string,
    customer: Object,
    date_paid: ?string,
    paypal_transaction_id: ?string,
    ticket_id: number,
    ticket_title: string,
    receiver_email: ?string,
    status: string,
};

export const getTableData = createSelector(
    (state: RootState) => payoutSelector(state, state.payoutList.data),
    (payouts: Payout[]): TableData => (
        payouts
            .filter((payout: ?Payout): boolean => !!payout)
            .map((payout: Payout) => {
                const {
                    amount,
                    customer,
                    date_paid: paymentDate,
                    paypal_transaction_id: transactionId,
                    receiver_email: paypalEmail,
                    ticket_id: ticketId,
                    ticket_title: ticketTitle,
                    status,
                } = payout;

                return {
                    amount: parseFloat(amount),
                    customer,
                    paymentDate: paymentDate ? moment(paymentDate).format() : null,
                    paypalEmail,
                    status,
                    ticket: {
                        id: ticketId,
                        title: ticketTitle,
                    },
                    transactionId,
                };
            })
    )
);

export const parseSearchParams = createSelector(
    <P: {}>(state: RootState, props: { ...P, ...ContextRouter }): string => {
        const { location } = props;
        return location.search;
    },
    (search: string): SearchParams => qs.parse(search, { ignoreQueryPrefix: true })
);

export const getAPIParams = createSelector(
    parseSearchParams,
    (search: SearchParams): APIParams => {
        const { page, q, size, ...filters } = search;
        const offset = Math.max(parseInt(page, 10) - 1, 0) || 0;
        const limit = Math.max(parseInt(size, 10), 0) || 10;
        let ticketId;
        let customerId;

        if (filters.ticket) {
            ticketId = parseInt(filters.ticket, 10);
        }

        if (filters.customer) {
            customerId = parseInt(filters.customer, 10);
        }

        return {
            [q && isEmail(q) ? 'receiver_email' : 'paypal_transaction_id']: q || null,
            customer_id: customerId,
            ticket_id: ticketId,
            limit,
            offset,
            sort_field: 'date_paid',
            sort_order: 'desc',
        };
    }
);

export default {
    getAPIParams,
    getTableData,
    parseSearchParams,
};
