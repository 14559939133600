// @flow
import { isCurrency } from 'validator';
import required from './required';

/**
 * Returns a validation error if value a malformed email address
 * @param value
 * @returns {string}
 */
export default (value: any): ?string => {
    const requiredError = required(value);
    if (requiredError) return;

    const invalid = typeof value !== 'string' || !isCurrency(value);
    if (invalid) {
        return 'invalidAmount';
    }
};
