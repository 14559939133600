// @flow
import React, { Component } from 'react';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { IconButton, Toolbar, Tooltip } from '@material-ui/core';
import {
    Email as EmailIcon,
    Refresh as RefreshIcon,
} from '@material-ui/icons';
import { compose } from 'recompose';
import type { $AxiosError } from 'axios';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { TFunction } from 'react-i18next';
import type { ContextRouter } from 'react-router';
import { format } from '../../../../../browser/shared/util/gigwalkApiErrorUtil';
import SubmitButton from '../../../../components/SubmitButton';
import * as snackbar from '../../../../ducks/snackbar';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type State = {
    sendingEmail: boolean,
}

type OwnProps = ContextRouter & {
    classes: Object,
    t: TFunction,
};
type StateProps = {
    recordCount: number,
    search: {
        size?: string,
        page?: string,
        sort?: string,
        order?: string,
    },
    user: ?Object,
};
type DispatchProps = {
    emailData: () => Promise<void>,
    enqueueSnackbar: (message: string, options: Object) => void,
    refresh: () => Promise<void>,
};
type Props = OwnProps & StateProps & DispatchProps;

export class ActionToolbar extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { sendingEmail: false };
        this.handleRefresh = debounce(this.handleRefresh, 500);
    }

    handleActionClick = (event: SyntheticEvent<*>) => {
        const { emailData, enqueueSnackbar, t, user } = this.props;
        const action = event.currentTarget.name;

        switch (action) {
            case 'emailData': {
                this.setState({ sendingEmail: true });
                emailData()
                    .then(() => {
                        const email = user ? user.email : '';
                        const message = t('gigwalkerList.toolbar.emailConfirmation', { email });
                        enqueueSnackbar(message, { variant: 'success' });
                    })
                    .catch((err: $AxiosError<any>) => {
                        const resp = err ? err.response : null;
                        if (resp && resp.data && resp.data.gw_api_response) {
                            const message = format(resp.data.gw_api_response);
                            enqueueSnackbar(message, { variant: 'error' });
                        }
                    })
                    .then(() => {
                        this.setState({ sendingEmail: false });
                    });
                break;
            }
            default:
                break;
        }
    };

    handleRefresh = () => {
        const { refresh } = this.props;
        refresh();
    };

    renderRecordCount = () => {
        const { classes, recordCount, search, t } = this.props;
        const page = Math.max(parseInt(search.page, 10) - 1, 0) || 0;
        const pageSize = Math.max(parseInt(search.size, 10), 0) || 10;

        const translateOptions = {
            count: recordCount,
            from: recordCount === 0 ? 0 : (page * pageSize) + 1,
            to: Math.min(recordCount, (page + 1) * pageSize),
        };

        return (
            <div className={classes.recordCount}>
                <Trans
                  parent="span"
                  defaults={t('gigwalkerList.toolbar.recordCount', translateOptions)}
                  components={[
                      <strong style={{ fontWeight: 600 }}>from</strong>, '-',
                      <strong style={{ fontWeight: 600 }}>to</strong>, 'of',
                      <strong style={{ fontWeight: 600 }}>count</strong>, 'workers',
                  ]}
                />
                <IconButton onClick={this.handleRefresh} size="small">
                    <RefreshIcon fontSize="small" />
                </IconButton>
            </div>
        );
    };

    renderActions = () => {
        const { sendingEmail } = this.state;
        const { classes, t } = this.props;
        const buttonProps = {
            classes: {
                root: classes.button,
                disabled: classes.buttonDisabled,
                contained: classes.buttonContained,
                containedPrimary: classes.buttonContainedPrimary,
            },
            variant: 'contained',
            color: 'primary',
            onClick: this.handleActionClick,
        };

        // @todo Tooltip child needs to be able to hold a ref
        return (
            <div>
                <Tooltip title={t('gigwalkerList.toolbar.emailTooltip')}>
                    <SubmitButton {...buttonProps} submitting={sendingEmail} name="emailData">
                        <EmailIcon className={classes.icon} />
                        {t('gigwalkerList.toolbar.email')}
                    </SubmitButton>
                </Tooltip>
            </div>
        );
    };

    render() {
        const { classes } = this.props;
        return (
            <Toolbar className={classes.root}>
                {this.renderRecordCount()}
                {this.renderActions()}
            </Toolbar>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    recordCount: state.gigwalkerList.metadata.record_count || 0,
    search: selectors.parseSearchParams(state, props),
    user: state.session.user,
});
const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    emailData: () => dispatch(actions.emailData()),
    enqueueSnackbar: (message: string, options: Object) => dispatch(snackbar.actions.enqueue(message, options)),
    refresh: () => dispatch(actions.refresh()),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(
    withStyles(styles, { name: 'ActionToolbar' }),
    withTranslation(),
    connector,
);

export default enhance(ActionToolbar);
