// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Component, createRef } from 'react';
import debounce from 'lodash.debounce';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import EventListener from 'react-event-listener';
import { Route } from 'react-router';
import { withStyles } from '@material-ui/styles';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Collapse,
} from '@material-ui/core';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { TFunction } from 'react-i18next';
import { selectors } from './duck';
import GPSVerifiedMap from './containers/GPSVerifiedMap';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type State = {
    expanded: boolean,
    collapsedHeight: number,
}

type OwnProps = ContextRouter & {
    classes: Object,
    className?: string,
    t: TFunction,
};
type StateProps = {
    ticket: Object,
};
type Props = OwnProps & StateProps;

export class Description extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.descriptionRef = createRef();
        this.handleResize = debounce(this.handleResize, 166);
        this.state = {
            expanded: false,
            collapsedHeight: 100,
        };
    }

    componentDidMount() {
        this.computeCollapsedHeight();
    }

    componentDidUpdate() {
        this.computeCollapsedHeight();
    }

    handleResize = () => {
        this.computeCollapsedHeight();
    };

    handleClick = () => {
        const { expanded } = this.state;
        this.setState({ expanded: !expanded });
    };

    computeCollapsedHeight = () => {
        const node = this.descriptionRef.current;
        if (node) {
            const { collapsedHeight } = this.state;
            const nextCollapsedHeight = Math.min(node.offsetHeight, 100);
            if (nextCollapsedHeight !== collapsedHeight) {
                this.setState({ collapsedHeight: nextCollapsedHeight });
            }
        }
    };

    descriptionRef: Object;

    render() {
        const { classes, className, t, ticket } = this.props;
        const { collapsedHeight, expanded } = this.state;
        const { title: locationTitle, formatted_address: locationAddress } = ticket.location;
        const hasOverflow = collapsedHeight === 100;

        return (
            <Card className={className}>
                <EventListener
                  target="window"
                  onResize={this.handleResize}
                />
                <CardHeader
                  classes={{
                      title: classes.locationTitle,
                      subheader: classes.locationAddress,
                  }}
                  title={locationTitle !== locationAddress ? locationTitle : locationAddress}
                  subheader={locationTitle !== locationAddress ? locationAddress : null}
                />
                <div style={{ height: 270, width: '100%' }}>
                    <Route component={GPSVerifiedMap} />
                </div>
                <CardContent className={classes.cardContent}>
                    <div className={classes.label}>{t('ticketDetail.description.description')}</div>
                    <Collapse className={classes.description} in={expanded} collapsedHeight={`${collapsedHeight}px`}>
                        <div
                          ref={this.descriptionRef}
                          style={{ maxHeight: expanded ? 'unset' : `${collapsedHeight}px` }}
                          dangerouslySetInnerHTML={{ __html: ticket.description }}
                        />
                        <div className={classes.gradient} style={{ visibility: hasOverflow && !expanded ? 'visible' : 'hidden' }} />
                    </Collapse>
                    {hasOverflow
                        ? (
                            <Button
                              className={classes.showMoreButton}
                              variant="outlined"
                              fullWidth
                              onClick={this.handleClick}
                            >
                                {expanded ? t('ticketDetail.description.hideDescription') : t('ticketDetail.description.showDescription')}
                            </Button>
                        )
                        : null
                    }
                </CardContent>
            </Card>
        );
    }
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    // $FlowIssue weird error message - could be a bug in flow or reselect typedef
    ticket: selectors.getTicket(state, props),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps);
const enhance = compose(
    withStyles(styles, { name: 'Description' }),
    withTranslation(),
    connector,
);

export default enhance(Description);
