// @flow
import React, { Component } from 'react';
import cx from 'classnames';
import Cleave from 'cleave.js/dist/cleave-react';
import { withStyles } from '@material-ui/styles';
import {
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
} from '@material-ui/core';
import type { Element } from 'react';
import type { CleaveSyntheticInputEvent } from 'cleave.js/dist/cleave-react';
import styles from './styles';

type Props = {
    className?: string,
    classes: Object,
    disabled?: boolean,
    error?: string,
    id?: string,
    label?: string | Element<any>,
    onChange?: (value: string) => void,
    onBlur?: (value: string) => void,
    options?: Object,
    required?: boolean,
    // Real support coming soon!! https://github.com/mui-org/material-ui/pull/12076
    variant?: 'outlined' | 'standard',
};

export class MaskedInput extends Component<Props> {
    handleBlur = (event: CleaveSyntheticInputEvent) => {
        const { onBlur } = this.props;
        if (typeof onBlur === 'function') {
            onBlur(event.currentTarget.rawValue);
        }
    };

    handleChange = (event: CleaveSyntheticInputEvent) => {
        const { onChange } = this.props;
        if (typeof onChange === 'function') {
            onChange(event.currentTarget.rawValue);
        }
    };

    render() {
        const { className, classes, disabled, error, label, options, variant, ...inputProps } = this.props;

        return (
            <FormControl
              className={cx({ error: !!error }, className, classes.root)}
              margin={label ? 'normal' : 'none'}
              error={!!error}
              disabled={disabled}
            >
                {label ? <FormLabel>{label}</FormLabel> : null}
                <Input
                  {...inputProps}
                  classes={{
                      root: cx(classes.input, { [classes.outlined]: variant === 'outlined' }),
                      disabled: classes.disabled,
                  }}
                  disableUnderline={variant === 'outlined'}
                  type="text"
                  inputComponent={Cleave}
                  inputProps={{
                      options,
                      onChange: this.handleChange,
                      onBlur: this.handleBlur,
                  }}
                />
                {error ? <FormHelperText className={classes.error}>{error}</FormHelperText> : null}
            </FormControl>
        );
    }
}

export default withStyles(styles, { name: 'MaskedInput' })(MaskedInput);
