// @flow
// $FlowIssue need to update to a more recent flow version
import React, { Fragment, useCallback } from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
    Badge,
    Button,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Toolbar,
    Tooltip,
} from '@material-ui/core';
import {
    FilterList as FilterListIcon,
    Search as SearchIcon,
    Settings as SettingsIcon,
} from '@material-ui/icons';
import type { Dispatch } from 'redux';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import Dropdown from '../../../../components/Dropdown';
import ToggleButton from '../../../../components/ToggleButton';
import * as api from '../../../../ducks/api';
import { actions, selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../redux/initialState';

type OwnProps = ContextRouter & {};
type StateProps = {
    filterCount: number,
    search: {
        q?: string,
    },
    settings: {
        columns: string[],
    }
};
type DispatchProps = {
    resetColumns: () => void,
    toggleColumn: (name: string, enabled: boolean) => void,
    toggleFilterDrawer: (open: boolean) => void,
    updateProfile: (params: Object) => Promise<void>,
};
type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles(styles, { name: 'SearchToolbar' });

const availableColumns = [
    { label: 'Status', value: 'status' },
    { label: 'Location', value: 'location' },
    { label: 'Assignee', value: 'assignee' },
    { label: 'Start date', value: 'startDate' },
    { label: 'End date', value: 'dueDate' },
    { label: 'Submitted date', value: 'submittedDate' },
];

export function SearchToolbar(props: Props) {
    const {
        filterCount,
        history,
        location,
        resetColumns,
        search,
        settings,
        toggleColumn,
        toggleFilterDrawer,
        updateProfile,
    } = props;

    const classes = useStyles(props);
    const { t } = useTranslation();

    const handleQueryChange = useCallback((event: SyntheticInputEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const { q, ...query } = search;

        history.replace({
            pathname: location.pathname,
            search: qs.stringify(
                value ? { ...query, q: value } : query,
                { addQueryPrefix: true, encodeValuesOnly: true }
            ),
        });
    }, [history, location.pathname, search]);

    const handleDrawerOpen = useCallback(() => {
        toggleFilterDrawer(true);
    }, [toggleFilterDrawer]);

    const handleToggle = useCallback((event: SyntheticEvent<any>, selected: boolean) => {
        const { name } = event.currentTarget.dataset;
        const columns = new Set(settings.columns);

        if (selected) {
            columns.add(name);
        } else {
            columns.delete(name);
        }

        toggleColumn(name, selected);
        updateProfile({ ticketColumns: Array.from(columns) });
    }, [settings.columns, toggleColumn, updateProfile]);

    const handleReset = useCallback(() => {
        resetColumns();
        updateProfile({ ticketColumns: [] });
    }, [resetColumns, updateProfile]);

    return (
        <Toolbar className={classes.root} disableGutters>
            <OutlinedInput
              className={classes.input}
              fullWidth
              labelWidth={0}
              margin="dense"
              onChange={handleQueryChange}
              placeholder={t('ticketList.searchPlaceholder')}
              startAdornment={(
                  <InputAdornment position="start">
                      <SearchIcon />
                  </InputAdornment>
              )}
              value={search.q || ''}
            />
            <Tooltip title={t('ticketList.filters.filterGigs')}>
                <IconButton onClick={handleDrawerOpen}>
                    <Badge classes={{ badge: classes.badge }} badgeContent={filterCount}>
                        <FilterListIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Dropdown
              anchor={(
                  <IconButton>
                      <SettingsIcon />
                  </IconButton>
              )}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
              }}
              classes={{ menu: classes.settings }}
              disableAutoFocusItem
              getContentAnchorEl={null}
              MenuListProps={{
                  component: 'div',
                  disableListWrap: true,
                  disablePadding: true,
              }}
              transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
              }}
            >
                {() => (
                    <Fragment>
                        <Toolbar className={classes.columnsToolbar} variant="dense">
                            <span className={classes.settingsLabel}>Add or remove columns</span>
                            <Button className={classes.resetButton} color="primary" onClick={handleReset}>
                                Reset
                            </Button>
                        </Toolbar>
                        <div className={classes.toggleButtons}>
                            {availableColumns.map((column: Object) => (
                                <ToggleButton
                                  classes={{
                                      root: classes.toggleButton,
                                      selected: classes.selected,
                                  }}
                                  color="primary"
                                  data-name={column.value}
                                  onChange={handleToggle}
                                  selected={settings.columns.includes(column.value)}
                                >
                                    {column.label}
                                </ToggleButton>
                            ))}
                        </div>
                    </Fragment>
                )}
            </Dropdown>
        </Toolbar>
    );
}

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
    filterCount: selectors.getActiveFilterCount(state, props),
    search: selectors.parseSearchParams(state, props),
    settings: state.ticketList.settings,
});

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    resetColumns: () => dispatch(actions.resetColumns()),
    toggleColumn: (name: string, enabled: boolean) => dispatch(actions.toggleColumn(name, enabled)),
    toggleFilterDrawer: (open: boolean) => dispatch(actions.toggleFilterDrawer(open)),
    updateProfile: (params: Object) => dispatch(api.actions.updateProfile(params)),
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);
export default connector(SearchToolbar);
