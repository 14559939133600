// @flow
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FieldArray, formValueSelector, getFormInitialValues, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { Avatar, Chip, ListItemText, Typography } from '@material-ui/core';
import {
    Cancel as CancelIcon,
    Check as CheckIcon,
    Search as SearchIcon,
    VerifiedUser as VerifiedUserIcon,
} from '@material-ui/icons';
import type { $AxiosXHR } from 'axios';
import type { FieldArrayProps, FormProps } from 'redux-form';
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router';
import type { TFunction } from 'react-i18next';
import type { Certification } from 'gigwalk/lib/api/certifications/types';
import { client as gigwalk } from '../../../../../../api/createGigwalkClient';
import highlightMatch from '../../../../../../util/highlightMatch';
import Select from '../../../../../../components/Select';
import Step from '../../../../components/Step';
import entitySelector from '../../../../../../redux/entities/entitySelector';
import { selectors } from './duck';
import styles from './styles';
import type { State as RootState } from '../../../../../../redux/initialState';

type OwnProps = {
    ...ContextRouter,
    ...FormProps,
    classes: Object,
    t: TFunction,
};

type StateProps = {
    editMode: boolean,
    needsPublicWorkforce: boolean,
};

type Props = OwnProps & StateProps;

const preventFormSubmission = (event) => {
    event.preventDefault();
    return false;
};

const formatOptionLabel = (option: Object, meta: Object) => {
    const { label } = option;
    const primary = highlightMatch(label, meta.inputValue);

    return (
        <Fragment>
            <ListItemText primary={primary} />
            {meta.isSelected ? <CheckIcon /> : null}
        </Fragment>
    );
};

export class Certifications extends Component<Props> {
    loadOptions = (inputValue: string): Promise<Object[]> => {
        const { match, needsPublicWorkforce } = this.props;
        const orgId = needsPublicWorkforce ? 5 : match.params.orgId;
        const params = {
            q: `*${inputValue}*`,
            sort_field: 'title',
            sort_order: 'asc',
            limit: 20,
        };

        return gigwalk.client.get(`/v1/organizations/${orgId}/certifications`, { params })
            .then((resp: $AxiosXHR<Object>) => {
                const certifications = resp.data.data || [];
                return certifications.map((certification: Certification) => ({
                    label: certification.title,
                    value: certification,
                }));
            });
    };

    handleCertificationsChange = (value: Object[]) => {
        const { change } = this.props;
        change('certifications', value.map((option: Object) => option.value));
    };

    handleRemoveCertification = (event: SyntheticMouseEvent<any>) => {
        const { index } = event.currentTarget.dataset;
        const { array } = this.props;
        array.remove('certifications', index);
    };

    renderCertifications = (props: FieldArrayProps): ?React$Element<*> => {
        const { classes, t } = this.props;
        const { fields } = props;
        const selected = [];

        const elements = fields.map((name: string, index: number) => {
            const certification = fields.get(index);
            selected.push({ label: certification.title, value: certification });

            return (
                <Chip
                  avatar={<Avatar><VerifiedUserIcon /></Avatar>}
                  className={classes.certification}
                  classes={{
                      root: classes.chipRoot,
                      avatar: classes.chipAvatar,
                      avatarChildren: classes.chipAvatarChildren,
                      label: classes.chipLabel,
                      deleteIcon: classes.chipDeleteIcon,
                  }}
                  deleteIcon={<CancelIcon data-index={index} />}
                  key={name}
                  label={certification.title}
                  onDelete={this.handleRemoveCertification}
                />
            );
        });

        return (
            <div>
                {elements.length > 0
                    ? (
                        <div className={classes.requiredCertifications}>
                            <Typography className={classes.label} align="center">
                                {t('projectBuilder.people.certifications.requiredCertifications')}
                            </Typography>
                            <ul>{elements}</ul>
                        </div>
                    )
                    : null
                }
                <Select
                  backspaceRemovesValue={false}
                  classes={{
                      input: classes.selectInput,
                      placeholder: classes.selectPlaceholder,
                  }}
                  ControlProps={{ fullWidth: true }}
                  controlShouldRenderValue={false}
                  defaultOptions
                  disableSelectedOptions
                  dropdownIcon={<SearchIcon />}
                  formatOptionLabel={formatOptionLabel}
                  isClearable={false}
                  isMulti
                  loadOptions={this.loadOptions}
                  onChange={this.handleCertificationsChange}
                  placeholder={t('projectBuilder.people.certifications.addCertification')}
                  value={selected}
                />
            </div>
        );
    };

    render() {
        const { classes, t } = this.props;

        // In some browsers, hitting the 'Enter' key in a text input that is the only field in the form will cause
        // the form to be submitted. This bypasses all the submission control/logic of reduxForm. As a workaround,
        // you can add an onSubmit event handler and use `event.preventDefault()` to disable this behavior.
        // See https://stackoverflow.com/questions/1370021/why-does-forms-with-single-input-field-submit-upon-pressing-enter-key-in-input
        return (
            <Step
              title={t('projectBuilder.people.certifications.header')}
              subheader={t('projectBuilder.people.certifications.subheader')}
            >
                <form className={classes.form} onSubmit={preventFormSubmission}>
                    <FieldArray name="certifications" component={this.renderCertifications} />
                </form>
            </Step>
        );
    }
}

const subscriptionSelector = entitySelector('subscriptions');
const valueSelector = formValueSelector('projectBuilder');

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => {
    const { match } = props;
    return {
        editMode: subscriptionSelector(state, match.params.subscriptionId, 'state') === 'ACTIVE',
        initialValues: {
            ...getFormInitialValues('projectBuilder')(state),
            ...selectors.getInitialValues(state, props),
        },
        needsPublicWorkforce: valueSelector(state, 'needsPublicWorkforce') || false,
    };
};

const connector: Connector<OwnProps, Props> = connect(mapStateToProps);
const enhance = compose(
    withStyles(styles, { name: 'Certifications' }),
    withTranslation(),
    connector,
    reduxForm({
        form: 'projectBuilder',
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    })
);
export default enhance(Certifications);
