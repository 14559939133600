// @flow
export const FETCH_API_VERSION = 'g/layout/FETCH_API_VERSION';
export const FETCH_API_VERSION_SUCCESS = `${FETCH_API_VERSION}_SUCCESS`;
export const FETCH_API_VERSION_ERROR = `${FETCH_API_VERSION}_ERROR`;

export default {
    FETCH_API_VERSION,
    FETCH_API_VERSION_SUCCESS,
    FETCH_API_VERSION_ERROR,
};
