// @flow
import SkipLogicTree from '../SkipLogicTree';
import Instructions from './Instructions';

/**
 * @class (Model) DataType
 * @constructor
 */
const DataType = SkipLogicTree.extend({

    defaults: {
        children: [],
        data_type_id: 0,
        observation_target_id: 0,
        template_id: 0,
    },

    Collection: Instructions,

    initialize(...args: Array<any>) {
        this.Collection = Instructions;
        this._super(...args);
    },
});

export default DataType;
