// @flow
import { emphasize } from '@material-ui/core/styles/colorManipulator';

export default (theme: Object) => ({
    appBar: {
        height: 80,
        borderBottom: [[4, 'solid', '#fbc61d']],

        [theme.breakpoints.down('sm')]: {
            height: 64,
            marginBottom: 40,
            borderBottom: 'none',
        },
    },
    toolbar: {
        height: '100%',
        alignItems: 'center',
    },
    menuButton: {
        fontSize: 24,
        marginLeft: 'calc(-24px + 0.5em)',

        '&:hover': {
            backgroundColor: 'unset', // override rule in _button-hover-active.scss
        },
    },
    navContainer: {
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    title: {
        fontSize: 30,
        fontWeight: 600,
        fontFamily: ['"ProximaNova"', 'sans-serif'], // $header-font-family
        marginBottom: 0,
        marginRight: theme.spacing(4),

        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(3),
            fontSize: 25,
            lineHeight: '64px',
        },
    },
    navigation: {
        [theme.breakpoints.down('sm')]: {
            padding: [[0, '3vw']],
            background: theme.palette.primary.dark,
            lineHeight: '40px',
            minHeight: 40,
            position: 'absolute',
            left: 0,
            top: 64,

            display: 'block',
            width: '100%',
            borderBottom: [[4, 'solid', '#fbc61d']],
        },
    },
    link: {
        display: 'inline-block',
        marginRight: theme.spacing(4),
        color: 'inherit',

        // @todo Move to typography.js
        fontWeight: 300,
        fontSize: 16,
        fontFamily: ['"ProximaNova"', 'sans-serif'], // $header-font-family

        '&$active': {
            position: 'relative',

            '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -22, // magic number
                left: '50%',
                marginLeft: -7,
                borderWidth: 7,
                borderStyle: 'solid',
                borderColor: [['transparent', 'transparent', '#fdc449', 'transparent']], // $accent-secondary-color

                [theme.breakpoints.down('sm')]: {
                    bottom: 0,
                },
            },
        },

        // Override styles applied in _typography.scss to anchor tags
        '&:active, &:hover, &:visited, &:focus': { color: 'inherit' },
        '&:focus': { outline: 'none' },

        [theme.breakpoints.down('sm')]: {
            padding: [[0, '3vw']],
            marginRight: 0,
        },
    },
    dropdownContainer: {
        marginLeft: 'auto',
        alignItems: 'center',
        display: 'flex',

        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
    label: {
        marginLeft: theme.spacing(1),
    },
    username: {
        display: 'block',
        // color: '$text-reversed-color',
        textTransform: 'uppercase',
        fontSize: 12,
    },
    organization: {
        display: 'block',
        // color: '$pale-brand-color',
        fontSize: 12,
    },
    profileMenu: {
        maxWidth: 250,
        minWidth: 200,
    },
    active: {},
    notifications: {
        width: 350,
        fontFamily: 'ProximaNova',
        fontSize: 16,
    },
    notificationsToolbar: {
        backgroundColor: emphasize(theme.palette.background.default, 0.8), // theme.palette.grey[900],
        color: theme.palette.common.white,
        fontWeight: 600,

        '&:focus': {
            outline: 'none',
        },
    },
    notificationsList: {
        maxHeight: 300,
        overflowY: 'auto',

        '&:focus': {
            outline: 'none',
        },
    },
    noNotifications: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 250,
        marginTop: -20,
        color: theme.palette.grey[500],
    },
    notification: {
        position: 'relative',
        wordBreak: 'break-word',

        '&$disabled': {
            pointerEvents: 'none',
        },

        '&:hover $notificationActions': {
            opacity: 1,
        },
    },
    notificationActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '100%',
        position: 'absolute',
        right: 0,
        padding: [[0, 4]],
        backgroundColor: theme.palette.common.white,
        opacity: 0,
        transition: theme.transitions.create('opacity', {
            easing: theme.transitions.easing.easeOut,
            duration: 200,
        }),
    },
    spinner: {
        position: 'absolute',
        left: 0,
        height: '100%',
        width: '100%',
        background: 'rgba(255, 255, 255, 1.64)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    disabled: {},
});
