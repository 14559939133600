var Handlebars = require('/tmp/build_c41768ab/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression;

  return "            <div class=\"key-pair\">\n                <div class=\"key\">"
    + alias1(((helper = (helper = helpers.key || (data && data.key)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data}) : helper)))
    + "</div>\n                <div class=\"value\">"
    + alias1(container.lambda(depth0, depth0))
    + "</div>\n            </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"wrapper\">\n    <div class=\"popup-header\">\n        <div class=\"popup-ti\">"
    + container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</div>\n        <a class=\"close\" href=\"javascript:;\" data-action=\"close\"><span class=\"im icon-cancel\"></span></a>\n    </div>\n\n    <div class=\"popup-content\"><!-- content area -->\n\n\n        <!-- key pairs can stack -->\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.metadata : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    </div><!-- ends popup content area -->\n\n    <div class=\"primary actions\">\n        <!-- some popups will have buttons - put the primary actions div here if you are making a template and it will be suppressed for metadata popups -->\n    </div>\n</div>\n";
},"useData":true});