// @flow
import { createSelector } from 'reselect';
import { matchPath } from 'react-router';
import type { Location, LocationShape, Match } from 'react-router';
import { USER_ROLES } from '../../../../../../browser/shared/constant/UserRoles';
import isCrossmark from '../../../../../util/isCrossmark';
import type { State } from '../../../../../redux/initialState';
import type { User } from '../../../../../store_utils/types/User';

type NavItem = {
    href?: string,
    icon: string,
    id: string,
    isActive?: Function,
    to?: string | LocationShape,
}

export const getLogoUri = createSelector(
    (state: State): ?User => state.session.user,
    (state: State): boolean => {
        const { open, inTransition, expanded } = state.layout.sideNav;
        return open || expanded || inTransition;
    },
    (user: ?User, isSideNavExpanded: boolean): string => {
        const defaultLogoUri = '/public/images/gigwalk-logo.png';
        const defaultMiniLogoUri = '/public/images/gigwalk-logo-mini.png';

        if (user == null) {
            return isSideNavExpanded ? defaultLogoUri : defaultMiniLogoUri;
        }

        const orgConfig = user.organization.config;
        return isSideNavExpanded
            ? orgConfig.logo_uri || defaultLogoUri
            // $FlowFixMe need to update gigwalk-node type definitions
            : orgConfig.mini_icon_uri || defaultMiniLogoUri;
    }
);

export const getNavItems = createSelector(
    (state: State): ?User => state.session.user,
    (user: ?User): NavItem[] => {
        if (user == null) {
            return [];
        }

        // $FlowFixMe need to update gigwalk-node type definitions
        const showLocations = !!user.organization.config.show_locations;
        const showTargets = !!user.organization.config.targets_enabled;

        const orgId = user.organization.id;
        const supervisorRoles = [
            USER_ROLES.PLATFORM_ADMIN,
            USER_ROLES.SUPER_ADMIN,
            USER_ROLES.SELF_SERVICE,
            USER_ROLES.ADMIN,
            USER_ROLES.OPERATOR,
        ];
        const isSupervisor = supervisorRoles.includes(user.role);
        const items = [];

        items.push({
            id: 'projects',
            icon: 'storage',
            to: `/projects/${orgId}/active/list`,
            isActive: (match: Match, location: Location): boolean => (
                !!matchPath(location.pathname, { path: '/projects/:orgId' })
            ),
        });

        if (isSupervisor) {
            items.push(
                {
                    id: 'tickets',
                    icon: 'assignment',
                    to: `/tickets/${orgId}/list`,
                    isActive: (match: Match, location: Location): boolean => (
                        !!matchPath(location.pathname, { path: '/tickets/:orgId' })
                    ),
                },
                {
                    id: 'myTeam',
                    icon: 'group',
                    to: `/admin/${orgId}/my-team/users/list`,
                    isActive: (match: Match, location: Location): boolean => (
                        !!matchPath(location.pathname, { path: '/admin/:orgId/my-team' })
                    ),
                }
            );
        }

        items.push({
            id: 'certifications',
            icon: 'verified_user',
            to: `/resources/${orgId}/certifications/list`,
            isActive: (match: Match, location: Location): boolean => (
                !!matchPath(location.pathname, { path: '/resources/:orgId/certifications' })
            ),
        });

        if (showLocations) {
            items.push({
                id: 'locations',
                icon: 'place',
                to: `/resources/${orgId}/location-lists/list`,
                isActive: (match: Match, location: Location): boolean => (
                    !!matchPath(location.pathname, { path: '/resources/:orgId/(location-lists|locations)' })
                ),
            });
        }

        if (showTargets) {
            items.push({
                id: 'targets',
                icon: 'gps_fixed',
                to: `/resources/${orgId}/target-lists/list`,
                isActive: (match: Match, location: Location): boolean => (
                    !!matchPath(location.pathname, { path: '/resources/:orgId/(target-lists|targets)' })
                ),
            });
        }

        items.push({
            id: 'questions',
            icon: 'help_outline',
            to: `/resources/${orgId}/questions/list`,
            isActive: (match: Match, location: Location): boolean => (
                !!matchPath(location.pathname, { path: '/resources/:orgId/questions' })
            ),
        });

        if (user.role === USER_ROLES.PLATFORM_ADMIN) {
            items.push({
                id: 'organizations',
                icon: 'group_work',
                to: `/admin/${orgId}/organizations/list`,
                isActive: (match: Match, location: Location): boolean => (
                    !!matchPath(location.pathname, { path: '/admin/:orgId/organizations' })
                ),
            });

            items.push({
                id: 'transactions',
                icon: 'credit_card',
                to: `/admin/${orgId}/transactions/list`,
                isActive: (match: Match, location: Location): boolean => (
                    !!matchPath(location.pathname, { path: '/admin/:orgId/transactions' }))
                ,
            });
        }

        if (!isCrossmark(user)) {
            items.push({
                id: 'support',
                icon: 'headset_mic',
                href: 'https://mygigwalk.zendesk.com/hc/en-us',
            });
        }

        return items;
    }
);

export default {
    getLogoUri,
    getNavItems,
};
