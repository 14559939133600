// @flow
import { createSelector } from 'reselect';
import type { State as RootState } from '../../../../../common/redux/initialState';
import type { FileUpload } from '../../../../../common/redux/entities/locationLists';

type StatusCountMap = {
    NEW: number,
    IN_PROCESS: number,
    FAILED: number,
    PROCESSED: number,
};

type UploadStatus = 'NEW' | 'PENDING' | 'PROCESSING' | 'FAILED' | 'COMPLETE';

export const getUploadIds = (state: RootState): Array<number> => {
    const { listId } = state.locationListUpload;
    const list = state.entities.locationLists[`${listId}`];

    // $FlowFixMe need to define normalized types
    return list && Array.isArray(list.file_uploads) ? list.file_uploads : [];
};

export const getUploads = (state: RootState): { [key: string]: FileUpload } => state.entities.fileUploads;

export const mapUploads = createSelector(
    [getUploadIds, getUploads],
    (ids: Array<number>, uploads: { [key: string]: FileUpload }): Array<FileUpload> => (
        ids.map((id: number): FileUpload => uploads[`${id}`])
    )
);

export const getUploadStatus = createSelector(
    [mapUploads],
    (uploads: Array<FileUpload>): UploadStatus => {
        if (uploads.length === 0) {
            return 'NEW';
        }

        const { NEW, IN_PROCESS, FAILED } = (
            uploads.reduce((map: StatusCountMap, upload: FileUpload) => {
                const count = map[upload.status];
                return {
                    ...map,
                    [upload.status]: count + 1,
                };
            }, { NEW: 0, IN_PROCESS: 0, FAILED: 0, PROCESSED: 0 })
        );

        // If there are any new uploads, the status should be 'PENDING'. This let's us know
        // we cannot reliably determine the upload progress
        if (NEW > 0) {
            return 'PENDING';
        }

        // We should consider that status to be 'PROCESSING' if there are any uploads that
        // are still being processed.
        if (IN_PROCESS > 0) {
            return 'PROCESSING';
        }

        // After all uploads have been processed, if there are any that have failed,
        // the status will be 'FAILED'. Otherwise, it will be 'COMPLETE'
        return (FAILED > 0) ? 'FAILED' : 'COMPLETE';
    }
);

export const getUnresolvedLocationCount = createSelector(
    [mapUploads],
    (uploads: Array<FileUpload>) => (
        uploads.reduce((sum: number, upload: FileUpload): number => sum + upload.unresolved_location_count, 0)
    )
);

export const getProcessedRowCount = createSelector(
    [mapUploads],
    (uploads: Array<FileUpload>) => (
        uploads.reduce((sum: number, upload: FileUpload): number => sum + upload.processed_row_count, 0)
    )
);

export const getLocationCount = createSelector(
    [mapUploads],
    (uploads: Array<FileUpload>) => (
        uploads.reduce((sum: number, upload: FileUpload): number => sum + upload.location_count, 0)
    )
);

export const getUploadProgress = createSelector(
    [getProcessedRowCount, getLocationCount, getUploadStatus],
    (processedRowCount: number, locationCount: number, status: UploadStatus): number => {
        if (locationCount === 0) {
            return status === 'COMPLETE' ? 100 : 0;
        }
        return Math.round((processedRowCount / locationCount) * 100);
    }
);
