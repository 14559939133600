// @flow
import { createAction } from 'redux-actions';
import type { Dispatch } from 'redux';
import type { $AxiosError } from 'axios';
import { STATUS } from '../../../../browser/shared/constant/TicketConstant';
import { USER_ROLES } from '../../../../browser/shared/constant/UserRoles';
import logger from '../../../util/logger';
import {
    fetch as fetchTicket,
    getApplicants as getTicketApplicants,
    getEvents as getTicketEvents,
} from '../../../redux/entities/tickets';
import { fetch as fetchTemplate } from '../../../redux/entities/templates';
import types from './types';
import type { State as RootState } from '../../../redux/initialState';

export const fetchAllApplicantsSuccess = createAction(types.FETCH_ALL_APPLICANTS_SUCCESS);
export const fetchAllApplicantsError = createAction(types.FETCH_ALL_APPLICANTS_ERROR);
export const fetchAllApplicants = createAction(
    types.FETCH_ALL_APPLICANTS,
    (ticketId: number) => (
        (dispatch: Dispatch<any>) => (
            dispatch(getTicketApplicants({ ticket_id: ticketId, limit: 200, offset: 0 }))
                .then((resp) => {
                    const promises = [];
                    if (resp.original && resp.original._metadata) {
                        const { page_count: pageCount } = resp.original._metadata;
                        for (let i = 1; i < pageCount; i += 1) {
                            promises.push(dispatch(getTicketApplicants({ ticket_id: ticketId, limit: 200, offset: i })));
                        }
                    }
                    return Promise.all(promises);
                })
                .then(() => {
                    dispatch(fetchAllApplicantsSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchAllApplicantsError());
                    return Promise.reject(err);
                })
        )
    )
);

export const fetchAllEventsSuccess = createAction(types.FETCH_ALL_EVENTS_SUCCESS);
export const fetchAllEventsError = createAction(types.FETCH_ALL_EVENTS_ERROR);
export const fetchAllEvents = createAction(
    types.FETCH_ALL_EVENTS,
    (ticketId: number) => (
        (dispatch: Dispatch<any>) => (
            dispatch(getTicketEvents({ ticket_id: ticketId, limit: 200, offset: 0 }))
                .then((resp) => {
                    const promises = [];
                    if (resp.original && resp.original._metadata) {
                        const { limit, record_count: recordCount } = resp.original._metadata;
                        for (let i = limit; i < recordCount; i += limit) {
                            promises.push(dispatch(getTicketEvents({ ticket_id: ticketId, limit, offset: i })));
                        }
                    }
                    return Promise.all(promises);
                })
                .then(() => {
                    dispatch(fetchAllEventsSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(fetchAllEventsError());
                    return Promise.reject(err);
                })
        )
    )
);

export const loadTicketSuccess = createAction(types.LOAD_TICKET_SUCCESS);
export const loadTicketError = createAction(types.LOAD_TICKET_ERROR);
export const loadTicket = createAction(
    types.LOAD_TICKET,
    (ticketId: number): Function => (
        (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => (
            dispatch(fetchTicket({ ticket_id: ticketId }))
                .then((result: Object) => {
                    const promises = [];
                    const ticket = result.entities.tickets[ticketId];
                    const {
                        status,
                        subscription,
                        template_map: templateMap,
                    } = ticket;

                    promises.push(dispatch(fetchAllEvents(ticketId)));

                    const state = getState();
                    const userRole = state.session.user ? state.session.user.role : '';
                    const canFetchApplicants = subscription.needs_public_workforce
                        ? [USER_ROLES.PLATFORM_ADMIN, USER_ROLES.SELF_SERVICE].includes(userRole)
                        : userRole !== USER_ROLES.WORKER;

                    if (canFetchApplicants && subscription.is_double_optin && status === STATUS.UNASSIGNED) {
                        promises.push(dispatch(fetchAllApplicants(ticketId)));
                    }

                    const templateIds = Object.keys(templateMap) || [];
                    templateIds.forEach((id: number) => {
                        promises.push(dispatch(fetchTemplate({ template_id: id })));
                    });

                    return Promise.all(promises);
                })
                .then(() => {
                    dispatch(loadTicketSuccess());
                })
                .catch((err: $AxiosError<any>) => {
                    logger.error(err);
                    dispatch(loadTicketError());
                    return Promise.reject(err);
                })
        )
    )
);

export default {
    loadTicket,
    loadTicketError,
    loadTicketSuccess,
};
